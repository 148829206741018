import { http } from '@/core/http/HttpFetch';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { Territories } from '@/settings/domain/views/territories/Territories';
import { TerritoriesRepository } from '@/settings/domain/views/territories/TerritoriesRepository';

@Injectable()
export class TerritoryService implements TerritoriesRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_VIEW_FIND_LOCATION_BY_QUERY: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_VIEW_FIND_LOCATION_BY_QUERY;

  private readonly URL_TERRITORIES_API_EXT = localStorageModule().config.routes.API_TERRITORIES;

  async findTerritoriesByQueryParams(query: string): Promise<Territories[]> {
    return await http.get<Territories[]>(this.API_VIEW_FIND_LOCATION_BY_QUERY + query);
  }

  async searchTerritoryByQueryDescription(query: string): Promise<Territories[]> {
    return await http.get<Territories[]>(process.env.VUE_APP_API_TERRITORIES + this.URL_TERRITORIES_API_EXT + query);
  }
}
