
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { SaveEmailTypeConfig } from '@/settings/application/uses_cases/emailTypeConfig/create/SaveEmailTypeConfig';
import { SearchEmailTypeConfig } from '@/settings/application/uses_cases/emailTypeConfig/search/SearchEmailTypeConfig';
import { CreateFileV2 } from '@/settings/application/uses_cases/file/create/CreateFileV2';
import { FindFile } from '@/settings/application/uses_cases/file/search/FindFile';
import { EmailType } from '@/settings/domain/emailType/EmailType';
import { EmailTypeConfig } from '@/settings/domain/emailTypeConfig/EmailTypeConfig';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'EmailTypeCard',
  components: {}
})
export default class EmailTypeCard extends Vue {
  @Inject(TYPES.SAVE_FILE)
  readonly createFile!: CreateFileV2;
  @Inject(TYPES.FIND_FILE_BY_NAME)
  readonly findFile!: FindFile;
  @Inject(TYPES.EMAIL_TYPE_CONFIG_SAVE)
  readonly saveEmailConfig!: SaveEmailTypeConfig;
  @Inject(TYPES.EMAIL_TYPE_CONFIG_FIND_BY_COMPANY_AND_TYPE)
  readonly findEmailConfig!: SearchEmailTypeConfig;

  @Prop() emailType!: EmailType;

  form: EmailTypeConfig = new EmailTypeConfig();
  companyId: string = localStorage.getItem('businessId') as string;
  emailTypeConfig!: EmailTypeConfig;

  $refs!: {
    fileInput: HTMLInputElement;
  };

  isLoading = false;

  mounted() {
    this.getAllData();
  }

  getAllData() {
    this.getHTMLFile();
    this.getEmailTypeConfig();
  }

  async saveAllConfig() {
    try {
      const res = await this.uploadHTML();
      await this.saveConfig(res);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async uploadHTML() {
    try {
      if (this.$refs.fileInput?.files?.length === 0) {
        throw new Error('No file selected');
      }

      if (this.$refs.fileInput.files?.[0].type !== 'text/html') {
        throw new Error('Invalid file type');
      }

      if (this.$refs.fileInput.files?.[0].size > 1000000) {
        throw new Error('File size is too big');
      }

      if (!this.$refs.fileInput.files?.[0]) {
        throw new Error('No file selected');
      }

      const file = this.$refs.fileInput.files?.[0];

      const fileNewName = `emailtemplates_${this.emailType.type.toLowerCase().replaceAll('_', '')}.html`;

      const newFile = new File([file], fileNewName, { type: file.type });

      const response = await this.createFile.execute({
        files: [newFile]
      });

      this.isLoading = false;

      if (!Array.isArray(response)) {
        return response;
      }

      return response[0];
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async saveConfig(fileName: string) {
    try {
      this.isLoading = true;
      this.form.file = fileName;
      this.form.type = this.emailType.type;
      this.form.company = this.companyId;
      await this.saveEmailConfig.execute(this.form);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
      this.getAllData();
    }
  }

  getHTMLFile() {
    try {
      this.isLoading = true;
      // this.findFile.execute(`${this.companyId}_custom_${this.emailType.type.toLowerCase()}.html`);
      // await this.findFile.execute(`${this.companyId}_custom_${this.emailType.type.toLowerCase()}.html`);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async getEmailTypeConfig() {
    try {
      this.isLoading = true;
      const res = await this.findEmailConfig.execute({ company: this.companyId, type: this.emailType.type });
      this.emailTypeConfig = res;
      this.emailTypeConfig.file = this.findFile.execute(res.file);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }
}
