import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StockRepository } from '@/wms/domain/stock/StockRepository';
import { StockCalculatedValues } from '@/courier/domain/DocsLin/DocsLin';

/**
 * @class FindHistoryMovementsStock
 * @description Clase que contiene la lógica de negocio para buscar el historial de movimientos de inventario
 * @version 1.0.0
 * @Author EnriquePallares
 */
@Injectable()
export class FindHistoryMovementsStock {
  public constructor(
    @Inject(TYPES.STOCK_REPOSITORY)
    private readonly repository: StockRepository
  ) {}

  public async execute(
    fMonth: string,
    iMonth: string,
    storeId: number,
    year: string,
    stock: number,
    sku?: string
  ): Promise<StockCalculatedValues[]> {
    return await this.repository.findHistoryMovements(fMonth, iMonth, storeId, year, stock, sku);
  }
}
