
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { CreateCourierPriceList } from '@/settings/application/uses_cases/courierPriceList/create/CreateCourierPriceList';
import { UpdateCourierPriceList } from '@/settings/application/uses_cases/courierPriceList/update/UpdateCourierPriceList';
import { DeleteCourierPriceList } from '@/settings/application/uses_cases/courierPriceList/delete/DeleteCourierPriceList';
import { FindAllCourierPriceList } from '@/settings/application/uses_cases/courierPriceList/search/FindAllCourierPriceList';
import { FindByIdCourierPriceList } from '@/settings/application/uses_cases/courierPriceList/search/FindByIdCourierPriceList';
import { FindByStatusCourierPriceList } from '@/settings/application/uses_cases/courierPriceList/search/FindByStatusCourierPriceList';
import { CourierPriceList } from '@/settings/domain/courierPriceList/CourierPriceList';
import { Taxe } from '@/settings/domain/taxe/Taxe';
import { TaxeFindByStatus } from '@/settings/application/uses_cases/taxe/search/TaxeFindByStatus';
import { Weight } from '@/settings/domain/weight/Weight';
import { WeightFindAll } from '@/settings/application/uses_cases/weight/search/WeightFindAll';
import { Transport } from '@/tracking/domain/typetransport/Transport';
import { TransportFindAll } from '@/tracking/application/uses_cases/typetransport/search/findall/TransportFindAll';
import { UnitOfMeasurement } from '@/settings/domain/unitofmeasurement/UnitOfMeasurement';
import { GroupReferences } from '@/wms/domain/category/groupReferences/GroupReferences';
import { GroupReferenceFindById } from '@/wms/application/category/groupReferences/search/GroupReferenceFindById';
import { GroupReferenceFindAllTree } from '@/wms/application/category/groupReferences/search/GroupReferenceFindAllTree';
import { References } from '@/wms/domain/catalogs/references/References';
import { ReferencesPriceListValue } from '@/settings/domain/courierPriceList/ReferencePriceListValue';
import { ReferenceFindAll } from '@/wms/application/catalogs/references/search/ReferenceFindAll';
import { VolumeFindAll } from '@/settings/application/uses_cases/volume/search/VolumeFindAll';
import { Volume } from '@/settings/domain/volume/Volume';

@Component({ name: 'PriceListN', components: { CustomTableN } })
export default class PriceListN extends Vue {
  @Inject(TYPES.COURIER_PRICE_LIST_SAVE)
  readonly savePriceList!: CreateCourierPriceList;
  @Inject(TYPES.COURIER_PRICE_LIST_UPDATE)
  readonly updatePriceList!: UpdateCourierPriceList;
  @Inject(TYPES.COURIER_PRICE_LIST_DELETE)
  readonly deletePriceList!: DeleteCourierPriceList;
  @Inject(TYPES.COURIER_PRICE_LIST_FIND_ALL)
  readonly findAllPriceList!: FindAllCourierPriceList;
  @Inject(TYPES.COURIER_PRICE_LIST_FIND_BY_ID)
  readonly findByIdPriceList!: FindByIdCourierPriceList;
  @Inject(TYPES.COURIER_PRICE_LIST_FIND_BY_STATUS)
  readonly findByStatusPriceList!: FindByStatusCourierPriceList;
  @Inject(TYPES.FINDALL_TRANSPORT)
  readonly findAllTypeTransport!: TransportFindAll;
  @Inject(TYPES.FINDBYSTATUS_TAXE)
  readonly taxFindByStatus!: TaxeFindByStatus;
  @Inject(TYPES.FINDALL_WEIGHT)
  readonly weightFindAll!: WeightFindAll;
  @Inject(TYPES.FINDALL_VOLUME)
  readonly volumeFindAll!: VolumeFindAll;
  @Inject(TYPES.FINDALL_GROUP_TREE_REFERENCE)
  readonly groupReferenceFindAllTree!: GroupReferenceFindAllTree;
  @Inject(TYPES.FINDBYID_GROUP_REFERENCE)
  readonly findByIdGroupReference!: GroupReferenceFindById;
  // @Inject(TYPES.FINDALL_CATEGORY_REFERENCE_SERVICE)
  // readonly findAllReferences!: ReferenceFindAllServices;
  @Inject(TYPES.FINDALL_REFERENCE)
  readonly findAllReferences!: ReferenceFindAll;

  form = new CourierPriceList();
  priceListItems: CourierPriceList[] = [];
  transportList: Transport[] = [];
  taxesList: Taxe[] = [];
  weightList: Weight[] = [];
  volumeList: Volume[] = [];
  unitMeasurementList: any = [];
  categoryList: GroupReferences[] = [];
  referenceList: References[] = [];
  referenceListSelected: References | null = null;
  isLoading = false;
  isEdit = false;

  mounted() {
    this.findAll();
    this.transportFindAll();
    this.taxesFindAll();
    this.findUnitMeasurements();
    this.findServiceReferences();
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  translateCol(colName: string) {
    return this.$i18n.t('general.' + colName);
  }

  get unitMeasurement() {
    return this.form.weightUnit ?? this.form.volumeUnit;
  }

  set unitMeasurement(newValue: Volume | Weight | null) {
    if (!newValue) this.unitMeasurement = null;
    const isVolume = this.volumeList.some(element => element === newValue);
    if (isVolume) {
      this.form.volumeUnit = newValue;
      this.form.weightUnit = null;
    } else {
      this.form.weightUnit = newValue;
      this.form.volumeUnit = null;
    }
  }

  get isVolume() {
    return this.form.weightUnit ? false : true;
  }

  get computedReferences() {
    // Filtramos aquellas referencias que sean de servicios y que no hayan sido seleccionadas en el formulario
    return this.referenceList.filter(
      reference => reference.noManageStock && !this.form.references.some(ref => ref.reference?.code === reference.code)
    );
  }

  get fields() {
    return [
      { field: 'id', label: this.translateCol('id') },
      { field: 'description', label: this.translateCol('description') },
      {
        field: 'weightUnit',
        label: this.translateCol('weightUnit'),
        formatFn: (value: UnitOfMeasurement) => value?.description ?? '-'
      },
      {
        field: 'volumeUnit',
        label: this.translateCol('volumeUnit'),
        formatFn: (value: UnitOfMeasurement) => value?.description ?? '-'
      },
      { field: 'unitValue', label: this.translateCol('unitValue') },
      { field: 'minRate', label: this.translateCol('minRate') },
      {
        field: 'typeTransport',
        label: this.translateCol('serviceType'),
        formatFn: (value: Transport) => this.renderTransport(value) || ''
      },
      { field: 'factor', label: this.translateCol('palletsFactor') },
      {
        field: 'reportedIn',
        label: this.translateCol('reportedin'),
        formatFn: (value: UnitOfMeasurement) => value?.description ?? ''
      },
      {
        field: 'taxes',
        label: this.translateCol('OherChargesAndtaxes'),
        formatFn: (value: Taxe[]) => (value.length ? value.map(taxe => taxe?.description).join(', ') : '-')
      },
      {
        field: 'active',
        label: this.translateCol('active'),
        formatFn: (value: boolean) => (value ? this.$t('general.yes') : this.$t('general.no'))
      },
      { field: 'actions', label: this.translateCol('actions'), sortable: false }
    ];
  }

  tableActions = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: (row: CourierPriceList) => this.preparePriceListEdit(row)
      },
      {
        title: `${this.$t('general.delete')}`,
        icon: 'fa fa-trash',
        variant: 'danger',
        action: (row: CourierPriceList) => this.delete(row)
      }
    ]
  };

  preparePriceListEdit(row: CourierPriceList) {
    this.isEdit = true;
    this.form = Object.assign({}, row);
  }

  async save() {
    try {
      this.isLoading = true;
      const payload = this.form;
      const res = await this.savePriceList.internalExecute(payload);
      if (!('error' in res)) {
        this.resetForm();
        return res;
      }
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
      this.findAll();
    }
  }

  async update() {
    try {
      this.isLoading = true;
      const payload = this.form;
      const res = await this.updatePriceList.internalExecute(payload);
      if (!('error' in res)) {
        this.resetForm();
        return res;
      }
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
      this.isEdit = false;
      this.findAll();
    }
  }

  async delete(item: CourierPriceList) {
    try {
      this.isLoading = true;
      const res = await this.deletePriceList.internalExecute(item);
      if (!('error' in res)) {
        return res;
      }
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
      this.findAll();
    }
  }

  async findAll() {
    const res = await this.findAllPriceList.internalExecute();
    this.priceListItems = res;
  }

  async transportFindAll() {
    const res = await this.findAllTypeTransport.execute();
    this.transportList = res;
  }

  async taxesFindAll() {
    try {
      const res = await this.taxFindByStatus.execute(true);
      this.taxesList = res;
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async weightFindAllList() {
    const res = await this.weightFindAll.execute();
    this.weightList = res;
  }

  async volumeFindAllList() {
    const res = await this.volumeFindAll.execute();
    this.volumeList = res;
  }

  async findUnitMeasurements() {
    await this.weightFindAllList();
    await this.volumeFindAllList();
    this.unitMeasurementList = [...this.volumeList, ...this.weightList];
  }

  submitForm() {
    if (this.isEdit) {
      this.update();
    } else {
      this.save();
    }
  }

  resetForm() {
    this.form = new CourierPriceList();
    this.isEdit = false;
  }

  factorEnabledValue(reference: ReferencesPriceListValue) {
    if (!reference.applyFactorConversion) {
      reference.factor = 0;
      reference.reportedIn = null;
    }
    return reference.factor;
  }

  async findAllGroupReference() {
    const res = await this.groupReferenceFindAllTree.execute();
    this.categoryList = res;
  }

  renderTransport(option: Transport) {
    if (!['Air', 'Ground', 'Ocean'].includes(option.name)) {
      return this.$t('general.' + option.name);
    }
    return `${this.$t('general.transport')} ${this.$t(`general.${option.name?.toLowerCase()}`)}`;
  }

  addReference() {
    this.form.references.push(new ReferencesPriceListValue());
  }

  async findServiceReferences() {
    try {
      this.isLoading = true;
      const res = await this.findAllReferences.execute();
      this.referenceList = res;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }
}
