import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { ClassReferences } from '@/wms/domain/catalogs/classReferences/ClassReferences';
import { ClassReferencesRepository } from '@/wms/domain/catalogs/classReferences/ClassReferencesRepository';

@Injectable()
export class FindAllClassReferences extends Query<Promise<ClassReferences[]>> {
  public constructor(
    @Inject(TYPES.CLASS_REFERENCE_REPOSITORY)
    private readonly classReferencesRepository: ClassReferencesRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(): Promise<ClassReferences[]> {
    const dataClassReferences = await this.classReferencesRepository.findAll();
    this.stateManager.patch({ dataClassReferences });
    return this.stateManager.state.dataClassReferences;
  }
}
