import { Injectable } from '@/core/di/Injectable';
import { Query } from '@/core/cqrs/Query';
import { House } from '@/courier/domain/House/House';
import { TYPES } from '@/core/config/Types';
import { HouseRepository } from '@/courier/domain/House/HouseRepository';
import { Inject } from '@/core/di/Inject';

@Injectable()
export class FindHouseByEntityAndMaster extends Query<
  Promise<House[]>,
  { entityCode: number; trackingNumber: string }
> {
  public constructor(
    @Inject(TYPES.HOUSE_REPOSITORY)
    private readonly repository: HouseRepository
  ) {
    super();
  }

  public async internalExecute(payload: { entityCode: number; trackingNumber: string }): Promise<House[]> {
    return await this.repository.findByEntityAndMaster(payload.entityCode, payload.trackingNumber);
  }
}
