import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { EmailType } from '@/settings/domain/emailType/EmailType';
import { EmailTypeRepository } from '@/settings/domain/emailType/EmailTypeRepository';

@Injectable()
export class EmailTypeFindAll extends Query<Promise<EmailType[]>> {
  public constructor(
    @Inject(TYPES.EMAIL_TYPE_REPOSITORY)
    private readonly repository: EmailTypeRepository
  ) {
    super();
  }
  async internalExecute(): Promise<EmailType[]> {
    return await this.repository.findAll();
  }
}
