import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { CostCenter } from '@/courier/domain/costCenter/CostCenter';
import { CostCenterRepository } from '@/courier/domain/costCenter/CostCenterRepository';

@Injectable()
export class FindAllCostCenter extends Query<Promise<CostCenter[]>, null> {
  public constructor(
    @Inject(TYPES.COST_CENTER_REPOSITORY) // Inyecta el repositorio de centros de costo
    private readonly repository: CostCenterRepository // Instancia del repositorio de centros de costo
  ) {
    super();
  }

  public async internalExecute(): Promise<CostCenter[]> {
    // Ejecuta el caso de uso
    return await this.repository.findAll();
  }
}
