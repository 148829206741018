import { PackingUnit } from '../../packingUnit/PackingUnit';

export class Packaging {
  codeRef: string;
  id: number;
  isGeneral: boolean;
  isPuschase: boolean;
  isSale: boolean;
  numberUnits: number;
  supplierCode: any = [];
  unit: PackingUnit | null;
  volume: number;
  weight: number;
  width: number;
  height: number;
  large: number;

  public constructor() {
    this.codeRef = '';
    this.id = 0;
    this.isGeneral = false;
    this.isPuschase = false;
    this.isSale = false;
    this.numberUnits = 0;
    this.unit = null;
    this.volume = 0;
    this.weight = 0;
    this.width = 0;
    this.height = 0;
    this.large = 0;
  }
}
