
import Component from 'vue-class-component';
import Vue from 'vue';
import { ZoneL } from '@/wms/domain/layout/zone/ZoneL';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { CreateZoneL } from '@/wms/application/layout/zone/create/CreateZoneL';
import { Prop } from 'vue-property-decorator';
import { UpdateZoneL } from '@/wms/application/layout/zone/update/UpdateZoneL';

@Component({
  name: 'LayoutZone'
})
export default class LayoutZone extends Vue {
  @Inject(TYPES.CREATE_LAYOUT_ZONE)
  readonly createZone!: CreateZoneL;
  @Inject(TYPES.UPDATE_LAYOUT_ZONE)
  readonly updateZone!: UpdateZoneL;
  //form register or update

  form: ZoneL = new ZoneL();
  isEdit = false;

  @Prop({ type: Function, required: true }) readonly findZones!: any;
  @Prop() readonly zones!: ZoneL[];

  //methods

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  saveZoneLayout() {
    this.createZone.execute(this.form).then(() => {
      this.findZones();
    });
  }

  factory() {
    this.isEdit ? this.update() : this.saveZoneLayout();
  }

  update() {
    this.updateZone.execute(this.form).then(() => {
      this.findZones();
    });
  }

  find() {
    const findZone =
      this.zones.filter(item => {
        if (item.zoneId.toLowerCase() === this.form.zoneId.toLowerCase()) {
          this.isEdit = true;
        }
        return item.zoneId.toLowerCase() === this.form.zoneId.toLowerCase();
      })[0] || this.form;

    this.form = Object.assign({}, findZone);
  }

  clearForm() {
    this.form = new ZoneL();
    this.isEdit = false;
  }
}
