import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { PricingEngineParamRepository } from '@/wms/domain/catalogs/pricingEngineParam/PricingEngineParamRepository';
import { PricingEngineParamList } from '@/wms/domain/catalogs/pricingEngineParam/PricingEngineParamList';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class PricingEngineParamService implements PricingEngineParamRepository {
  private readonly API_BASE_URI: string = localStorageModule().wms.host.API_BASE_URI;
  private readonly API_PRICING_ENGINE_FIND: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_PRICING_ENGINE_FIND;

  async findByTypeParams(typeParams: any): Promise<PricingEngineParamList[]> {
    try {
      return await http.get<PricingEngineParamList[]>(this.API_PRICING_ENGINE_FIND + typeParams);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
