
import Vue from 'vue';
import Component from 'vue-class-component';
import ListDocTransactional from '@/wms/infrastructure/ui/docs/ListDocTransactional.vue';
import { Order } from '@/courier/domain/order/Order';
import { currencyFormatter } from '@/core/formatter/CurrencyFormatter';

@Component({
  name: 'TypeDocumentSupplierQuotation',
  components: { ListDocTransactional }
})
export default class TypeDocumentSupplierQuotation extends Vue {
  get fields() {
    return [
      { field: 'number', label: `#`, sortable: true },
      { field: 'typeOrder', label: this.translateCol('typeOrder'), sortable: true },
      { field: 'concept', label: this.translateCol('concept'), sortable: true },
      { field: 'date', label: this.translateCol('date'), sortable: true },
      { field: 'customerName', label: this.translateCol('entity'), sortable: true },
      { field: 'warehouseName', label: this.translateCol('warehouse'), sortable: true },
      {
        field: 'total',
        label: this.$t('general.total'),
        sortable: true,
        formatFn: (value: any) => currencyFormatter.format({ value: value, currency: 'USD' })
      },
      { field: 'actions', label: this.translateCol('actions'), sortable: false }
    ];
  }
  items: Order[] = [];
  isLoading = false;
  fullPage = true;

  refreshItems(object: Order) {
    this.items.push(object);
  }

  translateCol(colName: any) {
    return this.$i18n.t('general.' + colName);
  }
}
