import { Switches } from '../switches/Switches';
import { MonetaryTransaction } from '../monetarytransaction/MonetaryTransaction';
import { Novelty } from '../novelty/Novelty';
import { EventTransactional } from '../eventsTransactional/EventTransactional';
import { BillOfLanding } from '@/wms/domain/billOfLanding/BillOfLanding';
import { DocumentHeader } from '@/courier/domain/DocumentsV2/Documents_V2';
import { ConceptsContainer } from '@/settings/domain/containers/conceptsContainer/ConceptsContainer';

export class ActivityTransactional {
  event: EventTransactional | null;
  description: string;
  sw: string;
  aswitch: Switches | null;
  processType: number;
  isNew: boolean;
  activityId: number;
  required: boolean;
  projectedDate: string;
  realDate: string;
  value: number;
  realValue: number;
  spreadAutomatically: boolean;
  monetaryTransaction: MonetaryTransaction | null;
  nature: string;
  processTypeName: string;
  active: boolean;
  createAt: string;
  updateAt: string;
  novelties: Novelty[];
  sequence: number;
  sendNotification: boolean;
  executed: boolean;
  files: string[];
  executedAt: string;
  notes: string;
  billOfLanding: BillOfLanding | null;
  airWayBill: BillOfLanding | null;
  documentHeader: DocumentHeader | null;
  containerInspectionList: ConceptsContainer[] | null;
  freightInspectionList: ConceptsContainer[] | null;

  public constructor() {
    this.event = null;
    this.description = '';
    this.sw = '';
    this.aswitch = null;
    this.processType = 0;
    this.isNew = false;
    this.activityId = 0;
    this.required = false;
    this.projectedDate = '';
    this.realDate = '';
    this.value = 0;
    this.realValue = 0;
    this.spreadAutomatically = false;
    this.monetaryTransaction = null;
    this.nature = '';
    this.processTypeName = '';
    this.active = true;
    this.createAt = '';
    this.updateAt = '';
    this.novelties = [];
    this.sequence = 0;
    this.sendNotification = false;
    this.executed = false;
    this.files = [];
    this.executedAt = '';
    this.notes = '';
    this.billOfLanding = null;
    this.airWayBill = null;
    this.documentHeader = null;
    this.containerInspectionList = null;
    this.freightInspectionList = null;
  }
}
