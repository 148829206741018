
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'FilterCustomize'
})
export default class AppFilterCustomize extends Vue {
  //Data
  private description = '';
  private selected: any = [];
  private itemSelected: any = [];
  private countryList: any = [];
  private stateList: any = [];
  private itemStateList = [] as any;
  private itemBoolean = false;
  //Prop
  @Prop() private readonly itemCountry!: any;
  @Prop() private itemState!: any;
  @Prop() private readonly typeDescription!: string;
  @Prop() private paramsFilter = false;
  //Refs
  $refs!: {
    dropdown: HTMLFormElement;
  };
  //Hook
  mounted() {
    if (this.typeDescription === 'state' || this.typeDescription === 'zone') {
      this.itemBoolean = true;
    }
  }
  //Methods
  filterData() {
    if (this.typeDescription === 'state') {
      this.$emit('filterData', this.countryList.code, this.itemSelected.status);
      this.$refs.dropdown.hide(true);
    } else if (this.typeDescription === 'zone') {
      this.$emit('filterData', this.countryList.code, this.stateList.code, this.itemSelected.status);
      this.$refs.dropdown.hide(true);
    } else if (this.typeDescription === 'country') {
      this.$emit('filterData', this.itemSelected.status);
      this.$refs.dropdown.hide(true);
    } else if (this.typeDescription === 'currency') {
      this.$emit('filterData', this.description.toUpperCase());
      this.$refs.dropdown.hide(true);
    }
  }
  filterDataByDescription() {
    if (this.typeDescription === 'state') {
      this.$emit('filterDataByDescription', this.description);
      this.$refs.dropdown.hide(true);
    } else if (this.typeDescription === 'zone') {
      this.$emit('filterDataByDescription', this.description);
      this.$refs.dropdown.hide(true);
    } else if (this.typeDescription === 'country') {
      this.$emit('filterDataByDescription', this.description);
      this.$refs.dropdown.hide(true);
    }
  }
  filterStates() {
    this.itemBoolean = false;
    const hash: any = {};
    this.itemStateList = [];
    const arrayZone = this.itemState.filter((item: any) => (hash[item.state] ? false : (hash[item.state] = true)));
    for (const item of arrayZone) {
      if (item.country === this.countryList.code) {
        this.itemStateList.push({ code: item.state, name: item.stateDescription });
      }
    }
  }
  clearForm() {
    this.itemSelected = [];
    this.selected = [];
    this.countryList = [];
    this.stateList = [];
    this.itemBoolean = false;
    this.description = '';
    if (this.selected.id === '001') {
      this.filterData();
    } else {
      this.filterDataByDescription();
    }
  }
}
