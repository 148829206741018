import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Query } from '@/core/cqrs/Query';
import { TYPES } from '@/core/config/Types';
import { User } from '@/settings/domain/user/User';
import { UserRepository } from '@/settings/domain/user/UserRepository';

@Injectable()
export class UserFindByEmail extends Query<Promise<User>, string> {
  public constructor(
    @Inject(TYPES.USER_REPOSITORY)
    private readonly userRepository: UserRepository
  ) {
    super();
  }

  async internalExecute(email: string): Promise<User> {
    return await this.userRepository.findByEmail(email);
  }
}
