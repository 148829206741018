import { AddressLine } from '@/settings/domain/address/AddressLine';
import { Currency } from '@/settings/domain/currency/Currency';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { Incoterms } from '@/settings/domain/ParmsGeneral/Incoterms/Incoterms';
import { TypeOperation } from '@/settings/domain/typeOperation/TypeOperation';
import { Transport } from '@/tracking/domain/typetransport/Transport';
import { WmsOrderLine } from '../orderline/WmsOrderLine';
import { WmsfOrderCategory } from '../WmsfOrderCategory/WmsfOrderCategory';

export class WmsQuotationOrder {
  additionalServices: WmsfOrderCategory[];
  approvalDate: string;
  arrivalDate: string;
  boxesAirTransport: boolean;
  boxesGroundTransport: boolean;
  boxesOceanTransport: boolean;
  categories: WmsfOrderCategory[];
  containerGroundTransport: boolean;
  containerOceanTransport: boolean;
  currency: Currency | null;
  customer: Entity;
  createAt: string;
  date: string;
  departureDate: string;
  destinationAddress: AddressLine;
  dueDate: string;
  exchangeRate: number | string;
  frequency: string;
  incorterms: Incoterms | null;
  lines: WmsOrderLine[];
  mainServices: WmsfOrderCategory[];
  master: string;
  module: string;
  naviera: Entity | null;
  notes: string;
  number: number;
  originAddress: AddressLine;
  physicalWeight: number;
  quotationDate: string;
  registerAccount: boolean;
  requestDate: string;
  state: string;
  tariffItem: string;
  thirdParty: Entity | null;
  total: number;
  transitTime: string;
  typeOperation: number;
  typeOperationData: TypeOperation | null;
  typeOrder: string;
  typeTransport: Transport | null;
  volumeM3: number;

  public constructor() {
    this.number = 0;
    this.typeOperation = 1;
    this.typeOperationData = null;
    this.lines = [];
    this.containerGroundTransport = false;
    this.containerOceanTransport = false;
    this.boxesOceanTransport = false;
    this.boxesGroundTransport = false;
    this.boxesAirTransport = false;
    this.originAddress = new AddressLine();
    this.destinationAddress = new AddressLine();
    this.additionalServices = [];
    this.mainServices = [];
    this.registerAccount = false;
    this.customer = new Entity();
    this.typeOrder = '';
    this.notes = '';
    this.module = '';
    this.categories = [];
    this.exchangeRate = '0';
    this.state = 'active';
    this.incorterms = null;
    this.currency = null;
    this.volumeM3 = 0;
    this.physicalWeight = 0;
    this.exchangeRate = 0;
    this.naviera = null;
    this.frequency = '';
    this.transitTime = '';
    this.tariffItem = '';
    this.currency = null;
    this.total = 0;
    this.date = '';
    this.requestDate = '';
    this.approvalDate = '';
    this.master = '';
    this.typeTransport = null;
    this.thirdParty = null;
    this.arrivalDate = '';
    this.departureDate = '';
    this.dueDate = '';
    this.quotationDate = '';
    this.createAt = '';
  }
}
