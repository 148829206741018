import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Command } from '@/core/cqrs/Command';
import { TYPES } from '@/core/config/Types';
import { RoleRepository } from '@/settings/domain/role/RoleRepository';
import { RoleDTO } from '@/settings/domain/role/RoleDTO';

@Injectable()
export class CreateRoleV2 extends Command<Promise<void>, RoleDTO> {
  public constructor(@Inject(TYPES.ROLE_REPOSITORY) private readonly roleRepository: RoleRepository) {
    super();
  }
  internalExecute(role: RoleDTO): Promise<void> {
    return this.roleRepository.createRoleV2(role);
  }
}
