
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
@Component({
  name: 'UpdateSalePrices',
  components: { CustomTableN }
})
export default class UpdateSalePrices extends Vue {
  @Prop() readonly category!: string;
  @Prop() readonly groupReferences!: any[];
  @Prop() readonly updateReference!: any;
  @Prop() readonly objGroupId!: any[];
  @Prop() readonly findGroupbyId!: Function;

  isLoading = false;
  payloads: any[] = [];

  get tableFields() {
    return [
      {
        field: 'code',
        label: `${this.$t('general.code')}`
      },
      {
        field: 'filterBrand',
        label: `${this.$t('general.productName')}`
      },
      {
        field: 'standardCost',
        label: `${this.$t('general.unitCost')}`
      },
      {
        field: 'msrp',
        label: `${this.$t('general.msrp')}`
      },
      {
        field: 'formInput',
        label: `${this.$t('general.newSalePrice')}`
      }
    ];
  }

  inputForm = {
    label: `${this.$t('general.msrp')}`,
    rules: 'decimal:3',
    id: 'msrp',
    vModel: 'msrp',
    type: 'text',
    placeholder: `${this.$t('general.newSalePrice')}`,
    onChange: (event: any, index: number) => (this.groupReferences[index].msrp = event)
  };

  async handleUpdate(bvModalEvent: any) {
    bvModalEvent.preventDefault();
    try {
      this.isLoading = true;
      this.groupReferences.map((item: any) =>
        this.payloads.push(this.updateReference.execute({ ...item, groups: this.objGroupId }))
      );
      if (this.payloads.length > 0) {
        await Promise.all(this.payloads);
      }
      await this.findGroupbyId();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
}
