import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { BillOfLandingRepository } from '@/wms/domain/billOfLanding/BillOfLandingRepository';

@Injectable()
export class CreateBillOfLandingReport extends Command<
  Promise<string>,
  {
    serial: string;
    isMaster: boolean;
    masterBL: string | null;
  }
> {
  public constructor(
    @Inject(TYPES.BILL_OF_LANDING_REPOSITORY)
    private readonly repository: BillOfLandingRepository
  ) {
    super();
  }

  public internalExecute(payload: { serial: string; isMaster: boolean; masterBL: string | null }): Promise<string> {
    return this.repository.createReport(payload);
  }
}
