import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { ConsolidatedRepository } from '@/wms/domain/consolidated/ConsolidatedRepository';

@Injectable()
export class FindConsolidatedNumbersByDateRange extends Command<
  Promise<number[]>,
  {
    dateFrom: string;
    dateTo: string;
    carrier: number | undefined;
    consignee: string | null;
  }
> {
  public constructor(
    @Inject(TYPES.CONSOLIDATED_REPOSITORY)
    private readonly repository: ConsolidatedRepository
  ) {
    super();
  }

  public internalExecute({
    dateFrom,
    dateTo,
    carrier,
    consignee
  }: {
    dateFrom: string;
    dateTo: string;
    carrier: number | undefined;
    consignee: string | null;
  }): Promise<number[]> {
    return this.repository.findByDateRange({ dateFrom, dateTo, carrier, consignee });
  }
}
