import { Company } from '../company/Company';

export class MailServerConfig {
  id: number;
  company: Company;
  host: string;
  port: string;
  sslPort: string;
  email: string;
  emailPassword: string;
  protocol: string | null;

  public constructor() {
    this.id = 0;
    this.company = new Company();
    this.host = '';
    this.port = '';
    this.sslPort = '';
    this.email = '';
    this.emailPassword = '';
    this.protocol = null;
  }
}
