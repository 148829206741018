import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { References } from '@/wms/domain/catalogs/references/References';
import { ReferencesRepository } from '@/wms/domain/catalogs/references/ReferencesRepository';

@Injectable()
export class ReferenceFindAll extends Query<Promise<References[]>> {
  public constructor(
    @Inject(TYPES.REFERENCE_REPOSITORY)
    private readonly referencesRepository: ReferencesRepository,

    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(): Promise<References[]> {
    const dataReferences = await this.referencesRepository.findAll();
    this.stateManager.patch({ dataReferences });
    return this.stateManager.state.dataReferences;
  }
}
