import { StandardPrice } from '@/wms/domain/catalogs/pricingEngine/SettingStandardPrice/StandardPrice';

export class SettingCustomerStandard {
  public customers: any;
  public standardPrice: StandardPrice;
  constructor() {
    this.customers = [];
    this.standardPrice = new StandardPrice();
  }
}
