import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { ShippingMethodRepository } from '@/settings/domain/shippingMethod/ShippingMethodRepository';
import { ShippingMethod } from '@/settings/domain/shippingMethod/ShippingMethod';

@Injectable()
export class UpdateShippingMethod extends Command<Promise<ShippingMethod>, ShippingMethod> {
  public constructor(
    @Inject(TYPES.SHIPPING_METHOD_REPOSITORY)
    private readonly repository: ShippingMethodRepository
  ) {
    super();
  }

  internalExecute(dto: ShippingMethod): Promise<ShippingMethod> {
    return this.repository.update(dto);
  }
}
