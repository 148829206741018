import { localStorageModule } from '@/core/config/ConfigurationServer';
import { http } from '@/core/http/HttpFetch';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import { CommodityTypeRepository } from '@/freight/domain/commodity_type/CommodityTypeRepository';
import { injectable } from 'inversify-props';

@injectable()
export class CommodityTypeService implements CommodityTypeRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_COMMODITY_TYPE_FIND_ALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_COMMODITY_FIND_ALL;
  private readonly API_COMMODITY_TYPE_SAVE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_COMMODITY_SAVE;
  private readonly API_COMMODITY_TYPE_UPDATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_COMMODITY_UPDATE;
  private readonly API_COMMODITY_TYPE_FIND_BY_ID: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_COMMODITY_FIND_BY_ID;
  private readonly API_COMMODITY_TYPE_FIND_BY_CODE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_COMMODITY_FIND_BY_CODE;
  private readonly API_COMMODITY_TYPE_FIND_BY_STATUS: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_COMMODITY_FIND_BY_STATUS;
  private readonly API_COMMODITY_DELETE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_COMMODITY_DELETE;

  async save(commodityType: CommodityType) {
    return await http.post<CommodityType>(this.API_COMMODITY_TYPE_SAVE, JSON.stringify(commodityType));
  }
  async update(commodityType: CommodityType) {
    return await http.put<CommodityType>(this.API_COMMODITY_TYPE_UPDATE, JSON.stringify(commodityType));
  }

  async findCommodityTypeByCode(query: string) {
    return await http.get<CommodityType[]>(this.API_COMMODITY_TYPE_FIND_BY_CODE + query);
  }
  async findCommodityTypeById(query: string) {
    return await http.get<CommodityType[]>(this.API_COMMODITY_TYPE_FIND_BY_ID + query);
  }
  async findCommodityTypeByStatus(query: boolean) {
    return await http.get<CommodityType[]>(this.API_COMMODITY_TYPE_FIND_BY_STATUS + query);
  }
  async findAll() {
    return await http.get<CommodityType[]>(this.API_COMMODITY_TYPE_FIND_ALL);
  }
  async delete(commodityType: CommodityType) {
    try {
      return await http.delete<CommodityType>(this.API_COMMODITY_DELETE, JSON.stringify(commodityType));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
