import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { UserRepository } from '@/settings/domain/user/UserRepository';

@Injectable()
export class ConfirmToken extends Command<Promise<any>, string> {
  public constructor(
    @Inject(TYPES.USER_REPOSITORY)
    private readonly userRepository: UserRepository
  ) {
    super();
  }

  async internalExecute(token: string): Promise<void> {
    return await this.userRepository.acceptInvitation(token);
  }
}
