import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { GammaRepository } from '@/wms/domain/gamma/GammaRepository';
import { Gamma } from '@/wms/domain/gamma/Gamma';

@Injectable()
export default class GammaServices implements GammaRepository {
  private readonly API_BASE_URI: string = localStorageModule().wms.host.API_BASE_URI;
  private readonly API_GAMMA_SAVE: string = this.API_BASE_URI + localStorageModule().wms.routes.API_GAMMA;
  private readonly API_GAMMA_DELETE: string = this.API_BASE_URI + localStorageModule().wms.routes.API_GAMMA;
  private readonly API_GAMMA_FINDALL: string = this.API_BASE_URI + localStorageModule().wms.routes.API_GAMMA;

  async saveGamma(dto: Gamma): Promise<Gamma> {
    try {
      return await http.post(this.API_GAMMA_SAVE, JSON.stringify(dto));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAllGamma(): Promise<Gamma[]> {
    try {
      return await http.get(this.API_GAMMA_FINDALL);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async deleteGamma(dto: Gamma): Promise<Gamma> {
    try {
      return await http.delete(this.API_GAMMA_DELETE, JSON.stringify(dto));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async updateGamma(dto: Gamma): Promise<Gamma> {
    try {
      return await http._put(this.API_GAMMA_SAVE, JSON.stringify(dto));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
