import { render, staticRenderFns } from "./CreateThird.vue?vue&type=template&id=fa9185cc&scoped=true"
import script from "./CreateThird.vue?vue&type=script&lang=ts"
export * from "./CreateThird.vue?vue&type=script&lang=ts"
import style0 from "./CreateThird.vue?vue&type=style&index=0&id=fa9185cc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa9185cc",
  null
  
)

export default component.exports