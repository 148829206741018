import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { Country } from '@/settings/domain/country/Country';
import { CountryRepository } from '@/settings/domain/country/CountryRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class CountryServices implements CountryRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_TERRITORIES_BASE: string = process.env.VUE_APP_API_TERRITORIES ?? '';
  private readonly API_COUNTRIES_FINDALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_COUNTRIES_FINDALL;
  private readonly API_COUNTRIES_FINDALL_BY_STATUS: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_COUNTRIES_FINDALL_BY_STATUS;
  private readonly API_COUNTRIES_SAVE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_COUNTRIES_SAVE;
  private readonly API_COUNTRIES_UPDATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_COUNTRIES_UPDATE;
  private readonly API_COUNTRIES_FINDALL_BY_STATUSANDCOUNTRY: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_COUNTRIES_FINDALL_BY_STATUSANDCOUNTRY;
  private readonly API_COUNTRIES_FINDALL_BY_DESCRIPTION_LIKE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_COUNTRIES_FINDALL_BY_DESCRIPTION_LIKE;
  private readonly API_COUNTRIES_FINDALL_BY_DESCRIPTION_LIKE_IN_GENERAL_TERRITORIES: string =
    this.API_TERRITORIES_BASE + localStorageModule().config.routes.API_FIND_COUNTRIES_BY_QUERY;

  async create(country: Country) {
    try {
      return await http.post<Country[]>(this.API_COUNTRIES_SAVE, JSON.stringify(country));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(country: Country) {
    try {
      return await http.put<Country[]>(this.API_COUNTRIES_UPDATE, JSON.stringify(country));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findCountryByStatus(status: boolean): Promise<Country[]> {
    try {
      return await http.get<Country[]>(this.API_COUNTRIES_FINDALL_BY_STATUS + status);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findByDescriptionLike(description: string): Promise<Country[]> {
    try {
      return await http.get<Country[]>(this.API_COUNTRIES_FINDALL_BY_DESCRIPTION_LIKE + description);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findAll(): Promise<Country[]> {
    try {
      return await http.get<Country[]>(this.API_COUNTRIES_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findCountryByStatusCountry(status: boolean): Promise<Country[]> {
    try {
      return await http.get<Country[]>(this.API_COUNTRIES_FINDALL_BY_STATUSANDCOUNTRY + status);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async findByDescriptionLikeInGeneralTerritories(description: string): Promise<Country[]> {
    try {
      return await http.get<Country[]>(
        this.API_COUNTRIES_FINDALL_BY_DESCRIPTION_LIKE_IN_GENERAL_TERRITORIES + description
      );
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
