import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { CommodityClassRepository } from '@/settings/domain/commodityClass/CommodityClassRepository';
import { CommodityClass } from '@/settings/domain/commodityClass/CommodityClass';
import { http } from '@/core/http/HttpFetch';

@Injectable()
export class CommodityClassService implements CommodityClassRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_COMMODITY_CLASS_SAVE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_COMMODITY_CLASS_SAVE;

  private readonly API_COMMODITY_CLASS_UPDATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_COMMODITY_CLASS_UPDATE;

  private readonly API_COMMODITY_CLASS_DELETE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_COMMODITY_CLASS_DELETE;

  private readonly API_COMMODITY_CLASS_FINDALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_COMMODITY_CLASS_FINDALL;

  private readonly API_COMMODITY_CLASS_FIND_BY_ID: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_COMMODITY_CLASS_FIND_BY_ID;

  private readonly API_COMMODITY_CLASS_FIND_BY_STATUS: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_COMMODITY_CLASS_FIND_BY_STATUS;

  async save(dto: CommodityClass): Promise<CommodityClass> {
    try {
      return await http.post<CommodityClass>(this.API_COMMODITY_CLASS_SAVE, JSON.stringify(dto));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(dto: CommodityClass): Promise<any> {
    try {
      return await http.put<CommodityClass>(this.API_COMMODITY_CLASS_UPDATE, JSON.stringify(dto));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async delete(dto: CommodityClass): Promise<CommodityClass> {
    try {
      return await http.delete<CommodityClass>(this.API_COMMODITY_CLASS_DELETE, JSON.stringify(dto));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findByCode(code: string): Promise<CommodityClass> {
    try {
      return await http.get<CommodityClass>(`${this.API_COMMODITY_CLASS_FIND_BY_ID}${code}`);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findAll(): Promise<CommodityClass[]> {
    try {
      return await http.get<CommodityClass[]>(this.API_COMMODITY_CLASS_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findByStatus(status: boolean): Promise<CommodityClass[]> {
    try {
      return await http.get<CommodityClass[]>(`${this.API_COMMODITY_CLASS_FIND_BY_STATUS}${status}`);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
