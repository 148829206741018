import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { RoleRepository } from '@/settings/domain/role/RoleRepository';
import { RoleDTO } from '@/settings/domain/role/RoleDTO';

@Injectable()
export class RoleDelete extends Query<Promise<void>, RoleDTO> {
  public constructor(
    @Inject(TYPES.ROLE_REPOSITORY)
    private readonly roleRepository: RoleRepository
  ) {
    super();
  }

  async internalExecute(role: RoleDTO): Promise<void> {
    return await this.roleRepository.delete(role);
  }
}
