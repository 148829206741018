import { Injectable } from '@/core/di/Injectable';
import { TypeDocumentRepository } from '@/settings/domain/TypeDocument/TypeDocumentRepository';
import { TypeDocument } from '@/settings/domain/TypeDocument/TypeDocument';
import { http } from '@/core/http/HttpFetch';
import { localStorageModule } from '@/core/config/ConfigurationServer';

/**
 * @name TypeDocumentService
 * @description Genera la clase para el servicio de tipo de documento que hace peticiones al API
 * @implements TypeDocumentRepository
 * @author San7iix
 */
@Injectable()
export class TypeDocumentService implements TypeDocumentRepository {
  /**
   * @name API_BASE_URI
   * @description Dirección base del API
   * @type {string}
   */
  private readonly API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;

  /**
   * @name API_DOCUMENT_TYPES_SAVE
   * @description Dirección del API para guardar un tipo de documento
   * @type {string}
   */
  private readonly API_DOCUMENT_TYPES_SAVE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_DOCUMENT_TYPES_SAVE;

  /**
   * @name API_DOCUMENT_TYPES_UPDATE
   * @description Dirección del API para actualizar un tipo de documento
   * @type {string}
   */
  private readonly API_DOCUMENT_TYPES_UPDATE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_DOCUMENT_TYPES_UPDATE;

  /**
   * @name API_DOCUMENT_TYPES_DELETE
   * @description Dirección del API para eliminar un tipo de documento
   * @type {string}
   */
  private readonly API_DOCUMENT_TYPES_DELETE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_DOCUMENT_TYPES_DELETE;

  /**
   * @name API_DOCUMENT_TYPES_FIND_BY_TYPE
   * @description Dirección del API para obtener un tipo de documento por su tipo
   * @type {string}
   */
  private readonly API_DOCUMENT_TYPES_FIND_BY_TYPE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_DOCUMENT_TYPES_FIND_BY_TYPE;

  /**
   * @name API_DOCUMENT_TYPES_FIND_ALL
   * @description Dirección del API para obtener todos los tipos de documentos
   * @type {string}
   */
  private readonly API_DOCUMENT_TYPES_FIND_ALL: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_DOCUMENT_TYPES_FIND_ALL;

  /**
   *
   * @param typeDocument
   * @returns El tipo de documento guardado
   * @throws Error al guardar el tipo de documento
   * @description Guarda el tipo de documento
   * @Author San7iix
   */
  async save(typeDocument: TypeDocument): Promise<TypeDocument> {
    try {
      return await http.post(this.API_DOCUMENT_TYPES_SAVE, typeDocument);
    } catch (error) {
      throw new Error('Error al guardar el tipo de documento');
    }
  }

  /**
   *
   * @param typeDocument
   * @returns El tipo de documento actualizado
   * @throws Error al actualizar el tipo de documento
   * @description Actualiza el tipo de documento
   * @Author San7iix
   */
  async update(typeDocument: TypeDocument): Promise<TypeDocument> {
    try {
      return await http._put(this.API_DOCUMENT_TYPES_UPDATE, typeDocument);
    } catch (error) {
      throw new Error('Error al actualizar el tipo de documento');
    }
  }

  /**
   *
   * @param typeDocument
   * @returns El tipo de documento eliminado
   * @throws Error al eliminar el tipo de documento
   * @description Elimina el tipo de documento
   * @Author San7iix
   */
  async delete(typeDocument: TypeDocument): Promise<TypeDocument> {
    try {
      return await http.delete(this.API_DOCUMENT_TYPES_DELETE, typeDocument);
    } catch (error) {
      throw new Error('Error al eliminar el tipo de documento');
    }
  }

  /**
   *
   * @param id
   * @returns El tipo de documento encontrado
   * @throws Error al buscar el tipo de documento
   * @description Busca el tipo de documento por su id
   * @Author San7iix
   */
  async findByType(id: string): Promise<TypeDocument> {
    try {
      return await http.get(`${this.API_DOCUMENT_TYPES_FIND_BY_TYPE}type=${id}`);
    } catch (error) {
      throw new Error('Error al buscar el tipo de documento');
    }
  }

  /**
   *
   * @returns Array de tipos de documento
   * @throws Error al buscar los tipos de documento
   * @description Busca todos los tipos de documento
   * @Author San7iix
   */
  async findAll(): Promise<TypeDocument[]> {
    try {
      return await http.get(this.API_DOCUMENT_TYPES_FIND_ALL);
    } catch (error) {
      throw new Error(`Error al buscar los tipos de documento: ${error}`);
    }
  }
}
