import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Command } from '@/core/cqrs/Command';
import { Routes } from '@/settings/domain/routes/Routes';
import { RoutesRepository } from '@/settings/domain/routes/RoutesRepository';

@Injectable()
export class CreateRoutes extends Command<Promise<any>, Routes> {
  public constructor(
    @Inject(TYPES.ROUTES_REPOSITORY)
    private readonly routesRepository: RoutesRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  internalExecute(routes: Routes): Promise<any> {
    return this.routesRepository.create(routes);
  }
}
