export class TypeOperation {
  code: string;
  createAt: string;
  description: string;
  processType: number;
  processTypeName: string;
  transactionType: number;
  status: boolean;
  updateAt: string;

  public constructor() {
    this.code = '';
    this.createAt = '';
    this.description = '';
    this.processType = 1;
    this.processTypeName = '';
    this.transactionType = 0;
    this.status = true;
    this.updateAt = '';
  }
}
