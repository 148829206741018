import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { ZoneLRepository } from '@/wms/domain/layout/zone/ZoneLRepository';
import { ZoneL } from '@/wms/domain/layout/zone/ZoneL';

@Injectable()
export class ZoneLServices implements ZoneLRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_ZONEWMS_FINDALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ZONEWMS_FINDALL;
  private readonly API_ZONEWMS_SAVE: string = this.API_BASE_URI + localStorageModule().config.routes.API_ZONEWMS_SAVE;
  private readonly API_ZONEWMS_DELETE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ZONEWMS_DELETE;
  private readonly API_ZONEWMS_UPDATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ZONEWMS_UPDATE;
  private readonly API_ZONEWMS_FIND_NOT_CONFIGURED: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ZONEWMS_FIND_NOT_CONFIGURED;

  async create(zone: ZoneL) {
    try {
      await http.post<ZoneL[]>(this.API_ZONEWMS_SAVE, JSON.stringify(zone));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async delete(zone: ZoneL): Promise<ZoneL> {
    try {
      return await http.delete<ZoneL>(this.API_ZONEWMS_DELETE, JSON.stringify(zone));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(zone: ZoneL) {
    try {
      await http.put<ZoneL[]>(this.API_ZONEWMS_UPDATE, JSON.stringify(zone));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findAll(): Promise<ZoneL[]> {
    try {
      return await http.get<ZoneL[]>(this.API_ZONEWMS_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async findNotConfigured(): Promise<ZoneL[]> {
    try {
      return await http.get<ZoneL[]>(this.API_ZONEWMS_FIND_NOT_CONFIGURED);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  findById(): Promise<ZoneL[]> {
    throw new Error('Method not implemented.');
  }

  findAllByStatus(): Promise<ZoneL[]> {
    throw new Error('Method not implemented.');
  }
}
