import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { CommodityTypeRepository } from '@/freight/domain/commodity_type/CommodityTypeRepository';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';

@Injectable()
export class FindCommodityTypeByStatus extends Query<Promise<CommodityType[]>, boolean> {
  public constructor(
    @Inject(TYPES.COMMODITY_TYPE_REPOSITORY)
    private readonly commodityTypeRepository: CommodityTypeRepository,
    @Inject(TYPES.STATE_MANAGER) private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(status: boolean): Promise<CommodityType[]> {
    const dataCommodityTypes = await this.commodityTypeRepository.findCommodityTypeByStatus(status);
    this.stateManager.patch({ dataCommodityTypes });
    return this.stateManager.state.dataCommodityTypes;
  }
}
