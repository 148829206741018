export class Product {
  Referencia: string;
  Nombre: string;
  Descripcion?: string;
  PrecioCompra?: number;
  PrecioVenta?: number;
  Utilidad?: number;
  Descuento?: number;
  Existencias?: number;
  ExistenciasMinima?: number;
  ExistenciasMaxima?: number;
  CantidadUnidadesMedida?: number;
  UnidadesSobrantes?: number;
  EsReceta?: boolean;
  IdReceta?: number | null;
  ImagenUrl?: string | null;
  IdBodega?: number;
  IdMarca?: number;
  IdCategoria?: number;
  IdMedida?: number;
  IdTipoUtilidad?: number;
  IdPUC?: number;
  IdEstadoProducto?: number;
  Habilitado?: boolean;
  IdGrupo?: number | null;
  Venta?: boolean;
  Compra?: boolean;
  PUC?: any;
  Grupo?: any;
  Bodega?: any;
  EstadoProducto?: any;
  Categoria?: any;
  Marca?: any;
  Medida?: any;
  TipoUtilidad?: any;
  DetalleFactura?: any;
  ImpuestoProductoTipoPersona?: any[];
  ProductoFuente?: any[];
  ProductoGrupo?: any[];
  Cantidad?: number;
  PrecioFinal?: number;
  ImageFile?: any;
  IdPUCVentas?: number;
  IdPUCCompras?: number;
  IdPUCTerceros?: number;
  PrecioCSInicialUnidad?: number;
  IdProveedor?: number;
  FechaSaldoInicial?: Date;
  CostoSInicial?: number;
  ManejaAIU?: boolean;
  EsAIU?: boolean;
  ProductoImportado?: boolean;

  public constructor() {
    this.Referencia = '';
    this.Nombre = '';
    this.Descripcion = '';
    this.PrecioCompra = 0;
    this.PrecioVenta = 0;
    this.Utilidad = 0;
    this.Descuento = 0;
    this.Existencias = 0;
    this.ExistenciasMinima = 0;
    this.ExistenciasMaxima = 0;
    this.CantidadUnidadesMedida = 0;
    this.UnidadesSobrantes = 0;
    this.EsReceta = false;
    this.IdReceta = null;
    this.ImagenUrl = null;
    this.IdBodega = 1;
    this.IdMarca = 1;
    this.IdCategoria = 1;
    this.IdMedida = 1;
    this.IdTipoUtilidad = 1;
    this.IdPUC = 1;
    this.IdEstadoProducto = 1;
    this.Habilitado = true;
    this.IdGrupo = null;
    this.Venta = true;
    this.Compra = true;
    this.IdPUCVentas = 1;
    this.IdPUCCompras = 1;
    this.IdPUCTerceros = 1;
    this.PUC = null;
    this.Grupo = null;
    this.Bodega = null;
    this.EstadoProducto = null;
    this.Categoria = {
      Id: 0,
      Nombre: null,
      Descripcion: null,
      Habilitado: false,
      Insumo: null,
      ActivoFijo: null,
      Servicio: null,
      Intermedio: null,
      Producto: null
    };
    this.Marca = null;
    this.Medida = null;
    this.TipoUtilidad = null;
    this.DetalleFactura = null;
    this.ImpuestoProductoTipoPersona = [];
    this.ProductoFuente = [];
    this.ProductoGrupo = [];
    this.Cantidad = 0;
    this.PrecioFinal = 0;
    this.ImageFile = null;
    this.PrecioCSInicialUnidad = 0;
    this.IdProveedor = 1;
    this.FechaSaldoInicial = new Date();
    this.CostoSInicial = 0;
    this.ManejaAIU = false;
    this.EsAIU = false;
    this.ProductoImportado = true;
  }
}
