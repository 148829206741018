import { CalcResponse } from './CalcResponse';

export class RateResponse {
  status: boolean;
  errorMessage: string;
  data: Array<CalcResponse>;

  public constructor() {
    this.status = true;
    this.errorMessage = '';
    this.data = [];
  }
}
