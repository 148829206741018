import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Currency } from '@/settings/domain/currency/Currency';
import { CurrencyRepository } from '@/settings/domain/currency/CurrencyRepository';
import { Command } from '@/core/cqrs/Command';

@Injectable()
export class CreateCurrency extends Command<Promise<any>, Currency> {
  public constructor(
    @Inject(TYPES.CURRENCY_REPOSITORY)
    private readonly currencyRepository: CurrencyRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  internalExecute(currency: Currency): Promise<any> {
    return this.currencyRepository.create(currency);
  }
}
