
import Component from 'vue-class-component';
import Vue from 'vue';
import { TypeCustomer } from '@/settings/domain/entity/typeCustomer/TypeCustomer';
import { CreateTypeCustomer } from '@/settings/application/uses_cases/parmsGeneral/typeCustomer/create/CreateTypeCustomer';
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';

@Component({
  name: 'EntityList'
})
export default class CreateEntityType extends Vue {
  //INJECTS
  @Inject(TYPES.CREATE_TYPE_CUSTOMER)
  readonly createTypeCustomer!: CreateTypeCustomer;
  //DATA
  form = new TypeCustomer();
  isLoading = false;
  //GETTERS
  get types() {
    return [
      { text: `${this.$t('general.supplier')}`, value: 'SUPPLIER', selected: true },
      { text: `${this.$t('general.customer')}`, value: 'CUSTOMER' },
      { text: `${this.$t('general.serviceProvider')}`, value: 'SERVICE' },
      { text: `${this.$t('general.others')}`, value: 'OTHERS' }
    ];
  }
  //METHODS

  async save() {
    try {
      this.isLoading = true;
      await this.createTypeCustomer.execute(this.form);
      this.clearForm();
      this.isLoading = false;
      this.$emit('update');
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  clearForm() {
    this.form = new TypeCustomer();
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
}
