import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Command } from '@/core/cqrs/Command';
import { TYPES } from '@/core/config/Types';
import { UserRepository } from '@/settings/domain/user/UserRepository';

@Injectable()
export class UserAssignRole extends Command<Promise<void>, { email: string; role: number; namespace: string }> {
  public constructor(
    @Inject(TYPES.USER_REPOSITORY)
    private readonly userRepository: UserRepository
  ) {
    super();
  }

  internalExecute(payload: { email: string; role: number; namespace: string }): Promise<void> {
    return this.userRepository.editRoles(payload);
  }
}
