
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
@Component({
  name: 'PricingZoneDetails'
})
export default class PricingZoneDetails extends Vue {
  //Props
  @Prop() readonly fields!: any;
  @Prop() readonly items!: any;
  @Prop() readonly actions!: any;
  @Prop() readonly zonePricingLevel!: string;
}
