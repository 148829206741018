
import Vue from 'vue';
import Component from 'vue-class-component';
import { OrderNovelty } from '@//tracking/domain/orderNovelty/OrderNovelty';
import { Inject } from '@/core/di/Inject';
import { CreateOrderNovelty } from '@/tracking/application/uses_cases/orderNovelty/create/CreateOrderNovelty';
import { TYPES } from '@/core/config/Types';
import { Prop } from 'vue-property-decorator';
import * as moment from 'moment';
import { DeleteOrderNovelty } from '@/tracking/application/uses_cases/orderNovelty/delete/DeleteOrderNovelty';
import { WmsQuotationOrderForTable } from '@/courier/domain/wmsQuotationOrder/WmsQuotationOrderForTable';
@Component({
  name: 'NoveltyList'
})
export default class NoveltyList extends Vue {
  @Inject(TYPES.ORDER_NOVELTY_CREATE) createOrderNovelty!: CreateOrderNovelty;
  @Inject(TYPES.ORDER_NOVELTY_DELETE) deleteOrderNovelty!: DeleteOrderNovelty;

  @Prop({ required: true }) selectedQuotation!: WmsQuotationOrderForTable;
  @Prop({ required: true }) findData!: () => Promise<void>;
  @Prop({ required: true, default: () => [] }) novelties!: OrderNovelty[];

  isOpen = false;
  noveltyText = '';
  dueDate = new Date();
  isLoading = false;

  async handleAddNovelty() {
    try {
      if (!this.noveltyText || !this.dueDate) {
        this.$toast.error('Please fill all fields');
        return;
      }

      this.isLoading = true;
      const newNovelty = new OrderNovelty();

      newNovelty.description = this.noveltyText;
      newNovelty.dateToReturnToClient = this.dueDate;
      newNovelty.orderNumber = this.selectedQuotation.number;
      newNovelty.orderType = this.selectedQuotation.type;

      await this.createOrderNovelty.execute(newNovelty);
      await this.findData();
      this.noveltyText = '';
      this.dueDate = new Date();
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async handleDeleteNovelty(id: string) {
    try {
      this.isLoading = true;
      await this.deleteOrderNovelty.internalExecute(id);
      this.findData();
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  get moment() {
    return moment;
  }
}
