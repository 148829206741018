import { Link } from './Link';
import { Injectable } from '@/core/di/Injectable';

@Injectable()
export class EmptyLink implements Link {
  setNext(): Link {
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  next(): void {}
}
