class TransactionalEventForTraceability {
  eventId: number;
  sequence: number;
  description: string;
  ready: boolean;
  state: string;

  constructor() {
    this.eventId = 0;
    this.sequence = 0;
    this.description = '';
    this.ready = false;
    this.state = '';
  }
}

export default TransactionalEventForTraceability;
