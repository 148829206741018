import { CloudConfig } from '@/settings/domain/cloudConfig/CloudConfig';
import { Query } from '@/core/cqrs/Query';
import { CloudConfigRepository } from '@/settings/domain/cloudConfig/CloudConfigRepository';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { TYPES } from '@/core/config/Types';

@Injectable()
export class FindByCompanyIdCloudConfig extends Query<Promise<CloudConfig[]>, number> {
  public constructor(
    @Inject(TYPES.CLOUD_CONFIG_REPOSITORY)
    private readonly cloudConfigRepository: CloudConfigRepository
  ) {
    super();
  }
  internalExecute(companyId: number): Promise<CloudConfig[]> {
    return this.cloudConfigRepository.findByCompanyId(companyId);
  }
}
