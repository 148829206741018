import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Command } from '@/core/cqrs/Command';
import { Warehouse } from '@/settings/domain/warehouse/Warehouse';
import { WarehouseRepository } from '@/settings/domain/warehouse/WarehouseRepository';

@Injectable()
export class CreateWarehouse extends Command<Promise<any>, Warehouse> {
  public constructor(
    @Inject(TYPES.WAREHOUSE_REPOSITORY)
    private readonly warehouseRepository: WarehouseRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  internalExecute(warehouse: Warehouse): Promise<any> {
    return this.warehouseRepository.create(warehouse);
  }
}
