import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';

import { Query } from '@/core/cqrs/Query';
import { Store } from '@/wms/domain/layout/store/Store';
import { StoreRepository } from '@/wms/domain/layout/store/StoreRepository';

@Injectable()
export class FindAllStoreByUser extends Query<Promise<Store[]>, string> {
  public constructor(
    @Inject(TYPES.STORE_REPOSITORY)
    private readonly storeRepository: StoreRepository
  ) {
    super();
  }

  async internalExecute(user: string): Promise<Store[]> {
    try {
      return await this.storeRepository.findAllStorebyUser(user);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
