
import Vue from 'vue';
import Component from 'vue-class-component';
import vueFilePond from 'vue-filepond';
import CreateBankC from '@/settings/infrastructure/ui/components/bank/CreateBankC.vue';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import EntityList from '@/settings/infrastructure/ui/components/entity/EntityList.vue';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { CreatePaymentMethods } from '@/settings/application/uses_cases/parmsGeneral/paymentMethods/create/CreatePaymentMethods';
import { PaymentMethodsFindAll } from '@/settings/application/uses_cases/parmsGeneral/paymentMethods/search/PaymentMethodsFindAll';
import { CreateGroupAgentService } from '@/settings/application/uses_cases/parmsGeneral/groupAgentService/create/CreateGroupAgentService';
import { GroupAgentServiceFindAll } from '@/settings/application/uses_cases/parmsGeneral/groupAgentService/search/GroupAgentServiceFindAll';
import { BankFindAll } from '@/settings/application/uses_cases/parmsGeneral/bank/search/BankFindAll';
import { TermsPaymentFindAll } from '@/settings/application/uses_cases/parmsGeneral/termsPayment/search/TermsPaymentFindAll';
import { CreateCurrency } from '@/settings/application/uses_cases/currency/create/CreateCurrency';
import { CurrencyFindAll } from '@/settings/application/uses_cases/currency/search/CurrencyFindAll';
import { CreateIncoterms } from '@/settings/application/uses_cases/parmsGeneral/incoterms/create/CreateIncoterms';
import { IncotermsFindAll } from '@/settings/application/uses_cases/parmsGeneral/incoterms/search/IncotermsFindAll';
import { TypeCustomerFindAll } from '@/settings/application/uses_cases/parmsGeneral/typeCustomer/search/TypeCustomerFindAll';
import { TypeIdentificationFindAll } from '@/settings/application/uses_cases/parmsGeneral/typeIdentificacion/search/TypeIdentificationFindAll';
import { CreateTypeIdentification } from '@/settings/application/uses_cases/parmsGeneral/typeIdentificacion/create/CreateTypeIdentification';
import { CreateTermsPayment } from '@/settings/application/uses_cases/parmsGeneral/termsPayment/create/CreateTermsPayment';
import { Territories } from '@/settings/domain/views/territories/Territories';
import { CreateEntity } from '@/settings/application/uses_cases/entity/create/CreateEntity';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { ValidationObserver } from 'vee-validate';
import { showAlert } from '@/core/plugins/Notifications';
import { CreateTypeCustomer } from '@/settings/application/uses_cases/parmsGeneral/typeCustomer/create/CreateTypeCustomer';
import { SearchTerritoryByQueryDescription } from '@/settings/application/uses_cases/views/territories/SearchTerritoryByQueryDescription';
import { Contact } from '@/settings/domain/ParmsGeneral/contact/Contact';
import { TypeContactFindAll } from '@/settings/application/uses_cases/parmsGeneral/typeContact/search/TypeContactFindAll';
import { CreateTypeContact } from '@/settings/application/uses_cases/parmsGeneral/typeContact/create/CreateTypeContact';
import { StateManager } from '@/core/statemanager/StateManager';
import { Bank } from '@/settings/domain/ParmsGeneral/bank/Bank';
import { AddressLine } from '@/settings/domain/address/AddressLine';
import { TypeAddressFindAll } from '@/settings/application/uses_cases/parmsGeneral/typeAddress/search/TypeAddressFindAll';
import { CreateTypeAddress } from '@/settings/application/uses_cases/parmsGeneral/typeAddress/create/CreateTypeAddress';
import { EntityFindFilter } from '@/settings/application/uses_cases/entity/search/EntityFindFilter';
import { EntityMaxCode } from '@/settings/application/uses_cases/entity/search/EntityMaxCode';
import { EntityFindById } from '@/settings/application/uses_cases/entity/search/EntityFindById';
import { EntityDeleteContact } from '@/settings/application/uses_cases/entity/delete/EntityDeleteContact';
import { EntityDeleteAddress } from '@/settings/application/uses_cases/entity/delete/EntityDeleteAddress';
import SearchTerritories from '@/core/components/shared/SearchTerritories.vue';
import CreateEntityType from './EntityType/CreateEntityType.vue';
import { TypeCustomer } from '@/settings/domain/entity/typeCustomer/TypeCustomer';
import { Person } from '@/settings/domain/person/Person';
import { CreatePerson } from '@/settings/application/uses_cases/person/create/CreatePerson';
import { PersonFindByDocument } from '@/settings/application/uses_cases/person/search/PersonFindByDocument';
import { UpdatePerson } from '@/settings/application/uses_cases/person/update/UpdatePerson';
import { TypeIdentification } from '@/settings/domain/entity/typeIdentification/TypeIdentification';
import { EntityTypeIdentification } from '@/settings/domain/entity/entityTypeIdentification/EntityTypeIdentification';
import { CourierPriceList } from '@/settings/domain/courierPriceList/CourierPriceList';
import { FindAllCourierPriceList } from '@/settings/application/uses_cases/courierPriceList/search/FindAllCourierPriceList';
import { DeleteTypeIdentification } from '@/settings/application/uses_cases/parmsGeneral/typeIdentificacion/delete/TypeIdentificationDelete';
import EditDynamicElement from '@/settings/infrastructure/ui/components/entity/editDynamicRecords/EditRecordModal.vue';
import { UpdatePaymentMethods } from '@/settings/application/uses_cases/parmsGeneral/paymentMethods/update/UpdatePaymentMethods';
import { PaymentMethods } from '@/settings/domain/ParmsGeneral/paymentMethods/PaymentMethods';
import { UpdateTypeIdentification } from '@/settings/application/uses_cases/parmsGeneral/typeIdentificacion/update/UpdateTypeIdentification';
import { UpdateGroupAgentService } from '@/settings/application/uses_cases/parmsGeneral/groupAgentService/update/UpdateGroupAgentService';
import { GroupAgentService } from '@/settings/domain/ParmsGeneral/groupAgentService/GroupAgentService';
import { UpdateTypeContact } from '@/settings/application/uses_cases/parmsGeneral/typeContact/update/UpdateTypeContact';
import { TypeContact } from '@/settings/domain/ParmsGeneral/typeContact/TypeContact';
import { UpdateIncoterms } from '@/settings/application/uses_cases/parmsGeneral/incoterms/update/UpdateIncoterms';
import { Incoterms } from '@/settings/domain/ParmsGeneral/Incoterms/Incoterms';
import { TermsPayment } from '@/settings/domain/ParmsGeneral/termsPayment/TermsPayment';
import { UpdateTermsPayment } from '@/settings/application/uses_cases/parmsGeneral/termsPayment/update/UpdateTermsPayment';
import { TypeAddress } from '@/settings/domain/ParmsGeneral/typeAddress/TypeAddress';
import { UpdateTypeAddress } from '@/settings/application/uses_cases/parmsGeneral/typeAddress/update/UpdateTypeAddress';
import { DeletePaymentMethods } from '@/settings/application/uses_cases/parmsGeneral/paymentMethods/delete/DeletePaymentMethods';
import { DeleteGroupAgentService } from '@/settings/application/uses_cases/parmsGeneral/groupAgentService/delete/DeleteGroupAgentService';
import { DeleteTypeContact } from '@/settings/application/uses_cases/parmsGeneral/typeContact/delete/DeleteTypeContact';
import { DeleteIncoterms } from '@/settings/application/uses_cases/parmsGeneral/incoterms/delete/DeleteIncoterms';
import { DeleteTermsPayment } from '@/settings/application/uses_cases/parmsGeneral/termsPayment/delete/DeleteTermsPayment';
import { DeleteTypeAddress } from '@/settings/application/uses_cases/parmsGeneral/typeAddress/delete/DeleteTypeAddress';

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

@Component({
  name: 'ThirdParty',
  components: {
    CreateBankC,
    FilePond,
    EntityList,
    SearchTerritories,
    CreateEntityType,
    EditDynamicElement
  }
})
export default class ThirdParty extends Vue {
  @Inject(TYPES.FINDALL_TYPE_CUSTOMER)
  readonly typeCustomerFindAll!: TypeCustomerFindAll;
  @Inject(TYPES.FINDALL_TYPE_IDENTIFICATION)
  readonly typeIdentificationFindAll!: TypeIdentificationFindAll;
  @Inject(TYPES.CREATE_TYPE_IDENTIFICATION)
  readonly typeIdentificationCreate!: CreateTypeIdentification;
  @Inject(TYPES.DELETE_TYPE_IDENTIFICATION)
  readonly typeIdentificationDelete!: DeleteTypeIdentification;
  @Inject(TYPES.UPDATE_TYPE_IDENTIFICATION)
  readonly updateTypeIdentification!: UpdateTypeIdentification;
  @Inject(TYPES.FINDALL_TYPE_CONTACT)
  readonly typeContactFindAll!: TypeContactFindAll;
  @Inject(TYPES.CREATE_TYPE_CONTACT)
  readonly createTypeContact!: CreateTypeContact;
  @Inject(TYPES.UPDATE_TYPE_CONTACT)
  readonly typeContactUpdate!: UpdateTypeContact;
  @Inject(TYPES.DELETE_TYPE_CONTACT)
  readonly typeContactDelete!: DeleteTypeContact;
  @Inject(TYPES.API_VIEW_FIND_TERRITORIES_BY_QUERY)
  readonly searchTerritoriesByQueryParameter!: SearchTerritoryByQueryDescription;
  @Inject(TYPES.FINDALL_INCOTERMS)
  readonly incotermsFindAll!: IncotermsFindAll;
  @Inject(TYPES.CREATE_INCOTERMS)
  readonly createIncoterms!: CreateIncoterms;
  @Inject(TYPES.UPDATE_INCOTERMS)
  readonly incotermsUpdate!: UpdateIncoterms;
  @Inject(TYPES.DELETE_INCOTERMS)
  readonly incotermsDelete!: DeleteIncoterms;
  @Inject(TYPES.FINDALL_CURRENCY)
  readonly currencyFindAll!: CurrencyFindAll;
  @Inject(TYPES.CREATE_CURRENCY)
  readonly createCurrency!: CreateCurrency;
  @Inject(TYPES.FINDALL_TERMS_PAYMENT)
  readonly termsPaymentFindAll!: TermsPaymentFindAll;
  @Inject(TYPES.CREATE_TERMS_PAYMENT)
  readonly createTermsPayment!: CreateTermsPayment;
  @Inject(TYPES.UPDATE_TERMS_PAYMENT)
  readonly termsPaymentUpdate!: UpdateTermsPayment;
  @Inject(TYPES.DELETE_TERMS_PAYMENT)
  readonly termsPaymentDelete!: DeleteTermsPayment;
  @Inject(TYPES.FINDALL_BANK)
  readonly bankFindAll!: BankFindAll;
  @Inject(TYPES.FINDALL_GROUP_AGENT_SERVICE)
  readonly groupAgentServiceFindAll!: GroupAgentServiceFindAll;
  @Inject(TYPES.CREATE_GROUP_AGENT_SERVICE)
  readonly createGroupAgentService!: CreateGroupAgentService;
  @Inject(TYPES.UPDATE_GROUP_AGENT_SERVICE)
  readonly updateGroupAgentService!: UpdateGroupAgentService;
  @Inject(TYPES.DELETE_GROUP_AGENT_SERVICE)
  readonly deleteGroupAgentService!: DeleteGroupAgentService;
  @Inject(TYPES.FINDALL_PAYMENT_METHODS)
  readonly paymentMethodsFindAll!: PaymentMethodsFindAll;
  @Inject(TYPES.CREATE_PAYMENT_METHODS)
  readonly createPaymentMethods!: CreatePaymentMethods;
  @Inject(TYPES.API_ENTITY_SAVE)
  readonly createEntity!: CreateEntity;
  @Inject(TYPES.CREATE_TYPE_CUSTOMER)
  readonly createTypeCustomer!: CreateTypeCustomer;
  @Inject(TYPES.FINDALL_TYPE_ADDRESS)
  readonly typeAddressFindAll!: TypeAddressFindAll;
  @Inject(TYPES.CREATE_TYPE_ADDRESS)
  readonly createTypeAddress!: CreateTypeAddress;
  @Inject(TYPES.UPDATE_TYPE_ADDRESS)
  readonly typeAddressUpdate!: UpdateTypeAddress;
  @Inject(TYPES.DELETE_TYPE_ADDRESS)
  readonly typeAddressDelete!: DeleteTypeAddress;
  @Inject(TYPES.STATE_MANAGER)
  readonly stateManager!: StateManager;
  @Inject(TYPES.ENTITY_FIND_BY_DESCRIPTION_LIKE)
  readonly findAllCustomersLike!: EntityFindFilter;
  @Inject(TYPES.ENTITY_MAX_CODE)
  readonly entityMaxCode!: EntityMaxCode;
  @Inject(TYPES.API_ENTITY_FIND_BY_ID)
  readonly entityFindById!: EntityFindById;
  @Inject(TYPES.API_ENTITY_DELETE_CONTACT)
  readonly entityDeleteContact!: EntityDeleteContact;
  @Inject(TYPES.API_ENTITY_DELETE_ADDRESS)
  readonly entityDeleteAddress!: EntityDeleteAddress;
  @Inject(TYPES.CREATE_PERSON)
  readonly createPerson!: CreatePerson;
  @Inject(TYPES.UPDATE_PERSON)
  readonly updatePerson!: UpdatePerson;
  @Inject(TYPES.FIND_PERSON_BY_DOCUMENT)
  readonly personFindByDocument!: PersonFindByDocument;
  @Inject(TYPES.COURIER_PRICE_LIST_FIND_ALL)
  readonly findAllPriceList!: FindAllCourierPriceList;
  @Inject(TYPES.UPDATE_PAYMENT_METHODS)
  readonly updatePaymentMethods!: UpdatePaymentMethods;
  @Inject(TYPES.DELETE_PAYMENT_METHODS)
  readonly deletePaymentMethods!: DeletePaymentMethods;

  //Data
  maxCodeEntiti = 1;
  formEntityUpdate: Entity = new Entity();
  isEdit = false;
  formEntity: Entity = new Entity();
  person: Person = new Person();
  formEntityaddress = {};
  isloading = false;
  priceListItems: CourierPriceList[] = [];
  typeTercero = [];
  City = [];
  selectCity = [];
  selectCityBank = [];
  Region = [];
  selectRegion = [];
  Country = [];
  selectCountry = [];
  typeContacted = [];
  selectTypeContacted = [];
  aditionalAddress = '';
  ZipCode = [];
  selectZipCode = [];
  incoterms = [];
  purchase = [];
  banks = [];
  serviceAgent = [];
  termsPayment = [];
  termsPaymentTwo = [];
  paymentMethods = [];
  typeAddress = [];
  groupThereParty = [];
  selectedGroupThereParty = [];
  zones = [];
  selectedZone = [];
  placePerformance = '';
  territories: Territories[] = [];
  selectTerritorie: Territories | null = null;
  territorieBk: Territories | null = null;
  isLoading = false;
  customersList = [];
  vari = 0;
  updateEntityB = false;
  identificationsArray: TypeIdentification[] = [];
  title = `${this.$t('general.confirmDeleteAll')}`;
  descriptions = `${this.$t('general.areyousuretodeletethis')}, ${this.$t('general.notUndoable')}`;

  $refs!: {
    form_wizard: HTMLFormElement;
    obs1: InstanceType<typeof ValidationObserver>;
    obs2: InstanceType<typeof ValidationObserver>;
    obs3: InstanceType<typeof ValidationObserver>;
    bvModal: HTMLFormElement;
    entityList: HTMLFormElement;
    createEntityTypeModal: HTMLFormElement;
  };

  created() {
    this.fillParams();
  }

  fillParams() {
    this.findTypeCustomer();
    this.findTypeIdentification();
    this.findIncoterms();
    this.findCurrency();
    this.findTermsPayment();
    this.findBank();
    this.findServiceAgent();
    this.findTypeContact();
    this.findTypeAddress();
    this.findPaymentMethods();
    this.findPriceList();
  }
  //GETTERS N' SETTERS
  get typeDocument() {
    return this.stateManager.state.dataTypeIdentification;
  }

  set typeDocument(newValue) {
    this.typeDocument = newValue;
  }
  /*parametros*/
  getMaxCodeEntity() {
    this.entityMaxCode
      .execute()
      .then((response: any) => {
        typeof response === 'number' ? (this.maxCodeEntiti = response + 1) : (this.maxCodeEntiti = 1);
      })
      .catch((err: any) => {
        throw new Error(`${err}`);
      });
  }
  findTypeCustomer() {
    this.typeTercero = [];
    this.typeCustomerFindAll
      .execute()
      .then((resp: any) => {
        this.typeTercero = resp;
      })
      .catch((err: any) => {
        throw new Error(`${err}`);
      });
  }
  findTypeIdentification() {
    this.stateManager.state.dataTypeIdentification = [];
    this.typeIdentificationFindAll
      .execute()
      .then((resp: any) => {
        this.stateManager.state.dataTypeIdentification = resp;
        this.stateManager.notifyAll();
      })
      .catch((err: any) => {
        throw new Error(`${err}`);
      });
  }
  findTypeContact() {
    this.typeContacted = [];
    this.typeContactFindAll
      .execute()
      .then((resp: any) => {
        this.typeContacted = resp;
      })
      .catch((err: any) => {
        throw new Error(`${err}`);
      });
  }
  findTypeAddress() {
    this.typeAddress = [];
    this.typeAddressFindAll
      .execute()
      .then((resp: any) => {
        this.typeAddress = resp;
      })
      .catch((err: any) => {
        throw new Error(`${err}`);
      });
  }
  clearTerritory() {
    this.Region = [];
    this.City = [];
    this.selectRegion = [];
    this.selectCity = [];
  }
  findIncoterms() {
    this.incoterms = [];
    this.incotermsFindAll
      .execute()
      .then((resp: any) => {
        this.incoterms = resp;
      })
      .catch(err => {
        throw new Error(`${err}`);
      });
  }
  findCurrency() {
    this.purchase = [];
    this.currencyFindAll
      .execute()
      .then((resp: any) => {
        this.purchase = resp;
      })
      .catch(err => {
        throw new Error(`${err}`);
      });
  }
  findTermsPayment() {
    this.termsPayment = [];
    this.termsPaymentFindAll
      .execute()
      .then((resp: any) => {
        this.termsPayment = resp;
      })
      .catch(err => {
        throw new Error(`${err}`);
      });
  }
  findBank() {
    this.banks = [];
    this.formEntity.bank = new Bank();
    this.bankFindAll
      .execute()
      .then((resp: any) => {
        this.stateManager.state.dataBank = resp;
      })
      .catch(err => {
        throw new Error(`${err}`);
      });
  }
  findServiceAgent() {
    this.serviceAgent = [];
    this.groupAgentServiceFindAll
      .execute()
      .then((resp: any) => {
        this.serviceAgent = resp;
      })
      .catch(err => {
        throw new Error(`${err}`);
      });
  }
  findPaymentMethods() {
    this.paymentMethods = [];
    this.paymentMethodsFindAll
      .execute()
      .then((resp: any) => {
        this.paymentMethods = resp;
      })
      .catch(err => {
        throw new Error(`${err}`);
      });
  }

  /*fin de  parametros*/
  searchCustomers(query: string) {
    const arrayTypeEntity = [];

    arrayTypeEntity.push(this.typeTercero.map((type: any) => type.type));

    if (query.length >= 3) {
      const params: any = {
        text: query,
        type: arrayTypeEntity
      };
      this.isLoading = true;
      this.findAllCustomersLike
        .execute(params)
        .then((response: any) => {
          this.customersList = response;
          this.isLoading = false;
        })
        .catch((err: any) => {
          this.isLoading = false;
          throw new Error(`${err}`);
        });
    }
  }
  asyncFindOrigin(query: string) {
    if (query.length >= 3) {
      this.isLoading = true;
      this.searchTerritoriesByQueryParameter
        .execute(query)
        .then((response: any) => {
          this.territories = response;
          this.isLoading = false;
        })
        .catch((err: any) => {
          this.isLoading = false;
          throw new Error(`${err}`);
        });
    }
  }

  addNewRow() {
    this.formEntity.contacts.push(new Contact());
    if (this.formEntity.contacts.length == 1) {
      this.formEntity.contacts[0].mainContact = true;
    }
  }
  addNewRowAddress() {
    this.formEntity.addressEntityList.push(new AddressLine());
  }
  addNewIdentification() {
    this.formEntity.otherTypeIdentificationEntityList.push(new EntityTypeIdentification());
  }

  deleteIdentification(obj: EntityTypeIdentification) {
    const idx = this.formEntity.otherTypeIdentificationEntityList.indexOf(obj);
    if (idx > -1) {
      this.formEntity.otherTypeIdentificationEntityList.splice(idx, 1);
    }
  }
  deleteRow(index: number) {
    this.formEntity.contacts.splice(index, 1);
  }

  cleanAllContacts() {
    this.$swal
      .fire({
        title: `${this.title}`,
        icon: 'info',
        text: `${this.descriptions}`,
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#00AFFF',
        confirmButtonText: `${this.$t('general.delete')}`,
        cancelButtonText: `${this.$t('general.cancel')}`
      })
      .then(result => {
        if (result.isConfirmed) {
          this.formEntity.contacts = [];
        }
      });
  }

  async deleteRowAddress(index: any, obj: any) {
    const idx = this.formEntity.addressEntityList.indexOf(obj);

    if (obj.addressId != 0) {
      await this.entityDeleteAddress
        .execute({
          entity: this.formEntity.code,
          address: obj.addressId
        })
        .then(response => {
          if ('id' in response) {
            this.$swal(`${this.$t('general.messageDelete')}`, ``, 'success');
          }
        })
        .catch(err => {
          throw new Error(`${err}`);
        });
    }
    if (idx > -1) {
      this.formEntity.addressEntityList.splice(idx, 1);
    }
  }

  /*add tags*/
  addTagTypeDocument(newTag: string) {
    const payload: any = {
      id: 0,
      type: newTag
    };
    this.typeIdentificationCreate
      .execute(payload)
      .then(() => {
        this.findTypeIdentification();
      })
      .catch((err: any) => {
        throw new Error(`${err}`);
      });
  }
  addTagTypeContact(newTag: string) {
    const payload: any = {
      id: 0,
      name: newTag
    };
    this.createTypeContact
      .execute(payload)
      .then(() => {
        this.findTypeContact();
      })
      .catch((err: any) => {
        throw new Error(`${err}`);
      });
  }
  addTagTypeAddress(newTag: string) {
    const payload: any = {
      id: 0,
      name: newTag
    };
    this.createTypeAddress
      .execute(payload)
      .then(() => {
        this.findTypeAddress();
      })
      .catch((err: any) => {
        throw new Error(`${err}`);
      });
  }
  addTagIncoterms(newTag: string) {
    const payload: any = {
      id: 0,
      name: newTag
    };
    this.createIncoterms
      .execute(payload)
      .then(() => {
        this.findIncoterms();
      })
      .catch((err: any) => {
        throw new Error(`${err}`);
      });
  }
  addTagServiceAgent(newTag: string) {
    const payload: any = {
      id: 0,
      name: newTag
    };
    this.createGroupAgentService
      .execute(payload)
      .then(() => {
        this.findServiceAgent();
      })
      .catch((err: any) => {
        throw new Error(`${err}`);
      });
  }
  addTagPaymentMethods(newTag: string) {
    const payload: any = {
      id: 0,
      name: newTag
    };
    this.createPaymentMethods
      .execute(payload)
      .then(() => {
        this.findPaymentMethods();
      })
      .catch((err: any) => {
        throw new Error(`${err}`);
      });
  }
  addTagTermsPayment(newTag: string) {
    const payload: any = {
      id: 0,
      name: newTag
    };
    this.createTermsPayment
      .execute(payload)
      .then(() => {
        this.findTermsPayment();
      })
      .catch((err: any) => {
        throw new Error(`${err}`);
      });
  }

  addTypeEntity(newTag: TypeCustomer) {
    const payload: any = {
      type: newTag.type,
      name: newTag.name
    };
    this.createTypeCustomer.execute(payload).then(() => this.findTypeCustomer());
  }
  /* end add tags*/

  controllerModal(isClose: boolean) {
    if (isClose) this.$bvModal.hide('DocTransactional-modal');

    this.findBank();
  }

  clearBankForm() {
    this.$refs.bvModal.hide();
    if (this.isEdit) return;
    this.formEntity.bank = new Bank();
  }

  //save form
  async saveForm(final?: boolean) {
    let executedCorrectly = false;

    if (!final) this.updateEntityB = true;

    if (!(await this.codeExist()) && !this.updateEntityB) {
      showAlert('error', `${this.$t('general.entityCodeExists')}`);
      return;
    }

    //set address properties
    if (this.formEntity.address) {
      this.formEntity.address.country = this.formEntity.address?.selectTerritorie?.country ?? '';
      this.formEntity.address.state = this.formEntity.address?.selectTerritorie?.state ?? '';
      this.formEntity.address.city = this.formEntity.address?.selectTerritorie?.city ?? '';
      this.formEntity.address.countryName = this.formEntity.address?.selectTerritorie?.countryName ?? '';
      this.formEntity.address.stateName = this.formEntity.address?.selectTerritorie?.stateName ?? '';
      this.formEntity.address.cityName = this.formEntity.address?.selectTerritorie?.cityName ?? '';
      // ...
    }

    const listAuxiliar: any[] = [];
    this.formEntity.addressEntityList.map((listAddress: any) => {
      listAddress.country = listAddress.selectTerritorie.country;
      listAddress.state = listAddress.selectTerritorie.state;
      listAddress.city = listAddress.selectTerritorie.city;
      listAddress.countryName = listAddress.selectTerritorie.countryName;
      listAddress.stateName = listAddress.selectTerritorie.stateName;
      listAddress.cityName = listAddress.selectTerritorie.cityName;

      listAuxiliar.push(listAddress);
    });

    this.formEntity.addressEntityList = listAuxiliar;
    if (this.formEntity.bank?.id == 0 || this.formEntity.bank?.id == null) this.formEntity.bank = null;

    this.isloading = true;

    await this.createEntity
      .execute(this.formEntity)
      .then((res: any) => {
        this.formEntity.otherTypeIdentificationEntityList = res.otherTypeIdentificationEntityList;
        this.formEntity.contacts = res.contacts;
        this.formEntity.addressEntityList = res.addressEntityList;

        if (final) {
          this.formEntity = new Entity();
          this.$refs.form_wizard.reset();
          this.updateEntityB = false;
        }

        this.isloading = false;
        executedCorrectly = true;
      })
      .catch((err: any) => {
        this.isloading = false;
        throw new Error(`${err}`);
      });

    return executedCorrectly;
  }

  updateEdit(data: boolean) {
    this.isEdit = data;
  }

  async codeExist() {
    try {
      await this.entityFindById.internalExecute(this.formEntity.code);
      return true;
    } catch (error) {
      return false;
    }
  }

  updateEntity(isUpdate: boolean) {
    if (!this.formEntityUpdate) return null;

    this.updateEntityB = isUpdate;
    isUpdate ? (this.formEntity = this.formEntityUpdate) : (this.formEntity = new Entity());

    if (!this.formEntity.bank) {
      this.formEntity.bank = new Bank();
    }
    if (!this.formEntity.bank.address) {
      this.formEntity.bank.address = new AddressLine();
    }
    this.vari += 1;
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  async validateStepOne() {
    if ((await this.codeExist()) && !this.updateEntityB) {
      showAlert('error', `${this.$t('general.entityCodeExists')}`);
      return false;
    }
    if (this.formEntity.address?.selectTerritorie == null) {
      showAlert('warning', `${this.$t('general.fillAllFields')}`);
      return false;
    }

    await this.saveForm();
    return await this.$refs.obs1.validate();
  }

  async saveStep() {
    if (this.formEntity.termsPayment && !this.formEntity.delivery) {
      showAlert('warning', `Debes asignar un tiempo de entrega en días`);
      return;
    }

    return this.saveForm();
  }

  async validateStepThree() {
    return await this.$refs.obs3.validate();
  }
  validateCodeEntity() {
    this.entityFindById
      .execute(this.formEntity.code)
      .then((response: any) => {
        if (response) {
          if (response.code === 0 || (response.code && response.id)) {
            showAlert(
              'error',
              'Entity already exists: code ' + response.code + ' - ' + response.id + ' - ' + response.fullName
            );
            this.formEntity.code = 0;
          }
        }
      })
      .catch((err: any) => {
        throw new Error(`${err}`);
      });
  }

  formUpdate(data: any) {
    this.formEntityUpdate = data;
    this.updateEntity(true);
    this.$refs.entityList.hide();
  }

  makeMainContact(indexContact: number) {
    this.formEntity.contacts = this.formEntity.contacts.map((item, index) => {
      item.mainContact = false;
      if (index == indexContact) {
        item.mainContact = true;
      }
      return item;
    });
  }

  async findAll() {
    const res = await this.findAllPriceList.internalExecute();
    this.priceListItems = res;
  }

  async findPriceList() {
    try {
      const res = await this.findAllPriceList.internalExecute();
      this.priceListItems = res;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isloading = false;
    }
  }

  renderPricelist(option: CourierPriceList) {
    return `${option.id} - ${option.description}`;
  }

  get typeIdentificationItems() {
    return this.stateManager.state.dataTypeIdentification;
  }
  async deleteTypeIdentificationItem(data: TypeIdentification) {
    try {
      await this.typeIdentificationDelete.execute(data);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.findTypeIdentification();
    }
  }

  async updateTypeIdentificationItem(data: TypeIdentification) {
    try {
      await this.updateTypeIdentification.execute(data);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.findTypeIdentification();
    }
  }

  async deleteCategories(data: PaymentMethods) {
    try {
      await this.deletePaymentMethods.internalExecute(data);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.findPaymentMethods();
    }
  }

  async updateCategories(data: PaymentMethods) {
    try {
      await this.updatePaymentMethods.execute(data);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.findPaymentMethods();
    }
  }

  async deleteClasses(data: GroupAgentService) {
    try {
      await this.deleteGroupAgentService.execute(data);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.findServiceAgent();
    }
  }

  async updateClasses(data: GroupAgentService) {
    try {
      await this.updateGroupAgentService.execute(data);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.findServiceAgent();
    }
  }

  async deleteTypeContact(data: TypeContact) {
    try {
      await this.typeContactDelete.execute(data);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.findTypeContact();
    }
  }

  async updateTypeContact(data: TypeContact) {
    try {
      await this.typeContactUpdate.execute(data);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.findTypeContact();
    }
  }

  async deleteIncoterms(data: Incoterms) {
    try {
      await this.incotermsDelete.execute(data);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.findIncoterms();
    }
  }

  async updateIncoterms(data: Incoterms) {
    try {
      await this.incotermsUpdate.execute(data);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.findIncoterms();
    }
  }

  async deleteTermsPayment(data: TermsPayment) {
    try {
      await this.termsPaymentDelete.execute(data);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.findTermsPayment();
    }
  }

  async updateTermsPayment(data: TermsPayment) {
    try {
      await this.termsPaymentUpdate.execute(data);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.findTermsPayment();
    }
  }

  async deleteTypeAddress(data: TypeAddress) {
    try {
      await this.typeAddressDelete.execute(data);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.findTypeAddress();
    }
  }

  async updateTypeAddress(data: TypeAddress) {
    try {
      await this.typeAddressUpdate.execute(data);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.findTypeAddress();
    }
  }
}
