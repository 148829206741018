import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import { Operation } from '../typeoperation/Operation';
import { Transport } from '../typetransport/Transport';
import { Process } from '../processtype/Process';
import { Routes } from '@/settings/domain/routes/Routes';
import { TimelineConfig } from '../timelineConfig/TimelineConfig';
import { EventTransactional } from '../eventsTransactional/EventTransactional';

export class TimelineTransactional {
  description: string;
  typeTransport: Transport | null;
  active: boolean;
  processType: Process | null;
  typeOperation: Operation | null;
  events: EventTransactional[];
  routeId: number;
  tmOrigin: number;
  routes: Routes | null;
  configFor: string;
  timelineOrigin: TimelineConfig | null;
  preRegister: PreRegister | null;

  public constructor() {
    this.description = '';
    this.typeTransport = null;
    this.active = true;
    this.processType = null;
    this.typeOperation = null;
    this.events = [];
    this.routeId = 0;
    this.tmOrigin = 0;
    this.routes = null;
    this.configFor = '';
    this.timelineOrigin = null;
    this.preRegister = null;
  }
}
