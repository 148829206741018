
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import AddContainer from './containers/AddContainer.vue';
import { Prop } from 'vue-property-decorator';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import AddPallet from './pallets/AddPallet.vue';
import AddBulk from './bulks/AddBulk.vue';
import { FindCommodityTypeByStatus } from '@/freight/application/commodity_type/search/FindCommodityTypesByStatus';
import { WeightFindAll } from '@/settings/application/uses_cases/weight/search/WeightFindAll';
import { FindAllPackingUnitsByStatus } from '@/settings/application/uses_cases/packing_units/search/FindPackingUnitsByStatus';
import { FindByStatusCommodityClass } from '@/settings/application/uses_cases/commodityClass/search/FindByStatusCommodityClass';
import { CreateBillOfLanding } from '@/wms/application/billOfLanding/create/CreateBillOfLanding';
import { FindallBillOfLanding } from '@/wms/application/billOfLanding/search/FindallBillOfLanding';
import { FindAllContainersByStatus } from '@/settings/application/uses_cases/containers/search/FindAllContainersByStatus';
import { BillOfLanding } from '@/wms/domain/billOfLanding/BillOfLanding';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { EntityFindFilter } from '@/settings/application/uses_cases/entity/search/EntityFindFilter';
import { CommodityClass } from '@/settings/domain/commodityClass/CommodityClass';
import { Containers } from '@/settings/domain/containers/Containers';
import { PackingUnits } from '@/settings/domain/packing_units/PackingUnits';
import { TypeOperation } from '@/settings/domain/typeOperation/TypeOperation';
import { ViewLocation } from '@/settings/domain/views/location/ViewLocation';
import { Territories } from '@/settings/domain/views/territories/Territories';
import AddReference from './references/AddReference.vue';

interface Lists {
  containerList?: Containers[];
  originList?: Territories[];
  destinationList?: Territories[];
  commodityList?: CommodityType[];
  packagingList?: PackingUnits[];
  referencesList?: any[];
  typeOperationList?: TypeOperation[];
  locationList?: ViewLocation[];
  weightList?: any[];
  commodityClassesList?: CommodityClass[];
  customerList?: Entity[];
  billOfLandingList?: BillOfLanding[];
}

@Component({
  name: 'AddCargoDetails',
  components: { AddContainer, AddPallet, AddBulk, AddReference }
})
export default class AddCargoDetails extends Vue {
  @Inject(TYPES.COMMODITY_TYPE_FIND_BY_STATUS)
  readonly findActiveCommodity!: FindCommodityTypeByStatus;
  @Inject(TYPES.FINDALL_WEIGHT)
  readonly findAllWeightUnits!: WeightFindAll;
  @Inject(TYPES.FIND_PACKING_UNITS_BY_STATUS)
  readonly findActivePackingUnits!: FindAllPackingUnitsByStatus;
  @Inject(TYPES.COMMODITY_CLASS_FIND_BY_STATUS)
  readonly findActiveCommodityClass!: FindByStatusCommodityClass;
  @Inject(TYPES.BILL_OF_LANDING_SAVE)
  readonly saveBillofLanding!: CreateBillOfLanding;
  @Inject(TYPES.BILL_OF_LANDING_FINDALL)
  readonly findAllBillOfLanding!: FindallBillOfLanding;
  @Inject(TYPES.FIND_CONTAINER_BY_STATUS)
  readonly searchActiveContainers!: FindAllContainersByStatus;
  @Inject(TYPES.ENTITY_FIND_BY_DESCRIPTION_LIKE)
  readonly findEntityByDescription!: EntityFindFilter;

  //Data
  isLoading = false;

  //Listas
  customerList: Entity[] = [];

  @Prop({ required: true }) forms!: any;
  @Prop({ required: true }) form!: any;
  @Prop({ required: true }) forView!: boolean;
  @Prop({ required: true }) lists!: Lists;
  @Prop({ required: true }) addBulk!: Function;
  @Prop({ required: true }) clearForm!: Function;
  @Prop({ required: true }) module!: string;
  @Prop({ required: false, default: 3 }) activeTab!: number;
  @Prop({ required: false, default: () => false }) isQuotation!: boolean;

  timeoutEntity: any;
  searchInterval = 600;

  get tabIndex() {
    return this.activeTab;
  }

  set tabIndex(newValue: number) {
    this.$emit('activeTab:update', newValue);
  }

  //Getter para ocultar o mostrar los campos de "Con cargo a" y "Propietario"
  get showCargoOwner() {
    return !('typeOrder' in this.form);
  }

  //Funcion invocada para obtener la lista de localizaciones de un almacen, filtrando por commodity
  findLocations() {
    throw new Error('Method not implemented.');
  }

  //Funcion invocada para hacer un custom label del input de Bill Of landing en cada uno de los forms
  customLabelBL(item: BillOfLanding) {
    return `${item.serial} (${!item.active ? this.$t('general.used') : this.$t('general.aviable')})`;
  }

  //Funcion invocada para buscar entidades basadas en el nombre
  findEntity(query: string) {
    try {
      clearTimeout(this.timeoutEntity);
      if (query.length < 2) return;
      const params: any = {
        text: query,
        type: ['SUPPLIER', 'CUSTOMER']
      };
      this.timeoutEntity = setTimeout(() => {
        (async () => {
          this.isLoading = true;
          const res = await this.findEntityByDescription.execute(params);
          this.lists.customerList = res.length > 0 ? res : [];
          this.isLoading = false;
          clearTimeout(this.timeoutEntity);
        })();
      }, this.searchInterval);
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
}
