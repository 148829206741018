import { BillOfLanding } from '../billOfLanding/BillOfLanding';

export class HouseBillOfLading extends BillOfLanding {
  totalVolume: number;
  totalItems: number;
  totalWeight: number;
  cargoType: string;
  productName: string;

  constructor() {
    super();
    this.totalVolume = 0;
    this.totalItems = 0;
    this.totalWeight = 0;
    this.cargoType = '';
    this.productName = '';
  }
}
