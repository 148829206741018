import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { AccountGroup } from '@/settings/domain/accountGroup/AccountGroup';
import { AccountGroupRepository } from '@/settings/domain/accountGroup/AccountGroupRepository';

/**
 * @name AccountGroupService
 * @description Genera la clase para el servicio de grupo de cuentas que hace peticiones al API
 * @implements AccountGroupRepository
 * @author EnriquePallares
 */
@Injectable()
export class AccountGroupService implements AccountGroupRepository {
  /**
   * @name API_BASE_URI
   * @description Dirección base del API
   * @type {string}
   */
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;

  /**
   * @name API_ACCOUNT_GROUP_SAVE
   * @description Dirección del API para guardar un grupo de cuentas
   * @type {string}
   */
  private readonly API_ACCOUNT_GROUP_SAVE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ACCOUNT_GROUP;

  /**
   * @name API_ACCOUNT_GROUP_UPDATE
   * @description Dirección del API para actualizar un grupo de cuentas
   * @type {string}
   */
  private readonly API_ACCOUNT_GROUP_UPDATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ACCOUNT_GROUP;

  /**
   * @name API_ACCOUNT_GROUP_DELETE
   * @description Dirección del API para eliminar un grupo de cuentas
   * @type {string}
   */
  private readonly API_ACCOUNT_GROUP_DELETE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ACCOUNT_GROUP;

  /**
   * @name API_ACCOUNT_GROUP_FIND_BY_ID
   * @description Dirección del API para obtener un grupo de cuentas por su id
   * @type {string}
   */
  private readonly API_ACCOUNT_GROUP_FIND_BY_ID: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ACCOUNT_GROUP_FIND_BY_ID;

  /**
   * @name API_ACCOUNT_GROUP_FIND_BY_STATUS
   * @description Dirección del API para obtener un grupo de cuentas por su status
   * @type {string}
   */
  private readonly API_ACCOUNT_GROUP_FIND_BY_STATUS: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ACCOUNT_GROUP_FIND_BY_STATUS;

  /**
   * @name API_ACCOUNT_GROUP_FIND_ALL
   * @description Dirección del API para obtener todos los grupos de cuentas
   * @type {string}
   */
  private readonly API_ACCOUNT_GROUP_FIND_ALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ACCOUNT_GROUP;

  /**
   *
   * @param accountGroup
   * @returns El grupo de cuentas guardado
   * @throws Error al guardar el grupo de cuentas
   * @description Guarda el grupo de cuentas
   * @Author EnriquePallares
   */
  async save(accountGroup: AccountGroup): Promise<AccountGroup> {
    try {
      return await http.post(this.API_ACCOUNT_GROUP_SAVE, accountGroup);
    } catch (error) {
      throw new Error('Error al guardar el grupo de cuentas');
    }
  }

  /**
   *
   * @param accountGroup
   * @returns El grupo de cuentas actualizada
   * @throws Error al actualizar el grupo de cuentas
   * @description Actualiza el grupo de cuentas
   * @Author EnriquePallares
   */
  async update(accountGroup: AccountGroup): Promise<AccountGroup> {
    try {
      return await http._put(this.API_ACCOUNT_GROUP_UPDATE, accountGroup);
    } catch (error) {
      throw new Error('Error al actualizar el grupo de cuentas');
    }
  }

  /**
   *
   * @param accountGroup
   * @returns El grupo de cuentas eliminado
   * @throws Error al eliminar el grupo de cuentas
   * @description Elimina el grupo de cuentas
   * @Author EnriquePallares
   */
  async delete(group: string): Promise<AccountGroup> {
    try {
      return await http.singleDelete(`${this.API_ACCOUNT_GROUP_DELETE}?group=${group}`);
    } catch (error) {
      throw new Error('Error al eliminar el grupo de cuentas');
    }
  }

  /**
   *
   * @param group
   * @returns El grupo de cuentas encontrado
   * @throws Error al buscar el grupo de cuentas
   * @description Busca el grupo de cuentas por su id
   * @Author EnriquePallares
   */
  async findById(group: string): Promise<AccountGroup> {
    try {
      return await http.get(`${this.API_ACCOUNT_GROUP_FIND_BY_ID}group=${group}`);
    } catch (error) {
      throw new Error('Error al buscar el grupo de cuentas');
    }
  }

  /**
   *
   * @param status
   * @returns El grupo de cuentas encontrado
   * @throws Error al buscar el grupo de cuentas
   * @description Busca el grupo de cuentas por su status
   * @Author EnriquePallares
   */
  async findByStatus(status: boolean): Promise<AccountGroup[]> {
    try {
      return await http.get(`${this.API_ACCOUNT_GROUP_FIND_BY_STATUS}status=${status}`);
    } catch (error) {
      throw new Error('Error al buscar el grupo de cuentas');
    }
  }

  /**
   *
   * @returns Array de grupos de cuentas
   * @throws Error al buscar los grupos de cuentas
   * @description Busca todos los grupos de cuentas
   * @Author EnriquePallares
   */
  async findAll(): Promise<AccountGroup[]> {
    try {
      return await http.get(this.API_ACCOUNT_GROUP_FIND_ALL);
    } catch (error) {
      throw new Error(`Error al buscar los grupos de cuentas: ${error}`);
    }
  }
}
