import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { PaymentMethodsRepository } from '@/settings/domain/ParmsGeneral/paymentMethods/PaymentMethodsRepository';
import { PaymentMethods } from '@/settings/domain/ParmsGeneral/paymentMethods/PaymentMethods';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class PaymentMethodsService implements PaymentMethodsRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_PAYNEMT_FINDALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PAYNEMT_FINDALL;
  private readonly API_PAYMENT_SAVE: string = this.API_BASE_URI + localStorageModule().config.routes.API_PAYMENT_SAVE;
  private readonly API_PAYMENT_UPDATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PAYMENT_UPDATE;
  private readonly API_PAYMENT_DELETE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PAYMENT_DELETE;

  async create(paymentMethods: PaymentMethods) {
    try {
      await http.post<PaymentMethods[]>(this.API_PAYMENT_SAVE, JSON.stringify(paymentMethods));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(paymentMethods: PaymentMethods) {
    try {
      await http.put<PaymentMethods[]>(this.API_PAYMENT_UPDATE, JSON.stringify(paymentMethods));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async delete(paymentMethods: PaymentMethods) {
    try {
      await http.singleDelete<PaymentMethods[]>(this.API_PAYMENT_DELETE + `/${paymentMethods.id}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAll(): Promise<PaymentMethods[]> {
    try {
      return await http.get<PaymentMethods[]>(this.API_PAYNEMT_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
