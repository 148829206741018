import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { LayoutConfigRepository } from '@/wms/domain/layout/configResizing/LayoutConfigRepository';
import { Rack } from '@/wms/domain/layout/rack/Rack';

@Injectable()
export class SetRacksToConfigZone extends Command<
  Promise<void>,
  {
    storeId: number;
    zoneId: number;
    racks: Rack[];
    layoutConfigId: number;
  }
> {
  public constructor(
    @Inject(TYPES.LAYOUT_CONFIG_REPOSITORY)
    private readonly repository: LayoutConfigRepository
  ) {
    super();
  }

  internalExecute({
    storeId,
    zoneId,
    racks,
    layoutConfigId
  }: {
    storeId: number;
    zoneId: number;
    racks: Rack[];
    layoutConfigId: number;
  }): Promise<void> {
    return this.repository.saveRacksToZone({ storeId, zoneId, layoutConfigId, racks });
  }
}
