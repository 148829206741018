import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { BulkCubeInfo } from '@/wms/domain/bulk/BulkCubeInfo';
import { BulkRepository } from '@/wms/domain/bulk/BulkRepository';

@Injectable()
export class FindBulkCubeDataByOperation extends Query<Promise<BulkCubeInfo[]>, { number: number; type: string }> {
  public constructor(
    @Inject(TYPES.BULK_REPOSITORY)
    private readonly repository: BulkRepository
  ) {
    super();
  }

  async internalExecute(payload: { number: number; type: string }): Promise<BulkCubeInfo[]> {
    try {
      return await this.repository.findCubeInfo(payload);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
