import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Segmentation } from '@/wms/domain/catalogs/segmentation/Segmentation';
import { SegmentationRepository } from '@/wms/domain/catalogs/segmentation/SegmentationRepository';

@Injectable()
export class FindAllSegmentation extends Query<Promise<Segmentation[]>> {
  public constructor(
    @Inject(TYPES.SEGMENTATION_REFERENCE_REPOSITORY)
    private readonly zoneRepository: SegmentationRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(): Promise<Segmentation[]> {
    const dataSegmentation = await this.zoneRepository.findAll();
    this.stateManager.patch({ dataSegmentation });
    return this.stateManager.state.dataSegmentation;
  }
}
