import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { HouseBillOfLandingRepository } from '@/wms/domain/houseBillOfLading/HouseBillOfLadingRepository';

@Injectable()
export class FindDataForReportHouseBillOfLading extends Command<Promise<any>, string> {
  public constructor(
    @Inject(TYPES.HOUSE_BILL_OF_LADING_REPOSITORY)
    private readonly repository: HouseBillOfLandingRepository
  ) {
    super();
  }

  public internalExecute(code: string): Promise<any> {
    return this.repository.findDataForReportHouseBL(code);
  }
}
