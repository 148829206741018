
import PortSearch from '@/core/components/shared/PortSearch.vue';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import { GeneralPort } from '@/settings/domain/generalPort/GeneralPort';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
@Component({
  name: 'AddCommodity',
  components: { PortSearch }
})
export default class AddCommodity extends Vue {
  @Prop() readonly form!: CommodityType;
  @Prop() readonly formFunctions!: any;
  @Prop() readonly buttonOptions!: any;

  setTerritoryToDescription(value: GeneralPort) {
    if (this.form?.description?.length < 1) {
      this.form.description = value.code + ' - ' + value.port;
    }
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
}
