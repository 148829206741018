import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { PricingEngine } from '@/wms/domain/catalogs/pricingEngine/PricingEngine';
import { PricingEngineRepository } from '@/wms/domain/catalogs/pricingEngine/PricingEngineRepository';

@Injectable()
export class SavePricingEngine extends Command<Promise<any>, PricingEngine> {
  public constructor(
    @Inject(TYPES.PRICING_ENGINE_REPOSITORY)
    private readonly pricingEngineRepository: PricingEngineRepository
  ) {
    super();
  }

  internalExecute(pricingEngine: PricingEngine): Promise<any> {
    return this.pricingEngineRepository.save(pricingEngine);
  }
}
