import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { TimelineConfig } from '@/tracking/domain/timelineConfig/TimelineConfig';
import { TimelineConfigRepository } from '@/tracking/domain/timelineConfig/TimelineConfigRepository';
import { Query } from '@/core/cqrs/Query';

@Injectable()
export class FindAllTimelineConfigByStatus extends Query<Promise<TimelineConfig[]>, boolean> {
  public constructor(
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager,
    @Inject(TYPES.TIMELINECONFIG_TYPE_REPOSITORY)
    private readonly timelineConfigRepository: TimelineConfigRepository
  ) {
    super();
  }
  async internalExecute(params: boolean): Promise<TimelineConfig[]> {
    const dataTimelineConfig = await this.timelineConfigRepository.findByStatus(params);
    this.stateManager.patch({ dataTimelineConfig });
    return this.stateManager.state.dataTimelineConfig;
  }
}
