import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { MonetaryTransaction } from '@/tracking/domain/monetarytransaction/MonetaryTransaction';
import { MonetaryTransactionRepository } from '@/tracking/domain/monetarytransaction/MonetaryTransactionRepository';

@Injectable()
export class MonetaryTransactionFindAll extends Query<Promise<MonetaryTransaction[]>> {
  public constructor(
    @Inject(TYPES.MONETARY_TRANSACTION_REPOSITORY)
    private readonly monetaryTransactionRepository: MonetaryTransactionRepository,
    @Inject(TYPES.STATE_MANAGER) private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(): Promise<MonetaryTransaction[]> {
    const dataMonetary = await this.monetaryTransactionRepository.findAll();
    this.stateManager.patch({ dataMonetary });
    return this.stateManager.state.dataMonetary;
  }
}
