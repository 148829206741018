
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import { Bulk } from '@/wms/domain/bulk/Bulk';
import { FindBulkByLocation } from '@/wms/application/bulk/search/FindBulkByLocation';
import { References } from '@/wms/domain/catalogs/references/References';
import { ShippingDetails } from '@/courier/domain/shippingDetails/ShippingDetails';

@Component({
  name: 'BulksByLocationCenter',
  components: { CustomTableN }
})
export default class BulksByLocationCenter extends Vue {
  @Inject(TYPES.BULKS_FIND_BY_LOCATION)
  private bulksFindByLocation!: FindBulkByLocation;

  isLoading = false;

  search = '';
  bulks: Bulk[] = [];

  get fields() {
    return [
      {
        label: `${this.$t('general.SKU')}`,
        field: 'reference',
        sortable: true,
        formatFn: (item: References) => {
          return `${item.code} - ${item.filterBrand}`;
        }
      },
      {
        label: `${this.$t('general.quantity')}`,
        field: 'quantity',
        sortable: true
      },
      {
        label: `${this.$t('general.commodity')}`,
        field: 'shippingDetails',
        sortable: true,
        formatFn: (item: ShippingDetails) => {
          return item.commodity?.description ?? 'N/A';
        }
      },
      {
        label: `${this.$t('general.packaging')}`,
        field: (item: Bulk) => {
          if (item?.containerType) return item.containerType?.description;
          return item.packaging
            ? item.packaging?.unit?.description
            : item.packingUnit
            ? item.packingUnit.description
            : '-';
        }
      },
    ];
  }

  async searchBulks() {
    try {
      this.isLoading = true;
      if (this.search === '') {
        this.bulks = [];
        return;
      }
      this.bulks = await this.bulksFindByLocation.internalExecute(this.search);
    } catch (error) {
      this.$toast.error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  clearSearch() {
    this.search = '';
  }
}
