
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { ProcessFindAll } from '@/tracking/application/uses_cases/processtype/search/ProcessFindAll';
@Component({
  name: 'AddTypeOperation'
})
export default class AddTypeOperation extends Vue {
  @Inject(TYPES.FINDALL_PROCESS)
  readonly processFindAll!: ProcessFindAll;
  //Datos
  isLoading = false;

  //Listas
  processTypeList: any[] = [];

  //Props
  @Prop() form!: any;
  @Prop() operationProcess!: any;
  @Prop() transactionType!: any;
  @Prop() actions!: any;
  @Prop() buttonOptions!: any;

  //Getter para obtener los tipos de movimientos
  get transactionTypeOptions() {
    return [
      {
        description: `${this.$t('general.warehouseReceipt')}`,
        id: 1
      },
      {
        description: `${this.$t('general.dispatchOfGoods')}`,
        id: 2
      }
    ];
  }

  //Getter para obtener un booleano del perfil operacional wms y renderizar/o no el tipo de transacción
  get renderTypeTransaction() {
    const res = JSON.parse(localStorage.getItem('operationalProfiles') as any);
    return !('wms' in res);
  }

  //Getter para obtener el objeto de operation Process sin error
  get operationProcessSelected() {
    return this.operationProcess;
  }

  //Setter para obtener el objeto de operation Process sin error
  set operationProcessSelected(newValue: any) {
    this.$emit('update:operationProcess', newValue);
  }

  //Getter para obtener el objeto de operation Process sin error
  get transactionTypeSelected() {
    return this.transactionType;
  }

  //Setter para obtener el objeto de operation Process sin error
  set transactionTypeSelected(newValue: any) {
    this.$emit('update:transactionType', newValue);
  }

  //Ciclo de vida del componente
  async mounted() {
    await this.findProcessTypes();
  }

  //Funcion para validar los input
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }): any {
    return dirty || validated ? valid : null;
  }

  //Funcion para cargar el maestro de los tipos de operaciones
  async findProcessTypes() {
    try {
      this.isLoading = true;
      const res = await this.processFindAll.internalExecute();
      this.processTypeList = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
}
