import { BaseLink } from './BaseLink';
import { Context } from './Context';
import { Injectable } from '@/core/di/Injectable';

@Injectable()
export class ExecutorLink extends BaseLink {
  next(context: Context) {
    context.result = context.useCase.internalExecute(context.param);
    this.nextLink.next(context);
  }
}
