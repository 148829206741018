
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { OrderFindByPk } from '@/courier/application/uses_cases/order/search/OrderFindByPk';

@Component({
  name: 'OrderDetail',
  filters: {
    toCurrency(value: number) {
      if (typeof value !== 'number') {
        return value;
      }
      const formatter = new Intl.NumberFormat(`USD`);
      return '$' + formatter.format(value);
    }
  }
})
export default class AppOrderDetail extends Vue {
  @Inject(TYPES.FINDBYPK_ORDER)
  readonly orderFindByPk!: OrderFindByPk;

  //Data
  currentPageLine = 1;
  order = [];
  addressee = [];
  addressTo = [];
  dataLine = [];

  //Props
  @Prop({ required: true, type: Object }) readonly shippingLines!: [];
  //Hook
  created() {
    this.$nextTick(() => this.getShippingByCode);
  }
  //Computed
  get fieldsReport() {
    return [
      { key: 'weight', label: this.translateCol('weight') },
      { key: 'height', label: this.translateCol('height') },
      { key: 'width', label: this.translateCol('width') },
      { key: 'length', label: this.translateCol('length') },
      { key: 'quantity', label: this.translateCol('quantity') },
      { key: 'purchaseurl', label: this.translateCol('link') },
      { key: 'unitprice', label: this.translateCol('value') }
    ];
  }
  get rowsLines() {
    return this.shippingLines.length;
  }
  get getShippingByCode() {
    this.orderFindByPk.execute(this.shippingLines).then((response: any) => {
      this.order = response;
      this.dataLine = response['lines'];
      this.addressee = response['destination'];
      this.addressTo = response['destination']['addressLine'];
    });
    return true;
  }
  //Methods
  translateCol(colName: any) {
    return this.$i18n.t('general.' + colName);
  }
  secondaryWindow(url: any) {
    const w = screen.width;
    const h = screen.height;
    const leftPosition = (screen.width - w - 12) / 2;
    const topPosition = (screen.height - h - 57) / 2;
    const params = `width=${w},height=${h},left=-${leftPosition}pt,top=${topPosition}pt,
      scrollbars=no,resizable=no`;
    const newWindow: any = window.open(url, 'Amazon virtual Store', params);
    // Puts focus on the newWindow
    if (window) newWindow.focus();
  }
}
