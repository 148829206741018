import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Command } from '@/core/cqrs/Command';
import { TYPES } from '@/core/config/Types';
import { User } from '@/settings/domain/user/User';
import { UserRepository } from '@/settings/domain/user/UserRepository';

@Injectable()
export class UpdateUserProfile extends Command<Promise<void>, User> {
  public constructor(
    @Inject(TYPES.USER_REPOSITORY)
    private readonly userRepository: UserRepository
  ) {
    super();
  }

  internalExecute(user: User): Promise<void> {
    return this.userRepository.updateProfile(user);
  }
}
