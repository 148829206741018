
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'RecursiveMenu'
})
export default class RecursiveMenu extends Vue {
  items = [];
  @Prop({ required: true })
  private readonly menu!: [];

  created() {
    this.items = this.menu;
  }
  setNavActive(item: any) {
    this.$store.dispatch('Menu/setNavActiveAct', item);
  }
}
