
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
// import { TYPES } from '@/core/config/Types';
// import { DocumentFindAllByType } from '@/courier/application/uses_cases/documents/search/DocumentFindAllByType';
// import { OrderFindAllByModule } from '@/courier/application/uses_cases/order/search/OrderFindAllByModule';
// import { DocumentOrderType } from '@/courier/domain/order/DocumentOrderEnum';
// import { EntityFindFilter } from '@/settings/application/uses_cases/entity/search/EntityFindFilter';
// import { Inject } from 'inversify-props';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
@Component({
  name: 'ListDocTransactional',
  components: { CustomTableN }
})
export default class ListDocumentComponent extends Vue {
  // @Inject(TYPES.ORDER_FIND_ALL_BY_MODULE) readonly orderFindAllByModule!: OrderFindAllByModule;
  // @Inject(TYPES.DOCUMENT_TYPE_FINDALL_BY_TYPE) readonly findAllDocumentByType!: DocumentFindAllByType;
  // @Inject(TYPES.ENTITY_FIND_BY_DESCRIPTION_LIKE) readonly findEntityByDescription!: EntityFindFilter;

  // //LISTAS

  // entityList: Entity[] = [];
  // //SELECTEDS
  // selectedFilters: any[] = this.filtersOptions;
  // selectedFilterOptions: any = null;
  // //DATA
  // isLoading = false;
  // //TABLE FILTER
  // filterSearch = {
  //   entity: '',
  //   from: '',
  //   to: ''
  // };
  // //PROPS
  // @Prop({ required: true }) switchListType!: string;
  @Prop({ required: true }) fields!: any[];
  @Prop({ required: false }) actions!: any;
  @Prop({ required: false, default: false }) isModalSearch!: any;
  @Prop({ required: false }) orderList!: any;
  //GETTERS
  // get filtersOptions() {
  //   let res: any[] = [];
  //   switch (this.switchListType) {
  //     case '0':
  //       res = [
  //         {
  //           description: this.$t(`orderTypeEnum.PO`),
  //           code: DocumentOrderType.purchaseOrder
  //         },
  //         {
  //           description: this.$t(`orderTypeEnum.CPO`),
  //           code: DocumentOrderType.clientOrder
  //         }
  //       ];
  //       break;
  //     case 'PO':
  //       res = [{ code: DocumentOrderType.purchaseOrder }];
  //       break;
  //     case 'CPO':
  //       res = [{ code: DocumentOrderType.clientOrder }];
  //       break;
  //     case 'WR':
  //       res = [{ code: DocumentOrderType.purchaseOrder }];
  //       break;
  //     case 'INC':
  //       res = [{ code: DocumentOrderType.clientOrder }];
  //       break;
  //     case 'RTS':
  //       res = [{ code: DocumentOrderType.warehouseEntries }];
  //       break;
  //   }
  //   return res;
  // }

  // get filterOptions() {
  //   return [
  //     {
  //       key: 'entity',
  //       label: `${this.$t('general.entity')}`
  //     },
  //     {
  //       key: 'number',
  //       label: `${this.$t('general.number')}`
  //     },
  //     {
  //       key: 'date',
  //       label: `${this.$t('general.date')}`
  //     }
  //   ];
  // }
  // //COMPONENT LIFECYCLE
  // mounted() {
  //   this.findAll();
  // }

  // //FUNCIONES

  // async findOrders() {
  //   this.isLoading = true;
  //   const payload = {
  //     module: 'wms_commercial',
  //     orderType: this.selectedFilters.map(item => item.code)
  //   };
  //   const res = await this.orderFindAllByModule.execute(payload);

  //   this.orderList = res.length > 0 ? res.reverse() : [];
  //   this.isLoading = false;
  // }

  // async findDocuments(documentType?: string) {
  //   this.isLoading = true;
  //   const res = await this.findAllDocumentByType.execute(!this.isModalSearch ? this.switchListType : documentType);
  //   this.orderList = res.length > 0 ? res.reverse() : [];
  //   this.isLoading = false;
  // }

  // findAll() {
  //   if (!this.isModalSearch) {
  //     this.switchListType == 'PO' || this.switchListType == 'CPO' || this.switchListType == '0'
  //       ? this.findOrders()
  //       : this.findDocuments();
  //   } else {
  //     switch (this.switchListType) {
  //       case 'WR':
  //         this.findOrders();
  //         break;
  //       case 'INC':
  //         this.findOrders();
  //         break;
  //       case 'CR':
  //         this.findDocuments('INC');
  //         break;
  //       case 'RTS':
  //         this.findDocuments('WR');
  //         break;
  //     }
  //   }
  // }

  // async entityFind(query: string) {
  //   if (query.length < 2) return;
  //   try {
  //     this.isLoading = true;
  //     const res = await this.findEntityByDescription.execute({
  //       text: query,
  //       type: ['SUPPLIER', 'CUSTOMER']
  //     });
  //     this.entityList = res;
  //     this.isLoading = true;
  //   } catch (error) {
  //     this.isLoading = true;
  //     throw new Error(`${error}`);
  //   }
  // }

  //CUSTOM LABELS
  entityLabel(entity: Entity) {
    return `${entity.code} - ${entity.fullName}`;
  }
}
