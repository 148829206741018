
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import EntitySearch from '@/core/components/shared/EntitySearch.vue';
import { TYPES } from '@/core/config/Types';
import { currencyFormatter } from '@/core/formatter/CurrencyFormatter';
import { getFormattedDate } from '@/core/plugins/DateFormatter';
import { FindPaymentsForPreRegister } from '@/courier/application/uses_cases/DocumentsV2/search/FindPaymentsForPreRegister';
import { DocumentsHeaderForTable } from '@/courier/domain/DocumentsV2/Projections/DocumentsHeaderForTable';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import { Inject } from 'inversify-props';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

interface Filters {
  dateFrom: string;
  dateTo: string;
  sw: string[];
  entity: Entity | null;
}

@Component({
  name: 'ModalCash',
  components: {
    CustomTableN,
    EntitySearch
  }
})
export default class ModalCash extends Vue {
  @Inject(TYPES.PRE_REGISTER_FIND_PAYMENTS)
  readonly findPreRegisterPayments!: FindPaymentsForPreRegister;

  @Prop() masterNumber!: number;
  @Prop({ required: true }) readonly master!: PreRegister;
  @Prop({ type: String, required: false }) readonly typeOperation!: string;
  @Prop({ type: Function }) searchActivities!: () => Promise<void>;

  filters: Filters = {
    dateFrom: getFormattedDate(new Date()),
    dateTo: getFormattedDate(new Date()),
    sw: ['5', '6'],
    entity: null
  };

  isLoading = false;
  documents: DocumentsHeaderForTable[] = [];

  get fields() {
    return [
      // {
      //   label: `${this.$t('general.Document')}`,
      //   field: (row: DocumentsHeaderForTable) => `${row.switch} - ${this.$t(`switchs.${row.switch}`)}`,
      //   sortable: true
      // },
      {
        label: `${this.$t('general.issueDate')}`,
        field: 'date',
        formatFn: (value: string) => getFormattedDate(new Date(value)),
        sortable: true
      },
      {
        label: `${this.$t('general.entity')}`,
        field: 'customer',
        sortable: true
      },
      // {
      //   label: `${this.$t('general.reference')}`,
      //   field: 'docReference',
      //   sortable: true
      // },
      {
        label: `${this.$t('general.bonus')}`,
        field: 'appliedValue',
        formatFn: (value: any) => currencyFormatter.format({ value: value, currency: 'USD' }),
        sortable: true
      },
      {
        label: `${this.$t('general.status')}`,
        field: () => 'Emitido',
        sortable: true
      }
    ];
  }

  async findDocuments() {
    try {
      if (!this.filters.entity) {
        throw new Error('Debe seleccionar una entidad');
      }
      this.isLoading = true;
      const res = await this.findPreRegisterPayments.internalExecute({
        dateFrom: this.filters.dateFrom,
        dateTo: this.filters.dateTo,
        entity: this.filters.entity.code,
        masterNumber: Number(this.master.number),
        masterType: this.master.type
      });
      this.documents = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
}
