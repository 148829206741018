import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { DocumentApplied } from '@/courier/domain/documents/documentApplied/DocumentApplied';
import { DocumentAppliedRepository } from '@/courier/domain/documents/documentApplied/DocumentAppliedRepository';

@Injectable()
export class CreateDocumentApplied extends Command<Promise<any>, DocumentApplied> {
  public constructor(
    @Inject(TYPES.DOCUMENT_APPLIED_REPOSITORY)
    private readonly documentAppliedRepository: DocumentAppliedRepository
  ) {
    super();
  }
  internalExecute(document: DocumentApplied): Promise<any> {
    return this.documentAppliedRepository.saveDocumentApplied(document);
  }
}
