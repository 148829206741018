
import { TYPES } from '@/core/config/Types';
import { OrderType } from '@/courier/domain/CourierOrder/OrderTypeEnum';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { WmsOrderFindAllByModule } from '@/courier/application/uses_cases/order/search/WmsOrderFindAllByModule';
import { WmsQuotationOrder } from '@/courier/domain/wmsQuotationOrder/WmsQuotationOrder';
import { WmsOrderFindByPk } from '@/courier/application/uses_cases/order/search/WmsOrderFindByPk';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { UpdateQuotation } from '@/courier/application/uses_cases/order/update/UpdateQuotation';
import FreightQuotationForm from '@/freight/infrastructure/ui/quotation/form/FreightQuotationForm.vue';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
@Component({
  name: 'OperationsList',
  components: { CustomTableN, FreightQuotationForm }
})
export default class OperationsList extends Vue {
  @Inject(TYPES.QUOTATIONS_FIND_ALL_BY_TYPE_AND_MODULE)
  readonly findOrdersByModule!: WmsOrderFindAllByModule;
  @Inject(TYPES.WMS_ORDER_FIND_BY_PK)
  readonly findWmsOrderByPk!: WmsOrderFindByPk;
  @Inject(TYPES.QUOTATIONS_UPDATE)
  readonly updateQuotation!: UpdateQuotation;

  //Datos
  isLoading = false;
  //Form
  form: WmsQuotationOrder | any = new WmsQuotationOrder();
  //Listas
  operationList: any[] = [];

  //Refs
  $refs!: {
    quotationModal: HTMLFormElement;
  };

  forView = false;
  componentType = `quotation`;

  //Objeto de acciones
  actions = {
    customActions: [
      {
        title: `${this.$t('general.details')}`,
        icon: 'fa fa-eye',
        variant: 'warning',
        action: this.showDetails
      }
    ]
  };

  mainActions = {
    clear: this.clearForm,
    save: this.saveQuotation
  };

  //Getter para obtener los campos de la tabla
  get fields() {
    return [
      {
        field: 'number',
        label: `${this.$t('general.number')}`,
        sortable: true
      },
      {
        field: 'customer',
        label: `${this.$t('general.name')}`,
        sortable: true,
        formatFn: (item: Entity) => `${item.company} - ${item.fullName}`
      },
      {
        field: 'state',
        label: `${this.$t('general.status')}`,
        sortable: true,
        formatFn: (value: boolean) => (value ? this.$t('general.active') : this.$t('general.inactive'))
      },
      {
        field: 'notes',
        label: `${this.$t('general.notes')}`
      },
      {
        field: 'actions',
        label: `${this.$t('general.actions')}`
      }
    ];
  }

  //Metodo usado para obtener todas las órdenes por módulo y tipo
  async findAll() {
    try {
      this.isLoading = true;
      const res = await this.findOrdersByModule.execute({ orderType: OrderType.wmsRequest, module: 'wms_f' });
      this.operationList = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Metodo usado para obtener los detalles de cada documento
  async showDetails(item: WmsQuotationOrder) {
    try {
      this.isLoading = true;
      const res = await this.findWmsOrderByPk.execute({
        number: item.number,
        type: item.typeOrder
      });
      this.form = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
    this.forView = true;
    this.$refs.quotationModal.show();
  }

  async loadQuotation(item: WmsQuotationOrder) {
    if (item.typeOrder.includes(OrderType.freightquotation)) {
      this.$swal({
        title: `${this.$t('general.info')}`,
        text: `${this.$t('general.cantCreatedQuotation')}`,
        icon: 'info'
      });
      return;
    }
    try {
      this.isLoading = true;
      const res = await this.findWmsOrderByPk.execute({
        number: item.number,
        type: item.typeOrder
      });
      this.form = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
    this.$refs.quotationModal.show();
  }

  addQuotation(input?: WmsQuotationOrder) {
    this.$router.push({
      name: 'Quotation',
      query: input && { type: input.typeOrder, number: input.number.toString() }
    });
  }

  saveQuotation() {
    throw new Error('Aun no esta implementado');
  }

  //Metodo usado para obtener los detalles de cada documento
  async showDetailsQuotation(item: any) {
    try {
      this.isLoading = true;
      this.componentType = item.typeOrder.includes(OrderType.freightquotation) ? `quotation` : `request`;
      const res = await this.findWmsOrderByPk.execute({
        number: item.number,
        type: item.typeOrder
      });
      this.form = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
    this.$refs.quotationModal.show();
  }

  //Funcion para limpiar el formulario
  clearForm() {
    this.form = new WmsQuotationOrder();
  }

  //Funcion invocada para inactivar una cotizacion
  async delete(item: WmsQuotationOrder) {
    try {
      this.isLoading = true;
      const res = await this.findWmsOrderByPk.execute({
        number: item.number,
        type: item.typeOrder
      });
      this.form = res;
      this.form.customer = Object.assign(new Entity(), res.customer);
      this.form.state = 'disabled';
      this.form.module = 'carga_f';
      this.isLoading = false;
      await this.updateQuotation.execute(this.form);
      this.findAll();
      this.clearForm();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para aprobar una cotizacion
  async approve(item: WmsQuotationOrder) {
    try {
      if (item.state == 'disabled') return;
      this.isLoading = true;
      const res = await this.findWmsOrderByPk.execute({
        number: item.number,
        type: item.typeOrder
      });
      this.form = res;
      this.form.customer = Object.assign(new Entity(), res.customer);
      this.form.approvalDate = new Date().toISOString().slice(0, 19);
      this.form.state = 'approved';
      this.form.module = 'carga_f';
      this.isLoading = false;
      await this.updateQuotation.execute(this.form);
      this.findAll();
      this.clearForm();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
}
