import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { ReferencesRepository } from '@/wms/domain/catalogs/references/ReferencesRepository';
import Serial from '@/wms/domain/catalogs/references/Serial';
import { Query } from '@/core/cqrs/Query';
/**
 * @class FindAllAssociatedSerialsByReference
 * @description Clase que permite encontrar los seriales de cada referencia(productos y servicios)
 * @version 1.0.0
 * @Author Mauricio Cantillo
 */
@Injectable()
export class FindAllAssociatedSerialsByReference extends Query<Promise<Serial[]>, string> {
  public constructor(
    @Inject(TYPES.REFERENCE_REPOSITORY)
    readonly repository: ReferencesRepository
  ) {
    super();
  }

  public async internalExecute(code: string): Promise<Serial[]> {
    return await this.repository.findAllAssociatedSerialByReference(code);
  }
}
