import Layout from '@/core/data/layout.json';
import store from '@/core/store';
import { VuexModule, getModule, config, Module, Mutation, Action } from 'vuex-module-decorators';

// Create style sheet append in head
function createStyle(color: string) {
  const head = document.head;
  const link = document.createElement('link');
  (link.type = 'text/css'), (link.rel = 'stylesheet'), (link.href = window.location.origin + '/css/' + color + '.css');
  head.appendChild(link);
}

config.rawError = true;
@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'Layout'
})
class LayoutStore extends VuexModule {
  layout = Layout;
  sidebarType = localStorage.getItem('SidebarTypeCompany') || 'default';

  @Mutation
  set() {
    document.body.className = this.layout.color.mixLayout;
    document.body.setAttribute('main-theme-layout', this.layout.settings.layoutType);
    document.getElementsByTagName('html')[0].setAttribute('dir', this.layout.settings.layoutType);
    const color = localStorage.getItem('colorCompany') || this.layout.color.color;
    const layoutVersion = localStorage.getItem('layoutVersionCompany') || this.layout.color.layoutVersion;
    if (color) {
      createStyle(color);
      if (layoutVersion) document.body.className = layoutVersion;
    }
  }
  @Mutation
  setLayoutType(payload: any) {
    document.body.setAttribute('main-theme-layout', payload);
    this.layout.settings.layoutType = payload;
    document.getElementsByTagName('html')[0].setAttribute('dir', payload);
  }
  @Mutation
  setLayout(payload: any) {
    document.body.className = payload;
  }
  @Mutation
  setColorLightScheme(payload: any) {
    createStyle(payload);
    this.layout.color.color = payload;
    switch (payload) {
      case 'color-1':
        this.layout.color.primaryColor = '#4466f2';
        this.layout.color.primaryColor = '#1ea6ec';
        break;

      case 'color-2':
        this.layout.color.primaryColor = '#0288d1';
        this.layout.color.secondaryColor = '#26c6da';
        break;

      case 'color-3':
        this.layout.color.primaryColor = '#8e24aa';
        this.layout.color.secondaryColor = '#ff6e40';
        break;

      case 'color-4':
        this.layout.color.primaryColor = '#4c2fbf';
        this.layout.color.secondaryColor = '#2e9de4';
        break;

      case 'color-5':
        this.layout.color.primaryColor = '#7c4dff';
        this.layout.color.secondaryColor = '#7b1fa2';
        break;

      case 'color-6':
        this.layout.color.primaryColor = '#3949ab';
        this.layout.color.secondaryColor = '#4fc3f7';
        break;
    }
    localStorage.setItem('colorCompany', this.layout.color.color);
    localStorage.setItem('primaryColorCompany', this.layout.color.primaryColor);
    localStorage.setItem('secondaryColorCompany', this.layout.color.secondaryColor);
    window.location.reload();
    this.layout.color.layoutVersion = 'light';
    localStorage.setItem('layoutVersionCompany', this.layout.color.layoutVersion);
  }
  @Mutation
  setColorDarkScheme(payload: any) {
    createStyle(payload);
    this.layout.color.color = payload;
    switch (payload) {
      case 'color-1':
        this.layout.color.primaryColor = '#4466f2';
        this.layout.color.primaryColor = '#1ea6ec';
        break;

      case 'color-2':
        this.layout.color.primaryColor = '#0288d1';
        this.layout.color.secondaryColor = '#26c6da';
        break;

      case 'color-3':
        this.layout.color.primaryColor = '#8e24aa';
        this.layout.color.secondaryColor = '#ff6e40';
        break;

      case 'color-4':
        this.layout.color.primaryColor = '#4c2fbf';
        this.layout.color.secondaryColor = '#2e9de4';
        break;

      case 'color-5':
        this.layout.color.primaryColor = '#7c4dff';
        this.layout.color.secondaryColor = '#7b1fa2';
        break;

      case 'color-6':
        this.layout.color.primaryColor = '#3949ab';
        this.layout.color.secondaryColor = '#4fc3f7';
        break;
    }
    localStorage.setItem('colorCompany', this.layout.color.color);
    localStorage.setItem('primaryColorCompany', this.layout.color.primaryColor);
    localStorage.setItem('secondaryColorCompany', this.layout.color.secondaryColor);
    window.location.reload();
    this.layout.color.layoutVersion = 'dark-only';
    localStorage.setItem('layoutVersionCompany', this.layout.color.layoutVersion);
  }
  @Mutation
  setCustomizeSidebarType(payload: any) {
    localStorage.setItem('SidebarTypeCompany', payload);
    window.location.reload();
  }

  @Action({ commit: 'set' })
  setAct() {
    return;
  }
  @Action({ commit: 'setLayoutType' })
  setLayoutTypeAct(payload: any) {
    return payload;
  }
  @Action({ commit: 'setLayout' })
  setLayoutAct(payload: any) {
    return payload;
  }
  @Action({ commit: 'setColorLightScheme' })
  setColorLightSchemeAct(payload: any) {
    return payload;
  }
  @Action({ commit: 'setColorDarkScheme' })
  setColorDarkSchemeAct(payload: any) {
    return payload;
  }
  @Action({ commit: 'setCustomizeSidebarType' })
  setCustomizeSidebarTypeAct(payload: any) {
    return payload;
  }
}

export default getModule(LayoutStore);
