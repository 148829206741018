import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Store } from '@/wms/domain/layout/store/Store';
import { RackRepository } from '@/wms/domain/layout/rack/RackRepository';
import { Rack } from '@/wms/domain/layout/rack/Rack';

@Injectable()
export class RackService implements RackRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_RACK_FINDALL: string = this.API_BASE_URI + localStorageModule().config.routes.API_RACK_FINDALL;
  private readonly API_RACK_FINDBYWAREHOUSE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_RACK_FINDBYWAREHOUSE;
  private readonly API_RACK_SAVE: string = this.API_BASE_URI + localStorageModule().config.routes.API_RACK_SAVE;
  private readonly API_RACK_UPDATE: string = this.API_BASE_URI + localStorageModule().config.routes.API_RACK_UPDATE;
  private readonly API_LAYOUTCONFIG_DELETE_RACK: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_LAYOUTCONFIG_DELETE_RACK;
  private readonly API_RACK_FIND_BY_STATUS =
    this.API_BASE_URI + localStorageModule().config.routes.API_RACK_FIND_BY_STATUS;

  async create(rack: Rack) {
    try {
      await http.post<Rack[]>(this.API_RACK_SAVE, JSON.stringify(rack));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(rack: Rack) {
    try {
      await http.put<Store[]>(this.API_RACK_UPDATE, JSON.stringify(rack));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findAll(): Promise<Rack[]> {
    try {
      return await http.get<Rack[]>(this.API_RACK_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async findByStatus(status: boolean): Promise<Rack[]> {
    try {
      return await http.get<Rack[]>(`${this.API_RACK_FIND_BY_STATUS}${status}`);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async deleteRack(rack: Rack): Promise<any> {
    try {
      return await http.delete(this.API_LAYOUTCONFIG_DELETE_RACK, JSON.stringify(rack));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
