import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { MigrationFromCountryRepository } from '@/settings/domain/migrations/MigrationFromCountryRepository';
import { Territories } from '@/settings/domain/views/territories/Territories';

@Injectable()
export class MigrationFromCountryCode extends Query<Promise<Territories[]>, string> {
  public constructor(
    @Inject(TYPES.MIGRATIONS_FROMCOUNTRYCODE_REPOSITORY)
    private readonly repository: MigrationFromCountryRepository,

    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(country: string): Promise<Territories[]> {
    const dataMigration = await this.repository.migrationFromCountryCode(country);
    this.stateManager.patch({ dataMigration });
    return this.stateManager.state.dataMigration;
  }
}
