
import Component from 'vue-class-component';
import Vue from 'vue';
import { ValidationObserver } from 'vee-validate/dist/types/components';
import { Prop } from 'vue-property-decorator';
import { Rack } from '@/wms/domain/layout/rack/Rack';
import { RackLevel } from '@/wms/domain/layout/rack/RackLevel';
import { Locations } from '@/wms/domain/layout/locations/Locations';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { CreateRack } from '@/wms/application/layout/rack/create/CreateRack';
import { UpdateRack } from '@/wms/application/layout/rack/update/UpdateRack';

@Component({
  name: 'LayoutRack'
})
export default class LayoutRack extends Vue {
  @Inject(TYPES.CREATE_RACKS)
  readonly CreateRack!: CreateRack;
  @Inject(TYPES.UPDATE_RACKS)
  readonly UpdateRack!: UpdateRack;
  //form register or update

  //refactorizar esto por la clase del dominio
  isValidGeneral = false;
  isLoading = false;

  //references
  $refs!: {
    generalData: InstanceType<typeof ValidationObserver>;
    positions: InstanceType<typeof ValidationObserver>;
  };
  //propertis
  @Prop({ type: Function, required: true }) function!: any;
  @Prop() racks!: Rack[];
  @Prop() form!: Rack;
  @Prop() isEdit!: boolean;
  @Prop() warehouse!: Rack;
  @Prop() store!: Rack;

  get havePositions() {
    return this.form.rackLevel.length > 0;
  }

  //methods
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  async beforeTabSwitch() {
    await this.$refs.generalData.validate().then((res: boolean) => {
      this.isValidGeneral = res;
    });
    return this.isValidGeneral;
  }

  fillDetailLevels() {
    //inicializar el detalle vacio
    this.form.rackLevel = [];
    let objDetail: RackLevel;
    // desagregar niveles o pisos
    for (let i = 1; i <= this.form.levels; i++) {
      objDetail = new RackLevel();
      objDetail.level = i;
      objDetail.locations = [new Locations(this.form.id, i)];
      objDetail.rackId = this.form.id;
      objDetail.levelDesc = `${this.$t('general.level')} ${i}`;
      this.form.rackLevel.push(objDetail);
    }
  }

  addNewRowPosition(element: any) {
    element.locations.push(new Locations(element.rackId, element.level));
  }
  deleteRowPositions(arrayPositions: any, element: any) {
    // const idx = element.indexOf(obj);
    // if (idx > -1) {
    //   element.splice(idx, 1);
    // }

    element.active = false;
  }

  async saveRack() {
    this.isLoading = true;
    await this.CreateRack.execute(this.form);
    this.function();
    this.isLoading = false;
  }

  async editRack() {
    this.isLoading = true;
    await this.UpdateRack.execute(this.form);
    this.function();
    this.isLoading = false;
  }

  toUpperCase(element: any) {
    element.position = element.position.toUpperCase();
  }

  async factory() {
    if (!(await this.$refs.positions.validate())) {
      this.$swal(`${this.$t('general.warning')}`, `${this.$t('general.fillAllFields')}`, 'warning');
      return false;
    }

    if (this.maxWeightCalc > this.form.maxCapacity || this.maxVolumeCalc > this.form.volume) {
      this.$swal(`${this.$t('general.warning')}`, `${this.$t('general.overCapacity')}.`, 'error');
      return false;
    }

    await this.saveRack();
  }

  get maxVolumeCalc() {
    let sum = 0;
    this.form.rackLevel.map(item => {
      item.locations.map(location => {
        sum += parseFloat(location.volume.toString());
      });
    });
    return sum;
  }
  get maxWeightCalc() {
    let sum = 0;
    this.form.rackLevel.map(item => {
      item.locations.map(location => {
        sum += parseFloat(location.maxCapacity.toString());
      });
    });
    return sum;
  }
}
