import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { Branch } from '@/settings/domain/branch/Branch';
import { BranchRepository } from '@/settings/domain/branch/BranchRepository';
@Injectable()
export class UpdateBranch extends Command<Promise<Branch>, Branch> {
  public constructor(@Inject(TYPES.BRANCH_REPOSITORY) private readonly branchRepository: BranchRepository) {
    super();
  }

  async internalExecute(dto: Branch): Promise<Branch> {
    return await this.branchRepository.update(dto);
  }
}
