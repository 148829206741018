export enum PreRegisterStatusEnum {
  PARTIAL_PRE_REGISTER = 'partial_pre_register',
  COURIER_PRE_REGISTER = 'courier_pre_register',
  PRE_REGISTER = 'pre_register',
  DISPATCH_PRE_REGISTER = 'dispatch_pre_register',
  CUBED = 'cubed',
  LOCATED = 'located',
  TAGGED = 'tagged',
  RECEIVED = 'received_in_warehouse',
  DISPATCHED = 'warehouse_dispatched',
  SHIPMENT = 'shipment',
  DISABLED = 'disabled',
  DELIVERED = 'delivered',
  BILLED = 'billed'
}

//Pre register steps
export const preRegisterSteps = {
  [PreRegisterStatusEnum.PARTIAL_PRE_REGISTER]: 0,
  [PreRegisterStatusEnum.PRE_REGISTER]: 1,
  [PreRegisterStatusEnum.SHIPMENT]: 1,
  [PreRegisterStatusEnum.COURIER_PRE_REGISTER]: 1,
  [PreRegisterStatusEnum.DISPATCH_PRE_REGISTER]: 2,
  [PreRegisterStatusEnum.CUBED]: 2,
  [PreRegisterStatusEnum.LOCATED]: 3,
  [PreRegisterStatusEnum.TAGGED]: 4,
  [PreRegisterStatusEnum.RECEIVED]: 5,
  [PreRegisterStatusEnum.DISPATCHED]: 6,
  [PreRegisterStatusEnum.DISABLED]: -1,
  [PreRegisterStatusEnum.DELIVERED]: 7,
  [PreRegisterStatusEnum.BILLED]: 8
};
