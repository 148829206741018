
import Vue from 'vue';
import Component from 'vue-class-component';
import LayoutStore from '@/core/store/modules/Layout';
import MenuStore from '@/core/store/modules/Menu';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';
import RecursiveMenu from '@/core/components/dashboard/recursiveMenu.vue';
import { FindFile } from '@/settings/application/uses_cases/file/search/FindFile';
import { getMenu } from '@/core/data/menu';
import { getLocalstorageItem } from '@/core/config/LocalStorageVariables';
import { Company } from '@/settings/domain/company/Company';

@Component({
  name: 'Sidebar',
  components: {
    RecursiveMenu
  }
})
export default class Sidebar extends Vue {
  @Inject(TYPES.FINDBYID_COMPANY)
  readonly companyFindById!: CompanyFindById;
  @Inject(TYPES.FIND_FILE_BY_NAME)
  readonly findFileByName!: FindFile;
  @Inject(TYPES.FINDBYID_COMPANY)
  readonly findByIdCompany!: CompanyFindById;

  //Data
  urlLogoCompany = '';
  width = 0;
  height = 0;
  margin = 0;
  widthLogoCompany = 0;
  hideRightArrowRTL = true;
  hideLeftArrowRTL = true;
  hideRightArrow = true;
  hideLeftArrow = true;
  menuWidth = 0;
  temp = 0;
  isHovered = false;
  isWms = false;
  currentCompany: Company | null = null;

  //Hook
  created() {
    MenuStore.getMenu();
    this.getUpdatedMenu();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    if (this.width < 991) {
      this.layout.settings.sidebar.type = 'default';
    }
    const val = this.width <= 540 ? 'horizontal' : this.sidebar;
    if (val == 'default') {
      this.layout.settings.sidebar.type = 'default';
      this.layout.settings.sidebar.bodyType = 'default';
      this.widthLogoCompany = 200;
    } else if (val == 'compact') {
      this.layout.settings.sidebar.type = 'default';
      this.layout.settings.sidebar.bodyType = 'sidebar-icon';
      this.widthLogoCompany = 110;
    } else if (val == 'compact-icon') {
      this.layout.settings.sidebar.type = 'default';
      this.layout.settings.sidebar.bodyType = 'sidebar-hover';
      this.widthLogoCompany = 60;
    } else if (val == 'horizontal') {
      this.layout.settings.sidebar.type = 'default';
      this.layout.settings.sidebar.bodyType = '';
      this.widthLogoCompany = 200;
    }
    setTimeout(() => {
      const element: any = document.getElementById('myDIV');
      this.menuWidth = element.offsetWidth;
      this.menuWidth > window.innerWidth
        ? ((this.hideRightArrow = false), (this.hideLeftArrowRTL = false))
        : ((this.hideRightArrow = true), (this.hideLeftArrowRTL = true));
    }, 100);
  }
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  }
  mounted() {
    this.logoCompany();
    this.findCompany();
  }

  async getUpdatedMenu() {
    const jwtFromLocalStorage = getLocalstorageItem('ACCESS_TOKEN_COMPANY');

    const decodedJwt = this.decodeJwt(jwtFromLocalStorage as string);

    const rolName: number = decodedJwt.role;

    await getMenu(rolName);
    await MenuStore.getMenu();
  }

  decodeJwt(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const data = JSON.parse(window.atob(base64));
    return data;
  }

  //Property Computed
  get layout() {
    return LayoutStore.layout;
  }
  get menuItems() {
    return MenuStore.data;
  }
  get sidebar() {
    return LayoutStore.sidebarType;
  }
  get rol() {
    return (localStorage.getItem('userRol') as string).replace('ROLE_', '');
  }
  get logo() {
    return this.findFileByName.execute(this.currentCompany?.urlLogo || '');
  }

  get appVersion() {
    return process.env.VUE_APP_VERSION;
  }

  get userName() {
    let name = getLocalstorageItem('USER_NAME') as string;
    if (name.includes('@')) {
      name = name.split('@')[0];
    }

    if (name.includes(' ')) {
      name = name.split(' ')[0];
    }

    return name;
  }

  set userName(value: string) {
    this.userName = value;
  }

  //Methods

  async findCompany() {
    const companyId = localStorage.getItem('businessId');
    if (!companyId) return;
    const res: any = await this.findByIdCompany.execute(companyId);
    this.isWms = res.isWms;
    this.currentCompany = res;
  }

  logoCompany() {
    const id = localStorage.getItem('businessId') as string;
    this.companyFindById.internalExecute(id).then((response: any) => {
      this.urlLogoCompany = this.findFileByName.execute(response['urlLogo']);
      localStorage.setItem('urlLogoCompany', this.urlLogoCompany);
    });
  }
  setNavActive(item: any) {
    this.$store.dispatch('Menu/setNavActiveAct', item);
  }
  handleResize() {
    this.width = window.innerWidth - 310;
  }
  scrollToRightRTL() {
    this.temp = this.width + this.margin;
    if (this.temp === 0) {
      this.margin = this.temp;
      this.hideRightArrowRTL = true;
    } else {
      this.margin += this.width;
      this.hideRightArrowRTL = false;
      this.hideLeftArrowRTL = false;
    }
  }
  scrollToLeftRTL() {
    // If Margin is reach between screen resolution
    if (this.margin <= -this.width) {
      this.margin += -this.width;
      this.hideLeftArrowRTL = true;
    }
    //Else
    else {
      this.margin += -this.width;
      this.hideRightArrowRTL = false;
    }
  }
  scrollToLeft() {
    // If Margin is reach between screen resolution
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.hideLeftArrow = true;
    }
    //Else
    else {
      this.margin += this.width;
      this.hideRightArrow = false;
    }
  }
  scrollToRight() {
    this.temp = this.menuWidth + this.margin;
    if (this.temp < this.menuWidth) {
      this.margin = -this.temp;
      this.hideRightArrow = true;
    }
    // else
    else {
      this.margin += -this.width;
      this.hideLeftArrow = false;
    }
  }

  handleMouseEnter() {
    this.isHovered = true;
  }
  handleMouseLeave() {
    this.isHovered = false;
  }
}
