import { localStorageModule } from '@/core/config/ConfigurationServer';
import { CourierPriceList } from '@/settings/domain/courierPriceList/CourierPriceList';
import { CourierPriceListRepository } from '@/settings/domain/courierPriceList/CourierPriceListRepository';
import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';

@Injectable()
export class CourierPriceListService implements CourierPriceListRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_COURIER_PRICE_LIST_GENERAL =
    this.API_BASE_URI + localStorageModule().config.routes.API_COURIER_PRICE_LIST;

  private readonly API_COURIER_PRICE_LIST_FIND_BY_STATUS =
    this.API_BASE_URI + localStorageModule().config.routes.API_COURIER_PRICE_LIST_FIND_BY_STATUS;

  async save(courierPriceList: CourierPriceList): Promise<CourierPriceList> {
    return await http.post<CourierPriceList>(this.API_COURIER_PRICE_LIST_GENERAL, JSON.stringify(courierPriceList));
  }

  async update(courierPriceList: CourierPriceList): Promise<CourierPriceList> {
    try {
      return await http._put<CourierPriceList>(this.API_COURIER_PRICE_LIST_GENERAL, JSON.stringify(courierPriceList));
    } catch (error) {
      throw new Error('Error al guardar edicion');
    }
  }

  async delete(courierPriceList: CourierPriceList): Promise<CourierPriceList> {
    try {
      return await http.delete<CourierPriceList>(this.API_COURIER_PRICE_LIST_GENERAL, courierPriceList.id);
    } catch (error) {
      throw new Error('Error al eliminar lista');
    }
  }

  async findAll(): Promise<CourierPriceList[]> {
    try {
      return await http.get<CourierPriceList[]>(this.API_COURIER_PRICE_LIST_GENERAL);
    } catch (error) {
      throw new Error('Error al encontrar registros');
    }
  }

  async findById(id: string): Promise<CourierPriceList> {
    try {
      return await http.get<CourierPriceList>(`${this.API_COURIER_PRICE_LIST_GENERAL}/${id}`);
    } catch (error) {
      throw new Error('Error al encontrar registros por id');
    }
  }

  async findByStatus(status: string): Promise<CourierPriceList[]> {
    try {
      return await http.get<CourierPriceList[]>(`${this.API_COURIER_PRICE_LIST_FIND_BY_STATUS}/${status}`);
    } catch (error) {
      throw new Error('Error al encontrar registros por status');
    }
  }
}
