import { Transport } from '@/tracking/domain/typetransport/Transport';

export class ShippingMethod {
  id: string;
  description: string;
  multiple: boolean;
  typeTransport: Transport | null;
  createdAt: string;
  updatedAt: string;
  active: boolean;
  isTriangulated: boolean;

  public constructor() {
    this.id = '';
    this.description = '';
    this.updatedAt = '';
    this.createdAt = '';
    this.multiple = false;
    this.typeTransport = null;
    this.active = true;
    this.isTriangulated = false;
  }
}
