import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { PreparationForList } from '@/freight/domain/Preparation/PreparationForList';
import { PreparationRepository } from '@/freight/domain/Preparation/PreparationRepository';
@Injectable()
export class FindAllPreparations extends Query<Promise<PreparationForList[]>> {
  constructor(
    @Inject(TYPES.CARGO_DETAILS_PRESET_REPOSITORY)
    private readonly repository: PreparationRepository
  ) {
    super();
  }

  async internalExecute(): Promise<PreparationForList[]> {
    return await this.repository.findAll();
  }
}
