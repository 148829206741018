
import Component from 'vue-class-component';
import Vue from 'vue';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Prop } from 'vue-property-decorator';
import { Store } from '@/wms/domain/layout/store/Store';
import { CreateStore } from '@/wms/application/layout/store/create/CreateStore';
import PictureInput from 'vue-picture-input';
import { FileFindAll } from '@/settings/application/uses_cases/file/search/FileFindAll';
import { UpdateStore } from '@/wms/application/layout/store/update/UpdateStore';
import { CreateFileV2 } from '@/settings/application/uses_cases/file/create/CreateFileV2';

@Component({
  name: 'AddStore',
  components: { PictureInput }
})
export default class AddStore extends Vue {
  @Inject(TYPES.CREATE_STORE) readonly createStore!: CreateStore;
  @Inject(TYPES.UPDATE_STORE) readonly updateStore!: UpdateStore;
  @Inject(TYPES.SAVE_FILE) readonly createFile!: CreateFileV2;
  @Inject(TYPES.FINDALL_FILE) readonly fileFindAll!: FileFindAll;

  //FORM
  form = new Store();
  //DATA
  isLoading = false;
  imagePreview = '';
  // REFS
  $refs!: {
    layoutImage: HTMLFormElement;
  };
  //PROPS
  @Prop({ type: Function, required: true }) function!: any;
  @Prop({ required: true }) warehouse!: any;
  @Prop({ required: false }) storeSelected!: Store;

  mounted() {
    if (this.storeSelected) Object.assign(this.form, this.storeSelected);
    this.loadPreview();
  }

  //METHODS
  async save() {
    this.isLoading = true;
    try {
      if (this.$refs.layoutImage.file) {
        // Obtenemos la imagen del componente picture-input
        const file = this.$refs.layoutImage.file;

        // Creamos el nombre de la imagen
        const newName = `WH${this.warehouse.code}-S${this.form.storeId}.${file.name.split('.').pop()}`;

        //Creamos un archivo nuevo con la imagen y el nombre creado
        const fileToSave = new File([file], newName, { type: file.type });

        const response = await this.createFile.execute({
          files: [fileToSave],
          folder: 'store-layouts'
        });

        if (!Array.isArray(response)) {
          this.form.imgStore = response;
        }
      }

      this.form.warehouse = this.warehouse;
      if (!this.storeSelected) {
        await this.createStore.execute(this.form);
      } else {
        await this.updateStore.execute(this.form);
      }
      this.function();
      this.isLoading = false;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async loadPreview() {
    try {
      if (this.form.imgStore) {
        this.isLoading = true;
        const getImage: any = await this.fileFindAll.execute(this.form.imgStore);

        if ('error' in getImage || 'message' in getImage) {
          this.isLoading = false;
          return;
        }

        this.imagePreview = getImage['base64'];
        this.isLoading = false;
      }
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //FORM ACTIONS
  clear() {
    this.form = new Store();
  }

  //VALIDATION
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
}
