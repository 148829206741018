
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import AddTypeOperation from './AddTypeOperation.vue';
import { TypeOperation } from '@/settings/domain/typeOperation/TypeOperation';
import { CreateOperation } from '@/tracking/application/uses_cases/typeoperation/create/CreateOperation';
import { UpdateOperation } from '@/tracking/application/uses_cases/typeoperation/update/UpdateOperation';
import { OperationFindAll } from '@/tracking/application/uses_cases/typeoperation/search/OperationFindAll';
import { ProcessFindAll } from '@/tracking/application/uses_cases/processtype/search/ProcessFindAll';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import { DeleteOperation } from '@/tracking/application/uses_cases/typeoperation/delete/DeleteOperation';

@Component({
  name: 'TypeOperationCenter',
  components: {
    AddTypeOperation,
    CustomTableN
  }
})
export default class TypeOperationCenter extends Vue {
  @Inject(TYPES.CREATE_OPERATION)
  readonly saveOperation!: CreateOperation;
  @Inject(TYPES.UPDATE_OPERATION)
  readonly updateOperation!: UpdateOperation;
  @Inject(TYPES.FINDALL_TYPE_OPERATION)
  readonly findAllTypeOperations!: OperationFindAll;
  @Inject(TYPES.FINDALL_PROCESS)
  readonly processFindAll!: ProcessFindAll;
  @Inject(TYPES.DELETE_OPERATION)
  readonly deleteOperation!: DeleteOperation;
  //Formulario
  form: TypeOperation = new TypeOperation();

  //Datos
  isLoading = false;
  operationProcess: any = null;
  transactionType: any = null;

  //Listas
  typeOperationList: TypeOperation[] = [];
  processTypeList: any[] = [];

  //Objetos de accion
  actions = {
    factory: this.factory,
    clear: this.clear
  };
  tableActions = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: this.load
      },
      {
        title: `${this.$t('general.delete')}`,
        icon: 'fa fa-trash',
        variant: 'danger',
        action: this.delete
      }
    ]
  };

  //Opciones de botón save
  buttonOptions = {
    variant: 'success',
    isEdit: false,
    title: 'save',
    icon: 'fa-plus'
  };

  //Getter para obtener los tipos de movimientos
  get transactionTypeOptions() {
    return [
      {
        description: `${this.$t('general.warehouseReceipt')}`,
        id: 1
      },
      {
        description: `${this.$t('general.dispatchOfGoods')}`,
        id: 2
      }
    ];
  }

  //Campos de la tabla
  get fields() {
    return [
      {
        label: `${this.$t('general.code')}`,
        field: 'code',
        sortable: true
      },
      {
        label: `${this.$t('general.description')}`,
        field: 'description',
        sortable: true
      },
      {
        label: `${this.$t('general.type')}`,
        field: 'processType',
        sortable: true,
        formatFn: (item: number) => {
          switch (item) {
            case 1:
              return this.$t('general.importation');
            case 2:
              return this.$t('general.exportation');
            case 3:
              return this.$t('general.localOperation');
          }
        }
      },
      {
        label: `${this.$t('general.transactionType')}`,
        field: 'transactionType',
        formatFn: (item: number) => {
          switch (item) {
            case 1:
              return this.$t('general.warehouseReceipt');
            case 2:
              return this.$t('general.dispatchOfGoods');
          }
        }
      },
      {
        label: `${this.$t('general.active')}`,
        field: 'status',
        sortable: true,
        formatFn: (row: boolean) => (row ? this.$t('general.active') : this.$t('general.disabled'))
      },
      {
        label: `${this.$t('general.actions')}`,
        field: 'actions'
      }
    ];
  }

  //Ciclo de vida del componente
  mounted() {
    this.findProcessTypes();
    this.findAll();
  }

  //Funcion invocada para buscar todos los tipos de operaciones en la base de datos
  async findAll() {
    try {
      this.isLoading = true;
      const res = (await this.findAllTypeOperations.execute()) as any[];
      this.typeOperationList = res.length > 0 ? res.reverse() : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion para determinar si es guardado o edición
  factory() {
    this.form.processType = this.operationProcess.id;
    this.form.processTypeName = this.operationProcess.name;
    this.form.transactionType = this.transactionType.id;
    !this.buttonOptions.isEdit ? this.save() : this.update();
  }

  //Funcion invocada para editar el objeto en la base de datos
  async update() {
    try {
      this.isLoading = true;
      await this.updateOperation.execute(this.form);
      await this.findAll();
      this.clear();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para guardar el objeto en la base de datos
  async save() {
    try {
      this.isLoading = true;
      await this.saveOperation.execute(this.form);
      await this.findAll();
      this.clear();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para limpiar los datos del formulario
  clear() {
    this.form = new TypeOperation();
    this.buttonOptions = {
      variant: 'success',
      isEdit: false,
      title: 'save',
      icon: 'fa-plus'
    };
    this.operationProcess = null;
    this.transactionType = null;
  }
  //Funcion invocada para cargar el objeto en el formulario
  load(item: TypeOperation): void {
    this.buttonOptions = {
      variant: 'secondary',
      isEdit: true,
      title: 'edit',
      icon: 'fa-pencil'
    };
    this.form = Object.assign({}, item);
    this.operationProcess = this.processTypeList.filter(process => process.id == item.processType)[0];
    this.transactionType = this.transactionTypeOptions.filter(transaction => transaction.id == item.transactionType)[0];
  }

  async delete(item: TypeOperation) {
    this.isLoading = true;
    try {
      await this.deleteOperation.execute(item.code);
      this.isLoading = false;
      this.findAll();
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion para cargar el maestro de los tipos de operaciones
  async findProcessTypes() {
    try {
      this.isLoading = true;
      const res = await this.processFindAll.execute();
      this.processTypeList = res.length > 0 ? res.reverse() : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
}
