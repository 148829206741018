import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Command } from '@/core/cqrs/Command';
import { GroupAgentService } from '@/settings/domain/ParmsGeneral/groupAgentService/GroupAgentService';
import { GroupAgentServiceRepository } from '@/settings/domain/ParmsGeneral/groupAgentService/GroupAgentServiceRepository';

@Injectable()
export class CreateGroupAgentService extends Command<Promise<any>, GroupAgentService> {
  public constructor(
    @Inject(TYPES.GROUP_AGENT_SERVICE_REPOSITORY)
    private readonly repository: GroupAgentServiceRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  public internalExecute(groupAgentService: GroupAgentService): Promise<any> {
    return this.repository.create(groupAgentService);
  }
}
