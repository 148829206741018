import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { BillOfLandingRepository } from '@/wms/domain/billOfLanding/BillOfLandingRepository';
import { BillOfLanding } from '@/wms/domain/billOfLanding/BillOfLanding';

@Injectable()
export class FindMasterBLByPreRegister extends Command<
  Promise<BillOfLanding>,
  {
    preRegisterType: string;
    preRegisterNumber: number;
  }
> {
  public constructor(
    @Inject(TYPES.BILL_OF_LANDING_REPOSITORY)
    private readonly repository: BillOfLandingRepository
  ) {
    super();
  }

  public async internalExecute(payload: {
    preRegisterNumber: number;
    preRegisterType: string;
  }): Promise<BillOfLanding> {
    return await this.repository.findByPreRegister(payload);
  }
}
