import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { MigrationFromCountryRepository } from '@/settings/domain/migrations/MigrationFromCountryRepository';
import { Territories } from '@/settings/domain/views/territories/Territories';

@Injectable()
export class MigrationFromCountryService implements MigrationFromCountryRepository {
  private readonly API_MIGRATIONS_FROM_COUNTRYCODE: string = localStorageModule().config.routes
    .API_IMPORT_CITIES_BYCOUNTRYCODE;

  async migrationFromCountryCode(country: string): Promise<Territories[]> {
    return await http.get<any[]>(process.env.VUE_APP_API_TERRITORIES + this.API_MIGRATIONS_FROM_COUNTRYCODE + country);
  }
}
