import { Injectable } from '@/core/di/Injectable';
import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { CloudConfig } from '@/settings/domain/cloudConfig/CloudConfig';
import { Inject } from '@/core/di/Inject';
import { CloudConfigRepository } from '@/settings/domain/cloudConfig/CloudConfigRepository';

@Injectable()
export class CreateCloudConfig extends Command<Promise<CloudConfig>, CloudConfig> {
  public constructor(
    @Inject(TYPES.CLOUD_CONFIG_REPOSITORY)
    private readonly cloudConfigRepository: CloudConfigRepository
  ) {
    super();
  }
  internalExecute(dto: CloudConfig): Promise<CloudConfig> {
    return this.cloudConfigRepository.save(dto);
  }
}
