import { Injectable } from '@/core/di/Injectable';
import { Query } from '@/core/cqrs/Query';
import { ReferenceGroupLevel } from '@/wms/domain/referenceGroupLevel/ReferenceGroupLevel';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { ReferenceGroupLevelRepository } from '@/wms/domain/referenceGroupLevel/ReferenceGroupLevelRepository';

@Injectable()
export class FindByParentReferenceGroupLevel extends Query<Promise<Array<ReferenceGroupLevel>>, string> {
  public constructor(
    @Inject(TYPES.REFERENCE_GROUP_LEVEL_REPOSITORY)
    private readonly referenceGroupLevelRepository: ReferenceGroupLevelRepository
  ) {
    super();
  }

  public async internalExecute(parent: string): Promise<Array<ReferenceGroupLevel>> {
    return await this.referenceGroupLevelRepository.findByParent(parent);
  }
}
