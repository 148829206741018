import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Volume } from '@/settings/domain/volume/Volume';
import { VolumeRepository } from '@/settings/domain/volume/VolumeRepository';

@Injectable()
export class VolumeFindAll extends Query<Promise<Volume[]>> {
  public constructor(
    @Inject(TYPES.VOLUME_REPOSITORY)
    private readonly volumeRepository: VolumeRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(): Promise<Volume[]> {
    const dataVolume = await this.volumeRepository.findAll();
    this.stateManager.patch({ dataVolume });
    return this.stateManager.state.dataVolume;
  }
}
