import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { BulkRepository } from '@/wms/domain/bulk/BulkRepository';

@Injectable()
export class SetBulkForPutAway extends Query<Promise<void>, { number: number; type: string; sequence: number }> {
  public constructor(
    @Inject(TYPES.BULK_REPOSITORY)
    private readonly repository: BulkRepository
  ) {
    super();
  }

  async internalExecute(payload: { number: number; type: string; sequence: number }): Promise<void> {
    try {
      return await this.repository.setPutAway(payload);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
