import { ConceptsContainer } from './conceptsContainer/ConceptsContainer';
export class Containers {
  active: boolean;
  edit: boolean;
  capacity: number;
  createAt: string;
  description: string;
  id: string;
  size: number;
  concepts: ConceptsContainer[];
  updateAt: string;

  public constructor() {
    this.edit = true;
    this.active = false;
    this.capacity = 0;
    this.createAt = '';
    this.description = '';
    this.id = '';
    this.size = 0;
    this.concepts = [];
    this.updateAt = '';
  }
}
