
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { FindOwnerByReferenceAndType } from '@/wms/application/catalogs/alternativeReferences/search/FindOwnerByReferenceAndType';
import { PricingEngine } from '@/wms/domain/catalogs/pricingEngine/PricingEngine';
import { SettingSupplierOffers } from '@/wms/domain/catalogs/pricingEngine/SettingOffers/SettingSupplierOffers';
import { SettingCustomerOffers } from '@/wms/domain/catalogs/pricingEngine/SettingOffers/SettingCustomerOffers';
import { SettingZoneOffers } from '@/wms/domain/catalogs/pricingEngine/SettingOffers/SettingZoneOffers';
import { SettingZoneStandard } from '@/wms/domain/catalogs/pricingEngine/SettingStandardPrice/SettingZoneStandard';
import { SettingSupplierStandard } from '@/wms/domain/catalogs/pricingEngine/SettingStandardPrice/SettingSupplierStandard';
import { SettingCustomerStandard } from '@/wms/domain/catalogs/pricingEngine/SettingStandardPrice/SettingCustomerStandard';
import { FindByTypeParam } from '@/wms/application/catalogs/PricingEngineParam/search/FindByTypeParam';
import { SavePricingEngine } from '@/wms/application/catalogs/PricingEngine/save/SavePricingEngine';
import { FindByReferencePackaging } from '@/wms/application/catalogs/packaging/search/FindByReferencePackaging';
import { CreatePackaging } from '@/wms/application/catalogs/packaging/create/CreatePackaging';
import { UpdatePackaging } from '@/wms/application/catalogs/packaging/update/UpdatePackaging';
import VueBarcode from 'vue-barcode';
import ModalTieredDiscount from '@/wms/infrastructure/ui/reference/ModalTieredDiscount.vue';
import { FindAllSegmentation } from '@/wms/application/catalogs/segmentation/search/FindAllSegmentation';
import { CreateSegmentation } from '@/wms/application/catalogs/segmentation/create/CreateSegmentation';
import { EntityFindFilter } from '@/settings/application/uses_cases/entity/search/EntityFindFilter';
import { FindAllPackingUnit } from '@/wms/application/packingUnit/search/FindAllPackingUnit';
import { CreatePackingUnit } from '@/wms/application/packingUnit/create/CreatePackingUnit';
import { StandardPrice } from '@/wms/domain/catalogs/pricingEngine/SettingStandardPrice/StandardPrice';
import Draggable from 'vuedraggable';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { FindAllPackingUnits } from '@/settings/application/uses_cases/packing_units/search/FindAllPackingUnits';
import { PackingUnits } from '@/settings/domain/packing_units/PackingUnits';
import { ValidationObserver } from 'vee-validate';
import { References } from '@/wms/domain/catalogs/references/References';
import { FindAllPackingUnitsByStatus } from '@/settings/application/uses_cases/packing_units/search/FindPackingUnitsByStatus';
import { Packaging } from '@/wms/domain/catalogs/packaging/Packaging';

@Component({
  name: 'ModalsInfo',
  components: {
    VueBarcode,
    ModalTieredDiscount,
    Draggable
  }
})
export default class ModalsInfo extends Vue {
  @Inject(TYPES.FINDOWNER_ALTERNATIVE_REFERENCE)
  readonly findOwnerByReferenceAndType!: FindOwnerByReferenceAndType;
  @Inject(TYPES.FINDALL_PACKINGUNIT_REFERENCE)
  readonly packingUnitFindAll!: FindAllPackingUnit;
  @Inject(TYPES.CREATE_PACKINGUNIT_REFERENCE)
  readonly createPackingUnit!: CreatePackingUnit;
  @Inject(TYPES.CREATE_PACKAGING_REFERENCE)
  readonly createPackaging!: CreatePackaging;
  @Inject(TYPES.UPDATE_PACKAGING_REFERENCE)
  readonly updatePackaging!: UpdatePackaging;
  @Inject(TYPES.FINDBYREFERENCE_PACKAGING_REFERENCE)
  readonly findByReferencePackagingcall!: FindByReferencePackaging;
  @Inject(TYPES.SAVE_PRICING_ENGINE_REFERENCE)
  readonly savePricingEngineCall!: SavePricingEngine;
  @Inject(TYPES.FINDALL_SEGMENTATION_REFERENCE)
  readonly zoneFindAll!: FindAllSegmentation;
  @Inject(TYPES.CREATE_SEGMENTATION_REFERENCE)
  readonly createZone!: CreateSegmentation;
  @Inject(TYPES.FINDALLLIKE_CUSTOMER)
  readonly findByNameAndType!: EntityFindFilter;
  @Inject(TYPES.PRICING_ENGINE_PARAM_FIND)
  readonly findByTypeParam!: FindByTypeParam;
  @Inject(TYPES.ENTITY_FIND_BY_DESCRIPTION_LIKE)
  readonly findEntityByDescription!: EntityFindFilter;
  @Inject(TYPES.FIND_ALL_PACKING_UNITS)
  readonly getAllPackingUnits!: FindAllPackingUnits;
  @Inject(TYPES.FIND_PACKING_UNITS_BY_STATUS)
  readonly findPackingUnitsByStatus!: FindAllPackingUnitsByStatus;
  //TAB INDEX
  tabSupplierIndex = 0;
  //LISTS
  customerList: Entity[] = [];
  supplierList: Entity[] = [];
  //Mostrar caracteristicas
  showThings = false;

  //DATA PACKAGING
  name = '';
  nameState = null;
  from: any = {
    unit: {},
    Numberunit: 0
  };
  okDisabled = false;

  units = [];
  checked = false;
  selected = '';

  configUnits: any = [
    {
      id: 0,
      unit: {}, //Measurement
      numberUnits: 0,
      unitsClass: [],
      general: false,
      supplier: [],
      height: 0,
      width: 0,
      large: 0,
      volume: 0,
      weight: 0
    }
  ];
  disableGeneral = false;
  isLoading = false;
  customers: any = [];
  suppliers: any = [];

  // DATA PRICING
  formP: any = {
    //FORMULARIO REAL
    codePricing: '',
    description: '',
    basePrice: 0,
    codeReferencia: ''
  };
  value: any = [];
  valueType: any = {};

  optionsprice: any = [{ code: 'SUPPLIER' }, { code: 'ZONE' }, { code: 'CUSTOMER' }];
  optionType: any = [];
  optionTypeFull: any = [];
  standardgeneral = true;
  estimatedValue = 0;
  saleValue = 0;
  show = true;
  percentage = 0;

  // DATA PRICING ZONE DETAIL
  settingZoneStandard: SettingZoneStandard = new SettingZoneStandard();
  zonesColl = [];
  configPriceZone: any = [this.settingZoneStandard];
  // DATA PRICING CUSTOMER DETAIL
  settingCustomerStandard: SettingCustomerStandard = new SettingCustomerStandard();
  configPriceCustomer: any = [this.settingCustomerStandard];
  // DATA PRICING SUPPLIER DETAIL
  settingSupplierStandard: SettingSupplierStandard = new SettingSupplierStandard();
  configPriceSupplier: any = [this.settingSupplierStandard];

  //TAB OFFERS
  offersgeneral = true;
  valueOffers: any = [];
  valueTypeOffers: any = {};
  totalCount = 0;
  optionsTypeOffers = [];
  specificQuantity = 0;
  optionsApplies = [];
  percentageOffers = 0;
  valueApplies: any = {};
  expirationDate = new Date();
  settingZoneOffers: SettingZoneOffers = new SettingZoneOffers();
  configPriceZoneOffers: any = [this.settingZoneOffers];
  settingCustomerOffers: SettingCustomerOffers = new SettingCustomerOffers();
  configPriceCustomerOffers: any = [this.settingCustomerOffers];
  settingSupplierOffers: SettingSupplierOffers = new SettingSupplierOffers();
  configPriceSupplierOffers: any = [this.settingSupplierOffers];
  //FORM CHARACTERISTICS
  selectedFreightCondition: any[] = [];
  inspectionTerms: any[] = [];
  containerInspectionList: any[] = [];
  newCheckContainerInspection = '';
  // generalFreigthList: any[] = [];
  forGeneralFreight = false;
  noManageStock = true;
  generalPackingUnits: PackingUnits[] = [];

  textModal = '';
  policyOffersTiered: any = [];
  generalPolicyOffersTiered: any = [];

  $refs!: {
    bvModal: HTMLFormElement;
    observer: HTMLFormElement;
    observerPricing: InstanceType<typeof ValidationObserver>;
  };
  //Prop
  @Prop() readonly showModal!: boolean;
  @Prop() readonly idModal!: string;
  @Prop() readonly codeReference!: string;
  @Prop() readonly objReference!: References;
  @Prop() updateReference!: Function;

  setObjOffersTiered(objConfiguration: any) {
    this.textModal = 'objeto';
    this.policyOffersTiered = objConfiguration;
  }

  initConfig() {
    if (this.idModal) this.configUnits = [];
    this.configUnits.push({
      id: 0,
      unitMeasurement: {},
      units: 0,
      unitsClass: [],
      general: false,
      supplier: [],
      height: 0,
      width: 0,
      large: 0,
      volume: 0,
      weight: 0
    });

    //FORMULARY PRICING RESET
    this.formP.codePricing = '';
    this.formP.description = '';
    this.formP.basePrice = 0;
    this.formP.codeReferencia = '';

    this.value = [];
    this.valueType = {};

    this.optionType = [];
    this.optionTypeFull = [];
    this.standardgeneral = true;
    this.estimatedValue = 0;
    this.saleValue = 0;
    this.show = true;
    this.percentage = 0;

    // DATA PRICING ZONE DETAIL
    this.settingZoneStandard = new SettingZoneStandard();
    this.zonesColl = [];
    this.configPriceZone = [this.settingZoneStandard];
    // DATA PRICING CUSTOMER DETAIL
    this.settingCustomerStandard = new SettingCustomerStandard();
    this.configPriceCustomer = [this.settingCustomerStandard];
    // DATA PRICING SUPPLIER DETAIL
    this.settingSupplierStandard = new SettingSupplierStandard();
    this.configPriceSupplier = [this.settingSupplierStandard];

    //TAB OFFERS
    this.offersgeneral = true;
    this.valueOffers = [];
    this.valueTypeOffers = {};
    this.totalCount = 0;
    this.optionsTypeOffers = [];
    this.specificQuantity = 0;
    this.optionsApplies = [];
    this.percentageOffers = 0;
    this.valueApplies = {};
    this.expirationDate = new Date();
    this.settingZoneOffers = new SettingZoneOffers();
    this.configPriceZoneOffers = [this.settingZoneOffers];
    this.settingCustomerOffers = new SettingCustomerOffers();
    this.configPriceCustomerOffers = [this.settingCustomerOffers];
    this.settingSupplierOffers = new SettingSupplierOffers();
    this.configPriceSupplierOffers = [this.settingSupplierOffers];

    //CHARACTERISTICS FORM
    this.selectedFreightCondition = [];
    this.inspectionTerms = [];
    this.containerInspectionList = [];
    this.noManageStock = false;
    this.forGeneralFreight = false;
    //PRICING
    this.formP.basePrice =
      this.objReference.unitCost != null ? this.objReference.unitCost : this.objReference.standardCost;
    this.formP.msrp = this.objReference.msrp != null ? this.objReference.msrp : 0;
  }
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  isPurchase(config: any) {
    return config.unitsClass == 'purchase_unit' && config.supplier != undefined && config.supplier.length < 1;
  }
  isPurchaseAndGeneral(config: any) {
    return config.unitsClass == 'purchase_unit' && !config.general;
  }

  get isStandardGeneral() {
    return this.standardgeneral;
  }
  get isOffersGeneral() {
    return this.offersgeneral;
  }
  get isAverage() {
    return JSON.stringify(this.valueType) != '{}' && this.valueType ? this.valueType.codeParam == 'AVERAGE' : true;
  }
  get isValidCalculateType() {
    return JSON.stringify(this.valueType) != '{}' && this.valueType;
  }
  get calculateValue() {
    const base = this.isAverage ? parseFloat(this.formP.basePrice) : parseFloat(this.formP.msrp);
    const percentage = !this.isAverage ? this.percentage * -1 : this.percentage;
    const price = base + (base * percentage) / 100;
    this.saleValue = price;
    return price;
  }

  get options() {
    return [
      { text: `${this.$t('general.purchases')}`, value: 'purchase_unit' },
      { text: `${this.$t('general.sales')}`, value: 'sales_unit' }
    ];
  }

  get alternativeReferencesSupplierList() {
    return this.objReference.alternativeReferences?.filter(
      (reference: any) => reference.entity.typeCustomer.type == 'SUPPLIER'
    );
  }

  get inCustomerTab() {
    return this.tabSupplierIndex == 2;
  }

  get transportMethods() {
    return [
      {
        code: 'ocean',
        description: this.$t('general.ocean')
      },
      {
        code: 'ground',
        description: this.$t('general.ground')
      },
      {
        code: 'air',
        description: this.$t('general.air')
      }
    ];
  }

  get generalFreigthList() {
    return this.generalPackingUnits;
  }

  set generalFreigthList(newValue: any) {
    this.generalFreigthList = newValue;
  }

  sortList() {
    this.selectedFreightCondition = this.selectedFreightCondition.sort((a, b) => a.level - b.level);
  }

  calculateValueSetting(config: any, averageCost: number, msrp?: number) {
    const { calculationType } = config.standardPrice;
    const base = calculationType.codeParam === 'UTILITY' && msrp != null ? msrp : averageCost;
    let percentage = Number(base * config.standardPrice.applicablePercentage) / 100;
    percentage = calculationType.codeParam === 'AVERAGE' ? percentage : percentage * -1;
    const priceSetting = (Number(base) + Number(percentage)).toFixed(2);
    config.standardPrice.estimatedValue = priceSetting;

    if (calculationType.codeParam === 'AVERAGE') {
      calculationType.saleValue = priceSetting;
      calculationType.marginRetail = 0;
    }

    this.calculateValueSaleSetting(config);
  }

  calculateValueSaleSetting(config: any) {
    if (config.standardPrice.calculationType.codeParam === 'BOTTOM_ UP') {
      config.standardPrice.saleValue = Math.ceil(
        Number(config.standardPrice.estimatedValue) / (1 - Number(config.standardPrice.marginRetail) / 100)
      );
      return null;
    }
    if (config.standardPrice.calculationType.codeParam === 'TOP_DOWN') {
      config.standardPrice.saleValue = (
        Number(config.standardPrice.consumerPrice) *
        (1 - Number(config.standardPrice.marginUsconsumer) / 100)
      ).toFixed(2);

      config.standardPrice.estimatedValue = (
        Number(config.standardPrice.saleValue) *
        (1 - Number(config.standardPrice.marginRetail) / 100)
      ).toFixed(2);

      config.standardPrice.distrPrice = (
        Number(config.standardPrice.estimatedValue) *
        (1 - Number(config.standardPrice.applicablePercentage) / 100)
      ).toFixed(2);

      return null;
    }
    config.standardPrice.saleValue =
      Number(config.standardPrice.estimatedValue) +
      (Number(config.standardPrice.estimatedValue) * Number(config.standardPrice.marginRetail)) / 100;
  }
  disabledGeneral(config: any) {
    this.disableGeneral = config.unitsClass != 'purchase_unit';
  }
  addNewRow() {
    this.customers = [];
    this.configUnits.push({
      id: 0,
      unitMeasurement: {},
      units: 0,
      unitsClass: [],
      general: false,
      supplier: [],
      height: 0,
      width: 0,
      large: 0,
      volume: 0,
      weight: 0
    });
  }
  async asyncFind(query: string) {
    if (query.length >= 3) {
      this.supplierList = [];
      const param: any = {
        text: query.toUpperCase(),
        type: 'SUPPLIER'
      };
      this.isLoading = true;
      await this.findEntityByDescription
        .execute(param)
        .then((response: any) => {
          this.supplierList = response;
          this.isLoading = false;
        })
        .catch((err: any) => {
          this.isLoading = false;
          throw new Error(`${err}`);
        });
    }
  }

  addTagUnit(newTag: string) {
    const payload: any = {
      id: 0,
      unit: newTag
    };
    this.createPackingUnit.execute(payload);
    setTimeout(this.findAllUnit, 1000);
  }

  findAllUnit() {
    this.packingUnitFindAll
      .execute()
      .then((resp: any) => {
        this.units = resp;
      })
      .catch((err: any) => {
        this.units = [];
        return err;
      });
  }

  //POLICY PRICING - PRICING ENGINE -------------------------------------------------------------
  //standard price active tab
  get activeTabZone() {
    return this.activeTabByCode('ZONE');
  }
  get activeTabCustomer() {
    return this.activeTabByCode('CUSTOMER');
  }
  get activeTabSupplier() {
    return this.activeTabByCode('SUPPLIER');
  }
  activeTabByCode(codeTab: string) {
    let active = false;

    if (this.value.length != 0) {
      this.value.forEach((value: any) => {
        if (value.code == codeTab) {
          active = true;
        }
      });
    }
    return active;
  }
  //offers active tab
  get offersActiveTabZone() {
    return this.offersActiveTabByCode('ZONE');
  }
  get offersActiveTabCustomer() {
    return this.offersActiveTabByCode('CUSTOMER');
  }
  get offersActiveTabSupplier() {
    return this.offersActiveTabByCode('SUPPLIER');
  }
  offersActiveTabByCode(codeTab: string) {
    let active = false;

    if (this.valueOffers.length != 0) {
      this.valueOffers.forEach((value: any) => {
        if (value.code == codeTab) {
          active = true;
        }
      });
    }
    return active;
  }
  asyncFindCustomer(query: string) {
    if (query.length >= 3) {
      this.customerList = [];
      const params: any = {
        text: query.toUpperCase,
        type: ['CUSTOMER']
      };
      this.isLoading = true;
      this.findByNameAndType
        .execute(params)
        .then((response: any) => {
          // eslint-disable-next-line no-console
          console.log(response);
          this.customerList = response;
          this.isLoading = false;
        })
        .catch((err: any) => {
          this.isLoading = false;
          throw new Error(`${err}`);
        });
    }
  }
  //new row
  addNewRowPricingZone() {
    this.configPriceZone.push(new SettingZoneStandard());
  }
  addNewRowPricingCustomer() {
    this.customers = [];
    this.configPriceCustomer.push(new SettingCustomerStandard());
  }
  addNewRowPricingSupplier() {
    this.configPriceSupplier.push(new SettingSupplierStandard());
  }
  addNewRowPricingZoneOffers() {
    this.configPriceZoneOffers.push(new SettingZoneOffers());
  }
  addNewRowPricingCustomerOffers() {
    this.customers = [];
    this.configPriceCustomerOffers.push(new SettingCustomerOffers());
  }
  addNewRowPricingSupplierOffers() {
    this.configPriceSupplierOffers.push(new SettingSupplierOffers());
  }
  //delete row
  deleteRow(index: number) {
    this.configUnits.splice(index, 1);
  }

  //submit forms
  async handleSubmitPackaging() {
    const payloadPackaging: any = [];

    for (const config of this.configUnits) {
      payloadPackaging.push({
        codeRef: this.codeReference,
        id: config.id,
        isGeneral: config.general,
        isPuschase: config.unitsClass.includes('purchase_unit'),
        isSale: config.unitsClass.includes('sales_unit'),
        numberUnits: config.numberUnits,
        suppliers: config.suppliers != undefined ? config.suppliers : [],
        unit: config.unit,
        height: config.height,
        width: config.width,
        large: config.large,
        volume: config.width * config.large * config.height,
        weight: config.weight
      });
    }

    await this.createPackaging.execute(payloadPackaging);
    this.modalView(this.idModal, false);
  }
  async handleSubmitPricing() {
    if (!(await this.$refs.observerPricing.validate())) return;
    //payload to save
    //header
    const pricingEngine = new PricingEngine();

    pricingEngine.code = this.formP.codePricing;
    pricingEngine.codeReference = this.codeReference;
    pricingEngine.name = this.formP.description;
    pricingEngine.basePrice = this.formP.basePrice;

    //Standard price header
    pricingEngine.standardPrice.codePolicy = this.formP.codePricing;
    pricingEngine.standardPrice.codeReference = this.codeReference;
    pricingEngine.standardPrice.isGeneral = this.standardgeneral;
    pricingEngine.standardPrice.calculationType = this.valueType;
    pricingEngine.standardPrice.applicablePercentage = this.percentage;
    pricingEngine.standardPrice.estimatedValue = this.calculateValue;
    pricingEngine.standardPrice.saleValue = this.saleValue;
    //pricingEngine.standardPrice.applyDetailJson = this.value;

    //Standard price detail
    pricingEngine.settingStandard.settingCustomerStandardList = this.configPriceCustomer;
    // set fk
    pricingEngine.settingStandard.settingCustomerStandardList.forEach((item: any) => {
      item.standardPrice.codePolicy = this.formP.codePricing;
      item.standardPrice.codeReference = this.codeReference;
    });

    pricingEngine.settingStandard.settingSupplierStandardList = this.configPriceSupplier;
    // set fk
    pricingEngine.settingStandard.settingSupplierStandardList.forEach((item: any) => {
      item.standardPrice.codePolicy = this.formP.codePricing;
      item.standardPrice.codeReference = this.codeReference;
    });

    pricingEngine.settingStandard.settingZoneStandardList = this.configPriceZone;
    // set fk
    pricingEngine.settingStandard.settingZoneStandardList.forEach((item: any) => {
      item.standardPrice.codePolicy = this.formP.codePricing;
      item.standardPrice.codeReference = this.codeReference;
    });

    //Offers header
    pricingEngine.offers.codePolicy = this.formP.codePricing;
    pricingEngine.offers.codeReference = this.codeReference;
    pricingEngine.offers.isGeneral = this.offersgeneral;
    pricingEngine.offers.typeOffers = this.valueTypeOffers;
    pricingEngine.offers.appliesTo = this.valueApplies;
    pricingEngine.offers.numberUnits = this.totalCount;
    pricingEngine.offers.expirationDate = this.expirationDate;
    pricingEngine.offers.applicablePercentage = this.percentageOffers;
    pricingEngine.offers.policyOffersTiered = this.generalPolicyOffersTiered;

    //pricingEngine.offers.applyDetailJson = this.valueOffers.code;

    //Offers detail
    pricingEngine.settingOffers.settingCustomerOffersList = this.configPriceCustomerOffers;
    // set fk
    pricingEngine.settingOffers.settingCustomerOffersList.forEach((item: any) => {
      item.offers.codePolicy = this.formP.codePricing;
      item.offers.codeReference = this.codeReference;
    });
    pricingEngine.settingOffers.settingSupplierOffersList = this.configPriceSupplierOffers;
    // set fk
    pricingEngine.settingOffers.settingSupplierOffersList.forEach((item: any) => {
      item.offers.codePolicy = this.formP.codePricing;
      item.offers.codeReference = this.codeReference;
    });
    pricingEngine.settingOffers.settingZoneOffersList = this.configPriceZoneOffers;
    // set fk
    pricingEngine.settingOffers.settingZoneOffersList.forEach((item: any) => {
      item.offers.codePolicy = this.formP.codePricing;
      item.offers.codeReference = this.codeReference;
    });

    this.savePricingEngineCall.execute(pricingEngine);

    // Hide the modal manually
    this.modalView(this.idModal, false);
  }
  handleSubmitAccounting() {
    // Hide the modal manually
    this.modalView(this.idModal, false);
  }

  handleSubmitCharacteristhic() {
    // eslint-disable-next-line no-console
    console.log(this.objReference);

    // this.updateReference();
  }

  //find by reference
  findPackagingByReference() {
    this.findByReferencePackagingcall
      .execute(this.codeReference)
      .then((responsex: any) => {
        this.configUnits = [];
        for (const res of responsex) {
          const classes = [];
          res.isPuschase ? classes.push('purchase_unit') : null;
          res.isSale ? classes.push('sales_unit') : null;
          this.configUnits.push({
            id: res.id,
            unit: res.unit,
            numberUnits: res.numberUnits,
            unitsClass: classes,
            general: res.isGeneral,
            suppliers: res.suppliers,
            height: res.height,
            width: res.width,
            large: res.large,
            volume: res.volume,
            weight: res.weight
          });
        }
      })
      .catch((err: any) => {
        throw new Error(`${err}`);
      });
  }
  findAllZone() {
    this.zoneFindAll
      .execute()
      .then((resp: any) => {
        this.zonesColl = resp;
      })
      .catch((err: any) => {
        return err;
      });
  }
  addTagZone(newTag: string) {
    const payload: any = {
      code: 0,
      zone: newTag
    };
    this.createZone.execute(payload);
    setTimeout(this.findAllZone, 1000);
  }
  findParamPricingByType() {
    const pyload = ['TypeOffers', 'AppliesTo', 'CalculationType'];

    this.findByTypeParam
      .execute(pyload)
      .then((resp: any) => {
        this.optionsTypeOffers = resp.typeOffers;
        this.optionsApplies = resp.appliesTo;
        this.optionType = resp.calculationType.filter(
          (type: any) => type.codeParam !== 'BOTTOM_ UP' && type.codeParam !== 'TOP_DOWN'
        );
        this.optionTypeFull = resp.calculationType;
      })
      .catch((err: any) => {
        return err;
      });
  }
  //----------------------------------------------------------------------------------------------------

  //operation modal
  get Modal() {
    if (this.showModal) {
      this.$bvModal.show(this.idModal);
    } else {
      this.$bvModal.hide(this.idModal);
    }
    return this.showModal;
  }
  handleOk(bvModalEvt: any, id: string) {
    switch (id) {
      case 'mdlPackaging':
        this.handleSubmitPackaging();
        break;
      case 'mdlPricing':
        this.handleSubmitPricing();
        break;
      case 'mdlAccounting':
        this.handleSubmitAccounting();
        break;
      case 'mdlcharacteristics':
        this.handleSubmitCharacteristhic();
        break;
    }
  }

  async isValidForm() {
    await this.$refs.observer.validate().then((isValid: boolean) => {
      this.okDisabled = isValid;
    });
  }

  hiddenModal() {
    this.modalView(this.idModal, false);
  }
  resetModal() {
    this.initConfig();
    this.findParams();
  }
  findParams() {
    if (this.showModal) {
      switch (this.idModal) {
        case 'mdlPackaging':
          break;
        case 'mdlPricing':
          this.findParamPricingByType();
          this.findAllZone();
          this.suppliers = this.objReference.alternativeReferences.filter((item: any) => {
            if (item.typeCustomer == 'SUPPLIER') return item;
          });

          break;
        case 'mdlAccounting':
          break;
      }
    }
  }
  @Emit()
  modalView(id: string, show: boolean) {
    this.$emit('modalView', id, show);
  }

  ApliConfig(typeCalculate: any, objConfig: any) {
    objConfig.standardPrice = new StandardPrice();
    objConfig.standardPrice.calculationType = typeCalculate;
    if (objConfig.suppliers.length > 0) {
      objConfig.standardPrice.distrPrice = objConfig.suppliers[0].basePrice;

      //Tipos de calculo
      if (typeCalculate.codeParam !== 'BOTTOM_ UP' && typeCalculate.codeParam !== 'TOP_DOWN') {
        objConfig.standardPrice.applicablePercentage = objConfig.suppliers[0].profitPercentage;
        this.calculateValueSetting(objConfig, objConfig.standardPrice.distrPrice);
        return null;
      }

      this.calculateValueSaleSetting(objConfig);
    }
  }
  get uriImage() {
    return process.env.VUE_APP_URL_TOKEN + '/file/getImageAsByteArray?name=';
  }

  addCheckToContainerInspectionList() {
    !this.objReference.containerInspectionList ? (this.objReference.containerInspectionList = []) : null;
    this.objReference.containerInspectionList.push(`${this.newCheckContainerInspection}`);
    this.newCheckContainerInspection = '';
  }

  removeCheckToContainerInspectionList(index: number) {
    this.objReference.containerInspectionList.splice(index, 1);
  }

  async findAllGeneralPackingUnits() {
    try {
      this.isLoading = true;
      const res = await this.findPackingUnitsByStatus.execute(true);

      if (res) {
        this.generalPackingUnits = res;
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  handlePackagingSelect(item: PackingUnits, config: Packaging) {
    config.weight = Number(item.weight);
    config.height = Number(item.height);
    config.width = Number(item.width);
  }
}
