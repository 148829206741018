
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Prop } from 'vue-property-decorator';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import { Containers } from '@/settings/domain/containers/Containers';
import { IncotermsFindAll } from '@/settings/application/uses_cases/parmsGeneral/incoterms/search/IncotermsFindAll';
import { CurrencyFindAll } from '@/settings/application/uses_cases/currency/search/CurrencyFindAll';
import { FindCommodityTypeByStatus } from '@/freight/application/commodity_type/search/FindCommodityTypesByStatus';
import { FindAllContainersByStatus } from '@/settings/application/uses_cases/containers/search/FindAllContainersByStatus';
import { WeightFindAll } from '@/settings/application/uses_cases/weight/search/WeightFindAll';
import { FindAllPackingUnitsByStatus } from '@/settings/application/uses_cases/packing_units/search/FindPackingUnitsByStatus';
import { PackingUnits } from '@/settings/domain/packing_units/PackingUnits';
import { Territories } from '@/settings/domain/views/territories/Territories';
import { SearchTerritoryByQueryDescription } from '@/settings/application/uses_cases/views/territories/SearchTerritoryByQueryDescription';
import { ReferenceFindAll } from '@/wms/application/catalogs/references/search/ReferenceFindAll';
import { References } from '@/wms/domain/catalogs/references/References';
import { ReferenceFindAllServices } from '@/wms/application/catalogs/references/search/ReferenceFindAllServices';
import { WmsfOrderCategory } from '@/courier/domain/WmsfOrderCategory/WmsfOrderCategory';
import { WmsfOrderReferences } from '@/courier/domain/WmsfOrderReferences/WmsfOrderReferences';
import { Incoterms } from '@/settings/domain/ParmsGeneral/Incoterms/Incoterms';
import { Currency } from '@/settings/domain/currency/Currency';
import { TypeOperation } from '@/settings/domain/typeOperation/TypeOperation';
import { OperationFindAll } from '@/tracking/application/uses_cases/typeoperation/search/OperationFindAll';
import AddCargoDetails from './addCargoDetails/AddCargoDetails.vue';
import { Bulk } from '@/wms/domain/bulk/Bulk';
import { ViewLocation } from '@/settings/domain/views/location/ViewLocation';
import { CommodityClass } from '@/settings/domain/commodityClass/CommodityClass';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { BillOfLanding } from '@/wms/domain/billOfLanding/BillOfLanding';
import ServicesList from './addCargoDetails/servicesList/ServicesList.vue';
import AddServices from './addServices/AddServices.vue';
import { Transport } from '@/tracking/domain/typetransport/Transport';
import { TransportFindAll } from '@/tracking/application/uses_cases/typetransport/search/findall/TransportFindAll';
import { ValidationObserver } from 'vee-validate';
import { EntityFindFilter } from '@/settings/application/uses_cases/entity/search/EntityFindFilter';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import { OrderType } from '@/courier/domain/CourierOrder/OrderTypeEnum';
import { WmsOrderLine } from '@/courier/domain/orderline/WmsOrderLine';
import { CurrencyFindAllWithRate } from '@/settings/application/uses_cases/currency/search/CurrencyFindAllWithRate';
import { CurrencyFactorFindRateByDate } from '@/settings/application/uses_cases/currencyfactor/search/CurrencyFactorFindRateByDate';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';
import TerritorySearch from '@/core/components/shared/TerritorySearch.vue';
import { EntityFindById } from '@/settings/application/uses_cases/entity/search/EntityFindById';
import QuotationTemplate from './addCargoDetails/document/QuotationTemplate.vue';

interface Lists {
  containerList?: Containers[];
  originList?: Territories[];
  destinationList?: Territories[];
  commodityList?: CommodityType[];
  packagingList?: PackingUnits[];
  referencesList?: any[];
  typeOperationList?: TypeOperation[];
  locationList?: ViewLocation[];
  weightList?: any[];
  commodityClassesList?: CommodityClass[];
  customerList?: Entity[];
  billOfLandingList?: BillOfLanding[];
  singleReferencesList?: References[];
}

@Component({
  name: 'FreightQuotationForm',
  components: { AddCargoDetails, ServicesList, AddServices, CustomTableN, TerritorySearch, QuotationTemplate }
})
export default class FreightQuotationForm extends Vue {
  @Inject(TYPES.API_VIEW_FIND_TERRITORIES_BY_QUERY)
  readonly searchTerritoriesByQueryParameter!: SearchTerritoryByQueryDescription;
  @Inject(TYPES.COMMODITY_TYPE_FIND_BY_STATUS)
  readonly searchActiveCommodities!: FindCommodityTypeByStatus;
  @Inject(TYPES.FIND_CONTAINER_BY_STATUS)
  readonly searchActiveContainers!: FindAllContainersByStatus;
  @Inject(TYPES.FINDALL_WEIGHT)
  readonly searchWeightUnits!: WeightFindAll;
  @Inject(TYPES.FIND_PACKING_UNITS_BY_STATUS)
  readonly searchActivePackingUnits!: FindAllPackingUnitsByStatus;
  @Inject(TYPES.FINDALL_REFERENCE)
  readonly findAllServiceReferences!: ReferenceFindAll;
  @Inject(TYPES.FINDALL_INCOTERMS)
  readonly incotermsFindAll!: IncotermsFindAll;
  @Inject(TYPES.FINDALL_CURRENCY)
  readonly currencyFindAllcall!: CurrencyFindAll;
  @Inject(TYPES.FINDALL_CATEGORY_REFERENCE_SERVICE)
  readonly findAllServiceOnlyReferences!: ReferenceFindAllServices;
  @Inject(TYPES.FINDALL_INCOTERMS)
  readonly findAllIncoterms!: IncotermsFindAll;
  @Inject(TYPES.FINDALL_TYPE_OPERATION)
  readonly findTypeOperations!: OperationFindAll;
  @Inject(TYPES.FINDALL_TRANSPORT)
  readonly findTransport!: TransportFindAll;
  @Inject(TYPES.ENTITY_FIND_BY_DESCRIPTION_LIKE)
  readonly findEntityByDescription!: EntityFindFilter;
  @Inject(TYPES.FINDALL_REFERENCE)
  readonly findAllReferences!: ReferenceFindAll;
  @Inject(TYPES.FINDALL_CURRENCY_WITH_RATE)
  readonly findAllCurrencyWithRate!: CurrencyFindAllWithRate;
  @Inject(TYPES.FINDRATEBYDATE_CURRENCY_FACTOR)
  readonly findRateByDay!: CurrencyFactorFindRateByDate;
  @Inject(TYPES.API_ENTITY_FIND_BY_ID)
  readonly findPreEntityById!: EntityFindById;
  @Inject(TYPES.FINDBYID_COMPANY)
  readonly findByIdCompany!: CompanyFindById;

  //PROPS
  @Prop({ required: true }) form!: any; /*WmsQuotationOrder*/
  @Prop({ required: true }) componentType!: string;
  @Prop({ required: true }) module!: string;
  @Prop({ required: false }) readonly actions!: { save: Function; clear: Function };
  @Prop() forView!: boolean;

  //LISTAS
  originList: Territories[] = [];
  destinationList: Territories[] = [];
  additionalServices: References[] = [];
  servicesCategories: any[] = [];
  incoterms: Incoterms[] = [];
  nameCurrencies: Currency[] = [];
  typeOperationList: TypeOperation[] = [];
  typeTransportList: Transport[] = [];

  $refs!: {
    requestInfoP1: InstanceType<typeof ValidationObserver>;
    requestInfoP2: InstanceType<typeof ValidationObserver>;
  };

  //Objeto de listas para el componente cargo details
  //Listas
  lists: Lists = {
    containerList: [],
    originList: [],
    destinationList: [],
    commodityList: [],
    packagingList: [],
    referencesList: [],
    typeOperationList: [],
    locationList: [],
    weightList: [],
    commodityClassesList: [],
    billOfLandingList: [],
    customerList: [],
    singleReferencesList: []
  };

  //DATOS
  isLoading = false;
  modalType = 'main';
  orderTypes = OrderType;
  showBulk!: WmsOrderLine;
  activeTab = 0;

  logoImg = localStorage.getItem('urlLogoCompany') as string;
  companyName = '';
  companyAdress = '';
  companyPhoneNumber = '';
  companyEmail = '';
  companyZipCode = '';

  //SELECTS
  selectedOrigin: Territories | null = null;
  selectedDestination: Territories | null = null;

  //VARIABLES PARA INTERVALO DE BUSQUEDA
  timeoutOrigin: any;
  timeoutDestination: any;
  searchInterval = 600;
  timeoutEntity: any;

  //Forms para detalles de la carga
  forms = {
    newBulk: new Bulk(),
    newContainer: new Bulk(),
    newBox: new Bulk(),
    newReference: new Bulk()
  };

  formsForView = {
    newBulk: new Bulk(),
    newContainer: new Bulk(),
    newBox: new Bulk(),
    newReference: new Bulk()
  };

  //Objeto de acciones para servicios principales
  servicesAction = {
    deleteS: this.deleteteS
  };

  //Objeto de acciones para la tabla de items
  tableActions = {
    customActions: [
      { title: `${this.$t('general.delete')}`, icon: 'fa fa-pencil', variant: 'secondary', action: this.loadBulk },
      { title: `${this.$t('general.delete')}`, icon: 'fa fa-trash', variant: 'danger', action: this.deleteItem },
      { title: `${this.$t('general.details')}`, icon: 'fa fa-eye', variant: 'warning', action: this.showItem }
    ]
  };

  /**
   * Funcion que llama toda la info de la empresa en el modal
   */
  created(): void {
    this.getCompanyInfo();
  }

  //Ciclo de vida del componente
  mounted() {
    if (this.forView) return;
    this.getCommodities();
    this.getContainers();
    this.getWeightUnits();
    this.getPackingUnits();
    this.getIncoterms();
    this.currencyFindAll();
    this.getTypeOperation();
    this.findAllTransport();
    this.findReferences();
  }

  //Getter para obtener los campos a mostra en la tabla
  get fields() {
    return [
      {
        label: `${this.$t('general.description')}`,
        field: 'shippingDetails.productName'
      },
      {
        label: `${this.$t('general.reference')}`,
        field: 'reference',
        formatFn: (item: References) => (item ? `${item.code} - ${item.filterBrand}` : '-')
      },
      {
        label: `${this.$t('general.commodity')}`,
        field: 'shippingDetails.commodity.description'
      },
      {
        label: `${this.$t('general.dimentions')}`,
        field: (row: Bulk) => `${row.length}x${row.width}x${row.height}`
      },
      {
        label: `${this.$t('general.weight')}`,
        field: 'weight'
      },
      {
        label: `${this.$t('general.weightUnit')}`,
        field: 'weightUnit',
        formatFn: (item: any) => (item ? item.description : `-`)
      },
      {
        label: `${this.$t('general.packaging')}`,
        field: (item: Bulk) =>
          item.packaging ? item.packaging.unit?.description : item.packingUnit ? item.packingUnit.description : '-'
      },
      {
        label: `${this.$t('general.type')}`,
        field: 'quotationTypeLine',
        formatFn: (item: string) => this.$t(`general.${item}`)
      },
      {
        label: `${this.$t('general.quantity')}`,
        field: 'quantity'
      },
      {
        label: `${this.$t('general.status')}`,
        field: 'active',
        class: 'col-1',
        formatFn: (value: boolean) => (value ? this.$t('general.active') : this.$t('general.inactive')),
        tdClass: (row: WmsOrderLine) => !row.active && 'bg-danger text-white'
      },
      {
        label: `${this.$t('general.actions')}`,
        field: 'actions',
        class: 'col-4',
        hidden: this.forView
      }
    ];
  }

  //Getter para obtener los lines activos en la lista
  get activeLines() {
    return this.form.lines.filter((item: WmsOrderLine) => item.active);
  }

  //Getter para saber si el componente es de registro o de cotizacion
  get isQuotation() {
    return this.componentType == 'quotation';
  }

  get processTypeSelect() {
    if (this.form.typeOperationData == null) return 0;
    return this.form.typeOperationData.processType;
  }

  set processTypeSelect(newValue: number) {
    this.form.typeOperation = newValue;
  }

  get typeOperationOptions() {
    return [
      {
        value: 1,
        html: `<strong>${this.$t('general.importation')}</strong><p>${this.$t('quotation.importationDescription')}</p>`
      },
      {
        value: 2,
        html: `<strong>${this.$t('general.exportation')}</strong><p>${this.$t('quotation.importationDescription')}</p>`
      },
      {
        value: 3,
        html: `<strong>${this.$t('general.localOperation')}</strong><p>${this.$t(
          'quotation.importationDescription'
        )}</p>`
      }
    ];
  }

  //Getter para procesar los servicios adicionales de forma compatible con el backend
  get filteredAditionalServices() {
    return this.servicesCategories
      .map(category => {
        const serviceCategory = new WmsfOrderCategory();
        serviceCategory.category = category;
        serviceCategory.type = 'additional';
        serviceCategory.references = category.referencesList.map((reference: any) => {
          const serviceReference = new WmsfOrderReferences();
          serviceReference.reference = reference;
          serviceReference.category = category;
          serviceReference.type = 'additional';
          return serviceReference;
        });
        return serviceCategory;
      })
      .filter(category => category.references.length > 0);
  }

  //Getter para procesar categorias adicionales de forma compatible con el backend
  get filteredCategoriesServices() {
    if (!this.form.typeTransport || !this.form.typeOperationData) return [];
    return this.servicesCategories
      .map(category => {
        const serviceCategory = new WmsfOrderCategory();
        serviceCategory.category = category;
        serviceCategory.type = 'categories';
        serviceCategory.references = category.referencesList
          .map((reference: any) => {
            const serviceReference = new WmsfOrderReferences();
            serviceReference.reference = reference;
            serviceReference.category = category;
            serviceReference.type = 'categories';
            return serviceReference;
          })
          .filter((reference: any) => {
            return (
              reference.reference.transportMethod == this.form.typeTransport?.name.toLowerCase() &&
              reference.reference?.processType?.id == this.form.typeOperationData?.processType
            );
          });
        return serviceCategory;
      })
      .filter(category => category.references.length > 0);
  }

  //Getter para filtrar los servicios principales que ya han sido agregados
  get filteredMainServices() {
    if (!this.form.typeTransport || !this.form.typeOperationData) return [];
    return this.servicesCategories
      .map(category => {
        const serviceCategory = new WmsfOrderCategory();
        serviceCategory.category = category;
        serviceCategory.type = 'main';
        serviceCategory.references = category.referencesList
          .map((reference: any) => {
            const serviceReference = new WmsfOrderReferences();
            serviceReference.reference = reference;
            serviceReference.category = category;
            serviceReference.type = 'main';
            return serviceReference;
          })
          .filter((reference: any) => {
            return (
              reference.reference.transportMethod == this.form.typeTransport?.name.toLowerCase() &&
              reference.reference.processType?.id == this.form.typeOperationData?.processType
            );
          });
        return serviceCategory;
      })
      .filter(category => category.references.length > 0);
  }

  get activeMainServices() {
    return this.form.mainServices.filter((item: WmsfOrderCategory) => item.active);
  }
  get activeServiceCategories() {
    return this.form.categories.filter((item: WmsfOrderCategory) => item.active);
  }
  get activeAdditionalServices() {
    return this.form.additionalServices.filter((item: WmsfOrderCategory) => item.active);
  }

  totalMainCurrencyByCategory(category: WmsfOrderCategory) {
    let suma = 0;
    category.references.map(reference => {
      suma += parseFloat(reference.price.toString());
    });

    return Number(suma.toFixed(2));
  }

  get TotalMainCurrencyMainCategory() {
    let suma = 0;

    this.form.mainServices.map((reference: WmsfOrderReferences) => {
      suma += parseFloat(reference.price.toString());
    });

    return Number(suma.toFixed(2));
  }

  get totalOrderMainCurrencyNoTaxes() {
    let suma = 0;
    this.form.categories.map((category: any) => {
      category.references.map((reference: any) => {
        suma += parseFloat(reference.price.toString());
      });
    });

    return Number(suma + this.totalAdditionalServices + this.totalMainServices).toFixed(2);
  }

  get totalMainServices() {
    let suma = 0;
    this.form.mainServices.map((category: any) => {
      category.references.map((reference: any) => {
        suma += parseFloat(reference.price.toString());
      });
    });

    return Number(suma.toFixed(2));
  }

  get totalAdditionalServices() {
    let suma = 0;
    this.form.additionalServices.map((category: any) => {
      category.references.map((reference: any) => {
        suma += parseFloat(reference.price.toString());
      });
    });

    return Number(suma.toFixed(2));
  }

  get totalOrderMainCurrencyTaxes() {
    let suma = 0;
    this.form.categories.map((category: any) => {
      category.references.map((reference: any) => {
        const tax = reference.reference?.saleTax || 0;
        suma += parseFloat(reference.price.toString()) * (1 + tax / 100);
      });
    });

    this.form.mainServices.map((category: any) => {
      category.references.map((reference: any) => {
        const tax = reference.reference?.saleTax || 0;
        suma += parseFloat(reference.price.toString()) * (1 + tax / 100);
      });
    });

    this.form.additionalServices.map((category: any) => {
      category.references.map((reference: any) => {
        const tax = reference.reference?.saleTax || 0;
        suma += parseFloat(reference.price.toString()) * (1 + tax / 100);
      });
    });

    return Number(suma.toFixed(2));
  }

  currencyFindAll() {
    this.findAllCurrencyWithRate
      .execute()
      .then((response: any) => {
        this.nameCurrencies = response;
      })
      .catch((error: any) => {
        throw new Error(error);
      });
  }

  //Funcion para obtener las listas de servicios cuando se abre el modal
  fetchData() {
    this.getServiceItems();
  }

  //Funcion invocada para eliminar una categoria de la lista
  deleteServiceCategory(index: number, item: any, list: string) {
    if (item.number) {
      item.active = false;
      return;
    }
    this.form[list].splice(index, 1);
  }

  //Funcion invocada para eliminar un Servicio  a la lista
  deleteteS(index: number, item: any, list: any[]) {
    if (item.number != 0) {
      item.active = false;
      return;
    }
    list.splice(index, 1);
  }

  //Funcion invocada para buscar los territorios
  async getCities(query: string) {
    this.isLoading = true;
    const res = await this.searchTerritoriesByQueryParameter.execute(query.toUpperCase());
    this.isLoading = false;
    return res;
  }

  //Funcion invocada para buscar los tipos de operaciones y cargar el maestro
  async getTypeOperation() {
    try {
      this.isLoading = true;
      const res = await this.findTypeOperations.execute();
      this.typeOperationList = res.length > 0 ? (res as any) : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  // Este método es llamado cuando el usuario escribe sobre el input de origen o destino para buscar territorios.
  findTerritoriesFactory(query: string, input: string, delayed = true): void {
    //Limpiamos cualquier función anterior en el timeout para evitar búsquedas repetitivas y solapadas.
    delayed ? clearTimeout(this.timeoutOrigin) : null;
    this.timeoutOrigin = setTimeout(
      () => {
        (async () => {
          if (query.length >= 3) {
            try {
              const res = await this.getCities(query);
              input == 'origin' ? (this.originList = res) : (this.destinationList = res);
            } catch (error) {
              this.isLoading = false;
              throw new Error(`${error}`);
            }
          }
          clearTimeout(this.timeoutOrigin);
        })();
      },
      delayed ? this.searchInterval : 0
    );
  }

  //Funcion invocada para buscar commodities activos
  async getCommodities() {
    try {
      this.isLoading = true;
      const res = await this.searchActiveCommodities.execute(true);
      res.length > 0 ? (this.lists.commodityList = res) : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para buscar containers activos
  async getContainers() {
    try {
      this.isLoading = true;
      const res = await this.searchActiveContainers.execute(true);
      res.length > 0 ? (this.lists.containerList = res) : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para buscar todos las unidades de peso
  async getWeightUnits() {
    try {
      this.isLoading = true;
      const res = await this.searchWeightUnits.internalExecute();
      res.length > 0 ? (this.lists.weightList = res) : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para buscar todos las unidades de peso
  async getPackingUnits() {
    try {
      this.isLoading = true;
      const res = await this.searchActivePackingUnits.execute(true);
      res.length > 0 ? (this.lists.packagingList = res) : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  // Funcion invocada para setear los valores del embalaje en la linea seleccionada
  setPackingDimensions(packing: PackingUnits, box: any) {
    box.length = packing.length;
    box.width = packing.width;
    box.height = packing.height;
  }

  //Funcion invocada para buscar los items marcados como servicio adicional
  async getServiceaditionalItems() {
    try {
      this.isLoading = true;
      const res = await this.findAllServiceReferences.execute();
      this.additionalServices = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para buscar los items marcados como servicio solamente
  async getServiceItems() {
    try {
      this.isLoading = true;
      const res = await this.findAllServiceOnlyReferences.execute(this.modalType);
      this.servicesCategories = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para buscar los items marcados como servicio solamente
  async getIncoterms() {
    try {
      this.isLoading = true;
      const res = await this.incotermsFindAll.execute();
      this.incoterms = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para generar un payload compatible
  async save() {
    const validated = (await this.$refs.requestInfoP1.validate()) && (await this.$refs.requestInfoP2.validate());
    validated && this.actions.save();
  }

  //Funcion ejecutada para limpiar el formulario
  clear(): void {
    this.actions.clear();
  }

  goToList() {
    this.$router.push({ name: 'Requests' });
  }

  //Funcion invocada para validar un input
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  //Funcion invocada para agregar un bulto
  addBulk(item: Bulk, quantity: number, form: string) {
    const newBulk = item;
    newBulk.quotationTypeLine = form;
    newBulk.quantity = quantity;
    const bulksNumber = this.form.lines.length + 1;
    const newBulkCopy = Object.assign({}, item);
    newBulkCopy.number = bulksNumber;
    this.form.lines.push(newBulkCopy);
    this.clearBulkForm(form);
  }

  //Funcion invocada para limpiar el formulario de adicionar bulto
  clearBulkForm(form?: string) {
    switch (form) {
      case 'container':
        this.forms.newContainer = new Bulk();
        break;
      case 'bulk':
        this.forms.newBulk = new Bulk();
        break;
      case 'box':
        this.forms.newBox = new Bulk();
        break;
      case 'reference':
        this.forms.newReference = new Bulk();
        break;
      default:
        this.forms = {
          newBulk: new Bulk(),
          newContainer: new Bulk(),
          newBox: new Bulk(),
          newReference: new Bulk()
        };
        break;
    }
  }

  //Funcion invocada para obtener todos los metodos de transporte
  async findAllTransport() {
    try {
      this.isLoading = true;
      const res = await this.findTransport.execute();
      this.typeTransportList = res.length > 0 ? res.reverse() : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para editar un bulto de la lista
  loadBulk(item: Bulk) {
    switch (item.quotationTypeLine) {
      case 'container':
        this.forms.newContainer = Object.assign(new Bulk(), item);
        break;
      case 'box':
        this.forms.newBox = Object.assign(new Bulk(), item);
        break;
      case 'bulk':
        this.forms.newBulk = Object.assign(new Bulk(), item);
        break;
      case 'reference':
        this.formsForView.newReference = Object.assign(new Bulk(), item);
        break;
      default:
        return;
    }
    this.deleteItem(item);
    this.$toasted.show(
      `${this.$t('general.loadedToSection', { section: this.$t(`general.${item.quotationTypeLine}`) })}`
    );
  }

  //Funcion invocada para eliminar un grupo de bultos
  deleteItem(item: any) {
    try {
      if (item.sequence) {
        this.form.lines[item.originalIndex].active = false;
        return;
      }
      this.form.lines.splice(item.originalIndex, 1);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  /**
   * Funcion invocada para buscar entidades basadas en el nombre
   * @param {string} query  Nombre de la entidad
   * @returns {void}
   */
  findEntity(query: string): void {
    try {
      clearTimeout(this.timeoutEntity);
      if (query.length < 2) return;
      const params: any = {
        text: query,
        type: ['SUPPLIER', 'CUSTOMER', 'SERVICE', 'OTHERS']
      };
      this.timeoutEntity = setTimeout(() => {
        (async () => {
          this.isLoading = true;
          const res = await this.findEntityByDescription.execute(params);
          this.lists.customerList = res.length > 0 ? res : [];
          this.isLoading = false;
          clearTimeout(this.timeoutEntity);
        })();
      }, this.searchInterval);
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
  /**
   * Funcion invocada para buscar pre - entidades basadas en el id
   * @param {string} query  Nombre de la entidad
   * @returns {void}
   */
  findPreEntity(identification: string): void {
    clearTimeout(this.timeoutEntity);
    if (identification.length < 1) return;
    this.timeoutEntity = setTimeout(() => {
      this.isLoading = true;
      this.findPreEntityById
        .internalExecute(Number(identification))
        .then(res => {
          !('message' in res) ? (this.form.customer = res) : null;
          this.isLoading = false;
          clearTimeout(this.timeoutEntity);
        })
        .catch(error => {
          throw new Error(`${error}`);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }, this.searchInterval);
  }

  //Funcion invocada para obtener todas las referencias
  async findReferences() {
    try {
      this.isLoading = true;
      const res = await this.findAllReferences.execute();
      this.lists.singleReferencesList = res ?? [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para setear el rate ultimo de la moneda en el form
  async setRate(input: any) {
    try {
      this.isLoading = true;
      const res = await this.findRateByDay.execute({
        currency: input.currencyCode,
        date: this.form.quotationDate
      });

      if ('message' in res) {
        this.$swal({
          title: `${this.$t('general.warning')}`,
          text: `${this.$t('general.noRateOnDb')}`,
          icon: 'warning'
        });
      }

      this.form.exchangeRate = res.rate ?? 0;

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.form.exchangeRate = 0;
      throw new Error(`${error}`);
    }
  }

  showItem(item: WmsOrderLine) {
    this.formsForView = {
      newBulk: new Bulk(),
      newContainer: new Bulk(),
      newReference: new Bulk(),
      newBox: new Bulk()
    };
    switch (item.quotationTypeLine) {
      case 'container':
        this.formsForView.newContainer = Object.assign(new Bulk(), item);
        this.activeTab = 0;
        break;
      case 'box':
        this.formsForView.newBox = Object.assign(new Bulk(), item);
        this.activeTab = 1;
        break;
      case 'bulk':
        this.formsForView.newBulk = Object.assign(new Bulk(), item);
        this.activeTab = 2;
        break;
      case 'reference':
        this.formsForView.newReference = Object.assign(new Bulk(), item);
        this.activeTab = 3;
        break;
      default:
        return;
    }
    this.$toasted.show(
      `${this.$t('general.loadedToSection', { section: this.$t(`general.${item.quotationTypeLine}`) })}`
    );
    this.$bvModal.show('mdShowItem');
  }

  async getCompanyInfo() {
    try {
      this.isLoading = true;
      const companyId = localStorage.getItem('businessId') as string;
      const res: any = await this.findByIdCompany.execute(companyId);
      this.isLoading = false;
      this.companyName = res.businessName;
      this.companyAdress = res.address;
      this.companyPhoneNumber = res.phoneNumber;
      this.companyEmail = res.email;
      this.companyZipCode = res.zipCode;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada en el componente de territorios para setear los datos de la direccion
  setAddressDataToCustomer(territory: Territories) {
    this.form.customer.mapAddressFromTerritory(territory);
  }
}
