export class Country {
  active: boolean;
  code: string;
  createAt: string;
  currencyCode: string;
  currencyName: string;
  name: string;
  updateAt: string;
  public constructor() {
    this.active = false;
    this.code = '';
    this.createAt = '';
    this.currencyCode = '';
    this.currencyName = '';
    this.name = '';
    this.updateAt = '';
  }
}
