
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { TopInformationCards } from '@/settings/domain/topInformationCards/TopInformationCards';

@Component({
  name: 'AppTopInformationCards'
})
export default class AppTopInformationCards extends Vue {
  @Prop() stats!: TopInformationCards;
}
