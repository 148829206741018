import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { GroupReferences } from '@/wms/domain/category/groupReferences/GroupReferences';
import { GroupReferencesRepository } from '@/wms/domain/category/groupReferences/GroupReferencesRepository';

@Injectable()
export class GroupReferenceDelete extends Command<Promise<any>, GroupReferences> {
  public constructor(
    @Inject(TYPES.GROUP_REFERENCE_REPOSITORY)
    private readonly groupReferencesRepository: GroupReferencesRepository
  ) {
    super();
  }

  internalExecute(groupReferences: GroupReferences): Promise<any> {
    return this.groupReferencesRepository.delete(groupReferences);
  }
}
