
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { EmailTypeFindAll } from '@/settings/application/uses_cases/emailType/search/EmailTypeFindAll';
import { EmailType } from '@/settings/domain/emailType/EmailType';
import Vue from 'vue';
import Component from 'vue-class-component';
import EmailTypeCard from './EmailTypeCard.vue';
@Component({
  name: 'EmailTypeCenter',
  components: { EmailTypeCard }
})
export default class EmailTypeCenter extends Vue {
  @Inject(TYPES.EMAIL_TYPE_FIND_ALL)
  readonly findAllEmailTypes!: EmailTypeFindAll;

  /**
   * Variable usada para activar el loader en la pagina
   */
  isLoading = false;

  /**
   * Variable usada para almacenar los tipos de Email disponibles
   */
  emailTypes: EmailType[] = [];
  mounted() {
    this.findEmailTypes();
  }

  /**Funcion usada para obtener todos los tipos de email disponibles para configurar */
  async findEmailTypes() {
    try {
      this.isLoading = true;
      const res = (await this.findAllEmailTypes.execute()) ?? [];
      this.emailTypes = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
}
