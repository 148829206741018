
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { CreateFileV2 } from '@/settings/application/uses_cases/file/create/CreateFileV2';
import { FindFile } from '@/settings/application/uses_cases/file/search/FindFile';
import { FindAllPackingUnits } from '@/settings/application/uses_cases/packing_units/search/FindAllPackingUnits';
import { ConceptsContainer } from '@/settings/domain/containers/conceptsContainer/ConceptsContainer';
import { Containers } from '@/settings/domain/containers/Containers';
import { PackingUnits } from '@/settings/domain/packing_units/PackingUnits';
import { FindTransactionalActivityById } from '@/tracking/application/uses_cases/activitiesTransactional/search/FindTransactionalActivityById';
import { ActivityTransactional } from '@/tracking/domain/activitiesTransactional/ActivityTransactional';
import { ActivityTransactionalForTraceability } from '@/tracking/domain/activitiesTransactional/Projections/ActivityTransactionalForTraceability';
import { PreRegisterForTraceabilityPanel } from '@/wms/domain/preRegister/PreRegisterForTraceabilityPanel';
import TransactionalEventForTraceability from '@/wms/domain/preRegister/TransactionalEventForTraceability';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import FooterModal from './FooterModals/FooterModal.vue';
import HeaderModal from './HeaderModals/HeaderModal.vue';

@Component({
  name: 'ModalC1',
  components: { HeaderModal, FooterModal }
})
export default class ModalC1 extends Vue {
  @Inject(TYPES.FIND_ALL_PACKING_UNITS)
  readonly getAllPackingUnits!: FindAllPackingUnits;
  @Inject(TYPES.SAVE_FILE)
  readonly createFile!: CreateFileV2;
  @Inject(TYPES.FIND_FILE_BY_NAME)
  readonly findFileByName!: FindFile;
  @Inject(TYPES.ACTIVITIES_TRANSACTIONAL_FIND_BY_ID)
  private readonly findActivityById!: FindTransactionalActivityById;

  @Prop() isLoading!: boolean;
  @Prop() switchId!: string;
  @Prop() idModal!: string;
  @Prop() activityData!: ActivityTransactionalForTraceability | null;
  @Prop() eventData!: TransactionalEventForTraceability;
  @Prop({ type: Function }) saveProcess!: (activity: ActivityTransactional) => void;
  @Prop() attachedFiles!: any[]; // #TODO: Toca cambiar el tipo de dato
  @Prop({ required: true }) master!: PreRegisterForTraceabilityPanel;
  @Prop({ type: Function }) searchActivities!: () => Promise<void>;

  modalActivityData: ActivityTransactional | null = null;

  loadingContainers = false;
  containers: PackingUnits[] = [];
  photos: File[] = [];
  conceptSelected: ConceptsContainer | null = null;
  containerSelected: PackingUnits | null = null;

  showModal = true;

  $refs!: {
    containerPhotos: HTMLFormElement;
  };

  @Watch('attachedFiles')
  onMyFilesChange() {
    // Debemos hacer que el padre actualice el valor de attachedFiles
    this.$emit('update:attachedFiles', this.attachedFiles);
  }

  get modalTitle() {
    return `${this.eventData.description} - ${this.$t('switchs.' + this.activityData?.sw)} - ${this.idModal}`;
  }

  get localIsLoading() {
    return this.isLoading;
  }

  set localIsLoading(value) {
    this.$emit('update:isLoading', value);
  }

  get sortConceptsContainer() {
    return this.containerSelected?.concepts.sort((a, b) => a.sequence - b.sequence);
  }

  get indoorConcepts() {
    return this.sortConceptsContainer?.filter(concept => concept.conceptType === 'indoor');
  }

  get outdoorConcepts() {
    return this.sortConceptsContainer?.filter(concept => concept.conceptType === 'outdoor');
  }

  get enableSave() {
    return this.sortConceptsContainer
      ?.filter(concept => concept.isRequired)
      .every(concept => concept.valuation && concept.valuation !== null);
  }

  get eventExecuted() {
    return this.eventData.state === 'EXECUTED';
  }

  async findData() {
    try {
      if (!this.activityData) return;

      this.localIsLoading = true;

      this.modalActivityData = await this.findActivityById.execute({
        activityId: this.activityData?.activityId,
        eventId: this.eventData.eventId,
        preRegisterNumber: this.master.number,
        preRegisterType: this.master.type
      });
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.localIsLoading = false;
    }
  }

  mounted() {
    this.findContainers();
  }

  async setFormData() {
    // Debemos verificar si la actividad ya fue ejecutada para setear los valores de los conceptos y los containers ejecutados
    // ademas debemos verificar si la lista de inspecciones de carga tiene elementos para setear el primer container
    await this.findData();
    if (
      this.modalActivityData &&
      this.modalActivityData.executed &&
      this.modalActivityData.freightInspectionList &&
      this.modalActivityData?.freightInspectionList?.length > 0
    ) {
      // Si la actividad ya fue ejecutada, debemos setear los valores de los conceptos y los containers ejecutados
      this.containerSelected = this.modalActivityData.freightInspectionList[0].packingUnits;

      if (this.modalActivityData?.freightInspectionList && this.containerSelected) {
        this.containerSelected.concepts = this.modalActivityData.freightInspectionList;
      }
    }
  }

  customContainerLabel(container: Containers) {
    return `${container.id} - ${container.description}`;
  }

  evaluationOptions(concept: ConceptsContainer) {
    return concept.evaluationMethod === 'value'
      ? JSON.parse(concept.listValues.toString())
      : JSON.parse(concept.listConceptual.toString());
  }

  async findContainers() {
    try {
      this.loadingContainers = true;

      const allContainers = await this.getAllPackingUnits.execute();
      if ('error' in allContainers || 'message' in allContainers) {
        this.loadingContainers = false;
        return;
      }

      this.containers = allContainers.filter((container: PackingUnits) => container.concepts.length > 0);
      this.loadingContainers = false;
    } catch (error) {
      this.loadingContainers = false;
      throw new Error(`${error}`);
    }
  }

  async setConcept(concept: ConceptsContainer) {
    this.conceptSelected = concept;

    if (!this.conceptSelected?.photos) {
      this.conceptSelected.photos = [];
    }

    // Si ya tiene imagenes asociadas, debemos buscarlas y obtener los blob
    const promises = concept?.photos?.map(async photo => {
      return await fetch(this.findFileByName.execute(photo)).then(response => response.blob());
    });

    const blobFiles = await Promise.all(promises);
    this.photos = blobFiles.map((blob, index) => {
      const extension = concept.photos[index].split('.').pop();
      return new File([blob], `FI-${index}.${extension}`, { type: `image/${extension}` });
    });

    this.$bvModal.show(`modal-comments`);
  }

  /**
   * @param conceptSelected
   * @description Guarda los comentarios de la inspección de un concepto seleccionado
   */
  async saveCommentsModal(e: Event) {
    e.preventDefault();
    try {
      if (!this.modalActivityData) return;
      const files = this.$refs.containerPhotos?.files;

      this.localIsLoading = true;
      if (files && files?.length > 0) {
        const folderFile = `${this.master.type.replaceAll('_', '')}${this.master.number}_E${this.eventData.eventId}_A${
          this.modalActivityData.activityId
        }_C${this.conceptSelected?.conceptCode}/`;

        const newFiles = files.map((file: File, index: number) => {
          return new File([file], folderFile + `FI-` + index + '.' + file.name.split('.').pop(), {
            type: file.type
          });
        });

        const response = await this.createFile.execute({
          files: newFiles,
          folder: 'activity'
        });

        if (this.conceptSelected) {
          if (Array.isArray(response)) {
            this.conceptSelected.photos = response;
          } else {
            this.conceptSelected.photos = [response];
          }
          this.photos = [];
        }
      }
      this.$nextTick(() => {
        this.$bvModal.hide('modal-comments');
      });
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.localIsLoading = false;
    }
  }

  closeCommentsModal() {
    this.conceptSelected = null;
    // this.containerSelected = null;
    this.photos = [];
  }

  getImage(file: File) {
    return window.URL.createObjectURL(file);
  }

  deleteFile(index: number) {
    this.photos.splice(index, 1);
  }

  setPhotosToInput() {
    // this.setFormData();
    this.$nextTick(() => {
      this.$refs.containerPhotos.files = this.photos;
    });
  }

  saveLocal(e: Event) {
    e.preventDefault();
    if (!this.modalActivityData) return;
    if (this.containerSelected && this.containerSelected.concepts) {
      this.modalActivityData.freightInspectionList = this.containerSelected?.concepts;
    }

    this.saveProcess(this.modalActivityData);
  }
}
