import { AddressLine } from '../../address/AddressLine';
import { CourierPriceListRepository } from '../../courierPriceList/CourierPriceListRepository';
import { Currency } from '../../currency/Currency';
import { Bank } from '../../ParmsGeneral/bank/Bank';
import { Contact } from '../../ParmsGeneral/contact/Contact';
import { GroupAgentService } from '../../ParmsGeneral/groupAgentService/GroupAgentService';
import { Incoterms } from '../../ParmsGeneral/Incoterms/Incoterms';
import { PaymentMethods } from '../../ParmsGeneral/paymentMethods/PaymentMethods';
import { TermsPayment } from '../../ParmsGeneral/termsPayment/TermsPayment';
import { Territories } from '../../views/territories/Territories';
import { Warehouse } from '../../warehouse/Warehouse';
import { EntityTypeIdentification } from '../entityTypeIdentification/EntityTypeIdentification';
import { TypeCustomer } from '../typeCustomer/TypeCustomer';
import { TypeIdentification } from '../typeIdentification/TypeIdentification';

export class Entity {
  accountNumber: string;
  address: AddressLine | null;
  addressEntityList: AddressLine[];
  bank: Bank | null;
  branchCode: string;
  code: number;
  commercialNumber: string;
  contacts: Contact[];
  createAt: string;
  createUser: boolean;
  currency: Currency | null;
  delivery: string;
  discountPercentage: number;
  division3: string;
  email: string;
  fein: string;
  fullName: string;
  groupAgentService: GroupAgentService | null;
  id: string;
  incoterms: Incoterms | null;
  numberVL: number;
  originCountry: string;
  paymentBlock: string;
  paymentMethods: PaymentMethods | null;
  phone1: string;
  placePerformance: string;
  prefix: string;
  reconciliationAccount: string;
  searchTerm1: string;
  searchTerm2: string;
  taxId: string;
  termsPayment: TermsPayment | null;
  termsPaymentAdditional: TermsPayment | null;
  typeCustomer: TypeCustomer | null;
  typeIdentification: TypeIdentification | null;
  updateAt: string;
  urlImage: string;
  username: string;
  vendorNumber: string;
  warehouse: Warehouse | null;
  largeContributor: boolean; // Gran Contribuyente
  selfWithholdingTax: boolean; // Retención en la fuente
  salesTaxExemption: boolean; // Exoneración impuesto de ventas
  generateTaxesOverSales: boolean; // Generar impuestos sobre ventas
  nonProfitOrganizations: boolean; // Entidades sin fines de lucro
  jurididicalPerson: boolean; // Persona jurídica
  naturalPerson: boolean; // Persona natural
  ivaResponsability: boolean; // Responsable de iva
  internationalComerceSocialProvider: boolean; // Proveedor de comercio internacional social
  simpleRegimen: boolean; // Régimen simplificado
  foreigner: boolean; // Extranjero
  company: string; // Empresa,
  otherTypeIdentificationEntityList: EntityTypeIdentification[];
  idCode: number | null;
  courierPriceList: CourierPriceListRepository | null;
  freeDays: number;

  public constructor() {
    this.code = 0;
    this.id = '';
    this.fullName = '';
    this.email = '';
    this.phone1 = '';
    this.typeCustomer = null;
    this.typeIdentification = null;
    this.originCountry = '';
    this.address = new AddressLine();
    this.contacts = [];
    this.incoterms = null;
    this.placePerformance = '';
    this.currency = null;
    this.termsPayment = null;
    this.delivery = '';
    this.bank = new Bank();
    this.groupAgentService = null;
    this.termsPaymentAdditional = null;
    this.reconciliationAccount = '';
    this.paymentBlock = '';
    this.branchCode = '';
    this.paymentMethods = null;
    this.vendorNumber = '';
    this.searchTerm1 = '';
    this.searchTerm2 = '';
    this.taxId = '';
    this.fein = '';
    this.commercialNumber = '';
    this.addressEntityList = [];
    this.accountNumber = '';
    this.prefix = '';
    this.company = '';
    this.discountPercentage = 0;
    this.otherTypeIdentificationEntityList = [];
    this.idCode = null;
    this.courierPriceList = null;
    this.freeDays = 0;
    this.warehouse = null;
    this.largeContributor = false;
    this.selfWithholdingTax = false;
    this.salesTaxExemption = false;
    this.generateTaxesOverSales = false;
    this.nonProfitOrganizations = false;
    this.jurididicalPerson = false;
    this.naturalPerson = false;
    this.ivaResponsability = false;
    this.internationalComerceSocialProvider = false;
    this.simpleRegimen = false;
    this.foreigner = false;
    this.createAt = '';
    this.createUser = false;
    this.updateAt = '';
    this.urlImage = '';
    this.username = '';
    this.division3 = '';
    this.numberVL = 0;
  }

  public mapAddressFromTerritory(territory: Territories) {
    if (this.address) {
      this.address.country = territory.country;
      this.address.state = territory.state;
      this.address.city = territory.city;
      this.address.countryName = territory.countryName;
      this.address.stateName = territory.stateName;
      this.address.cityName = territory.cityName;
    }
    return this;
  }
}
