
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'TimelineComponentFF'
})
export default class AppTimelineComponentFF extends Vue {
  //Data

  @Prop() readonly items!: any[];
  @Prop() selectedEvent!: number;
  @Prop({ default: 'planning' }) mod!: string;

  get currentEvent() {
    return this.selectedEvent ? this.selectedEvent : 0;
  }

  set currentEvent(newValue: number) {
    this.$emit('update:selectedEvent', newValue);
  }
}
