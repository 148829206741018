import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { Zone } from '@/settings/domain/zone/Zone';
import { ZoneRepository } from '@/settings/domain/zone/ZoneRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class ZoneServices implements ZoneRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_ZONE_FINDALL: string = this.API_BASE_URI + localStorageModule().config.routes.API_ZONE_FINDALL;
  private readonly API_ZONE_FINDBY_COUNTRYANDSTATEBYSTATUS: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ZONE_FINDBY_COUNTRYANDSTATEBYSTATUS;
  private readonly API_ZONE_FINDBY_COUNTRYANDSTATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ZONE_FINDBY_COUNTRYANDSTATE;
  private readonly API_ZONE_SAVE: string = this.API_BASE_URI + localStorageModule().config.routes.API_ZONE_SAVE;
  private readonly API_ZONE_UPDATE: string = this.API_BASE_URI + localStorageModule().config.routes.API_ZONE_UPDATE;
  private readonly API_ZONE_FIND_BY_DESCRIPTION_LIKE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ZONE_FIND_BY_DESCRIPTION_LIKE;

  async create(zone: Zone) {
    try {
      return await http.post<Zone[]>(this.API_ZONE_SAVE, JSON.stringify(zone));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(zone: Zone) {
    try {
      return await http.put<Zone[]>(this.API_ZONE_UPDATE, JSON.stringify(zone));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findZoneByStatusAndCountryAndState(payload: any): Promise<Zone[]> {
    try {
      return await http.get<Zone[]>(
        this.API_ZONE_FINDBY_COUNTRYANDSTATEBYSTATUS +
          `active=${payload.status}&country=${payload.country}&state=${payload.state}`
      );
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findByDescriptionLike(description: string): Promise<Zone[]> {
    try {
      return await http.get<Zone[]>(this.API_ZONE_FIND_BY_DESCRIPTION_LIKE + description);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findAll(): Promise<Zone[]> {
    try {
      return await http.get<Zone[]>(this.API_ZONE_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
