import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { StateManager } from '@/core/statemanager/StateManager';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { City } from '@/settings/domain/city/City';
import { CityRepository } from '@/settings/domain/city/CityRepository';

@Injectable()
export class DeleteCity extends Query<Promise<City>, any> {
  public constructor(
    @Inject(TYPES.CITY_REPOSITORY)
    private readonly cityServie: CityRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(payload: any): Promise<any> {
    return await this.cityServie.delete(payload);
  }
}
