import { Territories } from '@/settings/domain/views/territories/Territories';

export class Port {
  portId: string;
  portName: string;
  fullName: string;
  portAddress: string;
  portLevel: number | string;
  levelDescription: string;
  country: string;
  countryName: string;
  state: string;
  stateName: string;
  city: string;
  cityName: string;
  zipCode: string;
  zipCodeName: string;
  active: boolean;
  edit: boolean;
  origin: Territories | null;

  public constructor() {
    this.portId = '';
    this.portName = '';
    this.fullName = '';
    this.portAddress = '';
    this.portLevel = '';
    this.levelDescription = '';
    this.country = '';
    this.countryName = '';
    this.state = '';
    this.stateName = '';
    this.city = '';
    this.cityName = '';
    this.zipCode = '';
    this.zipCodeName = '';
    this.edit = true;
    this.active = true;
    this.origin = null;
  }
}
