
import Component from 'vue-class-component';
import Vue from 'vue';
import { ValidationObserver } from 'vee-validate/dist/types/components';
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { TaxeCreate } from '@/settings/application/uses_cases/taxe/create/TaxeCreate';
import i18n from '@/core/plugins/i18n';
import { Prop } from 'vue-property-decorator';
import { Taxe } from '@/settings/domain/taxe/Taxe';
import { TaxeUpdate } from '@/settings/application/uses_cases/taxe/update/TaxeUpdate';

@Component({
  name: 'AddCountry'
})
export default class AddTax extends Vue {
  @Inject(TYPES.CREATE_TAXE)
  readonly taxCreate!: TaxeCreate;
  @Inject(TYPES.UPDATE_TAXE)
  readonly taxeUpdate!: TaxeUpdate;

  target = i18n.t('general.taxes');
  isLoading = false;
  fullPage = true;
  buttonTitle = 'general.add';
  variant = 'success active';
  isPercentage = false;

  //Refs
  $refs!: {
    validationTaxes: InstanceType<typeof ValidationObserver>;
  };

  //Props
  @Prop() readonly taxes!: Taxe[];
  @Prop() form!: any;
  @Prop() readonly clear!: any;

  findTax() {
    const tax: Taxe | undefined = this.taxes.find(obj => {
      if (obj['code'] === this.form.taxCode) {
        return obj;
      }
    });

    //valid search
    if (tax) {
      this.variant = 'secondary active';
      this.buttonTitle = 'general.update';
      this.form.taxCode = tax.code;
      this.form.taxDescription = tax.description;
      this.form.taxPercentage = tax.percentage;
      this.form.taxStatus = tax.active;
      this.form.edit = true;
    }
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  factory() {
    if (this.form.edit) {
      this.editData();
    } else {
      this.submitTax();
    }
  }

  submitTax() {
    const payload: any = {
      code: this.form.taxCode.trim(),
      description: this.form.taxDescription.trim().toUpperCase(),
      percentage: this.form.taxPercentage,
      active: this.form.taxStatus,
      isPercentage: this.form.isPercentage
    };
    this.isLoading = true;
    this.taxCreate
      .execute(payload)
      .then(() => {
        this.isLoading = false;
        this.clearForm();
        this.$emit('submit-tax');
      })
      .catch((error: any) => {
        this.isLoading = false;
        throw new Error(error);
      });
  }

  editData() {
    const payload: any = {
      code: this.form.taxCode.trim(),
      description: this.form.taxDescription.trim().toUpperCase(),
      percentage: this.form.taxPercentage,
      active: this.form.taxStatus,
      isPercentage: this.form.isPercentage
    };
    this.isLoading = true;
    this.taxeUpdate
      .execute(payload)
      .then(() => {
        this.isLoading = false;
        this.clearForm();
        this.$emit('edit-data');
      })
      .catch((error: any) => {
        this.isLoading = false;
        throw new Error(`${error}`);
      });
  }

  clearForm() {
    this.clear();
    this.$refs.validationTaxes.reset();
    this.variant = 'success active';
    this.buttonTitle = 'general.add';
  }
}
