import { showAlert } from '@/core/plugins/Notifications';
import i18n from '@/core/plugins/i18n';
import { httpMethod } from './httpMethodsEnum';

const errorMessage = (response: any, method: httpMethod, payload?: any) => {
  switch (method) {
    case httpMethod.POST:
      postMessages(response.status, payload);
      break;
    case httpMethod.DELETE:
      deleteMessages(response.status, payload);
      break;
    case httpMethod.PUT:
      updateMessages(response.status, payload);
      break;
    case httpMethod.GET:
      getMessages(response.status);
      break;
  }
};

const postMessages = (code: number, payload: any) => {
  switch (code) {
    case 200:
      showAlert('success', i18n.t('generalmessage.POST_SUCCESS_MESSAGE'));
      break;
    case 201:
      showAlert('success', i18n.t('generalmessage.POST_SUCCESS_MESSAGE'));
      break;
    case 401:
      noAuth();
      break;
    case 400:
      showAlert('error', i18n.t('generalmessage.ERROR_MESSAGE_400_POST'), '', payload);
      break;
    case 500:
      showAlert('error', i18n.t('generalmessage.ERROR_MESSAGE_500_WITHOUT_AUTOMATICALLY_NOTIFICATION'), '', payload);
      break;
  }
};

const deleteMessages = (code: number, payload: any) => {
  switch (code) {
    case 200:
      showAlert('success', i18n.t('generalmessage.DELETED_CORRECTLY'));
      break;
    case 201:
      showAlert('success', i18n.t('generalmessage.DELETED_CORRECTLY'));
      break;
    case 400:
      showAlert(
        'error',
        `${i18n.t('generalmessage.ERROR_MESSAGE_DELETE_FAILED')}`,
        `${i18n.t('generalmessage.ERROR_MESSAGE_400_ON_DELETE')}`,
        payload
      );
      break;
    case 401:
      noAuth();
      break;
    case 404:
      showAlert(
        'error',
        `${i18n.t('generalmessage.ERROR_MESSAGE_DELETE_FAILED')}`,
        `${i18n.t('generalmessage.ERROR_MESSAGE_400_ON_DELETE')}`,
        payload
      );
      break;
    case 500:
      showAlert(
        'error',
        i18n.t('generalmessage.ERROR_MESSAGE_DELETE_FAILED'),
        `${i18n.t('generalmessage.ERROR_MESSAGE_500_ON_DELETE')}`,
        payload
      );
      break;
  }
};

const updateMessages = (code: number, payload: any) => {
  switch (code) {
    case 200:
      showAlert('success', i18n.t('generalmessage.UPDATED_CORRECTLY'));
      break;
    case 201:
      showAlert('success', i18n.t('generalmessage.UPDATED_CORRECTLY'));
      break;
    case 401:
      noAuth();
      break;
    case 500:
      showAlert(
        'error',
        i18n.t('generalmessage.ERROR_MESSAGE_FAILED_UPDATE'),
        `${i18n.t('generalmessage.ERROR_MESSAGE_500_WITHOUT_AUTOMATICALLY_NOTIFICATION')}`,
        payload
      );
      break;
  }
};

const getMessages = (code: number) => {
  switch (code) {
    case 401:
      noAuth();
      break;
  }
};

const noAuth = () => {
  showAlert('info', `${i18n.t('generalmessage.TITLE_UNAUTHORIZED')}`, `${i18n.t('generalmessage.DESC_UNAUTHORIZED')}`);
};

export { errorMessage };
