import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import { PreRegisterRepository } from '@/wms/domain/preRegister/PreRegisterRepository';
import { Command } from '@/core/cqrs/Command';

@Injectable()
export class PreRegisterChangeType extends Command<Promise<PreRegister>, { payload: PreRegister; newType: string }> {
  public constructor(
    @Inject(TYPES.PRE_REGISTER_REPOSITORY)
    private readonly preRegisterRepository: PreRegisterRepository
  ) {
    super();
  }
  async internalExecute({ payload, newType }: { payload: PreRegister; newType: string }): Promise<PreRegister> {
    const res = await this.preRegisterRepository.changeType({
      newType,
      payload
    });
    return res;
  }
}
