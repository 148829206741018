
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { EntityFindAll } from '@/settings/application/uses_cases/entity/search/EntityFindAll';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { Store } from '@/wms/domain/layout/store/Store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'GroupSearch',
  components: {}
})
export default class GroupSearch extends Vue {
  //Variable para almacenar la lista de territorios
  options: Entity[] = [];

  //Variable para controlar el estado de la carga
  isLoading = false;

  @Inject(TYPES.API_ENTITY_FIND_ALL_PAGE)
  readonly findAllEntities!: EntityFindAll;

  @Prop({ required: true }) value!: Entity | null;
  @Prop({ required: false }) label!: string | null;
  @Prop({ required: false, default: '' }) containerClass!: string | null;
  @Prop({ required: false, default: '' }) validationRules!: string | null;
  @Prop({
    required: false,
    default: (): null => {
      return null;
    }
  })
  onInput!: (store: Entity | null) => Promise<void> | void;
  @Prop({ required: false, default: false }) readonly disabled!: boolean;
  @Prop({ required: false }) stores!: Store[];
  @Prop({ required: false }) valuePath!: keyof Entity;

  mounted() {
    if (!this.stores) this.find();
  }

  get localValue(): Entity | null | string | number {
    return this.value;
  }

  set localValue(value: Entity | null | string | number) {
    this.$emit('update:value', value);
  }

  /**
   * @description Funcion para buscar tdas las eNTIDADES y asignarlo a la lista
   * @name find
   */
  async find() {
    this.isLoading = true;
    try {
      const response = await this.findAllEntities.internalExecute();
      this.options = response;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  //Función invocada cuando se selecciona un territorio y se desea enviar al padre para ejecutar alguna acción.
  async dispatchInput(value: Entity | null) {
    if (this.onInput) await this.onInput(value);
  }

  //Watch invocado para cuando el valor de la variable stores cambie
  @Watch('stores')
  onStoresChange(store: Entity[] | null) {
    if (store) this.options = store;
  }
}
