export class Routes {
  active: boolean;
  countryDestination: string;
  countryDestinationName: string;
  countryOrigin: string;
  countryOriginName: string;
  createAt: string;
  description: string;
  id: number;
  stateDestination: string;
  stateDestinationName: string;
  stateOrigin: string;
  stateOriginName: string;
  timelineId: number;
  timelineName: string;
  updateAt: string;
  zoneZcDestination: string;
  zoneZcDestinationDescription: string;
  zoneZcOrigin: string;
  zoneZcOriginDescription: string;
  cityOriginName: string;
  cityDestinationName: string;
  cityDestination: string;
  cityOrigin: string;

  public constructor(
    active?: boolean,
    countryDestination?: string,
    countryDestinationName?: string,
    countryOrigin?: string,
    countryOriginName?: string,
    createAt?: string,
    description?: string,
    id?: number,
    stateDestination?: string,
    stateDestinationName?: string,
    stateOrigin?: string,
    stateOriginName?: string,
    timelineId?: number,
    timelineName?: string,
    updateAt?: string,
    zoneZcDestination?: string,
    zoneZcDestinationDescription?: string,
    zoneZcOrigin?: string,
    zoneZcOriginDescription?: string
  ) {
    this.active = active || true;
    this.countryDestination = countryDestination || '';
    this.countryDestinationName = countryDestinationName || '';
    this.countryOrigin = countryOrigin || '';
    this.countryOriginName = countryOriginName || '';
    this.createAt = createAt || '';
    this.description = description || '';
    this.id = id || 0;
    this.stateDestination = stateDestination || '';
    this.stateDestinationName = stateDestinationName || '';
    this.stateOrigin = stateOrigin || '';
    this.stateOriginName = stateOriginName || '';
    this.timelineId = timelineId || 0;
    this.timelineName = timelineName || '';
    this.updateAt = updateAt || '';
    this.zoneZcDestination = zoneZcDestination || '';
    this.zoneZcDestinationDescription = zoneZcDestinationDescription || '';
    this.zoneZcOrigin = zoneZcOrigin || '';
    this.zoneZcOriginDescription = zoneZcOriginDescription || '';
    this.cityOriginName = '';
    this.cityDestinationName = '';
    this.cityDestination = '';
    this.cityOrigin = '';
  }
}
