import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { CategoryReferences } from '@/wms/domain/catalogs/categoryReferences/CategoryReferences';
import { CategoryReferencesRepository } from '@/wms/domain/catalogs/categoryReferences/CategoryReferencesRepository';

@Injectable()
export class CreateCategoryReferences extends Command<Promise<any>, CategoryReferences> {
  public constructor(
    @Inject(TYPES.CATEGORY_REFERENCE_REPOSITORY)
    private readonly categoryReferencesRepository: CategoryReferencesRepository
  ) {
    super();
  }

  internalExecute(categoryReferences: CategoryReferences): Promise<any> {
    return this.categoryReferencesRepository.create(categoryReferences);
  }
}
