
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
//services
import { CountryFindAll } from '@/settings/application/uses_cases/country/search/CountryFindAll';
import { CountryFindByStatus } from '@/settings/application/uses_cases/country/search/CountryFindByStatus';
import { StateFindAll } from '@/settings/application/uses_cases/state/search/StateFindAll';
import { StateFindByStatus } from '@/settings/application/uses_cases/state/search/StateFindByStatus';
import { ZoneFindAll } from '@/settings/application/uses_cases/zone/search/ZoneFindAll';
import { CountryUpdate } from '@/settings/application/uses_cases/country/update/CountryUpdate';
import { StateUpdate } from '@/settings/application/uses_cases/state/update/StateUpdate';
import { ZoneUpdate } from '@/settings/application/uses_cases/zone/update/ZoneUpdate';
import { ZoneFindByStatusAndCountryAndState } from '@/settings/application/uses_cases/zone/search/ZoneFindByStatusAndCountryAndState';
import { CountryFindByDescriptionLike } from '@/settings/application/uses_cases/country/search/CountryFindByDescriptionLike';
import { StateFindByDescriptionLike } from '@/settings/application/uses_cases/state/search/StateFindByDescriptionLike';
import { ZoneFindByDescriptionLike } from '@/settings/application/uses_cases/zone/search/ZoneFindByDescriptionLike';
import { Country } from '@/settings/domain/country/Country';
import { StateFindByCountryCode } from '@/settings/application/uses_cases/state/search/StateFindByCountryCode';
import { States } from '@/settings/domain/state/States';
import { SearchCitiesByCountryAndStateActive } from '@/settings/application/uses_cases/city/search/SearchCitiesByCountryAndStateActive';
import { City } from '@/settings/domain/city/City';

@Component({
  name: 'ManageTerritories'
})
export default class AppManageTerritories extends Vue {
  @Inject(TYPES.FIND_BY_DESCRIPTION_LIKE_COUNTRY)
  readonly countryFindByDescriptionLike!: CountryFindByDescriptionLike;
  @Inject(TYPES.FIND_BY_DESCRIPTION_LIKE_STATE)
  readonly stateFindByDescriptionLike!: StateFindByDescriptionLike;
  @Inject(TYPES.FIND_BY_DESCRIPTION_LIKE_ZONE)
  readonly zoneFindByDescriptionLike!: ZoneFindByDescriptionLike;
  @Inject(TYPES.FINDALL_COUNTRY)
  readonly countryFindAllcall!: CountryFindAll;
  @Inject(TYPES.FINDBYSTATUS_COUNTRY)
  readonly countryFindByStatus!: CountryFindByStatus;
  @Inject(TYPES.FINDALL_STATE)
  readonly stateFindAllcall!: StateFindAll;
  @Inject(TYPES.FINDBYSTATUS_STATE)
  readonly stateFindByStatus!: StateFindByStatus;
  @Inject(TYPES.FINDBYCOUNTRYCODE_STATE)
  readonly stateFindByCountryCode!: StateFindByCountryCode;
  @Inject(TYPES.FINDALL_ZONE)
  readonly zoneFindAllcall!: ZoneFindAll;
  @Inject(TYPES.FINDSTATUSCOUNTRYSTATE_ZONE)
  readonly zoneFindByStatusAndCountryAndState!: ZoneFindByStatusAndCountryAndState;
  @Inject(TYPES.UPDATE_COUNTRY)
  readonly countryUpdate!: CountryUpdate;
  @Inject(TYPES.UPDATE_STATE)
  readonly stateUpdate!: StateUpdate;
  @Inject(TYPES.UPDATE_ZONE)
  readonly zoneUpdate!: ZoneUpdate;
  @Inject(TYPES.CITY_SEARCH_BY_COUNTRY_AND_STATE)
  readonly citySearchByCountryAndState!: SearchCitiesByCountryAndStateActive;

  isLoading = false;
  isNew = false;
  fullPage = true;
  payloadCountry = new Country();
  payloadState = new States();
  countries: Country[] = [];
  states: States[] = [];
  cities: City[] = [];
  sortBy = 'code';
  sortDesc = true;
  totalRows = 1;
  currentPage = 1;
  perPage = 10;
  selectMode = 'single';
  mounted() {
    this.findAllCountriesActive();
  }

  get fields() {
    return [
      { key: 'code', label: this.translateCol('code'), sortable: true },
      { key: 'name', label: this.translateCol('description'), sortable: true }
    ];
  }

  get rows() {
    return this.countries.length;
  }

  translateCol(colName: string) {
    return this.$i18n.t('general.' + colName);
  }

  onFiltered(filteredItems: any) {
    // Trigger pagination to update the number of buttons/pages due to filtering
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  }

  async findAllCountriesActive() {
    this.isLoading = true;
    await this.countryFindByStatus
      .execute(true)
      .then(response => {
        this.countries = response;
        this.isLoading = false;
      })
      .catch(error => {
        this.isLoading = false;
        throw new Error(`${error}`);
      });
  }

  async selectedCountry(Listcountry: any) {
    const country: Country = Listcountry[0];
    this.isNew = false;
    this.payloadCountry = country;
    if (country === undefined) {
      this.cities = [];
      this.states = [];
      return;
    }
    await this.selectState(country);
  }

  async selectState(country: Country) {
    this.isNew = false;
    if (country === undefined) {
      this.cities = [];
      return;
    }
    await this.findStateByCountry(country);
  }

  async findStateByCountry(country: Country) {
    this.states = [];
    this.cities = [];
    await this.stateFindByCountryCode
      .execute(country.code)
      .then(response => {
        this.isLoading = false;
        this.states = response;
      })
      .catch(error => {
        this.isLoading = false;
        throw new Error(`${error}`);
      });
  }

  async selectCity(listState: any) {
    const state: States = listState[0];
    this.isNew = false;
    this.payloadState = state;
    if (state === undefined) {
      this.cities = [];
      return;
    }
    await this.findCitiesByCountryAndState(state);
  }

  async findCitiesByCountryAndState(state: States) {
    this.cities = [];
    this.isLoading = true;
    if (state) {
      await this.citySearchByCountryAndState
        .execute({
          country: state.countryCode,
          state: state.code,
          active: true
        })
        .then(response => {
          this.isLoading = false;
          this.cities = response;
        })
        .catch(error => {
          this.isLoading = false;
          throw new Error(`${error}`);
        });
    }
  }
}
