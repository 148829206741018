import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { ReferencesRepository } from '@/wms/domain/catalogs/references/ReferencesRepository';
import { References } from '@/wms/domain/catalogs/references/References';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import Serial from '@/wms/domain/catalogs/references/Serial';
import { NewReferenceObject } from '@/wms/domain/catalogs/references/NewReferenceObject';

@Injectable()
export class ReferencesServices implements ReferencesRepository {
  private readonly API_BASE_URI: string = localStorageModule().wms.host.API_BASE_URI;
  private readonly API_BASE_URI_TRACKING: string = localStorageModule().tracking.host.API_BASE_URI;
  private readonly API_REFERENCES_FINDALL: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_REFERENCES_FINDALL;
  private readonly API_REFERENCES_FINDALL_BY_ID: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_REFERENCES_FINDALL_BY_ID;
  private readonly API_REFERENCES_SAVE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_REFERENCES_SAVE;
  private readonly API_REFERENCES_UPDATE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_REFERENCES_UPDATE;
  private readonly API_REFERENCES_DELETE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_REFERENCES_DELETE;
  private readonly API_FIND_CATEGORY_SERVICES: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_FIND_CATEGORY_SERVICES;
  private readonly API_FIND_SUB_REFERENCES_BY_MAIN_SKU: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_FIND_SUB_REFERENCES_BY_MAIN_SKU;
  private readonly API_REFERENCES_UPDATE_BARCODE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_REFERENCES_UPDATE_BARCODE;
  private readonly API_SERIAL_FIND_BY_REFERENCE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_SERIAL_FIND_BY_REFERENCE;
  private readonly API_SERIAL_DELETE: string = this.API_BASE_URI + localStorageModule().wms.routes.API_SERIAL_DELETE;
  private readonly API_REFERENCES_CREATE_SERIALS: string =
    this.API_BASE_URI_TRACKING + localStorageModule().wms.routes.API_REFERENCES_CREATE_SERIALS;
  private readonly API_REFERENCES_ASSIGN_TO_TIMELINE: string =
    this.API_BASE_URI_TRACKING + localStorageModule().tracking.routes.API_REFERENCES_ASSIGN_TO_TIMELINE;
  private readonly API_REFERENCES_FIND_CODES_BY_TIMELINE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_REFERENCES_FIND_CODES_BY_TIMELINE;
  private readonly API_REFERENCES_FIND_BY_QUERY: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_REFERENCES_FIND_BY_QUERY;
  private readonly API_REFERENCES_FIND_MANAGE_STOCK: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_REFERENCES_FIND_MANAGE_STOCK;
  private readonly REFERENCES_FIND_ALL_FOR_LIST_BY_CATEGORY: string =
    this.API_BASE_URI + localStorageModule().wms.routes.REFERENCES_FIND_ALL_FOR_LIST_BY_CATEGORY;
  private readonly API_REFERENCES_FIND_BY_SKU: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_REFERENCES_FIND_BY_SKU;

  async create(reference: References) {
    try {
      await http.post<References[]>(this.API_REFERENCES_SAVE, JSON.stringify(reference));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(reference: References) {
    try {
      await http._put<References[]>(this.API_REFERENCES_UPDATE, JSON.stringify(reference));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async updateBarcode(currentBarcode: string, newBarCode: string) {
    try {
      await http._put<References[]>(`${this.API_REFERENCES_UPDATE_BARCODE}${currentBarcode}`, newBarCode);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async delete(reference: References) {
    try {
      await http.delete<References[]>(this.API_REFERENCES_DELETE, JSON.stringify(reference));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findReferencesById(code: string): Promise<References[]> {
    try {
      return await http.get<References[]>(this.API_REFERENCES_FINDALL_BY_ID + code);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findAll(): Promise<References[]> {
    try {
      return await http.get<References[]>(this.API_REFERENCES_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async findAllServices(type: string): Promise<References[]> {
    try {
      return await http.get<References[]>(`${this.API_FIND_CATEGORY_SERVICES}/${type}`);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async findByMainSku(mainSku: string): Promise<References[]> {
    try {
      if (mainSku === '') return [];
      return await http.get<References[]>(`${this.API_FIND_SUB_REFERENCES_BY_MAIN_SKU}?mainSku=${mainSku}`);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async findAllAssociatedSerialByReference(code: string): Promise<Serial[]> {
    try {
      return await http.get<Serial[]>(`${this.API_SERIAL_FIND_BY_REFERENCE}?reference=${code}`);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  /**
   *
   * @param code, serial
   * @returns El serial eliminado
   * @throws Error al eliminar el serial
   * @description Elimina el serial
   * @Author Mauricio Cantillo
   */
  async deleteAssociatedSerial(code: string, serial: string[]): Promise<Serial[]> {
    try {
      return await http.delete(`${this.API_SERIAL_DELETE}?reference=${code}`, JSON.stringify(serial));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  /**
   *
   * @param code
   * @returns codigo de serial
   * @throws Error al actualizar la referencia
   * @description Actualiza el serial asociado a la referencia
   * @Author Mauricio Cantillo
   */
  async saveAssociatedSerial(code: string, file: FormData): Promise<any> {
    try {
      return await http.postFormData(`${this.API_REFERENCES_CREATE_SERIALS}/${code}`, file);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  /**
   * @param timeline Timeline al cual se asociarán las referencias
   * @returns void
   * @throws Error al asignar las referencias al timeline
   * @description Asigna las referencias al timeline
   * @Author Santiago Martinez
   */
  async assignToTimeline({ timeline, references }: { timeline: number; references: string[] }): Promise<void> {
    try {
      return await http.post(`${this.API_REFERENCES_ASSIGN_TO_TIMELINE}/${timeline}`, JSON.stringify(references));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  /**
   * @param timeline Timeline del cual se obtendrán las referencias
   * @returns {string[]} Códigos de las referencias asociadas al timeline
   * @throws Error al obtener las referencias del timeline
   * @description Obtiene los códigos de las referencias asociadas al timeline
   * @Author Santiago Martinez
   */
  async getCodeReferencesByTimeline({ timeline }: { timeline: number }): Promise<string[]> {
    try {
      return await http.get<string[]>(`${this.API_REFERENCES_FIND_CODES_BY_TIMELINE}/${timeline}/codes`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  /**
   * @param timeline Timeline del cual se obtendrán las referencias
   * @returns {References[]} Referencias asociadas al timeline
   * @throws Error al obtener las referencias del timeline
   * @description Obtiene las referencias asociadas al timeline
   * @Author Santiago Martinez
   */
  async getReferencesByTimeline({ timeline }: { timeline: number }): Promise<References[]> {
    try {
      return await http.get<References[]>(`${this.API_REFERENCES_FIND_CODES_BY_TIMELINE}/${timeline}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  /**
   * @param query Query para buscar referencias
   * @returns {References[]} Referencias que cumplen con la query (SKU, barcode, alternative references)
   * @throws Error al buscar referencias
   * @description Busca referencias que cumplen con la query
   * @Author Santiago Martinez
   */
  async findByQuery(query: string): Promise<References[]> {
    try {
      return await http.get<References[]>(`${this.API_REFERENCES_FIND_BY_QUERY}?query=${query}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findManagedStockReferences(): Promise<References[]> {
    try {
      return await http.get<References[]>(this.API_REFERENCES_FIND_MANAGE_STOCK);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findByCategoryForList(category: number): Promise<References[]> {
    try {
      return await http.get<References[]>(`${this.REFERENCES_FIND_ALL_FOR_LIST_BY_CATEGORY}category=${category}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findBySku(sku: string): Promise<NewReferenceObject> {
    try {
      return await http.get<NewReferenceObject>(`${this.API_REFERENCES_FIND_BY_SKU}sku=${sku}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
