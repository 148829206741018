import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { ResizingZone } from '@/wms/domain/layout/configResizing/ResizingZone';
import { LayoutConfigRepository } from '@/wms/domain/layout/configResizing/LayoutConfigRepository';

@Injectable()
export class CreateLayoutConfig extends Command<Promise<any>, ResizingZone[]> {
  public constructor(
    @Inject(TYPES.LAYOUT_CONFIG_REPOSITORY)
    private readonly repository: LayoutConfigRepository
  ) {
    super();
  }

  internalExecute(resizingZone: ResizingZone[]): Promise<any> {
    return this.repository.create(resizingZone);
  }
}
