
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import AddWarehousePreRegister from '@/wms/infrastructure/ui/warehousePreRegister/AddWarehousePreRegister.vue';
import { PreRegisterFindByPk } from '@/wms/application/preRegister/search/PreRegisterFindByPk';
import { PreRegisterTypeEnum } from '@/wms/domain/preRegister/PreRegisterTypeEnum';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import { PreRegisterUpdate } from '@/wms/application/preRegister/update/PreRegisterUpdate';
import { PreRegisterFindByDateAndType } from '@/wms/application/preRegister/search/PreRegisterFindByDateAndType';
import { getFormattedDate } from '@/core/plugins/DateFormatter';
import { TypeOperation } from '@/settings/domain/typeOperation/TypeOperation';
import { AddressLine } from '@/settings/domain/address/AddressLine';
import { PreRegisterStatusEnum } from '@/wms/domain/preRegister/PreRegisterStatusEnum';

interface Filters {
  from: string;
  to: string;
  type: string;
}

@Component({
  name: 'ShipmentList',
  components: { CustomTableN, AddWarehousePreRegister }
})
export default class ShipmentList extends Vue {
  @Inject(TYPES.PRE_REGISTER_FIND_BY_PK)
  readonly findByPk!: PreRegisterFindByPk;
  @Inject(TYPES.PRE_REGISTER_UPDATE)
  readonly updatePreRegister!: PreRegisterUpdate;
  @Inject(TYPES.PRE_REGISTER_FINDALL_BY_DATE_AND_TYPE)
  readonly findAllByDateAndType!: PreRegisterFindByDateAndType;
  //Listas
  shipmentList: PreRegister[] = [];

  filters: Filters = {
    from: getFormattedDate(new Date()),
    to: getFormattedDate(new Date()),
    type: PreRegisterTypeEnum.SHIPMENT
  };

  //Data
  isLoading = false;
  form: PreRegister = new PreRegister();
  showModal = false;

  //Objetos de acciones
  tableActions = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: this.edit,
        disabled: (item: PreRegister) => item.state === 'disabled'
      },
      {
        title: `${this.$t('general.details')}`,
        icon: 'fa fa-eye',
        variant: 'warning',
        action: this.show
      },
      {
        title: `${this.$t('general.disable')}`,
        icon: 'fa fa-close',
        variant: 'danger',
        action: this.disable,
        disabled: (item: PreRegister) => item.state === 'disabled'
      }
    ]
  };

  //Getter para obtener los campos de la tabla
  get fields() {
    return [
      {
        label: `${this.$t('general.number')}`,
        field: 'number',
        sortable: true
      },
      {
        label: `${this.$t('general.type')}`,
        field: 'typeOperationData',
        sortable: true,
        formatFn: (item: TypeOperation) => {
          return `${item.description}`;
        }
      },
      {
        label: `${this.$t('general.chargeType')}`,
        field: (row: PreRegister) => row,
        sortable: true,
        formatFn: (item: PreRegister) => {
          return this.calculateFreigthDescription(item);
        }
      },
      {
        label: `${this.$t('general.sender')}`,
        field: 'sender.fullName',
        sortable: true
      },
      {
        label: `${this.$t('general.recipient')}`,
        field: 'consignee.name',
        sortable: true
      },
      {
        label: `ETD`,
        field: 'estimatedDepartureDate',
        sortable: true,
        formatFn: (item: string) => {
          return item.replaceAll('-', '/');
        }
      },
      {
        label: `ETA`,
        field: 'estimatedArrivalDate',
        sortable: true,
        formatFn: (item: string) => {
          return new Date(item).toLocaleDateString();
        }
      },
      {
        label: `${this.$t('general.origin')}`,
        field: 'address',
        sortable: true,
        formatFn: (item: AddressLine) => `${item.territory?.cityName} - ${item.territory?.country}`
      },
      {
        label: `${this.$t('general.destination')}`,
        field: 'addressDestination',
        sortable: true,
        formatFn: (item: AddressLine) => `${item.territory?.cityName} - ${item.territory?.country}`
      },
      {
        label: `${this.$t('general.status')}`,
        field: 'state',
        formatFn: (item: any) => this.$t(`general.${item}`),
        sortable: true
      },
      {
        label: `${this.$t('general.actions')}`,
        field: 'actions'
      }
    ];
  }

  /**
   * @name calculateFreigthDescription
   * @description Funcion para calcular la descripcion y cantidadde la carga
   */

  calculateFreigthDescription(shipment: PreRegister) {
    let description = '';

    description += shipment.bulksF.length
      ? description.concat(`${shipment.bulksF.length} ${this.$t('general.boxes')} `)
      : '';

    description += shipment.containers.length
      ? description.concat(`${shipment.containers.length} ${this.$t('general.containers')} `)
      : '';

    description += shipment.boxes.length
      ? description.concat(`${shipment.boxes.length} ${this.$t('general.pallets')}`)
      : '';

    return description;
  }

  //Funcion invocada para ejecutar la edicion
  edit(input: PreRegister) {
    this.$router.push({ path: 'shipment', query: { type: input.type, number: input.number } });
  }

  //Funcion invocada para obtener una lista de todos los pre registros que se han realizado
  async findAll() {
    try {
      this.isLoading = true;
      const res = await this.findAllByDateAndType.execute(this.filters);
      this.shipmentList = res.length > 0 ? res.reverse() : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  }

  //Funcion invocada para obtener un pre registro concreto
  async show(input: PreRegister) {
    try {
      this.isLoading = true;
      const res = await this.findByPk.execute({
        type: input.type,
        number: input.number
      });
      this.form = Object.assign(new PreRegister(), res);
      this.isLoading = false;
      this.showModal = true;
    } catch (error) {
      this.isLoading = false;
    }
  }

  //Funcion invocada para deshabilitar un registro
  async disable(input: PreRegister) {
    try {
      this.isLoading = true;
      const res = await this.findByPk.execute({
        type: input.type,
        number: input.number
      });
      this.form = Object.assign(new PreRegister(), res);
      this.form.state = PreRegisterStatusEnum.DISABLED;
      await this.updatePreRegister.execute(this.form);
      await this.findAll();
      this.clearForm();
      this.isLoading = false;
      this.showModal = true;
    } catch (error) {
      this.isLoading = false;
    }
  }

  //Funcion para limpiar el formulario
  clearForm() {
    this.form = new PreRegister();
  }
}
