
import Vue from 'vue';
import { TYPES } from '@/core/config/Types';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';
import { Inject } from 'inversify-props';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import { Component, Prop } from 'vue-property-decorator';
import {
  BCard,
  BCardBody,
  BCardHeader,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BTable,
  BRow,
  BCol
} from 'bootstrap-vue';
import html2pdf from 'html2pdf.js';

@Component({
  name: 'PreRegisterDocument',
  components: { BCard, BCardBody, BCardHeader, BForm, BFormGroup, BFormInput, BFormTextarea, BTable, BRow, BCol }
})
export default class PreRegisterDocument extends Vue {
  @Inject(TYPES.FINDBYID_COMPANY)
  readonly findByIdCompany!: CompanyFindById;

  @Prop({ required: true }) readonly preRegister!: PreRegister;

  mounted() {
    this.getCompanyInfo();
  }

  isLoading = false;
  logoImg = localStorage.getItem('urlLogoCompany') as string;
  companyName = '';
  companyAddress = '';
  companyPhoneNumber = '';
  companyEmail = '';
  companyZipCode = '';
  receiptData = {
    receiptNumber: this.preRegister.number,
    date: this.preRegister.date,
    location: this.preRegister.warehouse?.description,
    receivedFrom: this.preRegister.sender?.fullName,
    totalPackages: this.preRegister.bulks.length,
    storageName: this.preRegister.branch?.store?.storeName,
    totalWeight: `${this.preRegister.bulks.reduce((a, b) => a + b.weight, 0).toFixed(2)} kg`,
    items: this.preRegister.bulks.map(bulk => ({
      unitType: bulk.packingUnit?.description,
      description: bulk.shippingDetails?.productName,
      unitsReceived: bulk.quantity,
      referenceCode: bulk.referenceCode
    })),
    typeOperation: this.preRegister.typeOperationData?.description,
    observations: this.preRegister.notes,
    receiverName: this.preRegister.receivedBy,
    delivererName: this.preRegister.sender?.fullName
  };
  fields = [
    { key: 'unitType', label: `${this.$t('general.packaging')}` },
    { key: 'description', label: `${this.$t('general.description')}` },
    { key: 'unitsReceived', label: `${this.$t('general.unitsquantity')}` },
    { key: 'referenceCode', label: `${this.$t('general.referenceCode')}` }
  ];

  async getCompanyInfo() {
    try {
      this.isLoading = true;
      const companyId = localStorage.getItem('businessId') as string;
      const res: any = await this.findByIdCompany.execute(companyId);
      this.isLoading = false;
      this.companyName = res.businessName;
      this.companyAddress = res.address;
      this.companyPhoneNumber = res.phoneNumber;
      this.companyEmail = res.email;
      this.companyZipCode = res.zipCode;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  get documentTitle() {
    if (window.location.href.includes('/reception/')) {
      return `${this.$t('menu.receptionPreRegister')}`;
    } else {
      return `${this.$t('menu.expeditionPreRegister')}`;
    }
  }

  exportToPDF(id: string) {
    const config = {
      margin: 0.1,
      pagebreak: { mode: 'avoid-all' },
      filename: `${this.documentTitle} #${this.receiptData.receiptNumber}`,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { dpi: 300, scale: 2, useCORS: true },
      jsPDF: { unit: 'in', format: 'legal', orientation: 'p' }
    };
    html2pdf(document.getElementById(id), config);
  }
}
