import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { GeneralPort } from '@/settings/domain/generalPort/GeneralPort';
import { GeneralPortRepository } from '@/settings/domain/generalPort/GeneralPortsRepository';

@Injectable()
export class GeneralPortService implements GeneralPortRepository {
  private readonly API_TERRITORIES_BASE: string = process.env.VUE_APP_API_TERRITORIES ?? '';
  private readonly API_GENERAL_PORTS_SEARCH_BY_QUERY: string =
    this.API_TERRITORIES_BASE + localStorageModule().config.routes.API_GENERAL_PORTS_SEARCH_BY_QUERY;

  findByQuery(query: string): Promise<GeneralPort[]> {
    try {
      return http.get<GeneralPort[]>(this.API_GENERAL_PORTS_SEARCH_BY_QUERY + query);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
