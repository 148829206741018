
import Component from 'vue-class-component';
import Vue from 'vue';
import { Emit, Prop } from 'vue-property-decorator';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { UpdateTimeline } from '@/tracking/application/uses_cases/timeline/update/UpdateTimeline';
import { TransportFindAll } from '@/tracking/application/uses_cases/typetransport/search/findall/TransportFindAll';
import { OperationFindByProcessType } from '@/tracking/application/uses_cases/typeoperation/search/OperationFindByProcessType';
import i18n from '@/core/plugins/i18n';

@Component({
  name: 'TableEvent'
})
export default class AppCreateTableTimelineEvent extends Vue {
  @Inject(TYPES.UPDATE_TIMELINE)
  readonly updateTimeline!: UpdateTimeline;
  @Inject(TYPES.FINDALL_TRANSPORT)
  readonly transportFindAll!: TransportFindAll;
  @Inject(TYPES.FINDBYPROCESSTYPE_TYPE_OPERATION)
  readonly operationFindByProcessType!: OperationFindByProcessType;
  //Data
  target = i18n.t('general.event');
  typeTransportList = [];
  typeOperationList = [];
  listSequence: any = [];
  private formTableTimelineEvent: any = {
    isTouched: false,
    edit: null,
    dataTypeOperation: []
  };
  //Prop
  @Prop() readonly eventsListFindByPk!: any;
  @Prop() readonly timelineListByIndex!: any;
  @Prop() readonly typeProcess!: number;
  //Hook
  mounted() {
    this.$nextTick(() => this.typeTransport);
    this.$nextTick(() => this.typeOperation);
  }
  //Computed
  get typeTransport() {
    this.transportFindAll.execute().then((response: any) => {
      this.typeTransportList = response;
      return response;
    });
    return true;
  }
  get typeOperation() {
    this.operationFindByProcessType.execute(this.typeProcess).then((response: any) => {
      this.typeOperationList = response;
    });
    return true;
  }
  //Method
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  editTimeline(dataTimeline: any, dataEventUpdate: any) {
    this.formTableTimelineEvent.edit =
      this.formTableTimelineEvent.edit !== dataEventUpdate.id ? dataEventUpdate.id : null;
    if (this.formTableTimelineEvent.edit === null) {
      let dataTimelineFilter: any = dataTimeline.events.filter((item: any) => item.id !== dataEventUpdate.id);
      dataTimelineFilter = this.reorganizingAfterDeleteAnEvent(dataTimelineFilter);
      const payloadUpdate: any = {
        id: dataTimeline.id,
        description: dataTimeline.description.trim(),
        typeOperation: dataTimeline.typeOperation,
        typeTransport: dataTimeline.typeTransport,
        processType: this.typeProcess,
        active: dataTimeline.active,
        events: dataTimelineFilter.concat({
          activities: dataEventUpdate.activities,
          description: dataEventUpdate.description,
          day: dataEventUpdate.day,
          id: dataEventUpdate.id,
          sequence: 0,
          typeOperation: this.formTableTimelineEvent.dataTypeOperation.code,
          active: this.eventsListFindByPk.active,
          createAt: dataEventUpdate.createAt,
          updateAt: dataEventUpdate.updateAt
        })
      };
      this.updateTimeline
        .execute(payloadUpdate)
        .then((response: any) => {
          if (response.ok) {
            this.getTimelineSkeleton();
          }
          return response;
        })
        .catch((error: any) => {
          throw new Error(error);
        });
    } else {
      this.formTableTimelineEvent.dataTypeOperation = {
        code: dataEventUpdate.typeOperation,
        description: dataEventUpdate.typeOperationName
      };
    }
  }
  reorganizingAfterDeleteAnEvent(events: any) {
    events.forEach((element: any, index: number) => {
      element.sequence = index + 1;
    });
    return events;
  }

  //Emit
  @Emit()
  getTimelineSkeleton() {
    this.$emit('getTimelineSkeleton');
  }
}
