import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Segmentation } from '@/wms/domain/catalogs/segmentation/Segmentation';
import { SegmentationRepository } from '@/wms/domain/catalogs/segmentation/SegmentationRepository';

@Injectable()
export class CreateSegmentation extends Command<Promise<any>, Segmentation> {
  public constructor(
    @Inject(TYPES.SEGMENTATION_REFERENCE_REPOSITORY)
    private readonly zoneRepository: SegmentationRepository
  ) {
    super();
  }

  public internalExecute(segmentation: Segmentation): Promise<any> {
    return this.zoneRepository.create(segmentation);
  }
}
