
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import { TYPES } from '@/core/config/Types';
import { currencyFormatter } from '@/core/formatter/CurrencyFormatter';
import { CreateDocumentApplied } from '@/courier/application/uses_cases/documents/documentApplied/create/CreateDocumentApplied';
import { DocumentFindBySwtichsAndFilter } from '@/courier/application/uses_cases/documents/search/DocumentFindBySwtichsAndFilters';
import { UpdateDocument } from '@/courier/application/uses_cases/documents/update/UpdateDocument';
import { Document } from '@/courier/domain/documents/Document';
import { DocumentApplied } from '@/courier/domain/documents/documentApplied/DocumentApplied';
import { DocumentOrderType } from '@/courier/domain/order/DocumentOrderEnum';
import { EntityFindFilter } from '@/settings/application/uses_cases/entity/search/EntityFindFilter';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { SwitchesFindAll } from '@/tracking/application/uses_cases/switches/search/SwitchesFindAll';
import { Switches } from '@/tracking/domain/switches/Switches';
import { Inject } from 'inversify-props';
import { ValidationObserver } from 'vee-validate';
import Vue from 'vue';
import Component from 'vue-class-component';

export interface FilterSearch {
  entity: Entity | null;
  from: string;
  to: string;
  docsOptionSelected: string;
}

@Component({
  name: 'DocumentsConsultation',
  components: { CustomTableN }
})
export default class DocumentsConsultation extends Vue {
  @Inject(TYPES.ENTITY_FIND_BY_DESCRIPTION_LIKE) readonly findEntityByDescription!: EntityFindFilter;
  @Inject(TYPES.DOCUMENT_TYPE_UPDATE) readonly updateDocument!: UpdateDocument;
  @Inject(TYPES.DOCUMENT_APPLIED_SAVE) readonly saveDocumentApplied!: CreateDocumentApplied;
  @Inject(TYPES.FINDALL_SWITCH) readonly findAllSwitchs!: SwitchesFindAll;
  @Inject(TYPES.DOCUMENT_TYPE_FIND_BY_SWITCHS_FILTERS)
  readonly findDocumentsBySwitchsAndFilters!: DocumentFindBySwtichsAndFilter;

  $refs!: {
    filterForm: InstanceType<typeof ValidationObserver>;
    validationBonus: InstanceType<typeof ValidationObserver>;
    registerPayment: HTMLFormElement;
  };

  isLoading = false;
  isLoadingSelect = false;
  documentApplied: DocumentApplied = new DocumentApplied();
  docSelected: Document | null = null;
  docsList: Document[] = [];
  entityList: Entity[] = [];
  switchList: Switches[] = [];
  docsToBePaid = ['6', '31', '32'];
  docsReceivable = ['5', '21', '22'];
  switchsToBePaid = ['3', '4', '6', '31', '32'];
  switchsReceivable = ['1', '2', '5', '21', '22', '23'];
  filterSearch: FilterSearch = {
    entity: null,
    from: '',
    to: '',
    docsOptionSelected: ''
  };
  actions = {
    customActions: [
      {
        title: `${this.$t('general.registerPayments')}`,
        icon: 'fa fa-money',
        disabled: (row: Document) => Number((Number(row.total) - Number(row.appliedValue)).toFixed(2)) === 0,
        action: this.openModal,
        conditionalRender: (row: Document) => row.type === '*APL'
      }
    ]
  };

  get docsOptions() {
    return [
      {
        value: 'docsWithBalance',
        text: `${this.$t('general.docsWithBalance')}`
      },
      {
        value: 'allDocs',
        text: `${this.$t('general.allDocs')}`
      }
    ];
  }

  get fields() {
    return [
      { field: 'number', label: `#`, sortable: true },
      { field: 'type', label: this.$t('general.documentType'), sortable: true },
      { field: 'concept', label: this.$t('general.concept'), sortable: true },
      { field: 'date', label: this.$t('general.date'), sortable: true },
      {
        field: 'total',
        label: this.$t('general.total'),
        sortable: true,
        formatFn: (value: any) => currencyFormatter.format({ value: value, currency: 'USD' })
      },
      {
        field: 'appliedValue',
        label: this.$t('general.bonus'),
        sortable: true,
        formatFn: (value: any) => currencyFormatter.format({ value: value.toFixed(2), currency: 'USD' })
      },
      {
        field: (row: Document) => Number(row.total) - Number(row.appliedValue),
        label: this.$t('general.balance'),
        sortable: true,
        formatFn: (value: any) => currencyFormatter.format({ value: value, currency: 'USD' })
      },
      { field: 'actions', label: this.$t('general.actions'), sortable: false }
    ];
  }

  get filterRows() {
    if (this.filterSearch.docsOptionSelected === 'docsWithBalance') {
      return this.docsList.filter((item: Document) => Number(item.total) - Number(item.appliedValue) > 0);
    }
    return this.docsList;
  }

  get titleModal() {
    return this.docSelected ? `${this.docSelected.number} - ${this.docSelected.customerName}` : '';
  }

  customLabelSwitch(switchList: any) {
    return `${switchList.sw} - ${this.$t(`switchs.${switchList.sw}`)}`;
  }

  formatInput(value: string) {
    return currencyFormatter.format({ value, currency: 'USD' });
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  isEmpty(object: any) {
    return Object.values(object).some(value => !value);
  }

  resetFilter() {
    this.filterSearch = {
      entity: null,
      from: '',
      to: '',
      docsOptionSelected: ''
    };
  }

  entityLabel(entity: Entity) {
    return `${entity.code} - ${entity.fullName}`;
  }

  closeModal() {
    this.documentApplied.value = 0;
    this.documentApplied.sw = null;
    this.$nextTick(() => {
      this.$refs.validationBonus.reset();
    });
  }

  async openModal(document: Document) {
    try {
      this.isLoadingSelect = true;
      this.docSelected = document;
      this.$refs.registerPayment.show();

      const res = await this.findAllSwitchs.execute();

      if (this.switchsToBePaid.some((switchCode: string) => switchCode === document.switchCode)) {
        this.switchList = res.filter((item: Switches) => this.docsToBePaid.some((code: string) => code === item.sw));
      } else if (this.switchsReceivable.some((switchCode: string) => document.switchCode === switchCode)) {
        this.switchList = res.filter((item: Switches) => this.docsReceivable.some((code: string) => code === item.sw));
      }

      this.isLoadingSelect = false;
    } catch (error) {
      this.isLoadingSelect = false;
      throw new Error(`${error}`);
    }
  }

  async entityFind(query: string) {
    if (query.length < 2) return;
    try {
      this.isLoading = true;
      const res = await this.findEntityByDescription.execute({
        text: query,
        type: ['SUPPLIER', 'CUSTOMER']
      });
      this.entityList = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async findDocuments() {
    if (!this.isEmpty(this.filterSearch)) {
      try {
        const payload: any = {
          from: this.filterSearch.from,
          to: this.filterSearch.to,
          code: this.filterSearch.entity?.code
        };

        this.isLoading = true;
        const res = await this.findDocumentsBySwitchsAndFilters.execute(payload);

        this.docsList = res.sort((a, b) => a.number - b.number);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        throw new Error(`${error}`);
      }
    }
  }

  async registerPayment(document: Document) {
    try {
      this.isLoading = true;

      const payload: DocumentApplied = {
        ...this.documentApplied,
        type: DocumentOrderType.documentApplied,
        numberApplied: document.number,
        typeApplied: document.type,
        dateApplied: document.date,
        date: new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString()
      };

      await this.saveDocumentApplied.execute(payload);
      await this.findDocuments();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async handleOkModal(bvModalEvent: any) {
    bvModalEvent.preventDefault();

    if (!(await this.$refs.validationBonus.validate())) return;
    if (this.docSelected) await this.registerPayment(this.docSelected);

    this.$nextTick(() => {
      this.$bvModal.hide('modal-registerPayment');
    });
  }
}
