import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { Injectable } from '@/core/di/Injectable';
import { References } from '@/wms/domain/catalogs/references/References';
import { ReferencesRepository } from '@/wms/domain/catalogs/references/ReferencesRepository';
import { Inject } from 'inversify-props';

@Injectable()
export class ReferenceDelete extends Command<Promise<any>, References> {
  public constructor(
    @Inject(TYPES.REFERENCE_REPOSITORY)
    private readonly referenceRepository: ReferencesRepository
  ) {
    super();
  }

  internalExecute(references: References): Promise<any> {
    return this.referenceRepository.delete(references);
  }
}
