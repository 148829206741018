
import { DocumentHeader } from '@/courier/domain/DocumentsV2/Documents_V2';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'DocumentListModal'
})
export default class DocumentListModal extends Vue {
  @Prop() readonly documentList!: DocumentHeader[];
}
