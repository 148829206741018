import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { StateManager } from '@/core/statemanager/StateManager';
import { PackingUnits } from '@/settings/domain/packing_units/PackingUnits';
import { PackingUnitsRepository } from '@/settings/domain/packing_units/PackingUnitsRepository';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';

@Injectable()
export class FindPackingUnitById extends Query<Promise<PackingUnits>, string> {
  public constructor(
    @Inject(TYPES.PACKING_UNITS_REPOSITORY)
    private readonly packingUnitsRepository: PackingUnitsRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(packingUnitsId: string): Promise<PackingUnits> {
    return await this.packingUnitsRepository.findPackingUnitById(packingUnitsId);
  }
}
