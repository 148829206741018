import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { ResizingZone } from '@/wms/domain/layout/configResizing/ResizingZone';
import { Locations } from '@/wms/domain/layout/locations/Locations';
import { LocationsRepository } from '@/wms/domain/layout/locations/LocationsRepository';

@Injectable()
export class LocationService implements LocationsRepository {
  private readonly API_BASE_URI = localStorageModule().config.host.API_BASE_URI;
  private readonly API_LOCATION_GET_TAG_PDF =
    this.API_BASE_URI + localStorageModule().config.routes.API_LOCATION_GET_TAG_PDF;
  private readonly API_LOCATION_GET_TAG_PDF_BY_ZONE =
    this.API_BASE_URI + localStorageModule().config.routes.API_LOCATION_GET_TAG_PDF_BY_ZONE;

  create(location: Locations): Promise<any> {
    throw new Error('Method not implemented.');
  }
  update(location: Locations): Promise<any> {
    throw new Error('Method not implemented.');
  }
  findById(locationId: number): Promise<any> {
    throw new Error('Method not implemented.');
  }
  findAllLocationByRack(rackId: number): Promise<Locations[]> {
    throw new Error('Method not implemented.');
  }
  findAllByRackIdAndStatus(rackId: number, status: boolean): Promise<Locations[]> {
    throw new Error('Method not implemented.');
  }
  async getLocationPdf(location: Locations): Promise<string> {
    try {
      let QUERY = `locationId=${location.locationId}`;

      QUERY += `&storeId=${location.storeId}`;

      QUERY += `&zone=${location.zoneId}`;

      QUERY += `&layoutConfigId=${location.layoutId}`;

      QUERY += `&rackId=${location.rackId}`;

      QUERY += `&layoutRackId=${location.layoutRackId}`;

      QUERY += `&level=${location.level}`;

      const res = await http.getFile<Blob>(`${this.API_LOCATION_GET_TAG_PDF}${QUERY}`, 'application/pdf');

      const buffer = await res.arrayBuffer();

      const blob = new Blob([buffer], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      return url;
    } catch (error) {
      throw new Error('Error getting location pdf');
    }
  }

  async printTagsByZone(zone: ResizingZone): Promise<string> {
    try {
      const QUERY = `zone=${zone.zoneId}&storeId=${zone.storeId}&layoutConfigId=${zone.id}`;

      const res = await http.getFile<Blob>(`${this.API_LOCATION_GET_TAG_PDF_BY_ZONE}${QUERY}`, 'application/pdf');

      const buffer = await res.arrayBuffer();

      const blob = new Blob([buffer], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      return url;
    } catch (error) {
      throw new Error('Error getting location pdf');
    }
  }
}
