import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { Bulk } from '@/wms/domain/bulk/Bulk';
import { BulkRepository } from '@/wms/domain/bulk/BulkRepository';

@Injectable()
export class UpdateBulkPickingStatus extends Query<
  Promise<Bulk[]>,
  { bulks: string[]; status: boolean; carrier: number }
> {
  public constructor(
    @Inject(TYPES.BULK_REPOSITORY)
    private readonly repository: BulkRepository
  ) {
    super();
  }

  async internalExecute(payload: { bulks: string[]; status: boolean; carrier: number }): Promise<Bulk[]> {
    try {
      return await this.repository.updateBulkPickingStatus(payload);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
