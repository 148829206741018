
import { TYPES } from '@/core/config/Types';
import { Inject } from 'inversify-props';
import Vue from 'vue';
import Component from 'vue-class-component';
import { LoginImageConfig } from '@/settings/domain/loginImageConfig/LoginImageConfig';
import { LoginImage } from '@/settings/domain/loginImage/LoginImage';
import { Prop } from 'vue-property-decorator';
import { SaveLoginImageConfig } from '@/settings/application/uses_cases/loginImageConfig/create/SaveLoginImageConfig';
import { FindFile } from '@/settings/application/uses_cases/file/search/FindFile';
import { CreateFileV2 } from '@/settings/application/uses_cases/file/create/CreateFileV2';

@Component({
  name: 'LoginImageCard',
  components: {}
})
export default class LoginImageCard extends Vue {
  @Inject(TYPES.LOGIN_IMAGE_CONFIG_SAVE)
  readonly saveLoginImageConfig!: SaveLoginImageConfig;
  @Inject(TYPES.FIND_FILE_BY_NAME)
  readonly findFile!: FindFile;
  @Inject(TYPES.SAVE_FILE)
  readonly createFile!: CreateFileV2;

  @Prop() loginImage!: LoginImage;

  form: LoginImageConfig = new LoginImageConfig();
  companyId: string = localStorage.getItem('businessId') as string;
  fileGetted = '';
  $refs!: {
    fileInput: HTMLInputElement;
  };

  isLoading = false;

  mounted() {
    this.getAllData();
  }

  getAllData() {
    this.getImageFile();
  }

  get fileExtension() {
    return this.$refs.fileInput.files
      ? this.$refs.fileInput.files[0].name.substring(this.$refs.fileInput.files[0].name.lastIndexOf('.') + 1)
      : '';
  }

  async saveAllConfig() {
    try {
      const imageRes = await this.uploadImage();
      await this.saveConfig(imageRes);
      this.getImageFile();
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async uploadImage() {
    try {
      this.isLoading = true;
      // Verificamos si se selecciono un archivo
      if (this.$refs.fileInput?.files?.length === 0) {
        throw new Error('No file selected');
      }

      // Verificamos si el archivo existe
      if (!this.$refs.fileInput.files?.[0]) {
        throw new Error('No file selected');
      }

      // Verificamos si el archivo es una imagen, jpg, jpeg o png,svg, etc
      if (!['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml'].includes(this.$refs.fileInput.files?.[0].type)) {
        throw new Error('Invalid file type');
      }

      // Verificamos si el archivo es mayor a 1MB
      if (this.$refs.fileInput.files?.[0].size > 1000000) {
        throw new Error('File size is too big');
      }

      // Obtenemos el archivo seleccionado
      const file = this.$refs.fileInput.files?.[0];

      // Obtenemos la extensión del archivo
      const extension = file.name.substring(file.name.lastIndexOf('.') + 1);

      // Creamos un nuevo nombre para el archivo usando la convención de _ significa /
      const fileNewName = `customImages_${this.loginImage.type.toLowerCase().replaceAll('_', '')}.${extension}`;

      // Creamos un nuevo archivo con el nuevo nombre
      const newFile = new File([file], fileNewName, { type: file.type });

      // Creamos el archivo en el servidor
      const response = await this.createFile.execute({
        files: [newFile]
      });

      this.isLoading = false;

      if (!Array.isArray(response)) {
        return response;
      }

      // Retornamos el archivo creado
      return response[0];
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async saveConfig(fileName: string) {
    try {
      this.isLoading = true;
      this.form.file = fileName;
      this.form.type = this.loginImage.type;
      this.form.company = this.companyId;
      await this.saveLoginImageConfig.execute(this.form);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  getImageFile() {
    try {
      this.isLoading = true;
      this.fileGetted = this.findFile.execute(
        `${this.companyId}_customImages_${this.loginImage.type.toLowerCase().replaceAll('_', '')}.jpg`
      );
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
}
