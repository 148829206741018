
import Vue from 'vue';
import { selectedLocale } from '@/core/plugins/i18n';
import DetailInvoice from '@/courier/infrastructure/ui/components/invoice/DetailInvoice.vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { InvoiceUpdate } from '@/courier/application/uses_cases/invoice/update/InvoiceUpdate';
import { InvoiceFindAll } from '@/courier/application/uses_cases/invoice/search/InvoiceFindAll';
import { InvoiceFindByDateRange } from '@/courier/application/uses_cases/invoice/search/InvoiceFindByDateRange';
import { InvoiceFindByPk } from '@/courier/application/uses_cases/invoice/search/InvoiceFindByPk';
import { InvoiceFindByDateRangeAndCustomer } from '@/courier/application/uses_cases/invoice/search/InvoiceFindByDateRangeAndCustomer';
import { InvoiceFindByClientId } from '@/courier/application/uses_cases/invoice/search/InvoiceFindByClientId';

@Component({
  name: 'InvoiceHistory',
  components: {
    DetailInvoice
  }
})
export default class Invoice extends Vue {
  @Inject(TYPES.UPDATE_INVOICE)
  readonly invoiceUpdate!: InvoiceUpdate;
  @Inject(TYPES.FINDALL_INVOICE)
  readonly invoiceFindAllcall!: InvoiceFindAll;
  @Inject(TYPES.FINDBYCLIENTID_INVOICE)
  readonly invoiceFindByClientIdcall!: InvoiceFindByClientId;
  @Inject(TYPES.FINDBYDATERANGE_INVOICE)
  readonly InvoiceFindByDateRangecall!: InvoiceFindByDateRange;
  @Inject(TYPES.FINDBYDATERANGEANDCUSTOMER_INVOICE)
  readonly invoiceFindByDateRangeAndCustomercall!: InvoiceFindByDateRangeAndCustomer;
  @Inject(TYPES.FINDBYPK_INVOICE)
  readonly invoiceFindByPkcall!: InvoiceFindByPk;

  //DATA
  tabWizard = 0;
  selectedLocales = selectedLocale;
  editedIndex = -1;
  isDisabled = false;
  totalRows = 1;
  currentPage = 1;
  perPage = 5;
  filter = null;
  filterOn = [];
  shippingLines = [];
  sortBy = 'id';
  sortDesc = false;
  isBusy = true;
  arrayDetailInvoice = [];
  valueFrom = '';
  valueTo = '';
  arrayDateRange = [];
  form: any = {
    orderCustomerIdFrom: '',
    orderCustomerIdTo: '',
    orderCustomerNameFrom: '',
    orderCustomerNameTo: '',
    orderCustomerAddressFrom: '',
    orderCustomerAddressTo: '',
    orderCustomerPhoneTo: '',
    orderCountryFrom: '',
    orderCountryTo: '',
    orderStateFrom: '',
    orderStateTo: '',
    orderTotal: 0,
    orderTotalTaxes: 0,
    orderLength: 0,
    orderWidth: 0,
    orderHeight: 0,
    orderFindById: '',
    orderVirtualLocker: ''
  };

  created() {
    const date = new Date();
    const isoDateTime = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    this.valueFrom = new Date().getFullYear() + '-' + this.monthConcat + '-' + '01';
    this.valueTo = isoDateTime.substring(0, 10);
    this.invoiceFindByDateRange();
  }

  get fields() {
    return [
      { key: 'number', label: this.translateCol('nodocument'), sortable: true },
      { key: 'typeCode', label: this.translateCol('transactiontype'), sortable: true },
      { key: 'date', label: this.translateCol('documentdate'), sortable: true },
      { key: 'customerId', label: this.translateCol('customer'), sortable: true },
      { key: 'customerName', label: this.translateCol('name'), sortable: true },
      { key: 'docReference', label: this.translateCol('servicerequest'), sortable: true },
      { key: 'subtotal', label: this.translateCol('subtotal'), sortable: true },
      { key: 'tax', label: this.translateCol('taxes'), sortable: true },
      { key: 'total', label: this.translateCol('totalinvoiced'), sortable: true },
      { key: 'action', label: this.translateCol('action'), sortable: false }
    ];
  }

  get rows() {
    return this.arrayDateRange.length;
  }

  translateCol(colName: string) {
    return this.$i18n.t('general.' + colName);
  }

  get monthConcat() {
    return new Date().getMonth() + 1 > 9 ? new Date().getMonth() + 1 : '0' + (new Date().getMonth() + 1);
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  onFiltered(filteredItems: any) {
    // Trigger pagination to update the number of buttons/pages due to filtering.
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  }

  async invoiceFindByDateRange() {
    const parm: any = {
      dateFrom: this.valueFrom,
      dateTo: this.valueFrom
    };
    await this.InvoiceFindByDateRangecall.execute(parm)
      .then((response: any) => {
        this.arrayDateRange = response;
      })
      .catch(error => {
        throw new Error(error);
      });
  }

  async invoiceFindByPk(data: any, button: any) {
    const parm: any = {
      number: data.number,
      typeInvoice: data.typeCode,
      warehouse: data.warehouse
    };

    await this.invoiceFindByPkcall
      .execute(parm)
      .then((response: any) => {
        this.arrayDetailInvoice = response['data'];
        this.$root.$emit('bv::show::modal', 'mdDetailInvoice', button);
      })
      .catch(error => {
        throw new Error(error);
      });
  }
}
