import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Query } from '@/core/cqrs/Query';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Customer } from '@/courier/domain/customer/Customer';
import { CustomerRepository } from '@/courier/domain/customer/CustomerRepository';

@Injectable()
export class CustomerFindById extends Query<Promise<Customer[]>, string> {
  public constructor(
    @Inject(TYPES.CUSTOMER_REPOSITORY)
    private readonly customerRepository: CustomerRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(id: string): Promise<Customer[]> {
    const dataCustomer = await this.customerRepository.findById(id);
    this.stateManager.patch({ dataCustomer });
    return this.stateManager.state.dataCustomer;
  }
}
