import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Injectable } from '@/core/di/Injectable';
import { StateManager } from '@/core/statemanager/StateManager';
import { ZipCode } from '@/settings/domain/ZipCode/ZipCode';
import { ZipCodeRepository } from '@/settings/domain/ZipCode/ZipCodeRepository';
import { Inject } from 'inversify-props';

@Injectable()
export class SearchAll extends Query<Promise<ZipCode[]>> {
  public constructor(
    @Inject(TYPES.ZIPCODE_REPOSITORY)
    private readonly zipCodeRepository: ZipCodeRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(): Promise<ZipCode[]> {
    const dataZipCode = await this.zipCodeRepository.findAll();
    this.stateManager.patch({ dataZipCode });
    return this.stateManager.state.dataZipCode;
  }
}
