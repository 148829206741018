import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { ActivityTransactionalRepository } from '@/tracking/domain/activitiesTransactional/ActivityTransactionalRepository';
import { Injectable } from '@/core/di/Injectable';
import { ActivityTransactionalForTraceability } from '@/tracking/domain/activitiesTransactional/Projections/ActivityTransactionalForTraceability';

@Injectable()
export class FindActivitiesForTraceabilityByEvent extends Command<
  Promise<ActivityTransactionalForTraceability[]>,
  {
    preRegisterType: string;
    preRegisterNumber: number;
    eventId: number;
  }
> {
  public constructor(
    @Inject(TYPES.ACTIVITIES_TRANSACTIONAL_RESPOSITORY)
    private readonly activityTransactionalRepository: ActivityTransactionalRepository
  ) {
    super();
  }

  async internalExecute({
    preRegisterType,
    preRegisterNumber,
    eventId
  }: {
    preRegisterType: string;
    preRegisterNumber: number;
    eventId: number;
  }): Promise<ActivityTransactionalForTraceability[]> {
    return await this.activityTransactionalRepository.findActivitiesForTraceabilityByEventId({
      preRegisterType,
      preRegisterNumber,
      eventId
    });
  }
}
