import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { UnitOfMeasurementRepository } from '@/settings/domain/unitofmeasurement/UnitOfMeasurementRepository';
import { UnitOfMeasurement } from '@/settings/domain/unitofmeasurement/UnitOfMeasurement';

@Injectable()
export class UnitOfMeasurementServices implements UnitOfMeasurementRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_UNIT_OF_MEASUREMENT_SAVE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_UNIT_OF_MEASUREMENT_SAVE;
  private readonly API_UNIT_OF_MEASUREMENT_UPDATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_UNIT_OF_MEASUREMENT_UPDATE;
  private readonly API_UNIT_OF_MEASUREMENT_FINDALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_UNIT_OF_MEASUREMENT_FINDALL;

  async create(unitOfMeasurement: UnitOfMeasurement) {
    try {
      return await http.post<UnitOfMeasurement[]>(this.API_UNIT_OF_MEASUREMENT_SAVE, JSON.stringify(unitOfMeasurement));
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async update(unitOfMeasurement: UnitOfMeasurement) {
    try {
      return await http.put<UnitOfMeasurement[]>(
        this.API_UNIT_OF_MEASUREMENT_UPDATE,
        JSON.stringify(unitOfMeasurement)
      );
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findAll(): Promise<UnitOfMeasurement[]> {
    try {
      return await http.get<UnitOfMeasurement[]>(this.API_UNIT_OF_MEASUREMENT_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
