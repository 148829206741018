import localStorageVariables from '../config/LocalStorageVariables';

/**
 * @name LocalStoragerRemover
 * @description Elimina los datos del localStorage de la aplicación y que se encuentran en el archivo LocalStorageVariables.ts
 * @returns {void}
 * @memberof LocalStoragerRemover
 * @see LocalStorageVariables.ts
 */
const LocalStoragerRemover = (): void => {
  for (const key in localStorageVariables) {
    // Iteramos sobre las propiedades del objeto localStorageVariables
    const localKey = key as keyof typeof localStorageVariables;
    try {
      localStorage.removeItem(localStorageVariables[localKey]); // Eliminamos el item del localStorage
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
};

export default LocalStoragerRemover;
