
import { TYPES } from '@/core/config/Types';
import { FindDocumentsByMaster } from '@/courier/application/uses_cases/DocumentsV2/search/FindDocumentsByMaster';
import { DocumentHeader } from '@/courier/domain/DocumentsV2/Documents_V2';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import { Inject } from 'inversify-props';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'AssociatedDocumentsToMaster'
})
export default class AssociatedDocumentsToMaster extends Vue {
  @Inject(TYPES.DOCUMENTS_V2_FIND_BY_MASTER)
  readonly findDocumentsByMaster!: FindDocumentsByMaster;

  @Prop({ type: PreRegister, required: true }) master!: PreRegister;
  @Prop({ required: true }) setDocumentListModal!: (event: Event, documentList: DocumentHeader[]) => void;

  isLoading = false;

  documents: DocumentHeader[] = [];

  mounted() {
    this.findMasters();
  }

  async findMasters() {
    try {
      this.isLoading = true;

      const res = await this.findDocumentsByMaster.execute({
        number: Number(this.master.number),
        type: this.master.type
      });

      if (res.length > 0) {
        this.documents = res;
      }

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
}
