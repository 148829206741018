
import draggable from 'vuedraggable';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Menu } from '@/settings/domain/menu/menu';

@Component({
  name: 'draggable-list',
  components: { draggable }
})

/**
 * @name DraggableList
 * @description Componente que permite mostrar un arbol de niveles de grupo de referencia y permite moverlos
 * @author: San7iix
 */
export default class DraggableList extends Vue {
  /**
   * Props
   */

  @Prop({ required: true }) menuArray!: Menu[];
  @Prop({ required: false }) readonly parent!: string;
  @Prop() readonly updateItem!: ({ menuItem, parent }: { menuItem: Menu; parent: string }) => Promise<void>;
  @Prop() readonly loadToFormUpdate!: (event: PointerEvent, menuItem: Menu) => void;
  @Prop() readonly deleteItem!: (menuItem: Menu) => Promise<void>;

  /**
   * @name dragOptions
   * @description Opciones de configuracion para el componente draggable
   */
  dragOptions = {
    animation: 0,
    group: 'referenceGroupLevelId',
    disabled: false,
    ghostClass: 'ghost'
  };

  /**
   * Variables de ayuda
   */

  isLoading = false;

  /**
   * @name Update
   * @description Funcion que se ejecuta al terminar de mover un elemento y procesa el cambio de padre de un nodo
   * @param {any} value
   * @returns {Promise<void>}
   * @author: San7iix
   * @version 1.0.0
   * @date 2020-10-26
   */
  async update(value: any): Promise<void> {
    try {
      /**
       * Verificamos si el elemento se movio a si mismo
       */
      if (value.to.id === value.from.id && value.newIndex === value.oldIndex) return;
      /**
       * Verificamos si el elemento se movio a la raiz del arbol o si se mueve a un nodo padre
       */
      const parent = value.to.id === '' ? null : value.to.id;
      /**
       * Obtenemos el objeto que se movió desde el atributo data del elemento
       */
      const movedObject: Menu = JSON.parse(value.item.attributes.data.value);

      movedObject.sequence = value.newIndex + 1;

      /**
       * Actualizamos el objeto con el nuevo padre
       */
      await this.updateItem({
        menuItem: movedObject,
        parent: parent
      });
    } catch (error) {
      throw new Error('Error: ' + error);
    }
  }
}
