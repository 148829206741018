const header: any = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};
const get = async <T>(url: string) => {
  const response = await fetch(url, {
    method: 'GET',
    headers: header
  });
  return (await response.json()) as T;
};
const post = async <T>(url: string, body: any) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: header,
    body
  });
  return (await response.json()) as T;
};
const put = async <T>(url: string, body: any) => {
  const response = await fetch(url, {
    method: 'PUT',
    headers: header,
    body
  });
  return (await response.json()) as T;
};
const singleDelete = async <T>(url: string, body: any) => {
  const response = await fetch(url, {
    method: 'DELETE',
    headers: header,
    body
  });
  return (await response.json()) as T;
};

export const httpFetchNoToken = {
  get,
  post,
  put,
  singleDelete
};
