import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { PreparationForList } from '@/freight/domain/Preparation/PreparationForList';
import { PreparationRepository } from '@/freight/domain/Preparation/PreparationRepository';
@Injectable()
export class FindAllPreparationsByStatus extends Query<Promise<PreparationForList[]>, 'USED' | 'NOT_USED'> {
  constructor(
    @Inject(TYPES.CARGO_DETAILS_PRESET_REPOSITORY)
    private readonly repository: PreparationRepository
  ) {
    super();
  }

  async internalExecute(status: 'USED'|'NOT_USED'): Promise<PreparationForList[]> {
    return await this.repository.findByStatus(status);
  }
}
