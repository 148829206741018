
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { CountryFindAll } from '@/settings/application/uses_cases/country/search/CountryFindAll';
import { CompanyFindAll } from '@/settings/application/uses_cases/company/search/CompanyFindAll';
import { StateFindByCountryCode } from '@/settings/application/uses_cases/state/search/StateFindByCountryCode';
import { WarehouseFindAll } from '@/settings/application/uses_cases/warehouse/search/WarehouseFindAll';
import { ZoneFindByStatusAndCountryAndState } from '@/settings/application/uses_cases/zone/search/ZoneFindByStatusAndCountryAndState';
import { ValidationObserver } from 'vee-validate/dist/types/components';
import { CreateCustomer } from '@/courier/application/uses_cases/customer/create/CreateCustomer';

@Component({
  name: 'CreateCustomer'
})
export default class AppCreateCustomer extends Vue {
  @Inject(TYPES.FINDALL_COMPANY) readonly companyFindAll!: CompanyFindAll;
  @Inject(TYPES.FINDALL_COUNTRY) readonly countryFindAll!: CountryFindAll;
  @Inject(TYPES.FINDBYCOUNTRYCODE_STATE) readonly stateFindByCountryCode!: StateFindByCountryCode;
  @Inject(TYPES.FINDSTATUSCOUNTRYSTATE_ZONE)
  readonly zoneFindByStatusAndCountryAndState!: ZoneFindByStatusAndCountryAndState;
  @Inject(TYPES.FINDALL_WAREHOUSE) readonly warehouseFindAll!: WarehouseFindAll;
  @Inject(TYPES.CREATE_CUSTOMER) readonly createCustomer!: CreateCustomer;

  //Data
  isTouched = false;
  widthResize = 0;
  tabIndex = 0;
  zonesClient = [];
  countries = [];
  provinces = [];
  warehouses = [];
  dataCompany = [];
  form: any = {
    clientId: '',
    clientName: '',
    clientCompany: '',
    clientEmail: '',
    clientUserName: '',
    clientPassword: '',
    clientRePassword: '',
    clientAddress: '',
    clientCodePostal: [],
    clientPhone: '',
    clientCountry: [],
    clientState: [],
    clientVirtualLocker: [],
    clientCity: '',
    clientCurrency: ''
  };
  //Refs
  $refs!: {
    clientId: HTMLInputElement;
    formStep1: InstanceType<typeof ValidationObserver>;
    formStep2: InstanceType<typeof ValidationObserver>;
    formStep3: InstanceType<typeof ValidationObserver>;
    formStep4: InstanceType<typeof ValidationObserver>;
  };
  //Hook
  created() {
    this.$nextTick(() => this.findAllCompany);
    this.$nextTick(() => this.getCountryAll);
    this.$nextTick(() => this.getWarehouses);
    this.$nextTick(() => this.$refs.clientId.focus());
  }
  //Compute
  get findAllCompany() {
    this.companyFindAll.execute().then((response: any) => {
      this.dataCompany = response;
    });
    return true;
  }
  get getCountryAll() {
    this.countryFindAll.execute().then((response: any) => {
      this.countries = response;
    });
    return true;
  }
  get getWarehouses() {
    this.warehouseFindAll.execute().then((response: any) => {
      this.warehouses = response;
    });
    return true;
  }
  //Methods
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  handleResize() {
    this.widthResize = window.innerWidth;
  }
  getStateFindByCountryCode(country: any) {
    if (country.name === this.form.clientCountry.name) {
      this.stateFindByCountryCode
        .execute(country.code)
        .then((response: any) => {
          this.provinces = response;
          this.form.clientCurrency = country.currencyCode;
          return response;
        })
        .catch((error: any) => {
          this.form.clientCurrency = this.dataCompany[0]['localCurrency'];
          return error;
        });
    } else {
      this.form.clientState = [];
    }
  }
  getZoneByCountryAndState(countryFrom: any, stateFrom: any) {
    if (countryFrom.name === this.form.clientCountry.name && stateFrom.name === this.form.clientState.name) {
      this.form.clientCodePostal = [];
      const payload: any = {
        countryCode: countryFrom.code,
        stateCode: stateFrom.code
      };
      this.zoneFindByStatusAndCountryAndState
        .execute(payload)
        .then((response: any) => {
          this.zonesClient = response;
          //this.zonesClient.unshift('');
        })
        .catch(() => {
          this.zonesClient = [];
        });
    }
  }
  clearFormClient() {
    this.isTouched = false;
    this.zonesClient = [];
    this.tabIndex = 0;
    this.form = {
      clientId: '',
      clientName: '',
      clientCompany: '',
      clientEmail: '',
      clientUserName: '',
      clientPassword: '',
      clientRePassword: '',
      clientAddress: '',
      clientCodePostal: [],
      clientPhone: '',
      clientCountry: [],
      clientState: [],
      clientVirtualLocker: [],
      clientCity: '',
      clientWarehouse: false
    };
    this.$nextTick(() => this.$refs.formStep1.reset());
    this.$nextTick(() => this.$refs.formStep2.reset());
    this.$nextTick(() => this.$refs.formStep3.reset());
    this.$nextTick(() => this.$refs.formStep4.reset());
    this.$nextTick(() => this.$refs.clientId.focus());
  }
  registerClient() {
    const payload: any = {
      id: this.form.clientId.trim(),
      typeId: 'N',
      fullName: this.form.clientName.trim(),
      addressLine: {
        city: this.form.clientCity,
        country: this.form.clientCountry.code,
        description: this.form.clientAddress,
        state: this.form.clientState.code,
        zipCode: this.form.clientCodePostal.zoneZc
      },
      phone1: this.form.clientPhone.trim(),
      email: this.form.clientEmail.trim(),
      vlocker: {
        warehouseCode: this.form.clientVirtualLocker.code
      },
      username: this.form.clientUserName.trim(),
      password: this.form.clientPassword.trim(),
      boxId: true
    };
    this.createCustomer.execute(payload);
    this.clearFormClient();
  }
}
