import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Order } from '@/courier/domain/order/Order';
import { OrderRepository } from '@/courier/domain/order/OrderRepository';

@Injectable()
export class OrderFindAllByModule extends Query<Promise<Order[]>, any> {
  public constructor(
    @Inject(TYPES.ORDER_REPOSITORY)
    private readonly orderRepository: OrderRepository,
    @Inject(TYPES.STATE_MANAGER) private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(payload: any): Promise<Order[]> {
    const dataOrder = await this.orderRepository.findAllByModule(payload);
    this.stateManager.patch({ dataOrder });
    return this.stateManager.state.dataOrder;
  }
}
