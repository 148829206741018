
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { PreRegisterCreateReport } from '@/wms/application/preRegister/search/PreRegisterCreateReport';
import { BulkCubeInfo } from '@/wms/domain/bulk/BulkCubeInfo';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import { ValidationObserver } from 'vee-validate';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'CubeProcess'
})
export default class CubeProcess extends Vue {
  @Inject(TYPES.PRE_REGISTER_CREATE_REPORT)
  readonly createReport!: PreRegisterCreateReport;

  @Prop({ type: Object, required: true }) master!: PreRegister;
  @Prop({ type: Boolean, required: false, default: () => false }) disableFields!: PreRegister;
  @Prop({ required: false }) consigneeSelected!: string;
  @Prop({ required: true, default: () => [] }) bulks!: BulkCubeInfo;
  @Prop({ required: false }) dimensions!: {
    widthOnCube: number;
    heightOnCube: number;
    lengthOnCube: number;
    weightOnCube: number;
  };

  isLoading = false;

  $refs!: {
    cubeProcessForm: InstanceType<typeof ValidationObserver>;
  };

  getValidationState(errors: any[]) {
    return errors.length > 0 ? false : true;
  }

  async save() {
    return this.$refs.cubeProcessForm.validate();
  }

  async printReport() {
    try {
      this.isLoading = true;
      const response = await this.createReport.internalExecute({
        number: Number(this.master.number),
        type: this.master.type,
        report: 'cube'
      });

      if (response) {
        // Tomamos el objeto URL generado por el servicio y descargamos el archivo que ya ha sido convertido en Blob
        const link = document.createElement('a');
        link.href = response;
        link.setAttribute('download', `reporte_${this.master.number}.pdf`);
        document.body.appendChild(link);
        link.click();

        // Limpiamos la URL generada
        window.URL.revokeObjectURL(response);
      }
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }
}
