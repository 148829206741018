import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { EmailTypeConfigRepository } from '@/settings/domain/emailTypeConfig/EmailTypeConfigRepository';
import { EmailTypeConfig } from '@/settings/domain/emailTypeConfig/EmailTypeConfig';

@Injectable()
export class EmailTypeConfigService implements EmailTypeConfigRepository {
  private readonly API_BASE_URI: string = process.env.VUE_APP_URL_TOKEN ?? '';

  private readonly API_EMAIL_TYPES_SAVE_CONFIG: string =
    this.API_BASE_URI + localStorageModule().auth.routes.API_EMAIL_TYPES_SAVE_CONFIG;
  private readonly API_EMAIL_TYPES_FIND_BY_COMPANY_AND_TYPE: string =
    this.API_BASE_URI + localStorageModule().auth.routes.API_EMAIL_TYPES_FIND_BY_COMPANY_AND_TYPE;

  async saveConfig(config: EmailTypeConfig): Promise<EmailTypeConfig> {
    return await http.post<EmailTypeConfig>(this.API_EMAIL_TYPES_SAVE_CONFIG, JSON.stringify(config));
  }

  async findByCompanyAndType({ company, type }: { company: string; type: string }): Promise<EmailTypeConfig> {
    return await http.get<EmailTypeConfig>(
      this.API_EMAIL_TYPES_FIND_BY_COMPANY_AND_TYPE + `?company=${company}&type=${type}`
    );
  }
}
