
import { TYPES } from '@/core/config/Types';
import { CreateMenu } from '@/settings/application/uses_cases/menu/create/CreateMenu';
import { FindAllMenu } from '@/settings/application/uses_cases/menu/search/FindAllMenu';
import { UpdateMenu } from '@/settings/application/uses_cases/menu/update/UpdateMenu';
import { RoleFindAll } from '@/settings/application/uses_cases/role/search/RoleFindAll';
import { Menu } from '@/settings/domain/menu/menu';
import { Role } from '@/settings/domain/role/Role';
import { Inject } from 'inversify-props';
import Vue from 'vue';
import Component from 'vue-class-component';
import DraggableList from './components/DraggableList.vue';
import VJstree from 'vue-jstree';
import { RoleFindMenuPlaneByRoleId } from '@/settings/application/uses_cases/role/search/RoleFindMenuPlaneByRoleId';
import { UpdateRole } from '@/settings/application/uses_cases/role/update/UpdateRole';
import CreateRolForm from '@/settings/infrastructure/ui/components/licenseManagement/components/CreateRolForm.vue';
import { CreateRole } from '@/settings/application/uses_cases/role/create/CreateRole';
import { Company } from '@/settings/domain/company/Company';
import { RoleDelete } from '@/settings/application/uses_cases/role/delete/RoleDelete';
import CreateCompany from './components/CreateCompany.vue';
import { CreateCompany as CreateCompanyService } from '@/settings/application/uses_cases/company/create/CreateCompany';
import { Prop } from 'vue-property-decorator';

interface MenuTree extends Menu {
  children: MenuTree[];
  selected: boolean;
}

class FormRole {
  id: number | null = null;
  name = '';
  company = new Company();
  type: { type: string; id: number } = { type: 'USER', id: 2 };
}

@Component({
  name: 'LicenseCenter',
  components: {
    DraggableList,
    VJstree,
    CreateRolForm,
    CreateCompany
  }
})
export default class LicenseCenter extends Vue {
  @Inject(TYPES.MENU_FIND_ALL)
  readonly findAllMenu!: FindAllMenu;
  @Inject(TYPES.MENU_UPDATE)
  readonly updateMenu!: UpdateMenu;
  @Inject(TYPES.MENU_SAVE)
  readonly saveMenu!: CreateMenu;
  @Inject(TYPES.FINDALL_ROLE)
  readonly findAllRole!: RoleFindAll;
  @Inject(TYPES.FINDMENUPLANEBYROLEID_ROLE)
  readonly findMenuPlaneByRoleId!: RoleFindMenuPlaneByRoleId;
  @Inject(TYPES.UPDATE_ROLE)
  readonly updateRole!: UpdateRole;
  @Inject(TYPES.CREATE_ROLE)
  readonly createRole!: CreateRole;
  @Inject(TYPES.DELETE_ROLE)
  readonly deleteRoleInBd!: RoleDelete;
  @Inject(TYPES.CREATE_COMPANY)
  readonly createCompany!: CreateCompanyService;

  $refs!: {
    jsTree: VJstree;
  };

  /**
   * Variables de ayuda
   */
  isLoading = false;
  isEdit = false;
  roleSelected: Role | null = null;
  roleMenu: Menu[] = [];
  cardClass = '';

  /**
   * Variables de datos
   */
  rolesArray: Role[] = [];
  menuArray: MenuTree[] = [];

  /**
   * Variables de formulario
   */
  formRole: FormRole = new FormRole();
  formCompany: Company = new Company();

  /**
   * @name mounted
   * @description Hook de vue que se ejecuta cuando se carga el componente
   * @returns void
   * @author San7iix
   */
  mounted() {
    this.isLoading = true;
    Promise.all(this.getAllNecesaryData()).then(() => {
      this.isLoading = false;
    });
    this.setCardClass();
  }

  /**
   * @name getAllNecesaryData
   * @description Obtiene todos los datos necesarios para el componente
   * @returns Array de promesas
   * @author San7iix
   */
  getAllNecesaryData(): Promise<void>[] {
    try {
      return [this.getRoles(), this.getMenuData()];
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  setCardClass() {
    if (this.type === 'user') {
      this.cardClass = '';
    }
    if (this.type !== 'user') {
      this.cardClass = 'card';
    }
  }

  /**
   * @name getRoles
   * @description Obtiene los datos de los menus
   * @returns void
   * @throws Error
   * @author San7iix
   */
  async getRoles(): Promise<void> {
    try {
      const res = (await this.findAllRole.execute()) ?? [];
      if (this.type === 'user') {
        const companyId = localStorage.getItem('businessId');
        this.rolesArray = res.filter(item => {
          if (item.roleType === 2 && item.companyId === companyId) {
            return item;
          }
          return;
        });
      } else {
        this.rolesArray = res.filter(item => {
          if (item.roleType === 1) {
            return item;
          }
          return;
        });
      }
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  @Prop() type!: string;

  /**
   * @name getMenuData
   * @description Obtiene los datos de los menus
   * @returns void
   * @throws Error
   * @author San7iix
   */
  async getMenuData(): Promise<void> {
    try {
      const res = (await this.findAllMenu.execute()) ?? [];

      this.menuArray = res.map((menu: Menu) => this.treeFormatter(menu));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  /**
   * @description Obtiene el estado de validación
   * @param dirty
   * @param validated
   * @param valid
   *
   */
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }): any {
    return dirty || validated ? valid : null;
  }

  /**
   * @name treeFormatter
   * @description Formatea el array de menus para que sea compatible con el componente de arbol
   * @param menu
   */
  treeFormatter(menu: Menu): MenuTree {
    if (menu.children?.length > 0) {
      return {
        ...menu,
        children: menu.children.map((child: Menu) => this.treeFormatter(child)),
        selected: this.selectInRole(menu),
        icon: ''
      };
    } else {
      return {
        ...menu,
        children: [],
        selected: this.selectInRole(menu),
        icon: ''
      };
    }
  }

  /**
   * @name selectInRole
   * @description Selecciona los menus que tiene el rol
   * @param search Menu a buscar
   * @returns boolean
   */
  selectInRole(search: Menu) {
    if (!this.roleSelected) return false;
    return this.roleMenu.findIndex((menu: Menu) => search.id === menu.id) !== -1;
  }

  /**
   * @name onRoleChange
   * @description Se ejecuta cuando se cambia el rol para buscar el menu del rol seleccionado
   * @param role
   * @returns void
   */

  async onRoleChange(role: Role) {
    if (!role) {
      this.roleSelected = null;
      this.roleMenu = [];
      this.getAllNecesaryData();
      return;
    }

    this.isLoading = true;

    this.roleSelected = role;
    const res: Role = (await this.findMenuPlaneByRoleId.execute(role.id)) as any;
    this.roleMenu = res.menu;

    await this.getMenuData();
    this.isLoading = false;
  }

  /**
   * @name updateRoleMenu
   * @description Actualiza el menu del rol seleccionado
   * @param menu
   * @returns void
   */

  async updateRoleMenu() {
    if (!this.roleSelected) return;

    this.isLoading = true;
    const list: MenuTree[] = [];

    this.menuArray.map((menu: MenuTree) => {
      if (menu.selected) this.mapToList({ menu, list });
    });

    const role: Role = {
      ...this.roleSelected,
      menu: list
    };

    await this.updateRole.execute(role);
    this.onRoleChange(this.roleSelected);
  }

  mapToList({ menu, list }: { menu: any; list: Menu[] }) {
    if (menu.selected)
      list.push(
        Object.assign(new Menu(), {
          id: menu?.id,
          code: menu.code,
          name: menu.name,
          sequence: menu.sequence
        })
      );

    if (menu?.children) menu.children.map((child: MenuTree) => this.mapToList({ menu: child, list }));
  }

  /**
   * @name resetFormRole
   * @description Resetea el formulario de rol
   */
  resetFormRole() {
    this.formRole = new FormRole();
    this.$bvModal.show('modalRole');
  }

  resetFormCompany() {
    this.formCompany = new Company();
    this.$bvModal.show('modalCompany');
  }

  /**
   * @name assignRoleInfoToFormRole
   * @description Asigna la información del rol al formulario de rol
   */
  assignRoleInfoToFormRole(role: Role) {
    this.formRole = Object.assign(new FormRole(), {
      id: role.id,
      name: role.name,
      type: {
        id: role.roleType,
        type: role.roleTypeName
      },
      company: {
        id: role.companyId,
        businessName: role.companyName
      }
    });
    this.$bvModal.show('modalRole');
  }

  /**
   * @name saveRole
   * @description Guarda el rol en la base de datos
   * @returns void
   */
  async saveRole(): Promise<void> {
    if (!this.formRole) return;

    this.isLoading = true;

    const role: Role = {
      menu: [],
      roleType: this.formRole.type.id,
      roleTypeName: this.formRole.type.type,
      companyId: this.formRole.company.id,
      companyName: this.formRole.company.name,
      user: [],
      type: this.formRole.type,
      id: this.formRole.id ?? 0,
      name: this.formRole.name
    };

    await this.createRole.execute(role);
    this.getAllNecesaryData();
    this.isLoading = false;
    this.$bvModal.hide('modalRole');
  }

  /**
   * @name deleteRole
   * @description Elimina el rol seleccionado
   *
   * */
  deleteRole() {
    try {
      if (!this.roleSelected) return;

      this.isLoading = true;

      // await this.deleteRoleInBd.execute(this.roleSelected);

      this.getAllNecesaryData();
      this.roleSelected = null;
      this.isLoading = false;
      this.$bvModal.hide('modalRole');
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async saveCompany() {
    this.isLoading = true;
    const res = await this.createCompany.execute(this.formCompany);

    this.isLoading = false;
    if (res?.error) return;
    this.getAllNecesaryData();
    this.$bvModal.hide('modalCompany');
  }
}
