
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { ReferenceFindAll } from '@/wms/application/catalogs/references/search/ReferenceFindAll';
import { ReferenceFindAllManagedStock } from '@/wms/application/catalogs/references/search/ReferenceFindAllManagedStock';
import { ReferencesFindByQuery } from '@/wms/application/catalogs/references/search/ReferencesFindByQuery';
import { References } from '@/wms/domain/catalogs/references/References';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'ReferenceSearch',
  components: {}
})
export default class ReferenceSearch extends Vue {
  //Variable para almacenar la lista de territorios
  references: References[] = [];

  //Variable para controlar el estado de la carga
  isLoading = false;

  @Inject(TYPES.FINDALL_REFERENCE)
  readonly findAllReference!: ReferenceFindAll;
  @Inject(TYPES.FIND_REFERENCES_BY_QUERY)
  readonly findReferencesByQuery!: ReferencesFindByQuery;
  @Inject(TYPES.FIND_ALL_STOCK_MANAGED_REFERENCES)
  readonly findAllStockManagedReferences!: ReferenceFindAllManagedStock;

  @Prop({ required: true }) value!: References | null;
  @Prop({ required: false }) label!: string | null;
  @Prop({ required: false, default: '' }) containerClass!: string | null;
  @Prop({ required: false, default: '' }) validationRules!: string | null;
  @Prop({
    required: false,
    default: (): null => {
      return null;
    }
  })
  onInput!: (warehouse: References | null) => Promise<void> | void;
  @Prop({ required: false, default: false }) readonly disabled!: boolean;
  @Prop({ required: false, default: 'ALL' }) readonly type!: 'ALL' | 'STOCK' | 'SERVICE';

  mounted() {
    switch (this.type) {
      case 'ALL':
        this.findReferences();
        break;
      case 'STOCK':
        this.findManagedStockReferences();
        break;
      case 'SERVICE':
        this.findReferences();
        break;
    }
  }

  get localValue(): References | null {
    return this.value;
  }

  set localValue(value: References | null) {
    this.$emit('update:value', value);
  }

  /**
   * @description Funcion para buscar todos los warehouses y asignarlo a la lista
   * @name findReferences
   */
  async findReferences() {
    this.isLoading = true;
    try {
      const response = await this.findAllReference.execute();
      this.references = response;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  //Función invocada cuando se selecciona un territorio y se desea enviar al padre para ejecutar alguna acción.
  async dispatchInput(value: References | null) {
    if (this.onInput) await this.onInput(value);
  }

  async findByQuery(query: string) {
    this.isLoading = true;
    try {
      const response = await this.findReferencesByQuery.internalExecute(query);
      this.references = response;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  factorySearch(query: string) {
    if (query.length > 4) {
      this.findByQuery(query);
    } else if (query.length === 0) {
      this.findReferences();
    }
  }

  async findManagedStockReferences() {
    try {
      this.isLoading = true;
      const response = await this.findAllStockManagedReferences.execute();
      this.references = response;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }
}
