import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { BulkForPutAway } from '@/wms/domain/bulk/BulkForPutAway';
import { BulkRepository } from '@/wms/domain/bulk/BulkRepository';

@Injectable()
export class FindBulksForPutAway extends Query<Promise<BulkForPutAway[]>, { number: number; type: string }> {
  public constructor(
    @Inject(TYPES.BULK_REPOSITORY)
    private readonly repository: BulkRepository
  ) {
    super();
  }

  async internalExecute(payload: { number: number; type: string }): Promise<BulkForPutAway[]> {
    try {
      return await this.repository.findForPutAway(payload);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
