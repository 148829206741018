import { localStorageModule } from '@/core/config/ConfigurationServer';
import { http } from '@/core/http/HttpFetch';
import { PackingUnits } from '@/settings/domain/packing_units/PackingUnits';
import { PackingUnitsRepository } from '@/settings/domain/packing_units/PackingUnitsRepository';
import { injectable } from 'inversify-props';

@injectable()
export class PackingUnitServices implements PackingUnitsRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_PACKING_UNITS_SAVE =
    this.API_BASE_URI + localStorageModule().config.routes.API_PACKING_UNITS_SAVE;
  private readonly API_PACKING_UNITS_UPDATE =
    this.API_BASE_URI + localStorageModule().config.routes.API_PACKING_UNITS_UPDATE;
  private readonly API_PACKING_UNITS_DELETE =
    this.API_BASE_URI + localStorageModule().config.routes.API_PACKING_UNITS_DELETE;
  private readonly API_PACKING_UNITS_FIND_ALL =
    this.API_BASE_URI + localStorageModule().config.routes.API_PACKING_UNITS_FIND_ALL;
  private readonly API_PACKING_UNITS_FIND_aLL_BY_STATUS =
    this.API_BASE_URI + localStorageModule().config.routes.API_PACKING_UNITS_FIND_aLL_BY_STATUS;
  private readonly API_PACKING_UNITS_FIND_BY_ID =
    this.API_BASE_URI + localStorageModule().config.routes.API_PACKING_UNITS_FIND_BY_ID;

  async create(packingUnit: PackingUnits) {
    try {
      return await http.post<PackingUnits>(this.API_PACKING_UNITS_SAVE, JSON.stringify(packingUnit));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(packingUnit: PackingUnits) {
    try {
      return await http._put<PackingUnits>(this.API_PACKING_UNITS_UPDATE, JSON.stringify(packingUnit));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findPackingUnitById(packingUnitId: string) {
    try {
      return await http.get<PackingUnits>(this.API_PACKING_UNITS_FIND_BY_ID + packingUnitId);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findAll() {
    try {
      return await http.get<PackingUnits[]>(this.API_PACKING_UNITS_FIND_ALL);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findAllByStatus(status: boolean) {
    try {
      return await http.get<PackingUnits[]>(this.API_PACKING_UNITS_FIND_aLL_BY_STATUS + status);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async delete(packingUnit: PackingUnits) {
    try {
      return await http.delete<PackingUnits[]>(this.API_PACKING_UNITS_DELETE, JSON.stringify(packingUnit));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
