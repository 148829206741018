import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { EmailTypeConfig } from '@/settings/domain/emailTypeConfig/EmailTypeConfig';
import { EmailTypeConfigRepository } from '@/settings/domain/emailTypeConfig/EmailTypeConfigRepository';
import { Query } from '@/core/cqrs/Query';

@Injectable()
export class SearchEmailTypeConfig extends Query<Promise<EmailTypeConfig>, { type: string; company: string }> {
  public constructor(
    @Inject(TYPES.EMAIL_TYPE_CONFIG_REPOSITORY)
    private readonly repository: EmailTypeConfigRepository
  ) {
    super();
  }
  async internalExecute({ type, company }: { type: string; company: string }): Promise<EmailTypeConfig> {
    return await this.repository.findByCompanyAndType({ type, company });
  }
}
