
import Vue from 'vue';
import Component from 'vue-class-component';
import VJstree from 'vue-jstree';
import { DocumentFindAllByType } from '@/courier/application/uses_cases/documents/search/DocumentFindAllByType';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Document } from '@/courier/domain/documents/Document';
import { FindAllBillOfLandingWithOperationalData } from '@/wms/application/billOfLanding/search/FindallBillOfLandingWithOperationalData';
import { BillOfLanding } from '@/wms/domain/billOfLanding/BillOfLanding';

interface BLDocument {
  id: number | string;
  text: string;
  value: string;
  icon: string;
  opened: boolean;
  selected: boolean;
  disabled: boolean;
  loading: boolean;
  children: BLDocument[];
}

@Component({
  name: 'TransportDocumentList',
  components: { VJstree }
})
export default class TransportDocumentList extends Vue {
  // Injections
  @Inject(TYPES.DOCUMENT_TYPE_FINDALL_BY_TYPE) readonly findAllDocumentByType!: DocumentFindAllByType;
  @Inject(TYPES.BILL_OF_LANDING_FINDALL_WITH_OPERATION_DATA)
  readonly findAllBL!: FindAllBillOfLandingWithOperationalData;

  $refs!: {
    jsTree: VJstree;
  };

  // Data
  isLoading = false;
  documentType: Document[] = [];
  BLData: BillOfLanding[] = [];
  documentList: BLDocument[] | null = null;

  //  Methods

  consoleLog(element: any) {
    //eslint-disable-next-line no-console
    console.log(element);
  }

  mounted() {
    this.findDocuments();
    this.setTreeData();
  }

  async getBlData() {
    this.isLoading = true;
    const res = await this.findAllBL.execute();
    this.BLData = res;
    this.isLoading = false;
  }

  async setTreeData() {
    await this.getBlData();
    const MBLList: BLDocument[] = this.BLData.map(mbl => {
      const HBLList: BLDocument[] = mbl.houseBillOfLandingList.map(hbl => {
        return {
          id: hbl.serial,
          text: `House Bill of Lading #${hbl.serial}`,
          value: `hbl#${hbl.serial}`,
          icon: 'fa fa-file-text-o',
          opened: false,
          selected: false,
          disabled: false,
          loading: false,
          children: []
        };
      });
      return {
        id: mbl.serial,
        text: `Master Bill of Lading #${mbl.serial}`,
        value: `mbl#${mbl.serial}`,
        icon: 'fa fa-file-text-o',
        opened: false,
        selected: false,
        disabled: false,
        loading: false,
        children: HBLList
      };
    });
    this.documentList = [
      {
        id: 0,
        text: 'Ocean transport document',
        value: 'oceanTransportDocument',
        icon: 'fa fa-file-text-o',
        opened: false,
        selected: false,
        disabled: false,
        loading: false,
        children: MBLList
      }
    ];
  }

  async findDocuments() {
    this.isLoading = true;
    const res = await this.findAllDocumentByType.execute('WR');
    this.isLoading = false;
    this.documentType = res;
  }
}
