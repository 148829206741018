import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { CommodityTypeRepository } from '@/freight/domain/commodity_type/CommodityTypeRepository';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';

@Injectable()
export class UpdateCommodityType extends Query<Promise<CommodityType>, CommodityType> {
  public constructor(
    @Inject(TYPES.COMMODITY_TYPE_REPOSITORY)
    private readonly commodityTypeRepository: CommodityTypeRepository,
    @Inject(TYPES.STATE_MANAGER) private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(commodityType: CommodityType): Promise<CommodityType> {
    return await this.commodityTypeRepository.update(commodityType);
  }
}
