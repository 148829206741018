
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { TypeIdentification } from '@/settings/domain/entity/typeIdentification/TypeIdentification';
import { PaymentMethods } from '@/settings/domain/ParmsGeneral/paymentMethods/PaymentMethods';
import { GroupAgentService } from '@/settings/domain/ParmsGeneral/groupAgentService/GroupAgentService';
import { TypeContact } from '@/settings/domain/ParmsGeneral/typeContact/TypeContact';
import { Incoterms } from '@/settings/domain/ParmsGeneral/Incoterms/Incoterms';
import { TermsPayment } from '@/settings/domain/ParmsGeneral/termsPayment/TermsPayment';
import { TypeAddress } from '@/settings/domain/ParmsGeneral/typeAddress/TypeAddress';
import { CategoryReferences } from '@/wms/domain/catalogs/categoryReferences/CategoryReferences';
import { ClassReferences } from '@/wms/domain/catalogs/classReferences/ClassReferences';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import { Gamma } from '@/wms/domain/gamma/Gamma';

@Component({ name: 'EditDynamicElement' })
export default class EditDynamicElement extends Vue {
  @Prop() typeOfElement!: string;
  @Prop() items: any;
  @Prop() deleteRecord!: Function;
  @Prop() editRecord!: Function;

  isEdit = false;
  typeIdentificationForm = new TypeIdentification();
  categoriesForm = new PaymentMethods();
  classesForm = new GroupAgentService();
  typeContactForm = new TypeContact();
  incotermsForm = new Incoterms();
  termsPaymentForm = new TermsPayment();
  typeAddressForm = new TypeAddress();
  referenceCategoryForm = new CategoryReferences();
  referenceClassForm = new ClassReferences();
  priceListCommodityForm = new CommodityType();
  gammaForm = new Gamma();

  get fields() {
    const requiredFields = [];
    switch (this.typeOfElement) {
      case 'typeIdentification':
        requiredFields.push(
          {
            field: 'id',
            label: this.$t('general.id'),
            sortable: true
          },
          {
            field: 'type',
            label: this.$t('general.name'),
            sortable: true
          },
          {
            field: 'actions',
            label: this.$t('general.actions')
          }
        );
        break;
      case 'referenceCategory':
        requiredFields.push(
          {
            field: 'code',
            label: this.$t('general.code'),
            sortable: true
          },
          {
            field: 'category',
            label: this.$t('general.category'),
            sortable: true
          },
          {
            field: 'actions',
            label: this.$t('general.actions'),
            sortable: true
          }
        );
        break;
      case 'referenceClass':
        requiredFields.push(
          {
            field: 'code',
            label: this.$t('general.code'),
            sortable: true
          },
          {
            field: 'clazz',
            label: this.$t('general.clazz'),
            sortable: true
          },
          {
            field: 'actions',
            label: this.$t('general.actions'),
            sortable: true
          }
        );
        break;
      case 'priceList':
        requiredFields.push(
          {
            field: 'id',
            label: this.$t('general.id'),
            sortable: true
          },
          {
            field: 'description',
            label: this.$t('general.description'),
            sortable: true
          },
          {
            field: 'actions',
            label: this.$t('general.actions'),
            sortable: true
          }
        );
        break;
      case 'gamma':
        requiredFields.push(
          {
            field: 'gamma',
            label: this.$t('general.gamma'),
            sortable: true
          },
          {
            field: 'actions',
            label: this.$t('general.actions'),
            sortable: true
          }
        );
        break;
      default:
        requiredFields.push(
          {
            field: 'id',
            label: this.$t('general.id'),
            sortable: true
          },
          {
            field: 'name',
            label: this.$t('general.name'),
            sortable: true
          },
          {
            field: 'actions',
            label: this.$t('general.actions')
          }
        );
        break;
    }
    return requiredFields;
  }

  tableActions = {
    customActions: [
      { title: `${this.$t('general.edit')}`, icon: 'fa fa-pencil', variant: 'secondary', action: this.rowEdit },
      { title: `${this.$t('general.delete')}`, icon: 'fa fa-trash', variant: 'danger', action: this.deleteElement }
    ]
  };

  rowEdit(row: any) {
    this.isEdit = true;
    switch (this.typeOfElement) {
      case 'typeIdentification':
        this.typeIdentificationForm.id = row.id;
        this.typeIdentificationForm.type = row.type;
        break;
      case 'categories':
        this.categoriesForm.id = row.id;
        this.categoriesForm.name = row.name;
        break;
      case 'classes':
        this.classesForm.id = row.id;
        this.classesForm.name = row.name;
        break;
      case 'typeContact':
        this.typeContactForm.id = row.id;
        this.typeContactForm.name = row.name;
        break;
      case 'incoterms':
        this.incotermsForm.id = row.id;
        this.incotermsForm.name = row.name;
        break;
      case 'termsPayment':
        this.termsPaymentForm.id = row.id;
        this.termsPaymentForm.name = row.name;
        break;
      case 'typeAddress':
        this.typeAddressForm.id = row.id;
        this.typeAddressForm.name = row.name;
        break;
      case 'referenceCategory':
        this.referenceCategoryForm.code = row.code;
        this.referenceCategoryForm.category = row.category;
        break;
      case 'referenceClass':
        this.referenceClassForm.code = row.code;
        this.referenceClassForm.clazz = row.clazz;
        break;
      case 'priceList':
        this.priceListCommodityForm.id = row.id;
        this.priceListCommodityForm.description = row.description;
        break;
      case 'gamma':
        this.gammaForm.gamma = row.gamma;
        this.gammaForm.sizes = row.sizes;
        break;
    }
  }

  updateElement(data: any) {
    // eslint-disable-next-line
    console.log(data)
    this.editRecord(data);
    this.isEdit = false;
  }
  deleteElement(data: any) {
    this.deleteRecord(data);
  }
}
