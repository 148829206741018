import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Command } from '@/core/cqrs/Command';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { UnitOfMeasurement } from '@/settings/domain/unitofmeasurement/UnitOfMeasurement';
import { UnitOfMeasurementRepository } from '@/settings/domain/unitofmeasurement/UnitOfMeasurementRepository';

@Injectable()
export class CreateUnitOfMeasurement extends Command<Promise<any>, UnitOfMeasurement> {
  public constructor(
    @Inject(TYPES.STATE_MANAGER) private readonly stateManager: StateManager,
    @Inject(TYPES.UNITOFMEASUREMENT_REPOSITORY)
    private readonly unitOfMeasurementRepository: UnitOfMeasurementRepository
  ) {
    super();
  }
  internalExecute(unitOfMeasurement: UnitOfMeasurement): Promise<any> {
    return this.unitOfMeasurementRepository.create(unitOfMeasurement);
  }
}
