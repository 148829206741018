import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { Branch } from '@/settings/domain/branch/Branch';
import { BranchRepository } from '@/settings/domain/branch/BranchRepository';
@Injectable()
export class DeleteBranch extends Command<Promise<Branch | null>, string> {
  public constructor(@Inject(TYPES.BRANCH_REPOSITORY) private readonly branchRepository: BranchRepository) {
    super();
  }

  async internalExecute(code: string): Promise<Branch | null> {
    try {
      return await this.branchRepository.delete(code);
    } catch (error) {
      return null;
    }
  }
}
