/**
 * @class FindAllLedgerAccount
 * @description Clase que contiene la lógica de negocio para obtener todas las cuentas contables
 * @version 1.0.0
 * @Author EnriquePallares
 */

import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { LedgerAccount } from '@/courier/domain/ledgerAccount/LedgerAccount';
import { LedgerAccountRepository } from '@/courier/domain/ledgerAccount/LedgerAccountRepository';

@Injectable()
export class FindAllLedgerAccount extends Query<Promise<LedgerAccount[]>, null> {
  public constructor(
    @Inject(TYPES.LEDGER_ACCOUNT_REPOSITORY)
    private readonly repository: LedgerAccountRepository
  ) {
    super();
  }

  public async internalExecute(): Promise<LedgerAccount[]> {
    return await this.repository.findAll();
  }
}
