
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { FindAllBranch } from '@/settings/application/uses_cases/branch/search/FindAllBranch';
import { FindBranchByStatus } from '@/settings/application/uses_cases/branch/search/FindBranchByStatus';
import { Branch } from '@/settings/domain/branch/Branch';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'BranchSearch',
  components: {}
})
export default class BranchSearch extends Vue {
  //Variable para almacenar la lista de territorios
  branchs: Branch[] = [];

  //Variable para controlar el estado de la carga
  isLoading = false;

  @Inject(TYPES.BRANCH_FIND_BY_STATUS)
  readonly findActiveBranch!: FindBranchByStatus;
  @Inject(TYPES.BRANCH_FIND_ALL)
  readonly findAllBranch!: FindAllBranch;

  @Prop({ required: true }) value!: Branch | null;
  @Prop({ required: false }) label!: string | null;
  @Prop({ required: false, default: '' }) containerClass!: string | null;
  @Prop({ required: false, default: '' }) validationRules!: string | null;
  @Prop({
    required: false,
    default: (): null => {
      return null;
    }
  })
  onInput!: (warehouse: Branch | null) => Promise<void> | void;
  @Prop({ required: false, default: false }) readonly disabled!: boolean;

  mounted() {
    this.findBranchs();
  }

  get localValue(): Branch | null {
    return this.value;
  }

  set localValue(value: Branch | null) {
    this.$emit('update:value', value);
  }

  /**
   * @description Funcion para buscar todos los branchs y asignarlo a la lista
   * @name findBranchs
   */
  async findBranchs() {
    this.isLoading = true;
    try {
      const response = await this.findActiveBranch.execute(true);
      this.branchs = response;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  //Función invocada cuando se selecciona un territorio y se desea enviar al padre para ejecutar alguna acción.
  async dispatchInput(value: Branch | null) {
    if (this.onInput) await this.onInput(value);
  }
}
