export class PackingUnit {
  id: number;
  description: string;
  stackingQuantity: number;
  height: number;
  width: number;
  length: number;
  weight: number;
  active: boolean;
  createAt: string;
  updateAt: string;

  public constructor() {
    this.id = 0;
    this.description = '';
    this.stackingQuantity = 0;
    this.height = 0;
    this.width = 0;
    this.length = 0;
    this.weight = 0;
    this.active = false;
    this.createAt = '';
    this.updateAt = '';
  }
}
