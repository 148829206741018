
import Vue from 'vue';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';
import { TYPES } from '@/core/config/Types';
import Component from 'vue-class-component';
import { Inject } from 'inversify-props';
import { Prop } from 'vue-property-decorator';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import { WarehouseBulk } from '@/wms/domain/bulk/WarehouseBulk';

@Component({ name: 'WarehouseReceipt' })
export default class WarehouseReceipt extends Vue {
  @Prop({ required: true }) data!: PreRegister;
  @Prop({ required: true }) bulks!: WarehouseBulk[];
  @Inject(TYPES.FINDBYID_COMPANY) private readonly findByIdCompany!: CompanyFindById;

  logoImg = localStorage.getItem('urlLogoCompany') as string;
  isLoading = false;
  companyInfo = {
    companyName: '',
    companyAdress: '',
    companyPhoneNumber: '',
    companyEmail: '',
    companyZipCode: ''
  };

  created() {
    this.getCompanyInfo();
  }

  async getCompanyInfo() {
    try {
      this.isLoading = true;
      const companyId = localStorage.getItem('businessId') as string;
      const res: any = await this.findByIdCompany.execute(companyId);
      this.isLoading = false;
      this.companyInfo.companyName = res.businessName;
      this.companyInfo.companyAdress = res.address;
      this.companyInfo.companyPhoneNumber = res.phoneNumber;
      this.companyInfo.companyEmail = res.email;
      this.companyInfo.companyZipCode = res.zipCode;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  convertToTitleCase(str: string) {
    if (!str) {
      return '';
    }
    return str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
  }
}
