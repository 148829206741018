import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { ClassReferences } from '@/wms/domain/catalogs/classReferences/ClassReferences';
import { ClassReferencesRepository } from '@/wms/domain/catalogs/classReferences/ClassReferencesRepository';

@Injectable()
export class CreateClassReferences extends Command<Promise<any>, ClassReferences> {
  public constructor(
    @Inject(TYPES.CLASS_REFERENCE_REPOSITORY)
    private readonly classReferencesRepository: ClassReferencesRepository
  ) {
    super();
  }

  internalExecute(classReferences: ClassReferences): Promise<any> {
    return this.classReferencesRepository.create(classReferences);
  }
}
