
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { SearchGeneralPortByQuery } from '@/settings/application/uses_cases/generalPort/search/SearchGeneralPortByQuery';
import { GeneralPort } from '@/settings/domain/generalPort/GeneralPort';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'PortSearch',
  components: {}
})
export default class PortSearch extends Vue {
  /**
   * Componente para buscar puertos.
   * @module PortSearch
   * @vue-prop {Territories | null} value - Valor seleccionado.
   * @vue-prop {string | null} label - Etiqueta del campo.
   * @vue-prop {string | null} containerClass - Clase del contenedor.
   * @vue-prop {string | null} validationRules - Reglas de validación.
   * @vue-prop {function} onInput - Función que se ejecuta cuando se selecciona un territorio.
   * @vue-data {number | undefined} timeout - Timeout para evitar búsquedas repetitivas y solapadas.
   * @vue-data {number} searchInterval - Intervalo de tiempo para realizar la búsqueda.
   * @vue-data {Territories[]} territories - Lista de territorios.
   * @vue-data {boolean} isLoading - Estado de carga.
   * @vue-inject {SearchTerritoryByQueryDescription} searchTerritoriesByQueryParameter - Inyección de dependencia para buscar territorios por descripción.
   * @vue-methods {void} findTerritoriesFactory - Método que se ejecuta cuando el usuario escribe sobre el input para buscar territorios.
   * @vue-methods {Promise<void> | void} getPorts - Método que busca los territorios.
   * @vue-methods {Promise<void> | void} dispatchInput - Método que se ejecuta cuando se selecciona un territorio y se desea enviar al padre para ejecutar alguna acción.
   */

  //Timeout para evitar búsquedas repetitivas y solapadas.
  timeout: any | undefined = undefined;

  //Intervalo de tiempo para realizar la búsqueda
  searchInterval = 500;

  //Variable para almacenar la lista de territorios
  ports: GeneralPort[] = [];

  //Variable para controlar el estado de la carga
  isLoading = false;

  @Inject(TYPES.GENERAL_PORTS_FIND_BY_QUERY)
  readonly searchPorts!: SearchGeneralPortByQuery;

  @Prop({ required: true }) value!: GeneralPort | null;
  @Prop({ required: false }) label!: string | null;
  @Prop({ required: false, default: '' }) containerClass!: string | null;
  @Prop({ required: false, default: '' }) validationRules!: string | null;
  @Prop({
    required: false,
    default: (): null => {
      return null;
    }
  })
  onInput!: (territories: GeneralPort) => Promise<void> | void;
  @Prop({ required: false, default: false }) readonly disabled!: boolean;

  get localValue(): GeneralPort | null {
    return this.value;
  }

  set localValue(value: GeneralPort | null) {
    this.$emit('update:value', value);
  }

  // Este método es llamado cuando el usuario escribe sobre el input para buscar territorios.
  findTerritoriesFactory(query: string): void {
    //Limpiamos cualquier función anterior en el timeout para evitar búsquedas repetitivas y solapadas.
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      (async () => {
        if (query.length >= 3) {
          try {
            this.isLoading = true;
            const res = await this.getPorts(query);
            this.ports = res;
          } catch (error) {
            this.ports = [];
            throw new Error(`${error}`);
          } finally {
            this.isLoading = false;
          }
        }
        clearTimeout(this.timeout);
      })();
    }, this.searchInterval);
  }

  //Funcion invocada para buscar los territorios
  async getPorts(query: string) {
    this.isLoading = true;
    const res = await this.searchPorts.execute(query.toUpperCase());
    this.isLoading = false;
    return res;
  }

  //Función invocada cuando se selecciona un territorio y se desea enviar al padre para ejecutar alguna acción.
  async dispatchInput(value: GeneralPort) {
    if (this.onInput) await this.onInput(value);
  }
}
