
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import ShippingDetails from '@/freight/infrastructure/ui/quotation/form/shippingDetails/ShippingDetails.vue';
import { ViewLocationFindAllStoreAndCommodity } from '@/settings/application/uses_cases/views/location/search/ViewLocationFindAllStoreAndCommodity';
import { CommodityClass } from '@/settings/domain/commodityClass/CommodityClass';
import { Containers } from '@/settings/domain/containers/Containers';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { PackingUnits } from '@/settings/domain/packing_units/PackingUnits';
import { TypeOperation } from '@/settings/domain/typeOperation/TypeOperation';
import { ViewLocation } from '@/settings/domain/views/location/ViewLocation';
import { Territories } from '@/settings/domain/views/territories/Territories';
import { BillOfLanding } from '@/wms/domain/billOfLanding/BillOfLanding';
import { Bulk } from '@/wms/domain/bulk/Bulk';
import { Store } from '@/wms/domain/layout/store/Store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

interface Lists {
  containerList?: Containers[];
  originList?: Territories[];
  destinationList?: Territories[];
  commodityList?: CommodityType[];
  packagingList?: PackingUnits[];
  referencesList?: any[];
  typeOperationList?: TypeOperation[];
  locationList?: ViewLocation[];
  weightList?: any[];
  commodityClassesList?: CommodityClass[];
  customerList?: Entity[];
  billOfLandingList?: BillOfLanding[];
}

@Component({
  name: 'BulkInformation',
  components: {
    ShippingDetails
  }
})
export default class BulkInformation extends Vue {
  @Inject(TYPES.VIEW_FIND_LOCATIONS_BY_STORE_AND_COMMODITY)
  readonly findLocationsByStoreAndCommodity!: ViewLocationFindAllStoreAndCommodity;

  @Prop({ required: true }) bulk!: Bulk;
  @Prop({ required: true }) lists!: Lists;
  @Prop({ required: true }) store!: Store;
  @Prop({ required: true }) setLocation!: (location: ViewLocation, index: number) => void;
  @Prop({ required: true }) index!: number;
  @Prop({ required: true }) split!: (bulk: Bulk) => Promise<void>;

  locationList: ViewLocation[] = [];
  selectedLocation: ViewLocation | null = null;

  get watchBulk() {
    return this.bulk;
  }

  mounted() {
    this.findLocations();
  }

  async findLocations() {
    try {
      this.locationList = await this.findLocationsByStoreAndCommodity.execute({
        commodityId: this.bulk.shippingDetails.commodity?.id ?? 0,
        storeCode: this.store.storeId
      });
    } catch (error) {
      this.locationList = [];
      throw error;
    }
  }
}
