import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { UserERP } from '@/settings/domain/userERP/UserERP';
import { UserErpRepository } from '@/settings/domain/userERP/UserErpRepository';

@Injectable()
export class UserErpServices implements UserErpRepository {
  async create(person: UserERP): Promise<UserERP> {
    try {
      return await http._postERP<UserERP>(`${process.env.VUE_APP_ROUTE_API_ERP}/api/user`, JSON.stringify(person));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async update(person: UserERP): Promise<UserERP> {
    try {
      return await http._putERP<UserERP>(`${process.env.VUE_APP_ROUTE_API_ERP}/api/user`, JSON.stringify(person));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findByEmail(email: string): Promise<UserERP> {
    try {
      return await http._getERP<UserERP>(`${process.env.VUE_APP_ROUTE_API_ERP}/api/user/${email}/userByEmail`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
