
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'CustomButton'
})
export default class CustomButton extends Vue {
  //DATA
  @Prop() readonly actions!: undefined;
}
