import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { ZipCode } from '@/settings/domain/ZipCode/ZipCode';
import { ZipCodeRepository } from '@/settings/domain/ZipCode/ZipCodeRepository';

@Injectable()
export class ZipCodeService implements ZipCodeRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_ZONE_FINDALL: string = this.API_BASE_URI + localStorageModule().config.routes.API_ZONE_FINDALL;
  private readonly API_ZONE_FINDBY_COUNTRYANDSTATEBYSTATUS: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ZONE_FINDBY_COUNTRYANDSTATEBYSTATUS;
  private readonly API_ZONE_FINDBY_COUNTRYANDSTATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ZONE_FINDBY_COUNTRYANDSTATE;

  async findAll(): Promise<ZipCode[]> {
    return await http.get<ZipCode[]>(this.API_ZONE_FINDALL);
  }

  async findByCountryStateAndCityActive(payload: any): Promise<ZipCode[]> {
    return await http.get<ZipCode[]>(
      `${this.API_ZONE_FINDBY_COUNTRYANDSTATEBYSTATUS}country=${payload.country}&state=${payload.state}&city=${payload.city}&active=${payload.active}`
    );
  }

  async findByCountryStateAndCity(payload: any): Promise<ZipCode[]> {
    return await http.get<ZipCode[]>(
      `${this.API_ZONE_FINDBY_COUNTRYANDSTATE}country=${payload.country}&state=${payload.state}&city=${payload.city}`
    );
  }
}
