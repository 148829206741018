
import { Stock } from '@/wms/domain/stock/Stock';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'FreightStockResume',
  components: {}
})
export default class FreightStockResume extends Vue {
  //Props
  @Prop({ required: true, default: [] }) stockResume!: Stock[];

  //Table fields
  get fields() {
    return [
      {
        title: `Serial`,
        field: 'codigo',
        key: 'codigo'
      },
      {
        title: `${this.$t('general.description')}`,
        field: 'description',
        key: 'description'
      },
      {
        title: `${this.$t('general.year')}`,
        field: 'ano',
        key: 'ano'
      },
      {
        title: `${this.$t('general.month')}`,
        field: 'mes',
        key: 'mes'
      },
      {
        title: `${this.$t('general.store')}`,
        field: '',
        key: 'store',
        renderBodyCell: ({ row }: any, h: any) => h('span', `${row.bodega} -${row.store_name}`)
      },
      {
        title: `${this.$t('general.initialStock')}`,
        field: 'can_ini',
        key: 'can_ini'
      },
      {
        title: `${this.$t('general.enteredQuantity')}`,
        field: 'can_ent',
        key: 'can_ent'
      },
      {
        title: `${this.$t('general.outQuantity')}`,
        field: 'can_sal',
        key: 'can_sal'
      },
      {
        title: `${this.$t('general.finalStock')}`,
        field: 'stock',
        key: 'stock'
      }
    ];
  }

  /**
   * Propiedades para la tabla de preregistros
   */
  columnWidthResizeOption = {
    enable: true,
    minWidth: 20
  };
  cellStyleOption = {
    headerCellClass: () => {
      return 'table-header-cell-class';
    }
  };
}
