import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { CommodityClassRepository } from '@/settings/domain/commodityClass/CommodityClassRepository';
import { CommodityClass } from '@/settings/domain/commodityClass/CommodityClass';
import { Query } from '@/core/cqrs/Query';

@Injectable()
export class FindByCodeCommodityClass extends Query<Promise<CommodityClass>, string> {
  public constructor(
    @Inject(TYPES.COMMODITY_CLASS_REPOSITORY)
    private readonly repository: CommodityClassRepository
  ) {
    super();
  }

  internalExecute(code: string): Promise<CommodityClass> {
    return this.repository.findByCode(code);
  }
}
