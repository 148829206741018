
import Vue from 'vue';
import { WmsfOrderCategory } from '@/courier/domain/WmsfOrderCategory/WmsfOrderCategory';
import { WmsfOrderReferences } from '@/courier/domain/WmsfOrderReferences/WmsfOrderReferences';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'AddServices',
  components: {}
})
export default class AddServices extends Vue {
  @Prop() items!: any[];
  @Prop() form!: WmsfOrderReferences[] | WmsfOrderCategory[];

  //Getter para obtener
  get formModel() {
    return this.form;
  }

  set formModel(newValue: WmsfOrderReferences[] | WmsfOrderCategory[]) {
    this.$emit('update:form', newValue);
  }

  //Funcion que genera una descripcion a partir del tipo de objeto que ingresa
  generateDescription(item: any) {
    return 'references' in item ? item.category.description : item.reference.filterBrand;
  }
}
