import { AirportRepository } from '@/settings/domain/airport/AirportRepository';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import Airport from '@/settings/domain/airport/Airport';
import { http } from '@/core/http/HttpFetch';

@Injectable()
export class AirportService implements AirportRepository {
  private readonly API_BASE_URI: string = process.env.VUE_APP_URL_TOKEN ?? '';
  private readonly API_AIRPORT_SAVE: string = this.API_BASE_URI + localStorageModule().auth.routes.API_AIRPORT_SAVE;
  private readonly API_AIRPORT_UPDATE: string = this.API_BASE_URI + localStorageModule().auth.routes.API_AIRPORT_UPDATE;
  private readonly API_AIRPORT_FIND_BY_CODE: string =
    this.API_BASE_URI + localStorageModule().auth.routes.API_AIRPORT_FIND_BY_CODE;
  private readonly API_AIRPORT_FIND_ALL: string =
    this.API_BASE_URI + localStorageModule().auth.routes.API_AIRPORT_FIND_ALL;

  async save(airport: Airport): Promise<Airport> {
    return await http.post<Airport>(this.API_AIRPORT_SAVE, JSON.stringify(airport));
  }

  async update(airport: Airport): Promise<Airport> {
    return await http._put<Airport>(this.API_AIRPORT_UPDATE, JSON.stringify(airport));
  }

  async findByCode(code: string): Promise<Airport> {
    return await http.get<Airport>(this.API_AIRPORT_FIND_BY_CODE + 'code=' + code);
  }

  async findAll(): Promise<Airport[]> {
    return await http.get<Airport[]>(this.API_AIRPORT_FIND_ALL);
  }
}
