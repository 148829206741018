
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { LoginImageFindAll } from '@/settings/application/uses_cases/loginImage/search/LoginImageFindAll';
import { LoginImage } from '@/settings/domain/loginImage/LoginImage';
import Vue from 'vue';
import Component from 'vue-class-component';
import LoginImageCard from './LoginImageCard.vue';
@Component({
  name: 'LoginImageCenter',
  components: { LoginImageCard }
})
export default class LoginImageCenter extends Vue {
  @Inject(TYPES.LOGIN_IMAGE_FIND_ALL)
  readonly findAllLoginImages!: LoginImageFindAll;

  /**
   * Variable usada para activar el loader en la pagina
   */
  isLoading = false;

  /**
   * Variable usada para almacenar los tipos de Email disponibles
   */
  loginImages: LoginImage[] = [];
  mounted() {
    this.findLoginImages();
  }

  /**Funcion usada para obtener todos los tipos de email disponibles para configurar */
  async findLoginImages() {
    try {
      this.isLoading = true;
      const res = (await this.findAllLoginImages.execute()) ?? [];
      this.loginImages = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
}
