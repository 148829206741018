import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { CurrencyFactor } from '@/settings/domain/currencyfactor/CurrencyFactor';
import { CurrencyFactorRepository } from '@/settings/domain/currencyfactor/CurrencyFactorRepository';
import { Command } from '@/core/cqrs/Command';

@Injectable()
export class CreateCurrencyFactor extends Command<Promise<any>, CurrencyFactor> {
  public constructor(
    @Inject(TYPES.CURRENCYFACTOR_REPOSITORY)
    private readonly currencyFactorRepository: CurrencyFactorRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  internalExecute(currencyFactor: CurrencyFactor): Promise<any> {
    return this.currencyFactorRepository.create(currencyFactor);
  }
}
