import { Size } from './Size';

export class Gamma {
  gamma: string;
  description: string;
  sizes: Size[];

  public constructor(gamma?: string, description?: string, sizes?: Size[]) {
    this.gamma = gamma || '';
    this.sizes = sizes || [];
    this.description = description || '';
  }
}
