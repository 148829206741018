import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Packaging } from '@/wms/domain/catalogs/packaging/Packaging';
import { PackagingRepository } from '@/wms/domain/catalogs/packaging/PackagingRepository';

@Injectable()
export class FindByReferencePackaging extends Query<Promise<Packaging[]>, string> {
  public constructor(
    @Inject(TYPES.PACKAGING_REFERENCE_REPOSITORY)
    private readonly packagingRepository: PackagingRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(code: string): Promise<Packaging[]> {
    const dataPackaging = await this.packagingRepository.findByReference(code);
    this.stateManager.patch({ dataPackaging });
    return this.stateManager.state.dataPackaging;
  }
}
