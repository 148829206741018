import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { ViewLocation } from '@/settings/domain/views/location/ViewLocation';
import { ViewLocationRepository } from '@/settings/domain/views/location/ViewLocationRepository';

@Injectable()
export class ViewLocationService implements ViewLocationRepository {
  private readonly API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;
  private readonly API_LOCATIONS_FIND_BY_STORE_AND_COMMODITY: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_LOCATIONS_FIND_BY_STORE_AND_COMMODITY;

  async findByWarehouseAndCommodity(payload: { storeCode: number; commodityId: number }): Promise<ViewLocation[]> {
    try {
      return await http.get<ViewLocation[]>(
        `${this.API_LOCATIONS_FIND_BY_STORE_AND_COMMODITY}storeId=${payload.storeCode}&commodity=${payload.commodityId}`
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
