import { TYPES } from '@/core/config/Types';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Query } from '@/core/cqrs/Query';
import { StateManager } from '@/core/statemanager/StateManager';
import { ChartValues } from '@/tracking/domain/timeline/ChartValues';
import { TimelineRepository } from '@/tracking/domain/timeline/TimelineRepository';

@Injectable()
export class FindChartValuesByTimeLine extends Query<Promise<ChartValues[]>, number> {
  public constructor(
    @Inject(TYPES.TIMELINE_REPOSITORY)
    private readonly timelineRepository: TimelineRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(params: number): Promise<ChartValues[]> {
    const dataChartValues = await this.timelineRepository.findChartValuesByTimeLine(params);
    this.stateManager.patch({ dataChartValues });
    return this.stateManager.state.dataChartValues;
  }
}
