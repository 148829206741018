import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { PreEntity } from '@/settings/domain/pre_entity/PreEntity';
import { PreEntityRepository } from '@/settings/domain/pre_entity/PreEntityRepository';

@Injectable()
export class SearchPreEntityByFullNameLike extends Query<Promise<PreEntity[]>, string> {
  public constructor(
    @Inject(TYPES.API_PRE_ENTITY_REPOSITORY)
    private readonly service: PreEntityRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(query: string): Promise<PreEntity[]> {
    const dataPreEntity = await this.service.findByFullNameLike(query);
    this.stateManager.patch({ dataPreEntity });
    return this.stateManager.state.dataPreEntity;
  }
}
