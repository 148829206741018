
import Component from 'vue-class-component';
import Vue from 'vue';
import BreadCrumbs from '@/core/components/dashboard/BreadCrumbs.vue';
import AddContainer from './AddContainer.vue';
import { Containers } from '@/settings/domain/containers/Containers';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { FindAllContainers } from '@/settings/application/uses_cases/containers/search/FindAllContianers';
import { CreateContainer } from '@/settings/application/uses_cases/containers/create/CreateContainer';
import { UpdateContainer } from '@/settings/application/uses_cases/containers/update/UpdateContainer';
import { FindContainerById } from '@/settings/application/uses_cases/containers/search/FindContainerById';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';

@Component({
  name: 'ContainerCenter',
  components: { BreadCrumbs, AddContainer, CustomTableN }
})
export default class ContainerCenter extends Vue {
  @Inject(TYPES.FIND_ALL_CONTAINERS)
  readonly findAllContainers!: FindAllContainers;
  @Inject(TYPES.CREATE_CONTAINER)
  readonly createContainer!: CreateContainer;
  @Inject(TYPES.UPDATE_CONTAINER)
  readonly updateContainer!: UpdateContainer;
  @Inject(TYPES.FIND_CONTAINER_BY_ID)
  readonly findContainerBydId!: FindContainerById;

  formFunctions = {
    clearForm: this.clearForm,
    factory: this.factory,
    find: this.findContainer
  };

  actions = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: this.loadInfo
      },
      {
        title: `${this.$t('general.delete')}`,
        icon: 'fa fa-trash',
        variant: 'danger',
        action: this.deleteContainer
      }
    ]
  };

  buttonOptions = {
    variant: 'success',
    buttonTitle: 'general.add',
    icon: 'fa-plus',
    isEdit: false
  };

  isLoading = false;
  containers: Containers[] = [];
  form: Containers = new Containers();
  //Getters
  get fields() {
    return [
      { label: 'ID', field: 'id', sortable: true },
      { label: this.$t('general.description'), field: 'description', sortable: true },
      { label: this.$t('general.size'), field: 'size', sortable: true },
      { label: this.$t('general.capacity'), field: 'capacity', sortable: true },
      {
        label: this.$t('general.status'),
        field: 'active',
        sortable: true,
        formatFn: (value: boolean) => (value ? this.$t('general.active') : this.$t('general.disabled'))
      },
      { label: this.$t('general.actions'), field: 'actions' }
    ];
  }

  //Methods
  created() {
    this.findContainers();
  }

  factory() {
    if (!this.buttonOptions.isEdit) {
      this.create();
      return;
    }
    this.update();
  }

  async findContainers() {
    await this.findAllContainers
      .execute()
      .then((response: any) => {
        response.reverse();
        this.isLoading = false;
        this.containers = response;
      })
      .catch(error => {
        this.isLoading = false;
        throw new Error(error);
      });
  }

  async update() {
    this.isLoading = true;
    await this.updateContainer
      .execute(this.form)
      .then(() => {
        this.isLoading = false;
        this.form = new Containers();
        this.findContainers();
      })
      .catch((error: any) => {
        this.isLoading = false;
        throw new Error(error);
      });
  }

  async create() {
    this.isLoading = true;
    await this.createContainer
      .execute(this.form)
      .then(() => {
        this.isLoading = false;
        this.form = new Containers();
        this.findContainers();
      })
      .catch((error: any) => {
        this.isLoading = false;
        throw new Error(error);
      });
  }

  async deleteContainer(data: any) {
    this.isLoading = true;
    data.active = false;
    await this.updateContainer
      .execute(data)
      .then(() => {
        this.isLoading = false;
        this.findContainers();
      })
      .catch(error => {
        this.isLoading = false;
        throw new Error(error);
      });
  }

  clearForm() {
    this.form = new Containers();
    this.buttonOptions = {
      variant: 'success',
      buttonTitle: 'general.add',
      icon: 'fa-plus',
      isEdit: false
    };
  }

  loadInfo(data: Containers) {
    this.buttonOptions = {
      variant: 'secondary',
      buttonTitle: 'general.edit',
      icon: 'fa-save',
      isEdit: true
    };
    this.form = data;
  }

  findContainer() {
    const container =
      this.containers.filter((item: Containers) => {
        if (item.id === this.form.id) {
          this.buttonOptions = {
            variant: 'secondary',
            buttonTitle: 'general.edit',
            icon: 'fa-save',
            isEdit: true
          };
        }
        return item.id === this.form.id;
      })[0] || this.form;

    this.form.active = container.active;
    this.form.id = container.id;
    this.form.size = container.size;
    this.form.capacity = container.capacity;
    this.form.description = container.description;
  }
}
