
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { TimelineConfig } from '@/tracking/domain/timelineConfig/TimelineConfig';
import { AssignReferencesToTimeline } from '@/wms/application/catalogs/references/create/AssignReferencesToTimeline';
import { ReferenceFindAll } from '@/wms/application/catalogs/references/search/ReferenceFindAll';
import { ReferenceFindCodeByTimeline } from '@/wms/application/catalogs/references/search/ReferenceFindCodeByTimeline';
import { References } from '@/wms/domain/catalogs/references/References';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
@Component({
  name: 'AsociateReferences',
  components: {}
})
export default class AsociateReferences extends Vue {
  @Inject(TYPES.FINDALL_REFERENCE)
  private findReferences!: ReferenceFindAll;
  @Inject(TYPES.TIMELINE_REFERENCES_ASSIGN)
  private assignReferences!: AssignReferencesToTimeline;
  @Inject(TYPES.TIMELINE_REFERENCES_CODE_FIND_BY_TIMELINE)
  private findReferencesByTimeline!: ReferenceFindCodeByTimeline;

  @Prop({ required: true })
  readonly timeline!: TimelineConfig | null;

  references: References[] = [];
  isLoading = false;
  selectedReferences: string[] = [];

  get serviceReferences() {
    return this.references.filter(reference => reference.noManageStock);
  }

  getData() {
    if (!this.timeline) {
      return;
    }
    this.getReferences();
    this.getAssignedReferences();
  }

  async getAssignedReferences() {
    try {
      if (!this.timeline) return;
      this.isLoading = true;
      const response = await this.findReferencesByTimeline.internalExecute({ timeline: this.timeline.id });
      this.selectedReferences = response;
    } catch (error) {
      this.selectedReferences = [];
    } finally {
      this.isLoading = false;
    }
  }

  async saveReferences() {
    if (!this.timeline) {
      return;
    }
    try {
      this.isLoading = true;
      await this.assignReferences.internalExecute({ timeline: this.timeline.id, references: this.selectedReferences });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      this.isLoading = false;
      this.getData();
    }
  }

  async getReferences() {
    try {
      this.isLoading = true;
      const response = await this.findReferences.internalExecute();
      this.references = response;
    } catch (error) {
      this.references = [];
    } finally {
      this.isLoading = false;
    }
  }
}
