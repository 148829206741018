import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { DocsLinRepository } from '@/courier/domain/orderline/DocsLinRepository';
import { OrderLine } from '@/courier/domain/orderline/OrderLine';

@Injectable()
export class DocsLinService implements DocsLinRepository {
  //#region [RUTAS DEL API]
  /**
   * @description Ruta base del API
   * @type {string}
   */
  private readonly API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;

  /**
   * @description Ruta del API para crear documentos
   * @type {string}
   */
  private readonly API_LINES_GET_BY_TIMELINE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_LINES_GET_BY_TIMELINE;

  //#endregion

  //#region Métodos de la interfaz
  async getByTimeline({ timeline }: { timeline: number }): Promise<OrderLine[]> {
    try {
      return await http.get(`${this.API_LINES_GET_BY_TIMELINE}timeline=${timeline}`);
    } catch (error) {
      throw new Error(`Error al obtener las líneas de pedido por timeline: ${error}`);
    }
  }

  //#endregion
}
