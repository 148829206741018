
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { ValidationObserver } from 'vee-validate/dist/types/components';
import { TYPES } from '@/core/config/Types';
import { PriceListUpdateVolume } from '@/settings/application/uses_cases/priceList/update/volume/PriceListUpdateVolume';
import { PriceListFindAllByStatusVolume } from '@/settings/application/uses_cases/priceList/search/volume/PriceListFindAllByStatusVolume';
import { PriceListFindAllVolume } from '@/settings/application/uses_cases/priceList/search/volume/PriceListFindAllVolume';
import { PriceListUpdateWeight } from '@/settings/application/uses_cases/priceList/update/weight/PriceListUpdateWeight';
import { PriceListFindAllWeight } from '@/settings/application/uses_cases/priceList/search/weight/PriceListFindAllWeight';
import { PriceListFindAllByStatusWeight } from '@/settings/application/uses_cases/priceList/search/weight/PriceListFindAllByStatusWeight';
import { RouteFindById } from '@/settings/application/uses_cases/routes/search/RouteFindById';
import { PriceListCreateVolume } from '@/settings/application/uses_cases/priceList/create/volume/PriceListCreateVolume';
import { PriceListCreateWeight } from '@/settings/application/uses_cases/priceList/create/weight/PriceListCreateWeight';
import { PalletsFactorFindAll } from '@/settings/application/uses_cases/palletsFactor/search/PalletsFactorFindAll';
import FilterCustomize from '@/core/components/shared/FilterCustomize.vue';
import i18n from '@/core/plugins/i18n';
import { TaxeFindByStatus } from '@/settings/application/uses_cases/taxe/search/TaxeFindByStatus';
import { VolumeFindAll } from '@/settings/application/uses_cases/volume/search/VolumeFindAll';
import { WeightFindAll } from '@/settings/application/uses_cases/weight/search/WeightFindAll';
import { FindCommodityTypeByStatus } from '@/freight/application/commodity_type/search/FindCommodityTypesByStatus';
import { EntityFindFilter } from '@/settings/application/uses_cases/entity/search/EntityFindFilter';
import { CreateCommodityType } from '@/freight/application/commodity_type/create/CreateCommodityType';
import { UpdateCommodityType } from '@/freight/application/commodity_type/update/UpdateCommodityType';
import { RoutesFindByStatus } from '@/settings/application/uses_cases/routes/search/RoutesFindByStatus';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import { PricingZoneFindByStatus } from '@/settings/application/uses_cases/PricingZone/search/PricingZoneFindByStatus';
import { PricingZone } from '@/settings/domain/PricingZone/PricingZone';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { PriceListImport } from '@/settings/application/uses_cases/priceList/create/volume/PriceListImportVolume';
import { DeleteCommodityType } from '@/freight/application/commodity_type/delete/DeleteCommodityType';
import EditDynamicElement from '@/settings/infrastructure/ui/components/entity/editDynamicRecords/EditRecordModal.vue';

@Component({
  name: 'PriceList',
  components: { FilterCustomize, CustomTableN, EditDynamicElement }
})
export default class PriceList extends Vue {
  @Inject(TYPES.UPDATE_PRICE_LIST_VOLUME)
  readonly priceListUpdateVolume!: PriceListUpdateVolume;
  @Inject(TYPES.FINDALL_PRICE_LIST_VOLUME)
  readonly priceListFindAllVolumecall!: PriceListFindAllVolume;
  @Inject(TYPES.FINDBYSTATUS_PRICE_LIST_VOLUME)
  readonly priceListFindAllByStatusVolumecall!: PriceListFindAllByStatusVolume;
  @Inject(TYPES.UPDATE_PRICE_LIST_WEIGHT)
  readonly priceListUpdateWeight!: PriceListUpdateWeight;
  @Inject(TYPES.FINDALL_PRICE_LIST_WEIGHT)
  readonly priceListFindAllWeightcall!: PriceListFindAllWeight;
  @Inject(TYPES.FINDBYSTATUS_PRICE_LIST_WEIGHT)
  readonly priceListFindAllByStatusWeightcall!: PriceListFindAllByStatusWeight;
  @Inject(TYPES.FINDBYSTATUS_ROUTES)
  readonly routesActive!: RoutesFindByStatus;
  @Inject(TYPES.FINDBYID_ROUTES)
  readonly routeFindByIdcall!: RouteFindById;
  @Inject(TYPES.CREATE_PRICE_LIST_VOLUME)
  readonly priceListCreateVolume!: PriceListCreateVolume;
  @Inject(TYPES.CREATE_PRICE_LIST_WEIGHT)
  readonly priceListCreateWeight!: PriceListCreateWeight;
  @Inject(TYPES.FINDBYSTATUS_TAXE)
  readonly taxeFindByStatus!: TaxeFindByStatus;
  @Inject(TYPES.FINDALL_PALLETSFACTOR)
  readonly PalletsFactorFindAll!: PalletsFactorFindAll;
  @Inject(TYPES.FINDALL_VOLUME) readonly volumeFindAll!: VolumeFindAll;
  @Inject(TYPES.FINDALL_WEIGHT) readonly weightFindAll!: WeightFindAll;
  @Inject(TYPES.COMMODITY_TYPE_FIND_BY_STATUS)
  readonly findCommodityByStatus!: FindCommodityTypeByStatus;
  @Inject(TYPES.ENTITY_FIND_BY_DESCRIPTION_LIKE)
  readonly findAllCustomersLike!: EntityFindFilter;
  @Inject(TYPES.COMMODITY_TYPE_SAVE)
  readonly saveCommodityType!: CreateCommodityType;
  @Inject(TYPES.COMMODITY_TYPE_UPDATE)
  readonly commodityUpdate!: UpdateCommodityType;
  @Inject(TYPES.COMMODITY_TYPE_DELETE)
  readonly commodityDelete!: DeleteCommodityType;
  @Inject(TYPES.PRICING_ZONE_FIND_BY_STATUS)
  readonly findActivePricingZones!: PricingZoneFindByStatus;
  @Inject(TYPES.IMPORT_PRICE_LIST)
  readonly importPriceList!: PriceListImport;

  //LISTAS
  pricingZonesList: PricingZone[] = [];

  //DATA
  file1: File | null = null;
  target = i18n.t('menu.Price Lists');
  isLoading = false;
  filterParams = false;
  fullPage = true;
  priceVolumeKey = 0;
  priceWeightKey = 0;
  customersList = [];
  commodityList = [] as any;
  priceListVolume = [];
  priceListWeight = [];
  typeTransportList = [];
  routes = [];
  taxes = [];
  totalRows = 1;
  isDisabled = false;
  currentPageVolume = 1;
  currentPageWeigth = 1;
  perPage = 10;
  filter = null;
  filterOn = [];
  sortBy = 'age';
  sortDesc = false;
  provinceFrom = [];
  provinceTo = [];
  zonesFrom = [];
  zonesTo = [];
  volumeList = [];
  weightList = [];
  reportedList = [];
  isBusyVolume = true;
  isBusyWeight = true;
  isSave = true;
  isTouched: boolean | undefined;
  listroutestogether: any = {};
  route: any = null;
  currentPage = 0;
  form: any = {
    priceListCountryFrom: [],
    priceListCountryTo: [],
    priceListStateFrom: [],
    priceListStateTo: [],
    priceListZoneFrom: [],
    priceListZoneTo: [],
    priceListUnitValue: '',
    priceListMinRate: '',
    priceListType: [],
    entity: '',
    commodity: [],
    slidingScaleFrom: 0,
    slidingScaleTo: 0,
    dataTypeTransport: null,
    modelVolume: [],
    modelWeight: [],
    modelReporteIn: null,
    priceListTaxes: [],
    id: '',
    palletsFactor: '',
    status: true,
    ide: 0,
    pricingZone: null
  };
  useScale = true;
  $refs!: {
    formWizardPriceList: HTMLFormElement;
    priceListUnitValue: HTMLInputElement;
    dataTypeTransport: HTMLFormElement;
    validationPriceList: InstanceType<typeof ValidationObserver>;
    mdCreatmdPriceList: HTMLFormElement;
  };
  //-- LIFE CYCLES
  created() {
    this.priceListFindAllVolume();
    this.priceListFindAllWeight();
    this.getAllCommodityActive();
    this.transportFindAll();
    this.volumeFindAllList();
    this.weightFindAllList();
    this.findPricelistZones();
  }

  mounted() {
    this.$nextTick(() => this.$refs.formWizardPriceList.activateAll());
  }

  tableActions = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: (row: any) => this.preparePriceListUpdate(row, 'update')
      }
    ]
  };

  //Computed
  get fields() {
    return [
      { field: 'id', label: 'Id' },
      { field: 'zone.name', label: this.translateCol('pricingZone') },
      { field: 'entity.fullName', label: this.translateCol('supplier') },
      {
        field: 'commodity',
        label: this.translateCol('commodity'),
        formatFn: (row: CommodityType) => this.formatCommodity(row)
      },
      {
        field: (row: any) => (row.slidingScaleFrom !== null ? `${row.slidingScaleFrom} - ${row.slidingScaleTo}` : ''),
        label: this.translateCol('slidingScale'),
        class: 'col-2'
      },
      { field: 'unitValue', label: this.translateCol('value') },
      { field: 'minRate', label: this.translateCol('rate') },
      { field: 'transportName', label: this.translateCol('typetransport') },
      { field: 'factor', label: this.translateCol('palletsFactor') },
      {
        field: 'active',
        label: this.translateCol('status'),
        sortable: true,
        formatFn: (value: boolean) => (value ? this.$t('general.yes') : this.$t('general.no'))
      },
      { field: 'actions', label: this.translateCol('actions'), sortable: false }
    ];
  }
  transportFindAll() {
    this.PalletsFactorFindAll.execute()
      .then((response: any) => {
        if ('code' in response && response.code == 404) {
          this.typeTransportList = [];
          return;
        }
        this.typeTransportList = response;
        this.$emit('response-transport', response);
      })
      .catch(error => {
        this.typeTransportList = [];
        throw new Error(error);
      });
  }
  volumeFindAllList() {
    this.volumeFindAll
      .execute()
      .then((response: any) => {
        this.volumeList = response;
      })
      .catch(error => {
        throw new Error(error);
      });
    return this.volumeList;
  }
  weightFindAllList() {
    this.weightFindAll
      .execute()
      .then((response: any) => {
        this.weightList = response;
        this.reportedList = response;
      })
      .catch(error => {
        throw new Error(error);
      });
    return this.weightList;
  }
  get rowsVolume() {
    return this.priceListVolume.length;
  }
  get rowsWeight() {
    return this.priceListWeight.length;
  }
  //Methods

  translateCol(colName: string) {
    return this.$i18n.t('general.' + colName);
  }
  onFiltered(filteredItems: any) {
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  }
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  priceListFindAllVolume() {
    this.isLoading = true;
    this.priceListFindAllVolumecall
      .execute()
      .then((response: any) => {
        this.priceListVolume = response.reverse();
        this.isBusyVolume = false;
        this.priceVolumeKey += 1;
      })
      .catch((error: any) => {
        this.priceVolumeKey += 1;
        this.isBusyVolume = false;
        this.priceListVolume = [];
        throw new Error(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
  priceListFindAllWeight() {
    this.isLoading = true;
    this.priceListFindAllWeightcall
      .execute()
      .then((response: any) => {
        this.priceListWeight = response.reverse();
        this.isBusyWeight = false;
        this.priceWeightKey += 1;
      })
      .catch(() => {
        this.priceWeightKey += 1;
        this.isBusyWeight = false;
        this.priceListWeight = [];
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
  priceListFindAllByStatusVolume(status: boolean) {
    if (status || !status) {
      this.priceListFindAllByStatusVolumecall
        .execute(status)
        .then((response: any) => {
          this.filterParams = true;
          this.priceListVolume = response;
          this.isBusyVolume = false;
        })
        .catch((error: any) => {
          this.filterParams = false;
          this.isBusyVolume = false;
          this.priceListVolume = [];
          throw new Error(error);
        });
    } else {
      this.filterParams = false;
      this.priceListFindAllVolume();
    }
  }
  priceListFindAllByStatusWeight(status: boolean) {
    if (status || !status) {
      this.priceListFindAllByStatusWeightcall
        .execute(status)
        .then((response: any) => {
          this.filterParams = true;
          this.priceListWeight = response;
          this.isBusyWeight = false;
        })
        .catch((error: any) => {
          this.filterParams = false;
          this.priceListWeight = [];
          throw new Error(error);
        });
    } else {
      this.filterParams = false;
      this.priceListFindAllWeight();
    }
  }
  preparePriceListUpdate(item: any, Mode: string) {
    if (Mode === 'update') {
      this.form.commodity = item.commodity;
      this.form.pricingZone = item.zone;
      this.form.entity = item.entity;
      this.form.slidingScaleFrom = item.slidingScaleFrom;
      this.form.slidingScaleTo = item.slidingScaleTo;
      this.useScale = item.slidingScaleTo == 0 && item.slidingScaleFrom == 0 ? false : true;
      this.passingRouteId(item);
      this.priceListVolumenesByRow(item);
      this.$bvModal.show('mdPriceList');
    } else {
      this.setListTypeSave();
    }
    this.routesFindAll();
    this.getAllCommodityActive();
    this.taxesFindAll();
    this.transportFindAll();
  }

  setListTypeUpdate() {
    if (this.$refs.formWizardPriceList.isLastStep) {
      this.form.priceListType = { description: 'weight', code: 'weight' };
    } else {
      this.form.priceListType = { description: 'volume', code: 'volume' };
    }
  }

  setListTypeSave() {
    if (this.$refs.formWizardPriceList.isLastStep) {
      this.clearForm();
      this.form.priceListType = { description: 'weight', code: 'weight' };
      this.isSave = true;
    } else {
      this.clearForm();
      this.form.priceListType = { description: 'volume', code: 'volume' };
      this.isSave = true;
    }
  }
  //aqui
  passingRouteId(item: any) {
    this.route = { id: item.routeId, description: item.routeDescription || '' };
    this.listroutestogether = item;
  }
  routesFindAll() {
    this.routesActive
      .execute(true)
      .then((response: any) => {
        this.routes = response;
      })
      .catch(error => {
        throw new Error(error);
      });
  }
  taxesFindAll() {
    this.taxeFindByStatus
      .execute(true)
      .then((response: any) => {
        this.taxes = response;
      })
      .catch(error => {
        throw new Error(error);
      });
  }
  routeFindById(route: any) {
    this.routeFindByIdcall
      .execute(route.id)
      .then((response: any) => {
        this.route = response;
        this.listroutestogether = response;
        this.listroutestogether.routeId = this.listroutestogether.id;
        this.$refs.priceListUnitValue.focus();
      })
      .catch(error => {
        throw new Error(error);
      });
  }
  //evento de actualizacion o insert
  handleSubmit(e: any) {
    e.preventDefault();

    //aqui
    const payload = {
      routeId: parseFloat(this.listroutestogether.routeId),
      countryDestination: this.listroutestogether.countryDestination,
      countryOrigin: this.listroutestogether.countryOrigin,
      stateDestination: this.listroutestogether.stateDestination,
      stateOrigin: this.listroutestogether.stateOrigin,
      minRate: parseFloat(this.form.priceListMinRate),
      transportId: this.form.dataTypeTransport.code,
      transportName: this.form.dataTypeTransport.description,
      factor: this.form.priceListType.code !== 'weight' ? this.form.palletsFactor : 0,
      weightUnit: this.form.modelWeight.id,
      volumeUnit: this.form.modelVolume.id,
      range: '0',
      type: 'U',
      unitValue: parseFloat(this.form.priceListUnitValue),
      id: this.form.id || 0,
      taxes: this.form.priceListTaxes,
      active: this.form.status,
      zoneZcOrigin: this.listroutestogether.zoneZcOrigin,
      zoneZcDestination: this.listroutestogether.zoneZcDestination,
      reportedIn: this.form.modelReporteIn.code,
      entity: this.form.entity,
      zone: this.form.pricingZone,
      commodity: this.form.commodity,
      slidingScaleFrom: this.useScale ? this.form.slidingScaleFrom : 0,
      slidingScaleTo: this.useScale ? this.form.slidingScaleTo : 0,
      useScale: this.useScale
    };
    const arrayTaxes = [];
    for (const taxesData of this.form.priceListTaxes) {
      arrayTaxes.push({
        id: taxesData.id,
        taxCode: taxesData.code,
        taxName: taxesData.description,
        taxPercentage: taxesData.percentage
      });
    }
    payload.taxes = arrayTaxes;
    const noConfig =
      this.form.priceListType.code != 'weight'
        ? this.priceListVolume.filter((item: any) => {
            return (
              item.entity.id === this.form.entity.id &&
              item.commodity.id === this.form.commodity.id &&
              item.routeId === this.route.routeId
            );
          })
        : this.priceListWeight.filter((item: any) => {
            return (
              item.entity != null &&
              item.entity.id === this.form.entity.id &&
              item.commodity.id === this.form.commodity.id &&
              item.routeId === this.route.routeId
            );
          });

    const findNoScale =
      noConfig.filter((item: any) => {
        return item.slidingScaleFrom === 0 && item.slidingScaleTo === 0;
      }).length > 0;

    if (
      (this.useScale && !findNoScale && noConfig.length > 0) ||
      (!this.useScale && findNoScale && noConfig.length > 0) ||
      noConfig.length < 1
    ) {
      if (!this.isSave) {
        if (this.form.priceListType.code === 'weight') {
          //funcion que actualiza lista de precion weigth
          this.PriceListUpdateWeight(payload);
        } else if (this.form.priceListType.code === 'volume') {
          // funcion que actualiza lista de precios volume
          this.PriceListUpdateVolume(payload);
        }
      } else {
        if (this.form.priceListType.code === 'weight') {
          //save de lista de precios weight
          this.PriceListCreateWeight(payload);
        } else if (this.form.priceListType.code === 'volume') {
          // save de lista de precios volume
          this.PriceListCreateVolume(payload);
        }
      }
    } else {
      if (!this.useScale) {
        this.$swal(
          `${this.$t('general.scaleRegisterN')}`,
          `${this.$t('general.scaleRegisterNDescription')}`,
          'warning'
        );
      } else {
        this.$swal(`${this.$t('general.scaleRegister')}`, `${this.$t('general.scaleRegisterDescription')}`, 'warning');
      }
      return;
    }
  }

  //update services for price list
  PriceListUpdateWeight(payload: any) {
    this.isLoading = true;
    this.priceListUpdateWeight
      .execute(payload)
      .then((response: any) => {
        this.isLoading = false;
        this.priceListFindAllWeight();
        this.clearForm();
        return response;
      })
      .catch((error: any) => {
        this.isLoading = false;
        throw new Error(error);
      });
  }
  PriceListUpdateVolume(payload: any) {
    this.isLoading = true;
    this.priceListUpdateVolume
      .execute(payload)
      .then((response: any) => {
        this.isLoading = false;
        this.priceListFindAllVolume();
        this.clearForm();
        return response;
      })
      .catch((error: any) => {
        this.isLoading = false;
        throw new Error(error);
      });
  }

  //create services for price list
  PriceListCreateWeight(payload: any) {
    this.isLoading = true;
    this.priceListCreateWeight
      .execute(payload)
      .then((response: any) => {
        this.isLoading = false;
        this.priceListFindAllWeight();
        this.clearForm();
        return response;
      })
      .catch((error: any) => {
        this.isLoading = false;
        throw new Error(error);
      });
  }
  PriceListCreateVolume(payload: any) {
    this.isLoading = true;
    this.priceListCreateVolume
      .execute(payload)
      .then((response: any) => {
        this.isLoading = false;
        this.priceListFindAllVolume();
        this.clearForm();
        return response;
      })
      .catch((error: any) => {
        this.isLoading = false;
        throw new Error(error);
      });
  }

  priceListVolumenesByRow(item: any) {
    this.form.priceListUnitValue = item.unitValue;
    this.form.dataTypeTransport = { code: item.transportId, description: item.transportName };
    this.form.palletsFactor = item.factor;
    this.form.priceListMinRate = item.minRate;
    this.form.priceListCountryTo = { name: item.countryDestinationName, code: item.countryDestination };
    this.form.priceListCountryFrom = { name: item.countryOriginName, code: item.countryOrigin };
    this.form.priceListStateTo = { name: item.stateDestinationName, code: item.stateDestination };
    this.form.priceListStateFrom = { name: item.stateOriginName, code: item.stateOrigin };
    this.form.priceListZoneTo = {
      zoneZc: item.zoneZcDestination,
      description: item.zoneZcDestinationDescription
    };
    this.form.priceListZoneFrom = { zoneZc: item.zoneZcOrigin, description: item.zoneZcOriginDescription };
    this.form.id = item.id;
    this.form.modelWeight = {
      id: item.weightUnit,
      code: item.weightUnitCode,
      description: item.weightUnitDescription
    };
    this.form.modelVolume = {
      id: item.volumeUnit,
      code: item.volumeUnitCode,
      description: item.volumeUnitDescription
    };

    this.form.modelReporteIn = this.reportedList.filter((reported: any) => reported.code == item.reportedIn);

    this.form.priceListTaxes = [];
    this.form.status = item.active;
    for (const volumeTax of item.taxes) {
      this.form.priceListTaxes.push({
        id: volumeTax.id,
        code: volumeTax.taxCode,
        description: volumeTax.taxName,
        percentage: volumeTax.taxPercentage
      });
    }
    this.isSave = false;
    this.setListTypeUpdate();
  }
  taxesWithRate({
    description,
    percentage,
    isPercentage
  }: {
    description: string;
    percentage: string;
    isPercentage: boolean;
  }) {
    if (isPercentage) {
      return `${description} — [${percentage}%]`;
    }

    return `${description} — [${percentage}]`;
  }
  onTouch() {
    this.isTouched = true;
  }

  clearForm() {
    this.listroutestogether = {};
    this.route = {};
    this.form = {
      priceListCountryFrom: [],
      priceListCountryTo: [],
      priceListStateFrom: [],
      priceListStateTo: [],
      priceListZoneFrom: [],
      priceListZoneTo: [],
      priceListUnitValue: '',
      priceListMinRate: '',
      priceListType: [],
      priceListTaxes: [],
      dataTypeTransport: [],
      modelVolume: [],
      modelWeight: [],
      modelReporteIn: [],
      palletsFactor: '',
      status: true
    };
    this.provinceFrom = [];
    this.provinceTo = [];
    this.zonesFrom = [];
    this.zonesTo = [];
    this.$nextTick(() => this.$refs.validationPriceList.reset());
    this.$bvModal.hide('mdPriceList');
  }

  formatNameOrigin(objTerritory: any) {
    return `${objTerritory.countryOriginName} - ${objTerritory.stateOriginName} - ${objTerritory.cityOriginName} - ${objTerritory.zoneZcOrigin}`;
  }
  formatNameDestination(objTerritory: any) {
    return `${objTerritory.countryDestinationName} - ${objTerritory.stateDestinationName} - ${objTerritory.cityDestinationName} - ${objTerritory.zoneZcDestination}`;
  }

  formatCommodity(commodity: CommodityType) {
    return `${commodity.id} - ${commodity.description}`;
  }

  searchCustomers(query: string) {
    if (query.length >= 3) {
      const params: any = {
        text: query,
        type: ['SUPPLIER']
      };
      this.isLoading = true;
      this.findAllCustomersLike
        .execute(params)
        .then((response: any) => {
          this.customersList = response;
          this.isLoading = false;
        })
        .catch((error: any) => {
          this.isLoading = false;
          throw new Error(`${error}`);
        });
    }
  }

  getAllCommodityActive(asignateCommodity?: any) {
    this.isLoading = true;
    this.findCommodityByStatus
      .execute(true)
      .then((response: any) => {
        this.isLoading = false;
        this.commodityList = response;
        if (typeof asignateCommodity !== 'undefined') {
          this.form.commodity = this.commodityList.filter((item: any) => item.description === asignateCommodity)[0];
        }
      })
      .catch(error => {
        this.commodityList = [];
        this.isLoading = false;
        throw new Error(`${error}`);
      });
  }

  async addTagCommodity(newTag: string) {
    try {
      this.form.commodity = { id: 0, description: newTag, active: true };
      this.isLoading = true;
      await this.saveCommodityType.execute(this.form.commodity);
    } catch (error) {
      this.isLoading = false;
      this.form.commodity = '';
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
      this.getAllCommodityActive(newTag);
    }
  }

  manageSlidingScale() {
    if (this.form.slidingScaleTo < this.form.slidingScaleFrom) {
      this.form.slidingScaleTo = parseFloat(this.form.slidingScaleFrom);
    }
  }

  async deleteCommodity(commodity: any) {
    try {
      this.isLoading = true;
      commodity.active = false;
      const response: any = await this.commodityDelete.execute(commodity);
      this.isLoading = false;

      if (response.ok) {
        this.form.commodity = '';
      }
    } catch (error) {
      this.isLoading = false;
      this.form.commodity = '';
      throw new Error(`${error}`);
    } finally {
      this.getAllCommodityActive();
    }
  }

  async findPricelistZones() {
    this.isLoading = true;
    const res = await this.findActivePricingZones.execute(true);
    this.pricingZonesList = res.length > 0 ? res : [];
    this.isLoading = false;
  }

  async updateCommodity(data: CommodityType) {
    try {
      await this.commodityUpdate.execute(data);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.getAllCommodityActive();
    }
  }

  generatePDF() {
    this.isLoading = true;
    const doc = new jsPDF('portrait');
    doc.setFontSize(20);
    doc.text('Como importar precios a la aplicación', doc.internal.pageSize.getWidth() / 2, 20, { align: 'center' });

    doc.setFontSize(12);
    doc.text('Paso a paso', doc.internal.pageSize.getWidth() / 2, 30, { align: 'center' });

    doc.setFontSize(12);
    doc.text('1. Asegúrate que la tabla que vas a importar tenga los siguientes campos:', 20, 45, { align: 'left' });
    doc.setFontSize(10);
    doc.text('- Zona: Tipo operación, zona.', 25, 55, { align: 'left' });
    doc.text('- Origen: País, estado/prov, ciudad.', 25, 60, { align: 'left' });
    doc.text('- Destino: País, estado/prov, ciudad.', 25, 65, { align: 'left' });
    doc.text('- Proveedor.', 25, 70, { align: 'left' });
    doc.text('- Escala variable: Mínimo, máximo, unidad peso.', 25, 75, { align: 'left' });
    doc.text('- Commodity.', 25, 80, { align: 'left' });
    doc.text('- Tarifas/precios: Estándar, mínimo.', 25, 85, { align: 'left' });
    doc.text('- Parámetros adicionales: Modalidad, factor conversión, und volúmen, unidad a reportar.', 25, 90, {
      align: 'left'
    });
    doc.setFontSize(12);
    doc.text('2. Tu tabla deberá verse de la siguiente manera', 20, 100);
    autoTable(doc, {
      head: [
        [
          { content: 'Zona', colSpan: 2, styles: { halign: 'center', fillColor: [22, 160, 133] } },
          { content: 'Origen', colSpan: 3, styles: { halign: 'center', fillColor: [22, 160, 133] } },
          { content: 'Destino', colSpan: 3, styles: { halign: 'center', fillColor: [22, 160, 133] } },
          { content: 'Proveedor', colSpan: 1, styles: { halign: 'center', fillColor: [22, 160, 133] } },
          { content: 'Escala Variable', colSpan: 3, styles: { halign: 'center', fillColor: [22, 160, 133] } },
          { content: 'Commodity', colSpan: 1, styles: { halign: 'center', fillColor: [22, 160, 133] } },
          { content: 'Tarifas/precios', colSpan: 2, styles: { halign: 'center', fillColor: [22, 160, 133] } },
          { content: 'Parametros adicionales', colSpan: 4, styles: { halign: 'center', fillColor: [22, 160, 133] } }
        ],
        [
          'Tipo operacion',
          'Zona',
          'Pais',
          'Estado/prov',
          'Ciudad',
          'Pais',
          'Estado/prov',
          'Ciudad',
          'Proveedor',
          'Minimo',
          'Maximo',
          'Unidad peso',
          'Commodity',
          'Estandar',
          'Minimo',
          'Modalidad',
          'Factor conversion',
          'Und Volumen',
          'Unidad a reportar'
        ]
      ],
      body: [
        ['EXP', 1, 'PA', '', '', 'US', '', '', 123456, 0, 0.5, 'LBS', 'C001', 3, 2.8, 'AIR', 5000, 'CENT.', 'LBS']
      ],
      margin: { top: 105, left: 10, right: 10 },
      styles: {
        cellWidth: 'wrap',
        overflow: 'linebreak'
      },
      pageBreak: 'auto',
      tableWidth: 200,
      headStyles: { textColor: 'black', fontSize: 4.5 },
      columnStyles: {
        0: { cellWidth: 10 },
        1: { cellWidth: 10 },
        2: { cellWidth: 10 },
        3: { cellWidth: 10 },
        4: { cellWidth: 10 },
        5: { cellWidth: 10 },
        6: { cellWidth: 10 },
        7: { cellWidth: 10 },
        8: { cellWidth: 10 },
        9: { cellWidth: 10 },
        10: { cellWidth: 10 },
        11: { cellWidth: 10 },
        12: { cellWidth: 10 },
        13: { cellWidth: 10 },
        14: { cellWidth: 10 },
        15: { cellWidth: 10 },
        16: { cellWidth: 10 },
        17: { cellWidth: 10 },
        18: { cellWidth: 10 }
      },
      bodyStyles: { fontSize: 4.5 }
    });
    doc.setFontSize(12);
    doc.text('3. Tipo de datos de cada columna', 20, 135);
    doc.setFontSize(10);
    doc.text('- Tipo operacion: Texto, contiene el nombre o codigo del tipo de operacion', 25, 140);
    doc.text('- Zona: Numero, contiene el codigo numerico de la zona', 25, 145);
    doc.text('- Pais: Texto, contiene el codigo ISO 3166/1 del estado', 25, 150);
    doc.text('- Estado/prov: Texto, contiene el codigo ISO 3166/1 del estado', 25, 155);
    doc.text('- Ciudad: Texto, contiene el codigo ISO 3166/1 del estado', 25, 160);
    doc.text('- Pais: Texto, contiene el codigo ISO 3166/1 del estado', 25, 165);
    doc.text('- Estado/prov: Texto, contiene el codigo ISO 3166/1 del estado', 25, 170);
    doc.text('- Ciudad: Texto, contiene el codigo ISO 3166/1 del estado', 25, 175);
    doc.text('- Proveedor: Numero, debe ir el codigo del proveedor, no el nombre', 25, 180);
    doc.text('- Minimo: Numero, contiene el valor minimo de la escala', 25, 185);
    doc.text('- Maximo: Numero, contiene el valor maximo de la escala', 25, 190);
    doc.text('- Unidad peso: Texto, contiene el codigo del la unidad de peso, EJ: KG, LB, etc', 25, 195);
    doc.text('- Commodity: Alfanumerico, contiene el codigo de la referencia del producto, no use el nombre', 25, 200);
    doc.text('- Estandar: Numero, valor estandar', 25, 205);
    doc.text('- Minimo: Numero, valor minimo', 25, 210);
    doc.text('- Modalidad: Texto, contiene la descripcion de la modalidad, EJ: AIR, OCEAN, etc.', 25, 215);
    doc.text('- Factor conversion: Numero, contiene el valor del factor de conversion', 25, 220);
    doc.text('- Und Volumen: Texto, contiene la descripcion de la unidad de volumen', 25, 225);
    doc.text('- Unidad a reportar: Texto, contiene el codigo de la unidad de peso.', 25, 230);

    doc.setFontSize(12);
    doc.text(
      '4. Ahora en la aplicación, en la ventana de pricing, veras una sección de carga de información, ',
      20,
      240
    );
    doc.text('selecciona la tabla que cumpla con los requisitos mencionados anteriormente y da click ', 20, 245);
    doc.text('en el botón "Guardar"', 20, 250);
    doc.text('5. Listo, has generado tu cargue de información siempre y cuando la tabla cumpla', 20, 260);
    doc.text('con los requisitos', 20, 265);

    doc.save('Tutorial.pdf');
    this.isLoading = false;
  }

  parseCSV(file: File) {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      // eslint-disable-next-line no-console
      console.log(reader.result);
    };
  }

  async importCSV() {
    try {
      this.isLoading = true;

      const formData = new FormData();

      if (!this.file1) {
        this.$swal('Error', 'Debe seleccionar un archivo', 'error');
        return;
      }

      formData.append('file', this.file1);

      const res = await this.importPriceList.execute({ formData });

      if (res) {
        this.$swal('Importación exitosa', 'Se ha importado la lista de precios correctamente', 'success');
        this.priceListFindAllVolume();
        this.priceListFindAllWeight();
      }

      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      throw new Error(`${e}`);
    }
  }
}
