export class ActivityTransactionalForTraceability {
  eventId: number;
  preRegisterNumber: number;
  preRegisterType: string;
  activityId: number;
  sequence: number;
  sw: string;
  description: string;
  executed: boolean;
  sendNotification: boolean;
  keyModal: string;

  constructor({
    eventId = 0,
    preRegisterNumber = 0,
    preRegisterType = '',
    activityId = 0,
    sequence = 0,
    sw = '',
    description = '',
    executed = false,
    sendNotification = false,
    keyModal = ''
  }: {
    eventId?: number;
    preRegisterNumber?: number;
    preRegisterType?: string;
    activityId?: number;
    sequence?: number;
    sw?: string;
    description?: string;
    executed?: boolean;
    sendNotification?: boolean;
    keyModal?: string;
  }) {
    this.eventId = eventId;
    this.preRegisterNumber = preRegisterNumber;
    this.preRegisterType = preRegisterType;
    this.activityId = activityId;
    this.sequence = sequence;
    this.sw = sw;
    this.description = description;
    this.executed = executed;
    this.sendNotification = sendNotification;
    this.keyModal = keyModal;
  }
}
