import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { TermsPaymentRepository } from '@/settings/domain/ParmsGeneral/termsPayment/TermsPaymentRepository';
import { TermsPayment } from '@/settings/domain/ParmsGeneral/termsPayment/TermsPayment';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class TermsPaymentService implements TermsPaymentRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_TERMSPAYMENT_FINDALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_TERMSPAYMENT_FINDALL;
  private readonly API_TERMSPAYMENT_SAVE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_TERMSPAYMENT_SAVE;
  private readonly API_TERMSPAYMENT_UPDATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_TERMSPAYMENT_UPDATE;
  private readonly API_TERMSPAYMENT_DELETE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_TERMSPAYMENT_DELETE;

  async create(termsPayment: TermsPayment) {
    try {
      await http.post<TermsPayment[]>(this.API_TERMSPAYMENT_SAVE, JSON.stringify(termsPayment));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(termsPayment: TermsPayment) {
    try {
      await http.put<TermsPayment[]>(this.API_TERMSPAYMENT_UPDATE, JSON.stringify(termsPayment));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async delete(termsPayment: TermsPayment) {
    try {
      await http.singleDelete<TermsPayment[]>(this.API_TERMSPAYMENT_DELETE + `/${termsPayment.id}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAll(): Promise<TermsPayment[]> {
    try {
      return await http.get<TermsPayment[]>(this.API_TERMSPAYMENT_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
