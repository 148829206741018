
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'TimelineComponentWms'
})
export default class AppTimelineComponentWms extends Vue {
  //Data
  hover = false;
  //Prop
  @Prop({
    type: Array,
    required: true
  })
  private readonly items!: [];
  //computed property
  get hourTotal() {
    let sumaTotal = 0;
    const hour = 24;
    for (const item of this.items) {
      sumaTotal += item['day'];
    }
    sumaTotal = sumaTotal / hour;
    return sumaTotal;
  }
}
