import { Logger } from '@/core/helper/Logger';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';

@Injectable()
export class ConsoleLogger implements Logger {
  constructor(@Inject(TYPES.WINDOW) private readonly window: any) {}

  object<T>(object: T): void {
    this.window.console.dir(object);
  }

  groupEnd(): void {
    this.window.console.groupEnd();
  }

  info(message: string): void {
    this.window.console.info(message);
  }

  group(label: string): void {
    this.window.console.group(label);
  }

  log(message: string): void {
    this.window.console.log(message);
  }
}
