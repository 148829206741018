
import { TYPES } from '@/core/config/Types';
import { FindAllPackingUnits } from '@/settings/application/uses_cases/packing_units/search/FindAllPackingUnits';
import { PackingUnits } from '@/settings/domain/packing_units/PackingUnits';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'inversify-props';
import AddPackingUnits from './AddPackingUnits.vue';
import CustomTable from '@/core/components/shared/CustomTable.vue';
import { CreatePackingUnits } from '@/settings/application/uses_cases/packing_units/create/CreatePackingUnit';
import { UpdatePackingUnits } from '@/settings/application/uses_cases/packing_units/update/UpdatePackingUnits';
import { DeletePackingUnits } from '@/settings/application/uses_cases/packing_units/delete/DeletePackingUnits';
@Component({
  name: 'PackingUnitsCenter',
  components: {
    AddPackingUnits,
    CustomTable
  }
})
export default class PackingUnitsCenter extends Vue {
  @Inject(TYPES.FIND_ALL_PACKING_UNITS)
  readonly getAllPackingUnits!: FindAllPackingUnits;
  @Inject(TYPES.CREATE_PACKING_UNITS)
  readonly savePackingUnits!: CreatePackingUnits;
  @Inject(TYPES.UPDATE_PACKING_UNITS)
  readonly updatePackingUnit!: UpdatePackingUnits;
  @Inject(TYPES.DELETE_PACKINGUNIT_REFERENCE)
  readonly deletePackingUnit!: DeletePackingUnits;
  //DATA
  isLoading = false;
  packingUnits: PackingUnits[] = [];
  //FORM
  form: PackingUnits = new PackingUnits();
  //BUTTON
  buttonOptions = {
    variant: 'success',
    buttonTitle: 'general.add',
    icon: 'fa-plus',
    isEdit: false
  };
  //OBJECTS FUNCTIONS
  formFunctions = {
    clearForm: this.clearForm,
    factory: this.factory
  };

  actions = {
    edit: this.findPackingUnit,
    delete: this.delete
  };

  //COMPONENT FUNCTIONS
  created() {
    this.findAllPackingUnits();
  }
  //GETTERS
  get fields() {
    return [
      {
        label: this.$t('general.id'),
        key: 'id'
      },
      {
        label: this.$t('general.description'),
        key: 'description'
      },
      {
        label: this.$t('general.height'),
        key: 'height'
      },
      {
        label: this.$t('general.width'),
        key: 'width'
      },
      {
        label: this.$t('general.length'),
        key: 'length'
      },
      {
        label: this.$t('general.stackingQuantity'),
        key: 'stackingQuantity'
      },
      {
        label: this.$t('general.level'),
        key: 'level'
      },
      {
        label: this.$t('general.status'),
        key: 'active'
      },
      {
        label: this.$t('general.actions'),
        key: 'actions'
      }
    ];
  }
  //FUNCTIONS
  clearForm() {
    this.form = new PackingUnits();
    this.buttonOptions = {
      variant: 'success',
      buttonTitle: 'general.add',
      icon: 'fa-plus',
      isEdit: false
    };
  }

  factory() {
    !this.buttonOptions.isEdit ? this.create() : this.edit();
  }

  findPackingUnit(data: PackingUnits) {
    this.buttonOptions = {
      variant: 'secondary',
      buttonTitle: 'general.edit',
      icon: 'fa-save',
      isEdit: true
    };
    this.form = data;
  }

  //API REQUESTS
  create() {
    this.isLoading = true;
    this.savePackingUnits
      .execute(this.form)
      .then(async () => {
        await this.findAllPackingUnits();
        this.clearForm();
        this.isLoading = false;
      })
      .catch(err => {
        this.isLoading = false;
        throw new Error(`${err}`);
      });
  }

  edit() {
    this.isLoading = true;
    this.updatePackingUnit
      .execute(this.form)
      .then(async (response: any) => {
        if ('status' in response && response.status == 200) {
          await this.findAllPackingUnits();
          this.clearForm();
        }
        this.isLoading = false;
      })
      .catch(err => {
        this.isLoading = false;
        throw new Error(`${err}`);
      });
  }

  async delete(packagingUnit: PackingUnits) {
    this.isLoading = true;
    try {
      await this.deletePackingUnit.execute(packagingUnit);
      this.isLoading = false;
      await this.findAllPackingUnits();
    } catch (error) {
      this.isLoading = false;
    }
  }

  async findAllPackingUnits() {
    this.isLoading = true;
    await this.getAllPackingUnits
      .execute()
      .then((response: PackingUnits[]) => {
        if (response) {
          this.packingUnits = response.reverse();
        }
        this.isLoading = false;
      })
      .catch(err => {
        this.isLoading = false;
        throw new Error(`${err}`);
      });
  }
}
