import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { DashboardChartData } from '@/settings/domain/dashboardChartData/dashboardChartData';

@Injectable()
export class DashboardChartDataService {
  API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;
  API_FIND_ALL_DASHBOARD_CHART_DATA =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_DASHBOARD_GET_DATA;

  async findAll(): Promise<DashboardChartData> {
    try {
      return await http.get<DashboardChartData>(this.API_FIND_ALL_DASHBOARD_CHART_DATA);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
