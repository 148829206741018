import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { TypeAddress } from '@/settings/domain/ParmsGeneral/typeAddress/TypeAddress';
import { TypeAddressRepository } from '@/settings/domain/ParmsGeneral/typeAddress/TypeAddressRepository';

@Injectable()
export class TypeAddressFindAll extends Query<Promise<TypeAddress[]>> {
  public constructor(
    @Inject(TYPES.TYPE_ADDRESS_REPOSITORY)
    private readonly repository: TypeAddressRepository,

    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(): Promise<TypeAddress[]> {
    const dataTypeAddress = await this.repository.findAll();
    this.stateManager.patch({ dataTypeAddress });
    return this.stateManager.state.dataTypeAddress;
  }
}
