import { BaseLink } from './BaseLink';
import { TYPES } from '@/core/config/Types';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Logger } from '@/core/helper/Logger';

@Injectable()
export class LoggerLink extends BaseLink {
  constructor(@Inject(TYPES.LOGGER) private readonly logger: Logger) {
    super();
  }

  next(): void {
    /*    this.logger.group(context.useCase.constructor.name);
    this.logger.group('Parameters');
    this.logger.log(`${context.param ?? '-'}`);
    this.logger.groupEnd();
    this.logger.group('Result');
    this.logger.object(context.result ?? '-');
    this.logger.groupEnd();
    this.logger.groupEnd();
    this.nextLink.next(context);*/
  }
}
