import { Injectable } from '@/core/di/Injectable';
import { Query } from '@/core/cqrs/Query';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { AirportRepository } from '@/settings/domain/airport/AirportRepository';
import Airport from '@/settings/domain/airport/Airport';

@Injectable()
export class FindAllAirport extends Query<Promise<Airport[]>> {
  public constructor(
    @Inject(TYPES.AIRPORT_REPOSITORY)
    private readonly airportRepository: AirportRepository
  ) {
    super();
  }

  async internalExecute(): Promise<Airport[]> {
    return await this.airportRepository.findAll();
  }
}
