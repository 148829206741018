import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { Bulk } from '@/wms/domain/bulk/Bulk';
import { BulkRepository } from '@/wms/domain/bulk/BulkRepository';
import { Store } from '@/wms/domain/layout/store/Store';

@Injectable()
export class FindPickedBulksByStoreAndDate extends Query<
  Promise<Bulk[]>,
  {
    store?: Store | null | undefined;
    dateFrom: string;
    dateTo: string;
    origin: string;
    destination: string;
  }
> {
  public constructor(
    @Inject(TYPES.BULK_REPOSITORY)
    private readonly repository: BulkRepository
  ) {
    super();
  }

  async internalExecute(filters: {
    store?: Store | null | undefined;
    dateFrom: string;
    dateTo: string;
    origin: string;
    destination: string;
  }): Promise<Bulk[]> {
    try {
      return await this.repository.findPickedBulksByStoreAndDate(filters);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
