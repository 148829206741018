import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Command } from '@/core/cqrs/Command';
import { Timeline } from '@/tracking/domain/timeline/Timeline';
import { TimelineRepository } from '@/tracking/domain/timeline/TimelineRepository';

@Injectable()
export class CreateTimeline extends Command<Promise<any>, Timeline> {
  public constructor(
    @Inject(TYPES.STATE_MANAGER) private readonly stateManager: StateManager,
    @Inject(TYPES.TIMELINE_REPOSITORY)
    private readonly timelineRepository: TimelineRepository
  ) {
    super();
  }
  internalExecute(timeline: Timeline): Promise<any> {
    return this.timelineRepository.create(timeline);
  }
}
