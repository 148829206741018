export class CloudConfig {
  typeIntegration: string | null;
  type: string;
  projectId: string;
  privateKeyId: string;
  privateKey: string;
  key: string;
  clientEmail: string;
  clientId: string;
  authUri: string;
  tokenUri: string;
  authProviderX509CertUrl: string;
  clientX509CertUrl: string;
  company: number;

  public constructor() {
    this.typeIntegration = '';
    this.type = '';
    this.projectId = '';
    this.privateKeyId = '';
    this.privateKey = '';
    this.key = '';
    this.clientEmail = '';
    this.clientId = '';
    this.authUri = '';
    this.tokenUri = '';
    this.authProviderX509CertUrl = '';
    this.clientX509CertUrl = '';
    this.company = 0;
  }
}
