import { ActivityTransactional } from '../activitiesTransactional/ActivityTransactional';
import { TimelineTransactional } from '../timelineTransactional/TimelineTransactional';
import { Operation } from '../typeoperation/Operation';

export class EventTransactional {
  id: number;
  eventReference: number;
  sequence: number;
  description: string;
  finishDate: number;
  ready: boolean;
  timeline: TimelineTransactional | null;
  typeOperation: Operation | null;
  handleSpreadAutomatically: boolean;
  active: boolean;
  day: number;
  createAt: string;
  updateAt: string;
  activities: ActivityTransactional[];
  sendNotification: boolean;
  isSequential: boolean;
  startDate: string;
  ffinishDate: string;
  state: string;

  public constructor() {
    this.id = 0;
    this.eventReference = 0;
    this.sequence = 0;
    this.description = '';
    this.finishDate = 0;
    this.ready = false;
    this.timeline = null;
    this.typeOperation = null;
    this.handleSpreadAutomatically = false;
    this.active = true;
    this.day = 0;
    this.createAt = '';
    this.updateAt = '';
    this.activities = [];
    this.sendNotification = false;
    this.isSequential = true;
    this.startDate = '';
    this.ffinishDate = '';
    this.state = '';
  }
}
