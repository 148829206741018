
import Component from 'vue-class-component';
import Vue from 'vue';
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { showAlert } from '@/core/plugins/Notifications';
import i18n from '@/core/plugins/i18n';
import AddRole from '@/settings/infrastructure/ui/components/role/AddRole.vue';
import { CreateRole } from '@/settings/application/uses_cases/role/create/CreateRole';
import { UpdateRole } from '@/settings/application/uses_cases/role/update/UpdateRole';
import { RoleFindById } from '@/settings/application/uses_cases/role/search/RoleFindById';
import { RoleFindMenuPlaneByRoleId } from '@/settings/application/uses_cases/role/search/RoleFindMenuPlaneByRoleId';
import { RoleFindAll } from '@/settings/application/uses_cases/role/search/RoleFindAll';

@Component({
  name: 'Role',
  components: {
    AddRole
  }
})
export default class AppRole extends Vue {
  @Inject(TYPES.FINDBYMENU_ROLE) readonly roleFindById!: RoleFindById;
  @Inject(TYPES.FINDMENUPLANEBYROLEID_ROLE) readonly roleFindMenuPlaneByRoleId!: RoleFindMenuPlaneByRoleId;
  @Inject(TYPES.CREATE_ROLE) readonly createRole!: CreateRole;
  @Inject(TYPES.UPDATE_ROLE) readonly updateRole!: UpdateRole;
  @Inject(TYPES.FINDALL_ROLE) readonly roleFindAll!: RoleFindAll;

  //Data
  target = i18n.t('menu.Role');
  isLoading = false;
  fullPage = true;
  menuList = [];
  roleList = [];
  booleanAdd = false;
  childrenMenu = [] as any;
  menuChildren = [] as any;
  addRoleName = '';
  idUpdate = '';
  nameUpdate = '';
  selectDeselectAll = [] as any;
  //Hook
  created() {
    this.$nextTick(() => this.getMenuByRol(2));
    this.$nextTick(() => this.allRole);
  }
  //Computed
  get getMenuList() {
    //return this.menuList.filter((item: any) => item.code.length > 0 && item.code.length <= 3);
    return this.menuList;
  }
  //Methods
  toggleAll(data: any) {
    if (this.selectDeselectAll.length <= 0) {
      let menu: any;
      this.menuChildren = [];
      for (menu of this.menuList) {
        if (data.code === menu.code.substring(0, 3)) this.menuChildren.push(menu);
      }
      this.childrenMenu = this.childrenMenu.concat(this.menuChildren);
    } else {
      this.selectDeselectAll = [];
      this.menuChildren = [];
      this.childrenMenu = [];
    }
  }
  allRole() {
    this.roleFindAll
      .execute()
      .then((response: any) => {
        this.roleList = response;
      })
      .catch((error: any) => {
        throw new Error(error);
      });
  }
  getMenuByRol(val: number) {
    this.isLoading = true;
    this.roleFindMenuPlaneByRoleId
      .execute(val)
      .then((response: any) => {
        this.isLoading = false;
        this.idUpdate = response.id;
        this.nameUpdate = response.name;
        if (val === 2) {
          this.menuList = response['menu'];
          this.childrenMenu = response['menu'];
          for (const menuList of response['menu']) {
            if (menuList['code'].length <= 3) {
              this.selectDeselectAll.push(menuList['code']);
            }
          }
        } else {
          this.childrenMenu = response['menu'];
          this.selectDeselectAll = [];
        }
        this.allRole();
      })
      .catch((error: any) => {
        this.isLoading = false;
        throw new Error(error);
      });
  }
  getRole(name: string) {
    this.addRoleName = 'ROLE_' + name;
  }
  newRol() {
    this.booleanAdd = !this.booleanAdd;
    if (this.booleanAdd) {
      this.childrenMenu = [];
      this.selectDeselectAll = [];
    } else {
      this.getMenuByRol(2);
    }
  }
  saveRole() {
    if (this.childrenMenu.length > 0) {
      if (this.booleanAdd) {
        let parent: any;
        for (parent of this.getMenuList) {
          let menuChild = null;
          for (const child of this.childrenMenu) {
            if (parent.code === child.parent) {
              menuChild = parent;
            }
          }
          if (menuChild !== null) {
            this.childrenMenu.push(menuChild);
          }
        }
        const payload: any = {
          id: 0,
          name: this.addRoleName,
          menu: this.childrenMenu
        };
        this.isLoading = true;
        this.createRole
          .execute(payload)
          .then((response: any) => {
            this.isLoading = false;
            this.booleanAdd = !this.booleanAdd;
            return response;
          })
          .catch((error: any) => {
            this.isLoading = false;
            throw new Error(error);
          });
      } else {
        let parent: any;
        for (parent of this.getMenuList) {
          let menuChild = null;
          for (const child in this.childrenMenu) {
            if (parent.code === this.childrenMenu[child].code) {
              this.childrenMenu.splice(child, 1);
            }
            if (parent.code === this.childrenMenu[child].parent) {
              menuChild = parent;
            }
          }
          if (menuChild !== null) {
            this.childrenMenu.push(menuChild);
          }
        }
        const payload: any = {
          id: this.idUpdate,
          name: this.nameUpdate,
          menu: this.childrenMenu
        };
        this.isLoading = true;
        this.updateRole
          .execute(payload)
          .then((response: any) => {
            this.isLoading = false;
            //this.getMenuByRol(2);
            this.booleanAdd = false;
            return response;
          })
          .catch((error: any) => {
            this.isLoading = false;
            throw new Error(error);
          });
      }
    } else {
      showAlert('warning', i18n.t('general.therolehasnomenuoptionsselected'));
    }
  }
}
