import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { CommodityClassRepository } from '@/settings/domain/commodityClass/CommodityClassRepository';
import { CommodityClass } from '@/settings/domain/commodityClass/CommodityClass';

@Injectable()
export class DeleteCommodityClass extends Command<Promise<CommodityClass>, CommodityClass> {
  public constructor(
    @Inject(TYPES.COMMODITY_CLASS_REPOSITORY)
    private readonly repository: CommodityClassRepository
  ) {
    super();
  }

  internalExecute(dto: CommodityClass): Promise<CommodityClass> {
    return this.repository.delete(dto);
  }
}
