import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { ReferencesRepository } from '@/wms/domain/catalogs/references/ReferencesRepository';
import { References } from '@/wms/domain/catalogs/references/References';

@Injectable()
export class ReferenceFindByTimeline extends Query<Promise<References[]>, { timeline: number }> {
  public constructor(
    @Inject(TYPES.REFERENCE_REPOSITORY)
    private readonly referencesRepository: ReferencesRepository
  ) {
    super();
  }
  async internalExecute({ timeline }: { timeline: number }): Promise<References[]> {
    return this.referencesRepository.getReferencesByTimeline({ timeline });
  }
}
