import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { ConceptsContainer } from '@/settings/domain/containers/conceptsContainer/ConceptsContainer';
import { ConceptsContainerRepository } from '@/settings/domain/containers/conceptsContainer/ConceptsContainerRepository';

@Injectable()
export class ConceptContainerService implements ConceptsContainerRepository {
  private readonly API_BASE_URI = localStorageModule().config.host.API_BASE_URI;
  private readonly API_CONCEPT_CONTAINER_SAVE =
    this.API_BASE_URI + localStorageModule().config.routes.API_CONCEPT_CONTAINER_SAVE;
  private readonly API_CONCEPT_CONTAINER_UPDATE =
    this.API_BASE_URI + localStorageModule().config.routes.API_CONCEPT_CONTAINER_UPDATE;
  private readonly API_CONCEPT_CONTAINER_DELETE =
    this.API_BASE_URI + localStorageModule().config.routes.API_CONCEPT_CONTAINER_DELETE;
  private readonly API_CONCEPT_CONTAINER_FINDALL =
    this.API_BASE_URI + localStorageModule().config.routes.API_CONCEPT_CONTAINER_FINDALL;
  private readonly API_CONCEPT_CONTAINER_FINBYCODE =
    this.API_BASE_URI + localStorageModule().config.routes.API_CONCEPT_CONTAINER_FINBYCODE;

  async create(concept: ConceptsContainer) {
    try {
      return await http.post<ConceptsContainer>(this.API_CONCEPT_CONTAINER_SAVE, JSON.stringify(concept));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async update(concept: ConceptsContainer) {
    try {
      return await http._put<ConceptsContainer>(this.API_CONCEPT_CONTAINER_UPDATE, JSON.stringify(concept));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async delete(conceptCode: string) {
    try {
      return await http.delete<ConceptsContainer>(this.API_CONCEPT_CONTAINER_DELETE + conceptCode, null);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAll() {
    try {
      return await http.get<ConceptsContainer[]>(this.API_CONCEPT_CONTAINER_FINDALL);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findConceptByCode(conceptCode: string) {
    try {
      return await http.get<ConceptsContainer>(this.API_CONCEPT_CONTAINER_FINBYCODE + conceptCode);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
