import { Injectable } from '@/core/di/Injectable';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { ReportConfigRepository } from '@/wms/domain/reports/ReportConfigRepository';
import { Command } from '@/core/cqrs/Command';

/**
 * @class DeleteReport
 * @description Clase que permite eliminar reportes
 * @version 1.0.0
 * @author Mauricio Cantillo
 */
@Injectable()
export class DeleteReport extends Command<Promise<void>, string> {
  public constructor(
    @Inject(TYPES.REPORT_REPOSITORY)
    readonly repository: ReportConfigRepository
  ) {
    super();
  }

  public async internalExecute(id: string): Promise<void> {
    return await this.repository.delete(id);
  }
}
