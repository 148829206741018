import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { Product } from '@/wms/domain/catalogs/product/Product';
import { ProductRepository } from '@/wms/domain/catalogs/product/ProductRepository';

@Injectable()
export class UpdateProduct extends Command<Promise<Product>, Product> {
  public constructor(
    @Inject(TYPES.PRODUCT_REPOSITORY)
    private readonly productRepository: ProductRepository
  ) {
    super();
  }

  async internalExecute(product: Product): Promise<Product> {
    return await this.productRepository.update(product);
  }
}
