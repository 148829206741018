import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { ZipCode } from '@/settings/domain/ZipCode/ZipCode';
import { ZipCodeRepository } from '@/settings/domain/ZipCode/ZipCodeRepository';

@Injectable()
export class SearchByCountryStateAndCity extends Query<Promise<ZipCode[]>, any> {
  public constructor(
    @Inject(TYPES.ZIPCODE_REPOSITORY)
    private readonly zipCodeRepository: ZipCodeRepository
  ) {
    super();
  }

  async internalExecute(payload: any): Promise<ZipCode[]> {
    return await this.zipCodeRepository.findByCountryStateAndCity(payload);
  }
}
