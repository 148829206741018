import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { PricingEngineRepository } from '@/wms/domain/catalogs/pricingEngine/PricingEngineRepository';
import { PricingEngine } from '@/wms/domain/catalogs/pricingEngine/PricingEngine';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class PricingEngineService implements PricingEngineRepository {
  private readonly API_BASE_URI: string = localStorageModule().wms.host.API_BASE_URI;
  private readonly API_PRICING_ENGINE_SAVE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_PRICING_ENGINE_SAVE;

  async save(pricingEngine: PricingEngine) {
    try {
      await http.post<PricingEngine[]>(this.API_PRICING_ENGINE_SAVE, JSON.stringify(pricingEngine));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
