import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { Preparation } from '@/freight/domain/Preparation/Preparation';
import { PreparationRepository } from '@/freight/domain/Preparation/PreparationRepository';

@Injectable()
export class CreatePreparation extends Command<void, Preparation> {
  constructor(
    @Inject(TYPES.CARGO_DETAILS_PRESET_REPOSITORY)
    private readonly repository: PreparationRepository
  ) {
    super();
  }

  async internalExecute(dto: Preparation): Promise<void> {
    await this.repository.create(dto);
  }
}
