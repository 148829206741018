import { Switches } from '@/tracking/domain/switches/Switches';

export class DocumentApplied {
  adjustment: number;
  date: string;
  dateApplied: string;
  discount: number;
  number: number | null;
  numberApplied: number | null;
  numberFee: number;
  retention: number;
  sequence: number;
  sw: Switches | null;
  type: string;
  typeApplied: string;
  value: number;

  public constructor() {
    this.adjustment = 0;
    this.date = '';
    this.dateApplied = '';
    this.discount = 0;
    this.number = null;
    this.numberApplied = null;
    this.numberFee = 0;
    this.retention = 0;
    this.sequence = 0;
    this.sw = null;
    this.type = '';
    this.typeApplied = '';
    this.value = 0;
  }
}
