
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import { PreparationBulk } from '@/freight/domain/preparationBulk/domain/PreparationBulk';
import { Containers } from '@/settings/domain/containers/Containers';
import { Weight } from '@/settings/domain/weight/Weight';
import { Packaging } from '@/wms/domain/catalogs/packaging/Packaging';
import { References } from '@/wms/domain/catalogs/references/References';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ShippingDetails from '../../quotation/form/shippingDetails/ShippingDetails.vue';
@Component({
  name: 'AddPreparationPallet',
  components: {
    ShippingDetails
  }
})
export default class AddPreparationPallet extends Vue {
  @Prop({ required: true }) lists!: {
    containerList: Containers[];
    references: References[];
    weightList: Weight[];
    commodityList?: CommodityType[];
    packagingList?: Packaging[];
  };
  @Prop({ default: false }) forView!: boolean;
  @Prop({ required: true }) addToBulks!: (bulk: PreparationBulk) => void;

  box: PreparationBulk = new PreparationBulk();

  get referencesFilteredByCargoType() {
    return this.lists.references.filter(reference => {
      return reference.cargoType?.includes('pallet');
    });
  }

  get disabledInputs() {
    return false;
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  addBulk() {
    this.box.setBulkType('pallet');
    this.addToBulks(this.box);
    this.reset();
  }

  reset(bulk: PreparationBulk = new PreparationBulk()) {
    this.box = bulk;
  }
}
