import { Injectable } from '@/core/di/Injectable';
import { Query } from '@/core/cqrs/Query';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { BillOfLandingRepository } from '@/wms/domain/billOfLanding/BillOfLandingRepository';

@Injectable()
export class CheckExistBillOfLanding extends Query<Promise<boolean>, string> {
  public constructor(
    @Inject(TYPES.BILL_OF_LANDING_REPOSITORY)
    private readonly repository: BillOfLandingRepository
  ) {
    super();
  }

  public async internalExecute(code: string): Promise<boolean> {
    return await this.repository.checkExist(code);
  }
}
