
import Vue from 'vue';
import Component from 'vue-class-component';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { CreateSize } from '@/settings/application/uses_cases/gamma/size/create/CreateSize';
import { FindAllSize } from '@/settings/application/uses_cases/gamma/size/search/SizeFindall';
import { Size } from '@/wms/domain/gamma/Size';
import { DeleteSize } from '@/settings/application/uses_cases/gamma/size/delete/DeleteSize';
@Component({
  name: 'SizeModal',
  components: { CustomTableN }
})
export default class SizeModal extends Vue {
  @Inject(TYPES.SIZE_FINDALL)
  readonly findAllSizes!: FindAllSize;
  @Inject(TYPES.SIZE_SAVE)
  readonly createSize!: CreateSize;
  @Inject(TYPES.SIZE_DELETE)
  readonly deleteSize!: DeleteSize;

  isLoading = false;
  isEditing = false;
  size: Size = new Size();
  items: Size[] = [];

  mounted() {
    this.findSizes();
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  get fields() {
    return [
      {
        field: 'size',
        label: this.$i18n.t('general.size'),
        sortable: true
      },
      {
        field: 'actions',
        label: this.$i18n.t('general.actions')
      }
    ];
  }

  actions = {
    customActions: [
      // {
      //   title: `${this.$t('general.edit')}`,
      //   icon: 'fa fa-pencil',
      //   variant: 'secondary'
      // },
      {
        title: `${this.$t('general.delete')}`,
        icon: 'fa fa-trash',
        variant: 'danger',
        action: this.itemDelete
      }
    ]
  };

  async save() {
    try {
      this.isLoading = true;
      const res = await this.createSize.execute(this.size);
      if (!('error' in res)) {
        this.findSizes();
        this.reset();
      }
    } finally {
      this.isLoading = false;
    }
  }

  async findSizes() {
    const res = await this.findAllSizes.internalExecute();
    this.items = res;
  }

  factory() {
    this.save();
  }

  reset() {
    this.isEditing = false;
    this.size = new Size();
  }

  async itemDelete(item: Size) {
    try {
      this.isLoading = true;
      await this.deleteSize.internalExecute(item);
    } finally {
      this.isLoading = false;
      this.findSizes();
      this.reset();
    }
  }
}
