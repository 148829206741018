import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { References } from '@/wms/domain/catalogs/references/References';
import { ReferencesRepository } from '@/wms/domain/catalogs/references/ReferencesRepository';

@Injectable()
export class ReferenceFindAllManagedStock extends Query<Promise<References[]>> {
  public constructor(
    @Inject(TYPES.REFERENCE_REPOSITORY)
    private readonly referencesRepository: ReferencesRepository
  ) {
    super();
  }
  async internalExecute(): Promise<References[]> {
    return await this.referencesRepository.findManagedStockReferences();
  }
}
