
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { TableOptions } from '@/core/domain/TableOptions';
import AddCargoDetails from '@/freight/infrastructure/ui/quotation/form/addCargoDetails/AddCargoDetails.vue';
import { CommodityClass } from '@/settings/domain/commodityClass/CommodityClass';
import { ViewLocation } from '@/settings/domain/views/location/ViewLocation';
import { Bulk } from '@/wms/domain/bulk/Bulk';
import { References } from '@/wms/domain/catalogs/references/References';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import CubeProcess from '../OperationalModals/CubeProcess.vue';
import FooterModal from './FooterModals/FooterModal.vue';
import HeaderModal from './HeaderModals/HeaderModal.vue';
import Swal from 'sweetalert2';
import { ActivityTransactionalForTraceability } from '@/tracking/domain/activitiesTransactional/Projections/ActivityTransactionalForTraceability';
import TransactionalEventForTraceability from '@/wms/domain/preRegister/TransactionalEventForTraceability';
import { ActivityTransactional } from '@/tracking/domain/activitiesTransactional/ActivityTransactional';
import { FindTransactionalActivityById } from '@/tracking/application/uses_cases/activitiesTransactional/search/FindTransactionalActivityById';
import ConsigneeByOperationMultiSelect from '@/core/components/shared/ConsigneeByOperationMultiSelect.vue';
import { BulkCubeInfo } from '@/wms/domain/bulk/BulkCubeInfo';
import { FindBulkCubeDataByOperation } from '@/wms/application/bulk/search/FindBulkCubeDataByOperation';
import { BulkCubeProcess } from '@/wms/application/bulk/update/BulkCubeProcess';
import { PreRegisterForTraceabilityPanel } from '@/wms/domain/preRegister/PreRegisterForTraceabilityPanel';

//Modal C11: Proceso de cubicaje
@Component({
  name: 'ModalC11',
  components: { HeaderModal, FooterModal, AddCargoDetails, CustomTableN, CubeProcess, ConsigneeByOperationMultiSelect }
})
export default class ModalC11 extends Vue {
  @Inject(TYPES.ACTIVITIES_TRANSACTIONAL_FIND_BY_ID)
  private readonly findActivityById!: FindTransactionalActivityById;
  @Inject(TYPES.BULK_FIND_CUBE_INFO_BY_OPERATION)
  private readonly findCubeInfoByOperation!: FindBulkCubeDataByOperation;
  @Inject(TYPES.BULK_CUBE_PROCESS)
  private readonly cubeProcess!: BulkCubeProcess;

  @Prop() isLoading!: boolean;
  @Prop() activityData!: ActivityTransactionalForTraceability;
  @Prop() eventData!: TransactionalEventForTraceability;
  @Prop({ type: Function }) saveProcess!: (activityData: ActivityTransactional) => Promise<void>;
  @Prop({ type: PreRegister }) master!: PreRegisterForTraceabilityPanel;
  @Prop() attachedFiles!: any[]; // Toca cambiar el tipo de dato
  @Prop({ type: Function }) searchActivities!: () => Promise<void>;

  modalActivityData: ActivityTransactional = new ActivityTransactional();
  bulks: BulkCubeInfo[] = [];

  volumeToSplit = 0;

  @Watch('attachedFiles')
  onMyFilesChange() {
    // Debemos hacer que el padre actualice el valor de attachedFiles
    this.$emit('update:attachedFiles', this.attachedFiles);
  }

  get modalTitle() {
    return `${this.eventData.description} - ${this.$t('switchs.' + this.activityData?.sw)} - ${
      this.activityData.keyModal
    }`;
  }

  get localIsLoading() {
    return this.isLoading;
  }

  set localIsLoading(value) {
    this.$emit('update:isLoading', value);
  }

  forView = false;
  consigneeSelected = '';
  consigneeList: string[] = [];
  dimensions = {
    widthOnCube: 0,
    heightOnCube: 0,
    lengthOnCube: 0,
    weightOnCube: 0
  };

  $refs!: {
    cubeProcess: CubeProcess;
  };

  // mounted() {
  //   // this.getAllData();
  //   // this.master.state = PreRegisterStatusEnum.CUBED;
  //   // this.getConsigneeList();
  // }

  /**
   * Getter para obtener los campos a mostra en la tabla
   * @returns { Array<TableOptions> }
   */
  get fields(): TableOptions[] {
    return [
      {
        label: `#`,
        field: 'number'
      },
      {
        label: `${this.$t('general.reference')}`,
        field: 'reference',
        formatFn: (item: References) => (item ? `${item.code} - ${item.filterBrand}` : '-')
      },
      {
        label: `Serial`,
        field: 'serial',
        formatFn: (item: number) => (!item ? 'No generado' : item)
      },
      {
        label: `${this.$t('general.description')}`,
        field: 'shippingDetails.productName'
      },
      {
        label: `${this.$t('general.commodity')}`,
        field: 'shippingDetails.commodity.description'
      },
      {
        label: `${this.$t('general.class')}`,
        field: 'commodityClass',
        formatFn: (item: CommodityClass) => (item ? item.description : `-`)
      },
      {
        label: `${this.$t('general.location')}`,
        field: 'location',
        formatFn: (item: ViewLocation) => (item ? item.locationName : `-`)
      },
      {
        label: `${this.$t('general.dimentions')}`,
        field: (row: Bulk) => `${row.length}x${row.width}x${row.height}`
      },
      {
        label: `${this.$t('general.cubedDimentions')}`,
        field: (row: Bulk) => `${row.lengthOnCube}x${row.widthOnCube}x${row.heightOnCube}`
      },
      {
        label: `${this.$t('general.weight')}`,
        field: 'weight'
      },
      {
        label: `${this.$t('general.cubedWeight')}`,
        field: 'weightOnCube'
      },
      {
        label: `${this.$t('general.weightUnit')}`,
        field: 'weightUnit',
        formatFn: (item: any) => {
          return item ? item.description : `-`;
        }
      },
      {
        label: `${this.$t('general.packaging')}`,
        field: (item: Bulk) => {
          if (item?.containerType) return item.containerType?.description;
          return item.packaging
            ? item.packaging?.unit?.description
            : item.packingUnit
            ? item.packingUnit.description
            : '-';
        }
      },
      {
        label: `${this.$t('general.type')}`,
        field: 'quotationTypeLine'
      },
      {
        label: `${this.$t('general.containerType')}`,
        field: (item: Bulk) => {
          return item.dispatchContainer ? item.dispatchContainer.description : '-';
        }
      },
      {
        label: `${this.$t('general.owner')}`,
        field: 'owner.fullName'
      },
      {
        label: `${this.$t('general.cargoTo')}`,
        field: 'cargoTo.fullName'
      },
      {
        label: `${this.$t('general.actions')}`,
        field: 'actions',
        hidden: this.forView
      }
    ];
  }

  get isDisabled() {
    return this.eventData.state == 'EXECUTED';
  }

  get eventExecuted() {
    return this.eventData.state === 'EXECUTED';
  }
  async save() {
    try {
      this.localIsLoading = true;
      if (!(await this.$refs.cubeProcess.save())) {
        return;
      }
      await this.cubeProcess.internalExecute({
        number: this.master.number,
        type: this.master.type,
        cubeInfo: this.bulks
      });
      await this.saveProcess(this.modalActivityData);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.localIsLoading = false;
    }
  }

  getValidationState(errors: any[]) {
    return errors.length > 0 ? false : true;
  }

  async updateDimensionsDataOnAllBulks() {
    const sureDecision = await Swal.fire({
      title: `${this.$t('general.areYouSure')}`,
      text: `${this.$t('general.copyBulksText')}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `${this.$t('general.confirmCopyBulksButton')}`,
      cancelButtonText: `${this.$t('general.cancel')}`
    });

    if (!sureDecision.isConfirmed) return;

    const splitVolumeAndWeightDecision = await Swal.fire({
      title: `${this.$t('general.areYouSure')}`,
      text: `${this.$t('general.splitVolumeAndWeightText')}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `${this.$t('general.confirmSplitVolumeAndWeightButton')}`,
      cancelButtonText: `${this.$t('general.noSplitVolumeAndWeightButton')}`
    });

    if (!splitVolumeAndWeightDecision.isConfirmed) {
      const resolved = await this.copyDimensionsData();

      if (resolved) {
        Swal.fire(`${this.$t('general.success')}`, `${this.$t('general.successCopyBulksMsg')}`, 'success');
      } else {
        Swal.fire(`${this.$t('general.error')}`, `${this.$t('general.errorCopyBulksMsg')}`, 'error');
      }
      return;
    }

    this.copyDimensionsDataSplitVolumeAndWeight();

    Swal.fire(`${this.$t('general.success')}`, `${this.$t('general.successCopyBulksMsg')}`, 'success');
  }

  copyDimensionsData(): Promise<boolean> {
    return new Promise(resolve => {
      const updatedBulks = this.bulks.map(existingBulk => {
        if (existingBulk.consignee === this.consigneeSelected) {
          return {
            ...existingBulk,
            widthOnCube: this.dimensions.widthOnCube,
            heightOnCube: this.dimensions.heightOnCube,
            lengthOnCube: this.dimensions.lengthOnCube,
            weightOnCube: this.dimensions.weightOnCube
          };
        } else {
          return existingBulk;
        }
      });

      this.bulks = updatedBulks;
      resolve(true);
    });
  }

  copyDimensionsDataSplitVolumeAndWeight(): void {
    const volume = this.volumeToSplit;
    const weight = this.dimensions.weightOnCube;

    let bulksQuantity = 0;
    let volumePerBulk = 0;
    let weightPerBulk = 0;
    let bulkLength = 0;

    this.bulks
      .filter(existingBulk => existingBulk.consignee === this.consigneeSelected)
      .forEach((existingBulk, index, array) => {
        if (index === 0) {
          // Debemos redondear el volumen y el peso a 2 decimales
          bulksQuantity = array.length;
          volumePerBulk = volume / bulksQuantity;
          weightPerBulk = Math.round((weight / bulksQuantity) * 100) / 100;
          bulkLength = Math.round(Math.cbrt(volumePerBulk) * 100) / 100;
        }

        existingBulk.widthOnCube = bulkLength;
        existingBulk.heightOnCube = bulkLength;
        existingBulk.lengthOnCube = bulkLength;
        existingBulk.weightOnCube = weightPerBulk;
      });
  }

  async findActivityData() {
    try {
      if (!this.modalActivityData) return;

      this.localIsLoading = true;

      this.modalActivityData = await this.findActivityById.execute({
        activityId: this.activityData?.activityId,
        eventId: this.eventData.eventId,
        preRegisterNumber: this.master.number,
        preRegisterType: this.master.type
      });
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.localIsLoading = false;
    }
  }

  async findCubeInfo() {
    try {
      this.bulks = await this.findCubeInfoByOperation.execute({
        number: this.master.number,
        type: this.master.type
      });
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findData() {
    await this.findActivityData();
    await this.findCubeInfo();
  }
}
