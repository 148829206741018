import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { ShippingMethodRepository } from '@/settings/domain/shippingMethod/ShippingMethodRepository';
import { ShippingMethod } from '@/settings/domain/shippingMethod/ShippingMethod';
import { Query } from '@/core/cqrs/Query';

@Injectable()
export class FindByIdShippingMethod extends Query<Promise<ShippingMethod>, string> {
  public constructor(
    @Inject(TYPES.SHIPPING_METHOD_REPOSITORY)
    private readonly repository: ShippingMethodRepository
  ) {
    super();
  }

  internalExecute(id: string): Promise<ShippingMethod> {
    return this.repository.findById(id);
  }
}
