
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Bulk } from '@/wms/domain/bulk/Bulk';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import { CommodityClass } from '@/settings/domain/commodityClass/CommodityClass';
import { Containers } from '@/settings/domain/containers/Containers';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { PackingUnits } from '@/settings/domain/packing_units/PackingUnits';
import { TypeOperation } from '@/settings/domain/typeOperation/TypeOperation';
import { ViewLocation } from '@/settings/domain/views/location/ViewLocation';
import { Territories } from '@/settings/domain/views/territories/Territories';
import { BillOfLanding } from '@/wms/domain/billOfLanding/BillOfLanding';
import ShippingDetails from '../../shippingDetails/ShippingDetails.vue';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import { preRegisterSteps } from '@/wms/domain/preRegister/PreRegisterStatusEnum';
import { References } from '@/wms/domain/catalogs/references/References';
import ConsigneeSearch from '@/core/components/shared/ConsigneeSearch.vue';
import EntitySearch from '@/core/components/shared/EntitySearch.vue';
import CargoOwners from '../cargoOwners/CargoOwners.vue';

interface Lists {
  containerList?: Containers[];
  originList?: Territories[];
  destinationList?: Territories[];
  commodityList?: CommodityType[];
  packagingList?: PackingUnits[];
  referencesList?: any[];
  typeOperationList?: TypeOperation[];
  locationList?: ViewLocation[];
  weightList?: any[];
  commodityClassesList?: CommodityClass[];
  customerList?: Entity[];
  billOfLandingList?: BillOfLanding[];
  singleReferencesList?: References[];
}
@Component({
  name: 'AddContainer',
  components: { ShippingDetails, ConsigneeSearch, EntitySearch, CargoOwners }
})
export default class AddContainer extends Vue {
  @Prop() readonly forView!: boolean;
  @Prop() readonly showCargoOwners!: boolean;
  @Prop() container!: Bulk;
  @Prop() lists!: Lists;
  @Prop() addBulk!: Function;
  @Prop() clearForm!: Function;
  @Prop() customLabelBl!: Function;
  @Prop() findEntity!: Function;
  @Prop() form!: PreRegister;
  @Prop({ required: false, default: () => false }) isQuotation!: boolean;

  get referencesFilteredByCargoType() {
    return this.lists.singleReferencesList?.filter((reference: References) => {
      return reference.cargoType?.includes('container');
    });
  }

  get disabledInputs() {
    return this.forView;
  }

  getStatusValue(key: string) {
    if (key != 'cubed' && key != 'partial_pre_register' && key != 'pre_register' && key != 'shipment') return 1000;
    return preRegisterSteps[key];
  }

  add() {
    const number = this.container.quantity;
    this.container.quantity = 1;
    this.addBulk(this.container, number, 'container');
  }

  //Funcion invocada para validar un input
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
}
