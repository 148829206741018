import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { CommodityClassRepository } from '@/settings/domain/commodityClass/CommodityClassRepository';
import { CommodityClass } from '@/settings/domain/commodityClass/CommodityClass';
import { Query } from '@/core/cqrs/Query';

@Injectable()
export class FindAllCommodityClass extends Query<Promise<CommodityClass[]>> {
  public constructor(
    @Inject(TYPES.COMMODITY_CLASS_REPOSITORY)
    private readonly repository: CommodityClassRepository
  ) {
    super();
  }

  internalExecute(): Promise<CommodityClass[]> {
    return this.repository.findAll();
  }
}
