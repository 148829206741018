import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Command } from '@/core/cqrs/Command';
import { Bank } from '@/settings/domain/ParmsGeneral/bank/Bank';
import { BankRepository } from '@/settings/domain/ParmsGeneral/bank/BankRepository';

@Injectable()
export class CreateBank extends Command<Promise<any>, Bank> {
  public constructor(
    @Inject(TYPES.BANK_REPOSITORY)
    private readonly repository: BankRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  public internalExecute(bank: Bank): Promise<any> {
    return this.repository.create(bank);
  }
}
