
import Component from 'vue-class-component';
import Vue from 'vue';
import { Emit, Prop } from 'vue-property-decorator';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { UpdateTimeline } from '@/tracking/application/uses_cases/timeline/update/UpdateTimeline';
import { OperationFindByProcessType } from '@/tracking/application/uses_cases/typeoperation/search/OperationFindByProcessType';
import { MonetaryTransactionFindAll } from '@/tracking/application/uses_cases/monetarytransaction/search/findall/MonetaryTransactionFindAll';
import i18n from '@/core/plugins/i18n';

@Component({
  name: 'TableActivity'
})
export default class AppCreateTableTimelineActivity extends Vue {
  @Inject(TYPES.UPDATE_TIMELINE)
  readonly updateTimeline!: UpdateTimeline;
  @Inject(TYPES.FINDALL_MONETARY_TRANSACTION)
  readonly monetaryTransactionFindAll!: MonetaryTransactionFindAll;
  @Inject(TYPES.FINDBYPROCESSTYPE_TYPE_OPERATION)
  readonly operationFindByProcessType!: OperationFindByProcessType;
  //Data
  target = i18n.t('general.activity');
  activityMonetaryList = [];
  private formTableActivity: any = {
    dataMonetaryTransaction: [],
    isTouched: false,
    edit: null
  };
  //Prop
  @Prop() readonly activityListFindByPk!: any;
  @Prop() readonly eventsListFindByPk!: any;
  @Prop() readonly timelineListByIndex!: any;
  @Prop() readonly typeProcess!: number;
  //Hook
  mounted() {
    this.$nextTick(() => this.typeMonetary);
  }
  //Computed
  get typeMonetary() {
    this.monetaryTransactionFindAll.execute().then((response: any) => {
      this.activityMonetaryList = response;
    });
    return true;
  }
  //Method
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  editTimelineActivity(dataTimeline: any, dataEventUpdate: any, dataActivityUpdate: any) {
    this.formTableActivity.edit =
      this.formTableActivity.edit !== dataActivityUpdate.activityId ? dataActivityUpdate.activityId : null;
    if (this.formTableActivity.edit === null) {
      for (const event in dataTimeline.events) {
        for (const activity in dataTimeline.events[event].activities) {
          Number(activity);
          if (dataTimeline.events[event].activities[activity].activityId === dataActivityUpdate.activityId) {
            (dataTimeline.events[event].activities[activity].activityId = dataActivityUpdate.activityId),
              (dataTimeline.events[event].activities[activity].description = dataActivityUpdate.description),
              (dataTimeline.events[event].activities[
                activity
              ].monetaryTransactionId = this.formTableActivity.dataMonetaryTransaction.id),
              (dataTimeline.events[event].activities[
                activity
              ].monetaryTransactionName = this.formTableActivity.dataMonetaryTransaction.name),
              (dataTimeline.events[event].activities[activity].projectedDate = dataActivityUpdate.projectedDate),
              (dataTimeline.events[event].activities[activity].required = this.activityListFindByPk.required),
              (dataTimeline.events[event].activities[activity].spreadAutomatically = false),
              (dataTimeline.events[event].activities[activity].value = this.activityListFindByPk.value),
              (dataTimeline.events[event].activities[activity].active = this.activityListFindByPk.active),
              (dataTimeline.events[event].activities[activity].createAt = dataActivityUpdate.createAt),
              (dataTimeline.events[event].activities[activity].updateAt = dataActivityUpdate.updateAt);
          }
        }
      }
      const payloadUpdate: any = {
        id: dataTimeline.id,
        description: dataTimeline.description.trim(),
        typeOperation: dataTimeline.typeOperation,
        typeTransport: dataTimeline.typeTransport,
        processType: this.typeProcess,
        active: dataTimeline.active,
        events: dataTimeline.events
      };
      this.updateTimeline
        .execute(payloadUpdate)
        .then((response: any) => {
          if (response.ok) {
            this.getTimelineSkeleton();
            this.activityLoadChartData();
          }
          return response;
        })
        .catch((error: any) => {
          throw new Error(error);
        });
    } else {
      this.formTableActivity.dataMonetaryTransaction = {
        id: dataActivityUpdate.monetaryTransactionId,
        name: dataActivityUpdate.monetaryTransactionName
      };
    }
  }
  //Emit
  @Emit()
  getTimelineSkeleton() {
    this.$emit('getTimelineSkeleton');
  }
  @Emit()
  activityLoadChartData() {
    this.$emit('activityLoadChartData');
  }
}
