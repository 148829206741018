
import Vue from 'vue';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import Component from 'vue-class-component';
import { TaxeFindAll } from '@/settings/application/uses_cases/taxe/search/TaxeFindAll';
import { TaxeFindByStatus } from '@/settings/application/uses_cases/taxe/search/TaxeFindByStatus';
import { TaxeUpdate } from '@/settings/application/uses_cases/taxe/update/TaxeUpdate';
import AddTax from '@/settings/infrastructure/ui/components/taxes/AddTax.vue';
import FilterCustomize from '@/core/components/shared/FilterCustomize.vue';
import { DeleteTaxByCode } from '@/settings/application/uses_cases/taxe/delete/DeleteTaxByCode';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import { Taxe } from '@/settings/domain/taxe/Taxe';

@Component({
  name: 'RegisterTaxes',
  components: {
    FilterCustomize,
    AddTax,
    CustomTableN
  }
})
export default class RegisterTaxes extends Vue {
  @Inject(TYPES.UPDATE_TAXE)
  readonly taxeUpdate!: TaxeUpdate;
  @Inject(TYPES.FINDALL_TAXE)
  readonly taxeFindAllcall!: TaxeFindAll;
  @Inject(TYPES.FINDBYSTATUS_TAXE)
  readonly taxeFindByStatus!: TaxeFindByStatus;
  @Inject(TYPES.DELETE_TAX)
  readonly deleteTaxByCode!: DeleteTaxByCode;
  //--DATA
  paramsFilter = false;
  isLoading = false;
  fullPage = true;
  taxKey = 0;
  taxes = [];
  totalRows = 1;
  isDisabled = false;
  currentPage = 1;
  perPage = 10;
  filter = null;
  filterOn = [];
  sortBy = '';
  sortDesc = false;
  isBusy = true;
  edit = null;
  form: any = {
    taxCode: '',
    taxDescription: '',
    taxPercentage: 0,
    taxStatus: true,
    edit: false,
    status: true,
    isPercentage: false
  };

  tableActions = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: this.dataButtonEdit
      },
      {
        title: `${this.$t('general.delete')}`,
        icon: 'fa fa-trash',
        variant: 'danger',
        action: this.deleteTax
      }
    ]
  };

  mounted() {
    this.taxeFindAll();
  }
  //COMPUTED PROPERTIES
  get rows() {
    return this.taxes.length;
  }

  get fields() {
    return [
      { field: 'code', label: this.translateCol('code'), sortable: true },
      { field: 'description', label: this.translateCol('description'), sortable: true },
      {
        field: (row: Taxe) => {
          return !row.isPercentage ? '-' : row.percentage;
        },
        label: `${this.translateCol('percentage')} (%)`,
        sortable: true,
        sortDirection: 'desc'
      },
      {
        field: (row: Taxe) => {
          return row.isPercentage ? '-' : row.percentage;
        },
        label: `${this.translateCol('fixedAmount')}`,
        sortable: true,
        sortDirection: 'desc'
      },
      {
        field: 'active',
        label: this.translateCol('status'),
        sortable: true,
        formatFn: (value: boolean) => (value ? this.$t('general.active') : this.$t('general.inactive'))
      },
      { field: 'actions', label: this.translateCol('actions') }
    ];
  }

  //METHODS
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  translateCol(colName: string) {
    return this.$i18n.t('general.' + colName);
  }

  async deleteTax(tax: Taxe) {
    this.isLoading = true;
    await this.deleteTaxByCode
      .execute(tax.code)
      .then((): void => {
        this.isLoading = false;
        this.taxeFindAll();
      })
      .catch(error => {
        this.isLoading = false;
        throw new Error(`${error}`);
      });
  }

  taxeFindAll() {
    this.taxeFindAllcall
      .execute()
      .then((response: any) => {
        this.taxes = response.reverse();
        this.isBusy = false;
      })
      .catch(error => {
        throw new Error(error);
      });
    this.taxKey += 1;
  }

  taxeFindAllByStatus(status: boolean) {
    if (status === true || status === false) {
      this.taxeFindByStatus
        .execute(status)
        .then((response: any) => {
          this.taxes = response;
          this.isBusy = false;
          this.paramsFilter = true;
        })
        .catch((error: any) => {
          this.paramsFilter = false;
          throw new Error(error);
        });
    } else {
      this.paramsFilter = false;
      this.$nextTick(() => this.taxeFindAll());
    }
  }

  dataButtonEdit(data: any) {
    this.form = {
      ...this.form,
      taxCode: data.code,
      taxDescription: data.description,
      taxPercentage: data.percentage,
      taxStatus: data.active,
      edit: true,
      isPercentage: data.isPercentage
    };
  }

  addTax() {
    this.$nextTick(() => this.taxeFindAll());
  }

  clearForm() {
    this.form = {
      ...this.form,
      taxCode: '',
      taxDescription: '',
      taxPercentage: 0,
      taxStatus: true,
      edit: false,
      isPercentage: false
    };
  }
}
