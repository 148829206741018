
import { MailServerConfig } from '@/settings/domain/mailServerConfig/MailServerConfig';
import { ValidationObserver } from 'vee-validate';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { CreateEmailConfig } from '@/settings/application/uses_cases/company/create/CreateEmailConfig';
import { FindEmailConfigByCompany } from '@/settings/application/uses_cases/company/search/FindEmailConfigByCompany';
@Component({
  name: 'ConfigServerForm',
  components: {}
})
export default class ConfigServerForm extends Vue {
  @Inject(TYPES.EMAIL_CONFIG_SAVE)
  readonly emailConfigSave!: CreateEmailConfig;
  @Inject(TYPES.EMAIL_CONFIG_FIND_BY_COMPANY)
  readonly getEmailConfig!: FindEmailConfigByCompany;

  mailServerConfigObj: MailServerConfig = new MailServerConfig();

  isLoading = false;

  $refs!: {
    mailServerForm: InstanceType<typeof ValidationObserver>;
  };

  closeModalMailServerConfig() {
    this.mailServerConfigObj = new MailServerConfig();
  }

  async handleOkModal(bvModalEvent: any) {
    try {
      bvModalEvent.preventDefault();
      if (!(await this.$refs.mailServerForm.validate())) return;
      this.mailServerConfigObj.company.id = localStorage.getItem('businessId') as string;
      this.isLoading = true;
      const res = await this.emailConfigSave.execute(this.mailServerConfigObj);
      if (!('message' in res)) {
        this.$bvModal.hide('mail-server-modal');
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async getConfig() {
    try {
      this.isLoading = true;
      this.mailServerConfigObj = new MailServerConfig();
      const company = localStorage.getItem('businessId') as string;
      const res = await this.getEmailConfig.execute(company);
      this.isLoading = false;
      if ('error' in res) return;
      this.mailServerConfigObj = res;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
}
