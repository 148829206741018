import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { TopInformationCards } from '@/settings/domain/topInformationCards/TopInformationCards';
import { TopInformationCardsRepository } from '@/settings/domain/topInformationCards/TopInformationCardsRepository';

@Injectable()
export class TopInformationCardsService implements TopInformationCardsRepository {
  API_BASE_URI: string = localStorageModule().wms.host.API_BASE_URI;
  API_FIND_ALL_TOP_INFORMATION_CARDS: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_FIND_ALL_TOP_INFORMATION_CARDS;

  async findAll(): Promise<TopInformationCards[]> {
    try {
      return await http.get<TopInformationCards[]>(this.API_FIND_ALL_TOP_INFORMATION_CARDS);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
