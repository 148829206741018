import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Weight } from '@/settings/domain/weight/Weight';
import { WeightRepository } from '@/settings/domain/weight/WeightRepository';

@Injectable()
export class WeightServices implements WeightRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_WEIGHT_FINDALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_WEIGHT_FINDALL;
  private readonly API_WEIGHT_SAVE: string = this.API_BASE_URI + localStorageModule().config.routes.API_WEIGHT_SAVE;
  private readonly API_WEIGHT_UPDATE: string = this.API_BASE_URI + localStorageModule().config.routes.API_WEIGHT_UPDATE;
  private readonly API_WEIGHT_DELETE: string = this.API_BASE_URI + localStorageModule().config.routes.API_WEIGHT_DELETE;

  async create(weight: Weight) {
    try {
      return await http.post<Weight[]>(this.API_WEIGHT_SAVE, JSON.stringify(weight));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(weight: Weight) {
    try {
      return await http.put<Weight[]>(this.API_WEIGHT_UPDATE, JSON.stringify(weight));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findAll(): Promise<Weight[]> {
    try {
      return await http.get<Weight[]>(this.API_WEIGHT_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async delete(weight: Weight): Promise<any> {
    try {
      return await http.delete<Weight>(this.API_WEIGHT_DELETE, JSON.stringify(weight));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
