
import { ValidationObserver } from 'vee-validate';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { Prop } from 'vue-property-decorator';
import { StateManager } from '@/core/statemanager/StateManager';
import { FindAllTimelineConfigByStatus } from '@/tracking/application/uses_cases/timelineConfig/search/findAll/FindAllTimelineConfigByStatus';
import { RoutesFindByOiriginAndDestination } from '@/settings/application/uses_cases/routes/search/RoutesFindByOiriginAndDestination';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
@Component({
  name: 'PricelistOrder'
})
export default class PricelistOrder extends Vue {
  @Inject(TYPES.STATE_MANAGER)
  readonly stateManager!: StateManager;
  @Inject(TYPES.TIMELINECONFIG_TYPE_FINDALL_BY_STATUS)
  readonly findAllActiveTimelineConfig!: FindAllTimelineConfigByStatus;
  @Inject(TYPES.ROUTES_FIND_BY_ORIGIN_AND_DESTINATION)
  readonly findRouteByOriginAndDestination!: RoutesFindByOiriginAndDestination;

  //PROPS
  @Prop() readonly form!: PreRegister;
  @Prop() readonly pricingLevel!: string;
  @Prop() readonly pricingResponse!: any;
  @Prop() readonly calculated!: any;
  @Prop() readonly selectedTimelineAndPricelist!: any;

  get localSelectedTimelineAndPricelist() {
    return this.selectedTimelineAndPricelist;
  }

  set localSelectedTimelineAndPricelist(newValue: any) {
    this.$emit('update:selectedTimelineAndPricelist', newValue);
  }

  //GETTERS
  get fields() {
    return [
      {
        label: this.$t('general.supplier'),
        key: 'priceList.entity.fullName',
        sortable: true
      },
      {
        label: this.$t('general.typetransport'),
        key: 'timeline.typeTransportName',
        sortable: true
      },
      {
        label: this.$t('general.value'),
        sortable: true
      },
      {
        label: this.$t('general.baseValue'),
        key: 'priceList.calResponse.rateValue',
        sortable: true
      },
      {
        label: this.$t('general.otherChargues'),
        key: 'totalOtherCosts',
        sortable: true
      },
      {
        label: this.$t('general.route'),
        key: 'priceList.unitValue',
        sortable: true
      },
      {
        label: 'Acción',
        key: 'action'
      }
    ];
  }

  get packages() {
    return this.form.bulks;
  }

  set packages(newValue: any) {
    this.packages = newValue;
  }

  //REFS
  $refs!: {
    pricelist: InstanceType<typeof ValidationObserver>;
  };

  async verificar() {
    const validator = await this.$refs.pricelist.validate();

    return validator;
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  searchTimeline(routeId: number, transportId: number, timelines: any[]): any[] {
    try {
      return timelines.filter(item => {
        return item.routeId == routeId && item.typeTransport == transportId && item.active != false;
      });
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
