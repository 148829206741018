import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { CurrencyRepository } from '@/settings/domain/currency/CurrencyRepository';
import { CurrencyRate } from '@/settings/domain/currency/CurrencyRate';

@Injectable()
export class CurrencyFindAllWithRate extends Query<Promise<CurrencyRate[]>> {
  public constructor(
    @Inject(TYPES.CURRENCY_REPOSITORY)
    private readonly currencyRepository: CurrencyRepository
  ) {
    super();
  }
  async internalExecute(): Promise<CurrencyRate[]> {
    return await this.currencyRepository.findAllWithRate();
  }
}
