import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { ReferencesRepository } from '@/wms/domain/catalogs/references/ReferencesRepository';
import Serial from '@/wms/domain/catalogs/references/Serial';
import { Command } from '@/core/cqrs/Command';

/**
 * @class CreateSerials
 * @description Clase que permite crear seriales nuevos
 * @version 1.0.0
 * @Author Mauricio Cantillo
 */
@Injectable()
export class CreateSerial extends Command<Promise<Serial>, { code: string; file: File }> {
  public constructor(
    @Inject(TYPES.REFERENCE_REPOSITORY)
    readonly repository: ReferencesRepository
  ) {
    super();
  }

  public async internalExecute({ code, file }: { code: string; file: File }): Promise<Serial> {
    const form = new FormData();
    form.append('file', file);

    return await this.repository.saveAssociatedSerial(code, form);
  }
}
