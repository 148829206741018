import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { Branch } from '@/settings/domain/branch/Branch';
import { BranchRepository } from '@/settings/domain/branch/BranchRepository';
@Injectable()
export class FindBranchByStatus extends Query<Promise<Branch[]>, boolean> {
  public constructor(@Inject(TYPES.BRANCH_REPOSITORY) private readonly branchRepository: BranchRepository) {
    super();
  }

  async internalExecute(status: boolean): Promise<Branch[]> {
    try {
      const res = await this.branchRepository.findByStatus(status);
      if (!(res instanceof Array)) {
        return [];
      }
      return res;
    } catch (error) {
      return [];
    }
  }
}
