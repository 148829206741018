import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { PackingUnit } from '@/wms/domain/packingUnit/PackingUnit';
import { PackingUnitRepository } from '@/wms/domain/packingUnit/PackingUnitRepository';

@Injectable()
export class UpdatePackingUnit extends Command<Promise<any>, PackingUnit> {
  public constructor(
    @Inject(TYPES.PACKINGUNIT_REFERENCE_REPOSITORY)
    private readonly unitMeasurementRepository: PackingUnitRepository
  ) {
    super();
  }

  public internalExecute(packingUnit: PackingUnit): Promise<any> {
    return this.unitMeasurementRepository.update(packingUnit);
  }
}
