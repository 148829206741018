import { PricingEngineParam } from '@/wms/domain/catalogs/pricingEngineParam/PricingEngineParam';

export class Offers {
  public codePolicy: string;
  public codeReference: string;
  public idOffers: number;
  public isGeneral: boolean;
  public typeOffers: PricingEngineParam;
  public appliesTo: PricingEngineParam;
  public numberUnits: number;
  public expirationDate: Date;
  public applyDetailJson: string;
  public codeSettingOffers: number;
  public applicablePercentage: number;
  public policyOffersTiered: any;

  constructor() {
    this.codePolicy = '';
    this.codeReference = '';
    this.idOffers = 0;
    this.isGeneral = false;
    this.typeOffers = new PricingEngineParam();
    this.appliesTo = new PricingEngineParam();
    this.numberUnits = 0;
    this.expirationDate = new Date();
    this.applyDetailJson = '';
    this.codeSettingOffers = 0;
    this.applicablePercentage = 0;
    this.policyOffersTiered = [];
  }
}
