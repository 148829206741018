import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { AlternativeReferencesRepository } from '@/wms/domain/catalogs/alternativeReferences/AlternativeReferencesRepository';

@Injectable()
export class FindOwnerByReferenceAndType extends Query<Promise<any[]>, any> {
  public constructor(
    @Inject(TYPES.ALTERNATIVE_REFERENCE_REPOSITORY)
    private readonly alternativeReferencesRepository: AlternativeReferencesRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(parms: any): Promise<any[]> {
    const dataCustomer = await this.alternativeReferencesRepository.findOwnerByReferenceAndType(parms);
    this.stateManager.patch({ dataCustomer });
    return this.stateManager.state.dataCustomer;
  }
}
