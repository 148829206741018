
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { CurrencyFindAll } from '@/settings/application/uses_cases/currency/search/CurrencyFindAll';
import { Currency } from '@/settings/domain/currency/Currency';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'CurrencySearch',
  components: {}
})
export default class CurrencySearch extends Vue {
  //Variable para almacenar la lista de territorios
  localCurrencies: Currency[] = [];

  //Variable para controlar el estado de la carga
  isLoading = false;

  @Inject(TYPES.FINDALL_CURRENCY)
  readonly findAllCurrency!: CurrencyFindAll;

  @Prop({ required: true }) value!: Currency | null;
  @Prop({ required: false }) label!: string | null;
  @Prop({ required: false, default: '' }) containerClass!: string | null;
  @Prop({ required: false, default: '' }) validationRules!: string | null;
  @Prop({
    required: false,
    default: (): null => {
      return null;
    }
  })
  onInput!: (store: Currency | null) => Promise<void> | void;
  @Prop({ required: false, default: false }) readonly disabled!: boolean;
  @Prop({ required: false }) currencies!: Currency[];

  mounted() {
    if (!this.currencies) this.findCurrencies();
  }

  get localValue(): Currency | null {
    return this.value;
  }

  set localValue(value: Currency | null) {
    this.$emit('update:value', value);
  }

  /**
   * @description Funcion para buscar tdas las stores y asignarlo a la lista
   * @name findCurrencies
   */
  async findCurrencies() {
    this.isLoading = true;
    try {
      const response = await this.findAllCurrency.execute();
      this.localCurrencies = response;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  //Función invocada cuando se selecciona un territorio y se desea enviar al padre para ejecutar alguna acción.
  async dispatchInput(value: Currency | null) {
    if (this.onInput) await this.onInput(value);
  }

  //Watch invocado para cuando el valor de la variable stores cambie
  @Watch('currencies')
  onStoresChange(currency: Currency[] | null) {
    if (currency) this.localCurrencies = this.currencies;
  }
}
