import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { PaymentMethods } from '@/settings/domain/ParmsGeneral/paymentMethods/PaymentMethods';
import { PaymentMethodsRepository } from '@/settings/domain/ParmsGeneral/paymentMethods/PaymentMethodsRepository';

@Injectable()
export class PaymentMethodsFindAll extends Query<Promise<PaymentMethods[]>> {
  public constructor(
    @Inject(TYPES.PAYMENT_METHODS_REPOSITORY)
    private readonly repository: PaymentMethodsRepository,

    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(): Promise<PaymentMethods[]> {
    const dataPaymentMethods = await this.repository.findAll();
    this.stateManager.patch({ dataPaymentMethods });
    return this.stateManager.state.dataPaymentMethods;
  }
}
