import { TYPES } from '@/core/config/Types';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Query } from '@/core/cqrs/Query';
import { LoginRepository } from '@/courier/domain/login/LoginRepository';

@Injectable()
export class LoginFindToken extends Query<Promise<string>, string> {
  public constructor(
    @Inject(TYPES.LOGIN_REPOSITORY)
    private readonly loginRepository: LoginRepository
  ) {
    super();
  }

  async internalExecute(params: any): Promise<string> {
    return await this.loginRepository.getToken(params);
  }
}
