
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import VueBarcode from 'vue-barcode';

@Component({
  name: 'CustomTable',
  components: { VueBarcode }
})
export default class CustomTable extends Vue {
  //DATA
  @Prop() readonly IsPaginated!: any;
  @Prop() readonly IsFiltered!: any;
  @Prop() readonly fields!: any;
  @Prop() readonly items!: any[];
  @Prop() readonly sortBy!: any;
  @Prop() readonly sortDesc!: any;
  @Prop() readonly filter!: any;
  @Prop() readonly criteria!: any;
  @Prop() readonly filterTable!: any;
  @Prop() readonly clearFilter!: any;
  @Prop() readonly actions!: any;
  @Prop() readonly refresh!: Function;
  @Prop({ default: null }) readonly rowAction!: Function;

  perPage = 30;
  currentPage = 1;
  // totalRows = this.items.length;

  get totalRows() {
    return this.items.length;
  }

  set totalRows(newValue: number) {
    this.totalRows = newValue;
  }

  translateCol(colName: string) {
    return this.$i18n.t('general.' + colName);
  }

  onFiltered(filteredItems: any) {
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  }
}
