import { Command } from '@/core/cqrs/Command';
import { ReferenceGroupLevel } from '@/wms/domain/referenceGroupLevel/ReferenceGroupLevel';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { ReferenceGroupLevelRepository } from '@/wms/domain/referenceGroupLevel/ReferenceGroupLevelRepository';

@Injectable()
export class CreateReferenceGroupLevel extends Command<Promise<ReferenceGroupLevel>, ReferenceGroupLevel> {
  public constructor(
    @Inject(TYPES.REFERENCE_GROUP_LEVEL_REPOSITORY)
    private readonly referenceGroupLevelRepository: ReferenceGroupLevelRepository
  ) {
    super();
  }

  public async internalExecute(referenceGroupLevel: ReferenceGroupLevel): Promise<ReferenceGroupLevel> {
    return await this.referenceGroupLevelRepository.create(referenceGroupLevel);
  }
}
