
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'AddContainer'
})
export default class AddContainer extends Vue {
  @Prop() readonly form!: any;
  @Prop() readonly formFunctions!: any;
  @Prop() readonly buttonOptions!: any;

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
}
