export class Timeline {
  id: number;
  description: string;
  typeTransport: number;
  typeOperation: string;
  nature: string;
  events: any[];
  selectedRouteId: number;
  configFor: string;
  active: boolean;
  processType: number;

  public constructor(
    id?: number,
    description?: string,
    typeTransport?: number,
    typeOperation?: string,
    nature?: string,
    events?: any,
    selectedRouteId?: number,
    configFor?: string
  ) {
    this.id = id || 0;
    this.description = description || '';
    this.typeTransport = typeTransport || 0;
    this.typeOperation = typeOperation || '';
    this.nature = nature || '';
    this.events = events || [];
    this.selectedRouteId = selectedRouteId || 0;
    this.configFor = configFor || '';
    this.active = true;
    this.processType = 3;
  }
}
