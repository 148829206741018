import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { Transport } from '@/tracking/domain/typetransport/Transport';
import { TransportRepository } from '@/tracking/domain/typetransport/TransportRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class TransportServices implements TransportRepository {
  private readonly API_BASE_URI: string = localStorageModule().tracking.host.API_BASE_URI;
  private readonly API_TRANSPORT_FINDALL: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_TRANSPORT_FINDALL;
  async findAll(): Promise<Transport[]> {
    try {
      return await http.get<Transport[]>(this.API_TRANSPORT_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
