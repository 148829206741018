
import Vue from 'vue';
import Component from 'vue-class-component';
import { ValidationObserver } from 'vee-validate/dist/types/components';
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { RoleFindAll } from '@/settings/application/uses_cases/role/search/RoleFindAll';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'AddRole'
})
export default class AppAddRole extends Vue {
  @Inject(TYPES.FINDALL_ROLE) readonly roleFindAll!: RoleFindAll;

  //Data
  isTouched = false;
  private form: any = {
    roleName: '',
    roleSelect: []
  };
  //Prop
  @Prop() readonly activeAdd!: boolean;
  @Prop() readonly roleList!: any;
  //Refs
  $refs!: {
    validationRole: InstanceType<typeof ValidationObserver>;
    roleName: HTMLFormElement;
  };
  //Hook
  created() {
    this.form.roleSelect = { id: '002', name: 'ROLE_ADMIN' };
  }
  //Computed
  get saveRole() {
    return this.$emit('submitRole', this.form.roleName);
  }
  //Methods
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  getMenuByRol() {
    this.$emit('searchMenu', this.form.roleSelect.id);
  }
  clearForm() {
    this.form = {
      roleName: '',
      roleSelect: [],
      roleList: []
    };
    this.$nextTick(() => this.$refs.validationRole.reset());
  }
}
