import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { Person } from '@/settings/domain/person/Person';
import { PersonRepository } from '@/settings/domain/person/PersonRepository';

@Injectable()
export class CreatePerson extends Command<Promise<Person>, Person> {
  public constructor(
    @Inject(TYPES.PERSON_REPOSITORY)
    private readonly personRepository: PersonRepository
  ) {
    super();
  }

  async internalExecute(person: Person): Promise<Person> {
    return await this.personRepository.create(person);
  }
}
