
import Vue from 'vue';
import Component from 'vue-class-component';
import ListDocTransactional from '@/wms/infrastructure/ui/docs/ListDocTransactional.vue';
import { Order } from '@/courier/domain/order/Order';
import { currencyFormatter } from '@/core/formatter/CurrencyFormatter';

@Component({
  name: 'TypeDocumentOrder',
  components: { ListDocTransactional }
})
export default class TypeDocumentOrder extends Vue {
  //GETTERS
  get fields() {
    return [
      { field: 'number', label: `#`, sortable: true },
      { field: 'typeOrder', label: this.$t('general.typeOrder'), sortable: true },
      { field: 'concept', label: this.$t('general.concept'), sortable: true },
      { field: 'date', label: this.$t('general.date'), sortable: true },
      { field: 'customerName', label: this.$t('general.entity'), sortable: true },
      { field: 'warehouseName', label: this.$t('general.warehouse'), sortable: true },
      {
        field: 'total',
        label: this.$t('general.total'),
        sortable: true,
        formatFn: (value: any) => currencyFormatter.format({ value: value, currency: 'USD' })
      },
      { field: 'actions', label: this.$t('general.actions'), sortable: false }
    ];
  }
  items: Order[] = [];
  isLoading = false;
  fullPage = true;

  refreshItems(object: Order) {
    this.items.push(object);
  }
}
