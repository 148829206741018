
import Vue from 'vue';
import Component from 'vue-class-component';
import BreadCrumbs from '@/core/components/dashboard/BreadCrumbs.vue';
import Swal from 'sweetalert2';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { FindAllContainers } from '@/settings/application/uses_cases/containers/search/FindAllContianers';
import { Containers } from '@/settings/domain/containers/Containers';
import { ValidationObserver } from 'vee-validate/dist/types/components';
import { ConceptsContainer } from '@/settings/domain/containers/conceptsContainer/ConceptsContainer';
import { CreateConceptContainer } from '@/settings/application/uses_cases/containers/conceptsContainer/create/CreateConceptContainer';
import { FindContainerById } from '@/settings/application/uses_cases/containers/search/FindContainerById';
import { UpdateContainer } from '@/settings/application/uses_cases/containers/update/UpdateContainer';
import Draggable from 'vuedraggable';
import { UpdateConceptContainer } from '@/settings/application/uses_cases/containers/conceptsContainer/update/UpdateConceptContainer';
import { showAlert } from '@/core/plugins/Notifications';
import { PackingUnits } from '@/settings/domain/packing_units/PackingUnits';
import { FindAllPackingUnits } from '@/settings/application/uses_cases/packing_units/search/FindAllPackingUnits';
import { FindPackingUnitById } from '@/settings/application/uses_cases/packing_units/search/FindPackingUnitById';
import { UpdatePackingUnits } from '@/settings/application/uses_cases/packing_units/update/UpdatePackingUnits';
import { DeleteConceptContainer } from '@/settings/application/uses_cases/containers/conceptsContainer/delete/DeleteConceptContainer';

@Component({
  name: 'ContainerInspection',
  components: { BreadCrumbs, Draggable }
})
export default class ContainerInspection extends Vue {
  @Inject(TYPES.FIND_ALL_CONTAINERS)
  readonly findAllContainers!: FindAllContainers;
  @Inject(TYPES.FIND_CONTAINER_BY_ID)
  readonly findContainerById!: FindContainerById;
  @Inject(TYPES.UPDATE_CONTAINER)
  readonly updateContainer!: UpdateContainer;
  @Inject(TYPES.CREATE_CONCEPT_CONTAINER)
  readonly createConceptContainer!: CreateConceptContainer;
  @Inject(TYPES.UPDATE_CONCEPT_CONTAINER)
  readonly updateConceptContainer!: UpdateConceptContainer;
  @Inject(TYPES.FIND_ALL_PACKING_UNITS)
  readonly getAllPackingUnits!: FindAllPackingUnits;
  @Inject(TYPES.FIND_PACKING_UNITS_BY_ID)
  readonly findPackingUnitById!: FindPackingUnitById;
  @Inject(TYPES.UPDATE_PACKING_UNITS)
  readonly updatePackingUnit!: UpdatePackingUnits;
  @Inject(TYPES.DELETE_CONCEPT_CONTAINER)
  readonly deleteConceptContainer!: DeleteConceptContainer;

  isLoading = false;
  isEditingConcept = false;
  isDetailConcept = false;
  popoverValue = false;
  popoverConceptual = false;
  containers: Containers[] = [];
  optionSelected: Containers | PackingUnits | null = null;
  listValues: string[] = [];
  listConceptual: string[] = [];
  packingUnits: PackingUnits[] = [];
  isFreight = false;

  // form
  conceptForm: ConceptsContainer = new ConceptsContainer();

  // refs
  $refs!: {
    concepts_form: InstanceType<typeof ValidationObserver>;
  };

  //Methods
  created() {
    this.findContainers();
  }

  get inspectionTypes() {
    return [
      { text: `${this.$t('general.indoor')}`, value: 'indoor' },
      { text: `${this.$t('general.outdoor')}`, value: 'outdoor' }
    ];
  }

  get evaluationMethods() {
    return [
      { text: `${this.$t('general.value')}`, value: 'value' },
      { text: `${this.$t('general.conceptual')}`, value: 'conceptual' }
    ];
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  customContainerLabel(container: Containers) {
    return `${container.id} - ${container.description}`;
  }

  customPackingUnitLabel(packingUnit: PackingUnits) {
    return `${packingUnit.id} - ${packingUnit.description}`;
  }

  addValues(value: string) {
    const newValue = value;
    this.listValues.push(newValue);
    this.conceptForm.listValues.push(newValue);
  }

  removeValues(value: string) {
    this.listValues = this.listValues.filter((item: string) => item !== value);
  }

  addConceptuals(concept: string) {
    const newConcept = concept;
    this.listConceptual.push(newConcept);
    this.conceptForm.listConceptual.push(newConcept);
  }

  removeConceptuals(concept: string) {
    this.listConceptual = this.listConceptual.filter((item: string) => item !== concept);
  }

  onChangeMethod(input: any) {
    switch (input) {
      case 'value':
        this.popoverConceptual = false;
        break;
      case 'conceptual':
        this.popoverValue = false;
        break;
      default:
        this.popoverValue = false;
        this.popoverConceptual = false;
        break;
    }
  }

  loadConceptData(concept: ConceptsContainer) {
    this.listValues = JSON.parse(concept.listValues as any);
    this.listConceptual = JSON.parse(concept.listConceptual as any);
    this.conceptForm = {
      ...concept,
      listValues: JSON.parse(concept.listValues as any),
      listConceptual: JSON.parse(concept.listConceptual as any)
    };

    switch (concept.evaluationMethod) {
      case 'value':
        this.popoverConceptual = false;
        this.popoverValue = true;
        break;
      case 'conceptual':
        this.popoverValue = false;
        this.popoverConceptual = true;
        break;
      default:
        this.popoverValue = false;
        this.popoverConceptual = false;
        break;
    }
  }

  async findContainers() {
    try {
      this.isLoading = true;

      const allContainers = await this.findAllContainers.execute();
      if ('error' in allContainers || 'message' in allContainers) {
        this.isLoading = false;
        return;
      }

      this.containers = allContainers;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async findAllPackingUnits() {
    this.isLoading = true;
    await this.getAllPackingUnits
      .execute()
      .then((response: PackingUnits[]) => {
        if (response) {
          this.packingUnits = response.reverse();
        }
        this.isLoading = false;
      })
      .catch(err => {
        this.isLoading = false;
        throw new Error(`${err}`);
      });
  }

  async findContainerConcepts(option: Containers | PackingUnits | any) {
    try {
      this.isLoading = true;

      if (!this.isFreight) {
        const containerResponse = await this.findContainerById.execute(option.id);
        if ('error' in containerResponse || 'message' in containerResponse) {
          this.isLoading = false;
          return;
        }

        if (this.optionSelected) this.optionSelected.concepts = containerResponse.concepts;
      } else {
        const packingUnitResponse = await this.findPackingUnitById.execute(option.id);
        if ('error' in packingUnitResponse || 'message' in packingUnitResponse) {
          this.isLoading = false;
          return;
        }

        if (this.optionSelected) this.optionSelected.concepts = packingUnitResponse.concepts;
      }

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async createConcept() {
    try {
      this.isLoading = true;
      this.optionSelected?.concepts.sort((a: any, b: any) => a.sequence - b.sequence);
      const payload: any = {
        ...this.conceptForm,
        isFreight: this.isFreight,
        container: !this.isFreight ? this.optionSelected : null,
        packingUnits: this.isFreight ? this.optionSelected : null,
        listValues: JSON.stringify(this.conceptForm.listValues),
        listConceptual: JSON.stringify(this.conceptForm.listConceptual)
      };

      const saveConcept = await this.createConceptContainer.execute(payload);
      if ('error' in saveConcept || 'message' in saveConcept) {
        this.isLoading = false;
        return;
      }

      this.clearForm();
      await this.findContainerConcepts(!this.isFreight ? saveConcept.container : saveConcept.packingUnits);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async updateConcept() {
    try {
      this.isLoading = true;
      this.optionSelected?.concepts.sort((a: any, b: any) => a.sequence - b.sequence);
      const payload: any = {
        ...this.conceptForm,
        container: !this.isFreight ? this.optionSelected : null,
        packingUnits: this.isFreight ? this.optionSelected : null,
        listValues: JSON.stringify(this.conceptForm.listValues),
        listConceptual: JSON.stringify(this.conceptForm.listConceptual)
      };

      const updateConcept = await this.updateConceptContainer.execute(payload);
      if ('error' in updateConcept || 'message' in updateConcept) {
        this.isLoading = false;
        return;
      }

      this.clearForm();
      await this.findContainerConcepts(!this.isFreight ? updateConcept.container : updateConcept.packingUnits);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async updateConceptsOrder() {
    try {
      this.isLoading = true;
      this.optionSelected?.concepts.map((concept: ConceptsContainer, index: number) => (concept.sequence = index + 1));
      this.optionSelected?.concepts.sort((a: any, b: any) => a.sequence - b.sequence);

      const updateOrder = !this.isFreight
        ? await this.updateContainer.execute(this.optionSelected as Containers)
        : await this.updatePackingUnit.execute(this.optionSelected as PackingUnits);

      if ('error' in updateOrder || 'message' in updateOrder) {
        this.isLoading = false;
        return;
      }

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  factoryConcept() {
    if (
      (this.conceptForm.evaluationMethod === 'value' && this.conceptForm.listValues.length > 0) ||
      (this.conceptForm.evaluationMethod === 'conceptual' && this.conceptForm.listConceptual.length > 0)
    ) {
      !this.isEditingConcept ? this.createConcept() : this.updateConcept();
    } else {
      showAlert(
        'warning',
        this.$t('generalmessage.ERROR_EMPTY_LIST', {
          list:
            this.conceptForm.evaluationMethod === 'value'
              ? this.$t('general.listValues')
              : this.$t('general.listConceptual')
        })
      );
    }
  }

  clearForm() {
    this.conceptForm = new ConceptsContainer();
    this.isEditingConcept = false;
    this.isDetailConcept = false;
    this.popoverValue = false;
    this.popoverConceptual = false;
    this.$nextTick(() => {
      this.$refs.concepts_form.reset();
    });
  }

  onChangeCheckBox(value: boolean) {
    if (value) {
      this.optionSelected = null;
      this.conceptForm = new ConceptsContainer();
      !this.packingUnits.length && this.findAllPackingUnits();
    } else {
      this.optionSelected = null;
      this.conceptForm = new ConceptsContainer();
    }
  }

  removeConceptModal(concept: ConceptsContainer) {
    Swal.fire({
      title: `${this.$t('general.areYouSure')}`,
      text: `${this.$t('general.deleteModalText')}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `${this.$t('general.confirmDeleteButton')}`,
      cancelButtonText: `${this.$t('general.cancel')}`
    }).then(result => {
      if (result.isConfirmed) {
        this.removeConcept(concept)
          .then(() => {
            Swal.fire('Deleted!', 'Your item has been deleted.', 'success');
          })
          .catch(() => {
            Swal.fire('Error', 'An error occurred while deleting.', 'error');
          });
      }
    });
  }

  async removeConcept(concept: ConceptsContainer) {
    this.isLoading = true;
    try {
      await this.deleteConceptContainer.execute(concept.conceptCode);
      if (this.optionSelected !== null) {
        const deleteFromOptions = this.optionSelected?.concepts.indexOf(concept);
        if (deleteFromOptions !== -1) {
          this.optionSelected?.concepts.splice(deleteFromOptions, 1);
        }
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
}
