
import CustomTable from '@/core/components/shared/CustomTable.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import AddProductivityCenter from '@/settings/infrastructure/ui/components/productivity_center/AddProductivityCenter.vue';

@Component({
  name: 'ProductivityCenter',
  components: { CustomTable, AddProductivityCenter }
})
export default class ProductivityCenter extends Vue {
  //Data
  isLoading = false;
  fullPage = true;

  buttonOptions = {
    variant: 'success',
    buttonTitle: 'general.add',
    icon: 'fa-plus',
    isEdit: false
  };

  form: any = {
    id: '',
    description: '',
    active: false
  };

  actions: any = {
    edit: this.editAction,
    delete: this.deleteAction,
    clean: this.cleanForm
  };

  factory = {
    actions: this.factoryMethod,
    find: this.findItemByCode
  };

  items: any = [];
  //Getters
  get tableFields() {
    return [
      {
        key: 'id',
        label: 'Id'
      },
      {
        key: 'description',
        label: 'Description'
      },
      {
        key: 'active',
        label: 'Estado'
      },
      {
        key: 'actions',
        label: 'Actions',
        class: 'col-md-4'
      }
    ];
  }

  //Methods
  factoryMethod(data: any) {
    if (!this.buttonOptions.isEdit) {
      this.addCenter(data);
    } else {
      this.editCenter();
    }
  }

  addCenter(data: any) {
    this.form = {
      id: '',
      description: '',
      active: false
    };
    this.items.push(data as any[]);
  }

  editCenter() {
    this.findItemByCode();
  }

  deleteAction(data: any) {
    this.items = this.items.filter((item: any) => item.id != data.id);
  }

  editAction() {
    this.findItemByCode();
    this.buttonOptions = {
      isEdit: true,
      variant: 'secondary',
      buttonTitle: 'general.edit',
      icon: 'fa-save'
    };
  }

  cleanForm() {
    this.form = {
      id: '',
      description: '',
      active: false
    };

    this.buttonOptions = {
      isEdit: false,
      variant: 'success',
      buttonTitle: 'general.add',
      icon: 'fa-plus'
    };
  }

  findItemByCode() {
    const res = this.items.filter((item: any) => item.id === this.form.id)[0] || this.form;
    this.form = {
      id: res.id,
      description: res.description,
      active: res.active
    };
  }
}
