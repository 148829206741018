import { Injectable } from '@/core/di/Injectable';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { ReportConfigRepository } from '@/wms/domain/reports/ReportConfigRepository';
import { Command } from '@/core/cqrs/Command';
import { Report } from '@/wms/domain/reports/ReportConfig';

/**
 * @class FindByCategoryReports
 * @description Clase que permite encontrar todos los reportes por categoria
 * @version 1.0.0
 * @author Mauricio Cantillo
 */
@Injectable()
export class FindByCategoryReports extends Command<Promise<Report[]>, string> {
  public constructor(
    @Inject(TYPES.REPORT_REPOSITORY)
    readonly repository: ReportConfigRepository
  ) {
    super();
  }

  public async internalExecute(category: string): Promise<Report[]> {
    return await this.repository.findByCategory(category);
  }
}
