
import { CreateCloudConfig } from '@/settings/application/uses_cases/cloudConfig/create/CreateCloudConfig';
import { FindByCompanyIdCloudConfig } from '@/settings/application/uses_cases/cloudConfig/search/FindByCompanyIdCloudConfig';
import { CloudConfig } from '@/settings/domain/cloudConfig/CloudConfig';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { ValidationObserver } from 'vee-validate/dist/types/components';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'IntegrationsForm',
  components: {}
})
export default class IntegrationsForm extends Vue {
  @Inject(TYPES.CLOUD_CONFIG_SAVE)
  readonly createCloudConfig!: CreateCloudConfig;
  @Inject(TYPES.CLOUD_FIND_BY_COMPANY_ID)
  readonly findByCompanyIdCloudConfig!: FindByCompanyIdCloudConfig;

  @Prop() integrations!: CloudConfig;
  @Prop({ type: Function }) submitIntegrations!: Function;
  @Prop({ type: Function }) getValidationState!: Function;

  isLoading = false;

  integrationsConfig: CloudConfig[] = [];

  $refs!: {
    integrationsForm: InstanceType<typeof ValidationObserver>;
  };

  get integrationObj() {
    return this.integrations;
  }

  set integrationObj(newValue: CloudConfig) {
    this.$emit('update:integrations', newValue);
  }

  get integrationTypes() {
    return ['google_storage'];
  }

  mounted() {
    this.findCloudConfig();
  }

  handleModalIntegrations(event: string) {
    this.integrationObj = new CloudConfig();

    if (event === 'open') {
      this.findCloudConfig();
    }
  }

  async checkIntegrationsForm() {
    return await this.$refs.integrationsForm.validate();
  }

  async handleOkModal(bvModalEvent: any) {
    bvModalEvent.preventDefault();
    if (!(await this.checkIntegrationsForm())) return;
    if (await this.saveCloudConfig()) this.submitIntegrations();
  }

  // Metodo para guardar la configuracion del cloud por empresa
  async saveCloudConfig() {
    try {
      this.isLoading = true;
      const payload = {
        ...this.integrationObj,
        company: Number(localStorage.getItem('businessId'))
      };

      const res = await this.createCloudConfig.internalExecute(payload);
      this.isLoading = false;

      return !('error' in res);
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  // Metodo para recibir las diferentes integraciones por empresa
  async findCloudConfig() {
    try {
      this.isLoading = true;
      const id = Number(localStorage.getItem('businessId'));
      const res = await this.findByCompanyIdCloudConfig.execute(id);

      if (res.length > 0) {
        this.integrationsConfig = res;
      }

      this.isLoading = false;
      return res;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  findIntegrationByType(type: string) {
    try {
      this.isLoading = true;
      const res = this.integrationsConfig.find(item => item.typeIntegration === type);

      if (res) {
        this.integrationObj = res;
      }

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
}
