
import { PackingUnit } from '@/wms/domain/packingUnit/PackingUnit';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
@Component({
  name: 'AddPackingUnits'
})
export default class AddPackingUnits extends Vue {
  @Prop() form!: PackingUnit;
  @Prop() readonly formFunctions!: any;
  @Prop() readonly buttonOptions!: any;

  //FUNCTIONS
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
}
