import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { EventTransactional } from '@/tracking/domain/eventsTransactional/EventTransactional';
import { EventTransactionalRepository } from '@/tracking/domain/eventsTransactional/EventTransactionalRepository';
@Injectable()
export class TransactionalEventService implements EventTransactionalRepository {
  private readonly API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;
  private readonly API_TRANSACTIONAL_EVENT_FIND_BY_PK: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_TRANSACTIONAL_EVENT_FIND_BY_PK;

  async findEventByPk({
    eventId,
    preRegisterNumber,
    preRegisterType
  }: {
    eventId: number;
    preRegisterNumber: number;
    preRegisterType: string;
  }): Promise<EventTransactional> {
    try {
      const url = `${this.API_TRANSACTIONAL_EVENT_FIND_BY_PK}eventId=${eventId}&preRegisterNumber=${preRegisterNumber}&preRegisterType=${preRegisterType}`;
      return await http.get<EventTransactional>(url);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
