import { Rack } from '@/wms/domain/layout/rack/Rack';

export class ResizingZone {
  id: number;
  storeId: number;
  zoneId: number;
  zoneDescription: string;
  left: string;
  top: number;
  height: number;
  width: number;
  maxW: number;
  maxH: number;
  minW: number;
  minH: number;
  fit: boolean;
  state: boolean;
  dragSelector: string;
  rack: Rack[];
  windowWidth: number;
  windowHeight: number;

  constructor() {
    this.id = 0;
    this.zoneId = 0;
    this.zoneDescription = '';
    this.left = `calc( 50% - ${150 / 2}px)`;
    this.top = 0;
    this.height = 100;
    this.width = 100;
    this.maxW = 9999;
    this.maxH = 9999;
    this.minW = 10;
    this.minH = 10;
    this.fit = true;
    this.dragSelector = '.drag-container-1, .drag-container-2';
    this.rack = [];
    this.state = false;
    this.storeId = 0;
    this.windowWidth = 0;
    this.windowHeight = 0;
  }
}
