import { AddressLine } from '../../address/AddressLine';

export class Bank {
  id: number;
  name: string;
  code: string;
  iban: string;
  aba: string;
  swift: string;
  address: AddressLine;

  public constructor() {
    this.id = 0;
    this.name = '';
    this.code = '';
    this.iban = '';
    this.aba = '';
    this.swift = '';
    this.address = new AddressLine();
  }
}
