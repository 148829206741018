import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Command } from '@/core/cqrs/Command';
import { Company } from '@/settings/domain/company/Company';
import { CompanyRepository } from '@/settings/domain/company/CompanyRepository';

@Injectable()
export class CompanyUpdate extends Command<Promise<any>, Company> {
  public constructor(
    @Inject(TYPES.COMPANY_REPOSITORY)
    private readonly companyRepository: CompanyRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(company: Company): Promise<any> {
    return await this.companyRepository.update(company);
  }
}
