import { httpMethod } from '../exception/httpMethodsEnum';
import { errorMessage } from '../exception/NewNotificationException';

const header: any = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

const get = async <T>(url: string, triggerAlert = true) => {
  header['Authorization'] = 'Bearer ' + localStorage.getItem('accessTokenCompany');
  const response = await fetch(url, {
    method: 'GET',
    headers: header
  });
  if (triggerAlert) {
    errorMessage(response, httpMethod.GET);
  }
  if (!response.ok) {
    throw new Error('Error al consultar el recurso solicitado');
  }
  return (await response.json()) as T;
};

const getText = async (url: string, triggerAlert = true) => {
  header['Authorization'] = 'Bearer ' + localStorage.getItem('accessTokenCompany');
  const response = await fetch(url, {
    method: 'GET',
    headers: header
  });
  if (triggerAlert) {
    errorMessage(response, httpMethod.GET);
  }
  if (!response.ok) {
    throw new Error('Error al descargar el archivo');
  }
  return await response.text();
};

const getFile = async <T>(url: string, typeFile: string, triggerAlert = true) => {
  header['Authorization'] = 'Bearer ' + localStorage.getItem('accessTokenCompany');
  header['accept'] = ['application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  const response = await fetch(url, {
    method: 'GET',
    headers: header
  });
  if (triggerAlert) {
    errorMessage(response, httpMethod.GET);
  }
  if (!response.ok) {
    throw new Error('Error al descargar el archivo');
  }

  if (!response.ok) {
    throw new Error('Error al descargar el archivo');
  }
  return (response as unknown) as T;
};
const post = async <T>(url: string, body: any, triggerAlert = true) => {
  if (localStorage.getItem('accessTokenCompany') !== null) {
    header['Authorization'] = 'Bearer ' + localStorage.getItem('accessTokenCompany');
  }
  const response = await fetch(url, {
    method: 'POST',
    headers: header,
    body
  });
  if (triggerAlert) {
    errorMessage(response, httpMethod.POST, body);
  }
  if (!response.ok) {
    // Debemos devolver el error que nos envia el servidor junto con el código de error
    const error = await response.json();
    throw new Error(error.message);
  }
  return ((await response.json()) as unknown) as T;
};

const postFile = async <T>(url: string, body: any, triggerAlert = true) => {
  if (localStorage.getItem('accessTokenCompany') !== null) {
    header['Authorization'] = 'Bearer ' + localStorage.getItem('accessTokenCompany');
  }
  const response = await fetch(url, {
    method: 'POST',
    headers: header,
    body
  });
  if (triggerAlert) {
    errorMessage(response, httpMethod.POST, body);
  }
  if (!response.ok) {
    throw new Error('Error al cargar el archivo');
  }
  return ((await response.blob()) as unknown) as T;
};
const put = async <T>(url: string, body: any, triggerAlert = true) => {
  header['Authorization'] = 'Bearer ' + localStorage.getItem('accessTokenCompany');
  const response = await fetch(url, {
    method: 'PUT',
    headers: header,
    body
  });

  if (triggerAlert) {
    errorMessage(response, httpMethod.PUT, body);
  }
  if (!response.ok) {
    throw new Error('Error al descargar el archivo');
  }

  return (response as unknown) as T;
};

const _put = async <T>(url: string, body: any, triggerAlert = true) => {
  if (localStorage.getItem('accessTokenCompany') !== null) {
    header['Authorization'] = 'Bearer ' + localStorage.getItem('accessTokenCompany');
  }
  const response = await fetch(url, {
    method: 'PUT',
    headers: header,
    body
  });
  if (triggerAlert) {
    errorMessage(response, httpMethod.PUT, body);
  }
  if (!response.ok) {
    throw new Error('Error al modificar el registro');
  }
  return (await response.json()) as T;
};
const VOID_PUT = async <T>(url: string, body: any, triggerAlert = true) => {
  if (localStorage.getItem('accessTokenCompany') !== null) {
    header['Authorization'] = 'Bearer ' + localStorage.getItem('accessTokenCompany');
  }
  const response = await fetch(url, {
    method: 'PUT',
    headers: header,
    body
  });
  if (triggerAlert) {
    errorMessage(response, httpMethod.PUT, body);
  }
  if (!response.ok) {
    throw new Error('Error al modificar el registro');
  }
};
const VOID_PATCH = async <T>(url: string, body: any, triggerAlert = true) => {
  if (localStorage.getItem('accessTokenCompany') !== null) {
    header['Authorization'] = 'Bearer ' + localStorage.getItem('accessTokenCompany');
  }
  const response = await fetch(url, {
    method: 'PATCH',
    headers: header,
    body
  });
  if (triggerAlert) {
    errorMessage(response, httpMethod.PATCH, body);
  }
  if (!response.ok) {
    throw new Error('Error al modificar el registro');
  }
};

const VOID_POST = async <T>(url: string, body: any, triggerAlert = true) => {
  if (localStorage.getItem('accessTokenCompany') !== null) {
    header['Authorization'] = 'Bearer ' + localStorage.getItem('accessTokenCompany');
  }
  const response = await fetch(url, {
    method: 'POST',
    headers: header,
    body
  });
  if (triggerAlert) {
    errorMessage(response, httpMethod.POST, body);
  }
  if (!response.ok) {
    throw new Error('Error al modificar el registro');
  }
};

const _delete = async <T>(url: string, body: any, triggerAlert = true) => {
  header['Authorization'] = 'Bearer ' + localStorage.getItem('accessTokenCompany');
  const response = await fetch(url, {
    method: 'DELETE',
    headers: header,
    body
  });
  if (triggerAlert) {
    errorMessage(response, httpMethod.DELETE, body);
  }
  if (!response.ok) {
    throw new Error('Error al descargar el archivo');
  }
  return (await response.json()) as T;
};
const VOID_DELETE = async <T>(url: string, body: any, triggerAlert = true) => {
  header['Authorization'] = 'Bearer ' + localStorage.getItem('accessTokenCompany');
  const response = await fetch(url, {
    method: 'DELETE',
    headers: header,
    body
  });
  if (triggerAlert) {
    errorMessage(response, httpMethod.DELETE, body);
  }
  if (!response.ok) {
    throw new Error('Error al descargar el archivo');
  }
};

const _singleDelete = async <T>(url: string, triggerAlert = true) => {
  header['Authorization'] = 'Bearer ' + localStorage.getItem('accessTokenCompany');
  const response = await fetch(url, {
    method: 'DELETE',
    headers: header
  });
  if (triggerAlert) {
    errorMessage(response, httpMethod.DELETE);
  }
  if (!response.ok) {
    throw new Error('Error al descargar el archivo');
  }
  return (await response.json()) as T;
};

const _getERP = async <T>(url: string, triggerAlert = true) => {
  header['Authorization'] = 'Bearer ' + localStorage.getItem('accessTokenERP');
  header['UserId'] = localStorage.getItem('userIdERP');
  header['Content-Type'] = 'application/json';
  const response = await fetch(url, {
    method: 'GET',
    headers: header
  });
  if (triggerAlert) {
    errorMessage(response, httpMethod.GET);
  }
  return (await response.json()) as T;
};

const _postERP = async <T>(url: string, body: any, triggerAlert = true) => {
  header['Authorization'] = 'Bearer ' + localStorage.getItem('accessTokenERP');
  header['UserId'] = localStorage.getItem('userIdERP');
  header['Content-Type'] = 'application/json';
  const response = await fetch(url, {
    method: 'POST',
    headers: header,
    body
  });
  if (triggerAlert) {
    errorMessage(response, httpMethod.POST, body);
  }
  return (await response.json()) as T;
};

const _putERP = async <T>(url: string, body: any, triggerAlert = true) => {
  header['Authorization'] = 'Bearer ' + localStorage.getItem('accessTokenERP');
  header['UserId'] = localStorage.getItem('userIdERP');
  header['Content-Type'] = 'application/json';
  const response = await fetch(url, {
    method: 'PUT',
    headers: header,
    body
  });
  if (triggerAlert) {
    errorMessage(response, httpMethod.PUT, body);
  }
  return (await response.json()) as T;
};

const postFormData = async <T>(url: string, formData: FormData, triggerAlert = true) => {
  const headers: any = {};
  headers['Authorization'] = 'Bearer ' + localStorage.getItem('accessTokenCompany');
  const response = await fetch(url, {
    method: 'POST',
    headers: headers,
    body: formData
  });
  if (triggerAlert) {
    errorMessage(response, httpMethod.PUT, formData.values());
  }
  if (!response.ok) {
    throw new Error('Error al descargar el archivo');
  }
  return (await response.json()) as T;
};

export const http = {
  get,
  getText,
  post,
  postFile,
  put,
  _put,
  delete: _delete,
  singleDelete: _singleDelete,
  _postERP,
  _putERP,
  _getERP,
  postFormData,
  getFile,
  VOID_PUT,
  VOID_POST,
  VOID_DELETE,
  VOID_PATCH
};
