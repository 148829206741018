import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { ConsigneeRepository } from '@/settings/domain/consignee/ConsigneeRepository';

@Injectable()
export class FindConsigneeByOperation extends Query<Promise<string[]>, { number: number; type: string }> {
  public constructor(
    @Inject(TYPES.CONSIGNEE_REPOSITORY)
    private readonly repository: ConsigneeRepository
  ) {
    super();
  }
  async internalExecute(payload: { number: number; type: string }): Promise<string[]> {
    return await this.repository.findConsigneeByOperation(payload);
  }
}
