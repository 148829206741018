import { TYPES } from '@/core/config/Types';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { City } from '@/settings/domain/city/City';
import { CityRepository } from '@/settings/domain/city/CityRepository';
import { Command } from '@/core/cqrs/Command';

@Injectable()
export class CreateCity extends Command<Promise<City>, City> {
  public constructor(
    @Inject(TYPES.CITY_REPOSITORY)
    private readonly cityServie: CityRepository
  ) {
    super();
  }

  async internalExecute(city: City): Promise<any> {
    return await this.cityServie.create(city);
  }
}
