
import { TYPES } from '@/core/config/Types';
import { GetTemporalToken } from '@/courier/application/uses_cases/login/search/findtoken/GetTemporalToken';
import { GetWorkspaces } from '@/courier/application/uses_cases/login/search/findtoken/GetWorkspaces';
import { LoginWithWorkspace } from '@/courier/application/uses_cases/login/search/findtoken/LoginWithWorkspace';
import { Workspace } from '@/courier/domain/workspace/Workspace';
import { FindFile } from '@/settings/application/uses_cases/file/search/FindFile';
import { Inject } from 'inversify-props';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({ name: 'WorkspaceSelection' })
export default class WorkspaceSelection extends Vue {
  @Inject(TYPES.GET_TEMPORAL_TOKEN)
  readonly getToken!: GetTemporalToken;
  @Inject(TYPES.LOGIN_GET_WORKSPACES)
  readonly getWorkspaces!: GetWorkspaces;
  @Inject(TYPES.FIND_FILE_BY_NAME)
  readonly findFileByName!: FindFile;
  @Inject(TYPES.LOGIN_WITH_WORKSPACE)
  readonly loginWithWorkspace!: LoginWithWorkspace;

  isLoading = false;

  mounted() {
    if (!this.getToken.internalExecute()) {
      this.$router.push({ name: 'LoginN' });
      return;
    }
    this.getData();
  }

  getlogo(url: string) {
    return this.findFileByName.execute(url);
  }

  workspaces: Workspace[] = [];

  async selectWorkspace(workspace: Workspace) {
    try {
      this.isLoading = true;
      await this.loginWithWorkspace.internalExecute(workspace);
    } catch (error) {
      this.$toast.error(this.$t('general.error'));
    } finally {
      this.isLoading = false;
    }
  }

  async getData() {
    try {
      this.isLoading = true;
      const response = await this.getWorkspaces.internalExecute();
      this.workspaces = response;

      // Si solo hay un workspace, se selecciona automáticamente
      if (this.workspaces.length === 1) {
        this.selectWorkspace(this.workspaces[0]);
      }
    } catch (error) {
      this.workspaces = [];
    } finally {
      this.isLoading = false;
    }
  }
}
