import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { LoginImageConfig } from '@/settings/domain/loginImageConfig/LoginImageConfig';
import { LoginImageConfigRepository } from '@/settings/domain/loginImageConfig/LoginImageConfigRepository';

@Injectable()
export class SaveLoginImageConfig extends Command<Promise<LoginImageConfig>, LoginImageConfig> {
  public constructor(
    @Inject(TYPES.LOGIN_IMAGE_CONFIG_REPOSITORY)
    private readonly repository: LoginImageConfigRepository
  ) {
    super();
  }
  async internalExecute(config: LoginImageConfig): Promise<LoginImageConfig> {
    return await this.repository.saveConfig(config);
  }
}
