import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Invoice } from '@/courier/domain/invoice/Invoice';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { InvoiceRepository } from '@/courier/domain/invoice/InvoiceRepository';
import { Query } from '@/core/cqrs/Query';

@Injectable()
export class InvoiceFindByDateRange extends Query<Promise<Invoice[]>, any> {
  public constructor(
    @Inject(TYPES.INVOICE_REPOSITORY)
    private readonly invoiceRepository: InvoiceRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(params: any): Promise<Invoice[]> {
    const dataInvoice = await this.invoiceRepository.findByDateRange(params);
    this.stateManager.patch({ dataInvoice });
    return this.stateManager.state.dataInvoice;
  }
}
