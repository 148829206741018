import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StockRepository } from '@/wms/domain/stock/StockRepository';
import { Stock } from '@/wms/domain/stock/Stock';
@Injectable()
export class FindStockByDateRangeAndStore {
  public constructor(
    @Inject(TYPES.STOCK_REPOSITORY)
    private readonly repository: StockRepository
  ) {}

  public async execute(fMonth: string, iMonth: string, store: number, year: number, code?: string): Promise<Stock[]> {
    return await this.repository.findStockByDateRangeAndStore(fMonth, iMonth, store, year, code);
  }
}
