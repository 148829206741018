import { TYPES } from '@/core/config/Types';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Query } from '@/core/cqrs/Query';
import { EventTransactional } from '@/tracking/domain/eventsTransactional/EventTransactional';
import { EventTransactionalRepository } from '@/tracking/domain/eventsTransactional/EventTransactionalRepository';

@Injectable()
export class TransactionalEventFindByPk extends Query<
  Promise<EventTransactional>,
  {
    eventId: number;
    preRegisterNumber: number;
    preRegisterType: string;
  }
> {
  public constructor(
    @Inject(TYPES.EVENT_TRANSACTIONAL_REPOSITORY)
    private readonly eventsRepository: EventTransactionalRepository
  ) {
    super();
  }
  async internalExecute(params: {
    eventId: number;
    preRegisterNumber: number;
    preRegisterType: string;
  }): Promise<EventTransactional> {
    return await this.eventsRepository.findEventByPk(params);
  }
}
