import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { SegmentationRepository } from '@/wms/domain/catalogs/segmentation/SegmentationRepository';
import { Segmentation } from '@/wms/domain/catalogs/segmentation/Segmentation';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class SegmentationService implements SegmentationRepository {
  private readonly API_BASE_URI: string = localStorageModule().wms.host.API_BASE_URI;
  private readonly API_SEGMENTATION_FINDALL: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_SEGMENTATION_FINDALL;
  private readonly API_SEGMENTATION_SAVE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_SEGMENTATION_SAVE;
  private readonly API_SEGMENTATION_UPDATE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_SEGMENTATION_UPDATE;

  async create(segmentation: Segmentation) {
    try {
      await http.post<Segmentation[]>(this.API_SEGMENTATION_SAVE, JSON.stringify(segmentation));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(zone: Segmentation) {
    try {
      await http.put<Segmentation[]>(this.API_SEGMENTATION_UPDATE, JSON.stringify(zone));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAll(): Promise<Segmentation[]> {
    try {
      return await http.get<Segmentation[]>(this.API_SEGMENTATION_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
