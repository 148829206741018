import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { PriceListFindByOriginAndDestination } from '@/settings/application/uses_cases/priceList/search/volume/PriceListFindByOriginAndDestination';
import { PriceList } from '@/settings/domain/priceList/PriceList';
import { RateResponse } from '@/utils/pricing/RateResponse';
import { PriceCalculate } from '@/utils/pricing/PriceCalculate';
import { OrderLine } from '@/courier/domain/orderline/OrderLine';
import { PriceListFindByOriginAndDestinationWeight } from '@/settings/application/uses_cases/priceList/search/weight/PriceListFindByOriginAndDestinationWeight';
import { PriceWeightCalculate } from './pricing/PriceWeightCalculate';
import { StateManager } from '@/core/statemanager/StateManager';

export class RateEngine {
  @Inject(TYPES.FINDBY_ORIGINANDDESTINATION_VOLUME)
  readonly findPriceListByOrigingAndDestination!: PriceListFindByOriginAndDestination;
  @Inject(TYPES.FINDBY_ORIGINANDDESTINATION_WEIGHT)
  readonly findWeightPriceListByOrigingAndDestination!: PriceListFindByOriginAndDestinationWeight;
  private engine: RateResponse;
  @Inject(TYPES.STATE_MANAGER)
  readonly stateManager!: StateManager;

  public constructor() {
    this.engine = new RateResponse();
  }

  async validatePriceList(payload: any) {
    let isValid = false;
    this.stateManager.state.dataPriceList = [];
    let arrayAux: any[] = [];
    let arrayAux2: any[] = [];
    await this.findPriceListByOrigingAndDestination
      .execute(payload)
      .then((response: Array<PriceList>) => {
        isValid = response.length > 0;
        if (isValid) {
          arrayAux = response;
        }
      })
      .catch(err => {
        isValid = false;
        throw new Error(`${err}`);
      });

    await this.findWeightPriceListByOrigingAndDestination
      .execute(payload)
      .then((response: Array<PriceList>) => {
        isValid = response.length > 0;
        if (isValid) {
          arrayAux2 = response;
        }
      })
      .catch(err => {
        isValid = false;
        throw new Error(`${err}`);
      });

    this.stateManager.state.dataPriceList = arrayAux.concat(arrayAux2);

    return arrayAux.length > 0 || arrayAux2.length > 0;
  }

  async calc(payload: any, packages: Array<OrderLine>) {
    await this.findPriceListByOrigingAndDestination
      .execute(payload)
      .then((response: Array<PriceList>) => {
        //flag to indicate that rates were found
        this.engine.status = true;
        //data initialization
        this.engine.data = [];
        //Iterator
        response.forEach(priceList => {
          //Calculate rate by transport type, air or ground or ocean.
          new PriceCalculate().calculate(priceList, packages).then((response: any) => {
            this.engine.data.push(response);
          });
        });
      })
      .catch(error => {
        //flag to indicate that rates were not found
        this.engine.status = false;
        this.engine.errorMessage = error;
      });
    return this.engine;
  }

  async weightCalc(payload: any, packages: Array<OrderLine>) {
    await this.findWeightPriceListByOrigingAndDestination
      .execute(payload)
      .then((response: Array<PriceList>) => {
        //flag to indicate that rates were found
        this.engine.status = true;
        //data initialization
        this.engine.data = [];
        //Iterator
        response.forEach(priceList => {
          //Calculate rate by transport type, air or ground or ocean.
          this.engine.data.push(new PriceWeightCalculate().calculate(priceList, packages));
        });
      })
      .catch(error => {
        //flag to indicate that rates were not found
        this.engine.status = false;
        this.engine.errorMessage = error;
      });
    return this.engine;
  }
}
