
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { CreateEvents } from '@/tracking/application/uses_cases/events/create/CreateEvents';
import { UpdateEvents } from '@/tracking/application/uses_cases/events/update/UpdateEvents';
import Swal from 'sweetalert2';
import i18n from '@/core/plugins/i18n';
import { sortDescending } from '@/core/plugins/FunctionUtil';
import { SwitchFindAllByProcessType } from '@/tracking/application/uses_cases/switches/search/SwitchFindAllByProcessType';
import { OperationFindAll } from '@/tracking/application/uses_cases/typeoperation/search/OperationFindAll';
import { EventsFindAllConfActive } from '@/tracking/application/uses_cases/events/search/EventsFindAllConfActive';

@Component({
  name: 'ManageEventsCourier'
})
export default class AppManageEventsCourier extends Vue {
  @Inject(TYPES.FINDALL_TYPE_OPERATION)
  readonly operationFindAll!: OperationFindAll;
  @Inject(TYPES.FINDALL_CONF_ACTIVE_EVENTS)
  readonly eventsFindAllConfActive!: EventsFindAllConfActive;
  @Inject(TYPES.FINDBYPROCESSTYPE_SWITCH)
  readonly switchFindAllByProcessType!: SwitchFindAllByProcessType;
  @Inject(TYPES.CREATE_EVENTS)
  readonly createEvents!: CreateEvents;
  @Inject(TYPES.UPDATE_EVENTS)
  readonly updateEvents!: UpdateEvents;
  //Data
  eventKey = 0;
  target = i18n.t('general.event');
  isLoading = false;
  fullPage = true;
  private eventsList: any = [
    {
      description: '',
      day: 0,
      typeOperation: [],
      handleSpreadAutomatically: false,
      visible: false
    }
  ];
  private formLineTime: any = {
    isTouched: false,
    arrayTypeOperation: [],
    arraySwitch: [],
    sw: [],
    disabledEvents: false,
    eventSelected: [],
    eventsListFilter: [],
    edit: null
  };
  //Refs
  $refs!: {
    eventDescription: HTMLInputElement;
    dropdown: HTMLFormElement;
  };
  //Hook
  mounted() {
    this.$nextTick(() => this.typeOperation);
    this.$nextTick(() => this.switch);
    this.$nextTick(() => this.getEvents());
  }
  //Computed
  get typeOperation() {
    this.operationFindAll.execute().then((response: any) => {
      this.formLineTime.arrayTypeOperation = response;
    });
    return true;
  }
  get switch() {
    this.switchFindAllByProcessType.execute(1).then((response: any) => {
      this.formLineTime.arraySwitch = response;
    });
    return true;
  }
  //method
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  getEvents() {
    this.isLoading = true;
    this.eventsFindAllConfActive
      .execute(true)
      .then((response: any) => {
        this.isLoading = false;
        const res = response.reverse();
        this.eventsList = sortDescending(response);
        this.formLineTime.sw = res;
        this.formLineTime.eventsListFilter = res;
        this.formLineTime.disabledEvents = true;
        for (const ev in response) {
          if (ev === '0') {
            this.eventsList[ev].visible = true;
          }
        }
        this.eventKey += 1;
      })
      .catch((error: any) => {
        this.isLoading = false;
        this.eventKey += 1;
        this.eventsList = [];
        throw new Error(error);
      });
  }
  editEvents(data: any) {
    this.formLineTime.edit = this.formLineTime.edit !== data.id ? data.id : null;
    data.typeOperation = {
      code: data.typeOperation,
      description: data.typeOperationName
    };
    this.formLineTime.sw = {
      sw: data.activities[0].sw,
      nature: data.activities[0].nature
    };
    this.formLineTime.disabledEvents = false;
  }
  saveEvent(index: number) {
    if (!this.eventsList[index].id) {
      const payloadSave: any = {
        description: this.eventsList[index].description,
        day: this.eventsList[index].day,
        handleSpreadAutomatically: false,
        typeOperation: this.eventsList[index].typeOperation.code,
        active: true,
        activities: [
          {
            description: this.eventsList[index].description,
            projectedDate: new Date().getTime(),
            required: true,
            spreadAutomatically: false,
            value: 0,
            active: true,
            sw: this.formLineTime.sw.sw,
            processType: 1
          }
        ]
      };
      this.isLoading = true;
      this.createEvents
        .execute(payloadSave)
        .then((response: any) => {
          this.isLoading = false;
          this.getEvents();
          return response;
        })
        .catch((error: any) => {
          this.isLoading = false;
          throw new Error(error);
        });
    } else {
      const payloadUpdate: any = {
        id: this.eventsList[index].id,
        description: this.eventsList[index].description,
        day: this.eventsList[index].day,
        handleSpreadAutomatically: false,
        typeOperation: this.eventsList[index].typeOperation.code,
        active: this.eventsList[index].active,
        activities: [
          {
            activityId: this.eventsList[index].activities[0].activityId,
            description: this.eventsList[index].activities[0].description,
            projectedDate: new Date().getTime(),
            required: true,
            spreadAutomatically: false,
            value: 0,
            active: this.eventsList[index].active,
            sw: this.formLineTime.sw.sw,
            processType: 1
          }
        ]
      };
      this.isLoading = true;
      this.updateEvents
        .execute(payloadUpdate)
        .then((response: any) => {
          this.isLoading = false;
          this.formLineTime.edit = null;
          this.getEvents();
          return response;
        })
        .catch((error: any) => {
          this.isLoading = false;
          throw new Error(error);
        });
    }
  }
  deleteEvent(index: any) {
    if (this.eventsList[index].id) {
      Swal.fire({
        title:
          this.$i18n.t('general.areyousuretodeletethis') +
          ' ' +
          this.$i18n.t('general.event') +
          ' ' +
          this.$i18n.t('general.squestion'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `${i18n.t('general.delete')}`,
        cancelButtonText: `${i18n.t('general.cancel')}`,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then(result => {
        if (result.isConfirmed) {
          const payloadUpdate: any = {
            id: this.eventsList[index].id,
            description: this.eventsList[index].description,
            day: this.eventsList[index].day,
            handleSpreadAutomatically: false,
            typeOperation: this.eventsList[index].typeOperation.code,
            active: false,
            activities: [
              {
                activityId: this.eventsList[index].activities[0].activityId,
                description: this.eventsList[index].activities[0].description,
                projectedDate: new Date().getTime(),
                required: true,
                spreadAutomatically: false,
                value: 0,
                active: false,
                processType: 1
              }
            ]
          };
          this.isLoading = true;
          this.updateEvents
            .execute(payloadUpdate)
            .then((response: any) => {
              this.isLoading = false;
              this.getEvents();
              return response;
            })
            .catch((error: any) => {
              this.isLoading = false;
              throw new Error(error);
            });
        } else {
          return false;
        }
      });
    } else {
      this.eventsList.shift();
    }
  }
  addEvent() {
    this.eventsList.unshift({
      description: '',
      day: 0,
      typeOperation: [],
      handleSpreadAutomatically: false,
      visible: true
    });
    this.formLineTime.sw = this.formLineTime.arraySwitch[0];
    for (const ev in this.eventsList) {
      if (ev === '0') {
        this.eventsList[ev].visible = true;
      } else {
        this.eventsList[ev].visible = false;
      }
    }
    this.formLineTime.disabledEvents = false;
  }
  filterEvent() {
    const result: any = this.eventsList.filter((data: any) => data.id === this.formLineTime.eventSelected.id);
    this.eventsList = [];
    this.eventsList = result;
    this.$refs.dropdown.hide(true);
    this.eventsList[0].visible = true;
    return this.eventsList;
  }
}
