import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { PricingEngineParamList } from '@/wms/domain/catalogs/pricingEngineParam/PricingEngineParamList';
import { PricingEngineParamRepository } from '@/wms/domain/catalogs/pricingEngineParam/PricingEngineParamRepository';

@Injectable()
export class FindByTypeParam extends Query<Promise<PricingEngineParamList[]>, any> {
  public constructor(
    @Inject(TYPES.PRICING_ENGINE_PARAM_REPOSITORY)
    private readonly repository: PricingEngineParamRepository,

    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(typeParam: any): Promise<PricingEngineParamList[]> {
    const dataPricingEngineParam = await this.repository.findByTypeParams(typeParam);
    this.stateManager.patch({ dataPricingEngineParam });
    return this.stateManager.state.dataPricingEngineParam;
  }
}
