import { Injectable } from '@/core/di/Injectable';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { ReportConfigRepository } from '@/wms/domain/reports/ReportConfigRepository';
import { Command } from '@/core/cqrs/Command';
import { ReportFilters } from '@/wms/domain/reports/ReportFilters';

/**
 * @class GenerateReports
 * @description Clase que permite generar reportes
 * @version 1.0.0
 * @author Mauricio Cantillo
 */
@Injectable()
export class GenerateReports extends Command<Promise<string>, { type: string; filters: ReportFilters }> {
  public constructor(
    @Inject(TYPES.REPORT_REPOSITORY)
    readonly repository: ReportConfigRepository
  ) {
    super();
  }

  public async internalExecute(payload: { type: string; filters: Record<string, any> }): Promise<string> {
    const { type, filters } = payload;
    return await this.repository.generateReport(type, filters);
  }
}
