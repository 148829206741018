
import ConsigneeSearch from '@/core/components/shared/ConsigneeSearch.vue';
import EntitySearch from '@/core/components/shared/EntitySearch.vue';
import StoreSearch from '@/core/components/shared/StoreSearch.vue';
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { FindPickingListDataForReport } from '@/freight/application/pickingList/search/FindPickingListDataForReport';
import { FindPickingListsByDate } from '@/freight/application/pickingList/search/FindPickingListsByDate';
import { FindShippedPickingListsNumberByDateRange } from '@/freight/application/pickingList/search/FindShippedPickingListsNumberByDateRange';
import { PickingList } from '@/freight/domain/pickingList/PickingList';
import { PickingListForReport } from '@/freight/domain/pickingList/PickingListForReport';
import { RoutesFindAll } from '@/settings/application/uses_cases/routes/search/RoutesFindAll';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { Routes } from '@/settings/domain/routes/Routes';
import { FindBulksByPickingLists } from '@/wms/application/bulk/search/FindBulksByPickingLists';
import { FindConsolidatedBulks } from '@/wms/application/bulk/search/FindConsolidatedBulks';
import { FindPickedBulksByStoreAndDate } from '@/wms/application/bulk/search/FindPickedBulksByStoreAndDate.1';
import { ConsolidateBulks } from '@/wms/application/bulk/update/ConsolidateBulks';
import { DeconsolidateBulks } from '@/wms/application/bulk/update/DeconsolidateBulks';
import { FindConsolidatedDataForReport } from '@/wms/application/consolidated/FindConsolidatedDataForReport';
import { FindConsolidatedNumbersByDateRange } from '@/wms/application/consolidated/FindConsolidatedNumbersByDateRange';
import { Bulk } from '@/wms/domain/bulk/Bulk';
import { BulkPackingData } from '@/wms/domain/bulk/BulkPackingData';
import { GroupedBulk } from '@/wms/domain/bulk/GroupedBulk';
import { ConsolidatedDataForReport } from '@/wms/domain/consolidated/ConsolidatedDataForReport';
import { Store } from '@/wms/domain/layout/store/Store';
import html2pdf from 'html2pdf.js';
import Vue from 'vue';
import Component from 'vue-class-component';
import ConsolidationFormat from './ConsolidationFormat.vue';
import PackingListFormat from './PackingListFormat.vue';

class ConsilidationFilters {
  store: Store | null;
  dateFrom: string;
  dateTo: string;
  serial: string;
  route: Routes | null;
  consignee: string | null;
  carrier: Entity | null;

  constructor() {
    this.store = null;
    this.dateFrom = new Date().toISOString().substr(0, 10);
    this.dateTo = new Date().toISOString().substr(0, 10);
    this.serial = '';
    this.route = null;
    this.consignee = null;
    this.carrier = null;
  }
}

@Component({
  name: 'WmsConsolidationCenter',
  components: {
    StoreSearch,
    EntitySearch,
    PackingListFormat,
    ConsolidationFormat,
    ConsigneeSearch
  }
})
export default class WmsConsolidationCenter extends Vue {
  @Inject(TYPES.BULK_FIND_PICKED_BY_STORE_AND_DATE)
  readonly findPickedBulksByStoreAndDate!: FindPickedBulksByStoreAndDate;
  @Inject(TYPES.FINDALL_ROUTES)
  readonly findRoutes!: RoutesFindAll;
  @Inject(TYPES.BULK_CONSOLIDATE)
  readonly consolidateBulks!: ConsolidateBulks;
  @Inject(TYPES.BULK_DECONSOLIDATE)
  readonly deconsolidateBulks!: DeconsolidateBulks;
  @Inject(TYPES.BULK_FIND_CONSOLIDATED)
  readonly findConsolidatedBulks!: FindConsolidatedBulks;
  @Inject(TYPES.PICKING_LIST_FIND_BY_DATE)
  readonly findPickingListByDate!: FindPickingListsByDate;
  @Inject(TYPES.BULK_FIND_BY_PICKING_LISTS)
  readonly findBulksByPickingLists!: FindBulksByPickingLists;
  @Inject(TYPES.PICKING_LIST_FIND_SHIPPED_BY_DATE_RANGE)
  readonly findShippedPickingListByDateRange!: FindShippedPickingListsNumberByDateRange;
  @Inject(TYPES.PICKING_LIST_FIND_DATA_FOR_REPORT)
  readonly findPickingListData!: FindPickingListDataForReport;
  @Inject(TYPES.CONSOLIDATED_FIND_BY_DATE)
  readonly findConsolidatedList!: FindConsolidatedNumbersByDateRange;
  @Inject(TYPES.CONSOLIDATED_FIND_DATA_FOR_REPORT)
  readonly findConsolidatedData!: FindConsolidatedDataForReport;

  form: ConsilidationFilters = new ConsilidationFilters();
  selectedSerials: string[] = [];
  selectedPickingLists: number[] = [];
  plainedBulks: Bulk[] = [];

  //#region Variables
  pickingListToPrint: number | null = null;
  consolidatedToPrint: number | null = null;
  pickingInfo: PickingListForReport | null = null;
  consolidatedInfo: ConsolidatedDataForReport | null = null;

  isLoading = false;

  //Listas
  arraySwitchs: string[] = ['consolidation', 'deconsolidation'];
  arrayRoute: Routes[] = [];
  pickingLists: PickingList[] = [];
  dispatchedPickingLists: PickingList[] = [];
  consolidatedList: number[] = [];

  mode = 'consolidation';

  groupedSku: GroupedBulk[] = [];

  get totalBulksOnGroups() {
    return this.groupedSku.reduce((acc: number, group: GroupedBulk) => acc + group.bulks.length, 0);
  }

  mounted() {
    this.getRoutes();
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  get fields() {
    return [
      {
        key: 'reference',
        label: 'SKU',
        sortable: true,
        formatter: (value: string, key: string, item: GroupedBulk) => `${item.reference} - ${item.referenceName}`
      },
      {
        key: 'codeLocationPk',
        label: this.$t('general.location'),
        sortable: true
      },
      {
        key: 'customerName',
        label: 'Cliente',
        sortable: true,
        formatter: (value: string, key: string, item: GroupedBulk) => `${item.customerCode} - ${item.customerName}`
      },
      { key: 'consignee', label: this.$t('general.consignee'), sortable: true },
      { key: 'shipper', label: this.$t('general.shipper'), sortable: true },
      { key: 'show_details', label: this.$t('general.actions') }
    ];
  }

  get bulkFields() {
    return [
      { key: 'serial', label: 'Serial', sortable: true },
      {
        key: 'length',
        label: `${this.$t('general.length')} x ${this.$t('general.width')} x ${this.$t('general.height')}`,
        sortable: true,
        formatter: (value: string, key: string, item: BulkPackingData) =>
          `${item.length} x ${item.width} x ${item.height}`
      },
      {
        key: 'volume',
        label: this.$t('general.volume'),
        sortable: true,
        formatter: (value: string, key: string, item: BulkPackingData) => item.volume
      },
      {
        key: 'weight',
        label: this.$t('general.weight'),
        sortable: true,
        formatter: (value: string, key: string, item: BulkPackingData) => item.weight
      },
      {
        key: 'actions',
        label: this.$t('general.actions')
      }
    ];
  }

  get downloadablePDFName() {
    return `${this.$t('general.packing list')}-${this.pickingInfo?.number}`;
  }

  async getPickedBulks() {
    try {
      this.isLoading = true;
      const res = await this.findBulksByPickingLists.execute(this.selectedPickingLists);
      this.groupedSku = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async getConsolidatedBulks() {
    try {
      if (!this.form.store || !this.form.route) return;
      this.isLoading = true;
      await this.findConsolidatedBulks.execute({
        store: this.form.store,
        dateFrom: this.form.dateFrom,
        dateTo: this.form.dateTo,
        destination: this.form.route.cityDestination,
        origin: this.form.route.cityOrigin
      });
      // this.groupedSku = res.length > 0 ? res : [];
      // this.bulks = res.length > 0 ? res : [];
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  factorySearch() {
    this.mode === 'consolidation' ? this.getPickedBulks() : this.getConsolidatedBulks();
  }

  checkBulkSerialPresentInChecklist(e: Event) {
    const { value } = e.target as HTMLInputElement;

    const includesSku = this.groupedSku.filter((group: GroupedBulk) => group.reference === value.trim());

    if (includesSku.length > 0) {
      this.selectedSerials.push(includesSku[0].bulks[0].serial);
      return;
    }

    const includesBulk =
      this.groupedSku.filter(
        (bulk: GroupedBulk) => bulk.bulks.filter((b: BulkPackingData) => b.serial === value.trim()).length > 0
      ).length > 0;

    if (includesBulk) {
      this.selectedSerials.push(value.trim());
      this.form.serial = '';
    } else {
      this.$toasted.error(`${this.$t('general.bulkNotFound')}`);
    }
  }

  removeBulkFromChecklist(serial: string) {
    this.selectedSerials = this.selectedSerials.filter((s: string) => s !== serial);
  }

  async getRoutes() {
    try {
      this.isLoading = true;
      const res = await this.findRoutes.execute();
      this.arrayRoute = res;
      this.isLoading = false;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async consolidate() {
    try {
      if (this.selectedSerials.length === 0) {
        this.$toasted.error(`${this.$t('general.selectAtLeastOneBulk')}`);
        return;
      }

      // if (this.selectedSerials.length != this.totalBulksOnGroups) {
      //   this.$toasted.error(`${this.$t('general.selectAllBulks')}`);
      //   return;
      // }

      this.isLoading = true;
      const res = await this.consolidateBulks.execute(this.selectedSerials);
      if (!('error' in res)) {
        this.$toasted.success(`${this.$t('general.consolidateSuccess')}`);
        this.selectedSerials = [];
        this.groupedSku = [];
        return true;
      }
      return false;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }
  async deconsolidate() {
    try {
      if (this.selectedSerials.length === 0) {
        this.$toasted.error(`${this.$t('general.selectAtLeastOneBulk')}`);
        return;
      }
      this.isLoading = true;
      const res = await this.deconsolidateBulks.execute(this.selectedSerials);
      if (!('error' in res)) {
        this.$toasted.success(`${this.$t('general.consolidateSuccess')}`);
        this.selectedSerials = [];
        this.groupedSku = [];
        return true;
      }
      this.isLoading = false;
      return false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async factory() {
    return this.mode === 'consolidation' ? await this.consolidate() : await this.deconsolidate();
  }

  async getPickingLists() {
    try {
      this.isLoading = true;
      const res = await this.findPickingListByDate.internalExecute({
        dateFrom: this.form.dateFrom,
        dateTo: this.form.dateTo,
        consignee: this.form.consignee,
        carrier: this.form.carrier?.code
      });
      this.pickingLists = res;
      this.isLoading = false;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  selectAllOnGroup(row: GroupedBulk) {
    const serials = row.bulks.map((bulk: BulkPackingData) => bulk.serial);
    this.selectedSerials = this.selectedSerials.concat(serials);
  }

  getListst() {
    this.searchPackingLists();
    this.findConsolidated();
    this.getPickingLists();
  }

  async findPickingListDataForReport(pickingList: PickingList | null) {
    if (!pickingList) return;

    try {
      this.isLoading = true;
      const res = await this.findPickingListData.internalExecute(pickingList.number);
      this.pickingInfo = res;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async printFunction(id: string, fileName: string): Promise<void> {
    try {
      this.isLoading = true;
      const element = document.getElementById(id);
      const opt = {
        margin: 0.5,
        filename: fileName,
        image: { type: 'jpeg', quality: 0.8 },
        html2canvas: { scale: 2, letterRendering: true, useCORS: true, logging: true },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'p' }
      };
      await html2pdf()
        .set(opt)
        .from(element)
        .toPdf()
        .save();
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async searchPackingLists() {
    try {
      this.dispatchedPickingLists = [];
      this.isLoading = true;
      const res = await this.findShippedPickingListByDateRange.internalExecute({
        dateFrom: this.form.dateFrom,
        dateTo: this.form.dateTo,
        consignee: this.form.consignee,
        carrier: this.form.carrier?.code
      });
      this.dispatchedPickingLists = res;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async findConsolidated() {
    try {
      this.isLoading = true;
      this.consolidatedList = [];
      const res = await this.findConsolidatedList.internalExecute({
        dateFrom: this.form.dateFrom,
        dateTo: this.form.dateTo,
        consignee: this.form.consignee,
        carrier: this.form.carrier?.code
      });
      this.consolidatedList = res;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async findConsolidatedDataForReport(consolidated: number | null) {
    if (!consolidated) return;

    try {
      this.isLoading = true;
      const res = await this.findConsolidatedData.internalExecute(consolidated);
      this.consolidatedInfo = res;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }
}
