
import Vue from 'vue';
import Component from 'vue-class-component';
import CreateOrderN from './CreateOrderN.vue';
@Component({
  name: 'OrderContainer',
  components: { CreateOrderN }
})
export default class OrderContainer extends Vue {
  orderType = 1;
}
