
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { RoutesFindByStatus } from '@/settings/application/uses_cases/routes/search/RoutesFindByStatus';
import { Routes } from '@/settings/domain/routes/Routes';
import { SwitchFindAllByProcessType } from '@/tracking/application/uses_cases/switches/search/SwitchFindAllByProcessType';
import { MonetaryTransactionFindAll } from '@/tracking/application/uses_cases/monetarytransaction/search/findall/MonetaryTransactionFindAll';
import { StateManager } from '@/core/statemanager/StateManager';
import { EventsFindByProcessType } from '@/tracking/application/uses_cases/events/search/EventsFindByProcessType';

@Component({
  name: 'AsociateEvents'
})
export default class AsociateEvents extends Vue {
  @Inject(TYPES.FINDBYSTATUS_ROUTES)
  readonly findAllActiveRoutes!: RoutesFindByStatus;
  @Inject(TYPES.FINDBYPROCESSTYPE_SWITCH)
  readonly findSwitchByProcess!: SwitchFindAllByProcessType;
  @Inject(TYPES.FINDALL_MONETARY_TRANSACTION)
  readonly monetaryTransactionFindAll!: MonetaryTransactionFindAll;
  @Inject(TYPES.STATE_MANAGER)
  stateManager!: StateManager;
  @Inject(TYPES.FINDEVENTSBYPROCESSTYPE_EVENTS)
  readonly eventsFindByProcessType!: EventsFindByProcessType;

  @Prop() readonly addEvents!: any;
  @Prop() formModel!: any;
  @Prop() readonly sw!: number;
  @Prop() readonly routesList!: any;

  eventList: any[] = [];
  monetarytransactions: any[] = [];
  activeRoutes: Routes[] = [];
  selectedRoute = {};
  selectedEvents: any[] = [];
  activitySwitchList = [];
  eventListSw: any = [];
  placeholder = `${this.$t('general.selectanoption')}`;

  getSwitch() {
    this.findSwitchByProcess.execute(3).then((response: any) => {
      this.activitySwitchList = response;
    });
    return true;
  }

  mounted() {
    this.getSwitch();
    this.typeMonetary();
    this.getEvents();
  }

  switchNature(sw: any) {
    return this.activitySwitchList.filter((item: any) => {
      return item.sw == sw;
    })[0];
  }

  selectEvents(eventItem: any, event: any) {
    if (event) {
      this.formModel.selectEvent.push(eventItem);
    } else {
      this.formModel.selectEvent = this.formModel.selectEvent.filter((item: any) => {
        return item !== eventItem;
      });
    }
    this.selectedEvents.sort((a: any, b: any) => a.sequence - b.sequence);
  }

  findRoutes() {
    this.findAllActiveRoutes
      .execute(true)
      .then((response: any) => {
        this.activeRoutes = [response];
      })
      .catch(err => {
        throw new Error(`${err}`);
      });
  }

  async typeMonetary() {
    await this.monetaryTransactionFindAll
      .execute()
      .then((response: any) => {
        this.monetarytransactions = response;
      })
      .catch(error => {
        throw new Error(error);
      });
  }

  getEvents() {
    this.eventsFindByProcessType.execute(3).then((response: any) => {
      this.eventList = [];
      this.eventList = response.reverse();
      if (this.formModel.selectedTimeLine.configFor != undefined) {
        this.eventList = this.eventList
          .filter((item: any) => {
            return this.formModel.selectedTimeLine.configFor == 'u' ? item.forCourier : !item.forCourier;
          })
          .filter((item: any) => {
            return !(
              this.formModel.selectedTimeLine.events.filter((evento: any) => evento.description == item.description)
                .length > 0
            );
          });
      }
      this.eventListSw = this.formModel.selectedTimeLine.events;
    });
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  changePlaceholderWhenOn() {
    this.placeholder = '';
  }
  changePlaceholderWhenClose() {
    this.placeholder = 'Select an option';
  }
}
