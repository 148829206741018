
import { TYPES } from '@/core/config/Types';
import { CompanyFindAll } from '@/settings/application/uses_cases/company/search/CompanyFindAll';
import { Company } from '@/settings/domain/company/Company';
import { Inject } from 'inversify-props';
import { ValidationObserver } from 'vee-validate';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

interface FormRole {
  id: number | null;
  name: string;
  company: Company;
  type: { type: string; id: number };
}

@Component({
  name: 'CreateRolForm',
  components: {}
})
export default class CreateRolForm extends Vue {
  @Prop({ required: true }) form!: FormRole;
  @Prop({ required: true }) save!: () => void;

  @Inject(TYPES.FINDALL_COMPANY)
  readonly findAllCompany!: CompanyFindAll;

  $refs!: {
    roleForm: InstanceType<typeof ValidationObserver>;
  };

  /**
   * Variables de data
   */
  companies: Company[] = [];
  userTypes: { type: string; id: number }[] = [
    { type: 'ADMIN', id: 1 },
    { type: 'USER', id: 2 }
  ];

  /**
   * Variables de ayuda
   */

  isLoading = false;

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  /**
   * @name getCompanies
   * @description Obtiene las compañias para el select
   * @returns {Promise<void>}
   */
  async getCompanies(): Promise<void> {
    try {
      const response = await this.findAllCompany.execute();
      this.companies = response ?? [];
    } catch (error) {
      throw `${error}`;
    }
  }

  /**
   * @name getAllNecessaryData
   * @description Obtiene todos los datos necesarios para el formulario
   *
   */
  getAllNecessaryData(): void {
    this.isLoading = true;
    Promise.all([this.getCompanies()]).finally(() => {
      this.isLoading = false;
    });
  }

  async saveRole(e: any) {
    e.preventDefault();
    const validate = await this.$refs.roleForm.validate();
    if (!validate) return;

    this?.save();
  }
}
