import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { References } from '@/wms/domain/catalogs/references/References';
import { ReferencesRepository } from '@/wms/domain/catalogs/references/ReferencesRepository';

@Injectable()
export class CreateReference extends Command<Promise<any>, References> {
  public constructor(
    @Inject(TYPES.REFERENCE_REPOSITORY)
    private readonly referencesRepository: ReferencesRepository
  ) {
    super();
  }

  public internalExecute(references: References): Promise<any> {
    return this.referencesRepository.create(references);
  }
}
