import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Incoterms } from '@/settings/domain/ParmsGeneral/Incoterms/Incoterms';
import { IncotermsRepository } from '@/settings/domain/ParmsGeneral/Incoterms/IncotermsRepository';

@Injectable()
export class IncotermsFindAll extends Query<Promise<Incoterms[]>> {
  public constructor(
    @Inject(TYPES.INCOTERMS_REPOSITORY)
    private readonly repository: IncotermsRepository,

    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(): Promise<Incoterms[]> {
    const dataIncoterms = await this.repository.findAll();
    this.stateManager.patch({ dataIncoterms });
    return this.stateManager.state.dataIncoterms;
  }
}
