import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { WmsQuotationOrderForTable } from '@/courier/domain/wmsQuotationOrder/WmsQuotationOrderForTable';
import { WmsQuotationOrderRepository } from '@/courier/domain/wmsQuotationOrder/WmsQuotationOrderRepository';

@Injectable()
export class WmsQuotationOrderService implements WmsQuotationOrderRepository {
  private readonly API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;
  private readonly API_WMS_ORDER_FIND_BY_DATE_RANGE_AND_TYPE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_WMS_ORDER_FIND_BY_DATE_RANGE_AND_TYPE;

  findByDateRangeAndType({
    dateFrom,
    dateTo,
    type
  }: {
    dateFrom: string;
    dateTo: string;
    type: string;
  }): Promise<WmsQuotationOrderForTable[]> {
    try {
      return http.get<WmsQuotationOrderForTable[]>(
        `${this.API_WMS_ORDER_FIND_BY_DATE_RANGE_AND_TYPE}dateFrom=${dateFrom}&dateTo=${dateTo}&type=${type}`
      );
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
