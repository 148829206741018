import { Injectable } from '@/core/di/Injectable';
import { Query } from '@/core/cqrs/Query';
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { ZoneL } from '@/wms/domain/layout/zone/ZoneL';
import { ZoneLRepository } from '@/wms/domain/layout/zone/ZoneLRepository';

@Injectable()
export class FindZoneNotConfigured extends Query<Promise<ZoneL[]>> {
  public constructor(
    @Inject(TYPES.LAYOUT_ZONE_REPOSITORY)
    private readonly zoneRepository: ZoneLRepository
  ) {
    super();
  }

  async internalExecute(): Promise<ZoneL[]> {
    return await this.zoneRepository.findNotConfigured();
  }
}
