import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { DocsLinRepository } from '@/courier/domain/orderline/DocsLinRepository';
import { OrderLine } from '@/courier/domain/orderline/OrderLine';

@Injectable()
export class GetDocsLinByTimeline extends Query<Promise<OrderLine[]>, { timeline: number }> {
  constructor(
    @Inject(TYPES.DOCS_LIN_REPOSITORY)
    private readonly repository: DocsLinRepository
  ) {
    super();
  }

  async internalExecute({ timeline }: { timeline: number }) {
    return await this.repository.getByTimeline({ timeline });
  }
}
