
import Component from 'vue-class-component';
import Vue from 'vue';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import Staticschart from '@/core/components/dashboard/chartjs/statics.vue';
import Salechart from '@/core/components/dashboard/chartjs/salechart.vue';
import VueBarcode from 'vue-barcode';

// Import FilePond and styles
import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import { DeleteAlternativeReferences } from '@/wms/application/catalogs/alternativeReferences/delete/DeleteAlternativeReferences';
import { CreateClassReferences } from '@/wms/application/catalogs/classReferences/create/CreateClassReferences';
import { CreateCategoryReferences } from '@/wms/application/catalogs/categoryReferences/create/CreateCategoryReferences';
import { CreateVariety } from '@/wms/application/catalogs/Variety/create/CreateVariety';
import { FindAllVariety } from '@/wms/application/catalogs/Variety/search/FindAllVariety';
import { FindAllCategoryReferences } from '@/wms/application/catalogs/categoryReferences/search/FindAllCategoryReferences';
import { FindAllClassReferences } from '@/wms/application/catalogs/classReferences/search/FindAllClassReferences';
import { CreateFile } from '@/settings/application/uses_cases/file/create/CreateFile';
import { GroupReferenceFindById } from '@/wms/application/category/groupReferences/search/GroupReferenceFindById';
import { GroupReferenceFindAllTree } from '@/wms/application/category/groupReferences/search/GroupReferenceFindAllTree';
import { CreateReference } from '@/wms/application/catalogs/references/create/CreateReference';
import { ReferenceUpdate } from '@/wms/application/catalogs/references/update/ReferenceUpdate';
import { GroupReferenceFindAll } from '@/wms/application/category/groupReferences/search/GroupReferenceFindAll';
import { CreateGroupReference } from '@/wms/application/category/groupReferences/create/CreateGroupReference';
import ModalsInfo from '@/wms/infrastructure/ui/reference/InformacionAdicionalMdl.vue';
import { EntityFindFilter } from '@/settings/application/uses_cases/entity/search/EntityFindFilter';
import { ConsecutiveFindByWarehouseAndType } from '@/courier/application/uses_cases/consecutive/search/ConsecutiveFindByWarehouseAndType';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';
import Characteristics from './modals/Characteristics.vue';
import { ReferenceDelete } from '@/wms/application/catalogs/references/delete/ReferenceDelete';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import { CreateProduct } from '@/wms/application/catalogs/product/create/CreateProduct';
import { Product } from '@/wms/domain/catalogs/product/Product';
import { UpdateProduct } from '@/wms/application/catalogs/product/update/UpdateProduct';
import { ProductFindBySKU } from '@/wms/application/catalogs/product/search/ProductFindBySKU';
import UpdateSalePrices from './modals/UpdateSalePrices.vue';
import { FindCommodityTypeByStatus } from '@/freight/application/commodity_type/search/FindCommodityTypesByStatus';
import GammaModal from './modals/GammaModal.vue';
import { Gamma } from '@/wms/domain/gamma/Gamma';
import { FindAllGamma } from '@/settings/application/uses_cases/gamma/gamma/search/GammaFindall';
import GammaReferences from './modals/GammaReferences.vue';
import { FindFile } from '@/settings/application/uses_cases/file/search/FindFile';
import { CreateFileV2 } from '@/settings/application/uses_cases/file/create/CreateFileV2';
import { UpdateCategoryReferences } from '@/wms/application/catalogs/categoryReferences/update/UpdateCategoryReferences';
import { CategoryReferences } from '@/wms/domain/catalogs/categoryReferences/CategoryReferences';
import { ClassReferences } from '@/wms/domain/catalogs/classReferences/ClassReferences';
import { UpdateClassReferences } from '@/wms/application/catalogs/classReferences/update/UpdateClassReferences';
import EditDynamicElement from '@/settings/infrastructure/ui/components/entity/editDynamicRecords/EditRecordModal.vue';
import { DeleteClassReferences } from '@/wms/application/catalogs/classReferences/delete/DeleteClassReferences';
import { DeleteCategoryReferences } from '@/wms/application/catalogs/categoryReferences/delete/DeleteCategoryReferences';
import localStorageVariables from '@/core/config/LocalStorageVariables';
import { ReferenceFindAllForListByCategory } from '@/wms/application/catalogs/references/search/ReferenceFindAllForListByCategory';
import { ReferencesForList } from '@/wms/domain/catalogs/references/ReferencesForList';
import { ReferenceFindBySku } from '@/wms/application/catalogs/references/search/ReferenceFindBySku';

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

@Component({
  name: 'References',
  components: {
    ModalsInfo,
    VueBarcode,
    Staticschart,
    Salechart,
    FilePond,
    Characteristics,
    CustomTableN,
    UpdateSalePrices,
    GammaModal,
    GammaReferences,
    EditDynamicElement
  }
})
export default class References extends Vue {
  @Inject(TYPES.CREATE_GROUP_REFERENCE)
  readonly createGroupReference!: CreateGroupReference;
  @Inject(TYPES.FINDALL_GROUP_REFERENCE)
  readonly groupReferenceFindAll!: GroupReferenceFindAll;
  @Inject(TYPES.CREATE_REFERENCE)
  readonly createReference!: CreateReference;
  @Inject(TYPES.UPDATE_REFERENCE)
  readonly updateReference!: ReferenceUpdate;
  @Inject(TYPES.DELETE_REFERENCE)
  readonly deleteReference!: ReferenceDelete;
  @Inject(TYPES.FINDALL_GROUP_TREE_REFERENCE)
  readonly groupReferenceFindAllTree!: GroupReferenceFindAllTree;
  @Inject(TYPES.FINDBYID_GROUP_REFERENCE)
  readonly findByIdGroupReference!: GroupReferenceFindById;
  @Inject(TYPES.CREATE_FILE)
  readonly createFile!: CreateFile;
  @Inject(TYPES.FIND_FILE_BY_NAME)
  readonly fileFindAll!: FindFile;
  @Inject(TYPES.FINDALL_CLASS_REFERENCE)
  readonly classReferenceFindAll!: FindAllClassReferences;
  @Inject(TYPES.UPDATE_CLASS_REFERENCE)
  readonly classReferenceUpdate!: UpdateClassReferences;
  @Inject(TYPES.FINDALL_CATEGORY_REFERENCE)
  readonly categoryReferenceFindAll!: FindAllCategoryReferences;
  @Inject(TYPES.UPDATE_CATEGORY_REFERENCE)
  readonly categoryReferenceUpdate!: UpdateCategoryReferences;
  @Inject(TYPES.DELETE_CATEGORY_REFERENCE)
  readonly categoryReferenceDelete!: DeleteCategoryReferences;
  @Inject(TYPES.FINDALL_VARIETY_REFERENCE)
  readonly varietyFindAll!: FindAllVariety;
  @Inject(TYPES.CREATE_VARIETY_REFERENCE)
  readonly createVariety!: CreateVariety;
  @Inject(TYPES.CREATE_CATEGORY_REFERENCE)
  readonly createCategoryReference!: CreateCategoryReferences;
  @Inject(TYPES.CREATE_CLASS_REFERENCE)
  readonly createClassReference!: CreateClassReferences;
  @Inject(TYPES.ENTITY_FIND_BY_DESCRIPTION_LIKE)
  readonly customerFindFilter!: EntityFindFilter;
  @Inject(TYPES.DELETE_ALTERNATIVE_REFERENCE)
  readonly deleteAlternativeReference!: DeleteAlternativeReferences;
  @Inject(TYPES.FINDBYWAREHOUSEANDTYPE_CONSECUTIVE)
  readonly consecutiveFindByWarehouseAndType!: ConsecutiveFindByWarehouseAndType;
  @Inject(TYPES.FINDBYID_COMPANY)
  readonly findByCompanyId!: CompanyFindById;
  @Inject(TYPES.CREATE_PRODUCT)
  readonly createProduct!: CreateProduct;
  @Inject(TYPES.UPDATE_PRODUCT)
  readonly updateProduct!: UpdateProduct;
  @Inject(TYPES.PRODUCT_FIND_BY_SKU)
  readonly productFindBySKU!: ProductFindBySKU;
  @Inject(TYPES.COMMODITY_TYPE_FIND_BY_STATUS)
  readonly findCommodityByStatus!: FindCommodityTypeByStatus;
  @Inject(TYPES.GAMMA_FINDALL)
  readonly findAllGammas!: FindAllGamma;
  @Inject(TYPES.SAVE_FILE)
  readonly saveFile!: CreateFileV2;
  @Inject(TYPES.DELETE_CLASS_REFERENCE)
  readonly deleteClassReference!: DeleteClassReferences;
  @Inject(TYPES.REFERENCES_FIND_ALL_FOR_LIST_BY_CATEGORY)
  readonly referencesFindAllForListByCategory!: ReferenceFindAllForListByCategory;
  @Inject(TYPES.REFERENCE_FIND_BY_SKU)
  readonly referenceFindBySKU!: ReferenceFindBySku;

  $refs!: {
    childComponent: HTMLFormElement;
    pictureInput: HTMLFormElement;
    observer: HTMLFormElement;
    mdlCharacteristhic: HTMLFormElement;
  };
  //DATA
  consAutoReference = JSON.parse(localStorage.getItem(localStorageVariables.CONS_AUTO_REFERENCE) as any);
  businessId = localStorage.getItem(localStorageVariables.BUSINESS_ID);
  referenceLevelGrouping = false;
  widthResize = 0;
  isTouched = false;
  startIndex = 0;
  tabIndex = 1;
  isUpdateReferences = false;
  loadingImage = false;
  parents: any = [
    {
      subGroup: [
        {
          subGroup: [{}]
        }
      ]
    }
  ];
  objGroup: any = [];
  objGroupId: any = [];
  isShow = false;
  isShowTable = true;
  groupReferences: any = [];
  showImg = false;
  category: any = [];
  selectedcategory = '';
  isLoading = false;
  objclass: any = [];
  customers: any = [];
  reference: any = {
    code: '',
    description: '',
    filterAge: '',
    filterAlcoholGrade: '',
    filterBrand: '',
    filterCategory: null,
    filterClass: null,
    filterVariety: null,
    filterSize: '',
    standardCost: '',
    referenceStatus: '',
    image: '',
    barCode: '',
    alternativeReferences: [],
    variety: {},
    applyDiscount: false,
    consAutoReference: this.consAutoReference,
    msrp: '',
    saleTax: 0,
    purchaseTax: 0,
    isPromotional: false,
    isMainService: false,
    noManageStock: false,
    showOnQuotation: false,
    requestInfo: false,
    requestValue: false,
    forGeneralFreight: false,
    transportMethod: null,
    transportMethodValue: 0,
    inspectionTerms: [],
    processType: null,
    commodity: null,
    referenceGroupLevel1: null,
    referenceGroupLevel2: null,
    referenceGroupLevel3: null,
    referenceGroupLevel4: null,
    active: null,
    cargoType: null,
    manageSizes: false,
    gamma: null,
    hasSerials: false,
    serials: [],
    usePriceList: false,
    allowBatch: false
  };
  varieties: any = [];
  form: any = {
    //GROUP_REFERENCE
    codePrefix: '',
    newCode: '',
    level: '',
    description: '',
    shortName: '',
    parent: '',
    stateDisable: true,
    subGroup: '',
    createAt: '',
    updateAt: '',
    urlBase64Logo: '',
    urlImage: '',
    image: '',
    customer: ''
  };
  showReference = false;
  showCharts = false;
  suppliers: any = [
    {
      id: '',
      fullName: '',
      address: '',
      typeCustomer: '',
      email: '',
      originCountry: '',
      alternate: '',
      profitPercentage: 0,
      basePrice: 0,
      resetTime: 0,
      reorderPoint: 0
    }
  ];
  showModal = false;
  idModal = '';
  refModal = '';
  objReference: any = {};
  imgChanged = false;
  product: Product | null = new Product();
  commodityList: any[] = [];
  referencesList: ReferencesForList[] = [];

  // Gamma
  gammaList: Gamma[] | null = null;
  gammaSelected: Gamma | null = null;

  //GETTERS
  get optionsDiscount() {
    return [
      { text: `${this.$t('general.allow')}`, value: true },
      { text: `${this.$t('general.dontAllow')}`, value: false }
    ];
  }

  get dateNow() {
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    const today = String(new Date().getDate()).padStart(2, '0');
    return `${year}-${month < 10 ? '0' : ''}${month}-${today}`;
  }

  get tableFields() {
    return [
      {
        field: 'image',
        label: `${this.$t('general.image')}`,
        formatFn: (row: string) => `${this.fileFindAll.execute(row)}`
      },
      {
        field: 'code',
        label: `${this.$t('general.code')}`
      },
      {
        field: 'filterBrand',
        label: `${this.$t('general.productName')}`
      },
      {
        field: 'standardCost',
        label: `${this.$t('general.unitCost')}`
      },
      {
        field: 'actions',
        label: `${this.$t('general.actions')}`
      }
    ];
  }

  tableActions = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: (row: any) => {
          this.adelantar(1);
          this.setDataUpdateReference(row);
        }
      },
      {
        title: `${this.$t('general.statistics')}`,
        icon: 'fa fa-bar-chart',
        variant: 'warning',
        action: () => {
          this.adelantar(3);
        }
      },
      {
        title: `${this.$t('general.delete')}`,
        icon: 'fa fa-trash',
        variant: 'danger',
        action: this.removeReference
      }
    ],
    dropdowns: [
      {
        variant: 'outline-secondary',
        items: [
          {
            title: `${this.$t('general.productPackaging')}`,
            action: (reference: References) => this.showModalById('mdlPackaging', true, reference)
          },
          {
            title: `${this.$t('general.pricingAndPromotions')}`,
            action: (reference: References) => this.showModalById('mdlPricing', true, reference)
          },
          {
            title: `${this.$t('menu.Operations')}`,
            action: this.showCharacteristhicModal
          },
          {
            title: `${this.$t('general.gammaReferences')}`,
            action: (reference: References) => this.showModalById('mdlGammaReferences', true, reference)
          }
        ]
      }
    ]
  };

  created(): void {
    this.$nextTick(() => this.parentsquery());
    this.$nextTick(() => this.findAllParms());
    this.getAllCommodityActive();
  }
  getAllCommodityActive() {
    this.findCommodityByStatus
      .execute(true)
      .then((response: any) => {
        this.commodityList = response;
      })
      .catch(err => {
        this.commodityList = [];
        throw new Error(`${err}`);
      });
  }
  asyncFind(query: string) {
    if (query.length >= 3) {
      const params: any = {
        text: query,
        type: ['SUPPLIER', 'CUSTOMER']
      };
      this.isLoading = true;
      this.customerFindFilter
        .execute(params)
        .then((response: any) => {
          this.customers = response;
          this.isLoading = false;
        })
        .catch((err: any) => {
          this.isLoading = false;
          throw new Error(`${err}`);
        });
    }
  }
  addTagVariety(newTag: string) {
    this.reference.variety = { variety: newTag };
    const payload: any = {
      code: 0,
      variety: newTag
    };
    this.createVariety.execute(payload);
    setTimeout(this.findAllVariety, 1000);
  }
  addTagCategory(newTag: string) {
    this.reference.filterCategory = { category: newTag };
    const payload: any = {
      code: 0,
      category: newTag
    };
    this.createCategoryReference.execute(payload);
    setTimeout(this.findAllCategory, 1000);
  }
  addTagClass(newTag: string) {
    this.reference.filterClass = { clazz: newTag };
    const payload: any = {
      code: 0,
      clazz: newTag
    };

    this.createClassReference.execute(payload);
    setTimeout(this.findAllClass, 1000);
  }
  addNewRow() {
    this.reference.alternativeReferences.push({
      id: '',
      entity: undefined,
      alternate: '',
      profitPercentage: 0,
      basePrice: 0,
      resetTime: 0,
      reorderPoint: 0
    });
  }
  deleteRow(index: any, supplier: any) {
    if (supplier.id != '') this.deleteAlternativeReference.execute(supplier);
    const idx = this.reference.alternativeReferences.indexOf(supplier);
    if (idx > -1) {
      this.reference.alternativeReferences.splice(idx, 1);
    }
  }
  initSuppliers() {
    this.reference.alternativeReferences = [];
    this.reference.alternativeReferences.push();
  }
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  validateLevel(
    level01: any | null,
    level02: any | null,
    level03: any | null,
    level04: any | null,
    currentLevel: number
  ) {
    if (level01.level !== 1 && level01.subGroup.length === 0) {
      if (this.form.parent.description) {
        this.form.parent.description = undefined;
        this.groupReferences = [];
      }
    }

    if (level01.level == currentLevel) {
      const currentObj =
        currentLevel == 1
          ? level01
          : currentLevel == 2
          ? level02
          : currentLevel == 3
          ? level03
          : currentLevel == 4
          ? level04
          : null;
      this.setParent(currentObj, level04, level03, level02, level01);
    }
  }

  setParent(
    currentObj: any | null,
    level04: any | null,
    level03: any | null,
    level02: any | null,
    level01: any | null
  ) {
    this.form.parent = currentObj;

    this.objGroup = [];
    this.objGroupId = [];
    // this.findGroupbyId();
    this.getReferencesFromCategory(this.form.parent.id);

    if (level01 != null) this.objGroup.push(level01.code);
    if (level02 != null) this.objGroup.push(level02.code);
    if (level03 != null) this.objGroup.push(level03.code);
    if (level04 != null) this.objGroup.push(level04.code);

    if (level01 != null) this.objGroupId.push(level01.id);
    if (level02 != null) this.objGroupId.push(level02.id);
    if (level03 != null) this.objGroupId.push(level03.id);
    if (level04 != null) this.objGroupId.push(level04.id);

    this.back(1);
    this.clearReferences();
  }
  //GROUP TREE
  parentsquery() {
    this.isLoading = true;
    this.groupReferenceFindAllTree
      .execute()
      .then((response: any) => {
        this.parents = response;
        this.sortArray(this.parents);
        this.isLoading = false;
        this.isShow = true;
      })
      .catch((err: any) => {
        this.isShow = false;
        this.isLoading = false;
        throw new Error(`${err}`);
      });
  }
  async findGroupbyId() {
    this.isLoading = true;
    await this.findByIdGroupReference
      .execute(this.form.parent.id)
      .then((response: any) => {
        this.isLoading = false;
        this.groupReferences = response;
        this.isShowTable = true;
      })
      .catch((err: any) => {
        this.isLoading = false;
        this.isShowTable = false;
        throw new Error(`${err}`);
      });
  }
  //WIZARD ACTIONS
  back(tabIndex: number) {
    this.tabIndex = tabIndex--;
    this.$refs.childComponent.prevTab();
  }
  adelantar(tabIndex: number) {
    if (tabIndex == 3) {
      this.tabIndex = 2;

      this.$refs.childComponent.changeTab(0, 2);
    } else {
      this.tabIndex = tabIndex++;
      this.$refs.childComponent.nextTab();
    }
    if (!this.showReference) {
      this.showReference = this.tabIndex == 1;
    }
    if (!this.showCharts) {
      this.showCharts = this.tabIndex == 2;
    }
  }
  //IMAGE
  onChanged(event: Event) {
    // Leemos el archivo que ha sido seleccionado
    const files = (event?.target as HTMLInputElement).files;

    // Si hay un archivo seleccionado
    if (files?.length) {
      // Revisamos si el archivo seleccionado no pesa más de 2MB
      if (files[0].size > 2097152) {
        this.$bvToast.toast('El archivo seleccionado es muy grande, por favor seleccione un archivo de menos de 2MB', {
          title: 'Error',
          variant: 'danger',
          solid: true
        });
        return;
      }

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.form.urlBase64Logo = e.target.result;
      };
      const file = files[0];
      reader.readAsDataURL(file);
      this.imgChanged = true;
      this.reference.image = 'image';
    }
  }
  onRemoved() {
    this.form.image = null;
    this.reference.image = null;
    this.form.urlBase64Logo = this.fileFindAll.execute(this.form.image);
  }
  //REFERENCIA DATA AND MODE
  setDataCreateReference() {
    this.clearReferences();
    this.isUpdateReferences = false;
    this.objGroup.map((item: any) => {
      this.form.codePrefix += item;
    });
    this.getConsecutive();
  }
  getConsecutive() {
    if (!this.consAutoReference) return;
    const pyload = {
      type: 'REFERENCE',
      warehouse: '0'
    } as const;
    this.consecutiveFindByWarehouseAndType
      .execute(pyload)
      .then((response: any) => {
        this.form.newCode = Number(response.nextNumber) + 1;
      })
      .catch((err: any) => {
        return err;
      });
  }
  async setDataUpdateReference(referencesRow: ReferencesForList) {
    try {
      this.clearReferences();

      const references = await this.findReferenceData(referencesRow.code);

      this.reference.active = references.active;
      this.form.newCode = references.code;
      this.form.code = references.code;
      this.reference.code = references.code;
      this.reference.description = references.description;
      this.reference.filterAge = references.filterAge;
      this.reference.filterAlcoholGrade = references.filterAlcoholGrade;
      this.reference.filterBrand = references.filterBrand;
      this.reference.filterCategory = references.filterCategory;
      this.reference.filterClass = references.filterClass;
      this.reference.filterVariety = references.filterVariety;
      this.reference.filterSize = references.filterSize;
      this.reference.standardCost = references.standardCost;
      this.reference.referenceStatus = references.referenceStatus;
      this.reference.image = references.image;
      this.reference.barCode = references.barCode;
      this.reference.alternativeReferences = references.alternativeReferences;
      this.reference.msrp = references.msrp;
      this.reference.noManageStock = references.noManageStock;
      this.reference.showOnQuotation = references.showOnQuotation;
      this.reference.applyDiscount = references.applyDiscount || false;
      this.reference.isPromotional = references.isPromotional;
      this.reference.isMainService = references.isMainService;
      this.reference.forGeneralFreight = references.forGeneralFreight;
      this.reference.transportMethod = references.transportMethod;
      this.reference.transportMethodValue = references.transportMethodValue;
      this.reference.inspectionTerms =
        references.inspectionTerms != null && references.inspectionTerms.length > 0
          ? references.inspectionTerms[0].split(',')
          : [];
      this.reference.requestInfo = references.requestInfo;
      this.reference.requestValue = references.requestValue;
      this.reference.processType = references.processType;
      this.reference.saleTax = references.saleTax;
      this.reference.purchaseTax = references.purchaseTax;
      this.reference.commodity = references.commodity;
      this.reference.referenceGroupLevel1 = null;
      this.reference.referenceGroupLevel2 = null;
      this.reference.referenceGroupLevel3 = null;
      this.reference.referenceGroupLevel4 = null;
      this.reference.cargoType = references.cargoType;
      this.reference.manageSizes = references.manageSizes;
      this.reference.gamma = references.gamma;
      this.reference.hasSerials = references.hasSerials;
      this.reference.serials = references.serials;
      this.reference.usePriceList = references.usePriceList;
      this.reference.allowBatch = references.allowBatch;
      const alternativeRef = references.alternativeReferences;

      if (alternativeRef.length != 0) {
        this.suppliers = alternativeRef;
      }

      this.isUpdateReferences = true;
      this.form.image = references.image;
      this.loadingImage = true;
      this.form.urlBase64Logo = this.fileFindAll.execute(references.image);
    } finally {
      this.loadingImage = false;
    }
  }
  findAllParms() {
    this.findAllClass();
    this.findAllCategory();
    this.findAllVariety();
    this.findAllGamma();
  }
  findAllClass() {
    this.classReferenceFindAll
      .execute()
      .then((resp: any) => {
        this.objclass = resp;
      })
      .catch((err: any) => {
        throw new Error(`${err}`);
      });
  }
  findAllCategory() {
    this.categoryReferenceFindAll
      .execute()
      .then((resp: any) => {
        this.category = resp;
      })
      .catch((err: any) => {
        throw new Error(`${err}`);
      });
  }
  findAllVariety() {
    this.varietyFindAll
      .execute()
      .then((resp: any) => {
        this.varieties = resp;
      })
      .catch((err: any) => {
        throw new Error(`${err}`);
      });
  }

  async newReference() {
    let image = this.reference.image;
    if (this.imgChanged && this.reference.image != null) {
      image = await this.saveImage();
    }

    const payload2: any = {
      code: this.form.newCode,
      description: this.reference.description,
      filterAge: this.reference.filterAge,
      filterAlcoholGrade: this.reference.filterAlcoholGrade,
      filterBrand: this.reference.filterBrand,
      filterCategory: this.reference.filterCategory,
      filterVariety: this.reference.filterVariety,
      filterClass: this.reference.filterClass,
      filterSize: this.reference.filterSize,
      standardCost: this.reference.standardCost,
      referenceStatus: this.reference.referenceStatus,
      msrp: this.reference.msrp,
      image: image,
      groups: this.objGroupId,
      barCode: this.reference.barCode,
      alternativeReferences: this.reference.alternativeReferences,
      consAutoReference: this.consAutoReference,
      isPromotional: this.reference.isPromotional,
      isMainService: this.reference.isMainService,
      noManageStock: this.reference.noManageStock,
      showOnQuotation: this.reference.showOnQuotation,
      applyDiscount: this.reference.applyDiscount,
      requestInfo: this.reference.requestInfo,
      requestValue: this.reference.requestValue,
      forGeneralFreight: this.reference.forGeneralFreight,
      transportMethod: this.reference.transportMethod,
      transportMethodValue: this.reference.transportMethodValue,
      inspectionTerms: this.reference.inspectionTerms,
      processType: this.reference.processType,
      saleTax: this.reference.saleTax,
      purchaseTax: this.reference.purchaseTax,
      commodity: this.reference.commodity,
      referenceGroupLevel1: null,
      referenceGroupLevel2: null,
      referenceGroupLevel3: null,
      referenceGroupLevel4: null,
      cargoType: this.reference.cargoType,
      manageSizes: this.reference.manageSizes,
      gamma: this.reference?.gamma,
      active: this.reference.active,
      hasSerials: this.reference.hasSerials,
      usePriceList: this.reference.usePriceList,
      allowBatch: this.reference.allowBatch
    };

    this.isLoading = true;
    if (!this.isUpdateReferences) {
      await this.createReference.execute(payload2);
    } else {
      await this.updateReference.execute(payload2);
      this.isShowTable = false;
    }
    this.isLoading = false;

    await this.getReferencesFromCategory(this.form.parent.id);
    this.clearReferences();
    this.isUpdateReferences = false;
    this.imgChanged = false;
    this.$refs.observer.reset();
    this.back(1);
  }

  async removeReference(reference: References) {
    try {
      if (!reference) return;

      //Se muestra un mensaje de confirmacion
      const message = await this.$swal.fire({
        showCancelButton: true,
        showConfirmButton: true,
        reverseButtons: true,
        title: this.$t('general.info'),
        text: `${this.$t('general.areyousuretodeletethis')}`,
        confirmButtonText: `${this.$t('general.delete')}`,
        cancelButtonText: `${this.$t('general.cancel')}`,
        icon: 'warning'
      });

      if (!message.isConfirmed) return;
      this.isLoading = true;
      await this.deleteReferenceFromTable(reference);
      this.findAllParms();
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async deleteReferenceFromTable(reference: any) {
    try {
      this.isLoading = true;
      await this.deleteReference.execute(reference);
      await this.findGroupbyId();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async saveImage() {
    try {
      this.isLoading = true;
      // Se toma el archivo seleccionado
      const file = this.$refs.pictureInput.files[0] as File;

      //Si existe dicho archivo, cambiamos el nombre por
      // Codigo de compañía - "references" - sku del producto.extension
      if (file) {
        const newName = `${this.form.newCode}.${file.name.split('.').pop()}`;
        const newFile = new File([file], newName, { type: file.type });

        //Se guarda el archivo en el servidor y retornamos el nombre del archivo
        return await this.saveFile.execute({
          files: [newFile],
          folder: 'references'
        });
      }
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  clearReferences() {
    this.reference.description = '';
    this.reference.filterAge = '';
    this.reference.filterAlcoholGrade = '';
    this.reference.filterBrand = '';
    this.reference.filterCategory = null;
    this.reference.filterClass = null;
    this.reference.filterSize = '';
    this.reference.filterVariety = null;
    this.reference.standardCost = '';
    this.reference.msrp = '';
    this.reference.referenceStatus = '';
    this.reference.image = '';
    this.form.urlBase64Logo = '';
    this.form.newCode = '';
    this.form.codePrefix = '';
    this.reference.barCode = '';
    this.reference.commodity = null;
    this.reference.referenceGroupLevel1 = null;
    this.reference.referenceGroupLevel2 = null;
    this.reference.referenceGroupLevel3 = null;
    this.reference.referenceGroupLevel4 = null;
    this.reference.active = false;
    this.reference.allowBatch = false;

    this.initSuppliers();

    this.onRemoved();
  }

  showModalById(id: string, show: boolean, references: any) {
    this.refModal = references.code;
    this.objReference = Object.assign({}, references);
    this.getModal(id, show);
  }

  getModal(id: string, show: boolean) {
    this.showModal = show;
    this.idModal = id;
  }

  sortArray(array: any) {
    array.sort((a: any, b: any) => (a.description > b.description ? 1 : -1));
    array.forEach((element: any) => {
      if (element.subGroup.length > 0) {
        this.sortArray(element.subGroup);
      }
    });
  }

  //Funcion invocada para mostrar el modal de caracteristicas
  showCharacteristhicModal(reference: any) {
    this.setDataUpdateReference(reference);
    this.$refs.mdlCharacteristhic.show();
  }

  async findAllGamma() {
    this.isLoading = true;
    try {
      const res = await this.findAllGammas.internalExecute();
      this.gammaList = res;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  hideModal() {
    this.showModal = false;
  }

  setValuesAsZeroIfPriceList() {
    this.reference.standardCost = 0;
    this.reference.msrp = 0;
  }

  async updateReferenceCategory(data: CategoryReferences) {
    try {
      await this.categoryReferenceUpdate.execute(data);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.findAllCategory();
    }
  }

  async deleteReferenceCategory(data: CategoryReferences) {
    try {
      await this.categoryReferenceDelete.execute(data);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.findAllCategory();
    }
  }
  async updateReferenceClass(data: ClassReferences) {
    try {
      await this.classReferenceUpdate.execute(data);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.findAllClass();
    }
  }
  async deleteReferenceClass(data: ClassReferences) {
    try {
      await this.deleteClassReference.execute(data);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.findAllClass();
    }
  }

  async getReferencesFromCategory(category: any) {
    try {
      this.isLoading = true;
      const res = await this.referencesFindAllForListByCategory.execute(category);
      this.referencesList = res;
    } catch (error) {
      this.referencesList = [];
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async findReferenceData(sku: string) {
    try {
      this.isLoading = true;
      const res = await this.referenceFindBySKU.internalExecute(sku);
      return res;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }
}
