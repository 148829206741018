import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import { PreRegisterRepository } from '@/wms/domain/preRegister/PreRegisterRepository';

@Injectable()
export class PreRegisterFindWithBulks extends Query<Promise<PreRegister[]>, string> {
  public constructor(
    @Inject(TYPES.PRE_REGISTER_REPOSITORY)
    private readonly preRegisterRepository: PreRegisterRepository
  ) {
    super();
  }
  async internalExecute(module: string): Promise<PreRegister[]> {
    const res = await this.preRegisterRepository.findAllWithBulks(module);
    return res;
  }
}
