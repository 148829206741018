import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { ReferencesRepository } from '@/wms/domain/catalogs/references/ReferencesRepository';
import { ReferencesForList } from '@/wms/domain/catalogs/references/ReferencesForList';

@Injectable()
export class ReferenceFindAllForListByCategory extends Query<Promise<ReferencesForList[]>, number> {
  public constructor(
    @Inject(TYPES.REFERENCE_REPOSITORY)
    private readonly referencesRepository: ReferencesRepository
  ) {
    super();
  }
  async internalExecute(category: number): Promise<ReferencesForList[]> {
    return this.referencesRepository.findByCategoryForList(category);
  }
}
