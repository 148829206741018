import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { BillOfLandingRepository } from '@/wms/domain/billOfLanding/BillOfLandingRepository';
import { BillOfLanding } from '@/wms/domain/billOfLanding/BillOfLanding';

@Injectable()
export class FindMasterBLByPickingFilters extends Command<
  Promise<BillOfLanding[]>,
  {
    monthFrom: string;
    monthTo: string;
    commodity: number;
    shipper: string;
  }
> {
  public constructor(
    @Inject(TYPES.BILL_OF_LANDING_REPOSITORY)
    private readonly repository: BillOfLandingRepository
  ) {
    super();
  }

  public internalExecute({
    monthFrom,
    monthTo,
    commodity,
    shipper
  }: {
    monthFrom: string;
    monthTo: string;
    commodity: number;
    shipper: string;
  }): Promise<BillOfLanding[]> {
    return this.repository.findByPickingFilters({
      monthFrom,
      monthTo,
      commodity,
      shipper
    });
  }
}
