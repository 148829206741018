import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Transport } from '@/tracking/domain/typetransport/Transport';
import { TransportRepository } from '@/tracking/domain/typetransport/TransportRepository';

@Injectable()
export class TransportFindAll extends Query<Promise<Transport[]>> {
  public constructor(
    @Inject(TYPES.TYPE_TRANSPORT_REPOSITORY)
    private readonly transportRepository: TransportRepository,
    @Inject(TYPES.STATE_MANAGER) private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(): Promise<Transport[]> {
    const dataTransport = await this.transportRepository.findAll();
    this.stateManager.patch({ dataTransport });
    return this.stateManager.state.dataTransport;
  }
}
