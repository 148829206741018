import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Query } from '@/core/cqrs/Query';
import { TYPES } from '@/core/config/Types';
import { UserRepository } from '@/settings/domain/user/UserRepository';
import { UserForList } from '@/settings/domain/user/Projections/UserForList';

@Injectable()
export class UserFindAllForList extends Query<Promise<UserForList[]>, string> {
  public constructor(
    @Inject(TYPES.USER_REPOSITORY)
    private readonly userRepository: UserRepository
  ) {
    super();
  }

  async internalExecute(namespace: string): Promise<UserForList[]> {
    return await this.userRepository.findUsersByNamespace(namespace);
  }
}
