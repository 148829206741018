import { Injectable } from '@/core/di/Injectable';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { ReportConfigRepository } from '@/wms/domain/reports/ReportConfigRepository';
import { Command } from '@/core/cqrs/Command';
import { Report } from '@/wms/domain/reports/ReportConfig';

/**
 * @class UpdateReports
 * @description Clase que permite generar reportes
 * @version 1.0.0
 * @author Mauricio Cantillo
 */
@Injectable()
export class UpdateReports extends Command<Promise<Report>, Report> {
  public constructor(
    @Inject(TYPES.REPORT_REPOSITORY)
    readonly repository: ReportConfigRepository
  ) {
    super();
  }

  public async internalExecute(report: Report): Promise<Report> {
    return await this.repository.update(report);
  }
}
