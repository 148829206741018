import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { CurrencyFactor } from '@/settings/domain/currencyfactor/CurrencyFactor';
import { CurrencyFactorRepository } from '@/settings/domain/currencyfactor/CurrencyFactorRepository';

@Injectable()
export class CurrencyFactorFindByCurrencyCode extends Query<Promise<CurrencyFactor[]>, string> {
  public constructor(
    @Inject(TYPES.CURRENCYFACTOR_REPOSITORY)
    private readonly currencyFactorRepository: CurrencyFactorRepository
  ) {
    super();
  }
  async internalExecute(currencyFactorCode: any): Promise<CurrencyFactor[]> {
    return await this.currencyFactorRepository.findCurrencyFactorByCurrency(currencyFactorCode);
  }
}
