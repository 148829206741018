import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { OrderRepository } from '@/courier/domain/order/OrderRepository';

@Injectable()
export class WmsOrderFindByPk extends Query<Promise<any>, any> {
  public constructor(
    @Inject(TYPES.ORDER_REPOSITORY)
    private readonly orderRepository: OrderRepository
  ) {
    super();
  }
  async internalExecute(payload: any): Promise<any> {
    const res = await this.orderRepository.findWmsOrderByPk(payload);
    return res;
  }
}
