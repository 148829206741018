import { Containers } from '@/settings/domain/containers/Containers';
import { Packaging } from '@/wms/domain/catalogs/packaging/Packaging';
import { ShippingDetails } from '../shippingDetails/ShippingDetails';
import { References } from '@/wms/domain/catalogs/references/References';

export class WmsOrderLine {
  quotationTypeLine: string;
  containerType: Containers | null;
  measurementUnit: any | null;
  grossWeigth: number;
  shippingDetails: ShippingDetails;
  weight: number;
  width: number;
  height: number;
  length: number;
  weightOnCube: number;
  widthOnCube: number;
  heightOnCube: number;
  lengthOnCube: number;
  stackable: boolean;
  cargoType: string;
  loadingRate: number;
  dischargingRate: number;
  quantity: number;
  packaging: Packaging | null;
  number: number;
  containerId: string;
  active: boolean;
  sequence: number;
  reference: References | null;

  public constructor(quotationTypeLine?: string) {
    this.quotationTypeLine = quotationTypeLine || '';
    this.containerType = null;
    this.measurementUnit = null;
    this.grossWeigth = 0;
    this.shippingDetails = new ShippingDetails();
    this.stackable = false;
    this.cargoType = '';
    this.loadingRate = 0;
    this.dischargingRate = 0;
    this.weight = 0;
    this.width = 0;
    this.height = 0;
    this.length = 0;
    this.weightOnCube = 0;
    this.widthOnCube = 0;
    this.heightOnCube = 0;
    this.lengthOnCube = 0;
    this.quantity = 1;
    this.packaging = null;
    this.number = 0;
    this.containerId = '';
    this.sequence = 0;
    this.active = true;
    this.reference = null;
  }
}
