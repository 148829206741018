
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { FindTransactionalActivityById } from '@/tracking/application/uses_cases/activitiesTransactional/search/FindTransactionalActivityById';
import { ActivityTransactional } from '@/tracking/domain/activitiesTransactional/ActivityTransactional';
import { PreRegisterForTraceabilityPanel } from '@/wms/domain/preRegister/PreRegisterForTraceabilityPanel';
import TransactionalEventForTraceability from '@/wms/domain/preRegister/TransactionalEventForTraceability';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import DocForm from './components/DocForm.vue';

@Component({
  name: 'ModalIncome',
  components: { DocForm }
})
export default class ModalIncome extends Vue {
  @Inject(TYPES.ACTIVITIES_TRANSACTIONAL_FIND_BY_ID)
  private readonly findActivityById!: FindTransactionalActivityById;
  @Prop() isLoading!: boolean;
  @Prop() masterNumber!: number;
  @Prop({ required: true }) master!: PreRegisterForTraceabilityPanel;
  @Prop({ type: String, required: true }) readonly lineSerial!: string;
  @Prop({ type: Array, required: true }) readonly houses!: any[];
  @Prop({ type: String, required: false }) readonly typeOperation!: string;
  @Prop({ required: false }) activityData!: ActivityTransactional;
  @Prop({ type: Function }) searchActivities!: () => Promise<void>;
  @Prop({ type: Function }) saveProcess!: (activityData: ActivityTransactional) => Promise<void>;
  @Prop() eventData!: TransactionalEventForTraceability;

  modalActivityData: ActivityTransactional | null = null;

  get localIsLoading() {
    return this.isLoading;
  }

  set localIsLoading(value) {
    this.$emit('update:isLoading', value);
  }

  async findData() {
    try {
      if (!this.activityData) return;

      this.localIsLoading = true;

      this.modalActivityData = await this.findActivityById.execute({
        activityId: this.activityData?.activityId,
        eventId: this.eventData.eventId,
        preRegisterNumber: this.master.number,
        preRegisterType: this.master.type
      });
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.localIsLoading = false;
    }
  }
}
