import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { LayoutConfigRepository } from '@/wms/domain/layout/configResizing/LayoutConfigRepository';
import { ResizingZone } from '@/wms/domain/layout/configResizing/ResizingZone';
import { Rack } from '@/wms/domain/layout/rack/Rack';

@Injectable()
export class LayoutConfigServices implements LayoutConfigRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_LAYOUTCONFIG_SAVE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_LAYOUTCONFIG_SAVE;
  private readonly API_LAYOUTCONFIG_FIND: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_LAYOUTCONFIG_FIND;
  private readonly API_LAYOUTCONFIG_DELETE_ZONE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_LAYOUTCONFIG_DELETE_ZONE;
  private readonly API_RACKS_CONFIG_TO_LAYOUT_ZONE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_RACKS_CONFIG_TO_LAYOUT_ZONE;

  async create(resizingZone: ResizingZone[]) {
    try {
      await http.post<ResizingZone[]>(this.API_LAYOUTCONFIG_SAVE, JSON.stringify(resizingZone));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findAllConfigByWarehouseStore(
    warehouseCode: string,
    storeId: number,
    state: boolean,
    windowHeight: number,
    windowWidth: number
  ): Promise<ResizingZone[]> {
    try {
      return await http.get<ResizingZone[]>(
        this.API_LAYOUTCONFIG_FIND +
          `state=${state}&storeId=${storeId}&warehouseCode=${warehouseCode}&windowHeight=${windowHeight}&windowWidth=${windowWidth}`
      );
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  update(resizingZone: ResizingZone): Promise<any> {
    throw new Error(`Method not implemented. ${resizingZone}`);
  }

  async deleteZone(resizingZone: ResizingZone): Promise<any> {
    try {
      return await http.delete<ResizingZone>(this.API_LAYOUTCONFIG_DELETE_ZONE, JSON.stringify(resizingZone));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async saveRacksToZone({
    storeId,
    zoneId,
    racks,
    layoutConfigId
  }: {
    storeId: number;
    zoneId: number;
    racks: Rack[];
    layoutConfigId: number;
  }): Promise<void> {
    try {
      await http._put<Rack[]>(
        `${this.API_RACKS_CONFIG_TO_LAYOUT_ZONE}?storeId=${storeId}&zoneId=${zoneId}&layoutConfigId=${layoutConfigId}`,
        JSON.stringify(racks)
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
