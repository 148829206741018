import { Offers } from '@/wms/domain/catalogs/pricingEngine/SettingOffers/Offers';

export class SettingZoneOffers {
  public zones: any;
  public policyOffersTiered: any;
  public offers: Offers;
  constructor() {
    this.zones = [];
    this.policyOffersTiered = [];
    this.offers = new Offers();
  }
}
