import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import { CommodityClass } from '@/settings/domain/commodityClass/CommodityClass';
import { PackingUnits } from '@/settings/domain/packing_units/PackingUnits';
import { ViewLocation } from '@/settings/domain/views/location/ViewLocation';
import { TimelineConfig } from '@/tracking/domain/timelineConfig/TimelineConfig';
import { BillOfLanding } from '../billOfLanding/BillOfLanding';
import { WmsOrderLine } from '@/courier/domain/orderline/WmsOrderLine';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { References } from '../catalogs/references/References';
import { Containers } from '@/settings/domain/containers/Containers';
import { AddressLine } from '@/settings/domain/address/AddressLine';
import Serial from '../catalogs/references/Serial';
import { PreparationBulk } from '@/freight/domain/preparationBulk/domain/PreparationBulk';

export class Bulk extends WmsOrderLine {
  owner: Entity | null;
  customer: Entity | null;
  number: number;
  serial: string;
  description: string;
  commodity: CommodityType | null;
  commodityClass: CommodityClass | null;
  packingUnit: PackingUnits | null;
  location: ViewLocation | null;
  weightUnit: any | null;
  group: number;
  type: string;
  billOfLandingList: BillOfLanding[];
  referenceCode: string;
  timeline: TimelineConfig | null;
  cargoTo: Entity | null;
  shipper: Entity | null;
  fromQuotation: boolean;
  handleInventory: 'S' | 'N';
  reference: References | null;
  warehouseCode: string;
  warehouseName: string;
  storeId: number;
  storeName: string;
  zoneId: string;
  zoneName: string;
  configZone: string;
  rackId: number;
  rackName: string;
  configRackId: number;
  sequenceCode: string;
  rackLevel: number;
  rackSequence: number;
  locationId: number;
  locationName: string;
  position: string;
  commodityName: string;
  codeLocationPk: string;
  dispatchContainer: Containers | null;
  isLocated: boolean;
  locationSelected: ViewLocation | null;
  forPicking: boolean;
  houseBillOfLanding: BillOfLanding | null;
  declaredValue: number;
  consolidated: boolean;
  consolidatedNumber: number;
  consignee: Entity | null;
  finalDestination: AddressLine;
  serials: Serial[] | null;
  batch: string;
  batchExpiration: Date | null;

  //Nuevas propiedades
  newShipper: string;
  newConsignee: string;
  container: string;

  public constructor(type?: string, typeLine?: string) {
    super();
    this.customer = null;
    this.number = 0;
    this.serial = '';
    this.description = '';
    this.commodity = null;
    this.commodityClass = null;
    this.packingUnit = null;
    this.location = null;
    this.weightUnit = null;
    this.group = 0;
    this.type = type || '';
    this.billOfLandingList = [];
    this.quotationTypeLine = typeLine || '';
    this.referenceCode = 'RPR001';
    this.timeline = null;
    this.shipper = null;
    this.fromQuotation = false;
    this.handleInventory = 'S';
    this.reference = null;
    this.warehouseCode = '';
    this.warehouseName = '';
    this.storeId = 0;
    this.storeName = '';
    this.zoneId = '';
    this.zoneName = '';
    this.configZone = '';
    this.rackId = 0;
    this.rackName = '';
    this.configRackId = 0;
    this.sequenceCode = '';
    this.rackLevel = 0;
    this.rackSequence = 0;
    this.locationId = 0;
    this.locationName = '';
    this.position = '';
    this.commodityName = '';
    this.codeLocationPk = '';
    this.dispatchContainer = null;
    this.isLocated = false;
    this.locationSelected = null;
    this.forPicking = false;
    this.houseBillOfLanding = null;
    this.declaredValue = 0;
    this.consolidated = false;
    this.consolidatedNumber = 0;
    this.consignee = null;
    this.cargoTo = null;
    this.owner = null;
    this.finalDestination = new AddressLine();
    this.newShipper = '';
    this.newConsignee = '';
    this.serials = null;
    this.batch = '';
    this.batchExpiration = null;
    this.container = '';
  }

  public static mapLocationToBulk(location: ViewLocation, bulk: Bulk): Bulk {
    try {
      const bulkCopy = Object.assign(new Bulk(), bulk);
      bulkCopy.warehouseCode = location.warehouseCode;
      bulkCopy.warehouseName = location.warehouseName;
      bulkCopy.storeId = location.storeId;
      bulkCopy.storeName = location.storeName;
      bulkCopy.zoneId = location.zoneId;
      bulkCopy.zoneName = location.zoneName;
      bulkCopy.configZone = location.configZone;
      bulkCopy.rackId = location.rackId;
      bulkCopy.rackName = location.rackName;
      bulkCopy.configRackId = location.configRackId;
      bulkCopy.sequenceCode = location.sequenceCode;
      bulkCopy.rackLevel = location.rackLevel;
      bulkCopy.rackSequence = location.rackSequence;
      bulkCopy.locationId = location.locationId;
      bulkCopy.locationName = location.locationName;
      bulkCopy.position = location.position;
      bulkCopy.commodityName = location.commodityName;
      bulkCopy.codeLocationPk = location.id;

      return bulkCopy;
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  public setBulkType(type: string): Bulk {
    this.cargoType = type;
    this.quotationTypeLine = type;
    return this;
  }

  public loadPresetToBulk(preset: PreparationBulk): Bulk {
    this.shippingDetails.commodity = preset.commodity;
    this.newShipper = preset.newShipper;
    this.newConsignee = preset.newConsignee;
    this.customer = preset.customer;
    this.finalDestination = preset.finalDestination;
    return this;
  }
}
