import { EmptyLink } from './EmptyLink';
import { Context } from './Context';
import { Link } from './Link';

export abstract class BaseLink {
  nextLink: Link = new EmptyLink();

  setNext(link: Link): Link {
    this.nextLink = link;
    return this;
  }

  abstract next(context: Context): void;
}
