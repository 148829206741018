import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { HouseBillOfLandingRepository } from '@/wms/domain/houseBillOfLading/HouseBillOfLadingRepository';
import { HouseBillOfLading } from '@/wms/domain/houseBillOfLading/HouseBillOfLading';

@Injectable()
export class HouseBillOfLandingServices implements HouseBillOfLandingRepository {
  private readonly API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;
  private readonly API_HOUSE_BILL_OF_LANDING_FIND_DATA_FOR_REPORT: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_HOUSE_BILL_OF_LANDING_FIND_DATA_FOR_REPORT;
  private readonly API_HOUSE_BILL_OF_LANDING_FIND_BY_OPERATION: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_HOUSE_BILL_OF_LANDING_FIND_BY_OPERATION;
  private readonly API_HOUSE_BILL_OF_LANDING_SAVE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_HOUSE_BILL_OF_LANDING_SAVE;
  private readonly API_CONSIGNEE_FIND_BY_MASTERS: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_CONSIGNEE_FIND_BY_MASTERS;

  findDataForReportHouseBL(code: string): Promise<any> {
    return http.get<any>(`${this.API_HOUSE_BILL_OF_LANDING_FIND_DATA_FOR_REPORT}code=${code}`);
  }

  async findHousesByOperation(payload: { number: number; type: string }): Promise<HouseBillOfLading[]> {
    try {
      return await http.get<HouseBillOfLading[]>(
        `${this.API_HOUSE_BILL_OF_LANDING_FIND_BY_OPERATION}number=${payload.number}&type=${payload.type}`
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async saveHouseBL(housesBl: HouseBillOfLading[]): Promise<HouseBillOfLading[]> {
    try {
      return await http.post<HouseBillOfLading[]>(this.API_HOUSE_BILL_OF_LANDING_SAVE, JSON.stringify(housesBl));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findConsigneesByHouses(houses: string[]): Promise<string[]> {
    try {
      return await http.get<string[]>(`${this.API_CONSIGNEE_FIND_BY_MASTERS}houses=${houses}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
