
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { FindConsigneeByOperation } from '@/settings/application/uses_cases/consignee/search/FindConsigneeByOperation';
import { Consignee } from '@/settings/domain/consignee/Consignee';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'ConsigneeByOperationMultiSelect',
  components: {}
})
export default class ConsigneeByOperationMultiSelect extends Vue {
  //Variable para almacenar la lista de territorios
  consigneeList: string[] = [];

  //Variable para controlar el estado de la carga
  isLoading = false;

  @Inject(TYPES.PRE_REGISTER_FIND_CONSIGNEES)
  readonly findConsignee!: FindConsigneeByOperation;

  @Prop({ required: true }) value!: Consignee | null;
  @Prop({ required: false }) label!: string | null;
  @Prop({ required: false, default: '' }) containerClass!: string | null;
  @Prop({ required: false, default: '' }) validationRules!: string | null;
  @Prop({
    required: false,
    default: (): null => {
      return null;
    }
  })
  onInput!: (entity: string) => Promise<void> | void;
  @Prop({ required: false, default: false }) readonly disabled!: boolean;
  @Prop({ required: true }) readonly type!: string;
  @Prop({ required: true }) readonly number!: number;

  get localValue(): Consignee | null {
    return this.value;
  }

  set localValue(value: Consignee | null) {
    this.$emit('update:value', value);
  }

  async findData() {
    try {
      this.isLoading = true;
      this.consigneeList = await this.findConsignee.internalExecute({
        number: this.number,
        type: this.type
      });
    } finally {
      this.isLoading = false;
    }
  }

  mounted() {
    this.findData();
  }

  //Función invocada cuando se selecciona un territorio y se desea enviar al padre para ejecutar alguna acción.
  async dispatchInput(value: string) {
    if (this.onInput) await this.onInput(value);
  }
}
