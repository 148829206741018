import { TYPES } from '@/core/config/Types';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Query } from '@/core/cqrs/Query';
import { StateManager } from '@/core/statemanager/StateManager';
import { Timeline } from '@/tracking/domain/timeline/Timeline';
import { TimelineRepository } from '@/tracking/domain/timeline/TimelineRepository';

@Injectable()
export class TimelineFindByProcessType extends Query<Promise<Timeline[]>, number> {
  public constructor(
    @Inject(TYPES.TIMELINE_REPOSITORY)
    private readonly timelineRepository: TimelineRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(params: number): Promise<Timeline[]> {
    const dataTimeline = await this.timelineRepository.findByProcessType(params);
    this.stateManager.patch({ dataTimeline });
    return this.stateManager.state.dataTimeline;
  }
}
