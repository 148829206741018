
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { FindReferencesByMainSku } from '@/wms/application/catalogs/references/search/FindReferencesByMainSku';
import { ReferenceUpdateBarcode } from '@/wms/application/catalogs/references/update/ReferenceUpdateBarcode';
import { References } from '@/wms/domain/catalogs/references/References';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
@Component({
  name: 'GammaReferences',
  components: {
    CustomTableN
  }
})
export default class GammaReferences extends Vue {
  @Inject(TYPES.FIND_REFERENCES_BY_MAIN_SKU)
  private readonly findReferencesByMainSku!: FindReferencesByMainSku;
  @Inject(TYPES.UPDATE_BARCODE_REFERENCES)
  private readonly updateBarCode!: ReferenceUpdateBarcode;

  @Prop({ required: true }) reference!: References;

  references: References[] = [];
  isLoading = false;
  selectedReference: References | null = null;
  isEdit = false;
  newBarCode = '';

  mounted() {
    this.findReferences();
  }

  get fields() {
    return [
      {
        field: 'code',
        label: this.$t('general.code'),
        sortable: true
      },
      {
        field: 'filterBrand',
        label: this.$t('general.name'),
        sortable: true
      },
      {
        field: 'barCode',
        label: this.$t('general.barCode'),
        sortable: true
      },
      { field: 'actions', label: this.$t('general.actions'), sortable: false }
    ];
  }

  tableActions = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: this.editBarCode
      }
    ]
  };

  async findReferences() {
    try {
      this.isLoading = true;
      const res = await this.findReferencesByMainSku.execute(this.reference.code);

      if (!('error' in res)) {
        this.references = res;
      }
    } finally {
      this.isLoading = false;
    }
  }

  async updateExistingBarCode() {
    this.isLoading = true;
    try {
      if (!this.selectedReference) return;
      const params = { currentBarcode: this.selectedReference.code, newBarCode: this.newBarCode };
      return await this.updateBarCode.execute(params);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
      this.isEdit = false;
      this.selectedReference = null;
      this.newBarCode = '';
      this.findReferences();
    }
  }

  editBarCode(item: References) {
    this.selectedReference = item;
    this.newBarCode = this.selectedReference.barCode;
    this.isEdit = true;
  }
}
