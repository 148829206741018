import { GroupReferences } from '@/wms/domain/category/groupReferences/GroupReferences';
import { WmsfOrderReferences } from '../WmsfOrderReferences/WmsfOrderReferences';

export class WmsfOrderCategory {
  number: number;
  typeOrder: string;
  category: GroupReferences | null;
  references: WmsfOrderReferences[];
  type: string;
  active: boolean;

  public constructor() {
    this.number = 0;
    this.typeOrder = '';
    this.type = '';
    this.category = null;
    this.references = [];
    this.active = true;
  }
}
