import { container } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { Runner } from '@/core/runner/Runner';

export abstract class UseCase<Result, Param = void> {
  abstract readonly: boolean;
  abstract internalExecute(param: Param): Result;

  execute(param: Param): Result {
    return container.get<Runner>(TYPES.RUNNER).run(this, param) as Result;
  }
}
