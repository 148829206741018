import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';

export class ShippingDetails {
  commodity: CommodityType | null;
  hsCode: string;
  productName: string;
  dangerousCargo: boolean;
  insurance: boolean;
  insuranceValue: string;
  pickUpRequest: boolean;
  pickUpRequestAddress: string;

  public constructor() {
    this.commodity = null;
    this.hsCode = '';
    this.productName = '';
    this.dangerousCargo = false;
    this.insurance = false;
    this.insuranceValue = '0';
    this.pickUpRequest = !!false; /* Guiño guiño */
    this.pickUpRequestAddress = '';
  }
}
