
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'ModalTieredDiscount',
  components: {}
})
export default class ModalTieredDiscount extends Vue {
  //DATA
  tieredDiscount: any = [
    {
      quantityInitial: 0,
      quantityFinal: 0,
      percentage: 0
    }
  ];

  //Prop
  @Prop() readonly modal!: string;
  @Prop() readonly objConfig!: any;

  settingObjConfiguration() {
    this.tieredDiscount = this.objConfig || [
      {
        quantityInitial: 0,
        quantityFinal: 0,
        percentage: 0,
        id: 0
      }
    ];
  }

  addNewRowTiredDiscount() {
    this.tieredDiscount.push({
      quantityInitial: 0,
      quantityFinal: 0,
      percentage: 0
    });
  }

  //delete row
  deleteRowObj(index: any, item: any) {
    const idx = this.tieredDiscount.indexOf(item);
    if (idx > -1) {
      this.tieredDiscount.splice(idx, 1);
    }
  }
}
