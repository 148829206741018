import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Port } from '@/freight/domain/ports/Port';
import { PortRepository } from '@/freight/domain/ports/PortRepository';

@Injectable()
export class UpdatePort extends Command<Promise<Port>, Port> {
  public constructor(
    @Inject(TYPES.PORT_REPOSITORY)
    private readonly portRepository: PortRepository
  ) {
    super();
  }

  internalExecute(port: Port): Promise<Port> {
    return this.portRepository.update(port);
  }
}
