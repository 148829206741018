
import { TYPES } from '@/core/config/Types';
import { WarehouseFindByCode } from '@/settings/application/uses_cases/warehouse/search/WarehouseFindByCode';
import { Warehouse } from '@/settings/domain/warehouse/Warehouse';
import LayoutStore from '@/wms/infrastructure/ui/Layout/LayoutStore.vue';
import { Inject } from 'inversify-props';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'RegisterWarehouse',
  components: { LayoutStore }
})
export default class RegisterWarehouse extends Vue {
  @Inject(TYPES.FINDBYCODE_WAREHOUSE)
  private findWarehouseByCode!: WarehouseFindByCode;

  objSelected: Warehouse | null = null;

  mounted() {
    this.findWarehouse();
  }

  // Obtenemos del query el codigo del almacen
  get warehouseSelect() {
    return this.$route.query.warehouse;
  }

  async findWarehouse() {
    if (!this.warehouseSelect || this.warehouseSelect === '' || Array.isArray(this.warehouseSelect)) {
      return;
    }

    this.objSelected = await this.findWarehouseByCode.internalExecute(this.warehouseSelect);
  }
}
