import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { AlternativeReferences } from '@/wms/domain/catalogs/alternativeReferences/AlternativeReferences';
import { AlternativeReferencesRepository } from '@/wms/domain/catalogs/alternativeReferences/AlternativeReferencesRepository';

@Injectable()
export class DeleteAlternativeReferences extends Command<Promise<any>, AlternativeReferences> {
  public constructor(
    @Inject(TYPES.ALTERNATIVE_REFERENCE_REPOSITORY)
    private readonly alternativeReferencesRepository: AlternativeReferencesRepository
  ) {
    super();
  }

  public internalExecute(alternativeReferences: AlternativeReferences): Promise<any> {
    return this.alternativeReferencesRepository.delete(alternativeReferences);
  }
}
