
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import AddWarehousePreRegister from '@/wms/infrastructure/ui/warehousePreRegister/AddWarehousePreRegister.vue';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import { PreRegisterSave } from '@/wms/application/preRegister/create/PreRegisterSave';
import { PreRegisterTypeEnum } from '@/wms/domain/preRegister/PreRegisterTypeEnum';
import { PreRegisterStatusEnum } from '@/wms/domain/preRegister/PreRegisterStatusEnum';
import { Bulk } from '@/wms/domain/bulk/Bulk';
import { PreRegisterUpdate } from '@/wms/application/preRegister/update/PreRegisterUpdate';
import { PreRegisterFindByPk } from '@/wms/application/preRegister/search/PreRegisterFindByPk';
import { WmsOrderFindByPk } from '@/courier/application/uses_cases/order/search/WmsOrderFindByPk';

@Component({
  name: 'ShipmentCenter',
  components: { AddWarehousePreRegister }
})
export default class ShipmentCenter extends Vue {
  @Inject(TYPES.PRE_REGISTER_SAVE)
  readonly preRegisterSave!: PreRegisterSave;
  @Inject(TYPES.PRE_REGISTER_UPDATE)
  readonly preRegisterUpdate!: PreRegisterUpdate;
  @Inject(TYPES.PRE_REGISTER_FIND_BY_PK)
  readonly findByPk!: PreRegisterFindByPk;
  @Inject(TYPES.WMS_ORDER_FIND_BY_PK)
  readonly findWmsOrderByPk!: WmsOrderFindByPk;

  //Datos
  isLoading = false;
  quantity = 0;
  suggestedDate = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substring(0, 10);

  //Objeto de acciones para componente hijo
  actions = {
    save: this.factory,
    clear: this.clear,
    goTo: this.goToList
  };

  //Formulario
  form: PreRegister = new PreRegister();

  //Getter para obtener la user data desde localstorage
  get userData() {
    return `${localStorage.getItem('customerId') as string} - ${localStorage.getItem('userNameCompany') as string}`;
  }

  //Getter para obtener los parametros de la URL
  get urlParams() {
    return {
      query: this.$route.query
    };
  }

  //Ciclo de vida del componente
  mounted() {
    //Si no viene desde una pantalla de busqueda entonces crea un pre registro vacio.
    !('type' in this.urlParams.query) ? this.clear() : this.find();
  }

  //Metodo ejecutado para guardar el objeto en la base de datos
  async save() {
    try {
      this.isLoading = true;
      this.form.type = PreRegisterTypeEnum.SHIPMENT;
      this.form.bulks.map((item: Bulk) => (item.type = `${this.form.warehouse?.code}_${this.form.type}`));
      if (this.form.timeline) {
        if (this.form.timeline.typeTransport) {
          this.form.timeline.typeTransport = {
            id: Number(this.form.timeline.typeTransport),
            active: true,
            name: ''
          };
        }

        if (this.form.timeline.typeOperation) {
          this.form.timeline.typeOperation = {
            code: String(this.form.timeline.typeOperation),
            nature: '',
            status: true,
            sw: ''
          };
        }

        if (this.form.timeline.processType) {
          this.form.timeline.processType = {
            id: Number(this.form.timeline.processType),
            name: '',
            nameEs: '',
            active: true
          };
        }
      }
      const res = await this.preRegisterSave.execute(this.form);
      if (!('error' in res)) {
        this.clear();
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Metodo ejecutado para guardar el objeto en la base de datos
  async update() {
    try {
      this.isLoading = true;
      this.form.state = this.form.state = PreRegisterStatusEnum.PRE_REGISTER;
      const res = await this.preRegisterUpdate.execute(this.form);
      if (!('error' in res)) {
        this.clear();
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Metodo ejecutado para limpiar el formulario
  clear() {
    this.form = new PreRegister();
    this.form.receivedBy = this.userData;
    this.form.date = this.suggestedDate;
    this.form.state = PreRegisterStatusEnum.SHIPMENT;
    this.form.type = PreRegisterTypeEnum.SHIPMENT;
  }

  //Funcion invocada para obtener un pre registro concreto
  async findPreRegister(input: PreRegister) {
    try {
      this.isLoading = true;
      const res = await this.findByPk.execute({
        type: input.type,
        number: input.number
      });
      this.form = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  }

  //Funcion invocada para decidir que accion realizar en la base de datos
  factory() {
    !this.form.trackingNumber ? this.save() : this.update();
  }

  //Funcion invocada para verificar que todos los bultos tengan un BL asociado
  checkBL() {
    return (
      this.form.bulks.filter(bulk => {
        return bulk.billOfLandingList.length < 1;
      }).length > 1
    );
  }

  //Metodo ejecutado para obtener los datos completos del documento basado en la primary key del mismo
  async find() {
    try {
      this.isLoading = true;
      const res = await this.findByPk.execute({
        type: this.urlParams.query.type,
        number: this.urlParams.query.number
      });
      !('error' in res) ? (this.form = res) : null;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para ir a la lista de pre registros
  goToList() {
    this.$router.push({ name: 'historyPanel' });
  }
}
