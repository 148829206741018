import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { ConceptsContainer } from '@/settings/domain/containers/conceptsContainer/ConceptsContainer';
import { ConceptsContainerRepository } from '@/settings/domain/containers/conceptsContainer/ConceptsContainerRepository';

@Injectable()
export class CreateConceptContainer extends Command<Promise<ConceptsContainer>, ConceptsContainer> {
  public constructor(
    @Inject(TYPES.CONCEPTS_CONTAINER_REPOSITORY)
    private readonly conceptsContainerRepository: ConceptsContainerRepository
  ) {
    super();
  }

  async internalExecute(concept: ConceptsContainer): Promise<ConceptsContainer> {
    return await this.conceptsContainerRepository.create(concept);
  }
}
