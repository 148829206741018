import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { TypeAddress } from '@/settings/domain/ParmsGeneral/typeAddress/TypeAddress';
import { TypeAddressRepository } from '@/settings/domain/ParmsGeneral/typeAddress/TypeAddressRepository';

@Injectable()
export class TypeAddressService implements TypeAddressRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_TYPEADDRESS_FINDALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_TYPEADDRESS_FINDALL;
  private readonly API_TYPEADDRESS_SAVE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_TYPEADDRESS_SAVE;
  private readonly API_TYPEADDRESS_UPDATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_TYPEADDRESS_UPDATE;
  private readonly API_TYPEADDRESS_DELETE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_TYPEADDRESS_DELETE;

  async create(typeAddress: TypeAddress) {
    try {
      await http.post<TypeAddress[]>(this.API_TYPEADDRESS_SAVE, JSON.stringify(typeAddress));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(typeAddress: TypeAddress) {
    try {
      await http.put<TypeAddress[]>(this.API_TYPEADDRESS_UPDATE, JSON.stringify(typeAddress));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async delete(typeAddress: TypeAddress) {
    try {
      await http.singleDelete<TypeAddress[]>(this.API_TYPEADDRESS_DELETE + `/${typeAddress.id}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAll(): Promise<TypeAddress[]> {
    try {
      return await http.get<TypeAddress[]>(this.API_TYPEADDRESS_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
