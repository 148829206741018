import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Command } from '@/core/cqrs/Command';
import { PaymentMethods } from '@/settings/domain/ParmsGeneral/paymentMethods/PaymentMethods';
import { PaymentMethodsRepository } from '@/settings/domain/ParmsGeneral/paymentMethods/PaymentMethodsRepository';

@Injectable()
export class CreatePaymentMethods extends Command<Promise<any>, PaymentMethods> {
  public constructor(
    @Inject(TYPES.PAYMENT_METHODS_REPOSITORY)
    private readonly repository: PaymentMethodsRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  public internalExecute(paymentMethods: PaymentMethods): Promise<any> {
    return this.repository.create(paymentMethods);
  }
}
