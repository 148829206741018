import { RackLevel } from '@/wms/domain/layout/rack/RackLevel';

export class Rack {
  id: number;
  maxCapacity: number;
  volume: number;
  levels: number;
  description: string;
  movementType: string;
  movementTypeDescription: string;
  createAt: string;
  updateAt: string;
  rackLevel: RackLevel[];
  active: boolean;
  show: boolean;
  secuenceCode: string;
  //keys for config layout
  layoutRackId: number;
  layoutId: number;
  storeId: number;
  zoneId: string;
  public constructor() {
    this.id = 0;
    this.maxCapacity = 0;
    this.volume = 0;
    this.levels = 0;
    this.description = '';
    this.movementType = '';
    this.movementTypeDescription = '';
    this.createAt = '';
    this.updateAt = '';
    this.rackLevel = [];
    this.active = true;
    this.show = false;
    this.secuenceCode = '';
    this.layoutRackId = 0;
    this.layoutId = 0;
    this.storeId = 0;
    this.zoneId = '';
  }
}
