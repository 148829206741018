
import localStorageVariables, { getLocalstorageItem } from '@/core/config/LocalStorageVariables';
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { CreateFileV2 } from '@/settings/application/uses_cases/file/create/CreateFileV2';
import { FindFile } from '@/settings/application/uses_cases/file/search/FindFile';
import { UserFindByEmail } from '@/settings/application/uses_cases/user/search/UserFindByEmail';
import { UpdateUserProfile } from '@/settings/application/uses_cases/user/update/UpdateUserProfile';
import { User } from '@/settings/domain/user/User';
import Vue from 'vue';
import Component from 'vue-class-component';
@Component({
  name: 'UserProfile'
})
export default class UserProfile extends Vue {
  @Inject(TYPES.USER_FIND_BY_EMAIL)
  private readonly findUserInfo!: UserFindByEmail;
  @Inject(TYPES.USER_UPDATE_PROFILE)
  private readonly updateUserProfile!: UpdateUserProfile;
  @Inject(TYPES.SAVE_FILE)
  private readonly saveFile!: CreateFileV2;
  @Inject(TYPES.FIND_FILE_BY_NAME)
  readonly findFileByName!: FindFile;

  userEmail = getLocalstorageItem('USER_EMAIL');

  user: User = new User();
  file: File | null = null;
  fileURL = 'https://via.placeholder.com/150';

  isLoading = false;
  showPassword = false;

  mounted() {
    this.findInfo();
  }

  get passwordType() {
    return this.showPassword ? 'text' : 'password';
  }

  async findInfo() {
    try {
      this.isLoading = true;
      if (!this.userEmail) {
        return;
      }
      this.user = await this.findUserInfo.internalExecute(this.userEmail);

      // Debemos revisar si el usuario tiene una imagen
      this.fileURL = this.findFileByName.internalExecute(this.user.image);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  }

  async save() {
    try {
      this.isLoading = true;

      const fileURL = await this.uploadFile();

      const payload: User = {
        ...this.user,
        image: fileURL
      };

      await this.updateUserProfile.execute(payload);
      this.isLoading = false;
      localStorage.setItem(localStorageVariables.USER_NAME, this.user.name);
      localStorage.setItem(localStorageVariables.LOGO_USER, payload.image);

      // Hacemos un refresh para que las imagenes se actualicen
      window.location.reload();
    } catch (error) {
      this.isLoading = false;
    }
  }

  async uploadFile() {
    try {
      this.isLoading = true;
      if (!this.file) {
        // Si no hay archivo, se retorna la imagen actual
        return this.user.image;
      }
      const res = await this.saveFile.internalExecute({
        files: [this.file],
        folder: 'users'
      });

      if (Array.isArray(res)) {
        return '';
      }

      return res;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  setFileToUpload(event: any) {
    try {
      this.isLoading = true;
      if (!event.target.files.length) {
        return;
      }
      this.file = event.target.files[0];
      this.fileURL = URL.createObjectURL(this.file as Blob);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }
}
