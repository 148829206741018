import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { PickingListForReport } from '@/freight/domain/pickingList/PickingListForReport';
import { PickingListRepository } from '@/freight/domain/pickingList/PickingListRepository';
@Injectable()
export class FindPickingListDataForReport extends Query<Promise<PickingListForReport>, number> {
  public constructor(
    @Inject(TYPES.PICKING_LIST_REPOSITORY)
    private readonly repository: PickingListRepository
  ) {
    super();
  }
  async internalExecute(pickinList: number): Promise<PickingListForReport> {
    return await this.repository.getDataForReport(pickinList);
  }
}
