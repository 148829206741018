
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { ReferenceFindAll } from '@/wms/application/catalogs/references/search/ReferenceFindAll';
import { FindAllStore } from '@/wms/application/layout/store/search/FindAllStore';
import { FindFreightMovements } from '@/wms/application/stock/search/FindFreightMovements';
import { FindFreightStockByDateRangeAndStore } from '@/wms/application/stock/search/FindFreightStockByDateRangeAndStore';
import { References } from '@/wms/domain/catalogs/references/References';
import { Store } from '@/wms/domain/layout/store/Store';
import { Stock } from '@/wms/domain/stock/Stock';
import Vue from 'vue';
import Component from 'vue-class-component';
import FreightStockMovements from '@/wms/infrastructure/ui/FreightStockDashboard/components/FreightStockMovements.vue';
import FreightStockResume from '@/wms/infrastructure/ui/FreightStockDashboard/components/FreightStockResume.vue';
import { StockDocumentsLin } from '@/wms/domain/stock/StockDocumentsLin';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { EntityFindFilter } from '@/settings/application/uses_cases/entity/search/EntityFindFilter';

interface Filters {
  year: number;
  from: number;
  to: number;
  store: Store | null;
  // reference: References | null;
  serial: string;
  entity: Entity | null;
}

@Component({
  name: 'FreightStockDashboard',
  components: {
    FreightStockMovements,
    FreightStockResume
  }
})
export default class FreightStockDashboard extends Vue {
  @Inject(TYPES.FIND_FREIGHT_STOCK)
  private findStockResume!: FindFreightStockByDateRangeAndStore;
  @Inject(TYPES.FIND_ALL_STORE)
  private findAllStore!: FindAllStore;
  @Inject(TYPES.FINDALL_REFERENCE)
  private findAllReference!: ReferenceFindAll;
  @Inject(TYPES.FIND_FREIGHT_STOCK_MOVEMENTS)
  private findStockMovements!: FindFreightMovements;
  @Inject(TYPES.ENTITY_FIND_BY_DESCRIPTION_LIKE)
  private findEntityByDescription!: EntityFindFilter;

  mounted() {
    this.getLists();
  }

  //Variables
  isLoading = false;

  //Search interval
  searchInterval = 500;

  //Timeouts
  timeoutEntity: any;

  //Data
  stockMovements: StockDocumentsLin[] = [];
  stockResume: Stock[] = [];

  //Filters
  filters: Filters = {
    year: new Date().getFullYear(),
    from: new Date().getMonth() + 1,
    to: new Date().getMonth() + 1,
    store: null,
    serial: '',
    entity: null
  };
  //Lists
  stores: Store[] = [];
  entities: Entity[] = [];
  references: References[] = [];
  //Methods

  async getResume() {
    try {
      if (!this.filters.store) throw new Error('Store is required');
      this.isLoading = true;

      const res = await this.findStockResume.execute({
        year: this.filters.year.toString(),
        iMonth: this.filters.from.toString(),
        fMonth: this.filters.to.toString(),
        store: this.filters.store?.storeId.toString(),
        serial: this.filters.serial ?? '',
        entity: String(this.filters.entity?.code ?? '')
      });

      this.stockResume = res;

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw error;
    }
  }

  async findReferences() {
    try {
      this.isLoading = true;
      const res = await this.findAllReference.execute();
      this.references = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw error;
    }
  }

  async findStores() {
    try {
      this.isLoading = true;
      const res = await this.findAllStore.execute();
      this.stores = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw error;
    }
  }

  async findMovements() {
    try {
      if (!this.filters.store) throw new Error('Store is required');

      this.isLoading = true;
      const res = await this.findStockMovements.execute({
        year: this.filters.year.toString(),
        iMonth: this.filters.from.toString(),
        fMonth: this.filters.to.toString(),
        store: this.filters.store?.storeId.toString(),
        serial: this.filters.serial ?? '',
        entity: String(this.filters.entity?.code ?? '')
      });
      this.stockMovements = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw error;
    }
  }

  getData() {
    this.findMovements();
    this.getResume();
  }

  getLists() {
    this.findReferences();
    this.findStores();
  }

  //Funcion invocada para buscar entidades basadas en el nombre
  findEntity(query: string) {
    try {
      clearTimeout(this.timeoutEntity);
      if (query.length < 2) return;
      const params: any = {
        text: query,
        type: ['SUPPLIER', 'CUSTOMER', 'SERVICE', 'OTHERS']
      };
      this.timeoutEntity = setTimeout(() => {
        (async () => {
          this.isLoading = true;
          const res = await this.findEntityByDescription.execute(params);
          this.entities = res.length > 0 ? res : [];
          this.isLoading = false;
          clearTimeout(this.timeoutEntity);
        })();
      }, this.searchInterval);
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
}
