import { Bulk } from '@/wms/domain/bulk/Bulk';

export class PreparationBulk extends Bulk {
  preRegisterPreparation: string;

  public constructor() {
    super();
    this.preRegisterPreparation = '';
  }
}
