import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { CostCenter } from '@/courier/domain/costCenter/CostCenter';
import { CostCenterRepository } from '@/courier/domain/costCenter/CostCenterRepository';

/**
 * @class CostCenterService
 * @description Servicio de para interactuar con el API de CostCenter
 * @implements CostCenterRepository
 * @version 1.0.0
 * @since 1.0.0
 */
@Injectable()
export class CostCenterService implements CostCenterRepository {
  /**
   * @description Ruta base del API
   * @type {string}
   */
  readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;

  /**
   * @description Ruta del API para Crud de CostCenter
   * @type {string}
   */
  readonly API_COST_CENTER: string = this.API_BASE_URI + localStorageModule().config.routes.API_COST_CENTER;

  /**
   * @description Ruta del API para buscar CostCenter por padre
   * @type {string}
   */
  readonly API_COST_CENTER_FIND_BY_PARENT: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_COST_CENTER_FIND_BY_PARENT;

  /**
   * @description Ruta del API para obtener el arbol de CostCenter
   * @type {string}
   */
  readonly API_COST_CENTER_GET_TREE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_COST_CENTER_GET_TREE;

  /**
   * @description Obtiene todos los CostCenter desde el API
   * @returns {Promise<CostCenter[]>}
   */
  async save(costCenter: CostCenter): Promise<CostCenter> {
    try {
      return await http.post(this.API_COST_CENTER, costCenter);
    } catch (error) {
      throw new Error('Method not implemented.');
    }
  }

  /**
   * @description Obtiene todos los CostCenter desde el API
   * @returns {Promise<CostCenter[]>}
   * @memberof CostCenterService
   */
  async delete(costCenter: CostCenter): Promise<CostCenter> {
    try {
      return await http.delete(this.API_COST_CENTER, costCenter);
    } catch (error) {
      throw new Error('Method not implemented.');
    }
  }

  /**
   * @description Obtiene todos los CostCenter desde el API
   * @returns {Promise<CostCenter[]>}
   * @memberof CostCenterService
   */
  async update(costCenter: CostCenter): Promise<CostCenter> {
    try {
      return await http._put(this.API_COST_CENTER, costCenter);
    } catch (error) {
      throw new Error('Method not implemented.');
    }
  }

  /**
   * @description Obtiene todos los CostCenter desde el API
   * @returns {Promise<CostCenter[]>}
   */
  async findByParent(parent: string): Promise<CostCenter[]> {
    try {
      return await http.get(`${this.API_COST_CENTER_FIND_BY_PARENT}parent=${parent}`);
    } catch (error) {
      throw new Error('Method not implemented.');
    }
  }

  /**
   * @description Obtiene todos los CostCenter desde el API
   * @returns {Promise<CostCenter[]>}
   */
  async getTree(): Promise<CostCenter[]> {
    try {
      return await http.get(`${this.API_COST_CENTER_GET_TREE}`);
    } catch (error) {
      throw new Error('Method not implemented.');
    }
  }

  /**
   * @description Obtiene todos los CostCenter desde el API
   * @returns {Promise<CostCenter[]>}
   */
  async findAll(): Promise<CostCenter[]> {
    try {
      return await http.get(this.API_COST_CENTER);
    } catch (error) {
      throw new Error('Method not implemented.');
    }
  }
}
