import { StandardPrice } from '@/wms/domain/catalogs/pricingEngine/SettingStandardPrice/StandardPrice';
import { SettingStandard } from '@/wms/domain/catalogs/pricingEngine/SettingStandard';
import { Offers } from '@/wms/domain/catalogs/pricingEngine/SettingOffers/Offers';
import { SettingOffers } from '@/wms/domain/catalogs/pricingEngine/SettingOffers';

export class PricingEngine {
  public code: string;
  public codeReference: string;
  public name: string;
  public basePrice: number;

  //StandardPrice
  public standardPrice: StandardPrice;
  public settingStandard: SettingStandard;

  //Offers
  public offers: Offers;
  public settingOffers: SettingOffers;

  public constructor() {
    this.code = '';
    this.codeReference = '';
    this.name = '';
    this.basePrice = 0;

    //StandardPrice
    this.standardPrice = new StandardPrice();
    this.settingStandard = new SettingStandard();

    //Offers
    this.offers = new Offers();
    this.settingOffers = new SettingOffers();
  }
}
