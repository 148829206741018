import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { Preparation } from '@/freight/domain/Preparation/Preparation';
import { PreparationRepository } from '@/freight/domain/Preparation/PreparationRepository';
@Injectable()
export class FindPreparationById extends Query<Promise<Preparation>, string> {
  constructor(
    @Inject(TYPES.CARGO_DETAILS_PRESET_REPOSITORY)
    private readonly repository: PreparationRepository
  ) {
    super();
  }

  async internalExecute(id: string): Promise<Preparation> {
    return await this.repository.findById(id);
  }
}
