
import Vue from 'vue';
import Component from 'vue-class-component';
import i18n from '@/core/plugins/i18n';
import { Prop } from 'vue-property-decorator';
import Swal from 'sweetalert2';

@Component({
  name: 'SwalConfirm',
  components: {}
})
export default class SwalConfirm extends Vue {
  title = `${this.$t('general.confirmAction')}`;
  descriptions = `${this.$t('general.areyousuretodeletethis')}`;

  @Prop({ type: Function, required: true }) readonly parentMethod!: any;
  @Prop({ type: Function, required: true }) readonly parentMethodCancel!: any;
  @Prop({ type: Object, required: true }) readonly object!: any;

  created() {
    this.excuteConfirm();
  }
  excuteConfirm() {
    Swal.fire({
      title: `${this.title}`,
      icon: 'info',
      text: `${this.descriptions}`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `${i18n.t('general.delete')}`,
      cancelButtonText: `${i18n.t('general.cancel')}`
    }).then(result => {
      if (result.isConfirmed) {
        this.parentMethod(this.object);
      } else {
        this.parentMethodCancel();
      }
    });
  }
}
