import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { WarehouseRepository } from '@/settings/domain/warehouse/WarehouseRepository';
import { Warehouse } from '@/settings/domain/warehouse/Warehouse';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class WarehouseServices implements WarehouseRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_WAREHOUSE_FINDALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_WAREHOUSE_FINDALL;
  private readonly API_WAREHOUSE_FINDALL_BY_CODE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_WAREHOUSE_FINDALL_BY_CODE;
  private readonly API_WAREHOUSE_FINDALL_BY_STATUS: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_WAREHOUSE_FINDALL_BY_STATUS;
  private readonly API_WAREHOUSE_SAVE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_WAREHOUSE_SAVE;
  private readonly API_WAREHOUSE_UPDATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_WAREHOUSE_UPDATE;
  private readonly API_WAREHOUSE_DELETE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_WAREHOUSE_DELETE;

  async create(state: Warehouse) {
    try {
      return await http.post<Warehouse[]>(this.API_WAREHOUSE_SAVE, JSON.stringify(state));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(state: Warehouse) {
    try {
      return await http.put<Warehouse[]>(this.API_WAREHOUSE_UPDATE, JSON.stringify(state));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async delete(state: Warehouse) {
    try {
      return await http.delete<Warehouse[]>(this.API_WAREHOUSE_DELETE, JSON.stringify(state));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAll(): Promise<Warehouse[]> {
    try {
      return await http.get<Warehouse[]>(this.API_WAREHOUSE_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findWarehouseByCode(code: string): Promise<Warehouse> {
    try {
      return await http.get<Warehouse>(this.API_WAREHOUSE_FINDALL_BY_CODE + '?code=' + code);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findWarehouseByStatus(status: boolean): Promise<Warehouse[]> {
    try {
      return await http.get<Warehouse[]>(this.API_WAREHOUSE_FINDALL_BY_STATUS + status);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
