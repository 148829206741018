import { City } from '../city/City';
import { Country } from '../country/Country';
import { States } from '../state/States';

export class ZoneLevel {
  code: string;
  country: Country | null;
  state: States | null;
  city: City | null;
  active: boolean;
  createAt: string;
  level: number | null;

  public constructor() {
    this.code = '';
    this.country = null;
    this.state = null;
    this.city = null;
    this.active = true;
    this.createAt = '';
    this.level = 0;
  }
}
