import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { LedgerAccount } from '@/courier/domain/ledgerAccount/LedgerAccount';
import { LedgerAccountRepository } from '@/courier/domain/ledgerAccount/LedgerAccountRepository';

@Injectable()
export class LedgerAccountService implements LedgerAccountRepository {
  /**
   * @name API_BASE_URI
   * @description Dirección base del API
   * @type {string}
   */
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;

  /**
   * @name API_LEDGER_ACCOUNT_SAVE
   * @description Dirección del API para guardar una cuenta contable
   * @type {string}
   */
  private readonly API_LEDGER_ACCOUNT_SAVE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_LEDGER_ACCOUNT;

  /**
   * @name API_LEDGER_ACCOUNT_UPDATE
   * @description Dirección del API para actualizar una cuenta contable
   * @type {string}
   */
  private readonly API_LEDGER_ACCOUNT_UPDATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_LEDGER_ACCOUNT;

  /**
   * @name API_LEDGER_ACCOUNT_DELETE
   * @description Dirección del API para eliminar una cuenta contable
   * @type {string}
   */
  private readonly API_LEDGER_ACCOUNT_DELETE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_LEDGER_ACCOUNT;

  /**
   * @name API_LEDGER_ACCOUNT_BY_AFE_AND_ACCOUNT
   * @description Dirección del API para obtener cuentas contables por su account & afe
   * @type {string}
   */
  private readonly API_LEDGER_ACCOUNT_BY_AFE_AND_ACCOUNT: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_LEDGER_ACCOUNT_BY_AFE_AND_ACCOUNT;

  /**
   * @name API_LEDGER_ACCOUNT_FIND_ALL
   * @description Dirección del API para obtener todas las cuentas contables
   * @type {string}
   */
  private readonly API_LEDGER_ACCOUNT_FIND_ALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_LEDGER_ACCOUNT;

  /**
   * @name API_LEDGER_ACCOUNT_BY_ACCOUNT
   * @description Dirección del API para obtener cuentas contables por su account
   * @type {string}
   */
  private readonly API_LEDGER_ACCOUNT_BY_ACCOUNT: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_LEDGER_ACCOUNT_BY_ACCOUNT;

  /**
   * @name API_LEDGER_ACCOUNT_BY_AFE
   * @description Dirección del API para obtener cuentas contables por su afe
   * @type {string}
   */

  private readonly API_LEDGER_ACCOUNT_BY_AFE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_LEDGER_ACCOUNT_BY_AFE;

  /**
   *
   * @param ledgerAccount
   * @returns La cuenta contable guardada
   * @throws Error al guardar la cuenta contable
   * @description Guarda la cuenta contable
   * @Author EnriquePallares
   */
  async save(ledgerAccount: LedgerAccount): Promise<LedgerAccount> {
    try {
      return await http.post(this.API_LEDGER_ACCOUNT_SAVE, ledgerAccount);
    } catch (error) {
      throw new Error('Error al guardar la cuenta contable');
    }
  }

  /**
   *
   * @param ledgerAccount
   * @returns La cuenta contable actualizada
   * @throws Error al actualizar la cuenta contable
   * @description Actualiza la cuenta contable
   * @Author EnriquePallares
   */
  async update(ledgerAccount: LedgerAccount): Promise<LedgerAccount> {
    try {
      return await http._put(this.API_LEDGER_ACCOUNT_UPDATE, ledgerAccount);
    } catch (error) {
      throw new Error('Error al actualizar la cuenta contable');
    }
  }

  /**
   *
   * @param ledgerAccount
   * @returns La cuenta contable eliminada
   * @throws Error al eliminar la cuenta contable
   * @description Elimina la cuenta contable
   * @Author EnriquePallares
   */
  async delete(ledgerAccount: LedgerAccount): Promise<LedgerAccount> {
    try {
      return await http.delete(this.API_LEDGER_ACCOUNT_DELETE, ledgerAccount);
    } catch (error) {
      throw new Error('Error al eliminar la cuenta contable');
    }
  }

  /**
   *
   * @param account Número de la cuenta contable
   * @param afe Es cuenta activa
   * @returns La cuenta contable encontrada
   * @throws Error al buscar la cuenta contable
   * @description Busca las cuentas contables por su account & afe
   * @Author EnriquePallares
   */
  async findByAfeAndAccount(account: string, afe: boolean): Promise<LedgerAccount[]> {
    try {
      return await http.get(`${this.API_LEDGER_ACCOUNT_BY_AFE_AND_ACCOUNT}account=${account}&afe=${afe}`);
    } catch (error) {
      throw new Error(`Error al buscar las cuentas contables: ${error}`);
    }
  }

  /**
   *
   * @param account Número de la cuenta contable
   * @returns La cuenta contable encontrada
   * @throws Error al buscar la cuenta contable
   * @description Busca las cuentas contables por su account
   * @Author EnriquePallares
   */
  async findByAccount(account: string): Promise<LedgerAccount> {
    try {
      return await http.get(`${this.API_LEDGER_ACCOUNT_BY_ACCOUNT}account=${account}`);
    } catch (error) {
      throw new Error(`Error al buscar la cuenta contables: ${error}`);
    }
  }

  /**
   *
   * @returns La cuenta contable encontrada
   * @throws Error al buscar la cuenta contable
   * @description Busca todas las cuentas contables
   * @Author EnriquePallares
   */
  async findAll(): Promise<LedgerAccount[]> {
    try {
      return await http.get(this.API_LEDGER_ACCOUNT_FIND_ALL);
    } catch (error) {
      throw new Error(`Error al buscar las cuentas contables: ${error}`);
    }
  }

  async findByAfe(afe: boolean): Promise<LedgerAccount[]> {
    try {
      return await http.get(`${this.API_LEDGER_ACCOUNT_BY_AFE}afe=${afe}`);
    } catch (error) {
      throw new Error(`Error al buscar las cuentas contables: ${error}`);
    }
  }
}
