
import { BulkPackingData } from '@/wms/domain/bulk/BulkPackingData';
import { GroupedBulk } from '@/wms/domain/bulk/GroupedBulk';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'BulkStock',
  components: {}
})
export default class BulkStock extends Vue {
  //Props
  @Prop({ required: true, default: [] }) groupedSku!: GroupedBulk[];
  @Prop({
    required: true,
    default: () => {
      return;
    }
  })
  pick!: (serials: string[], pick: boolean) => void;
  @Prop({
    required: true,
    default: () => {
      return [];
    }
  })
  pickedList!: string[];

  //Table fields
  get fields() {
    return [
      {
        key: 'reference',
        label: 'SKU',
        sortable: true,
        formatter: (value: string, key: string, item: GroupedBulk) => `${item.reference} - ${item.referenceName}`
      },
      {
        key: 'codeLocationPk',
        label: this.$t('general.location'),
        sortable: true
      },
      {
        key: 'customerName',
        label: 'Cliente',
        sortable: true,
        formatter: (value: string, key: string, item: GroupedBulk) => `${item.customerCode} - ${item.customerName}`
      },
      { key: 'consignee', label: this.$t('general.consignee'), sortable: true },
      { key: 'shipper', label: this.$t('general.shipper'), sortable: true },
      { key: 'show_details', label: this.$t('general.actions') }
    ];
  }

  get bulkFields() {
    return [
      { key: 'serial', label: 'Serial', sortable: true },
      {
        key: 'length',
        label: `${this.$t('general.length')} x ${this.$t('general.width')} x ${this.$t('general.height')}`,
        sortable: true,
        formatter: (value: string, key: string, item: BulkPackingData) =>
          `${item.length} x ${item.width} x ${item.height}`
      },
      {
        key: 'volume',
        label: this.$t('general.volume'),
        sortable: true,
        formatter: (value: string, key: string, item: BulkPackingData) => item.volume
      },
      {
        key: 'weight',
        label: this.$t('general.weight'),
        sortable: true,
        formatter: (value: string, key: string, item: BulkPackingData) => item.weight
      },
      {
        key: 'actions',
        label: this.$t('general.actions')
      }
    ];
  }

  columnWidthResizeOption = {
    enable: true,
    minWidth: 50
  };

  cellStyleOption = {
    headerCellClass: () => {
      return 'table-header-cell-class';
    }
  };

  selectAllOnGroup(group: GroupedBulk) {
    this.pick(
      group.bulks.map(bulk => bulk.serial),
      true
    );
  }

  unselectAllOnGroup(group: GroupedBulk) {
    this.pick(
      group.bulks.map(bulk => bulk.serial),
      false
    );
  }
}
