import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { DocumentRepository } from '@/courier/domain/documents/DocumentRepository';
import { Document } from '@/courier/domain/documents/Document';

@Injectable()
export class DocumentServices implements DocumentRepository {
  private readonly API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;
  private readonly API_DOCUMENT_FIND_ALL_BY_TYPE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_DOCUMENTS_FINDALL_BY_TYPE;
  private readonly API_DOCUMENT_SAVE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_DOCUMENTS_SAVE;
  private readonly API_DOCUMENTS_UPDATE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_DOCUMENTS_UPDATE;
  private readonly API_DOCUMENTS_FIND_BY_PK: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_DOCUMENTS_FIND_BY_PK;
  private readonly API_DOCUMENTS_BY_SWITCHS: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_DOCUMENTS_BY_SWITCHS;

  async findAllByDocumentType(payload: any): Promise<Document[]> {
    try {
      return await http.get<Document[]>(this.API_DOCUMENT_FIND_ALL_BY_TYPE + `${payload}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async saveDocument(payload: Document): Promise<Document[]> {
    try {
      return await http.post<Document[]>(this.API_DOCUMENT_SAVE, JSON.stringify(payload));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async updateDocument(payload: Document): Promise<Document[]> {
    try {
      return await http._put<Document[]>(this.API_DOCUMENTS_UPDATE, JSON.stringify(payload));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findByPk(payload: { type: string; number: number }): Promise<Document> {
    try {
      return await http.get<Document>(
        this.API_DOCUMENTS_FIND_BY_PK + `?number=${payload.number}&typeOrder=${payload.type}`
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findBySwitchsAndFilters(payload: any): Promise<Document[]> {
    try {
      return await http.get<Document[]>(
        this.API_DOCUMENTS_BY_SWITCHS + `${payload.from}/${payload.to}/${payload.code}`
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
