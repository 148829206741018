
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { FindTransactionalActivityById } from '@/tracking/application/uses_cases/activitiesTransactional/search/FindTransactionalActivityById';
import { ActivityTransactional } from '@/tracking/domain/activitiesTransactional/ActivityTransactional';
import { ActivityTransactionalForTraceability } from '@/tracking/domain/activitiesTransactional/Projections/ActivityTransactionalForTraceability';
import { FindBulksForPutAway } from '@/wms/application/bulk/search/FindBulkForPutAway';
import { SetBulkForPutAway } from '@/wms/application/bulk/search/SetBulkPutAway';
import { BulkForPutAway } from '@/wms/domain/bulk/BulkForPutAway';
import { PreRegisterForTraceabilityPanel } from '@/wms/domain/preRegister/PreRegisterForTraceabilityPanel';
import TransactionalEventForTraceability from '@/wms/domain/preRegister/TransactionalEventForTraceability';
import { BvModalEvent } from 'bootstrap-vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import WmsConsolidationCenter from '../../../consolidation/WmsConsolidationCenter.vue';
import PutAwayCenter from '../../../putAway/PutAwayCenter.vue';
import FooterModal from './FooterModals/FooterModal.vue';
import HeaderModal from './HeaderModals/HeaderModal.vue';

@Component({
  name: 'ModalC16',
  components: { HeaderModal, FooterModal, WmsConsolidationCenter, PutAwayCenter }
})
export default class ModalC16 extends Vue {
  @Inject(TYPES.ACTIVITIES_TRANSACTIONAL_FIND_BY_ID)
  private readonly findActivityById!: FindTransactionalActivityById;
  @Inject(TYPES.BULK_FIND_FOR_PUT_AWAY)
  private readonly findBulkforPutAway!: FindBulksForPutAway;
  @Inject(TYPES.BULK_PUT_AWAY_BULK) private readonly putAwayBulk!: SetBulkForPutAway;

  @Prop() isLoading!: boolean;
  @Prop() activityData!: ActivityTransactionalForTraceability;
  @Prop() eventData!: TransactionalEventForTraceability;
  @Prop({ type: Function }) saveProcess!: (activityData: ActivityTransactional) => Promise<void>;
  @Prop() attachedFiles!: any[]; // Toca cambiar el tipo de dato
  @Prop() master!: PreRegisterForTraceabilityPanel;
  @Prop({ type: Function }) searchActivities!: () => Promise<void>;

  $refs!: {
    consolidationCenter: WmsConsolidationCenter;
  };
  serial = '';
  location = '';
  matchSerialBulk: BulkForPutAway[] = [];
  bulks: BulkForPutAway[] = [];

  modalActivityData: ActivityTransactional = new ActivityTransactional();

  mounted() {
    this.findBulksForPutAway();
  }

  @Watch('attachedFiles')
  onMyFilesChange() {
    // Debemos hacer que el padre actualice el valor de attachedFiles
    this.$emit('update:attachedFiles', this.attachedFiles);
  }

  get localIsLoading() {
    return this.isLoading;
  }

  set localIsLoading(value) {
    this.$emit('update:isLoading', value);
  }

  get eventExecuted() {
    return this.eventData.state === 'EXECUTED';
  }

  get modalTitle() {
    return `${this.eventData.description} - ${this.$t('switchs.' + this.activityData?.sw)} - ${
      this.activityData.keyModal
    }`;
  }
  save(event: BvModalEvent) {
    event.preventDefault();
    if (!this.modalActivityData) return;
    this.saveProcess(this.modalActivityData);
  }

  async findActivityData() {
    try {
      if (!this.modalActivityData) return;

      this.localIsLoading = true;

      this.modalActivityData = await this.findActivityById.execute({
        activityId: this.activityData?.activityId,
        eventId: this.eventData.eventId,
        preRegisterNumber: this.master.number,
        preRegisterType: this.master.type
      });
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.localIsLoading = false;
    }
  }

  async findBulksForPutAway() {
    try {
      const res = await this.findBulkforPutAway.internalExecute({
        number: this.master.number,
        type: this.master.type
      });
      this.bulks = res;
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  matchBulkSerial() {
    const matchSerial = this.bulks.filter(bulk => {
      return bulk.serial === this.serial;
    });

    if (matchSerial.length === 0) {
      this.serial = '';
      this.$toast.error(this.$t('general.notFoundRecord'));
      return;
    }
    this.matchSerialBulk = matchSerial;
  }

  async matchBulkLocation() {
    if (!this.matchBulkSerial) return;
    const matchLocation = this.matchSerialBulk.find(bulk => {
      return bulk.location === this.location;
    });

    if (!matchLocation) {
      this.location = '';
      this.$toast.error(this.$t('general.notFoundRecord'));
      return;
    }
    await this.setPutAway(matchLocation);
  }

  async setPutAway(bulk: BulkForPutAway) {
    try {
      this.localIsLoading = true;
      await this.putAwayBulk.execute({ type: bulk.type, number: bulk.number, sequence: bulk.sequence });
      this.findBulksForPutAway();
      this.$toast.success(this.$t('general.success'));
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.localIsLoading = false;
    }
  }
}
