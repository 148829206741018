import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Command } from '@/core/cqrs/Command';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Role } from '@/settings/domain/role/Role';
import { RoleRepository } from '@/settings/domain/role/RoleRepository';

@Injectable()
export class UpdateRole extends Command<Promise<any>, Role> {
  public constructor(
    @Inject(TYPES.STATE_MANAGER) private readonly stateManager: StateManager,
    @Inject(TYPES.ROLE_REPOSITORY) private readonly roleRepository: RoleRepository
  ) {
    super();
  }
  internalExecute(role: Role): Promise<any> {
    return this.roleRepository.update(role);
  }
}
