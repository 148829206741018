import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { OrderNoveltyRepository } from '@/tracking/domain/orderNovelty/OrderNoveltyRepository';
import { OrderNovelty } from '@/tracking/domain/orderNovelty/OrderNovelty';

@Injectable()
export class FindByTypeAndNumberOrderNovelty extends Command<
  Promise<OrderNovelty[]>,
  { orderType: string; orderNumber: number }
> {
  public constructor(
    @Inject(TYPES.ORDER_NOVELTY_REPOSITORY)
    private readonly repository: OrderNoveltyRepository
  ) {
    super();
  }

  public async internalExecute(payload: { orderType: string; orderNumber: number }): Promise<OrderNovelty[]> {
    try {
      return await this.repository.findByOrder(payload)
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
