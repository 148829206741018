import { TYPES } from '@/core/config/Types';
import { Injectable } from '@/core/di/Injectable';
import { RecentShipments } from '@/settings/domain/recentShipments/RecentShipments';
import { RecentShipmentsRepository } from '@/settings/domain/recentShipments/RecentShipmentsRepository';
import { Inject } from '@/core/di/Inject';
import { Query } from '@/core/cqrs/Query';

@Injectable()
export class FindAllRecentShipments extends Query<Promise<RecentShipments[]>> {
  public constructor(
    @Inject(TYPES.RECENT_SHIPMENT_REPOSITORY) private readonly recentShipmentRepository: RecentShipmentsRepository
  ) {
    super();
  }

  async internalExecute(): Promise<RecentShipments[]> {
    return await this.recentShipmentRepository.findAll();
  }
}
