import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { UserRepository } from '@/settings/domain/user/UserRepository';

@Injectable()
export class DeleteUser extends Command<Promise<any>, { username: string; company: string }> {
  public constructor(
    @Inject(TYPES.USER_REPOSITORY)
    private readonly userRepository: UserRepository
  ) {
    super();
  }

  internalExecute({ username, company }: { username: string; company: string }): Promise<any> {
    return this.userRepository.delete(username, company);
  }
}
