import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { States } from '@/settings/domain/state/States';
import { StateRepository } from '@/settings/domain/state/StateRepository';

@Injectable()
export class StateFindByCountryAndDescription extends Query<Promise<States[]>, any> {
  public constructor(@Inject(TYPES.STATE_REPOSITORY) private readonly stateRepository: StateRepository) {
    super();
  }

  async internalExecute(params: any) {
    return await this.stateRepository.findByCountryAndDescription(params);
  }
}
