import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { CurrentMonthRepository } from '@/settings/domain/currentMonth/CurrentMonthRepository';
import { CurrentMonth } from '@/settings/domain/currentMonth/CurrentMonth';
/**
 * @class FindAllCurrentMonth
 * @description Encuentra el mes actual
 * @version 1.0.0
 * @since 1.0.0
 */

@Injectable()
export class FindAllCurrentMonth {
  public constructor(
    @Inject(TYPES.CURRENT_MONTH_REPOSITORY) // Inyecta el repositorio de documentos
    private readonly repository: CurrentMonthRepository // Instancia del repositorio de documentos
  ) {}

  public async execute(): Promise<CurrentMonth> {
    // Ejecuta el caso de uso
    return await this.repository.getCurrentMonth();
  }
}
