import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { Preparation } from '@/freight/domain/Preparation/Preparation';
import { PreparationForList } from '@/freight/domain/Preparation/PreparationForList';
import { PreparationRepository } from '@/freight/domain/Preparation/PreparationRepository';

@Injectable()
export class PreparationService implements PreparationRepository {
  private readonly API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;

  private readonly API_CARGO_DETAILS_PRESET: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_CARGO_DETAILS_PRESET;

  findAll(): Promise<PreparationForList[]> {
    try {
      return http.get(this.API_CARGO_DETAILS_PRESET);
    } catch (error) {
      throw new Error('Error while fetching cargo details preset list');
    }
  }
  create(cargoDetailsPreset: Preparation): Promise<void> {
    try {
      return http.VOID_POST(this.API_CARGO_DETAILS_PRESET, JSON.stringify(cargoDetailsPreset));
    } catch (error) {
      throw new Error('Error while creating cargo details preset');
    }
  }
  update(cargoDetailsPreset: Preparation): Promise<void> {
    try {
      return http.VOID_PUT(this.API_CARGO_DETAILS_PRESET, JSON.stringify(cargoDetailsPreset));
    } catch (error) {
      throw new Error('Error while updating cargo details preset');
    }
  }
  delete(id: string): Promise<void> {
    try {
      return http.singleDelete(this.API_CARGO_DETAILS_PRESET + '/' + id);
    } catch (error) {
      throw new Error('Error while deleting cargo details preset');
    }
  }
  findById(id: string): Promise<Preparation> {
    try {
      return http.get(this.API_CARGO_DETAILS_PRESET + '/' + id);
    } catch (error) {
      throw new Error('Error while fetching cargo details preset');
    }
  }

  findByStatus(status: 'USED' | 'NOT_USED'): Promise<PreparationForList[]> {
    try {
      return http.get(this.API_CARGO_DETAILS_PRESET + '/status/' + status);
    } catch (error) {
      throw new Error('Error while fetching cargo details preset list');
    }
  }
}
