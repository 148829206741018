
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { RoutesFindByStatus } from '@/settings/application/uses_cases/routes/search/RoutesFindByStatus';
import { Routes } from '@/settings/domain/routes/Routes';
import { SwitchFindAllByProcessType } from '@/tracking/application/uses_cases/switches/search/SwitchFindAllByProcessType';
import { MonetaryTransactionFindAll } from '@/tracking/application/uses_cases/monetarytransaction/search/findall/MonetaryTransactionFindAll';
import { StateManager } from '@/core/statemanager/StateManager';
import { EventsFindByProcessType } from '@/tracking/application/uses_cases/events/search/EventsFindByProcessType';
import { Events } from '@/tracking/domain/events/Events';

@Component({
  name: 'AsociateEventsN'
})
export default class AsociateEventsN extends Vue {
  @Inject(TYPES.FINDBYSTATUS_ROUTES)
  readonly findAllActiveRoutes!: RoutesFindByStatus;
  @Inject(TYPES.FINDBYPROCESSTYPE_SWITCH)
  readonly findSwitchByProcess!: SwitchFindAllByProcessType;
  @Inject(TYPES.FINDALL_MONETARY_TRANSACTION)
  readonly monetaryTransactionFindAll!: MonetaryTransactionFindAll;
  @Inject(TYPES.STATE_MANAGER)
  stateManager!: StateManager;
  @Inject(TYPES.FINDEVENTSBYPROCESSTYPE_EVENTS)
  readonly eventsFindByProcessType!: EventsFindByProcessType;

  @Prop() readonly timeline!: any;
  @Prop() readonly typeComponent!: string;
  @Prop() readonly updateTimeline!: Function;
  @Prop() readonly createConfigTimeline!: Function;

  //Data
  isLoading = false;

  //Selects
  selectedRoute: Routes | null = null;
  selectedEvents: Events[] = [];

  //Listas
  routesList: Routes[] = [];

  eventList: any[] = [];
  monetarytransactions: any[] = [];
  activeRoutes: Routes[] = [];
  placeholder = `${this.$t('general.selectanoption')}`;

  async created() {
    await this.typeMonetary();
    this.getRoutes();
    if (this.typeComponent == 'asociate') {
      this.getEvents();
    } else {
      this.eventList = Object.assign([], this.timeline.events);

      //Funcion invocada para convertir la propiedad typeMonetaryTransactionId de las actividades a un objeto que pueda leer el multiselect (Hacer una funcion para esto)
      this.eventList = this.eventList.map((item: any) => {
        item.activities = item.activities.map((activity: any) => {
          return {
            ...activity,
            monetaryTransactionId: this.findTypeMonetaryObject(activity.monetaryTransactionId)[0] || null
          };
        });
        return item;
      });
    }
  }

  //Funcion invocada para obtener todos las activas
  async getRoutes() {
    try {
      this.isLoading = true;
      const res = await this.findAllActiveRoutes.execute(true);
      this.routesList = res.length > 0 ? res.reverse() : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para obtener todos los tipos de movimientos monetarios
  async typeMonetary() {
    try {
      this.isLoading = true;
      const res = await this.monetaryTransactionFindAll.execute();
      this.monetarytransactions = res.length > 0 ? res.reverse() : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para obtener todos los eventos desde la BD
  async getEvents() {
    try {
      this.isLoading = true;
      const res = await this.eventsFindByProcessType.execute(3);
      this.eventList =
        res.length > 0
          ? //Se hace un filtro para no mostrar eventos que ya han sido asignados al timeline
            res.filter((listEvent: any) => {
              return !this.timeline.events.filter(
                (timelineEvent: any) => listEvent.description == timelineEvent.description
              ).length;
            })
          : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para validar los inputs
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  //Funcion para ver cosas
  verCosas(algo?: any) {
    // eslint-disable-next-line no-console
    console.log(algo || this.selectedEvents);
  }

  //Funcion para determinar la acción del botón "Asociar eventos"
  factory() {
    this.typeComponent == 'asociate' ? this.update() : this.createConfig();
  }

  //Funcion para trasformar el array de eventos seleccionados en los eventos del payload
  update() {
    this.timeline.events = this.addEvents(this.selectedEvents);
    this.updateTimeline(this.timeline, true);
  }

  //Funcion para trasformar el array de eventos seleccionados en los eventos del payload
  createConfig() {
    const newTimelineConfig: any = Object.assign(
      {},
      {
        ...this.timeline
      }
    );
    newTimelineConfig.routeId = this.selectedRoute?.id || 0;
    newTimelineConfig.events = this.addEvents(newTimelineConfig.events);
    this.createConfigTimeline(newTimelineConfig, true);
  }

  //Funcion invocada para dar un status de activo o inactivo a los eventos en el modal de asociacion
  manageChecks(status: boolean) {
    this.eventList.map(item => (item.active = status));
  }

  //Funcion invocada para crear un payload de eventos valido
  addEvents(eventList: Events[]) {
    return eventList.map((event: any, index: number) => ({
      ...event,
      sequence: index + 1,
      activities: event.activities.map((activity: any) => ({
        ...activity,
        activityId: null,
        eventReference: event.id,
        monetaryTransactionName: activity.monetaryTransactionId && activity.monetaryTransactionId.name,
        monetaryTransactionId: activity.monetaryTransactionId && activity.monetaryTransactionId.id
      }))
    }));
  }

  findTypeMonetaryObject(id: number) {
    return this.monetarytransactions.filter(transaction => transaction.id == id);
  }
}
