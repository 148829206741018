import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { PackingUnitRepository } from '@/wms/domain/packingUnit/PackingUnitRepository';
import { PackingUnit } from '@/wms/domain/packingUnit/PackingUnit';

@Injectable()
export class FindPackagingByType extends Query<Promise<PackingUnit[]>, string> {
  public constructor(
    @Inject(TYPES.PACKINGUNIT_REFERENCE_REPOSITORY)
    private readonly packingUnitRepository: PackingUnitRepository
  ) {
    super();
  }

  async internalExecute(type: string): Promise<PackingUnit[]> {
    const dataPackaging = await this.packingUnitRepository.findAllByType(type);
    return dataPackaging;
  }
}
