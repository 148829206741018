
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import LanguageSwitcher from '@/core/components/LanguageSwitcher.vue';
import { LoginFindToken } from '@/courier/application/uses_cases/login/search/findtoken/LoginFindToken';
import { showAlert } from '@/core/plugins/Notifications';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

@Component({
  name: 'Login',
  components: { LanguageSwitcher, VueSlickCarousel }
})
export default class LoginN extends Vue {
  @Inject(TYPES.LOGIN_USER)
  loginFindToken!: LoginFindToken;

  //Data
  isLoading = false;
  slides = [
    {
      image: 'slide-1.png',
      title: 'JOIN OUR ECOSYSTEM',
      description: 'Companies of all sizes already boost their logistics with us'
    },
    {
      image: 'slide-2.png',
      title: 'PLAN, AUTOMATE AND OPTIMIZE YOUR OPERATION',
      description: 'Distribute more and better in less time'
    },
    {
      image: 'slide-3.png',
      title: 'IMMEDIATE SETUP',
      description: 'Get started right away, without code or integrations'
    },
    {
      image: 'slide-4.png',
      title: 'DRIVES CUSTOMER EXPERIENCE',
      description: 'Better notification, ensuring brand presence from start to finish'
    }
  ];

  //Formulario
  form: {
    username: string;
    password: string;
  } = {
    username: '',
    password: ''
  };

  //Getter para obtener el año actual
  get currentYear() {
    return new Date().getFullYear();
  }

  //Metodo invocado para validar inputs
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }): any {
    return dirty || validated ? valid : null;
  }

  //Metodo para limpiar los campos
  clearForm() {
    this.form.username = '';
    this.form.password = '';
  }

  //Funcion invocada para hacer login
  async Login() {
    this.isLoading = true;
    const payload: {
      username: string;
      password: string;
    } = {
      username: this.form.username.trim(),
      password: this.form.password.trim()
    };

    try {
      await this.loginFindToken.internalExecute(payload);
      this.isLoading = false;
      this.$toasted.show(`${this.$t('general.welcome')}`);
      this.$router.replace({ name: 'WorkspaceSelection' });
    } catch (error) {
      this.isLoading = false;
      showAlert('warning', this.$t('generalmessage.ERROR_MESSAGE_LOGIN'));
      throw new Error(`${error}`);
    }
  }
}
