export class Menu {
  id: string;
  icon: string;
  parent: string;
  badgeType: string;
  children: any[];
  path: string;
  title: string;
  type: string;
  active: boolean;
  sequence: number;
  selected: boolean;
  admin: boolean;

  public constructor() {
    this.id = '0';
    this.icon = '';
    this.parent = '';
    this.badgeType = '';
    this.children = [];
    this.path = '';
    this.title = '';
    this.type = 'sub';
    this.active = true;
    this.sequence = 1;
    this.selected = false;
    this.admin = false;
  }
}
