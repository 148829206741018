import { TYPES } from '@/core/config/Types';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Query } from '@/core/cqrs/Query';
import { LoginRepository } from '@/courier/domain/login/LoginRepository';
import { Workspace } from '@/courier/domain/workspace/Workspace';

@Injectable()
export class GetWorkspaces extends Query<Promise<Workspace[]>> {
  public constructor(
    @Inject(TYPES.LOGIN_REPOSITORY)
    private readonly loginRepository: LoginRepository
  ) {
    super();
  }

  internalExecute(): Promise<Workspace[]> {
    return this.loginRepository.getWorkspaces();
  }
}
