export class EmailTypeConfig {
  id: number;
  type: string;
  company: string;
  file: string;

  public constructor() {
    this.id = 0;
    this.type = '';
    this.company = '';
    this.file = '';
  }
}
