import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { HouseBillOfLandingRepository } from '@/wms/domain/houseBillOfLading/HouseBillOfLadingRepository';
import { HouseBillOfLading } from '@/wms/domain/houseBillOfLading/HouseBillOfLading';

@Injectable()
export class FindHousesByOperation extends Command<Promise<HouseBillOfLading[]>, { number: number; type: string }> {
  public constructor(
    @Inject(TYPES.HOUSE_BILL_OF_LADING_REPOSITORY)
    private readonly repository: HouseBillOfLandingRepository
  ) {
    super();
  }

  public async internalExecute(payload: { type: string; number: number }): Promise<HouseBillOfLading[]> {
    return await this.repository.findHousesByOperation(payload);
  }
}
