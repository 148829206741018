import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Command } from '@/core/cqrs/Command';
import { Country } from '@/settings/domain/country/Country';
import { CountryRepository } from '@/settings/domain/country/CountryRepository';

@Injectable()
export class CountryUpdate extends Command<Promise<any>, Country> {
  public constructor(
    @Inject(TYPES.TERRITORIES_REPOSITORY)
    private readonly countryRepository: CountryRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  internalExecute(country: Country): Promise<any> {
    return this.countryRepository.update(country);
  }
}
