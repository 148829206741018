import { Injectable } from '@/core/di/Injectable';
import { ActivityTransactionalRepository } from '@/tracking/domain/activitiesTransactional/ActivityTransactionalRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { ActivityTransactional } from '@/tracking/domain/activitiesTransactional/ActivityTransactional';
import { http } from '@/core/http/HttpFetch';
import { ActivityTransactionalForTraceability } from '@/tracking/domain/activitiesTransactional/Projections/ActivityTransactionalForTraceability';

@Injectable()
export class ActivityTransactionalService implements ActivityTransactionalRepository {
  private readonly API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;
  private readonly API_ACTIVITIES_TRANSACTIONAL_UPDATE_STATUS: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_TRANSACTIONA_ACTIVITY_UPDATE_STATUS;
  private readonly API_TRANSACTIONAL_ACTIVITIES_FIND_BY_ID: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_TRANSACTIONAL_ACTIVITIES_FIND_BY_ID;
  private readonly API_TRANSACTIONAL_ACTIVITIES_FIND_FOR_TRACEABILITY_BY_EVENT: string =
    this.API_BASE_URI +
    localStorageModule().transactions.routes.API_TRANSACTIONAL_ACTIVITIES_FIND_FOR_TRACEABILITY_BY_EVENT;

  async updateStatus(activity: ActivityTransactional) {
    try {
      return await http._put<ActivityTransactional>(
        this.API_ACTIVITIES_TRANSACTIONAL_UPDATE_STATUS,
        JSON.stringify(activity)
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  findById({
    preRegisterType,
    preRegisterNumber,
    eventId,
    activityId
  }: {
    preRegisterType: string;
    preRegisterNumber: number;
    eventId: number;
    activityId: number;
  }): Promise<ActivityTransactional> {
    try {
      return http.get<ActivityTransactional>(
        `${this.API_TRANSACTIONAL_ACTIVITIES_FIND_BY_ID}?preRegisterType=${preRegisterType}&preRegisterNumber=${preRegisterNumber}&eventId=${eventId}&activityId=${activityId}`
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findActivitiesForTraceabilityByEventId({
    preRegisterType,
    preRegisterNumber,
    eventId
  }: {
    preRegisterType: string;
    preRegisterNumber: number;
    eventId: number;
  }) {
    try {
      return await http.get<ActivityTransactionalForTraceability[]>(
        `${this.API_TRANSACTIONAL_ACTIVITIES_FIND_FOR_TRACEABILITY_BY_EVENT}preRegisterType=${preRegisterType}&preRegisterNumber=${preRegisterNumber}&eventId=${eventId}`
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
