import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Command } from '@/core/cqrs/Command';
import { Process } from '@/tracking/domain/processtype/Process';
import { ProcessRepository } from '@/tracking/domain/processtype/ProcessRepository';

@Injectable()
export class CreateProcess extends Command<Promise<any>, Process> {
  public constructor(
    @Inject(TYPES.STATE_MANAGER) private readonly stateManager: StateManager,
    @Inject(TYPES.PROCESS_TYPE_REPOSITORY)
    private readonly processRepository: ProcessRepository
  ) {
    super();
  }
  internalExecute(process: Process): Promise<any> {
    return this.processRepository.create(process);
  }
}
