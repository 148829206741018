
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import AddWarehousePreRegister from '@/wms/infrastructure/ui/warehousePreRegister/AddWarehousePreRegister.vue';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import { PreRegisterSave } from '@/wms/application/preRegister/create/PreRegisterSave';
import { PreRegisterTypeEnum } from '@/wms/domain/preRegister/PreRegisterTypeEnum';
import { PreRegisterStatusEnum } from '@/wms/domain/preRegister/PreRegisterStatusEnum';
import { Bulk } from '@/wms/domain/bulk/Bulk';
import { PreRegisterUpdate } from '@/wms/application/preRegister/update/PreRegisterUpdate';
import { PreRegisterFindByPk } from '@/wms/application/preRegister/search/PreRegisterFindByPk';
import { FindTransactionalActivityById } from '@/tracking/application/uses_cases/activitiesTransactional/search/FindTransactionalActivityById';
import { ActivityTransactionalUpdateStatus } from '@/tracking/application/uses_cases/activitiesTransactional/update/ActivityTransactionalUpdateStatus';
import { ActivityTransactional } from '@/tracking/domain/activitiesTransactional/ActivityTransactional';

@Component({
  name: 'WarehouseTaggingCenter',
  components: { AddWarehousePreRegister }
})
export default class WarehouseTaggingCenter extends Vue {
  @Inject(TYPES.PRE_REGISTER_SAVE)
  readonly preRegisterSave!: PreRegisterSave;
  @Inject(TYPES.PRE_REGISTER_UPDATE)
  readonly preRegisterUpdate!: PreRegisterUpdate;
  @Inject(TYPES.PRE_REGISTER_FIND_BY_PK)
  readonly findByPk!: PreRegisterFindByPk;
  @Inject(TYPES.ACTIVITIES_TRANSACTIONAL_FIND_BY_ID)
  readonly activitiesTransactionalFindById!: FindTransactionalActivityById;
  @Inject(TYPES.ACTIVITIES_TRANSACTIONAL_UPDATE_STATUS)
  readonly updateStatusActivity!: ActivityTransactionalUpdateStatus;

  //Datos
  isLoading = false;
  quantity = 0;
  suggestedDate = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substring(0, 10);
  componentType = 1;
  activityTransactional!: ActivityTransactional;

  //Objeto de acciones para componente hijo
  actions = {
    save: this.factory,
    clear: this.clear
  };

  //Formulario
  form: PreRegister = new PreRegister();

  //Getter para obtener la user data desde localstorage
  get userData() {
    return `${localStorage.getItem('userName') as string}`;
  }

  //Getter para obtener los parametros de la URL
  get urlParams() {
    return {
      query: this.$route.query
    };
  }

  //Ciclo de vida del componente
  mounted() {
    //Si no viene desde una pantalla de busqueda entonces crea un pre registro vacio.
    !('type' in this.urlParams.query)
      ? this.clear()
      : (() => {
          this.find();
          this.getActivityTransactional();
        })();
  }

  //Metodo ejecutado para guardar el objeto en la base de datos
  async save() {
    try {
      this.isLoading = true;
      this.form.type = `${this.form.warehouse?.code}_${PreRegisterTypeEnum.PRE_REGISTER}`;
      this.form.bulks.map((item: Bulk) => (item.type = `${this.form.warehouse?.code}_${this.form.type}`));
      if (this.form.timeline) {
        if (this.form.timeline.typeTransport) {
          this.form.timeline.typeTransport = {
            id: Number(this.form.timeline.typeTransport),
            active: true,
            name: ''
          };
        }

        if (this.form.timeline.typeOperation) {
          this.form.timeline.typeOperation = {
            code: String(this.form.timeline.typeOperation),
            nature: '',
            status: true,
            sw: ''
          };
        }

        if (this.form.timeline.processType) {
          this.form.timeline.processType = {
            id: Number(this.form.timeline.processType),
            name: '',
            nameEs: '',
            active: true
          };
        }
      }
      const res = await this.preRegisterSave.execute(this.form);
      if (!('error' in res)) {
        this.clear();
        await this.findPreRegister(res);
        this.form.state = PreRegisterStatusEnum.TAGGED;
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.form.state = PreRegisterStatusEnum.TAGGED;
      throw new Error(`${error}`);
    }
  }

  //Metodo ejecutado para guardar el objeto en la base de datos
  async update() {
    try {
      this.isLoading = true;
      this.form.state = this.form.state = PreRegisterStatusEnum.TAGGED;
      const res = await this.preRegisterUpdate.execute(this.form);

      if (!('error' in res)) {
        await this.executeActivity();
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Metodo ejecutado para limpiar el formulario
  clear() {
    this.form = new PreRegister();
    this.form.receivedBy = this.userData;
    this.form.date = this.suggestedDate;
    this.form.state = PreRegisterStatusEnum.TAGGED;
  }

  //Funcion invocada para obtener un pre registro concreto
  async findPreRegister(input: PreRegister) {
    try {
      this.isLoading = true;
      const res = await this.findByPk.execute({
        type: input.type,
        number: input.number
      });

      this.componentType = 3;
      this.form = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  }

  //Metodo ejecutado para obtener los datos completos del documento basado en la primary key del mismo
  async find() {
    try {
      this.isLoading = true;
      const res = await this.findByPk.execute({
        type: this.urlParams.query.type,
        number: this.urlParams.query.number
      });
      if (!('error' in res)) {
        this.form = res;
        this.form.state = PreRegisterStatusEnum.TAGGED;
        this.componentType = 3;
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para decidir que accion realizar en la base de datos
  async factory() {
    !this.form.trackingNumber ? await this.save() : await this.update();
  }

  /**
   * Funcion invocada para obtener la informacion de la actividad transaccional
   */
  async getActivityTransactional() {
    try {
      this.isLoading = true;
      const res = await this.activitiesTransactionalFindById.execute({
        activityId: Number(this.urlParams.query?.activityId ?? '0'),
        eventId: Number(this.urlParams.query?.eventId ?? '0'),
        preRegisterNumber: Number(this.urlParams.query?.number ?? '0'),
        preRegisterType: String(this.urlParams.query?.type ?? '')
      });
      if (!('error' in res)) {
        this.activityTransactional = res;
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  /**
   * Funcion invocada para actualizar el estado de la actividad transaccional
   */
  async executeActivity() {
    try {
      if (!this.activityTransactional || this.activityTransactional?.executed) return;
      this.isLoading = true;

      this.activityTransactional.executed = true;
      await this.updateStatusActivity.execute(this.activityTransactional);

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
}
