import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { UserRepository } from '@/settings/domain/user/UserRepository';

@Injectable()
export class InviteUserToNamespace extends Command<Promise<any>, { email: string; namespace: string; role: number }> {
  public constructor(
    @Inject(TYPES.USER_REPOSITORY)
    private readonly userRepository: UserRepository
  ) {
    super();
  }

  internalExecute(payload: { email: string; namespace: string; role: number }): Promise<any> {
    return this.userRepository.inviteToNamespace(payload);
  }
}
