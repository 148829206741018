
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { FindByStatusAccountGroup } from '@/settings/application/uses_cases/accountGroup/search/FindByStatusAccountGroup';
import { AccountGroup } from '@/settings/domain/accountGroup/AccountGroup';
import { Store } from '@/wms/domain/layout/store/Store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'AccountGroupSearch',
  components: {}
})
export default class AccountGroupSearch extends Vue {
  //Variable para almacenar la lista de territorios
  options: AccountGroup[] = [];

  //Variable para controlar el estado de la carga
  isLoading = false;

  @Inject(TYPES.ACCOUNT_GROUP_FIND_BY_STATUS)
  readonly findAllActiveAccountGroup!: FindByStatusAccountGroup;

  @Prop({ required: true }) value!: AccountGroup | null;
  @Prop({ required: false }) label!: string | null;
  @Prop({ required: false, default: '' }) containerClass!: string | null;
  @Prop({ required: false, default: '' }) validationRules!: string | null;
  @Prop({
    required: false,
    default: (): null => {
      return null;
    }
  })
  onInput!: (store: AccountGroup | null) => Promise<void> | void;
  @Prop({ required: false, default: false }) readonly disabled!: boolean;
  @Prop({ required: false }) stores!: Store[];
  @Prop({ required: false }) valuePath!: keyof AccountGroup;

  mounted() {
    if (!this.stores) this.find();
  }

  get localValue(): AccountGroup | null | string | number {
    return this.value;
  }

  set localValue(value: AccountGroup | null | string | number) {
    this.$emit('update:value', value);
  }

  /**
   * @description Funcion para buscar tdas las AccountGroup y asignarlo a la lista
   * @name find
   */
  async find() {
    this.isLoading = true;
    try {
      const response = await this.findAllActiveAccountGroup.internalExecute(true);
      this.options = response;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  //Función invocada cuando se selecciona un territorio y se desea enviar al padre para ejecutar alguna acción.
  async dispatchInput(value: AccountGroup | null) {
    if (this.onInput) await this.onInput(value);
  }

  //Watch invocado para cuando el valor de la variable stores cambie
  @Watch('stores')
  onStoresChange(store: AccountGroup[] | null) {
    if (store) this.options = store;
  }
}
