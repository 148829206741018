import { PricingEngineParam } from '@/wms/domain/catalogs/pricingEngineParam/PricingEngineParam';

export class StandardPrice {
  public codePolicy: string;
  public codeReference: string;
  public idStandardPrice: number;
  public isGeneral: boolean;
  public calculationType: PricingEngineParam;
  public applicablePercentage: number;
  public estimatedValue: number;
  public saleValue: number;
  public applyDetailJson: string;
  public codeSettingStandard: number;
  public distrPrice: number;
  public marginRetail: number;
  public consumerPrice: number;
  public marginUsconsumer: number;
  constructor() {
    this.codePolicy = '';
    this.codeReference = '';
    this.idStandardPrice = 0;
    this.isGeneral = false;
    this.calculationType = new PricingEngineParam();
    this.applicablePercentage = 0;
    this.estimatedValue = 0;
    this.saleValue = 0;
    this.applyDetailJson = '';
    this.codeSettingStandard = 0;
    this.distrPrice = 0;
    this.marginRetail = 0;
    this.consumerPrice = 0;
    this.marginUsconsumer = 0;
  }
}
