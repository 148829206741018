import i18n from '@/core/plugins/i18n';
import router from '@/core/router';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import LocalStoragerRemover from '../helper/LocalStorageRemover';

function translateCol(colName: any) {
  return i18n.t('general.' + colName);
}
function destroyToken() {
  LocalStoragerRemover();
  router.replace({ name: 'Login' });
}
function sortDescending(data: any) {
  return data.sort((a: any, b: any) => b.id - a.id);
}
async function getToken() {
  const encodedUserPswd = btoa(`${process.env.VUE_APP_BASIC_USERNAME}:${process.env.VUE_APP_BASIC_PASSWORD}`);
  const params: any = new URLSearchParams();
  params.append('username', 'generic');
  params.append('password', 'M@st3r321..');
  params.append('grant_type', 'password');
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Authorization: 'Basic ' + encodedUserPswd
    },
    body: params
  };
  const response = await fetch(process.env.VUE_APP_URL_TOKEN + '/oauth/token', requestOptions);
  const data = await response.json();
  return data.access_token;
}
async function requestGet(url: string) {
  const response = await fetch(`${process.env.VUE_APP_URL_TOKEN}/${url}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
  return response;
}
async function requestGetRol(url: string, token: string) {
  const response = await fetch(process.env.VUE_APP_API_SETTINGS + url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    }
  });
  return response.json();
}
async function requestPost(url: string, body: any, token: string) {
  const response = await fetch(process.env.VUE_APP_API_SETTINGS + url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body
  });
  return response;
}
async function requestPut(url: string, body: any, token: string) {
  const response = await fetch(localStorageModule().tracking.host.API_BASE_URI + url, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body
  });
  return response;
}

function logger(item: any) {
  // eslint-disable-next-line no-console
  console.log(item);
}

/**
 * Funcion para generar un ID a partir de un Date now
 * @returns [number]
 */
function generateIDByDate() {
  return Date.now();
}

function getRandomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min) + min);
}

export {
  translateCol,
  destroyToken,
  sortDescending,
  getToken,
  requestGet,
  requestPost,
  requestPut,
  requestGetRol,
  logger,
  generateIDByDate,
  getRandomNumber
};
