
import { TYPES } from '@/core/config/Types';
import { ConfirmToken } from '@/settings/application/uses_cases/user/create/ConfirmToken';
import { Inject } from 'inversify-props';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'ActivateUser',
  components: {}
})
export default class ActivateUser extends Vue {
  @Inject(TYPES.USER_ACCEPT_TOKEN)
  private verifyToken!: ConfirmToken;

  isLoading = true;

  // Debemos obtener el token de la URL
  public mounted() {
    const token = this.$route.query.token as string;
    if (token) this.activateUser(token);
  }

  async activateUser(token: string) {
    try {
      this.isLoading = false;
      await this.verifyToken.execute(token);
      // Lanzamos un mensaje de confirmación
      const swalRes = await this.$swal({
        title: '¡Muy bien!',
        html:
          '<p>Te has unido a un nuevo espacio de trabajo!</p><p>Ahora puedes iniciar sesión y seleccionarlo en tu lista de espacios de trabajo.</p><p>Si eres un usuario nuevo, te enviaremos un mail y una contraseña para que puedas acceder a Aris 360.</p>',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });

      if (swalRes.isConfirmed) {
        this.$router.replace({ path: '/' });
      }
    } catch (error) {
      const swalRes = await this.$swal({
        title: '¡Ups!',
        text: 'Ha ocurrido un error al activar tu cuenta, creemos que el token ha expirado o ha sido usado.',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });

      if (swalRes.isConfirmed) {
        this.$router.replace({ path: '/' });
      }
    } finally {
      this.isLoading = false;
    }
  }
}
