
import { TYPES } from '@/core/config/Types';
import { FindChartValuesByTimeLine } from '@/tracking/application/uses_cases/timeline/search/findchartvaluesbytimeline/FindChartValuesByTimeLine';
import { TimelineTransactional } from '@/tracking/domain/timelineTransactional/TimelineTransactional';
import { Inject } from 'inversify-props';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'BillsAndProfit'
})
export default class BillsAndProfit extends Vue {
  @Inject(TYPES.FIND_CHARTVALUESTIMELINE)
  readonly findChartValuesByTimeLine!: FindChartValuesByTimeLine;

  @Prop({ required: true }) timeline!: TimelineTransactional;

  isLoading = false;
  chartData: any = [];

  mounted() {
    this.loadChartData(this.timeline);
  }

  //Funcion para cargar las graficas
  async loadChartData(timeline: TimelineTransactional) {
    try {
      if (!timeline) return;
      const incomeArray: number[] = [];
      const billArray: number[] = [];
      const profitArray: number[] = [];
      const daysArray: number[] = [];
      const eventList: { description: string }[] = [{ description: '' }];

      this.isLoading = true;
      const res = await this.findChartValuesByTimeLine.execute(timeline.timelineOrigin?.id as number);
      res.forEach((element: any) => {
        incomeArray.push(element[2]);
        billArray.push(element[3]);
        profitArray.push(element[4]);
        daysArray.push(element[6]);
        eventList.push({ description: element[1] });
      });
      this.chartData = {
        profit: profitArray.reduce((a: number, b: number) => a + b, 0),
        income: incomeArray.reduce((a: number, b: number) => a + b, 0),
        bill: billArray.reduce((a: number, b: number) => a + b, 0)
      };
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
}
