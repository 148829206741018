import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { File as CustomFile } from '@/settings/domain/file/File';
import { FileRepository } from '@/settings/domain/file/FileRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class FileServices implements FileRepository {
  private readonly API_BASE_URI: string =
    localStorageModule()?.config?.host?.API_BASE_URI ?? 'https://files.aris-operations.com';
  private readonly API_BASE_URI_NEW: string =
    process.env.VUE_APP_FILES_BACKEND_URL ?? 'https://files.aris-operations.com';
  private readonly API_FILE_FINDALL: string =
    this.API_BASE_URI + localStorageModule()?.config?.routes?.API_FILE_FINDALL;
  private readonly API_FILE_SAVE: string = this.API_BASE_URI + localStorageModule()?.config?.routes?.API_FILE_SAVE;
  private readonly API_PRIVATE_FILES_SAVE: string =
    this.API_BASE_URI_NEW + (localStorageModule()?.files?.routes?.API_PRIVATE_FILES_SAVE ?? '/file/private');
  // private readonly API_PUBLIC_FILES_SAVE: string =
  //   this.API_BASE_URI_NEW + localStorageModule().files.routes.API_PUBLIC_FILES_SAVE;
  // private readonly API_FILES_GET: string = this.API_BASE_URI_NEW + localStorageModule().files.routes.API_FILES_GET;
  private readonly API_SAVE_MANIFEST: string =
    this.API_BASE_URI + localStorageModule()?.config?.routes?.API_SAVE_MANIFEST;

  async create(file: CustomFile) {
    try {
      return await http.post<File[]>(this.API_FILE_SAVE, JSON.stringify(file));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findAll(name: string): Promise<CustomFile[]> {
    try {
      return await http.get<CustomFile[]>(this.API_FILE_FINDALL + name);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async createNewVersion({ files, folder }: { files: File[]; folder?: string }): Promise<string | string[]> {
    try {
      const formData = new FormData();
      files.map(file => formData.append('files', file));
      formData.append('folder', folder ?? '');

      return await http.postFormData<string>(this.API_PRIVATE_FILES_SAVE, formData);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  getFile(file: string): string {
    try {
      return this.API_BASE_URI_NEW + `/file/public?filename=${file}`;
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async saveManifest(file: File): Promise<File> {
    try {
      return await http.post(this.API_SAVE_MANIFEST, file);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
