import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { MenuRepository } from '@/settings/domain/menu/MenuRepository';
import { Menu } from '@/settings/domain/menu/menu';
@Injectable()
export class MenuServices implements MenuRepository {
  private readonly API_BASE_URI: string = process.env.VUE_APP_URL_TOKEN ?? '';
  private readonly API_MENU: string = this.API_BASE_URI + localStorageModule().auth.routes.API_MENU;
  private readonly API_MENU_FINDBYID: string = this.API_BASE_URI + localStorageModule().auth.routes.API_MENU_FINDBYID;
  private readonly API_MENU_DELETE: string = this.API_BASE_URI + localStorageModule().auth.routes.API_MENU_DELETE;
  private readonly API_MENU_FIND_BY_ROLE: string =
    this.API_BASE_URI + localStorageModule().auth.routes.API_MENU_FIND_BY_ROLE;
  private readonly API_MENU_ASSIGN_TO_ROLE: string =
    this.API_BASE_URI + localStorageModule().auth.routes.API_MENU_ASSIGN_TO_ROLE;

  async findAll(): Promise<Menu[]> {
    try {
      return await http.get<Menu[]>(this.API_MENU + '/active');
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findById(id: number): Promise<Menu> {
    try {
      return await http.get<Menu>(`${this.API_MENU_FINDBYID}${id}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async create(menu: Menu): Promise<void> {
    try {
      await http.VOID_POST<Menu>(this.API_MENU, JSON.stringify(menu));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(menu: Menu): Promise<void> {
    try {
      await http.VOID_PUT<Menu>(this.API_MENU, JSON.stringify(menu));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async delete(id: number): Promise<void> {
    try {
      await http.VOID_DELETE<Menu>(`${this.API_MENU}/${id}`, null);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async assignToRole(payload: { companyId: string; role: number; menuItems: Menu[] }): Promise<void> {
    try {
      // Debemos enviar solamente los id de los menus, para eso debemos mapear el array de objetos a un array de numeros plano
      // Por lo que tambien debemos llegar a los id de los submenus recursivamente
      const payloadMapped = payload.menuItems.map(menu => {
        const ids = [Number(menu.id)];
        const subMenuIds = this.getSubMenuIds(menu);
        return ids.concat(subMenuIds);
      });

      return await http.post<void>(
        `${this.API_MENU_ASSIGN_TO_ROLE}companyId=${payload.companyId}?roleId=${payload.role}`,
        payloadMapped
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  getSubMenuIds(menu: Menu): number[] {
    const ids: number[] = [];
    if (menu.children) {
      menu.children.forEach(subMenu => {
        ids.push(subMenu.id);
        this.getSubMenuIds(subMenu);
      });
    }
    return ids;
  }

  findByRole(payload: { companyId: string; roleId: number; type: string }): Promise<Menu[]> {
    const { companyId, roleId, type } = payload;
    try {
      return http.get<Menu[]>(`${this.API_MENU_FIND_BY_ROLE}companyId=${companyId}&roleId=${roleId}&type=${type}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAllMenuForSuperUser(): Promise<Menu[]> {
    try {
      return await http.get<Menu[]>(this.API_MENU);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
