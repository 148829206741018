import { Role } from '../role/Role';

export class User {
  businessId: string;
  businessName: string;
  configProfile: string;
  customerEmail: string;
  customerId: string;
  customerName: string;
  customerType: string;
  enabled: boolean;
  logo: string;
  password: string;
  confirmPassword: string;
  registerDate: string;
  roles: Role[];
  username: string;
  image: string;
  name: string;

  public constructor() {
    this.businessId = '';
    this.businessName = '';
    this.configProfile = '';
    this.customerEmail = '';
    this.customerId = '';
    this.customerName = '';
    this.customerType = '';
    this.enabled = true;
    this.logo = '';
    this.password = '';
    this.confirmPassword = '';
    this.registerDate = '';
    this.roles = [];
    this.username = '';
    this.image = '';
    this.name = '';
  }
}
