import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Events } from '@/tracking/domain/events/Events';
import { EventsRepository } from '@/tracking/domain/events/EventsRepository';

@Injectable()
export class EventsFindAllConfActive extends Query<Promise<Events[]>, boolean> {
  public constructor(
    @Inject(TYPES.EVENTS_REPOSITORY)
    private readonly eventsRepository: EventsRepository,
    @Inject(TYPES.STATE_MANAGER) private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(status: boolean): Promise<Events[]> {
    const dataEvents = await this.eventsRepository.findAllConfActive(status);
    this.stateManager.patch({ dataEvents });
    return this.stateManager.state.dataEvents;
  }
}
