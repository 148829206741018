import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Country } from '@/settings/domain/country/Country';
import { CountryRepository } from '@/settings/domain/country/CountryRepository';

@Injectable()
export class CountryFindByDescriptionLikeInGeneralTerritories extends Query<Promise<Country[]>, string> {
  public constructor(
    @Inject(TYPES.TERRITORIES_REPOSITORY)
    private readonly countryRepository: CountryRepository
  ) {
    super();
  }

  async internalExecute(description: string): Promise<Country[]> {
    return await this.countryRepository.findByDescriptionLikeInGeneralTerritories(description);
  }
}
