
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { TransportFindAll } from '@/tracking/application/uses_cases/typetransport/search/findall/TransportFindAll';
import { CreateTimeline } from '@/tracking/application/uses_cases/timeline/create/CreateTimeline';
import { EventsFindByProcessType } from '@/tracking/application/uses_cases/events/search/EventsFindByProcessType';
import { UpdateTimeline } from '@/tracking/application/uses_cases/timeline/update/UpdateTimeline';
import { OperationFindAll } from '@/tracking/application/uses_cases/typeoperation/search/OperationFindAll';
import { Timeline } from '@/tracking/domain/timeline/Timeline';
import { Events } from '@/tracking/domain/events/Events';
import { Transport } from '@/tracking/domain/typetransport/Transport';

@Component({
  name: 'AddTimeline'
})
export default class AddTimeline extends Vue {
  @Inject(TYPES.FINDALL_TRANSPORT)
  readonly transportFindAll!: TransportFindAll;
  @Inject(TYPES.CREATE_TIMELINE)
  readonly createTimeline!: CreateTimeline;
  @Inject(TYPES.UPDATE_TIMELINE)
  readonly updateTimeline!: UpdateTimeline;
  @Inject(TYPES.FINDEVENTSBYPROCESSTYPE_EVENTS)
  readonly eventsFindByProcessType!: EventsFindByProcessType;
  @Inject(TYPES.FINDALL_TYPE_OPERATION)
  readonly operationFindByProcessType!: OperationFindAll;

  //Props
  @Prop() form!: Timeline;
  @Prop() actions!: { save: Function; clear: Function };

  //Data
  isLoading = false;

  //Selects
  selectedTypeOperation: any | null = null;
  selectedTypeTransport: Transport | null = null;

  //Listas
  eventList: Events[] = [];
  transportList: Transport[] = [];
  typeOperationList: any[] = [];

  mounted() {
    this.getTransport();
    this.getTypeOperation();
    this.getEvents();
  }

  //Getter para obtener los perfiles de la empresa
  get operationalProfiles() {
    const profiles = JSON.parse(localStorage.getItem('operationalProfiles') as any);
    const arrayProfile = [
      {
        text: 'wms',
        value: 'w',
        notEnabled: false
      },
      {
        text: 'courier',
        value: 'u',
        notEnabled: false
      },
      {
        text: 'freight',
        value: 'c',
        notEnabled: false
      }
    ] as any;

    arrayProfile.map((item: any) => {
      if (!profiles.find((element: any) => element == item.text)) {
        item.notEnabled = true;
      }
      item.text =
        item.text == 'wms'
          ? this.$t('general.WMS')
          : item.text == 'courier'
          ? this.$t('menu.Courier')
          : item.text == 'freight'
          ? this.$t('menu.Freight')
          : '';
    });
    return arrayProfile;
  }

  //Funcion para validar los inputs
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  //Funcion para obtener todos los tipos de operaciones
  async getTypeOperation() {
    try {
      this.isLoading = true;
      const res = await this.operationFindByProcessType.execute();
      this.typeOperationList = res.length > 0 ? res : [];
      if (this.form.id != 0) {
        this.selectedTypeOperation = res.filter(typeOperation => typeOperation.code == this.form.typeOperation)[0];
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion para obtener los tipos de transporte
  async getTransport() {
    try {
      this.isLoading = true;
      const res = await this.transportFindAll.execute();
      this.transportList = res.length > 0 ? res : [];
      if (this.form.id != 0) {
        this.selectedTypeTransport = res.filter(transport => transport.id == this.form.typeTransport)[0];
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion para obtener los eventos
  async getEvents() {
    try {
      this.isLoading = true;
      const res = await this.eventsFindByProcessType.execute(3);
      this.eventList = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para generar un payload valido antes del save
  save() {
    this.form.typeTransport = Number(this.selectedTypeTransport?.id);
    this.form.typeOperation = this.selectedTypeOperation.code;
    this.form.processType = 3;
    this.actions.save();
  }
}
