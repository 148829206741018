export class OrderNovelty {
  id: string;
  orderType: string;
  orderNumber: number;
  date: Date;
  dateToReturnToClient: Date;
  description: string;

  public constructor() {
    this.id = '';
    this.orderType = '';
    this.orderNumber = 0;
    this.date = new Date();
    this.dateToReturnToClient = new Date();
    this.description = '';
  }
}
