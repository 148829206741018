
import { ActivityTransactional } from '@/tracking/domain/activitiesTransactional/ActivityTransactional';
import { EventTransactional } from '@/tracking/domain/eventsTransactional/EventTransactional';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import FooterModal from './FooterModals/FooterModal.vue';
import HeaderModal from './HeaderModals/HeaderModal.vue';

@Component({
  name: 'ModalC7',
  components: { HeaderModal, FooterModal }
})
export default class ModalC7 extends Vue {
  @Prop() isLoading!: boolean;
  @Prop() switchId!: string;
  @Prop() switchDescription!: string;
  @Prop() eventDescription!: string;
  @Prop() activityDescription!: string;
  @Prop() sendNotification!: boolean;
  @Prop() idModal!: string;
  @Prop() activityData!: ActivityTransactional;
  @Prop() eventData!: EventTransactional;
  @Prop({ type: Function }) saveProcess!: any;
  @Prop() attachedFiles!: any[]; // Toca cambiar el tipo de dato
  @Prop({ type: Function }) searchActivities!: () => Promise<void>;

  @Watch('attachedFiles')
  onMyFilesChange() {
    // Debemos hacer que el padre actualice el valor de attachedFiles
    this.$emit('update:attachedFiles', this.attachedFiles);
  }

  get localIsLoading() {
    return this.isLoading;
  }

  set localIsLoading(value) {
    this.$emit('update:localIsLoading', value);
  }

  get eventExecuted() {
    return this.eventData.state === 'EXECUTED';
  }
}
