import { Injectable } from '@/core/di/Injectable';
import { Query } from '@/core/cqrs/Query';
import { TypeDocument } from '@/settings/domain/TypeDocument/TypeDocument';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { TypeDocumentRepository } from '@/settings/domain/TypeDocument/TypeDocumentRepository';

/**
 * @class TypeDocumentSave
 * @description Clase que contiene la lógica de negocio para obtener un tipo de documento por su tipo
 * @version 1.0.0
 * @Author San7iix
 */

@Injectable()
export class FindByIdTypeDocument extends Query<Promise<TypeDocument>, string> {
  public constructor(
    @Inject(TYPES.TYPE_DOCUMENT_REPOSITORY)
    private readonly repository: TypeDocumentRepository
  ) {
    super();
  }

  public async internalExecute(type: string): Promise<TypeDocument> {
    return await this.repository.findByType(type);
  }
}
