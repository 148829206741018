
import Vue from 'vue';
import Component from 'vue-class-component';
import PictureInput from 'vue-picture-input';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { ValidationObserver } from 'vee-validate/dist/types/components';
import { CreateUser } from '@/settings/application/uses_cases/user/create/CreateUser';
import { CreateFile } from '@/settings/application/uses_cases/file/create/CreateFile';
import { RoleFindAll } from '@/settings/application/uses_cases/role/search/RoleFindAll';
import i18n from '@/core/plugins/i18n';

@Component({
  name: 'CreateUser',
  components: {
    PictureInput
  }
})
export default class AppCreateUSer extends Vue {
  @Inject(TYPES.CREATE_FILE)
  readonly createFile!: CreateFile;
  @Inject(TYPES.CREATE_USER)
  readonly createUser!: CreateUser;
  @Inject(TYPES.FINDALL_ROLE) readonly roleFindAll!: RoleFindAll;

  //Data
  target = i18n.t('general.user');
  isLoading = false;
  fullPage = true;
  roleList = [];
  private form: any = {
    idUser: '',
    user: '',
    userEmail: '',
    userName: '',
    userPass: '',
    userRPass: '',
    urlImage: '',
    urlBase64Logo: '',
    image: '',
    roleSelect: []
  };
  //Refs
  $refs!: {
    pictureInput: HTMLFormElement;
    formStep1: InstanceType<typeof ValidationObserver>;
    idUser: HTMLInputElement;
  };
  //Hook
  mounted() {
    this.$nextTick(() => this.$refs.idUser.focus());
    this.$nextTick(() => this.allRole);
  }
  //Computed
  get allRole() {
    this.roleFindAll.execute().then((response: any) => {
      this.roleList = response;
    });
    return this.roleList;
  }
  //Methods
  public getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  public onChanged() {
    if (this.$refs.pictureInput.file) {
      this.form.image = this.$refs.pictureInput.file;
    }
  }
  public RegisterClient() {
    if (this.form.image) {
      const payloadImg: any = {
        base64: this.$refs.pictureInput.image,
        contentType: this.$refs.pictureInput.file.type,
        name: this.$refs.pictureInput.file.name,
        size: this.$refs.pictureInput.file.size
      };
      this.form.urlImage = payloadImg.name;
      this.createFile.execute(payloadImg);
    } else if (this.$refs.pictureInput.image === '') {
      this.form.urlImage = '';
    }
    const payload: any = {
      businessId: localStorage.getItem('businessId'),
      customerId: this.form.idUser.trim(),
      customerType: '02',
      customerName: this.form.userName.trim(),
      customerEmail: this.form.userEmail.trim(),
      username: this.form.user.trim(),
      password: this.form.userPass.trim(),
      logo: this.form.urlImage,
      enabled: true,
      roles: [{ id: this.form.roleSelect.id, name: this.form.roleSelect.name }]
    };
    this.isLoading = true;
    this.createUser
      .execute(payload)
      .then(() => {
        this.isLoading = false;
        this.clearForm();
      })
      .catch((error: any) => {
        this.isLoading = false;
        throw new Error(error);
      });
  }
  public clearForm() {
    this.form = {
      idUser: '',
      user: '',
      userEmail: '',
      userName: '',
      userPass: '',
      userRPass: '',
      urlImage: '',
      urlBase64Logo: '',
      image: '',
      roleSelect: []
    };
    this.$refs.formStep1.reset();
    this.$refs.idUser.focus();
  }
}
