import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StockRepository } from '@/wms/domain/stock/StockRepository';
import { StockCalculatedValues } from '@/courier/domain/DocsLin/DocsLin';

/**
 * @class FindHistoryMovementsStock
 * @description Clase que contiene la lógica de negocio para buscar el historial de movimientos de inventario
 * @version 1.0.0
 * @Author EnriquePallares
 */
@Injectable()
export class FindAllHistoryMovementsStock {
  public constructor(
    @Inject(TYPES.STOCK_REPOSITORY)
    private readonly repository: StockRepository
  ) {}

  public async execute(year: number): Promise<StockCalculatedValues[]> {
    return await this.repository.findAllHistoryMovements(year);
  }
}
