import { Injectable } from '@/core/di/Injectable';
import { LoggerLink } from './LoggerLink';
import { ExecutorLink } from './ExecutorLink';
import { UseCase } from '@/core/helper/BaseUseCase';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';

@Injectable()
export class Runner {
  chain = this.executorLink.setNext(this.loggerLink);

  constructor(
    @Inject(TYPES.EXECUTOR_LINK) private readonly executorLink: ExecutorLink,
    @Inject(TYPES.LOGGER_LINK) private readonly loggerLink: LoggerLink
  ) {}

  run(useCase: UseCase<unknown, unknown>, param: unknown): unknown {
    const context = { useCase, result: [], param };
    this.chain.next(context);
    return context.result;
  }
}
