import { PriceList } from '@/settings/domain/priceList/PriceList';
import { PriceListFindByOriginAndDestinationWeight } from '@/settings/application/uses_cases/priceList/search/weight/PriceListFindByOriginAndDestinationWeight';
import { CalcResponse } from './CalcResponse';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { OrderLine } from '@/courier/domain/orderline/OrderLine';

export class PriceCalculate {
  @Inject(TYPES.FINDBY_ORIGINANDDESTINATION_WEIGHT)
  readonly findWeightPriceList!: PriceListFindByOriginAndDestinationWeight;

  async calculate(priceList: PriceList, packages: Array<OrderLine>): Promise<CalcResponse> {
    let totalWeight = 0.0;
    let widthTotal = 0.0;
    let heightTotal = 0.0;
    let lengthTotal = 0.0;
    let rate = 0.0;
    let unitValue = 0.0;
    //flags
    let isPriceListByWeight = false;

    //Calculate total packages
    packages.forEach(element => {
      totalWeight += Number(element['weight']);
      widthTotal += Number(element['width']);
      heightTotal += Number(element['height']);
      lengthTotal += Number(element['lengthy']);
    });

    //Volumetric weight calc.
    const volumetricWeight = (widthTotal * heightTotal * lengthTotal) / priceList['factor'];

    //Call weight price list
    if (volumetricWeight < totalWeight) {
      await this.findPriceListByWeight(priceList).then(response => {
        isPriceListByWeight = true;
        priceList = response;
      });

      //Validate weight price list
      if (!priceList) {
        return new CalcResponse();
      }
    }

    //Calc. rate
    unitValue = priceList['unitValue'];
    rate = volumetricWeight * unitValue;
    //Validate min rate.
    if (rate < priceList['minRate']) {
      rate = priceList['minRate'];
    }

    const calcResponse = new CalcResponse();
    //Taxes calc.
    const base = rate;
    priceList['taxes'].forEach(element => {
      const percentaje = element['taxPercentage'] / 100;
      calcResponse.taxes.push({
        taxName: element['taxName'],
        taxValue: (base * percentaje).toFixed(2),
        taxPercentage: element['taxPercentage']
      });
      rate = rate * (1 + percentaje);
    });
    //Response
    calcResponse.transportName = priceList['transportName'];
    calcResponse.transportId = priceList['transportId'];
    calcResponse.routeId = priceList['routeId'];
    calcResponse.timeLineId = priceList['timeline'];
    calcResponse.rateValue = Number(rate.toFixed(2));
    calcResponse.isValidPriceList = true;
    calcResponse.isVolumePriceList = !isPriceListByWeight;
    calcResponse.volumetricWeight = volumetricWeight;
    calcResponse.subTotal = Number(base.toFixed(2));
    return calcResponse;
  }

  async findPriceListByWeight(priceList: PriceList) {
    const payload: any = {
      countryOrigin: priceList['countryOrigin'],
      stateOrigin: priceList['stateOrigin'],
      zoneZcOrigin: priceList['zoneZcOrigin'],
      countryDestination: priceList['countryDestination'],
      stateDestination: priceList['stateDestination'],
      zoneZcDestination: priceList['zoneZcDestination'],
      weightUnit: priceList['weightUnit'],
      transport: priceList['transportId']
    };
    let weightPriceList: any = undefined;
    await this.findWeightPriceList
      .execute(payload)
      .then((response: Array<PriceList>) => {
        weightPriceList = response[0];
      })
      .catch(() => {
        weightPriceList = null;
      });
    return weightPriceList;
  }
}
