
import ConsigneeSearch from '@/core/components/shared/ConsigneeSearch.vue';
import EntitySearch from '@/core/components/shared/EntitySearch.vue';
import TerritorySearch from '@/core/components/shared/TerritorySearch.vue';
import { Bulk } from '@/wms/domain/bulk/Bulk';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'CargoOwners',
  components: { ConsigneeSearch, EntitySearch, TerritorySearch }
})
export default class CargoOwners extends Vue {
  @Prop({ type: Boolean, default: false })
  public forView!: boolean;

  @Prop({ type: Boolean, default: false })
  public disabledInputs!: boolean;

  @Prop({ type: Object, required: true }) form!: Bulk;
  @Prop({ type: Boolean, default: true })
  public showCargoOwners!: boolean;

  //Funcion invocada para validar un input
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
}
