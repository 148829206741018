import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { TimelineConfig } from '@/tracking/domain/timelineConfig/TimelineConfig';
import { TimelineConfigRepository } from '@/tracking/domain/timelineConfig/TimelineConfigRepository';
import { Query } from '@/core/cqrs/Query';

@Injectable()
export class FindAllTimelineConfigByPk extends Query<Promise<TimelineConfig>, number> {
  public constructor(
    @Inject(TYPES.TIMELINECONFIG_TYPE_REPOSITORY)
    private readonly repository: TimelineConfigRepository
  ) {
    super();
  }
  async internalExecute(id: number): Promise<TimelineConfig> {
    return await this.repository.findByPk(id);
  }
}
