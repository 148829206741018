
import { TYPES } from '@/core/config/Types';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';
import WarehouseAdministrationCenter from '@/wms/infrastructure/ui/warehouseCenter/WarehouseAdministrationCenter.vue';
import AppTopInformationCards from '@/core/components/shared/TopInformationCards.vue';
import { Inject } from 'inversify-props';
import Vue from 'vue';
import Component from 'vue-class-component';
import Chart from 'chart.js';
import { FindAllDashboardChartData } from '@/settings/application/uses_cases/dashboardChartData/search/FindAllDashboardChartData';
import { RecentShipments } from '@/settings/domain/recentShipments/RecentShipments';
import { TopInformationCards } from '@/settings/domain/topInformationCards/TopInformationCards';
import { WarehouseUsage } from '@/settings/domain/dashboardChartData/dashboardChartData';

@Component({
  name: 'AppDashboardDefault',
  components: { WarehouseAdministrationCenter, AppTopInformationCards }
})
export default class AppDashboardDefault extends Vue {
  @Inject(TYPES.FINDBYID_COMPANY)
  readonly findByIdCompany!: CompanyFindById;
  @Inject(TYPES.DASHBOARD_CHART_DATA_FIND_ALL) readonly findAllDashboardChartData!: FindAllDashboardChartData;

  isWms = false;
  isLoading = false;

  created() {
    this.findCompany();
  }

  async findCompany() {
    const companyId = localStorage.getItem('businessId');
    if (!companyId) return;
    const res: any = await this.findByIdCompany.execute(companyId);
    this.isWms = res.isWms;
  }

  searchQuery = '';
  stats: TopInformationCards[] = [];

  shipmentData: {
    key: string;
    value: number;
  }[] = [];

  transportationData: {
    key: string;
    value: number;
  }[] = [];

  shippedCities: {
    key: string;
    value: number;
  }[] = [];

  warehouseDataVolume: WarehouseUsage[] = [];
  warehouseDataCapacity: WarehouseUsage[] = [];

  recentShipments: RecentShipments[] = [];

  mounted() {
    this.findAllChartData();
  }

  createShipmentChart() {
    const ctx = this.$refs.shipmentChart;
    new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.shipmentData.map(data => data.key),
        datasets: [
          {
            label: 'Shipment volume',
            data: this.shipmentData.map(data => data.value),
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          }
        ]
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });
  }

  createTransportationChart() {
    const ctx = this.$refs.transportationChart;
    new Chart(ctx, {
      type: 'pie',
      data: {
        labels: this.transportationData.map(data => data.key),
        datasets: [
          {
            data: this.transportationData.map(data => data.value),
            backgroundColor: ['#EF946C', '#C4A77D', '#70877F', '#454372', '#2F2963']
          }
        ]
      },
      options: {
        responsive: true
      }
    });
  }

  createWarehouseChartVolume() {
    try {
      const ctx = this.$refs.warehouseChartVolume;
      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.warehouseDataVolume.map(data => data.warehouseDescription),
          datasets: [
            {
              label: 'Utilization (%)',
              data: this.warehouseDataVolume.map(data => data.usedVolumePercentage),
              backgroundColor: ['#EF946C', '#C4A77D', '#70877F', '#454372', '#2F2963']
            }
          ]
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              max: 100
            }
          }
        }
      });
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  createWarehouseChartCapacity() {
    const ctx = this.$refs.warehouseChartCapacity;
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.warehouseDataCapacity.map(data => data.warehouseDescription),
        datasets: [
          {
            label: 'Utilization (%)',
            data: this.warehouseDataCapacity.map(data => data.usedCapacityPercentage),
            backgroundColor: ['#EF946C', '#C4A77D', '#70877F', '#454372', '#2F2963']
          }
        ]
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
            max: 100
          }
        }
      }
    });
  }
  createMostShippedCities() {
    const ctx = this.$refs.shippedCities;
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.shippedCities.map(data => data.key),
        datasets: [
          {
            label: 'Total shipments',
            data: this.shippedCities.map(data => data.value),
            backgroundColor: ['#EF946C', '#C4A77D', '#70877F', '#454372', '#2F2963']
          }
        ]
      },
      options: {
        responsive: true
      }
    });
  }

  async findAllChartData() {
    try {
      this.isLoading = true;
      const res = await this.findAllDashboardChartData.internalExecute();
      this.shipmentData = res.shippedByMonth;
      this.recentShipments = res.lastPreRegisters;
      this.transportationData = res.transportTypeData;
      this.shippedCities = res.top5ShippedCities;

      this.stats = [
        ...this.stats,
        {
          title: 'averageTransitTime',
          value: res.transitTime.average ? `${res.transitTime.average.toFixed(2).toString()} days` : '0 days',
          change: `${
            !isNaN(res.transitTime.percentage) && isFinite(res.transitTime.percentage)
              ? res.transitTime.percentage.toFixed(2).toString()
              : '0'
          }% ${
            res.transitTime.percentage > 0 ? this.$t('general.upFromLastMonth') : this.$t('general.downFromLastMonth')
          }`
        },
        {
          title: 'totalPreregisters',
          value: res.totalPreRegisters.total ? res.totalPreRegisters.total.toString() : '0',
          change: `${res.totalPreRegisters.total ? res.totalPreRegisters.percentage.toFixed(2).toString() : '0'}% ${
            res.totalPreRegisters.percentage > 0
              ? this.$t('general.upFromLastMonth')
              : this.$t('general.downFromLastMonth')
          }`
        }
      ];
      this.warehouseDataCapacity = res.warehouseUsage;
      this.warehouseDataVolume = res.warehouseUsage;

      this.createShipmentChart();
      this.createTransportationChart();
      this.createMostShippedCities();
      this.createWarehouseChartCapacity();
      this.createWarehouseChartVolume();
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }
}
