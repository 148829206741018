import { Injectable } from '@/core/di/Injectable';
import { ContableConceptsRepository } from '@/courier/domain/ContableConcepts/ContableConceptsRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { ContableConcepts } from '@/courier/domain/ContableConcepts/ContableConcepts';
import { http } from '@/core/http/HttpFetch';

/**
 * @name ContableConceptsService
 * @description Genera la clase para el servicio de grupos contables que hace peticiones al API
 * @implements ContableConceptsRepository
 * @author Mauricio Cantillo
 * @version 1.0.0
 * @since 1.0.0
 * @date 2022-03-24
 */

@Injectable()
export class ContableConceptsService implements ContableConceptsRepository {
  private readonly API_BASE_URI = localStorageModule().transactions.host.API_BASE_URI;
  private readonly API_CONTABLE_CONCEPTS =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_CONTABLE_CONCEPTS;
  private readonly API_CONTABLE_CONCEPTS_FIND_BY_ID =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_CONTABLE_CONCEPTS_FIND_BY_ID;
  private readonly API_CONTABLE_CONCEPTS_FIND_BY_STATUS =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_CONTABLE_CONCEPTS_FIND_BY_STATUS;
  private readonly API_CONTABLE_CONCEPTS_FIND_BY_DOCUMENT_TYPE =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_CONTABLE_CONCEPTS_FIND_BY_DOCUMENT_TYPE;

  async findAll(): Promise<ContableConcepts[]> {
    try {
      return await http.get(this.API_CONTABLE_CONCEPTS);
    } catch (error) {
      throw new Error(`Error en el servicio ${error}`);
    }
  }

  async save(contableConcept: ContableConcepts): Promise<ContableConcepts> {
    try {
      return await http.post(this.API_CONTABLE_CONCEPTS, JSON.stringify(contableConcept));
    } catch (error) {
      throw new Error(`Error en el servicio ${error}`);
    }
  }

  async update(contableConcept: ContableConcepts): Promise<ContableConcepts> {
    try {
      return await http._put(this.API_CONTABLE_CONCEPTS, JSON.stringify(contableConcept));
    } catch (error) {
      throw new Error(`Error en el servicio ${error}`);
    }
  }

  async delete(id: number): Promise<ContableConcepts> {
    try {
      return await http.singleDelete(`${this.API_CONTABLE_CONCEPTS}${id}`);
    } catch (error) {
      throw new Error(`Error en el servicio ${error}`);
    }
  }

  async findById(id: string): Promise<ContableConcepts> {
    try {
      return await http.get(`${this.API_CONTABLE_CONCEPTS_FIND_BY_ID}${id}`);
    } catch (error) {
      throw new Error(`Error en el servicio ${error}`);
    }
  }

  async findByStatus(status: boolean): Promise<ContableConcepts[]> {
    try {
      return await http.get(`${this.API_CONTABLE_CONCEPTS_FIND_BY_STATUS}${status}`);
    } catch (error) {
      throw new Error(`Error en el servicio ${error}`);
    }
  }

  async findByDocumentType(documentType: string): Promise<ContableConcepts[]> {
    try {
      return await http.get(`${this.API_CONTABLE_CONCEPTS_FIND_BY_DOCUMENT_TYPE}${documentType}`);
    } catch (error) {
      throw new Error(`Error en el servicio ${error}`);
    }
  }
}
