
import Component from 'vue-class-component';
import Vue from 'vue';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { SwitchFindAllByProcessType } from '@/tracking/application/uses_cases/switches/search/SwitchFindAllByProcessType';
import { ConsecutiveFindByWarehouseAndType } from '@/courier/application/uses_cases/consecutive/search/ConsecutiveFindByWarehouseAndType';
import { RoutesFindAll } from '@/settings/application/uses_cases/routes/search/RoutesFindAll';
import { FindOrderByFilters } from '@/courier/application/uses_cases/view/order/FindOrderByFilters';
import { OrderbyFilter } from '@/courier/domain/view/orderbyfilter/OrderbyFilter';
import { showAlert } from '@/core/plugins/Notifications';
import { CreateEventsExec } from '@/tracking/application/uses_cases/events/create/CreateEventsExec';

@Component({
  name: 'OperationbySwitch'
})
export default class OperationbySwitch extends Vue {
  @Inject(TYPES.FINDBYPROCESSTYPE_SWITCH)
  readonly switchFindAllByProcessType!: SwitchFindAllByProcessType;
  @Inject(TYPES.FINDBYWAREHOUSEANDTYPE_CONSECUTIVE)
  readonly consecutiveFindByWarehouseAndType!: ConsecutiveFindByWarehouseAndType;
  @Inject(TYPES.FINDALL_ROUTES)
  readonly routesFindAll!: RoutesFindAll;
  @Inject(TYPES.VIEW_ORDER_FINDBYFILTERS)
  readonly findOrderByFilters!: FindOrderByFilters;
  @Inject(TYPES.CREATE_EXEC_EVENTS)
  readonly createEventsExec!: CreateEventsExec;

  arraySwitch = [];
  arrayRoutes = [];
  switch: any | null = null;
  keyComponente = 1;
  form = {
    sw: this.switch,
    number: 0,
    route: null,
    destino: null,
    fachaIni: null,
    fechaFin: null,
    serial: ''
  };
  fields = ['serial', 'dateOrder', 'cityOrigin', 'cityDestination', 'C'];
  items: OrderbyFilter[] = [];
  objbySerial: OrderbyFilter | any = new OrderbyFilter();
  created() {
    this.findAllswitch();
    this.findAllRoutes();
  }

  findAllswitch() {
    this.switchFindAllByProcessType.execute(1).then((response: any) => {
      this.arraySwitch = response.filter((item: any) => {
        if (item.sw === '40' || item.sw === '41') {
          item.nature = item.sw + ' - ' + item.nature;
          return item;
        }
      });
    });
    return true;
  }

  findAllRoutes() {
    this.routesFindAll
      .execute()
      .then((response: any) => {
        this.arrayRoutes = response;
      })
      .catch((err: any) => {
        return err;
      });
  }

  getConsecutive(object: any) {
    if (object.sw != '40') return;

    const pyload = {
      type: 'CNS',
      warehouse: '000'
    } as const;
    this.consecutiveFindByWarehouseAndType
      .execute(pyload)
      .then((response: any) => {
        this.form.number = Number(response.nextNumber) + 1;
      })
      .catch((err: any) => {
        return err;
      });
  }
  findOrderByFilter(obj: any, route: any) {
    if (route && obj) {
      const payload = {
        sw: obj.sw.sw,
        numberConsol: this.form.number,
        idRoute: route.id,
        FechIni: this.form.fachaIni,
        FechFin: this.form.fechaFin
      };
      this.findOrderByFilters
        .execute(payload)
        .then((response: any) => (this.items = response))
        .catch((err: any) => {
          return err;
        });
    }
  }

  getOrderbySerial() {
    const auxItems: OrderbyFilter[] = [];
    this.objbySerial = new OrderbyFilter();
    if ((this.form.serial != '' || this.form.serial != null) && this.form.serial.length >= 13) {
      this.items.map((item: any) => {
        if (item.serial == this.form.serial) {
          item.C = 'X';
          this.objbySerial = item;
        }
        auxItems.push(item);
      });
      this.items = auxItems;
      if (this.objbySerial.serial == '') {
        showAlert('error', 'Record not Found');
      }
    }
  }

  get valueRoute() {
    return this.objbySerial.cityOrigin_name + ' ----> ' + this.objbySerial.cityDestination_name;
  }

  executeEvent() {
    const consolidationList: any = [];

    this.items.map((item: any) => {
      if (item.C == 'X') {
        consolidationList.push({
          activityCode: item.activityId,
          eventCode: item.event,
          orderNumber: item.number,
          orderType: item.type,
          timelineCode: item.timeline,
          sequence: item.sequence
        });
      }
    });

    const payload: any = {
      swCode: this.form.sw.sw,
      swTypeOperation: 1,
      consolidationList
    };

    this.createEventsExec
      .execute(payload)
      .then(() => {
        this.findOrderByFilter(this.form, this.form.route);
        this.form.serial = '';
        this.objbySerial = new OrderbyFilter();
        this.getConsecutive(this.form.sw);
      })
      .catch((error: any) => {
        throw new Error(error);
      });
  }
  changeKey() {
    this.keyComponente += 1;
  }
}
