import { Offers } from '@/wms/domain/catalogs/pricingEngine/SettingOffers/Offers';

export class SettingCustomerOffers {
  public customers: any;
  public policyOffersTiered: any;
  public offers: Offers;
  constructor() {
    this.customers = [];
    this.policyOffersTiered = [];
    this.offers = new Offers();
  }
}
