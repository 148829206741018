import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { WmsQuotationOrderForTable } from '@/courier/domain/wmsQuotationOrder/WmsQuotationOrderForTable';
import { WmsQuotationOrderRepository } from '@/courier/domain/wmsQuotationOrder/WmsQuotationOrderRepository';
@Injectable()
export class FindWmsQuotationsByDateRangeAndType extends Query<
  Promise<WmsQuotationOrderForTable[]>,
  { dateFrom: string; dateTo: string; type: string }
> {
  constructor(@Inject(TYPES.WMS_ORDER_REPOSITORY) private readonly repository: WmsQuotationOrderRepository) {
    super();
  }

  async internalExecute(param: { dateFrom: string; dateTo: string; type: string }) {
    return this.repository.findByDateRangeAndType(param);
  }
}
