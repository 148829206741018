import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Command } from '@/core/cqrs/Command';
import { Events } from '@/tracking/domain/events/Events';
import { EventsRepository } from '@/tracking/domain/events/EventsRepository';

@Injectable()
export class CreateEvents extends Command<Promise<any>, Events> {
  public constructor(
    @Inject(TYPES.STATE_MANAGER) private readonly stateManager: StateManager,
    @Inject(TYPES.EVENTS_REPOSITORY)
    private readonly eventsRepository: EventsRepository
  ) {
    super();
  }
  internalExecute(events: Events): Promise<any> {
    return this.eventsRepository.create(events);
  }
}
