import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { PricingZone } from '@/settings/domain/PricingZone/PricingZone';
import { PricingZoneRepository } from '@/settings/domain/PricingZone/PricingZoneRepository';

@Injectable()
export class SavePricingZone extends Command<Promise<any>, PricingZone> {
  public constructor(
    @Inject(TYPES.PRICING_ZONE_REPOSITORY)
    private readonly pricingZoneRepository: PricingZoneRepository
  ) {
    super();
  }

  internalExecute(payload: PricingZone): Promise<any> {
    return this.pricingZoneRepository.save(payload);
  }
}
