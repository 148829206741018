import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { Bulk } from '@/wms/domain/bulk/Bulk';
import { BulkRepository } from '@/wms/domain/bulk/BulkRepository';

@Injectable()
export class SuggestLocations extends Command<Promise<Bulk[]>, { number: number; type: string }> {
  public constructor(
    @Inject(TYPES.BULK_REPOSITORY)
    private readonly repository: BulkRepository
  ) {
    super();
  }

  async internalExecute(payload: { number: number; type: string }): Promise<Bulk[]> {
    try {
      return await this.repository.suggestLocations(payload);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
