import { Injectable } from '@/core/di/Injectable';
import { ZoneLevelRepository } from '@/settings/domain/ZoneLevel/ZoneLevelRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { ZoneLevel } from '@/settings/domain/ZoneLevel/ZoneLevel';
import { http } from '@/core/http/HttpFetch';

@Injectable()
export class ZoneLevelService implements ZoneLevelRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_PRICING_ZONE_LEVEL_DELETE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PRICING_ZONE_LEVEL_DELETE;

  async delete(zoneLevel: ZoneLevel) {
    return await http.delete<ZoneLevel>(this.API_PRICING_ZONE_LEVEL_DELETE, JSON.stringify(zoneLevel));
  }
}
