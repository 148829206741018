import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import Airport from '@/settings/domain/airport/Airport';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { AirportRepository } from '@/settings/domain/airport/AirportRepository';

@Injectable()
export class UpdateAirport extends Command<Promise<Airport>, Airport> {
  public constructor(
    @Inject(TYPES.AIRPORT_REPOSITORY)
    private readonly airportRepository: AirportRepository
  ) {
    super();
  }

  async internalExecute(airport: Airport): Promise<Airport> {
    return await this.airportRepository.update(airport);
  }
}
