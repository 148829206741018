import { getLocalstorageItem } from './LocalStorageVariables';

export async function getRoutesApi(dataUrlApi: any, configProfile: any) {
  const URL_ROUTESDEV: any = `${process.env.VUE_APP_ROUTE_API}${dataUrlApi}/routes,${configProfile}`;
  const header: any = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  try {
    const response = await fetch(URL_ROUTESDEV, {
      method: 'GET',
      headers: header
    });
    const data = await response.json();
    const dataModule: any = {};
    for (const item of dataUrlApi) {
      dataModule[item] = {};
      for (const module of data['propertySources']) {
        if (module['name'].includes(item + '-routes')) {
          dataModule[item]['routes'] = module['source'];
        } else if (module['name'].includes(item + '-' + configProfile)) {
          dataModule[item]['host'] = module['source'];
        }
      }
    }
    localStorage.setItem('dataModuleCompany', JSON.stringify(dataModule));
    localStorage.setItem('profile', configProfile);
    // localStorage.setItem('consAutoReference', )
  } catch (err) {
    throw new Error(`${err}`);
  }
}
export function localStorageModule() {
  return JSON.parse(getLocalstorageItem('DATA_MODULE_COMPANY') as any);
}
