import { TYPES } from '@/core/config/Types';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Query } from '@/core/cqrs/Query';
import { StateManager } from '@/core/statemanager/StateManager';
import { Operation } from '@/tracking/domain/typeoperation/Operation';
import { OperationRepository } from '@/tracking/domain/typeoperation/OperationRepository';

@Injectable()
export class OperationFindByProcessType extends Query<Promise<Operation[]>, number> {
  public constructor(
    @Inject(TYPES.TYPE_OPERATION_REPOSITORY)
    private readonly operationRepository: OperationRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(params: number): Promise<Operation[]> {
    const dataOperation = await this.operationRepository.findByProcessType(params);
    this.stateManager.patch({ dataOperation });
    return this.stateManager.state.dataOperation;
  }
}
