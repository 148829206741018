import { Transport } from '@/tracking/domain/typetransport/Transport';
import { Taxe } from '../taxe/Taxe';
import { Weight } from '../weight/Weight';
import { GroupReferences } from '@/wms/domain/category/groupReferences/GroupReferences';
import { ReferencesPriceListValue } from './ReferencePriceListValue';
import { Volume } from '../volume/Volume';

export class CourierPriceList {
  id: string;
  description: string;
  weightUnit: Weight | null;
  volumeUnit: Volume | null;
  reportedIn: Weight | null;
  active: boolean;
  unitValue: number;
  minRate: number;
  typeTransport: Transport | null;
  applyFactorConversion: boolean;
  factor: number;
  taxes: Taxe[];
  category: GroupReferences | null;
  createAt: string;
  updateAt: string;
  references: ReferencesPriceListValue[];

  public constructor() {
    this.id = '';
    this.description = '';
    this.weightUnit = null;
    this.volumeUnit = null;
    this.reportedIn = null;
    this.active = true;
    this.unitValue = 0;
    this.minRate = 0;
    this.typeTransport = null;
    this.applyFactorConversion = false;
    this.factor = 0;
    this.taxes = [];
    this.category = null;
    this.references = [];
    this.createAt = '';
    this.updateAt = '';
  }
}
