import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Variety } from '@/wms/domain/catalogs/xgeneral/variety/Variety';
import { VarietyRepository } from '@/wms/domain/catalogs/xgeneral/variety/VarietyRepository';

@Injectable()
export class UpdateVariety extends Command<Promise<any>, Variety> {
  public constructor(
    @Inject(TYPES.VARIETY_REFERENCE_REPOSITORY)
    private readonly varietyRepository: VarietyRepository
  ) {
    super();
  }

  internalExecute(variety: Variety): Promise<any> {
    return this.varietyRepository.update(variety);
  }
}
