import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { OrderNoveltyRepository } from '@/tracking/domain/orderNovelty/OrderNoveltyRepository';

@Injectable()
export class DeleteOrderNovelty extends Command<Promise<void>, string> {
  public constructor(
    @Inject(TYPES.ORDER_NOVELTY_REPOSITORY)
    private readonly repository: OrderNoveltyRepository
  ) {
    super();
  }

  public async internalExecute(id: string): Promise<void> {
    return this.repository.delete(id);
  }
}
