import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { PreparationRepository } from '@/freight/domain/Preparation/PreparationRepository';

@Injectable()
export class DeletePreparation extends Command<void, string> {
  constructor(
    @Inject(TYPES.CARGO_DETAILS_PRESET_REPOSITORY)
    private readonly repository: PreparationRepository
  ) {
    super();
  }

  async internalExecute(dto: string): Promise<void> {
    return await this.repository.delete(dto);
  }
}
