import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { DocumentsV2Repository } from '@/courier/domain/DocumentsV2/DocumentsRepository_V2';
import { DocumentHeader } from '@/courier/domain/DocumentsV2/Documents_V2';

/**
 * @class SaveDocuments
 * @description Caso de uso para crear un documento que toma como dependencia el repositorio de documentos y se inyecta en el contenedor de inversify
 * @version 1.0.0
 * @since 1.0.0
 */

@Injectable()
export class SaveDocumentsV2 extends Command<Promise<DocumentHeader>, DocumentHeader> {
  public constructor(
    @Inject(TYPES.DOCUMENTS_V2_REPOSITORY) // Inyecta el repositorio de documentos
    private readonly repository: DocumentsV2Repository // Instancia del repositorio de documentos
  ) {
    super();
  }

  public async internalExecute(document: DocumentHeader): Promise<DocumentHeader> {
    // Ejecuta el caso de uso
    return await this.repository.save(document);
  }
}
