import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { PickingListRepository } from '@/freight/domain/pickingList/PickingListRepository';
@Injectable()
export class FindNoShippedPickingListsNumber extends Query<Promise<number[]>> {
  public constructor(
    @Inject(TYPES.PICKING_LIST_REPOSITORY)
    private readonly repository: PickingListRepository
  ) {
    super();
  }
  async internalExecute(): Promise<number[]> {
    return await this.repository.getNoShippedLists();
  }
}
