
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import Vue from 'vue';
import Component from 'vue-class-component';
import AddCommodity from './AddCommodity.vue';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { FindAllCommodityTypes } from '@/freight/application/commodity_type/search/FindAllCommodityType';
import { CreateCommodityType } from '@/freight/application/commodity_type/create/CreateCommodityType';
import { UpdateCommodityType } from '@/freight/application/commodity_type/update/UpdateCommodityType';
import { DeleteCommodityType } from '@/freight/application/commodity_type/delete/DeleteCommodityType';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import { Territories } from '@/settings/domain/views/territories/Territories';
import { GeneralPort } from '@/settings/domain/generalPort/GeneralPort';

@Component({
  name: 'CommodityCenter',
  components: {
    AddCommodity,
    CustomTableN
  }
})
export default class CommodityCenter extends Vue {
  @Inject(TYPES.COMMODITY_TYPE_FIND_ALL)
  readonly findAllCommodity!: FindAllCommodityTypes;
  @Inject(TYPES.COMMODITY_TYPE_SAVE)
  readonly saveCommodity!: CreateCommodityType;
  @Inject(TYPES.COMMODITY_TYPE_UPDATE)
  readonly updateCommodity!: UpdateCommodityType;
  @Inject(TYPES.COMMODITY_TYPE_DELETE)
  readonly deleteCommodity!: DeleteCommodityType;
  //DATA
  commodityArray: CommodityType[] = [];
  isLoading = false;
  buttonOptions = {
    variant: 'success',
    buttonTitle: 'general.add',
    icon: 'fa-plus',
    isEdit: false
  };

  selectedTerritory: Territories | null = null;

  //OBJETC FUNCTIONS
  formFunctions = {
    clearForm: this.clearForm,
    factory: this.factory,
    find: this.findCommodity
  };

  actions = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: this.loadInfo
      },
      {
        title: `${this.$t('general.delete')}`,
        icon: 'fa fa-trash',
        variant: 'danger',
        action: this.delete
      }
    ]
  };

  //FORM
  form: CommodityType = new CommodityType();
  //GETTERS
  get fields() {
    return [
      { label: this.$t('general.id'), field: 'id', sortable: true },
      { label: this.$t('general.description'), field: 'description', sortable: true },
      {
        label: this.$t('general.port'),
        field: 'port',
        sortable: true,
        formatFn: (value: GeneralPort) => (value ? `${value?.code} - ${value?.port}` : '-')
      },
      {
        label: this.$t('general.status'),
        field: 'active',
        sortable: true,
        formatFn: (value: boolean) => (value ? this.$t('general.active') : this.$t('general.disabled'))
      },
      { label: this.$t('general.actions'), field: 'actions' }
    ];
  }

  created() {
    this.getCommodities();
  }

  factory() {
    if (!this.buttonOptions.isEdit) {
      this.create();
      return;
    }
    this.update();
  }

  create() {
    this.isLoading = true;
    this.saveCommodity
      .execute(this.form)
      .then(() => {
        this.getCommodities();
        this.isLoading = false;
        this.clearForm();
      })
      .catch(error => {
        this.isLoading = false;
        throw new Error(`${error}`);
      });
  }

  update() {
    this.isLoading = true;
    this.updateCommodity
      .execute(this.form)
      .then(() => {
        this.isLoading = false;
        this.getCommodities();
        this.clearForm();
      })
      .catch(error => {
        this.isLoading = false;
        throw new Error(error);
      });
  }

  async delete(commodity: CommodityType) {
    this.isLoading = true;
    try {
      await this.deleteCommodity.execute(commodity);
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
    this.isLoading = false;
    this.getCommodities();
  }

  getCommodities() {
    this.isLoading = true;
    this.findAllCommodity
      .execute()
      .then(response => {
        this.isLoading = false;
        this.commodityArray = response.reverse();
      })
      .catch(err => {
        this.isLoading = false;
        throw new Error(`${err}`);
      });
  }

  clearForm() {
    this.form = new CommodityType();
    this.buttonOptions = {
      variant: 'success',
      buttonTitle: 'general.add',
      icon: 'fa-solid fa-plus',
      isEdit: false
    };
    this.selectedTerritory = null;
  }

  findCommodity() {
    const commodity = Object.assign(
      {},
      this.commodityArray.filter((item: CommodityType) => {
        if (item.id == this.form.id) {
          this.buttonOptions = {
            variant: 'secondary',
            buttonTitle: 'general.edit',
            icon: 'fa-save',
            isEdit: true
          };
        }
        return item.id == this.form.id;
      })[0] || this.form
    );

    this.form.id = commodity.id;
    this.form.description = commodity.description;
    this.form.active = commodity.active;
  }

  loadInfo(data: CommodityType) {
    this.buttonOptions = {
      variant: 'secondary',
      buttonTitle: 'general.edit',
      icon: 'fa-save',
      isEdit: true
    };
    this.form = data;
  }
}
