import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { CategoryReferencesRepository } from '@/wms/domain/catalogs/categoryReferences/CategoryReferencesRepository';
import { CategoryReferences } from '@/wms/domain/catalogs/categoryReferences/CategoryReferences';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class CategoryReferencesService implements CategoryReferencesRepository {
  private readonly API_BASE_URI: string = localStorageModule().wms.host.API_BASE_URI;
  private readonly API_CATEGORYREFERENCES_FINDALL: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_CATEGORYREFERENCES_FINDALL;
  private readonly API_CATEGORYREFERENCES_SAVE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_CATEGORYREFERENCES_SAVE;
  private readonly API_CATEGORYREFERENCES_UPDATE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_CATEGORYREFERENCES_UPDATE;
  private readonly API_CATEGORYREFERENCES_DELETE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_CATEGORYREFERENCES_DELETE;

  async create(categoryReferences: CategoryReferences) {
    try {
      await http.post<CategoryReferences[]>(this.API_CATEGORYREFERENCES_SAVE, JSON.stringify(categoryReferences));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(categoryReferences: CategoryReferences) {
    try {
      await http.put<CategoryReferences[]>(this.API_CATEGORYREFERENCES_UPDATE, JSON.stringify(categoryReferences));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async delete(categoryReferences: CategoryReferences) {
    try {
      await http.singleDelete<CategoryReferences[]>(this.API_CATEGORYREFERENCES_DELETE + `/${categoryReferences.code}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAll(): Promise<CategoryReferences[]> {
    try {
      return await http.get<CategoryReferences[]>(this.API_CATEGORYREFERENCES_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
