import { HouseRepository } from '@/courier/domain/House/HouseRepository';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { House } from '@/courier/domain/House/House';
import { http } from '@/core/http/HttpFetch';

@Injectable()
export class HouseService implements HouseRepository {
  private readonly API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;
  private readonly API_HOUSE_FIND_BY_ENTITY_AND_MASTER: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_HOUSE_FIND_BY_ENTITY_AND_MASTER;
  private readonly API_HOUSE_FIND_BY_DATE_RANGE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_HOUSE_FIND_BY_DATE_RANGE;

  async findByEntityAndMaster(entityCode: number, trackingNumber: string): Promise<House[]> {
    try {
      return await http.get(
        `${this.API_HOUSE_FIND_BY_ENTITY_AND_MASTER}entityCode=${entityCode}&trackingNumber=${encodeURI(
          trackingNumber
        )}`
      );
    } catch (error) {
      throw new Error('Method not implemented.');
    }
  }
  async findByDateRange(from: string, to: string): Promise<string[]> {
    try {
      return await http.get(`${this.API_HOUSE_FIND_BY_DATE_RANGE}startDate=${from}&endDate=${to}`);
    } catch (error) {
      throw new Error('Method not implemented.');
    }
  }
}
