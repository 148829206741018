
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { FindAllStore } from '@/wms/application/layout/store/search/FindAllStore';
import { Store } from '@/wms/domain/layout/store/Store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'StoreSearch',
  components: {}
})
export default class StoreSearch extends Vue {
  //Variable para almacenar la lista de territorios
  localStores: Store[] = [];

  //Variable para controlar el estado de la carga
  isLoading = false;

  @Inject(TYPES.FIND_ALL_STORE)
  readonly findAllStores!: FindAllStore;

  @Prop({ required: true }) value!: Store | null;
  @Prop({ required: false }) label!: string | null;
  @Prop({ required: false, default: '' }) containerClass!: string | null;
  @Prop({ required: false, default: '' }) validationRules!: string | null;
  @Prop({
    required: false,
    default: (): null => {
      return null;
    }
  })
  onInput!: (store: Store | null) => Promise<void> | void;
  @Prop({ required: false, default: false }) readonly disabled!: boolean;
  @Prop({ required: false }) stores!: Store[];
  @Prop({ required: false }) valuePath!: keyof Store;

  mounted() {
    if (!this.stores) this.findStores();
  }

  get localValue(): Store | null {
    return this.value;
  }

  set localValue(value: Store | null) {
    this.$emit('update:value', value);
  }

  /**
   * @description Funcion para buscar tdas las stores y asignarlo a la lista
   * @name findStores
   */
  async findStores() {
    this.isLoading = true;
    try {
      const response = await this.findAllStores.execute();
      this.localStores = response;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  //Función invocada cuando se selecciona un territorio y se desea enviar al padre para ejecutar alguna acción.
  async dispatchInput(value: Store | null) {
    if (this.onInput) await this.onInput(value);
  }

  //Watch invocado para cuando el valor de la variable stores cambie
  @Watch('stores')
  onStoresChange(store: Store[] | null) {
    if (store) this.localStores = store;
  }
}
