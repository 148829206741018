import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { UserERP } from '@/settings/domain/userERP/UserERP';
import { UserErpRepository } from '@/settings/domain/userERP/UserErpRepository';

@Injectable()
export class CreateUserERP extends Command<Promise<UserERP>, UserERP> {
  public constructor(
    @Inject(TYPES.USER_ERP_REPOSITORY)
    private readonly userErpRepository: UserErpRepository
  ) {
    super();
  }

  async internalExecute(user: UserERP): Promise<UserERP> {
    return await this.userErpRepository.create(user);
  }
}
