export class RoleDTO {
  id: number;
  name: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  updatedBy: string;
  namespace: string;
  permissions: string[];
  menu: number[];

  public constructor() {
    this.id = 0;
    this.name = '';
    this.type = '';
    this.createdAt = '';
    this.updatedAt = '';
    this.updatedBy = '';
    this.namespace = '';
    this.permissions = [];
    this.menu = [];
  }
}
