
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'HeaderModal'
})
export default class HeaderModal extends Vue {
  @Prop() eventDescription!: string;
  @Prop() activityDescription!: string;
}
