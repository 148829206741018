
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'TimelineComponent'
})
export default class AppTimelineComponent extends Vue {
  //Data
  hover = false;
  myIndex = 0;
  //Prop
  @Prop({
    type: Array,
    required: true
  })
  private readonly items!: [];
  @Prop({ type: Number, required: true }) private readonly selectedBuble = 0;
  //computed property
  get hourTotal() {
    let sumaTotal = 0;
    //const hour = 24;
    for (const item of this.items) {
      sumaTotal += item['day'];
    }
    return sumaTotal;
  }
  //Methods
  acumulated(number: number) {
    this.myIndex = number;
    let myItem = 0;
    for (let i = 0; i <= number; i++) {
      myItem += this.items[i]['day'];
    }
    return myItem;
  }
}
