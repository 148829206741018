import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { TermsPayment } from '@/settings/domain/ParmsGeneral/termsPayment/TermsPayment';
import { TermsPaymentRepository } from '@/settings/domain/ParmsGeneral/termsPayment/TermsPaymentRepository';

@Injectable()
export class TermsPaymentFindAll extends Query<Promise<TermsPayment[]>> {
  public constructor(
    @Inject(TYPES.TERMS_PAYMENT_REPOSITORY)
    private readonly repository: TermsPaymentRepository,

    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(): Promise<TermsPayment[]> {
    const dataTermsPayment = await this.repository.findAll();
    this.stateManager.patch({ dataTermsPayment });
    return this.stateManager.state.dataTermsPayment;
  }
}
