import { City } from '../city/City';
import { Country } from '../country/Country';
import { States } from '../state/States';
import { ZoneLevel } from '../ZoneLevel/ZoneLevel';

export class PricingZone {
  active: boolean;
  city: City | null;
  code: string;
  country: Country | null;
  createAt: string;
  level: string;
  name: string;
  state: States | null;
  type: string;
  updatedAt: string;
  zoneLevel: ZoneLevel[];

  public constructor({ code, level }: { code?: string; level?: string } = {}) {
    this.code = code || '';
    this.name = '';
    this.type = 'origin';
    this.country = null;
    this.state = null;
    this.city = null;
    this.active = true;
    this.createAt = '';
    this.updatedAt = '';
    this.level = level || '3';
    this.zoneLevel = [];
  }
}
