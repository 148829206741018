
import { TYPES } from '@/core/config/Types';
import { StockCalculatedValues } from '@/courier/domain/DocsLin/DocsLin';
import { FindAllHistoryMovementsStock } from '@/wms/application/stock/search/FindAllHistoryMovementsStock';
import { Inject } from 'inversify-props';
import Vue from 'vue';
import Component from 'vue-class-component';
import { VuePivottableUi } from 'vue-pivottable';
import 'vue-pivottable/dist/vue-pivottable.css';
import { v4 as uuidv4 } from 'uuid';
@Component({
  name: 'CommercialManagement',
  components: { VuePivottableUi }
})
export default class CommercialManagement extends Vue {
  @Inject(TYPES.STOCK_FIND_ALL_HISTORY_MOVEMENTS) getMovements!: FindAllHistoryMovementsStock;

  nameConfig = '';
  configSelected: any | null = null;
  pivotTableState: any = {};
  listPivotConfigs = JSON.parse(localStorage.getItem('pivotConfigs') ?? '[]');
  isLoading = false;

  // Listas
  dataPivotTable: string[][] = [];

  // Getters
  get configSelectedName() {
    return !this.configSelected ? this.nameConfig : this.configSelected.configName;
  }

  private restorePivotConfig() {
    if (this.configSelected) {
      this.pivotTableState = {
        ...this.configSelected,
        rows: this.configSelected.rows,
        cols: this.configSelected.cols,
        aggregatorName: this.configSelected.aggregatorName,
        vals: this.configSelected.vals
      };
    }
  }

  created() {
    this.handleRequest();
  }

  private handleNameConfig(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.nameConfig = target.value;
  }

  private handleClose(): void {
    const modal = this.$refs.pivotConfigModal as any;
    if (modal && typeof modal.hide === 'function') {
      modal.hide();
    }
  }

  private handleSave(): void {
    // Implement save logic here
    this.savePivotConfig();
    this.nameConfig = this.configSelected?.configName ?? '';
    this.handleClose();
  }

  private setLocalStorage(key: string, item: unknown): boolean {
    try {
      localStorage.setItem(key, JSON.stringify(item));

      return true;
    } catch (error) {
      return false;
    }
  }

  private savePivotConfig() {
    const pivotTableStateCopy = JSON.parse(JSON.stringify(this.pivotTableState));
    delete pivotTableStateCopy.aggregators;
    delete pivotTableStateCopy.renderers;

    let configs = (() => {
      const fieldValueStored = localStorage.getItem('pivotConfigs') as string;
      const fieldValue = JSON.parse(fieldValueStored);

      return fieldValue === null ? [] : fieldValue;
    })();

    if (!this.configSelected) {
      configs.push({ ...pivotTableStateCopy, configId: uuidv4(), configName: this.nameConfig });
    } else {
      configs = configs.map((item: any) => {
        if (item.configId === this.configSelected.configId) {
          return { ...item, ...pivotTableStateCopy, configName: this.nameConfig };
        }

        return item;
      });
    }

    const saveConfig = this.setLocalStorage('pivotConfigs', configs);
    if (saveConfig) {
      this.$toasted.success(
        ` ${this.$t('general.successTableConfig')}`,

        {
          position: 'top-center'
        }
      );
      this.listPivotConfigs = JSON.parse(localStorage.getItem('pivotConfigs') ?? '[]');
    } else {
      this.$toasted.error(
        `${this.$t('general.errorTableConfig')}`,

        {
          position: 'top-center'
        }
      );
    }
  }

  private async handleRequest() {
    this.isLoading = true;

    const date = new Date();
    const resMovements = await this.getMovements.execute(date.getFullYear());

    const res = resMovements.map(
      (movement: StockCalculatedValues) =>
        [
          movement.storeId,
          movement.year,
          movement.month,
          `${movement.dateDoc}, ${movement.timeDoc}`,
          movement.sw,
          movement.type,
          movement.number,
          `${movement.reference?.code} - ${movement.reference?.filterBrand}`,
          movement.categoria,
          movement.marca,
          movement.familia,
          movement.subFamilia,
          movement.grupo,
          movement.subGrupo,
          movement.quantity,
          movement.totalTaxes,
          movement.entry,
          movement.invoice,
          movement.stockBalance,
          movement.stockValue.toFixed(2),
          movement.unitCostSetted,
          movement.unitValueSetted.toFixed(2),
          movement.totalCost.toFixed(2),
          movement.averageCost.toFixed(2),
          movement.totalPrice.toFixed(2),
          movement.profit,
          movement.proveedor,
          movement.subCategoria,
          movement.nivelIii,
          movement.nivelIv,
          movement.storeName,
          movement.canIni,
          movement.cosIni,
          movement.canEnt,
          movement.cosEnt,
          movement.canSal,
          movement.cosSal,
          movement.stock,
          movement.costoStock,
          movement.costoPromedio,
          movement.valorValoriz,
          movement.porcValoriz,
          movement.nroVta,
          movement.canVta,
          movement.cosVta,
          movement.valVta,
          movement.nroDevVta,
          movement.canDevVta,
          movement.cosDevVta,
          movement.valDev,
          movement.canVtaNeta,
          movement.cosVtaNeta,
          movement.valVtaNeta,
          movement.valUtilidad,
          movement.porcUtil,
          movement.canPed,
          movement.negados,
          movement.valNegados,
          movement.porcNegados,
          movement.nroCom,
          movement.canCom,
          movement.cosCom,
          movement.nroDevCom,
          movement.canDevCom,
          movement.cosDevCom,
          movement.canOtrEnt,
          movement.cosOtrEnt,
          movement.canOtrSal,
          movement.cosOtrSal,
          movement.canTra,
          movement.subCos,
          movement.bajCos,
          movement.standardCost
        ] as string[]
    );

    this.dataPivotTable = [
      [
        `${this.$t('general.Store')}`,
        `${this.$t('general.Year')}`,
        `${this.$t('general.Month')}`,
        `${this.$t('Date')}`,
        `${this.$t('general.Nature')}`,
        `${this.$t('Type')}`,
        `${this.$t('Number')}`,
        `${this.$t('general.Reference')}`,
        `${this.$t('general.Category')}`,
        `${this.$t('general.Brand')}`,
        `${this.$t('general.Family')}`,
        `${this.$t('general.subFamily')}`,
        `${this.$t('general.GroupR')}`,
        `${this.$t('general.subGroupR')}`,
        `${this.$t('Quantity')}`,
        `${this.$t('Taxes')}`,
        `${this.$t('general.Entry')}`,
        `${this.$t('general.Outgoing')}`,
        `${this.$t('general.Stock balance')}`,
        `${this.$t('general.Stock value')}`,
        `${this.$t('general.Unit cost')}`,
        `${this.$t('general.Unit price')}`,
        `${this.$t('general.Total cost')}`,
        `${this.$t('general.Average cost')}`,
        `${this.$t('general.Total price')}`,
        `% ${this.$t('general.Profit')}`,
        `${this.$t('general.Proveedor')}`,
        `${this.$t('general.SubCategoria')}`,
        `${this.$t('general.NivelIii')}`,
        `${this.$t('general.NivelIv')}`,
        `${this.$t('general.storeName')}`,
        `${this.$t('general.CanIni')}`,
        `${this.$t('general.CosIni')}`,
        `${this.$t('general.CanEnt')}`,
        `${this.$t('general.CosEnt')}`,
        `${this.$t('general.CanSal')}`,
        `${this.$t('general.CosSal')}`,
        `${this.$t('general.Stock')}`,
        `${this.$t('general.CostoStock')}`,
        `${this.$t('general.CostoPromedio')}`,
        `${this.$t('general.ValorValoriz')}`,
        `${this.$t('general.PorcValoriz')}`,
        `${this.$t('general.NroVta')}`,
        `${this.$t('general.CanVta')}`,
        `${this.$t('general.CosVta')}`,
        `${this.$t('general.ValVta')}`,
        `${this.$t('general.NroDevVta')}`,
        `${this.$t('general.CanDevVta')}`,
        `${this.$t('general.CosDevVta')}`,
        `${this.$t('general.ValDev')}`,
        `${this.$t('general.CanVtaNeta')}`,
        `${this.$t('general.CosVtaNeta')}`,
        `${this.$t('general.ValVtaNeta')}`,
        `${this.$t('general.ValUtilidad')}`,
        `${this.$t('general.PorcUtil')}`,
        `${this.$t('general.CanPed')}`,
        `${this.$t('general.Negados')}`,
        `${this.$t('general.ValNegados')}`,
        `${this.$t('general.PorcNegados')}`,
        `${this.$t('general.NroCom')}`,
        `${this.$t('general.CanCom')}`,
        `${this.$t('general.CosCom')}`,
        `${this.$t('general.NroDevCom')}`,
        `${this.$t('general.CanDevCom')}`,
        `${this.$t('general.CosDevCom')}`,
        `${this.$t('general.CanOtrEnt')}`,
        `${this.$t('general.CosOtrEnt')}`,
        `${this.$t('general.CanOtrSal')}`,
        `${this.$t('general.CosOtrSal')}`,
        `${this.$t('general.CanTra')}`,
        `${this.$t('general.SubCos')}`,
        `${this.$t('general.BajCos')}`,
        `${this.$t('general.StandardCost')}`
      ],
      ...res
    ];
    this.isLoading = false;
  }
}
