import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Query } from '@/core/cqrs/Query';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { EntityRepository } from '@/settings/domain/entity/entity/EntityRepository';

@Injectable()
export class EntityFindAll extends Query<Promise<Entity[]>> {
  public constructor(
    @Inject(TYPES.API_ENTITY_REPOSITORY)
    private readonly customerRepository: EntityRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(): Promise<Entity[]> {
    return await this.customerRepository.findAll();
  }
}
