import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Command } from '@/core/cqrs/Command';
import { UserRepository } from '@/settings/domain/user/UserRepository';

@Injectable()
export class UpdateUserPassword extends Command<Promise<any>, any> {
  public constructor(
    @Inject(TYPES.USER_REPOSITORY)
    private readonly userRepository: UserRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  internalExecute(payload: any): Promise<any> {
    return this.userRepository.updatePassword(payload);
  }
}
