import { ActivityTransactional } from '@/tracking/domain/activitiesTransactional/ActivityTransactional';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { ActivityTransactionalRepository } from '@/tracking/domain/activitiesTransactional/ActivityTransactionalRepository';
import { Injectable } from '@/core/di/Injectable';

@Injectable()
export class FindTransactionalActivityById extends Command<
  Promise<ActivityTransactional>,
  {
    preRegisterType: string;
    preRegisterNumber: number;
    eventId: number;
    activityId: number;
  }
> {
  public constructor(
    @Inject(TYPES.ACTIVITIES_TRANSACTIONAL_RESPOSITORY)
    private readonly activityTransactionalRepository: ActivityTransactionalRepository
  ) {
    super();
  }

  async internalExecute({
    preRegisterType,
    preRegisterNumber,
    eventId,
    activityId
  }: {
    preRegisterType: string;
    preRegisterNumber: number;
    eventId: number;
    activityId: number;
  }): Promise<ActivityTransactional> {
    return await this.activityTransactionalRepository.findById({
      preRegisterType,
      preRegisterNumber,
      eventId,
      activityId
    });
  }
}
