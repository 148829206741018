
import Vue from 'vue';
import Component from 'vue-class-component';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { FindAllColor } from '@/settings/application/uses_cases/gamma/color/search/ColorFindall';
import { Color } from '@/wms/domain/gamma/Color';
import { CreateColor } from '@/settings/application/uses_cases/gamma/color/create/CreateColor';
import { UpdateColor } from '@/settings/application/uses_cases/gamma/color/update/UpdateColor';
import { DeleteColor } from '@/settings/application/uses_cases/gamma/color/delete/DeleteColor';
@Component({
  name: 'ColorModal',
  components: { CustomTableN }
})
export default class ColorModal extends Vue {
  @Inject(TYPES.COLOR_FINDALL)
  readonly findAllColors!: FindAllColor;
  @Inject(TYPES.COLOR_SAVE)
  readonly createColor!: CreateColor;
  @Inject(TYPES.COLOR_UPDATE)
  readonly updateColor!: UpdateColor;
  @Inject(TYPES.COLOR_DELETE)
  readonly deleteColor!: DeleteColor;

  color: Color = new Color();
  isLoading = false;
  isEditing = false;

  items: Color[] = [];

  mounted() {
    this.findColors();
  }

  get fields() {
    return [
      {
        field: 'code',
        label: this.$i18n.t('general.code'),
        sortable: true
      },
      {
        field: 'color',
        label: this.$i18n.t('general.name'),
        sortable: true
      },
      {
        field: 'actions',
        label: this.$i18n.t('general.actions')
      }
    ];
  }

  actions = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: this.setItemToForm
      },
      {
        title: `${this.$t('general.delete')}`,
        icon: 'fa fa-trash',
        variant: 'danger',
        action: this.itemDelete
      }
    ]
  };

  async saveColor() {
    try {
      this.isLoading = true;
      const res = await this.createColor.internalExecute(this.color);

      if (!('error' in res)) {
        this.color = new Color();
        this.findColors();
        this.isEditing = false;
      }
    } finally {
      this.isLoading = false;
    }
  }
  async findColors() {
    const res = await this.findAllColors.internalExecute();
    this.items = res;
  }

  async update() {
    try {
      this.isLoading = true;
      const res = await this.updateColor.internalExecute(this.color);

      if (!('error' in res)) {
        this.color = new Color();
        this.findColors();
        this.isEditing = false;
      }
    } finally {
      this.isLoading = false;
    }
  }

  reset() {
    this.isEditing = false;
    this.color = new Color();
  }

  factory() {
    this.isEditing ? this.update() : this.saveColor();
  }

  setItemToForm(item: Color) {
    this.isEditing = true;
    this.color = Object.assign({}, item);
  }

  async itemDelete(item: Color) {
    try {
      this.isLoading = true;
      await this.deleteColor.internalExecute(item);
    } finally {
      this.isLoading = false;
      this.reset();
      this.findColors();
    }
  }
}
