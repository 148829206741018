
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { EntityFindFilter } from '@/settings/application/uses_cases/entity/search/EntityFindFilter';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'EntitySearch',
  components: {}
})
export default class EntitySearch extends Vue {
  //Timeout para evitar búsquedas repetitivas y solapadas.
  timeout: any | undefined = undefined;

  //Intervalo de tiempo para realizar la búsqueda
  searchInterval = 500;

  //Variable para almacenar la lista de territorios
  entities: Entity[] = [];

  //Variable para controlar el estado de la carga
  isLoading = false;

  @Inject(TYPES.ENTITY_FIND_BY_DESCRIPTION_LIKE)
  readonly entityFindByName!: EntityFindFilter;

  @Prop({ required: true }) value!: Entity | null;
  @Prop({ required: false }) label!: string | null;
  @Prop({ required: false, default: '' }) containerClass!: string | null;
  @Prop({ required: false, default: '' }) validationRules!: string | null;
  @Prop({
    required: false,
    default: (): null => {
      return null;
    }
  })
  onInput!: (entity: Entity) => Promise<void> | void;
  @Prop({ required: false, default: false }) readonly disabled!: boolean;
  @Prop({ required: false, default: () => ['CUSTOMER', 'SUPPLIER', 'SERVICE', 'OTHERS'] }) readonly types!: string[];
  get localValue(): Entity | null {
    return this.value;
  }

  set localValue(value: Entity | null) {
    this.$emit('update:value', value);
  }

  // Este método es llamado cuando el usuario escribe sobre el input para buscar territorios.
  findEntitiesFactory(query: string): void {
    //Limpiamos cualquier función anterior en el timeout para evitar búsquedas repetitivas y solapadas.
    clearTimeout(this.timeout);
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    this.timeout = setTimeout(async () => {
      if (query.length >= 3) {
        try {
          const res = await this.getEntities(query);
          this.entities = res;
        } catch (error) {
          this.isLoading = false;
          this.entities = [];
          throw new Error(`${error}`);
        }
      }
      clearTimeout(this.timeout);
    }, this.searchInterval);
  }

  //Funcion invocada para buscar los territorios
  async getEntities(query: string) {
    this.isLoading = true;
    const res = await this.entityFindByName.execute({
      text: query.toUpperCase(),
      type: this.types
    });
    this.isLoading = false;

    return res;
  }

  //Función invocada cuando se selecciona un territorio y se desea enviar al padre para ejecutar alguna acción.
  async dispatchInput(value: Entity) {
    if (this.onInput) await this.onInput(value);
  }
}
