export class CalcResponse {
  isValidPriceList: boolean;
  transportName: string;
  transportId: number;
  routeId: number;
  timeLineId: number;
  rateValue: number;
  isVolumePriceList: boolean;
  volumetricWeight: number;
  subTotal: number;
  totalWeight: number;
  taxes: any = [];

  public constructor() {
    this.isValidPriceList = false;
    this.transportName = '';
    this.transportId = 0;
    this.routeId = 0;
    this.timeLineId = 0;
    this.volumetricWeight = 0;
    this.rateValue = 0;
    this.isVolumePriceList = false;
    this.subTotal = 0;
    this.totalWeight = 0;
    this.taxes = [];
  }
}
