import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { PreRegisterRepository } from '@/wms/domain/preRegister/PreRegisterRepository';

@Injectable()
export class PreRegisterCreateReport extends Query<Promise<string>, { report: string; type: string; number: number }> {
  public constructor(
    @Inject(TYPES.PRE_REGISTER_REPOSITORY)
    private readonly preRegisterRepository: PreRegisterRepository
  ) {
    super();
  }
  async internalExecute({ report, type, number }: { report: string; type: string; number: number }): Promise<string> {
    const res = await this.preRegisterRepository.createReport({ report, type, number });
    return res;
  }
}
