export class Person {
  Nombres?: string;
  Apellidos?: string;
  Documento?: string;
  IdTipoDocumento?: number;
  IdTipoPersona?: number;
  Genero?: string;
  Telefono?: string;
  Celular?: string;
  Direccion?: string;
  Email?: string;
  Imagen?: string | null;
  CodigoTarjeta?: string;
  SaldoTarjeta?: number | null;
  EsClienteInterno?: boolean;
  EsCliente?: boolean;
  EsProveedor?: boolean;
  EsContacto?: boolean;
  EsFuente?: boolean;
  EsVendedor?: boolean;
  Paciente?: boolean;
  IdEmpresa?: number | null;
  IdCiudad?: number;
  FechaNacimiento?: Date | null;
  IdDepartamento?: number;
  IdPais?: number;
  Ciudad?: any;
  CodCIIU?: number;
  PlazoFactura?: number;
  Empresa?: any;
  GeneraDE?: boolean;
  DireccionEntrega?: string;
  NombreCompleto?: string;
  TipoDocumento?: any;
  TipoPersona?: any;

  public constructor() {
    this.Nombres = '';
    this.Apellidos = '';
    this.Documento = '';
    this.IdTipoDocumento = 0;
    this.IdTipoPersona = 2;
    this.Genero = '';
    this.Telefono = '';
    this.Celular = '';
    this.Direccion = '';
    this.Email = '';
    this.Imagen = null;
    this.CodigoTarjeta = '';
    this.SaldoTarjeta = null;
    this.EsClienteInterno = false;
    this.EsCliente = false;
    this.EsProveedor = false;
    this.EsContacto = false;
    this.EsFuente = false;
    this.EsVendedor = false;
    this.Paciente = false;
    this.IdEmpresa = null;
    this.IdCiudad = 1;
    this.FechaNacimiento = null;
    this.IdDepartamento = undefined;
    this.IdPais = 1;
    this.Ciudad = null;
    this.CodCIIU = 0;
    this.PlazoFactura = 0;
    this.Empresa = null;
    this.GeneraDE = false;
    this.DireccionEntrega = '';
    this.NombreCompleto = '';
    this.TipoDocumento = null;
    this.TipoPersona = null;
  }
}
