import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { DocumentsV2Repository } from '@/courier/domain/DocumentsV2/DocumentsRepository_V2';
import { DocumentHeader } from '@/courier/domain/DocumentsV2/Documents_V2';
import { DocumentsHeaderForTable } from '@/courier/domain/DocumentsV2/Projections/DocumentsHeaderForTable';

@Injectable()
export class DocumentsV2Service implements DocumentsV2Repository {
  /**
   * @description Ruta base del API
   * @type {string}
   */
  private readonly API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;

  /**
   * @description Ruta del API para crear documentos
   * @type {string}
   */
  private readonly API_DOCUMENTS_SAVE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_DOCUMENTS_V2;

  /**
   * @description Ruta del API para actualizar documentos
   * @type {string}
   */
  private readonly API_DOCUMENTS_UPDATE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_DOCUMENTS_V2;

  /**
   * @description Ruta del API para buscar documento por number y tipo
   * @type {string}
   */
  private readonly API_DOCUMENTS_FIND_BY_PK: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_DOCUMENTS_V2_FIND_BY_PK;

  /**
   * @description Ruta del API para buscar documentos por tipo
   * @type {string}
   */
  private readonly API_DOCUMENTS_FIND_ALL_BY_TYPE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_DOCUMENTS_V2_FIND_BY_TYPE;

  /**
   * @description Ruta del API para buscar documentos por switches, rango de fechas y entidad
   * @type {string}
   */
  private readonly API_DOCUMENTS_BY_SWITCHS_AND_FILTERS: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_DOCUMENTS_BY_SWITCHS;

  /**
   * @description Ruta del API para buscar documentos por switches y rango de fechas
   * @type {string}
   */
  private readonly API_DOCUMENTS_BY_SWITCHS_AND_DATERANGE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_DOCUMENTS_V2_FIND_BY_SWITCHS_AND_DATE_RANGE;

  /**
   * @description Ruta del API para buscar documentos por master
   * @type {string}
   */
  private readonly API_DOCUMENTS_FIND_BY_MASTER: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_DOCUMENTS_V2_FIND_BY_MASTER;

  /**
   * @description Ruta del API para buscar documentos para la tabla de
   * @type {string}
   */
  private readonly API_DOCUMENTS_FIND_PAYMENTS_FOR_PRE_REGISTER: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_DOCUMENTS_FIND_PAYMENTS_FOR_PRE_REGISTER;

  private readonly API_DOCUMENTS_V2_FIND_BY_SWITCHS: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_DOCUMENTS_V2_FIND_BY_SWITCHS;

  /**
   * @description Guardar documentos
   * @returns {Promise<DocumentHeader>}
   */
  async save(document: DocumentHeader): Promise<DocumentHeader> {
    try {
      // eslint-disable-next-line
      console.log('service', JSON.stringify(document));
      return await http.post(this.API_DOCUMENTS_SAVE, JSON.stringify(document));
    } catch (error) {
      throw new Error(`${error}, Error al guardar documento`);
    }
  }

  /**
   * @description Actualizar documentos
   * @returns {Promise<DocumentHeader>}
   */
  async update(document: DocumentHeader): Promise<DocumentHeader> {
    try {
      return await http._put(this.API_DOCUMENTS_UPDATE, JSON.stringify(document));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  /**
   * @description Busca documento por number y tipo
   * @returns {Promise<DocumentHeader>}
   */
  async findByPk(payload: { number: number; typeOrder: string }): Promise<DocumentHeader> {
    const { number, typeOrder } = payload;
    try {
      return await http.get(`${this.API_DOCUMENTS_FIND_BY_PK}?number=${number}&type=${typeOrder}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  /**
   * @description Busca documentos por tipo
   * @returns {Promise<DocumentHeader[]>}
   */
  async findAllByType(type: string): Promise<DocumentHeader[]> {
    try {
      return await http.get(`${this.API_DOCUMENTS_FIND_ALL_BY_TYPE}${type}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  /**
   * @description Busca documentos por switches, rango de fechas y entidad
   * @returns {Promise<DocumentHeader[]>}
   */
  async findBySwitchsAndFilters(dateFrom: string, dateTo: string, entityCode: number): Promise<DocumentHeader[]> {
    try {
      return await http.get(`${this.API_DOCUMENTS_BY_SWITCHS_AND_FILTERS}${dateFrom}/${dateTo}/${entityCode}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  /**
   * @description Busca documentos por switches y rango de fechas
   * @returns {Promise<DocumentHeader[]>}
   */
  async findBySwitchsAndDateRange(dateFrom: string, dateTo: string, sw: string[]): Promise<DocumentHeader[]> {
    try {
      return await http.get(
        `${this.API_DOCUMENTS_BY_SWITCHS_AND_DATERANGE}startDate=${dateFrom}&endDate=${dateTo}&switchs=${sw}`
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  /**
   * @description Busca documentos por master
   * @returns {Promise<DocumentHeader[]>}
   */
  async findByMaster(number: number, type: string): Promise<DocumentHeader[]> {
    try {
      return await http.get(`${this.API_DOCUMENTS_FIND_BY_MASTER}master=${number}&type=${type}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  /**
   * @description Busca documentos para la tabla de pre-registro
   * @returns {Promise<DocumentHeader[]>}
   */
  async findPaymentsByMasterAndEntityAndDateRange(payload: {
    dateFrom: string | null;
    dateTo: string | null;
    masterType: string;
    masterNumber: number;
    entity: number | null;
  }): Promise<DocumentsHeaderForTable[]> {
    try {
      let query = this.API_DOCUMENTS_FIND_PAYMENTS_FOR_PRE_REGISTER;

      if (payload.masterType) {
        query += `masterType=${payload.masterType}`;
      }

      if (payload.masterNumber) {
        query += `&masterNumber=${payload.masterNumber}`;
      }
      if (payload.dateFrom) {
        query += `dateFrom=${payload.dateFrom}`;
      }

      if (payload.dateTo) {
        query += `&dateTo=${payload.dateTo}`;
      }

      if (payload.entity) {
        query += `&entity=${payload.entity}`;
      }

      return await http.get(`${query}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findBySwitch(sw: string[]): Promise<DocumentHeader[]> {
    try {
      return await http.get(`${this.API_DOCUMENTS_V2_FIND_BY_SWITCHS}${sw}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
