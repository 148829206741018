import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { PickingList } from '@/freight/domain/pickingList/PickingList';
import { PickingListRepository } from '@/freight/domain/pickingList/PickingListRepository';
@Injectable()
export class FindShippedPickingListsNumberByDateRange extends Query<
  Promise<PickingList[]>,
  {
    dateFrom: string;
    dateTo: string;
    carrier: number | undefined;
    consignee: string | null;
  }
> {
  public constructor(
    @Inject(TYPES.PICKING_LIST_REPOSITORY)
    private readonly repository: PickingListRepository
  ) {
    super();
  }
  async internalExecute({
    dateFrom,
    dateTo,
    carrier,
    consignee
  }: {
    dateFrom: string;
    dateTo: string;
    carrier: number | undefined;
    consignee: string | null;
  }): Promise<PickingList[]> {
    return await this.repository.getShippedLists({ dateFrom, dateTo, carrier, consignee });
  }
}
