
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { SearchTerritoryByQueryDescription } from '@/settings/application/uses_cases/views/territories/SearchTerritoryByQueryDescription';
import { StateFindByCountryAndDescription } from '@/settings/application/uses_cases/state/search/StateFindByCountryAndDescription';
import { SearchCitiesByCountryStateAndDescription } from '@/settings/application/uses_cases/city/search/SearchCitiesByCountryStateAndDescription';
import { CountryFindByDescriptionLikeInGeneralTerritories } from '@/settings/application/uses_cases/country/search/CountryFindByDescriptionLikeInGeneralTerritories';
import { PricingZone } from '@/settings/domain/PricingZone/PricingZone';
import { ZoneLevel } from '@/settings/domain/ZoneLevel/ZoneLevel';
@Component({
  name: 'AddNewPricingZone'
})
export default class AddNewPricingZone extends Vue {
  @Inject(TYPES.API_VIEW_FIND_TERRITORIES_BY_QUERY)
  readonly searchCities!: SearchTerritoryByQueryDescription;
  @Inject(TYPES.COUNTRY_FIND_BY_DESCRIPTION_IN_GENERAL_TERRITORIES)
  readonly searchCountrByName!: CountryFindByDescriptionLikeInGeneralTerritories;
  @Inject(TYPES.FIND_STATE_BY_COUNTRY_AND_DESCRIPTION_LIKE)
  readonly searchStateInCountry!: StateFindByCountryAndDescription;
  @Inject(TYPES.CITY_SEARCH_BY_COUNTRY_STATE_AND_DESCRIPTION)
  readonly searchCityInState!: SearchCitiesByCountryStateAndDescription;

  //LISTAS
  countryMainList: any[] = [];
  statesMainList: any[] = [];
  citiesMainList: any[] = [];

  countrySecondaryList: any[] = [];
  statesSecondaryList: any[] = [];
  citiesSecondaryList: any[] = [];

  //FORM DATA
  @Prop() formCreate!: PricingZone;
  @Prop() buttonOptions!: { variant: string; buttonTitle: string; icon: string; isEdit: boolean };
  @Prop() formFunctions!: { clearForm: any; factory: any };
  @Prop() zonePricingLevel!: string;
  @Prop() deleteAssociationOnDb!: (index: number, zoneLevel: ZoneLevel) => Promise<void>;

  //VARIABLES PARA INTERVALO DE BUSQUEDA
  mainTimeOut: any;
  searchInterval = 600;
  //OTRAS VARIABLEs
  isLoading = false;

  //GETTERS
  get typeOptions() {
    return ['origin', 'destination'];
  }
  //FUNCION DE VALIDACION DE INPUTS
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  //FUNCION INVOCADA PARA AGREGAR UNA ASOCIACION A LA ZONA
  addAssociation() {
    this.formCreate.zoneLevel.push(new ZoneLevel());
  }
  //FUNCION INVOCADA PARA ELIMINAR UNA ASOCIACION A DE LA ZONA LOCALMENTE O EN LA BASE DE DATOS

  //FUNCION INVOCADA PARA BUSCAR PAISES A PARTIR DE UN QUERY
  findAllCountries(query: string, input: string) {
    clearTimeout(this.mainTimeOut);
    if (!(query.length > 2)) return;
    this.mainTimeOut = setTimeout(() => {
      (async () => {
        this.isLoading = true;
        const res = await this.searchCountrByName.execute(query);
        input == 'main' ? (this.countryMainList = res) : (this.countrySecondaryList = res);
        this.isLoading = false;
      })();
    }, this.searchInterval);
  }

  //FUNCION INVOCADA PARA BUSCAR ESTADOS DE UN PAIS A PARTIR DE UN QUERY
  findStates(query: string, input: string, association?: any) {
    clearTimeout(this.mainTimeOut);
    if (!(query.length > 2)) return;
    this.mainTimeOut = setTimeout(() => {
      (async () => {
        this.isLoading = true;
        const res = await this.searchStateInCountry.execute({
          countryCode: !association ? this.formCreate.country?.code : association.country.code,
          description: query
        });
        input == 'main' ? (this.statesMainList = res) : (this.statesSecondaryList = res);
        this.isLoading = false;
      })();
    }, this.searchInterval);
  }

  //FUNCION INVOCADA PARA BUSCAR CIUDADES DE UN ESTADO
  findCities(query: string, input: string, association?: any) {
    clearTimeout(this.mainTimeOut);
    if (!(query.length > 2)) return;
    this.mainTimeOut = setTimeout(() => {
      (async () => {
        this.isLoading = true;
        const res = await this.searchCityInState.execute({
          country: !association ? this.formCreate.country?.code : association.country.code,
          state: !association ? this.formCreate.state?.code : association.state.code,
          query: query
        });
        input == 'main' ? (this.citiesMainList = res) : (this.citiesSecondaryList = res);
        this.isLoading = false;
      })();
    }, this.searchInterval);
  }
}
