import { References } from '@/wms/domain/catalogs/references/References';
import { Weight } from '../weight/Weight';
import { Taxe } from '../taxe/Taxe';

export class ReferencesPriceListValue {
  reference: References | null;
  value: number;
  minRate: number;
  applyFactorConversion: boolean;
  reportedIn: Weight | null;
  factor: number;
  taxes: Taxe[];

  constructor() {
    this.reference = null;
    this.value = 0;
    this.applyFactorConversion = false;
    this.reportedIn = null;
    this.factor = 0;
    this.minRate = 0;
    this.taxes = [];
  }
}
