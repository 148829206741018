import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Command } from '@/core/cqrs/Command';
import { TypeContact } from '@/settings/domain/ParmsGeneral/typeContact/TypeContact';
import { TypeContactRepository } from '@/settings/domain/ParmsGeneral/typeContact/TypeContactRepository';

@Injectable()
export class CreateTypeContact extends Command<Promise<any>, TypeContact> {
  public constructor(
    @Inject(TYPES.TYPE_CONTACT_REPOSITORY)
    private readonly repository: TypeContactRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  internalExecute(typeContact: TypeContact): Promise<any> {
    return this.repository.create(typeContact);
  }
}
