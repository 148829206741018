import { Injectable } from '@/core/di/Injectable';
import { Query } from '@/core/cqrs/Query';
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { StateManager } from '@/core/statemanager/StateManager';
import { LayoutConfigRepository } from '@/wms/domain/layout/configResizing/LayoutConfigRepository';
import { ResizingZone } from '@/wms/domain/layout/configResizing/ResizingZone';

@Injectable()
export class FindLayoutConfigByWarehouseAndStore extends Query<Promise<ResizingZone[]>, any> {
  public constructor(
    @Inject(TYPES.LAYOUT_CONFIG_REPOSITORY)
    private readonly repository: LayoutConfigRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(params: any): Promise<ResizingZone[]> {
    const dataLayoutConfig = await this.repository.findAllConfigByWarehouseStore(
      params.warehouseCode,
      params.storeId,
      params.state,
      params.windowHeight,
      params.windowWidth
    );
    this.stateManager.patch({ dataLayoutConfig });
    return this.stateManager.state.dataLayoutConfig;
  }
}
