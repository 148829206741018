import { Territories } from '../views/territories/Territories';

export class AddressLine {
  addressId: number;
  description: string;
  aditionalAddress: string;
  country: string;
  countryName: string;
  state: string;
  stateName: string;
  city: string;
  zipCode: any;
  cityName: string;
  zipCodeDescription: string;
  entity: any;
  typeAddress: any;
  selectTerritorie: Territories | null;
  territory: Territories | null;
  co: string;
  public constructor() {
    this.addressId = 0;
    this.countryName = '';
    this.stateName = '';
    this.cityName = '';
    this.zipCodeDescription = '';
    this.description = '';
    this.country = '';
    this.state = '';
    this.city = '';
    this.zipCode = '';
    this.co = '';
    this.selectTerritorie = null;
    this.aditionalAddress = '';
    this.typeAddress = null;
    this.territory = null;
  }
}
