import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { ClassReferencesRepository } from '@/wms/domain/catalogs/classReferences/ClassReferencesRepository';
import { ClassReferences } from '@/wms/domain/catalogs/classReferences/ClassReferences';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class ClassReferencesService implements ClassReferencesRepository {
  private readonly API_BASE_URI: string = localStorageModule().wms.host.API_BASE_URI;
  private readonly API_CLASSREFERENCES_FINDALL: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_CLASSREFERENCES_FINDALL;
  private readonly API_CLASSREFERENCES_SAVE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_CLASSREFERENCES_SAVE;
  private readonly API_CLASSREFERENCES_UPDATE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_CLASSREFERENCES_UPDATE;
  private readonly API_CLASSREFERENCES_DELETE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_CLASSREFERENCES_DELETE;

  async create(classReferences: ClassReferences) {
    try {
      await http.post<ClassReferences[]>(this.API_CLASSREFERENCES_SAVE, JSON.stringify(classReferences));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(classReferences: ClassReferences) {
    try {
      await http.put<ClassReferences[]>(this.API_CLASSREFERENCES_UPDATE, JSON.stringify(classReferences));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async delete(classReferences: ClassReferences) {
    try {
      await http.singleDelete<ClassReferences[]>(this.API_CLASSREFERENCES_DELETE + `?code=${classReferences.code}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAll(): Promise<ClassReferences[]> {
    try {
      return await http.get<ClassReferences[]>(this.API_CLASSREFERENCES_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
