import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { Color } from '@/wms/domain/gamma/Color';
import { ColorRepository } from '@/wms/domain/gamma/ColorRepository';

@Injectable()
export class FindAllColor extends Command<Promise<Color[]>, Color[]> {
  public constructor(
    @Inject(TYPES.COLOR_REPOSITORY)
    private readonly colorRepository: ColorRepository
  ) {
    super();
  }

  async internalExecute(): Promise<Color[]> {
    return await this.colorRepository.findAllColor();
  }
}
