import { Store } from '@/wms/domain/layout/store/Store';
import { Warehouse } from '../warehouse/Warehouse';
import { References } from '@/wms/domain/catalogs/references/References';

export class Branch {
  code = '';
  name = '';
  prefix = '';
  warehouse: Warehouse | null = null;
  store: Store | null = null;
  consecutive = 0;
  active = true;
  poBox = false;
  createdAt = '';
  updatedAt = '';
  reference: References | null = null;
}
