import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { FileRepository } from '@/settings/domain/file/FileRepository';

@Injectable()
export class FindFile extends Query<string, string> {
  public constructor(
    @Inject(TYPES.FILE_REPOSITORY)
    private readonly fileRepository: FileRepository
  ) {
    super();
  }
  internalExecute(name: string): string {
    return this.fileRepository.getFile(name);
  }
}
