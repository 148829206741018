import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { Gamma } from '@/wms/domain/gamma/Gamma';
import { GammaRepository } from '@/wms/domain/gamma/GammaRepository';

@Injectable()
export class FindAllGamma extends Command<Promise<Gamma[]>, Gamma[]> {
  public constructor(
    @Inject(TYPES.GAMMA_REPOSITORY)
    private readonly gammaRepository: GammaRepository
  ) {
    super();
  }

  async internalExecute(): Promise<Gamma[]> {
    return await this.gammaRepository.findAllGamma();
  }
}
