import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Injectable } from '@/core/di/Injectable';
import { MenuRepository } from '@/settings/domain/menu/MenuRepository';
import { Menu } from '@/settings/domain/menu/menu';
import { Inject } from 'inversify-props';

@Injectable()
export class FindAllMenu extends Query<Promise<Menu[]>> {
  public constructor(
    @Inject(TYPES.MENU_REPOSITORY)
    private readonly repository: MenuRepository
  ) {
    super();
  }

  async internalExecute(): Promise<Menu[]> {
    const data = await this.repository.findAll();
    return data;
  }
}
