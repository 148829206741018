import { Locations } from '@/wms/domain/layout/locations/Locations';

export class RackLevel {
  level: number;
  levelDesc: string;
  rackId: number;
  locations: Locations[];
  show: boolean;
  //keys for config layout
  layoutRackId: number;
  layoutId: number;
  storeId: number;
  zoneId: string;

  public constructor() {
    this.level = 0;
    this.rackId = 0;
    this.locations = [];
    this.show = false;
    this.levelDesc = '';
    this.layoutRackId = 0;
    this.layoutId = 0;
    this.storeId = 0;
    this.zoneId = '';
  }
}
