
import { selectedLocale } from '@/core/plugins/i18n';
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'DetailInvoice',
  components: {}
})
export default class DetailInvoice extends Vue {
  //DATA
  selectedLocales = selectedLocale;
  editedIndex = -1;
  currentPageLine = 1;
  perPage = 3;
  filter = null;
  filterOn = [];
  sortBy = 'id';
  sortDesc = false;
  //Props
  @Prop() readonly dataInvoice!: any;
  toCurrency(value: string) {
    if (typeof value !== 'number') {
      return value;
    }
    const language = selectedLocale;
    const formatter = new Intl.NumberFormat(`${language}`);
    return formatter.format(value);
  }

  get rowsLines() {
    return this.dataInvoice.invoiceLines.length;
  }
  get fieldsReport() {
    return [
      { key: 'weight', label: this.translateCol('weight') },
      { key: 'height', label: this.translateCol('height') },
      { key: 'width', label: this.translateCol('width') },
      { key: 'length', label: this.translateCol('length') },
      { key: 'quantity', label: this.translateCol('quantity') },
      { key: 'purchaseurl', label: this.translateCol('link') },
      { key: 'unitprice', label: this.translateCol('value') }
    ];
  }

  translateCol(colName: any) {
    return this.$i18n.t('general.' + colName);
  }
}
