/**
 * @class FindByStatusAccountGroup
 * @description Clase que contiene la lógica de negocio para obtener un grupo de cuentas por status
 * @version 1.0.0
 * @Author EnriquePallares
 */

import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { AccountGroup } from '@/settings/domain/accountGroup/AccountGroup';
import { AccountGroupRepository } from '@/settings/domain/accountGroup/AccountGroupRepository';

@Injectable()
export class FindByStatusAccountGroup extends Query<Promise<AccountGroup[]>, boolean> {
  public constructor(
    @Inject(TYPES.ACCOUNT_GROUP_REPOSITORY)
    private readonly repository: AccountGroupRepository
  ) {
    super();
  }

  async internalExecute(status: boolean): Promise<AccountGroup[]> {
    return await this.repository.findByStatus(status);
  }
}
