
import { TYPES } from '@/core/config/Types';
import { SavePricingZone } from '@/settings/application/uses_cases/PricingZone/create/SavePricingZone';
import { PricingZone } from '@/settings/domain/PricingZone/PricingZone';
import { Inject } from '@/core/di/Inject';
import Vue from 'vue';
import Component from 'vue-class-component';
import AddNewPricingZone from './AddNewPricingZone.vue';
import { UpdatePricingZone } from '@/settings/application/uses_cases/PricingZone/update/UpdatePricingZone';
import { PricingZoneFindAll } from '@/settings/application/uses_cases/PricingZone/search/PricingZoneFindAll';
import CustomTable from '@/core/components/shared/CustomTable.vue';
import PricingZoneDetails from './PricingZoneDetails.vue';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';
import { DeletePricingZone } from '@/settings/application/uses_cases/PricingZone/delete/DeletePricingZone';
import { ZoneLevel } from '@/settings/domain/ZoneLevel/ZoneLevel';
import { DeleteZoneLevel } from '@/settings/application/uses_cases/ZoneLevel/delete/DeleteZoneLevel';
@Component({
  name: 'PrincingZonesCenter',
  components: { AddNewPricingZone, CustomTable, PricingZoneDetails }
})
export default class PrincingZonesCenter extends Vue {
  @Inject(TYPES.PRICING_ZONE_SAVE)
  readonly savePricingZone!: SavePricingZone;
  @Inject(TYPES.PRICING_ZONE_DELETE)
  readonly deletePricingZone!: DeletePricingZone;
  @Inject(TYPES.PRICING_ZONE_UPDATE)
  readonly updatePricingZone!: UpdatePricingZone;
  @Inject(TYPES.PRICING_ZONE_FIND_ALL)
  readonly findAllPricingZones!: PricingZoneFindAll;
  @Inject(TYPES.FINDBYID_COMPANY)
  readonly findCompanyById!: CompanyFindById;
  @Inject(TYPES.PRICING_ZONE_LEVEL_DELETE)
  readonly deleteZoneLevel!: DeleteZoneLevel;
  //DATOS
  isLoading = false;

  //LISTAS
  pricingZones: PricingZone[] = [];

  //PROPIEDADES DE BOTON PRINCIPAL
  buttonOptions: { variant: string; buttonTitle: string; icon: string; isEdit: boolean } = {
    variant: 'success',
    buttonTitle: `${this.$t('general.add')}`,
    icon: 'fa-plus',
    isEdit: false
  };
  //DATA
  formCreate: PricingZone = new PricingZone();
  zonePricingLevel = '';

  //OBJETOS DE ACCIONES
  formFunctions = {
    clearForm: this.clearForm,
    factory: this.factory
  };

  tableFunctions = {
    edit: this.load,
    delete: this.delete
  };

  //Ciclo de vida del componente
  mounted() {
    this.findAll();
    this.findCompany();
  }

  //Campos de la tabla
  get fields() {
    return [
      {
        key: 'code',
        label: `${this.$t('general.code')}`,
        sortable: true
      },
      {
        key: 'name',
        label: `${this.$t('general.name')}`,
        sortable: true
      },
      {
        key: 'type',
        label: `${this.$t('general.type')}`,
        sortable: true,
        formatter: (value: any) => {
          return value == 'origin' ? this.$t('general.origin') : this.$t('general.destination');
        }
      },
      {
        key: 'country.name',
        label: `${this.$t('general.country')}`,
        sortable: true
      },
      this.zonePricingLevel == 'state' || this.zonePricingLevel == 'city'
        ? {
            key: 'state.name',
            label: `${this.$t('general.state')}`,
            sortable: true
          }
        : null,
      this.zonePricingLevel == 'city'
        ? {
            key: 'city.name',
            label: `${this.$t('general.city')}`,
            sortable: true
          }
        : null,
      {
        key: 'actions',
        label: `${this.$t('general.actions')}`
      }
    ];
  }

  //Este metodo es llamado para reiniciar el formulario
  clearForm() {
    this.formCreate = new PricingZone();
    this.buttonOptions = {
      variant: 'success',
      buttonTitle: 'general.add',
      icon: 'fa-plus',
      isEdit: false
    };
  }
  //Funcion invocada para realizar guardado o actualización de una zona
  factory() {
    if (this.formCreate.zoneLevel.length < 1) {
      this.$swal.fire({
        title: this.$t('general.warning'),
        text: `${this.$t('general.associateAtLeast1')} ${this.$t(
          `general.${this.formCreate.type != 'origin' ? 'origin' : 'destination'}`
        )}`,
        icon: 'warning'
      });

      return;
    }
    !this.buttonOptions.isEdit ? this.saveZone() : this.updateZone();
  }
  //Funcion invocada para guardar la zona en la base de datos
  async saveZone() {
    try {
      this.isLoading = true;
      this.formCreate.zoneLevel = this.formCreate.zoneLevel.map((element, index) => {
        element.level = index;
        return element;
      });
      await this.savePricingZone.execute(this.formCreate);
      this.findAll();
      this.clearForm();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
  //Funcion invocada para actualizar la zona en la base de datos
  async updateZone() {
    try {
      this.isLoading = true;
      this.formCreate.zoneLevel = this.formCreate.zoneLevel.map((element, index) => {
        element.level = index;
        return element;
      });
      await this.updatePricingZone.execute(this.formCreate);
      this.findAll();
      this.clearForm();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para obtener todas las zonas
  async findAll() {
    try {
      this.isLoading = true;
      const res = await this.findAllPricingZones.execute();
      this.pricingZones = res.length > 0 ? res.reverse() : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para cargar el objeto desde la tabla
  load(pricingZone: PricingZone) {
    this.formCreate = Object.assign({}, pricingZone);
    this.buttonOptions = {
      variant: 'secondary',
      buttonTitle: `${this.$t('general.edit')}`,
      icon: 'fa-edit',
      isEdit: true
    };
  }
  //Funcion invocada para buscar el objeto de compañia
  async findCompany() {
    const companyId = localStorage.getItem('businessId') as string;
    const res: any = await this.findCompanyById.execute(companyId);
    this.zonePricingLevel = res.zonePricingLevel;
  }

  //Funcion invocada para eliminar una zona de la base de datos
  async delete(zone: PricingZone) {
    try {
      this.isLoading = true;
      await this.deletePricingZone.execute(zone);
      this.findAll();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //FUNCION INVOCADA PARA ELIMINAR UNA ASOCIACION EN LA BASE DE DATOS
  async deleteAssociationOnDb(index: number, association: ZoneLevel) {
    try {
      if (association.code === '') {
        this.formCreate.zoneLevel.splice(index, 1);
        return;
      }
      this.isLoading = true;
      const res = await this.deleteZoneLevel.execute(association);
      if (!('error' in res)) {
        this.formCreate.zoneLevel.splice(index, 1);
        await this.findAll();
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`Not implemented yet, ${error}`);
    }
  }
}
