
import { TYPES } from '@/core/config/Types';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import { FindAllAssociatedSerialsByReference } from '@/wms/application/catalogs/references/search/SerialFindAllByReference';
import { References } from '@/wms/domain/catalogs/references/References';
import Serial from '@/wms/domain/catalogs/references/Serial';
import { Inject } from 'inversify-props';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CreateSerial } from '@/wms/application/catalogs/references/create/CreateSerial';
import { DeleteSerials } from '@/wms/application/catalogs/references/delete/SerialDelete';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import { Bulk } from '@/wms/domain/bulk/Bulk';

@Component({
  name: 'SerialModal',
  components: {
    CustomTableN
  }
})
export default class SerialModal extends Vue {
  @Inject(TYPES.REFERENCES_FIND_SERIALS_BY_REFERENCE)
  private readonly findAllSerials!: FindAllAssociatedSerialsByReference;
  @Inject(TYPES.REFERENCES_SAVE_SERIALS)
  private readonly createSerial!: CreateSerial;
  @Inject(TYPES.REFERENCES_DELETE_SERIAL)
  private readonly deleteSerials!: DeleteSerials;

  @Prop({ required: true })
  reference!: References;
  @Prop({ required: false })
  warehouseProcess!: 'outbound' | 'inbound' | '';
  @Prop({ required: false }) form!: PreRegister;

  serials: Serial[] = [];
  selectedSerialForSaving: Serial[] = [];
  serialsByWarehouseProcess: Serial[] = [];
  isLoading = false;
  buttonIsLoading = false;
  csvName = '';
  rowsSelected: Serial[] = [];
  savedSuccessMsg = '';
  $refs!: {
    file: HTMLInputElement;
  };

  mounted() {
    this.findAllSerialsByRef();
  }

  async findAllSerialsByRef() {
    this.isLoading = true;
    try {
      const res = await this.findAllSerials.internalExecute(this.reference?.code);
      if (this.warehouseProcess === 'inbound') {
        this.serials = res;
      }
      if (this.warehouseProcess === 'outbound') {
        this.serials = res.filter((serial: Serial) => {
          return serial.sold === false;
        });
      }
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async importSerialList() {
    try {
      if (!this.$refs.file.files) return;
      const code = this.reference?.code;
      const file = this.$refs.file.files[0];
      return await this.createSerial.internalExecute({ code, file });
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.findAllSerialsByRef();
    }
  }

  async deleteSelectedSerials() {
    this.isLoading = true;
    try {
      const serialList = this.rowsSelected.map((row: Serial) => {
        return row.serial;
      });
      const payload = { code: this.reference?.code, serials: serialList };
      await this.deleteSerials.execute(payload);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.findAllSerialsByRef();
      this.isLoading = false;
    }
  }

  setRowsSelected(newValue: any) {
    this.rowsSelected = newValue.selectedRows;
  }

  setCsvName() {
    if (!this.$refs.file.files) return;
    this.csvName = this.$refs.file.files[0].name;
  }

  get fields() {
    return [
      {
        field: 'serial',
        label: this.$t('general.serialNumber'),
        flex: 0.5
      },
      {
        field: 'store',
        label: this.$t('general.store'),
        flex: 0.5,
        formatFn: (row: any) => `${row.storeId} - ${row.storeName}`
      },
      {
        field: 'sold',
        label: this.$t('Status'),
        flex: 0.5,
        formatFn: (row: any) => (row ? this.$t('general.sold') : this.$t('general.notSold'))
      },
      {
        field: 'soldAt',
        label: this.$t('general.soldAt'),
        flex: 0.5,
        formatFn: (row: any) => row?.split('T').shift() ?? '-'
      },
      {
        field: 'createdAt',
        label: this.$t('general.Created At'),
        flex: 0.5,
        formatFn: (row: any) => row?.split('T').shift() ?? '-'
      }
    ];
  }

  filterSerials() {
    const selectedSerials = this.serials.filter((serial: Serial) => {
      return this.rowsSelected.some(code => serial.serial.includes(code.serial));
    });
    this.selectedSerialForSaving = selectedSerials;
  }
  saveSerialList() {
    this.buttonIsLoading = true;
    try {
      this.form.bulks.map((bulk: Bulk) => {
        bulk.serials = this.rowsSelected;
      });
      this.savedSuccessMsg = 'Successfully selected serial';
      setTimeout(() => {
        this.savedSuccessMsg = '';
      }, 2000);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      setTimeout(() => {
        this.buttonIsLoading = false;
      }, 250);
    }
  }
}
