
import Vue from 'vue';
import Component from 'vue-class-component';
import VueBarcode from 'vue-barcode';
import { Prop } from 'vue-property-decorator';
import { BulkForPutAway } from '@/wms/domain/bulk/BulkForPutAway';
@Component({
  name: 'PutAwayCenter',
  components: { VueBarcode }
})
export default class PutAwayCenter extends Vue {
  @Prop({ required: true }) bulkList!: BulkForPutAway[];

  columnWidthResizeOption = {
    enable: true,
    minWidth: 10
  };
  cellStyleOption = {
    headerCellClass: ({ column }: any) => {
      if (column.key === 'origindata' || column.key === 'adresseedetails') {
        return 'table-header-cell-class';
      }
    }
  };

  get putAwayColumns() {
    return [
      {
        field: 'barCode',
        key: 'barCode',
        title: this.$t('general.barCode'),
        renderBodyCell: ({ row }: any, h: any) => {
          return h(VueBarcode, {
            props: {
              value: row.serial,
              width: '1',
              height: '30',
              displayValue: 'true',
              fontSize: '11'
            }
          });
        }
      },
      {
        field: 'location',
        key: 'location',
        title: this.$t('general.location'),
        renderBodyCell: ({ row }: any, h: any) => {
          return h(VueBarcode, {
            props: {
              value: row.location,
              width: '1',
              height: '30',
              displayValue: 'true',
              fontSize: '11'
            }
          });
        }
      },
      {
        field: 'description',
        key: 'description',
        title: this.$t('general.SKU') + ' - ' + this.$t('general.description'),
        width: 75,
        renderBodyCell: ({ row }: any, h: any) => {
          return h('span', `${row.reference}`);
        }
      },
      {
        field: 'quantity',
        key: 'quantity',
        title: this.$t('general.quantity'),
        width: 25,
        renderBodyCell: ({ row }: any, h: any) => {
          return h('span', `${row.quantity}`);
        }
      },
      {
        field: 'checkbox',
        key: 'checkbox',
        title: this.$t('general.checkbox'),
        width: 25,
        renderBodyCell: ({ row }: any, h: any) => {
          return h('input', {
            attrs: {
              type: 'checkbox',
              checked: row.puttedAway
            },
            on: {
              change: (event: Event) => {
                row.isChecked = (event.target as HTMLInputElement).checked;
              }
            }
          });
        }
      }
    ];
  }
}
