import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Volume } from '@/settings/domain/volume/Volume';
import { VolumeRepository } from '@/settings/domain/volume/VolumeRepository';

@Injectable()
export class VolumeServices implements VolumeRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_VOLUME_FINDALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_VOLUME_FINDALL;
  private readonly API_VOLUME_SAVE: string = this.API_BASE_URI + localStorageModule().config.routes.API_VOLUME_SAVE;
  private readonly API_VOLUME_UPDATE: string = this.API_BASE_URI + localStorageModule().config.routes.API_VOLUME_UPDATE;
  private readonly API_VOLUME_DELETE: string = this.API_BASE_URI + localStorageModule().config.routes.API_VOLUME_DELETE;

  async create(volume: Volume) {
    try {
      return await http.post<Volume[]>(this.API_VOLUME_SAVE, JSON.stringify(volume));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(volume: Volume) {
    try {
      return await http.put<Volume[]>(this.API_VOLUME_UPDATE, JSON.stringify(volume));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findAll(): Promise<Volume[]> {
    try {
      return await http.get<Volume[]>(this.API_VOLUME_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async delete(volume: Volume): Promise<any> {
    try {
      return await http.delete<Volume>(this.API_VOLUME_DELETE, JSON.stringify(volume));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
