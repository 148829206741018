import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { User } from '@/settings/domain/user/User';
import { UserRepository } from '@/settings/domain/user/UserRepository';

@Injectable()
export class UserGetSecurityCode extends Query<Promise<User[]>, string> {
  public constructor(
    @Inject(TYPES.USER_REPOSITORY)
    private readonly userRepository: UserRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(email: string): Promise<User[]> {
    const dataUser = await this.userRepository.getSecurityCode(email);
    this.stateManager.patch({ dataUser });
    return this.stateManager.state.dataUser;
  }
}
