import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { StockRepository } from '@/wms/domain/stock/StockRepository';

@Injectable()
export class CalculateAverageCost extends Query<Promise<any>, any> {
  public constructor(
    @Inject(TYPES.STOCK_REPOSITORY)
    private readonly stockRepository: StockRepository
  ) {
    super();
  }
  async internalExecute(payload: any): Promise<any> {
    return await this.stockRepository.calculateAverageCost(payload);
  }
}
