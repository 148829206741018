import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { ConsolidatedDataForReport } from '@/wms/domain/consolidated/ConsolidatedDataForReport';
import { ConsolidatedRepository } from '@/wms/domain/consolidated/ConsolidatedRepository';

@Injectable()
export class FindConsolidatedDataForReport extends Command<Promise<ConsolidatedDataForReport>, number> {
  public constructor(
    @Inject(TYPES.CONSOLIDATED_REPOSITORY)
    private readonly repository: ConsolidatedRepository
  ) {
    super();
  }

  public internalExecute(id: number): Promise<ConsolidatedDataForReport> {
    return this.repository.findDataReportById(id);
  }
}
