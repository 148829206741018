import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { PricingZoneRepository } from '@/settings/domain/PricingZone/PricingZoneRepository';
import { PricingZone } from '@/settings/domain/PricingZone/PricingZone';

@Injectable()
export class PricingZoneFindAll extends Query<Promise<PricingZone[]>> {
  public constructor(
    @Inject(TYPES.PRICING_ZONE_REPOSITORY)
    private readonly pricingZoneRepository: PricingZoneRepository
  ) {
    super();
  }
  async internalExecute(): Promise<PricingZone[]> {
    return await this.pricingZoneRepository.findAll();
  }
}
