
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'RightSidebar',
  props: ['rightsidebarToggleVar', 'msg']
})
export default class RightSidebar extends Vue {
  search = '';

  //method
  setSerchUsers() {
    if (this.search != '') this.$store.dispatch('chat/setSerchUsersAct', this.search);
  }
}
