import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { GroupAgentServiceRepository } from '@/settings/domain/ParmsGeneral/groupAgentService/GroupAgentServiceRepository';
import { GroupAgentService } from '@/settings/domain/ParmsGeneral/groupAgentService/GroupAgentService';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class GroupAgentSrvService implements GroupAgentServiceRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_GROUPAGENT_FINDALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_GROUPAGENT_FINDALL;
  private readonly API_GROUPAGENT_SAVE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_GROUPAGENT_SAVE;
  private readonly API_GROUPAGENT_UPDATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_GROUPAGENT_UPDATE;
  private readonly API_GROUPAGENT_DELETE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_GROUPAGENT_DELETE;

  async create(groupAgentService: GroupAgentService) {
    try {
      await http.post<GroupAgentService[]>(this.API_GROUPAGENT_SAVE, JSON.stringify(groupAgentService));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(groupAgentService: GroupAgentService) {
    try {
      await http.put<GroupAgentService[]>(this.API_GROUPAGENT_UPDATE, JSON.stringify(groupAgentService));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async delete(groupAgentService: GroupAgentService) {
    try {
      await http.singleDelete<GroupAgentService[]>(this.API_GROUPAGENT_DELETE + `/${groupAgentService.id}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAll(): Promise<GroupAgentService[]> {
    try {
      return await http.get<GroupAgentService[]>(this.API_GROUPAGENT_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
