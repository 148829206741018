import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { OperationRepository } from '@/tracking/domain/typeoperation/OperationRepository';
import { TypeOperation } from '@/settings/domain/typeOperation/TypeOperation';

@Injectable()
export class OperationFindAll extends Query<Promise<TypeOperation[]>> {
  public constructor(
    @Inject(TYPES.TYPE_OPERATION_REPOSITORY)
    private readonly operationRepository: OperationRepository
  ) {
    super();
  }
  async internalExecute(): Promise<TypeOperation[]> {
    const res = await this.operationRepository.findAll();
    return res;
  }
}
