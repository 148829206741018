import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Consignee } from '@/settings/domain/consignee/Consignee';
import { ConsigneeRepository } from '@/settings/domain/consignee/ConsigneeRepository';

@Injectable()
export class FindByNameConsignee extends Query<Promise<Consignee[]>, string> {
  public constructor(
    @Inject(TYPES.CONSIGNEE_REPOSITORY)
    private readonly repository: ConsigneeRepository
  ) {
    super();
  }
  async internalExecute(name: string): Promise<Consignee[]> {
    return await this.repository.findByName(name);
  }
}
