export class States {
  countryCode: string;
  countryName: string;
  code: string;
  name: string;
  active: boolean;
  createAt: string;
  updateAt: string;
  public constructor() {
    this.countryCode = '';
    this.countryName = '';
    this.code = '';
    this.name = '';
    this.active = true;
    this.createAt = '';
    this.updateAt = '';
  }
}
