
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ButtonOptions } from '@/settings/domain/buttonOptions/ButtonOptions';
import { ShippingMethod } from '@/settings/domain/shippingMethod/ShippingMethod';
import { Transport } from '@/tracking/domain/typetransport/Transport';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { TransportFindAll } from '@/tracking/application/uses_cases/typetransport/search/findall/TransportFindAll';
@Component({
  name: 'AddShippingMethod',
  components: {}
})
export default class AddShippingMethod extends Vue {
  @Inject(TYPES.FINDALL_TRANSPORT)
  readonly findTransport!: TransportFindAll;

  //Declaracion de props
  @Prop() readonly buttonOptions!: ButtonOptions;
  @Prop() readonly form!: ShippingMethod;
  @Prop() readonly actions!: any;

  //Data
  isLoading = false;
  typeTransportList: Transport[] = [];

  //Funcion invocada cuando el componente es montado en el DOM
  async mounted() {
    await this.findAllTransport();
  }

  //Funcion ejecutada para validar un input
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }): any {
    return dirty || validated ? valid : null;
  }

  //Funcion invocada para obtener todos los metodos de transporte
  async findAllTransport() {
    try {
      this.isLoading = true;
      const res = await this.findTransport.execute();
      this.typeTransportList = res.length > 0 ? res.reverse() : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
}
