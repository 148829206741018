import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { DashboardChartData } from '@/settings/domain/dashboardChartData/dashboardChartData';
import { DashboardChartDataRepository } from '@/settings/domain/dashboardChartData/dashboardChartDataRepository';

@Injectable()
export class FindAllDashboardChartData extends Query<Promise<DashboardChartData>> {
  public constructor(
    @Inject(TYPES.DASHBOARD_CHART_DATA_REPOSITORY)
    private readonly recentShipmentsRepository: DashboardChartDataRepository
  ) {
    super();
  }

  public async internalExecute(): Promise<DashboardChartData> {
    return await this.recentShipmentsRepository.findAll();
  }
}
