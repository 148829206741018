import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { GroupReferences } from '@/wms/domain/category/groupReferences/GroupReferences';
import { GroupReferencesRepository } from '@/wms/domain/category/groupReferences/GroupReferencesRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class GroupReferencesServices implements GroupReferencesRepository {
  private readonly API_BASE_URI: string = localStorageModule().wms.host.API_BASE_URI;
  private readonly API_GROUPREFERENCES_FINDALL: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_GROUPREFERENCES_FINDALL;
  private readonly API_GROUPREFERENCES_FINDALL_BY_ID: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_GROUPREFERENCES_FINDALL_BY_ID;
  private readonly API_GROUPREFERENCES_CODE_VALIDATE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_GROUPREFERENCES_CODE_VALIDATE;
  private readonly API_GROUPREFERENCES_SAVE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_GROUPREFERENCES_SAVE;
  private readonly API_GROUPREFERENCES_UPDATE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_GROUPREFERENCES_UPDATE;
  private readonly API_GROUPREFERENCES_DELETE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_GROUPREFERENCES_DELETE;
  private readonly API_GROUPREFERENCES_FINDALL_TREE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_GROUPREFERENCES_FINDALL_TREE;

  async create(groupReferences: GroupReferences) {
    try {
      await http.post<GroupReferences[]>(this.API_GROUPREFERENCES_SAVE, JSON.stringify(groupReferences));
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async update(groupReferences: GroupReferences) {
    try {
      return await http._put<GroupReferences[]>(this.API_GROUPREFERENCES_UPDATE, JSON.stringify(groupReferences));
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async delete(groupReferences: GroupReferences) {
    try {
      return await http.delete<GroupReferences[]>(this.API_GROUPREFERENCES_DELETE, JSON.stringify(groupReferences));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async validateGroupReferencesCode(id: string): Promise<boolean> {
    try {
      return await http.get<boolean>(this.API_GROUPREFERENCES_CODE_VALIDATE + id);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findGroupReferencesById(id: number): Promise<GroupReferences[]> {
    try {
      return await http.get<GroupReferences[]>(this.API_GROUPREFERENCES_FINDALL_BY_ID + id);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findAll(): Promise<GroupReferences[]> {
    try {
      return await http.get<GroupReferences[]>(this.API_GROUPREFERENCES_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async findAllTree(): Promise<GroupReferences[]> {
    try {
      return await http.get<GroupReferences[]>(this.API_GROUPREFERENCES_FINDALL_TREE);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
