
import Component from 'vue-class-component';
import Vue from 'vue';
import { Emit, Prop } from 'vue-property-decorator';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { UpdateTimeline } from '@/tracking/application/uses_cases/timeline/update/UpdateTimeline';
import { TransportFindAll } from '@/tracking/application/uses_cases/typetransport/search/findall/TransportFindAll';
import { OperationFindByProcessType } from '@/tracking/application/uses_cases/typeoperation/search/OperationFindByProcessType';
import i18n from '@/core/plugins/i18n';

@Component({
  name: 'TableTimeline'
})
export default class AppCreateTableTimeline extends Vue {
  @Inject(TYPES.UPDATE_TIMELINE)
  readonly updateTimeline!: UpdateTimeline;
  @Inject(TYPES.FINDALL_TRANSPORT)
  readonly transportFindAll!: TransportFindAll;
  @Inject(TYPES.FINDBYPROCESSTYPE_TYPE_OPERATION)
  readonly operationFindByProcessType!: OperationFindByProcessType;
  //Data
  target = i18n.t('general.timeline');
  typeTransportList = [];
  typeOperationList = [];
  private formTableTimeline: any = {
    isTouched: false,
    edit: null,
    dataTypeTransport: [],
    dataTypeOperation: []
  };
  //Prop
  @Prop() readonly timelineListByIndex!: any;
  @Prop() readonly typeProcess!: number;
  //Hook
  mounted() {
    this.$nextTick(() => this.typeTransport);
    this.$nextTick(() => this.typeOperation);
  }
  //Computed
  get typeTransport() {
    this.transportFindAll.execute().then((response: any) => {
      this.typeTransportList = response;
      return response;
    });
    return true;
  }
  get typeOperation() {
    this.operationFindByProcessType.execute(this.typeProcess).then((response: any) => {
      this.typeOperationList = response;
    });
    return true;
  }
  //Method
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  editTimeline(data: any) {
    this.formTableTimeline.edit = this.formTableTimeline.edit !== data.id ? data.id : null;
    if (this.formTableTimeline.edit === null) {
      const payloadUpdate: any = {
        id: data.id,
        description: data.description.trim(),
        typeTransport: this.formTableTimeline.dataTypeTransport.id,
        typeOperation: this.formTableTimeline.dataTypeOperation.code,
        processType: this.typeProcess,
        events: data.events,
        active: data.active
      };
      this.updateTimeline
        .execute(payloadUpdate)
        .then((response: any) => {
          if (response.ok) {
            this.getTimelineSkeleton();
            this.formTableTimeline.edit = null;
          }
          return response;
        })
        .catch((error: any) => {
          throw new Error(error);
        });
    } else {
      this.formTableTimeline.dataTypeTransport = { id: data.typeTransport, name: data.typeTransportName };
      this.formTableTimeline.dataTypeOperation = { code: data.typeOperation, description: data.typeOperationName };
    }
  }
  //Emit
  @Emit()
  getTimelineSkeleton() {
    this.$emit('getTimelineSkeleton');
  }
}
