import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { PalletsFactorRepository } from '@/settings/domain/palletsFactor/PalletsFactorRepository';
import { PalletsFactor } from '@/settings/domain/palletsFactor/PalletsFactor';

@Injectable()
export class PalletsFactorFindAll extends Query<Promise<PalletsFactor[]>> {
  public constructor(
    @Inject(TYPES.PALLETSFACTOR_REPOSITORY)
    private readonly palletsFactorRepository: PalletsFactorRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(): Promise<PalletsFactor[]> {
    const dataPalletsFactor = await this.palletsFactorRepository.findAll();
    this.stateManager.patch({ dataPalletsFactor });
    return this.stateManager.state.dataPalletsFactor;
  }
}
