
import Vue from 'vue';
import Component from 'vue-class-component';
import ColorModal from './modals/ColorModal.vue';
import SizeModal from './modals/SizesModal.vue';
@Component({
  name: 'SizesAndColorsCenter',
  components: { ColorModal, SizeModal }
})
export default class SizesAndColorsCenter extends Vue {
  tabIndex = 0;
}
