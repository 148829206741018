import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import { PreRegisterEventLogDetail } from '@/wms/domain/preRegisterEventLogDetail/PreRegisterEventLogDetail';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { PreRegisterEventLogDetailRepository } from '@/wms/domain/preRegisterEventLogDetail/PreRegisterEventLogDetailRepository';

@Injectable()
export class PreRegisterEventLogDetailSave extends Command<
  Promise<PreRegisterEventLogDetail>,
  {
    activityId: number;
    detail: PreRegisterEventLogDetail;
    eventId: number;
    eventLogId: number;
    preRegisterNumber: number;
    preRegisterType: string;
  }
> {
  public constructor(
    @Inject(TYPES.PRE_REGISTER_EVENT_LOG_DETAIL_REPOSITORY)
    private readonly preRegisterEventLogDetailRepository: PreRegisterEventLogDetailRepository
  ) {
    super();
  }

  public async internalExecute(payload: {
    activityId: number;
    detail: PreRegisterEventLogDetail;
    eventId: number;
    eventLogId: number;
    preRegisterNumber: number;
    preRegisterType: string;
  }): Promise<PreRegisterEventLogDetail> {
    return await this.preRegisterEventLogDetailRepository.save(payload);
  }
}
