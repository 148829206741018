import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Injectable } from '@/core/di/Injectable';
import { DocumentsV2Repository } from '@/courier/domain/DocumentsV2/DocumentsRepository_V2';
import { DocumentHeader } from '@/courier/domain/DocumentsV2/Documents_V2';
import { Inject } from 'inversify-props';

/**
 * @class FindDocumentsByPk
 * @description Caso de uso para buscar un documento por number & typeOrder que toma como dependencia el repositorio de documentos y se inyecta en el contenedor de inversify
 * @version 1.0.0
 * @since 1.0.0
 */

@Injectable()
export class FindDocumentsByPkV2 extends Query<Promise<DocumentHeader>, { number: number; typeOrder: string }> {
  public constructor(
    @Inject(TYPES.DOCUMENTS_V2_REPOSITORY) // Inyecta el repositorio de documentos
    private readonly repository: DocumentsV2Repository // Instancia del repositorio de documentos
  ) {
    super();
  }

  public async internalExecute(payload: { number: number; typeOrder: string }): Promise<DocumentHeader> {
    // Ejecuta el caso de uso
    return await this.repository.findByPk(payload);
  }
}
