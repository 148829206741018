
import { TYPES } from '@/core/config/Types';
import { FindHistoryMovementsStock } from '@/wms/application/stock/search/FindHistoryMovementStock';
import { FindStockByDateRangeAndStore } from '@/wms/application/stock/search/FindStockByDateRangeAndStore';
import { References } from '@/wms/domain/catalogs/references/References';
import { Store } from '@/wms/domain/layout/store/Store';
import { Inject } from 'inversify-props';
import Vue from 'vue';
import Component from 'vue-class-component';
import FilterForm from './FilterForm.vue';
import { StockFilterSearch } from '@/wms/domain/FilterSearch/FilterSearch';
import { Stock } from '@/wms/domain/stock/Stock';
import { StockCalculatedValues } from '@/courier/domain/DocsLin/DocsLin';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';

export type StockMovementForm = {
  year: number;
  iMonth: number;
  fMonth: number;
  reference: References | null;
  store: Store | null;
  isLoading: boolean;
};
@Component({
  name: 'StockMovements',
  components: { FilterForm, CustomTableN }
})
export default class StockMovements extends Vue {
  @Inject(TYPES.STOCK_FIND_BY_DATE_RANGE_AND_STORE) getStock!: FindStockByDateRangeAndStore;
  @Inject(TYPES.STOCK_FIND_HISTORY_MOVEMENTS) getHistoryMovements!: FindHistoryMovementsStock;

  form: StockMovementForm = {
    year: new Date().getFullYear(),
    iMonth: 1,
    fMonth: 12,
    reference: null,
    store: null,
    isLoading: false
  };
  resume: Stock[] = [];
  historyMovements: StockCalculatedValues[] = [];
  isLoading = false;

  get columnsResume() {
    return [
      {
        label: this.$t('general.SKU'),
        field: 'code',
        sortable: true
      },
      {
        label: this.$t('Description'),
        field: 'description'
      },
      {
        label: this.$t('general.year'),
        field: 'ano'
      },
      {
        label: this.$t('general.month'),
        field: 'mes'
      },
      {
        label: this.$t('general.store'),
        field: 'store_name'
      },
      {
        label: this.$t('general.initialStock'),
        field: 'can_ini'
      },
      {
        label: this.$t('general.incomingQuantity'),
        field: 'can_ent'
      },
      {
        label: this.$t('general.outgoingQuantity'),
        field: 'can_sal'
      },
      {
        label: this.$t('general.finalStock'),
        field: 'stock'
      }
    ];
  }

  get columnsMovements() {
    return [
      {
        field: 'number',
        label: this.$t('general.number'),
        sortable: true
      },
      {
        field: 'storeId',
        label: this.$t('general.store')
      },
      {
        field: 'year',
        label: this.$t('general.year')
      },
      {
        field: 'month',
        label: this.$t('general.month')
      },
      {
        field: 'dateFormatted',
        label: this.$t('Date'),
        formatFn: (row: StockCalculatedValues) => `${row.dateDoc}, ${row.timeDoc}`
      },
      {
        field: 'sw',
        label: this.$t('general.nature')
      },
      {
        field: 'type',
        label: this.$t('Type')
      },
      {
        field: 'reference',
        label: this.$t('general.reference'),
        formatFn: (row: References) => `${row?.code} - ${row?.filterBrand}`,
        width: '600px'
      },
      {
        field: 'transactionReferenceLevel1',
        label: this.$t('general.category')
      },
      {
        field: 'transactionReferenceLevel2',
        label: this.$t('general.brand')
      },
      {
        field: 'transactionReferenceLevel3',
        label: this.$t('general.family')
      },
      {
        field: 'transactionReferenceLevel4',
        label: this.$t('general.subFamily')
      },
      {
        field: 'grupo',
        label: this.$t('general.groupR')
      },
      {
        field: 'subGrupo',
        label: this.$t('general.subGroupR')
      },
      {
        field: 'quantity',
        label: this.$t('Quantity')
      },
      {
        field: 'totalTaxes',
        label: this.$t('Taxes')
      },
      {
        field: 'entry',
        label: this.$t('general.entry')
      },
      {
        field: 'invoice',
        label: this.$t('general.outgoing')
      },
      {
        field: 'stockBalance',
        label: this.$t('general.stockBalance')
      },
      {
        field: 'stockValue',
        label: this.$t('general.stockValue'),
        formatFn: (item: any) => {
          return item ? item.toFixed(2) : `-`;
        }
      },
      {
        field: 'unitCostSetted',
        label: this.$t('general.unitCost')
      },
      {
        field: 'unitValueSetted',
        label: this.$t('general.unitPrice'),
        formatFn: (item: any) => {
          return item ? item.toFixed(2) : `-`;
        }
      },
      {
        field: 'totalCost',
        label: this.$t('general.totalCost'),
        formatFn: (item: any) => {
          return item ? item.toFixed(2) : `-`;
        }
      },
      {
        field: 'averageCost',
        label: this.$t('general.averageCost'),
        formatFn: (item: any) => {
          return item ? item.toFixed(2) : `-`;
        }
      },
      {
        field: 'totalPrice',
        label: this.$t('general.totalPrice'),
        formatFn: (item: any) => {
          return item ? item.toFixed(2) : `-`;
        }
      },
      {
        field: 'profit',
        label: `% ${this.$t('general.profit')}`
      }
    ];
  }

  async handdleRequest(filter: StockFilterSearch) {
    const year = (parseInt(filter.iMonth) - 1 === 0 ? parseInt(filter.year) - 1 : filter.year) as number;
    const prevMont = (parseInt(filter.iMonth) - 1 < 10 && parseInt(filter.iMonth) - 1 !== 0
      ? `0${parseInt(filter.iMonth) - 1}`
      : parseInt(filter.iMonth) - 1 === 0
      ? '12'
      : parseInt(filter.iMonth) - 1) as string;

    if (!filter.store) return;

    this.isLoading = true;
    const resPrevStock =
      filter.reference !== null
        ? await this.getStock.execute(prevMont, prevMont, filter.store.storeId, year, filter.reference.code)
        : [];

    const resResume = await this.getStock.execute(
      filter.fMonth,
      filter.iMonth,
      filter.store.storeId,
      parseInt(filter.year),
      filter.reference?.code
    );

    const resHistoryMovements = await this.getHistoryMovements.execute(
      filter.fMonth,
      filter.iMonth,
      filter.store.storeId,
      filter.year,
      resPrevStock[resPrevStock.length - 1]?.stock ?? 0,
      filter.reference?.code
    );

    this.resume = resResume;
    this.historyMovements = resHistoryMovements;
    this.isLoading = false;
  }

  async runSearch(filter: StockMovementForm) {
    await this.handdleRequest({
      fMonth: (filter.fMonth < 10 ? '0' : '') + filter.fMonth.toString(),
      iMonth: (filter.iMonth < 10 ? '0' : '') + filter.iMonth.toString(),
      reference: filter?.reference || null,
      store: filter.store,
      year: filter.year.toString()
    });
  }
}
