import { SettingZoneStandard } from '@/wms/domain/catalogs/pricingEngine/SettingStandardPrice/SettingZoneStandard';
import { SettingSupplierStandard } from '@/wms/domain/catalogs/pricingEngine/SettingStandardPrice/SettingSupplierStandard';
import { SettingCustomerStandard } from '@/wms/domain/catalogs/pricingEngine/SettingStandardPrice/SettingCustomerStandard';

export class SettingStandard {
  public settingCustomerStandardList: SettingCustomerStandard[];
  public settingSupplierStandardList: SettingSupplierStandard[];
  public settingZoneStandardList: SettingZoneStandard[];
  constructor() {
    this.settingCustomerStandardList = [];
    this.settingSupplierStandardList = [];
    this.settingZoneStandardList = [];
  }
}
