
import Vue from 'vue';
import Component from 'vue-class-component';
import { Bulk } from '@/wms/domain/bulk/Bulk';
import { Prop } from 'vue-property-decorator';
import { PackingUnits } from '@/settings/domain/packing_units/PackingUnits';
import ShippingDetails from '../../shippingDetails/ShippingDetails.vue';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import { CommodityClass } from '@/settings/domain/commodityClass/CommodityClass';
import { Containers } from '@/settings/domain/containers/Containers';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { TypeOperation } from '@/settings/domain/typeOperation/TypeOperation';
import { ViewLocation } from '@/settings/domain/views/location/ViewLocation';
import { Territories } from '@/settings/domain/views/territories/Territories';
import { BillOfLanding } from '@/wms/domain/billOfLanding/BillOfLanding';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import { preRegisterSteps } from '@/wms/domain/preRegister/PreRegisterStatusEnum';
import { References } from '@/wms/domain/catalogs/references/References';
import ConsigneeSearch from '@/core/components/shared/ConsigneeSearch.vue';
import CargoOwners from '../cargoOwners/CargoOwners.vue';

interface Lists {
  containerList?: Containers[];
  originList?: Territories[];
  destinationList?: Territories[];
  commodityList?: CommodityType[];
  packagingList?: PackingUnits[];
  referencesList?: any[];
  typeOperationList?: TypeOperation[];
  locationList?: ViewLocation[];
  weightList?: any[];
  commodityClassesList?: CommodityClass[];
  customerList?: Entity[];
  billOfLandingList?: BillOfLanding[];
  singleReferencesList?: References[];
}

@Component({
  name: 'AddPallet',
  components: { ShippingDetails, ConsigneeSearch, CargoOwners }
})
export default class AddPallet extends Vue {
  @Prop() readonly forView!: boolean;
  @Prop() readonly showCargoOwners!: boolean;
  @Prop() box!: Bulk;
  @Prop() lists!: Lists;
  @Prop() addBulk!: Function;
  @Prop() customLabelBl!: Function;
  @Prop() clearForm!: Function;
  @Prop() findEntity!: Function;
  @Prop() form!: PreRegister;
  @Prop() module!: string;
  @Prop({ required: false, default: () => false }) isQuotation!: boolean;

  // Valor para obtener un volúmen y luego dividirlo entre los bultos
  volumeForCube = 0;

  get referencesFilteredByCargoType() {
    return this.lists.singleReferencesList?.filter((reference: References) => {
      return reference.cargoType?.includes('pallet');
    });
  }

  get disabledInputs() {
    return this.forView;
  }

  getStatusValue(key: string) {
    if (key != 'cubed' && key != 'partial_pre_register' && key != 'pre_register' && key != 'shipment') return 1000;
    return preRegisterSteps[key];
  }

  add() {
    const number = this.box.quantity;
    this.box.quantity = 1;
    // Tomamos el valor del input de volumen y si no escero lo dividimos entre los bultos y asignamos la medida al bulto
    // asumiendo que todos los bultos tienen la misma medida en largo, ancho y alto
    if (this.volumeForCube > 0) {
      const volumePerBulk = this.volumeForCube / number;

      const length = Math.round(Math.pow(volumePerBulk, 1 / 3) * 100) / 100;

      this.box.length = length;
      this.box.width = length;
      this.box.height = length;
    }

    this.box.weight = this.box.weight / number;
    this.volumeForCube = 0;

    this.addBulk(this.box, number, 'box');
  }

  // Funcion invocada para setear los valores del embalaje en la linea seleccionada
  setPackingDimensions(packing: PackingUnits, box: any) {
    box.length = packing.length;
    box.width = packing.width;
    box.height = packing.height;
  }

  //Funcion invocada para validar un input
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  calculateShape(input: string) {
    const value = parseFloat(input);

    if (!value) {
      return;
    }

    const quantity = this.box.quantity;
    const volumeToSplit = value / quantity;

    const length = Math.round(Math.pow(volumeToSplit, 1 / 3) * 100) / 100;

    this.box.length = length;
    this.box.width = length;
    this.box.height = length;
  }
}
