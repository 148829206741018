
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import CreateTimelineWms from '@/tracking/infrastructure/ui/components/wms/timeline/CreateTimelineWms.vue';
import TimelineComponentWms from '@/tracking/infrastructure/ui/components/wms/timeline/TimelineComponentWms.vue';
import { TimelineFindByProcessType } from '@/tracking/application/uses_cases/timeline/search/findtimelinebyprocesstype/TimelineFindByProcessType';
import TableTimeline from '@/tracking/infrastructure/ui/components/shared/TableTimeline.vue';
import TableEvent from '@/tracking/infrastructure/ui/components/shared/TableEvent.vue';
import TableActivity from '@/tracking/infrastructure/ui/components/shared/TableActivity.vue';
import Swal from 'sweetalert2';
import i18n from '@/core/plugins/i18n';
import { UpdateTimeline } from '@/tracking/application/uses_cases/timeline/update/UpdateTimeline';
import { EventsFindByProcessType } from '@/tracking/application/uses_cases/events/search/EventsFindByProcessType';

@Component({
  name: 'ManageTimelineWms',
  components: {
    TableActivity,
    TableEvent,
    TableTimeline,
    TimelineComponentWms,
    CreateTimelineWms
  }
})
export default class AppManageTimelineWms extends Vue {
  @Inject(TYPES.FINDBYPROCESSTYPE_TIMELINE)
  readonly timelineFindByProcessType!: TimelineFindByProcessType;
  @Inject(TYPES.UPDATE_TIMELINE)
  readonly updateTimeline!: UpdateTimeline;
  @Inject(TYPES.FINDEVENTSBYPROCESSTYPE_EVENTS)
  readonly eventsFindByProcessType!: EventsFindByProcessType;

  //Data
  timelineList = [];
  processEventList = [];
  private formLineTime: any = {
    isTouched: false,
    visibleTimeline: false,
    timelineSelected: [],
    timelineListFilter: [],
    dataProcessType: [],
    dataTimeline: [],
    validationButtonSave: false,
    timelineListByIndex: [],
    eventsListByIndex: [],
    eventsListLink: [],
    activitiesListLink: [],
    valueItem: '',
    edit: null,
    selectEvent: [],
    selectAll: false
  };
  //Refs
  $refs!: {
    mdCreateModal: HTMLFormElement;
    mdAddEvent: HTMLFormElement;
    dropdownTimeline: HTMLFormElement;
  };
  //Hook
  mounted() {
    this.$nextTick(() => this.getTimelineSkeleton());
    this.$nextTick(() => this.eventsProcessType);
  }
  //Computed
  public get eventsProcessType() {
    this.eventsFindByProcessType.execute(2).then((response: any) => {
      this.processEventList = response;
    });
    return true;
  }
  //method
  public getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  public getTimelineSkeleton() {
    this.timelineFindByProcessType
      .execute(2)
      .then((response: any) => {
        const res = response.reverse();
        this.timelineList = res;
        this.formLineTime.timelineListFilter = res;
        this.formLineTime.visibleTimeline = false;
        this.closeModal();
      })
      .catch(error => {
        this.timelineList = [];
        throw new Error(error);
      });
  }
  public filterTimeline() {
    const result: any = this.timelineList.filter((data: any) => data.id === this.formLineTime.timelineSelected.id);
    this.timelineList = [];
    this.timelineList = result;
    this.$refs.dropdownTimeline.hide(true);
    this.formLineTime.visibleTimeline = true;
    return this.timelineList;
  }
  public closeModal() {
    this.$refs['mdCreateModal'].hide();
  }
  public getTimelineDetail(data: any) {
    this.formLineTime.timelineListByIndex = data;
    this.formLineTime.eventsListByIndex = data.events;
    this.formLineTime.valueItem = 'timeline';
    this.formLineTime.eventsListByIndex.forEach((item: any, index: number) => {
      if (index === 0) {
        this.$set(item, 'hover', true);
      } else {
        this.$set(item, 'hover', false);
      }
    });
    this.formLineTime.eventsListByIndex.sort((a: any, b: any) => a.sequence - b.sequence);
    const arrayEvents: [] = [];
    for (let i = 0; i < this.processEventList.length; i++) {
      let igual = false;
      for (let j = 0; j < data.events.length && !igual; j++) {
        if (this.processEventList[i]['description'] === data.events[j]['description']) {
          igual = true;
        }
      }
      if (!igual) arrayEvents.push(this.processEventList[i]);
    }
    this.processEventList = [];
    this.processEventList = arrayEvents;
  }
  public deleteTimeline(dataTimeline: any) {
    Swal.fire({
      title:
        this.$i18n.t('general.areyousuretodeletethis2') +
        ' ' +
        this.$i18n.t('general.timeline') +
        ' ' +
        this.$i18n.t('general.squestion'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `${i18n.t('general.delete')}`,
      cancelButtonText: `${i18n.t('general.cancel')}`,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    }).then(result => {
      if (result.isConfirmed) {
        const payloadUpdate: any = {
          id: dataTimeline.id,
          description: dataTimeline.description,
          typeTransport: dataTimeline.typeTransport,
          typeOperation: dataTimeline.typeOperation,
          processType: 2,
          active: false,
          events: dataTimeline.events
        };
        this.updateTimeline.execute(payloadUpdate);
        this.getTimelineSkeleton();
        this.formLineTime.valueItem = '';
      } else {
        return false;
      }
    });
  }
  public addEvents(dataTimeline: any) {
    const dataFilterEvents = this.formLineTime.selectEvent.find((event: any) => {
      event.id = null;
      event.sequence = dataTimeline.events.length + 1;
      for (const item of event.activities) {
        item.activityId = null;
      }
      return event;
    });
    const payloadUpdate: any = {
      id: dataTimeline.id,
      description: dataTimeline.description,
      typeTransport: dataTimeline.typeTransport,
      typeOperation: dataTimeline.typeOperation,
      processType: 2,
      active: true,
      events: dataTimeline.events.concat(dataFilterEvents)
    };
    this.updateTimeline.execute(payloadUpdate);
    this.formLineTime.eventsListByIndex = [];
    this.formLineTime.eventsListByIndex = payloadUpdate.events;
    this.getTimelineSkeleton();
    this.$refs['mdAddEvent'].hide();
  }
}
