import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { ReferencesRepository } from '@/wms/domain/catalogs/references/ReferencesRepository';
import { Command } from '@/core/cqrs/Command';

@Injectable()
export class AssignReferencesToTimeline extends Command<Promise<void>, { timeline: number; references: string[] }> {
  public constructor(
    @Inject(TYPES.REFERENCE_REPOSITORY)
    private readonly referencesRepository: ReferencesRepository
  ) {
    super();
  }
  async internalExecute({ references, timeline }: { timeline: number; references: string[] }): Promise<void> {
    return this.referencesRepository.assignToTimeline({ timeline, references });
  }
}
