import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { httpFetchNoToken } from '@/core/http/HttpFetchWithoutToken';
import { User } from '@/settings/domain/user/User';
import { UserRepository } from '@/settings/domain/user/UserRepository';
import { http } from '@/core/http/HttpFetch';
import { UserForList } from '@/settings/domain/user/Projections/UserForList';
@Injectable()
export class UserServices implements UserRepository {
  private readonly API_BASE_URI: string = process.env.VUE_APP_URL_TOKEN ?? '';
  private readonly API_USER_SAVE: string = this.API_BASE_URI + localStorageModule()?.auth?.routes?.API_USER_SAVE;
  private readonly API_USER_UPDATE: string = this.API_BASE_URI + localStorageModule()?.auth?.routes?.API_USER_UPDATE;
  private readonly API_CLIENT_UPDATEPASSWORD: string =
    this.API_BASE_URI + localStorageModule()?.auth?.routes?.API_CLIENT_UPDATEPASSWORD;
  private readonly API_USER_FINDBYEMAIL: string =
    this.API_BASE_URI + localStorageModule()?.auth?.routes?.API_USER_FINDBYEMAIL;
  private readonly API_USER_FINDBYID: string =
    this.API_BASE_URI + localStorageModule()?.auth?.routes?.API_USER_FINDBYID;
  private readonly API_CLIENT_GETSECURITYCODE: string =
    this.API_BASE_URI + localStorageModule()?.auth?.routes?.API_CLIENT_GETSECURITYCODE;
  private readonly API_USER_DELETE: string =
    process.env.VUE_APP_URL_TOKEN + localStorageModule()?.auth?.routes?.API_USER_DELETE;
  private readonly API_USER_FIND_FOR_LIST: string =
    this.API_BASE_URI + localStorageModule()?.auth?.routes?.API_USER_FIND_FOR_LIST;
  private readonly API_USER_ASSIGN_ROLE: string =
    this.API_BASE_URI + localStorageModule()?.auth?.routes?.API_USER_ASSIGN_ROLE;
  private readonly API_USER_INVITE_TO_NAMESPACE: string =
    this.API_BASE_URI + localStorageModule()?.auth?.routes?.API_USER_INVITE_TO_NAMESPACE;
  private readonly API_USER_REMOVE_FROM_NAMESPACE: string =
    this.API_BASE_URI + localStorageModule()?.auth?.routes?.API_USER_REMOVE_FROM_NAMESPACE;
  private readonly API_USER_UPDATE_PROFILE: string =
    this.API_BASE_URI + localStorageModule()?.auth?.routes?.API_USER_UPDATE_PROFILE;
  private readonly API_USER_GET_PROFILE_INFO: string =
    this.API_BASE_URI + localStorageModule()?.auth?.routes?.API_USER_GET_PROFILE_INFO;

  // TODO: Hacer esto una variable de entorno
  // private readonly ACCEPT_TOKEN: string = process.env.ACCEPT_TOKEN;
  async create(user: User) {
    try {
      return await httpFetchNoToken.post<User[]>(this.API_USER_SAVE, JSON.stringify(user));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(user: User) {
    try {
      return await httpFetchNoToken.put<User[]>(this.API_USER_UPDATE, JSON.stringify(user));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async delete(username: string, company: string) {
    try {
      return await httpFetchNoToken.singleDelete<User[]>(
        `${this.API_USER_DELETE}?username=` + username + '&company=' + company,
        null
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async updatePassword(payload: any) {
    try {
      return await httpFetchNoToken.put<User[]>(
        this.API_CLIENT_UPDATEPASSWORD,
        `password=${payload.password}&securityCode=${payload.securityCode}`
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findById(id: string): Promise<User[]> {
    try {
      return await httpFetchNoToken.get<User[]>(this.API_USER_FINDBYID + id);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findByEmail(email: string): Promise<User> {
    try {
      return await http.get<User>(this.API_USER_FINDBYEMAIL + email);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async getSecurityCode(email: string): Promise<User[]> {
    try {
      return await httpFetchNoToken.get<User[]>(this.API_CLIENT_GETSECURITYCODE + email);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findUsersByNamespace(namespace: string): Promise<UserForList[]> {
    try {
      return http.get<UserForList[]>(`${this.API_USER_FIND_FOR_LIST}namespace=${namespace}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async editRoles(payload: { email: string; role: number; namespace: string }): Promise<void> {
    try {
      return await http.VOID_PUT<void>(this.API_USER_ASSIGN_ROLE, JSON.stringify(payload));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async inviteToNamespace(payload: { email: string; namespace: string; role: number }): Promise<void> {
    try {
      return await http.VOID_POST<void>(
        `${this.API_USER_INVITE_TO_NAMESPACE}namespace=${payload.namespace}&role=${payload.role}`,
        payload.email
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async acceptInvitation(token: string): Promise<void> {
    try {
      return await http.VOID_POST<void>(`${this.API_BASE_URI}/user/public/confirm-invite?token=${token}`, null);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async removeFromNamespace(payload: { email: string; namespace: string }): Promise<void> {
    try {
      return await http.VOID_DELETE<void>(
        `${this.API_USER_REMOVE_FROM_NAMESPACE}namespace=${payload.namespace}&email=${payload.email}`,
        null
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  resetPassword(email: string): Promise<void> {
    try {
      return http.VOID_PUT<void>(`${this.API_BASE_URI}/user/public/reset-password?email=${email}`, null);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async updateProfile(payload: User): Promise<void> {
    try {
      return await http.VOID_PUT<void>(this.API_USER_UPDATE_PROFILE, JSON.stringify(payload));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findProfileInfo(): Promise<User> {
    try {
      return await http.get<User>(this.API_USER_GET_PROFILE_INFO);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
