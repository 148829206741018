import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { BillOfLandingRepository } from '@/wms/domain/billOfLanding/BillOfLandingRepository';
import { BillOfLanding } from '@/wms/domain/billOfLanding/BillOfLanding';

@Injectable()
export class BillOfLandingServices implements BillOfLandingRepository {
  private readonly API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;
  private readonly API_BL_SAVE: string = this.API_BASE_URI + localStorageModule().transactions.routes.API_BL_SAVE;
  private readonly API_BL_FINDALL: string = this.API_BASE_URI + localStorageModule().transactions.routes.API_BL_FINDALL;
  private readonly API_BL_FINDALL_WITH_OPERATION_DATA: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_BL_FINDALL_WITH_OPERATION_DATA;
  private readonly API_BL_FIND_GROUPED_DATA_FOR_REPORT: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_BL_FIND_GROUPED_DATA_FOR_REPORT;
  private readonly API_BL_EXIST: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_BILL_OF_LANDING_EXISTS;
  private readonly API_BL_FIND_BY_PICKING_FILTERS: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_BL_FIND_BY_PICKING_FILTERS;
  private readonly API_MASTER_BL_FIND_BY_HOUSES: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_MASTER_BL_FIND_BY_HOUSES;
  private readonly API_BILL_OF_LANDING_SAVE_FOR_PRE_REGISTER: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_BILL_OF_LANDING_SAVE_FOR_PRE_REGISTER;
  private readonly API_BILL_OF_LANDING_FIND_BY_PRE_REGISTER: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_BILL_OF_LANDING_FIND_BY_PRE_REGISTER;
  private readonly API_BILL_OF_LANDING_CREATE_REPORT: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_BILL_OF_LANDING_CREATE_REPORT;

  update(): Promise<BillOfLanding> {
    throw new Error('Method not implemented.');
  }

  delete(): Promise<BillOfLanding> {
    throw new Error('Method not implemented.');
  }

  async findAll(): Promise<BillOfLanding[]> {
    try {
      return await http.get<BillOfLanding[]>(this.API_BL_FINDALL);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findAllWithOperationData(): Promise<BillOfLanding[]> {
    try {
      return await http.get<BillOfLanding[]>(this.API_BL_FINDALL_WITH_OPERATION_DATA);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async save(dto: BillOfLanding): Promise<BillOfLanding> {
    try {
      return await http.post<BillOfLanding>(this.API_BL_SAVE, JSON.stringify(dto));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async checkExist(code: string): Promise<boolean> {
    try {
      return await http.get<boolean>(`${this.API_BL_EXIST}code=${code}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  findByPickingFilters({
    monthFrom,
    monthTo,
    commodity,
    shipper
  }: {
    monthFrom: string;
    monthTo: string;
    commodity: number;
    shipper: string;
  }): Promise<BillOfLanding[]> {
    return http.get<BillOfLanding[]>(
      `${this.API_BL_FIND_BY_PICKING_FILTERS}dateFrom=${monthFrom}&dateTo=${monthTo}&commodity=${commodity}&shipper=${shipper}`
    );
  }

  async findByHouses(houses: string[]): Promise<string[]> {
    try {
      return await http.get<string[]>(`${this.API_MASTER_BL_FIND_BY_HOUSES}serials=${houses}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  findDataForReport(code: string): Promise<any> {
    return http.get<any>(`${this.API_BL_FIND_GROUPED_DATA_FOR_REPORT}code=${code}`);
  }

  findByPreRegister({
    preRegisterType,
    preRegisterNumber
  }: {
    preRegisterType: string;
    preRegisterNumber: number;
  }): Promise<BillOfLanding> {
    return http.get<BillOfLanding>(
      `${this.API_BILL_OF_LANDING_FIND_BY_PRE_REGISTER}preRegisterType=${preRegisterType}&preRegisterNumber=${preRegisterNumber}`
    );
  }

  saveForPreRegister({
    billOfLanding,
    preRegisterType,
    preRegisterNumber
  }: {
    billOfLanding: BillOfLanding;
    preRegisterType: string;
    preRegisterNumber: number;
  }): Promise<BillOfLanding> {
    return http.post<BillOfLanding>(
      `${this.API_BILL_OF_LANDING_SAVE_FOR_PRE_REGISTER}preRegisterType=${preRegisterType}&preRegisterNumber=${preRegisterNumber}`,
      JSON.stringify(billOfLanding)
    );
  }

  async createReport(payload: { serial: string; isMaster: boolean; masterBL: string | null }): Promise<string> {
    let URI = `${this.API_BILL_OF_LANDING_CREATE_REPORT}serial=${payload.serial}&isMaster=${payload.isMaster}`;

    if (payload.masterBL) URI += `&masterBL=${payload.masterBL}`;

    const res = await http.getFile<Blob>(URI, 'application/pdf');

    const buffer = await res.arrayBuffer();

    const blob = new Blob([buffer], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);

    return url;
  }
}
