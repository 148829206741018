import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Command } from '@/core/cqrs/Command';
import { TypeIdentification } from '@/settings/domain/entity/typeIdentification/TypeIdentification';
import { TypeIdentificationRepository } from '@/settings/domain/entity/typeIdentification/TypeIdentificationRepository';

@Injectable()
export class UpdateTypeIdentification extends Command<Promise<any>, TypeIdentification> {
  public constructor(
    @Inject(TYPES.TYPE_IDENTIFICATION_REPOSITORY)
    private readonly repository: TypeIdentificationRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  internalExecute(typeIdentification: TypeIdentification): Promise<any> {
    return this.repository.update(typeIdentification);
  }
}
