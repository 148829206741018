import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { Events } from '@/tracking/domain/events/Events';
import { EventsRepository } from '@/tracking/domain/events/EventsRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class EventsServices implements EventsRepository {
  private readonly API_BASE_URI: string = localStorageModule().tracking.host.API_BASE_URI;
  private readonly API_BASE_URI_COURIER: string = '';
  private readonly API_EVENTS_SAVE: string = this.API_BASE_URI + localStorageModule().tracking.routes.API_EVENTS_SAVE;
  private readonly API_EVENTS_UPDATE: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_EVENTS_UPDATE;
  private readonly API_EVENTS_FINDEVENTSBYPROCESSTYPE: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_EVENTS_FINDEVENTSBYPROCESSTYPE;
  private readonly API_EVENTS_FINDALLBYTIMELINEID: string =
    this.API_BASE_URI_COURIER + localStorageModule().courier.routes.API_EVENTS_FINDALLBYTIMELINEID;
  private readonly API_EVENTS_SAVE_EXEC: string =
    this.API_BASE_URI_COURIER + localStorageModule().courier.routes.API_EVENTS_SAVE_EXEC;
  private readonly API_EVENTS_FINDALL: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_EVENTS_FINDALL;
  private readonly API_EVENTS_FINDALL_CONF_ACTIVE: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_EVENTS_FINDALL_CONF_ACTIVE;
  private readonly API_EVENTS_DELETE_ON_ROUTE_TIMELINE: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_TIMELINE_CONFIG_DELETEEVENT;
  private readonly API_EVENTS_DELETE_ON_MAIN_TIMELINE: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_TIMELINE_DELETEEVENT;

  async create(events: Events) {
    try {
      return await http.post<Events[]>(this.API_EVENTS_SAVE, JSON.stringify(events));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async createExec(events: Events) {
    try {
      return await http.post<Events[]>(this.API_EVENTS_SAVE_EXEC, JSON.stringify(events));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(events: Events) {
    try {
      return await http.put<Events[]>(this.API_EVENTS_UPDATE, JSON.stringify(events));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findEventsByProcessType(params: number): Promise<Events[]> {
    try {
      return await http.get<Events[]>(this.API_EVENTS_FINDEVENTSBYPROCESSTYPE + params);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  findAllByTimelineId(id: number): Promise<Events[]> {
    // try {
    //   return await http.get<Events[]>(this.API_EVENTS_FINDALLBYTIMELINEID + id);
    // } catch (err) {
    //   throw new Error(`${err}`);
    // }
    throw new Error(id + 'Not implemented yet');
  }
  async findAllConfActive(status: boolean): Promise<Events[]> {
    try {
      return await http.get<Events[]>(this.API_EVENTS_FINDALL_CONF_ACTIVE + status);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findAll(): Promise<Events[]> {
    try {
      return await http.get<Events[]>(this.API_EVENTS_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async deleteEventOnGeneralTimeline(idsObject: any) {
    try {
      return await http.get<any>(this.API_EVENTS_DELETE_ON_MAIN_TIMELINE + `${idsObject.timeline}/${idsObject.event}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async deleteEventOnRouteTimeline(idsObject: any) {
    try {
      return await http.get<any>(this.API_EVENTS_DELETE_ON_ROUTE_TIMELINE + `${idsObject.timeline}/${idsObject.event}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
