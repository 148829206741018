import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { ActivitiesRepository } from '@/tracking/domain/activities/ActivitiesRepository';

@Injectable()
export class DeleteActivity extends Command<Promise<any>, { idEvent: number; actividyId: number }> {
  public constructor(
    @Inject(TYPES.ACTIVITIES_REPOSITORY)
    private readonly activitiesRepository: ActivitiesRepository
  ) {
    super();
  }

  internalExecute(payload: { idEvent: number; actividyId: number }): Promise<any> {
    return this.activitiesRepository.delete(payload);
  }
}
