import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Routes } from '@/settings/domain/routes/Routes';
import { RoutesRepository } from '@/settings/domain/routes/RoutesRepository';

@Injectable()
export class RoutesFindAll extends Query<Promise<Routes[]>> {
  public constructor(
    @Inject(TYPES.ROUTES_REPOSITORY)
    private readonly routesRepository: RoutesRepository,

    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(): Promise<Routes[]> {
    const dataRoutes = await this.routesRepository.findAll();
    this.stateManager.patch({ dataRoutes });
    return this.stateManager.state.dataRoutes;
  }
}
