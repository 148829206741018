import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { OrderRepository } from '@/courier/domain/order/OrderRepository';
import { WmsQuotationOrder } from '@/courier/domain/wmsQuotationOrder/WmsQuotationOrder';

@Injectable()
export class OrderFindAllByTypeAndState extends Query<
  Promise<WmsQuotationOrder[]>,
  { type: string; state: string; page: number }
> {
  public constructor(
    @Inject(TYPES.ORDER_REPOSITORY)
    private readonly orderRepository: OrderRepository
  ) {
    super();
  }
  async internalExecute(payload: { type: string; state: string; page: number }): Promise<WmsQuotationOrder[]> {
    return await this.orderRepository.findByTypeAndState(payload);
  }
}
