export class CommodityClass {
  code: string;
  description: string;
  createAt: string;
  updateAt: string;
  active: boolean;

  public constructor() {
    this.code = '';
    this.description = '';
    this.createAt = '';
    this.updateAt = '';
    this.active = true;
  }
}
