import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Injectable } from '@/core/di/Injectable';
import { DocumentsV2Repository } from '@/courier/domain/DocumentsV2/DocumentsRepository_V2';
import { DocumentsHeaderForTable } from '@/courier/domain/DocumentsV2/Projections/DocumentsHeaderForTable';
import { Inject } from 'inversify-props';

/**
 * @class FindDocumentsByMaster
 * @description Caso de uso para buscar un documento por master que toma como dependencia el repositorio de documentos y se inyecta en el contenedor de inversify
 * @version 1.0.0
 * @since 1.0.0
 */

@Injectable()
export class FindPaymentsForPreRegister extends Query<
  Promise<DocumentsHeaderForTable[]>,
  {
    dateFrom: string | null;
    dateTo: string | null;
    masterType: string;
    masterNumber: number;
    entity: number | null;
  }
> {
  public constructor(
    @Inject(TYPES.DOCUMENTS_V2_REPOSITORY) // Inyecta el repositorio de documentos
    private readonly repository: DocumentsV2Repository // Instancia del repositorio de documentos
  ) {
    super();
  }

  public async internalExecute(payload: {
    dateFrom: string | null;
    dateTo: string | null;
    masterType: string;
    masterNumber: number;
    entity: number | null;
  }): Promise<DocumentsHeaderForTable[]> {
    // Ejecuta el caso de uso
    return await this.repository.findPaymentsByMasterAndEntityAndDateRange(payload);
  }
}
