import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { StoreRepository } from '@/wms/domain/layout/store/StoreRepository';
import { Store } from '@/wms/domain/layout/store/Store';

@Injectable()
export class StoreServices implements StoreRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_STORE_FINDALL: string = this.API_BASE_URI + localStorageModule().config.routes.API_STORE_FINDALL;
  private readonly API_STORE_FINDBYWAREHOUSE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_STORE_FINDBYWAREHOUSE;
  private readonly API_STORE_SAVE: string = this.API_BASE_URI + localStorageModule().config.routes.API_STORE_SAVE;
  private readonly API_STORE_UPDATE: string = this.API_BASE_URI + localStorageModule().config.routes.API_STORE_UPDATE;
  private readonly API_STORES_GET_BY_USER: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_STORES_GET_BY_USER;
  private readonly API_FIND_ALL_STORES: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_FIND_ALL_STORES;
  private readonly API_ASSIGN_STORES_TO_USER: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ASSIGN_STORES_TO_USER;

  async create(store: Store) {
    try {
      await http.post<Store[]>(this.API_STORE_SAVE, JSON.stringify(store));
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
      throw new Error(`${error}`);
    }
  }
  async update(store: Store) {
    try {
      await http.put<Store[]>(this.API_STORE_UPDATE, JSON.stringify(store));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findAll(): Promise<Store[]> {
    try {
      return await http.get<Store[]>(this.API_STORE_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findAllStorebyWarehouse(code: string): Promise<Store[]> {
    try {
      return await http.get<Store[]>(this.API_STORE_FINDBYWAREHOUSE + code);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findAllStorebyUser(user: string): Promise<Store[]> {
    try {
      return await http.get<Store[]>(this.API_STORES_GET_BY_USER + `?user=${user}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAllForUserAssign(): Promise<Store[]> {
    try {
      return await http.get<Store[]>(this.API_FIND_ALL_STORES);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async assignStore(payload: { user: string; store: Store[] }): Promise<Store[]> {
    return await http._put<Store[]>(this.API_ASSIGN_STORES_TO_USER + payload.user, JSON.stringify(payload.store));
  }
}
