export class TermsPayment {
  id: number;
  name: string;
  description: string | null;

  public constructor() {
    this.id = 0;
    this.name = '';
    this.description = null;
  }
}
