import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Command } from '@/core/cqrs/Command';
import { Weight } from '@/settings/domain/weight/Weight';
import { WeightRepository } from '@/settings/domain/weight/WeightRepository';

@Injectable()
export class CreateWeight extends Command<Promise<any>, Weight> {
  public constructor(
    @Inject(TYPES.WEIGHT_REPOSITORY)
    private readonly weightRepository: WeightRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  internalExecute(weight: Weight): Promise<any> {
    return this.weightRepository.create(weight);
  }
}
