import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { Timeline } from '@/tracking/domain/timeline/Timeline';
import { ChartValues } from '@/tracking/domain/timeline/ChartValues';
import { TimelineRepository } from '@/tracking/domain/timeline/TimelineRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class TimelineServices implements TimelineRepository {
  private readonly API_BASE_URI: string = localStorageModule().tracking.host.API_BASE_URI;
  private readonly API_TIMELINE_SAVE: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_TIMELINE_SAVE;
  private readonly API_TIMELINE_UPDATE: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_TIMELINE_UPDATE;
  private readonly API_TIMELINE_FINDBYPROCESSTYPE: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_TIMELINE_FINDBYPROCESSTYPE;
  private readonly API_TIMELINE_FINDALLBYSTATUS: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_TIMELINE_FINDALLBYSTATUS;
  private readonly API_TIMELINE_FINDALL: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_TIMELINE_FINDALL;
  private readonly API_TIMELINE_FINDCHARTBYTIMELINE: string =
    this.API_BASE_URI + '/timeline-skeleton/chartValueOfIncomeBillAndProfitByTimeline/';

  async create(timeline: Timeline) {
    try {
      return await http.post<Timeline[]>(this.API_TIMELINE_SAVE, JSON.stringify(timeline));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(timeline: Timeline) {
    try {
      return await http.put<Timeline[]>(this.API_TIMELINE_UPDATE, JSON.stringify(timeline), false);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findByProcessType(params: number): Promise<Timeline[]> {
    try {
      return await http.get<Timeline[]>(this.API_TIMELINE_FINDBYPROCESSTYPE + params);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findAllByStatus(): Promise<Timeline[]> {
    try {
      return await http.get<Timeline[]>(this.API_TIMELINE_FINDALLBYSTATUS);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findAll(): Promise<Timeline[]> {
    try {
      return await http.get<Timeline[]>(this.API_TIMELINE_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findChartValuesByTimeLine(params: number): Promise<ChartValues[]> {
    try {
      return await http.get<ChartValues[]>(this.API_TIMELINE_FINDCHARTBYTIMELINE + params);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
