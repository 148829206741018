
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { Store } from '@/wms/domain/layout/store/Store';
import { References } from '@/wms/domain/catalogs/references/References';
import { ReferenceFindAll } from '@/wms/application/catalogs/references/search/ReferenceFindAll';
import { FindAllStore } from '@/wms/application/layout/store/search/FindAllStore';
import { FindAllMovements } from '@/wms/application/stock/search/FindAllMovements';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import { FindByDateRangeAndStore } from '@/wms/application/stock/search/FindByDateRangeAndStore';
import { CalculateAverageCost } from '@/wms/application/stock/search/CalculateAverageCost';

@Component({
  name: 'InventoryDashboard',
  components: { CustomTableN }
})
export default class InventoryDashboard extends Vue {
  @Inject(TYPES.FINDALL_REFERENCE) readonly findAllReference!: ReferenceFindAll;
  @Inject(TYPES.FIND_ALL_STORE) readonly findAllStore!: FindAllStore;
  @Inject(TYPES.FIND_STOCK_MOVES) readonly findStockMoves!: FindAllMovements;
  @Inject(TYPES.FINDBY_DATERANGE_AND_STORE) readonly findByDateRangeAndStore!: FindByDateRangeAndStore;
  @Inject(TYPES.CALCULATE_AVERAGE_COST) readonly calculateAverageCost!: CalculateAverageCost;

  //DATA
  isLoading = false;
  stockItems: any[] = [];
  movimientoHistorico: any[] = [];
  totalStockBalance: any;
  averageCostView = 0;
  stockItemsPrevMonth: any[] = [];
  finalStockPrevMonth = 0;
  finalStockLastMonth = 0;
  //TABS CONTROL
  tabControl = 0;
  //SELECTEDS
  selectedFilterOptions: any = null;
  //OPTIONS
  references: References[] = [];
  stores: Store[] = [];
  //GETTERS
  get fields() {
    return [
      {
        field: 'code',
        label: `${this.$t('general.SKU')}`,
        sortable: true
      },
      {
        field: 'description',
        label: `${this.$t('general.description')}`,
        sortable: true
      },
      {
        field: 'ano',
        label: `${this.$t('general.year')}`,
        sortable: true
      },
      {
        field: 'mes',
        label: `${this.$t('general.month')}`,
        sortable: true
      },
      {
        field: 'store_name',
        label: `${this.$t('general.store')}`,
        sortable: true
      },
      {
        field: 'can_ini',
        label: `${this.$t('general.initialStock')}`,
        sortable: true
      },
      {
        field: 'can_ent',
        label: `${this.$t('general.enteredQuantity')}`,
        sortable: true
      },
      {
        field: 'can_sal',
        label: `${this.$t('general.outQuantity')}`,
        sortable: true
      },
      {
        field: 'stock',
        label: `${this.$t('general.finalStock')}`,
        sortable: true
      }
    ];
  }

  get fieldsMovimientoHistorico() {
    return [
      {
        field: 'storeId',
        label: `${this.$t('general.storeMin')}`,
        sortable: true
      },
      {
        field: 'dateFormated',
        label: `${this.$t('general.date')}`,
        sortable: true
      },
      {
        field: 'switchCode',
        label: `${this.$t('general.nature')}`,
        sortable: true
      },
      { field: 'type', label: this.$t('general.type'), sortable: true },
      {
        field: 'number',
        label: `${this.$t('general.number')}`,
        sortable: true
      },
      {
        field: 'entry',
        label: `${this.$t('general.in')}`,
        sortable: true
      },
      {
        field: 'invoice',
        label: `${this.$t('general.out')}`,
        sortable: true
      },
      {
        field: 'stockBalance',
        label: `${this.$t('general.stockBalance')}`,
        sortable: true
      },
      {
        field: 'stockValue',
        label: `${this.$t('general.stockValue')}`,
        sortable: true,
        formatFn: (value: number) => Number(value).toFixed(2)
      },
      {
        field: 'unitCostCalculated',
        label: `${this.$t('general.unitCost')}`,
        sortable: true
      },
      {
        field: 'unitValueCalculated',
        label: `${this.$t('general.unitPrice')}`,
        sortable: true,
        formatFn: (value: number) => (value !== null ? Number(value).toFixed(2) : 0)
      },
      {
        field: 'totalCostCalculated',
        label: `${this.$t('general.totalCost')}`,
        sortable: true,
        formatFn: (value: number) => (value !== null ? Number(value).toFixed(2) : 0)
      },
      {
        field: 'averageCostValueCalculated',
        label: `${this.$t('general.averageCostValue')}`,
        sortable: true,
        formatFn: (value: number) => (value !== null ? Number(value).toFixed(2) : 0),
        tdClass: (row: any) => Number(this.compareAverageCost(row)) > 1 && 'text-danger'
      },
      {
        field: 'totalPriceCalculated',
        label: `${this.$t('general.totalPrice')}`,
        sortable: true,
        formatFn: (value: number) => (value !== null ? Number(value).toFixed(2) : 0)
      },
      {
        field: 'profitCalculated',
        label: `${this.$t('general.profitPercentage')}`,
        sortable: true
      }
    ];
  }

  get allowSearchMovimientos() {
    return (
      this.filterSearch.year != '' &&
      this.filterSearch.from != '' &&
      this.filterSearch.to != '' &&
      this.filterSearch.reference != null
    );
  }

  get allowSearchResumes() {
    return (
      this.filterSearch.year != '' &&
      this.filterSearch.from != '' &&
      this.filterSearch.to != '' &&
      this.filterSearch.store != null
    );
  }

  //TABLE FILTER
  filterSearch: {
    year: string;
    from: string;
    to: string;
    store: Store | null;
    reference: References | null;
  } = {
    year: '',
    from: '',
    to: '',
    store: null,
    reference: null
  };
  //MOUNT COMPONENT
  async mounted() {
    await this.getReferences();
    await this.getStores();
  }
  //GETTERS
  get filterOptions() {
    return [
      {
        id: 'store',
        label: `${this.$t('general.store')}`
      },
      {
        id: 'ref',
        label: `${this.$t('general.reference')}`
      },
      {
        id: 'date',
        label: `${this.$t('general.date')}`
      }
    ];
  }

  resetFilter() {
    this.filterSearch = {
      year: '',
      from: '',
      to: '',
      store: null,
      reference: null
    };
  }

  setDateDocument() {
    if (this.movimientoHistorico.length > 0) {
      this.movimientoHistorico.map((item: any) => (item.dateFormated = `${item.dateDoc}, ${item.timeDoc}`));
    }
  }

  calculateStockBalance() {
    let objectTemp: any | null = null;

    if (this.movimientoHistorico.length > 0) {
      this.movimientoHistorico.map((item: any) => {
        if (!objectTemp) {
          switch (item.switchCode) {
            case '2':
            case '3':
            case '12':
            case '16':
              item.stockBalance = Number(this.finalStockPrevMonth) + Number(item.entry);
              break;
            case '1':
            case '4':
            case '11':
              item.stockBalance = Number(this.finalStockPrevMonth) - Number(item.invoice);
              break;
            case '13':
            case '14':
              item.stockBalance = Number(this.finalStockPrevMonth);
              break;
          }
        } else {
          item.stockBalance = Number(objectTemp.stockBalance) + Number(item.entry) - Number(item.invoice);
        }

        objectTemp = item;
        return item;
      });
    }
  }

  calculateTotalCost(item: any) {
    switch (item.switchCode) {
      case '2':
      case '3':
      case '12':
      case '16':
        return Number(item.unitCost) * Number(item.entry);
      case '1':
      case '4':
      case '11':
        return Number(item.unitCost) * Number(item.invoice);
      case '13':
        return Number(item.unitCost) * Number(item.stockBalance) - this.calculateUnitValue(item);
      case '14':
        return Number(item.unitCost) * Number(item.stockBalance) + this.calculateUnitValue(item);
      default:
        return item.unitCost;
    }
  }

  calculateStockValue() {
    let objectTemp: any | null = null;

    if (this.movimientoHistorico.length > 0) {
      this.movimientoHistorico.map((item: any) => {
        if (!objectTemp) {
          switch (item.switchCode) {
            case '2':
            case '3':
            case '12':
            case '16':
              item.stockValue = Number(item.unitCost) * Number(item.entry);
              break;
            case '1':
            case '4':
            case '11':
              item.stockValue = Number(item.unitCost) * Number(item.invoice);
              break;
            case '13':
            case '14':
              item.stockValue = this.calculateUnitValue(item);
              break;
          }
        } else {
          switch (item.switchCode) {
            case '2':
            case '3':
            case '12':
            case '16':
              item.stockValue = Number(objectTemp.stockValue) + Number(item.unitCost) * Number(item.entry);
              break;
            case '1':
            case '4':
            case '11':
              item.stockValue = Number(objectTemp.stockValue) - Number(item.unitCost) * Number(item.invoice);
              break;
            case '13':
              item.stockValue = Number(objectTemp.stockValue) - this.calculateUnitValue(item) * item.stockBalance;
              break;
            case '14':
              item.stockValue = Number(objectTemp.stockValue) + this.calculateUnitValue(item) * item.stockBalance;
              break;
          }
        }

        objectTemp = item;
        return item;
      });
    }
  }

  calculateUnitValue(item: any) {
    return Number(item.unitValue) * (1 - Number(item.discountRate) / 100);
  }

  setUnitCost() {
    if (this.movimientoHistorico.length > 0) {
      this.movimientoHistorico.map(
        (item: any) =>
          (item.unitCostCalculated =
            item.switchCode != 13 && item.switchCode != 14
              ? item.unitCost !== null
                ? Number(item.unitCost).toFixed(2)
                : 0
              : '-')
      );
    }
  }

  setUnitValue() {
    if (this.movimientoHistorico.length > 0) {
      this.movimientoHistorico.map(
        (item: any) =>
          (item.unitValueCalculated = Number(Number(item.unitValue) * (1 - Number(item.discountRate) / 100)).toFixed(2))
      );
    }
  }

  setTotalCost() {
    if (this.movimientoHistorico.length > 0) {
      this.movimientoHistorico.map((item: any) => {
        switch (item.switchCode) {
          case '2':
          case '3':
          case '12':
          case '16':
            item.totalCostCalculated = Number(item.unitCost) * Number(item.entry);
            break;
          case '1':
          case '4':
          case '11':
            item.totalCostCalculated = Number(item.unitCost) * Number(item.invoice);
            break;
          case '13':
            item.totalCostCalculated =
              Number(item.unitCost) * Number(item.stockBalance) - this.calculateUnitValue(item);
            break;
          case '14':
            item.totalCostCalculated =
              Number(item.unitCost) * Number(item.stockBalance) + this.calculateUnitValue(item);
            break;
          default:
            item.totalCostCalculated = item.unitCost;
            break;
        }
      });
    }
  }

  setAverageCostValue() {
    if (this.movimientoHistorico.length > 0) {
      this.movimientoHistorico.map(
        (item: any) => (item.averageCostValueCalculated = Number(item.stockValue) / Number(item.stockBalance))
      );
    }
  }

  setTotalPrice() {
    if (this.movimientoHistorico.length > 0) {
      this.movimientoHistorico.map(
        (item: any) => (item.totalPriceCalculated = this.calculateUnitValue(item) * Number(item.quantity))
      );
    }
  }

  calculateProfit() {
    if (this.movimientoHistorico.length > 0) {
      this.movimientoHistorico.map((item: any) => {
        const profit =
          ((this.calculateUnitValue(item) - this.calculateTotalCost(item) / Number(item.quantity)) /
            this.calculateUnitValue(item)) *
          100;

        const profitAbs =
          item.switchCode != 13 && item.switchCode != 14
            ? Math.sign(Number(profit.toFixed(2))) === 0
              ? Math.abs(profit)
              : profit
            : 0;

        item.profitCalculated = this.formatAsPercent(profitAbs);
      });
    }
  }

  compareAverageCost(item: any) {
    return (Number(this.averageCostView) - Number(item.stockValue) / Number(item.stockBalance)).toFixed(2);
  }

  formatAsPercent(num: number) {
    return new Intl.NumberFormat('default', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(num / 100);
  }

  //METHODS
  async getReferenceAverageCost(reference: References | null) {
    try {
      this.isLoading = true;
      const res = await this.calculateAverageCost.execute({
        reference: encodeURIComponent(reference?.code as string)
      });

      this.averageCostView = res.costoPromedio.toFixed(2);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async findAllResume() {
    try {
      this.isLoading = true;
      const payload: any = {
        fMonth: parseInt(this.filterSearch.to) < 10 ? `0${this.filterSearch.to}` : this.filterSearch.to,
        iMonth: parseInt(this.filterSearch.from) < 10 ? `0${this.filterSearch.from}` : this.filterSearch.from,
        store: this.filterSearch.store?.storeId,
        year: this.filterSearch.year,
        reference: this.filterSearch.reference !== null ? encodeURIComponent(this.filterSearch.reference?.code) : ''
      };

      const res = await this.findByDateRangeAndStore.execute(payload);
      if (res.length > 0) {
        this.stockItems = res;

        if (this.filterSearch.reference != null) {
          this.finalStockLastMonth = this.stockItems[this.stockItems.length - 1].stock;
        } else {
          this.finalStockLastMonth = 0;
        }
      } else {
        this.stockItems = [];
        this.finalStockLastMonth = 0;
      }

      // Obtener el stock final del mes anterior
      if (this.filterSearch.reference != null) {
        const prevMonth =
          payload.iMonth - 1 < 10 && payload.iMonth - 1 !== 0
            ? `0${payload.iMonth - 1}`
            : payload.iMonth - 1 === 0
            ? '12'
            : payload.iMonth - 1;

        const year = payload.iMonth - 1 === 0 ? payload.year - 1 : payload.year;

        const payloadPrevMont: any = {
          fMonth: prevMonth,
          iMonth: prevMonth,
          store: payload.store,
          year: year,
          reference: payload.reference
        };

        const resPrevMonth = await this.findByDateRangeAndStore.execute(payloadPrevMont);
        if (resPrevMonth.length > 0) {
          this.stockItemsPrevMonth = resPrevMonth;
          this.finalStockPrevMonth = this.stockItemsPrevMonth[this.stockItemsPrevMonth.length - 1].stock;
        } else {
          this.stockItemsPrevMonth = [];
          this.finalStockPrevMonth = 0;
        }
      }

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async getReferences() {
    try {
      this.isLoading = true;
      const res = await this.findAllReference.execute();
      this.references = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async getStores() {
    try {
      this.isLoading = true;
      const res = await this.findAllStore.execute();
      this.stores = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
  //HANDDLERS
  handdleMonthsChange(input: string) {
    if (parseInt(input) < 1 || parseInt(input) > 12) {
      this.filterSearch.from = '1';
      this.filterSearch.to = '12';
    }
  }

  verifyMonths() {
    if (
      Number(this.filterSearch.from) > Number(this.filterSearch.to) &&
      this.filterSearch.from != '' &&
      this.filterSearch.to != ''
    ) {
      const aux = this.filterSearch.from;
      this.filterSearch.from = this.filterSearch.to;
      this.filterSearch.to = aux;
    }
  }
  //CUSTOM LABELS
  codeDescriptionReference(reference: References) {
    return `${reference.code} - ${reference.filterBrand}`;
  }
  codeNameStore(store: Store) {
    return `${store.storeId} - ${store.storeName}`;
  }

  async searchSKUMoves() {
    try {
      this.isLoading = true;
      const res = await this.findStockMoves.execute({
        fMonth: parseInt(this.filterSearch.to) < 10 ? `0${this.filterSearch.to}` : this.filterSearch.to,
        iMonth: parseInt(this.filterSearch.from) < 10 ? `0${this.filterSearch.from}` : this.filterSearch.from,
        sku: encodeURIComponent(this.filterSearch.reference?.code as string),
        store: this.filterSearch.store != null ? this.filterSearch.store.storeId : 0,
        year: this.filterSearch.year
      });

      await this.findAllResume();
      this.movimientoHistorico = res.length > 0 ? res : [];
      this.tabControl = 2;
      this.setDateDocument();
      this.calculateStockBalance();
      this.calculateStockValue();
      this.setUnitCost();
      this.setUnitValue();
      this.setTotalCost();
      this.setAverageCostValue();
      this.setTotalPrice();
      this.calculateProfit();
      await this.getReferenceAverageCost(this.filterSearch.reference);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
}
