import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Query } from '@/core/cqrs/Query';
import { Taxe } from '@/settings/domain/taxe/Taxe';
import { TYPES } from '@/core/config/Types';
import { TaxeRepository } from '@/settings/domain/taxe/TaxeRepository';
import { StateManager } from '@/core/statemanager/StateManager';

@Injectable()
export class TaxeFindAll extends Query<Promise<Taxe[]>> {
  public constructor(
    @Inject(TYPES.TAXE_REPOSITORY)
    private readonly taxeRepository: TaxeRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(): Promise<Taxe[]> {
    const dataTaxes = await this.taxeRepository.findAll();
    this.stateManager.patch({ dataTaxes });
    return this.stateManager.state.dataTaxes;
  }
}
