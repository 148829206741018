import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { PackagingRepository } from '@/wms/domain/catalogs/packaging/PackagingRepository';
import { Packaging } from '@/wms/domain/catalogs/packaging/Packaging';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class PackagingService implements PackagingRepository {
  private readonly API_BASE_URI: string = localStorageModule().wms.host.API_BASE_URI;
  private readonly API_PACKAGING_FINDALL: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_PACKAGING_FINDALL;
  private readonly API_PACKAGING_FINDBYREFERENCE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_PACKAGING_FINDBYREFERENCE;
  private readonly API_PACKAGING_SAVE: string = this.API_BASE_URI + localStorageModule().wms.routes.API_PACKAGING_SAVE;
  private readonly API_PACKAGING_UPDATE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_PACKAGING_UPDATE;
  private readonly API_PACKAGING_DELETE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_PACKAGING_DELETE;
  private readonly API_PACKAGING_FIND_BY_REFERENCE_AND_TYPE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_PACKAGING_FIND_BY_REFERENCE_AND_TYPE;

  async create(packaging: any) {
    try {
      await http.post<Packaging[]>(this.API_PACKAGING_SAVE, JSON.stringify(packaging));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async update(packaging: any) {
    try {
      await http.put<Packaging[]>(this.API_PACKAGING_UPDATE, JSON.stringify(packaging));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async delete(packaging: Packaging) {
    try {
      await http.delete<Packaging>(this.API_PACKAGING_DELETE, JSON.stringify(packaging));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAll(): Promise<Packaging[]> {
    try {
      return await http.get<Packaging[]>(this.API_PACKAGING_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findByReference(code: string): Promise<Packaging[]> {
    try {
      return await http.get<Packaging[]>(this.API_PACKAGING_FINDBYREFERENCE + code);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async findByReferenceAndType(payload: { reference: string; type: string }): Promise<Packaging[]> {
    try {
      return await http.get<Packaging[]>(
        `${this.API_PACKAGING_FIND_BY_REFERENCE_AND_TYPE}type=${payload.type}&code=${payload.reference}`
      );
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
