import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { References } from '@/wms/domain/catalogs/references/References';
import { ReferencesRepository } from '@/wms/domain/catalogs/references/ReferencesRepository';

@Injectable()
export class ReferenceUpdateBarcode extends Command<
  Promise<References>,
  { currentBarcode: string; newBarCode: string }
> {
  public constructor(
    @Inject(TYPES.REFERENCE_REPOSITORY)
    private readonly referenceRepository: ReferencesRepository
  ) {
    super();
  }

  internalExecute(params: { currentBarcode: string; newBarCode: string }): Promise<References> {
    const { currentBarcode, newBarCode } = params;
    return this.referenceRepository.updateBarcode(currentBarcode, newBarCode);
  }
}
