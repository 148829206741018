import { ReferenceGroupLevelRepository } from '@/wms/domain/referenceGroupLevel/ReferenceGroupLevelRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { ReferenceGroupLevel } from '@/wms/domain/referenceGroupLevel/ReferenceGroupLevel';
import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';

@Injectable()
export class ReferenceGroupLevelServices implements ReferenceGroupLevelRepository {
  private readonly API_BASE_URI: string = localStorageModule().wms.host.API_BASE_URI;
  private readonly API_REFERENCES_GROUP_LEVEL_SAVE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_REFERENCES_GROUP_LEVEL;
  private readonly API_REFERENCES_GROUP_LEVEL_UPDATE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_REFERENCES_GROUP_LEVEL;
  private readonly API_REFERENCES_GROUP_LEVEL_DELETE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_REFERENCES_GROUP_LEVEL;
  private readonly API_REFERENCES_GROUP_LEVEL_FIND_ALL: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_REFERENCES_GROUP_LEVEL;
  private readonly API_REFERENCES_GROUP_LEVEL_FIND_BY_ID: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_REFERENCES_GROUP_LEVEL_FIND_BY_ID;
  private readonly API_REFERENCES_GROUP_LEVEL_FIND_BY_PARENT: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_REFERENCES_GROUP_LEVEL_FIND_BY_PARENT;
  private readonly API_REFERENCES_GROUP_LEVEL_GET_TREE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_REFERENCES_GROUP_LEVEL_GET_TREE;

  async create(referenceGroupLevel: ReferenceGroupLevel): Promise<ReferenceGroupLevel> {
    try {
      return await http.post<ReferenceGroupLevel>(
        this.API_REFERENCES_GROUP_LEVEL_SAVE,
        JSON.stringify(referenceGroupLevel)
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async update(referenceGroupLevel: ReferenceGroupLevel): Promise<ReferenceGroupLevel> {
    try {
      return await http._put<ReferenceGroupLevel>(
        this.API_REFERENCES_GROUP_LEVEL_UPDATE,
        JSON.stringify(referenceGroupLevel)
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async delete(referenceGroupLevel: ReferenceGroupLevel): Promise<ReferenceGroupLevel> {
    try {
      return await http.delete<ReferenceGroupLevel>(
        this.API_REFERENCES_GROUP_LEVEL_DELETE,
        JSON.stringify(referenceGroupLevel)
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAll(): Promise<Array<ReferenceGroupLevel>> {
    try {
      return await http.get<Array<ReferenceGroupLevel>>(this.API_REFERENCES_GROUP_LEVEL_FIND_ALL);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findById(referenceGroupLevelId: string): Promise<ReferenceGroupLevel> {
    try {
      return await http.get<ReferenceGroupLevel>(
        `${this.API_REFERENCES_GROUP_LEVEL_FIND_BY_ID}id=${referenceGroupLevelId}`
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findByParent(parent: string): Promise<Array<ReferenceGroupLevel>> {
    try {
      return await http.get<Array<ReferenceGroupLevel>>(
        `${this.API_REFERENCES_GROUP_LEVEL_FIND_BY_PARENT}parent=${parent}`
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async getTree(): Promise<Array<ReferenceGroupLevel>> {
    try {
      return await http.get<Array<ReferenceGroupLevel>>(this.API_REFERENCES_GROUP_LEVEL_GET_TREE);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
