import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { StateManager } from '@/core/statemanager/StateManager';
import { Containers } from '@/settings/domain/containers/Containers';
import { ContainersRepository } from '@/settings/domain/containers/CotainersRepository';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';

@Injectable()
export class UpdateContainer extends Command<Promise<Containers>, Containers> {
  public constructor(
    @Inject(TYPES.CONTAINER_REPOSITORY)
    private readonly containerRepository: ContainersRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  internalExecute(container: Containers): Promise<Containers> {
    return this.containerRepository.update(container);
  }
}
