import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Purchase } from '@/courier/domain/purchase/Purchase';
import { PurchaseRepository } from '@/courier/domain/purchase/PurchaseRepository';

@Injectable()
export class PurchaseScraping extends Query<Promise<Purchase[]>, any> {
  public constructor(
    @Inject(TYPES.PURCHASE_REPOSITORY)
    private readonly purchaseRepository: PurchaseRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(url: string): Promise<Purchase[]> {
    const dataPurchase = await this.purchaseRepository.getDataFromAmazonUrl(url);
    this.stateManager.patch({ dataPurchase });
    return this.stateManager.state.dataPurchase;
  }
}
