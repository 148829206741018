import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Consecutive } from '@/courier/domain/consecutive/Consecutive';
import { ConsecutiveRepository } from '@/courier/domain/consecutive/ConsecutiveRepository';

@Injectable()
export class ConsecutiveFindByWarehouseAndType extends Query<Promise<Consecutive>, any> {
  public constructor(
    @Inject(TYPES.CONSECUTIVE_REPOSITORY)
    private readonly consecutiveRepository: ConsecutiveRepository,
    @Inject(TYPES.STATE_MANAGER) private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(payload: { type: string; warehouse: string }): Promise<Consecutive> {
    return await this.consecutiveRepository.searchByWarehouseAndType(payload);
  }
}
