import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { Person } from '@/settings/domain/person/Person';
import { PersonRepository } from '@/settings/domain/person/PersonRepository';

@Injectable()
export class PersonServices implements PersonRepository {
  async create(person: Person): Promise<Person> {
    try {
      return await http._postERP<Person>(`${process.env.VUE_APP_ROUTE_API_ERP}/api/persona`, JSON.stringify(person));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async update(person: Person): Promise<Person> {
    try {
      return await http._putERP<Person>(`${process.env.VUE_APP_ROUTE_API_ERP}/api/persona`, JSON.stringify(person));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findByDocument(document: string): Promise<Person[]> {
    try {
      return await http._getERP<Person[]>(`${process.env.VUE_APP_ROUTE_API_ERP}/api/persona/documento/${document}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
