import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { Activities } from '@/tracking/domain/activities/Activities';
import { ActivitiesRepository } from '@/tracking/domain/activities/ActivitiesRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class ActivitiesServices implements ActivitiesRepository {
  private readonly API_BASE_URI: string = localStorageModule().tracking.host.API_BASE_URI;
  private readonly API_ACTIVITIES_SAVE: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_ACTIVITIES_SAVE;
  private readonly API_ACTIVITIES_DELETE =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_ACTIVITIES_DELETE;

  async create(activities: Activities) {
    try {
      return await http.post<Activities[]>(this.API_ACTIVITIES_SAVE, JSON.stringify(activities));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async delete(payload: { idEvent: number; actividyId: number }): Promise<any> {
    try {
      return await http.singleDelete<any>(`${this.API_ACTIVITIES_DELETE}${payload.idEvent}/${payload.actividyId}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
