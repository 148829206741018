
import i18n, { selectedLocale } from '@/core/plugins/i18n';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { WarehouseFindAll } from '@/settings/application/uses_cases/warehouse/search/WarehouseFindAll';
import { CreateWarehouse } from '@/settings/application/uses_cases/warehouse/create/CreateWarehouse';
import { WarehouseUpdate } from '@/settings/application/uses_cases/warehouse/update/WarehouseUpdate';
import { WarehouseFindByCode } from '@/settings/application/uses_cases/warehouse/search/WarehouseFindByCode';
import { WarehouseFindByStatus } from '@/settings/application/uses_cases/warehouse/search/WarehouseFindByStatus';
import FilterCustomize from '@/core/components/shared/FilterCustomize.vue';
import LayoutStore from '@/wms/infrastructure/ui/Layout/LayoutStore.vue';
import { Warehouse } from '@/settings/domain/warehouse/Warehouse';
import { EntityFindFilter } from '@/settings/application/uses_cases/entity/search/EntityFindFilter';
import { WarehouseDelete } from '@/settings/application/uses_cases/warehouse/delete/WarehouseDelete';
import { showAlert } from '@/core/plugins/Notifications';
import SwalConfirm from '@/core/components/notify/SwalConfirm.vue';
import { ConsecutiveFindByWarehouseAndType } from '@/courier/application/uses_cases/consecutive/search/ConsecutiveFindByWarehouseAndType';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';

@Component({
  name: 'RegisterWarehouse',
  components: { FilterCustomize, LayoutStore, SwalConfirm, CustomTableN }
})
export default class RegisterWarehouse extends Vue {
  @Inject(TYPES.FINDALL_WAREHOUSE)
  readonly warehouseFindAll!: WarehouseFindAll;
  @Inject(TYPES.CREATE_WAREHOUSE)
  readonly createWarehouse!: CreateWarehouse;
  @Inject(TYPES.UPDATE_WAREHOUSE)
  readonly warehouseUpdate!: WarehouseUpdate;
  @Inject(TYPES.DELETE_WAREHOUSE)
  readonly warehouseDelete!: WarehouseDelete;
  @Inject(TYPES.FINDBYCODE_WAREHOUSE)
  readonly currencyFactorFindByCurrencyCode!: WarehouseFindByCode;
  @Inject(TYPES.FINDBYSTATUS_WAREHOUSE)
  readonly warehouseFindByStatus!: WarehouseFindByStatus;
  @Inject(TYPES.ENTITY_FIND_BY_DESCRIPTION_LIKE)
  readonly customerFindFilter!: EntityFindFilter;
  @Inject(TYPES.FINDBYWAREHOUSEANDTYPE_CONSECUTIVE)
  readonly consecutiveFindByWarehouseAndType!: ConsecutiveFindByWarehouseAndType;

  //DATA
  paramsFilter = false;
  target = i18n.t('general.warehouse');
  isLoading = false;
  isLoadingasync = false;
  fullPage = true;
  customers = [];
  warehouseKey = 0;
  warehouses: Warehouse[] = [];
  warehouseSelect: Warehouse = new Warehouse();
  isTouched = false;
  isDisable = true;
  isSave = true;
  totalRows = 1;
  currentPage = 1;
  perPage = 5;
  filter = null;
  filterOn = [];
  sortBy = 'age';
  sortDesc = false;
  isBusy = true;
  selectedLocales = selectedLocale;
  edit = false;
  showBodega = false;
  form: Warehouse = new Warehouse();
  buttonTitle = 'general.add';
  //componente confirm actions
  excuteConfirm = false;
  objSelected: Warehouse | null = null;
  prevConsecutive = 0;
  tableActions = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: this.findObject
      },
      {
        title: `${this.$t('general.warehouseLayout')}`,
        icon: 'fa fa-eye',
        variant: 'warning',
        action: this.showLayoutStore
      },
      {
        title: `${this.$t('general.delete')}`,
        icon: 'fa fa-trash',
        variant: 'danger',
        action: this.deleteWarehouse
      }
    ]
  };

  //REFS
  $refs!: {
    validationWarehouse: HTMLFormElement;
  };
  //HOOKS
  created() {
    this.$nextTick(() => this.warehouseFindAllCall());
  }
  get rows() {
    return this.warehouses.length;
  }
  get fields() {
    return [
      { field: 'code', label: this.translateCol('code'), sortable: true },
      { field: 'address', label: this.translateCol('address'), sortable: true },
      { field: 'description', label: this.translateCol('description'), sortable: true },
      {
        field: 'virtualLocker',
        label: this.translateCol('virtuallocker'),
        sortable: true,
        formatFn: (value: boolean) => (value ? this.translateCol('yes') : this.translateCol('no'))
      },
      {
        field: 'status',
        label: this.translateCol('status'),
        sortable: true,
        formatFn: (value: boolean) => (value ? this.translateCol('active') : this.translateCol('disabled'))
      },
      { field: 'actions', label: this.translateCol('actions'), sortable: false }
    ];
  }

  get warehouseSelected() {
    return this.warehouseSelect.code + ' - ' + this.warehouseSelect.description;
  }

  get enabledConsecutive() {
    return Number(this.prevConsecutive - 1) === Number(this.form.consecutive);
  }
  //METHODS
  async getConsecutive(codeAux: string) {
    try {
      this.isLoading = true;
      const payload = {
        type: `ALM_${codeAux}`,
        warehouse: '000'
      };

      const res: any = await this.consecutiveFindByWarehouseAndType.execute(payload);
      this.prevConsecutive = Number(res.nextNumber) + Number(this.form.consecutive);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  warehouseFindAllCall() {
    this.isLoading = true;
    this.warehouseFindAll
      .execute()
      .then((response: any) => {
        this.warehouses = response.reverse();
        this.isBusy = false;
        this.warehouseKey += 1;
      })
      .catch(() => {
        this.warehouseKey += 1;
        this.isBusy = false;
      })
      .finally(() => {
        this.isLoading = false;
      });
    //this.$nextTick(() => this.$refs.warehouseCode.focus());
  }
  warehouseFindAllByStatus(status: boolean) {
    if (status === true || status === false) {
      this.warehouseFindByStatus
        .execute(status)
        .then((response: any) => {
          this.warehouses = response;
          this.paramsFilter = true;
          this.isBusy = false;
        })
        .catch((error: any) => {
          this.paramsFilter = false;
          this.isBusy = false;
          throw new Error(error);
        });
    } else {
      this.paramsFilter = false;
      this.warehouseFindAllCall();
    }
  }
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  translateCol(colName: string) {
    return this.$i18n.t('general.' + colName);
  }
  onFiltered(filteredItems: any) {
    // Trigger pagination to update the number of buttons/pages due to filtering
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  }
  sendData() {
    this.form.code = this.form.codeAux;
    if (this.edit) {
      this.editData();
    } else {
      this.registerWarehouse();
    }
  }
  editData() {
    this.isLoading = true;
    this.warehouseUpdate
      .execute(this.form)
      .then((response: any) => {
        if (response.status !== 200) {
          this.$swal(`${this.$t('general.ERROR_MESSAGE_FAILED_UPDATE')}`, ``, 'error');
        }
        this.warehouseFindAllCall();
        this.clearForm();
        this.isLoading = false;
        return response;
      })
      .catch((error: any) => {
        this.isLoading = false;
        throw new Error(error);
      });
  }
  registerWarehouse() {
    this.isLoading = true;
    this.createWarehouse
      .execute(this.form)
      .then(() => {
        this.isLoading = false;
        this.warehouseFindAllCall();
        this.clearForm();
      })
      .catch((error: any) => {
        this.isLoading = false;
        throw new Error(error);
      });
  }
  clearForm() {
    this.form = new Warehouse();
    this.$nextTick(() => this.$refs.validationWarehouse.reset());
    this.buttonTitle = 'general.add';
    this.edit = false;
    this.prevConsecutive = 0;
  }
  showLayoutStore(obj: Warehouse) {
    this.$router.push({ name: 'layoutStore', query: { warehouse: obj.code } });
  }
  asyncFind(query: string) {
    if (query.length == 3) {
      const params: any = {
        text: query,
        type: ['SUPPLIER', 'CUSTOMER']
      };
      this.isLoadingasync = true;
      this.customerFindFilter
        .execute(params)
        .then((response: any) => {
          this.customers = response;
          this.isLoadingasync = false;
        })
        .catch((err: any) => {
          this.isLoadingasync = false;
          throw new Error(`${err}`);
        });
    }
  }
  async findObject(object: Warehouse) {
    const obj = Object.assign({}, object);
    if (obj && obj['code']) {
      this.form = obj;
      this.form.codeAux = this.form.code;
      this.edit = true;
      this.buttonTitle = 'general.edit';
      await this.getConsecutive(this.form.code);
    } else {
      const aux = this.form.codeAux;
      this.clearForm();
      this.form.codeAux = aux;
    }
  }

  setConfirm(value: boolean, obj: Warehouse | null) {
    this.excuteConfirm = value;
    this.objSelected = obj;
  }

  cancelConfirm() {
    this.excuteConfirm = false;
    this.objSelected = null;
  }

  deleteWarehouse(obj: Warehouse) {
    this.warehouseDelete
      .execute(obj)
      .then((response: any) => {
        if (!response.code) {
          showAlert('error', 'Warehouse ' + obj.code + ' error removed');
        } else {
          this.warehouseFindAllCall();
        }
      })
      .catch((err: any) => {
        throw new Error(`${err}`);
      })
      .finally(() => this.setConfirm(false, null));
  }
}
