import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { BankRepository } from '@/settings/domain/ParmsGeneral/bank/BankRepository';
import { Bank } from '@/settings/domain/ParmsGeneral/bank/Bank';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class BankService implements BankRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_BANK_FINDALL: string = this.API_BASE_URI + localStorageModule().config.routes.API_BANK_FINDALL;
  private readonly API_BANK_SAVE: string = this.API_BASE_URI + localStorageModule().config.routes.API_BANK_SAVE;
  private readonly API_BANK_UPDATE: string = this.API_BASE_URI + localStorageModule().config.routes.API_BANK_UPDATE;

  async create(bank: Bank) {
    try {
      await http.post<Bank[]>(this.API_BANK_SAVE, JSON.stringify(bank));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(bank: Bank) {
    try {
      await http.put<Bank[]>(this.API_BANK_UPDATE, JSON.stringify(bank));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAll(): Promise<Bank[]> {
    try {
      return await http.get<Bank[]>(this.API_BANK_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
