
import StoreSearch from '@/core/components/shared/StoreSearch.vue';
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { RoutesFindAll } from '@/settings/application/uses_cases/routes/search/RoutesFindAll';
import { Routes } from '@/settings/domain/routes/Routes';
import { FindConsolidatedBulks } from '@/wms/application/bulk/search/FindConsolidatedBulks';
import { FindPickedBulksByStoreAndDate } from '@/wms/application/bulk/search/FindPickedBulksByStoreAndDate.1';
import { ConsolidateBulks } from '@/wms/application/bulk/update/ConsolidateBulks';
import { DeconsolidateBulks } from '@/wms/application/bulk/update/DeconsolidateBulks';
import { Bulk } from '@/wms/domain/bulk/Bulk';
import { Store } from '@/wms/domain/layout/store/Store';
import Vue from 'vue';
import Component from 'vue-class-component';

class ConsilidationFilters {
  store: Store | null;
  dateFrom: string;
  dateTo: string;
  serial: string;
  route: Routes | null;

  constructor() {
    this.store = null;
    this.dateFrom = new Date().toISOString().substr(0, 10);
    this.dateTo = new Date().toISOString().substr(0, 10);
    this.serial = '';
    this.route = null;
  }
}

@Component({
  name: 'ConsolidationCenter',
  components: {
    StoreSearch
  }
})
export default class ConsolidationCenter extends Vue {
  @Inject(TYPES.BULK_FIND_PICKED_BY_STORE_AND_DATE)
  readonly findPickedBulksByStoreAndDate!: FindPickedBulksByStoreAndDate;
  @Inject(TYPES.FINDALL_ROUTES)
  readonly findRoutes!: RoutesFindAll;
  @Inject(TYPES.BULK_CONSOLIDATE)
  readonly consolidateBulks!: ConsolidateBulks;
  @Inject(TYPES.BULK_DECONSOLIDATE)
  readonly deconsolidateBulks!: DeconsolidateBulks;
  @Inject(TYPES.BULK_FIND_CONSOLIDATED)
  readonly findConsolidatedBulks!: FindConsolidatedBulks;

  form: ConsilidationFilters = new ConsilidationFilters();
  selectedSerials: string[] = [];

  isLoading = false;

  //Listas
  arraySwitchs: string[] = ['consolidation', 'deconsolidation'];
  arrayRoute: Routes[] = [];
  bulks: Bulk[] = [];

  mode = 'consolidation';

  mounted() {
    this.getRoutes();
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  get fields() {
    return [];
  }

  async getPickedBulks() {
    try {
      if (!this.form.store || !this.form.route) return;
      this.isLoading = true;
      const res = await this.findPickedBulksByStoreAndDate.execute({
        store: this.form.store,
        dateFrom: this.form.dateFrom,
        dateTo: this.form.dateTo,
        destination: this.form.route.cityDestination,
        origin: this.form.route.cityOrigin
      });
      this.bulks = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async getConsolidatedBulks() {
    try {
      if (!this.form.store || !this.form.route) return;
      this.isLoading = true;
      const res = await this.findConsolidatedBulks.execute({
        store: this.form.store,
        dateFrom: this.form.dateFrom,
        dateTo: this.form.dateTo,
        destination: this.form.route.cityDestination,
        origin: this.form.route.cityOrigin
      });
      this.bulks = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  factorySearch() {
    this.mode === 'consolidation' ? this.getPickedBulks() : this.getConsolidatedBulks();
  }

  checkBulkSerialPresentInChecklist(e: Event) {
    const { value } = e.target as HTMLInputElement;

    const includesBulk = this.bulks.filter((bulk: Bulk) => bulk.serial === value.trim()).length > 0;

    if (includesBulk) {
      this.selectedSerials.push(value.trim());
      this.form.serial = '';
    } else {
      this.$toasted.error(`${this.$t('general.bulkNotFound')}`);
    }
  }

  removeBulkFromChecklist(serial: string) {
    this.selectedSerials = this.selectedSerials.filter((s: string) => s !== serial);
  }

  async getRoutes() {
    try {
      this.isLoading = true;
      const res = await this.findRoutes.execute();
      this.arrayRoute = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async consolidate() {
    try {
      if (this.selectedSerials.length === 0) {
        this.$toasted.error(`${this.$t('general.selectAtLeastOneBulk')}`);
        return;
      }
      this.isLoading = true;
      const res = await this.consolidateBulks.execute(this.selectedSerials);
      if (!('error' in res)) {
        this.$toasted.success(`${this.$t('general.consolidateSuccess')}`);
        this.selectedSerials = [];
        this.bulks = [];
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
  async deconsolidate() {
    try {
      if (this.selectedSerials.length === 0) {
        this.$toasted.error(`${this.$t('general.selectAtLeastOneBulk')}`);
        return;
      }
      this.isLoading = true;
      const res = await this.deconsolidateBulks.execute(this.selectedSerials);
      if (!('error' in res)) {
        this.$toasted.success(`${this.$t('general.consolidateSuccess')}`);
        this.selectedSerials = [];
        this.bulks = [];
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  factory() {
    this.mode === 'consolidation' ? this.consolidate() : this.deconsolidate();
  }
}
