import { Injectable } from '@/core/di/Injectable';
import { LoginImageRepository } from '@/settings/domain/loginImage/LoginImageRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { LoginImage } from '@/settings/domain/loginImage/LoginImage';
import { http } from '@/core/http/HttpFetch';

@Injectable()
export class LoginImageService implements LoginImageRepository {
  private readonly API_BASE_URI: string = process.env.VUE_APP_URL_TOKEN ?? '';

  private readonly API_LOGIN_IMAGE_FIND_ALL: string =
    this.API_BASE_URI + localStorageModule().auth.routes.API_PERSONALIZATION_TYPES;

  async findAll(): Promise<LoginImage[]> {
    return await http.get<LoginImage[]>(this.API_LOGIN_IMAGE_FIND_ALL);
  }
}
