import { Injectable } from '@/core/di/Injectable';
import { InvoiceRepository } from '@/courier/domain/invoice/InvoiceRepository';
import { http } from '@/core/http/HttpFetch';
import { Invoice } from '@/courier/domain/invoice/Invoice';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class InvoiceService implements InvoiceRepository {
  private readonly API_BASE_URI: string = localStorageModule().courier.host.API_BASE_URI;
  private readonly API_INVOICE_FINDALL: string =
    this.API_BASE_URI + localStorageModule().courier.routes.API_INVOICE_FINDALL;
  private readonly API_INVOICE_FINDBYCLIENTID: string =
    this.API_BASE_URI + localStorageModule().courier.routes.API_INVOICE_FINDBYCLIENTID;
  private readonly API_INVOICE_FINDBYDATERANGE: string =
    this.API_BASE_URI + localStorageModule().courier.routes.API_INVOICE_FINDBYDATERANGE;
  private readonly API_INVOICE_FINDBYDATERANGEANDCUSTOMER: string =
    this.API_BASE_URI + localStorageModule().courier.routes.API_INVOICE_FINDBYDATERANGEANDCUSTOMER;
  private readonly API_INVOICE_FINDBYPK: string =
    this.API_BASE_URI + localStorageModule().courier.routes.API_INVOICE_FINDBYPK;
  private readonly API_INVOICE_SAVE: string = this.API_BASE_URI + localStorageModule().courier.routes.API_INVOICE_SAVE;
  private readonly API_INVOICE_SAVE_ORDER: string =
    this.API_BASE_URI + localStorageModule().courier.routes.API_INVOICE_SAVE_ORDER;
  private readonly API_INVOICE_UPDATE: string =
    this.API_BASE_URI + localStorageModule().courier.routes.API_INVOICE_UPDATE;

  async create(invoice: Invoice) {
    try {
      return await http.post<Invoice[]>(this.API_INVOICE_SAVE, JSON.stringify(invoice));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async createInvoiceFromOrder(params: any) {
    try {
      return await http.post<Invoice[]>(this.API_INVOICE_SAVE_ORDER + `${params.orderType}/${params.orderNumber}`, '');
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(invoice: Invoice) {
    try {
      return await http.put<Invoice[]>(this.API_INVOICE_UPDATE, JSON.stringify(invoice));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAll(): Promise<Invoice[]> {
    try {
      return await http.get<Invoice[]>(this.API_INVOICE_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async findByClientId(clientId: string): Promise<Invoice[]> {
    try {
      return await http.get<Invoice[]>(this.API_INVOICE_FINDBYCLIENTID + clientId);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async findByDateRange(params: any): Promise<Invoice[]> {
    try {
      return await http.get<Invoice[]>(this.API_INVOICE_FINDBYDATERANGE + `${params.from}/${params.to}`);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async findByDateRangeAndCustomer(status: any): Promise<Invoice[]> {
    try {
      return await http.get<Invoice[]>(this.API_INVOICE_FINDBYDATERANGEANDCUSTOMER + status);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async findByPk(status: any): Promise<Invoice[]> {
    try {
      return await http.get<Invoice[]>(this.API_INVOICE_FINDBYPK + status);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
