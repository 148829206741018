import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Command } from '@/core/cqrs/Command';
import { PriceList } from '@/settings/domain/priceList/PriceList';
import { PriceListRepositoryVolume } from '@/settings/domain/priceList/PriceListRepositoryVolume';

@Injectable()
export class PriceListUpdateWeight extends Command<Promise<any>, PriceList> {
  public constructor(
    @Inject(TYPES.PRICE_LIST_REPOSITORY_WEIGHT)
    private readonly priceListRepository: PriceListRepositoryVolume,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  internalExecute(priceList: PriceList): Promise<any> {
    return this.priceListRepository.update(priceList);
  }
}
