import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { DocumentRepository } from '@/courier/domain/documents/DocumentRepository';
import { Document } from '@/courier/domain/documents/Document';

@Injectable()
export class UpdateDocument extends Command<Promise<any>, Document> {
  public constructor(
    @Inject(TYPES.DOCUMENT_TYPE_REPOSITORY)
    private readonly documentRepository: DocumentRepository
  ) {
    super();
  }
  internalExecute(document: Document): Promise<any> {
    return this.documentRepository.updateDocument(document);
  }
}
