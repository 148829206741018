import { ConceptsContainer } from '../containers/conceptsContainer/ConceptsContainer';

export class PackingUnits {
  id: number;
  description: string;
  width: string;
  height: string;
  weight: string;
  lenght: string;
  length: string;
  stackingQuantity: number;
  active: boolean;
  edit: boolean;
  createAt: string;
  updateAt: string;
  level: number;
  concepts: ConceptsContainer[];
  files?: string[];

  public constructor() {
    this.id = 0;
    this.description = '';
    this.width = '0';
    this.height = '0';
    this.weight = '0';
    this.lenght = '0';
    this.length = '0';
    this.stackingQuantity = 0;
    this.active = true;
    this.edit = false;
    this.createAt = '';
    this.updateAt = '';
    this.level = 5;
    this.concepts = [];
  }
}
