
import { TYPES } from '@/core/config/Types';
import { Routes } from '@/settings/domain/routes/Routes';
import { Inject } from '@/core/di/Inject';
import Vue from 'vue';
import Component from 'vue-class-component';
import AddRoute from './AddRoute.vue';
import { SearchTerritoryByQueryDescription } from '@/settings/application/uses_cases/views/territories/SearchTerritoryByQueryDescription';
import { Territories } from '@/settings/domain/views/territories/Territories';
import { RoutesFindAll } from '@/settings/application/uses_cases/routes/search/RoutesFindAll';
import { CreateRoutes } from '@/settings/application/uses_cases/routes/create/CreateRoutes';
import { RoutesUpdate } from '@/settings/application/uses_cases/routes/update/RoutesUpdate';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
@Component({
  name: 'RoutesCenter',
  components: { AddRoute, CustomTableN }
})
export default class RoutesCenter extends Vue {
  @Inject(TYPES.API_VIEW_FIND_TERRITORIES_BY_QUERY)
  readonly searchTerritoriesByQueryParameter!: SearchTerritoryByQueryDescription;
  @Inject(TYPES.FINDALL_ROUTES)
  readonly routesFindAll!: RoutesFindAll;
  @Inject(TYPES.CREATE_ROUTES)
  readonly createRoutes!: CreateRoutes;
  @Inject(TYPES.UPDATE_ROUTES)
  readonly routesUpdate!: RoutesUpdate;
  //DATOS

  isLoading = false;

  //LISTAS
  originList: Territories[] = [];
  destinationList: Territories[] = [];
  routesList: any[] = [];

  //FORMULARIO
  formAdd: { origin: any | null; destination: any | null; active: boolean; id: number } = {
    origin: null,
    destination: null,
    active: true,
    id: 0
  };
  form: Routes = new Routes();

  //VARIABLES PARA INTERVALO DE BUSQUEDA
  timeoutOrigin: any;
  timeoutDestination: any;
  searchInterval = 600;

  //OBJETOS DE ACCIONES
  actions = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: this.loadRoute
      }
    ]
  };

  formFunctions = {
    clearForm: this.clearForm,
    factory: this.factory
  };

  //PROPIEDADES DE BOTON PRINCIPAL
  buttonOptions = {
    variant: 'success',
    buttonTitle: 'general.add',
    icon: 'fa-plus',
    isEdit: false
  };

  //CAMPOS DE TABLA
  get fields() {
    return [
      {
        label: 'ID',
        field: 'id',
        class: 'col-1'
      },
      {
        label: `${this.$t('general.origin')}`,
        field: 'id',
        formatFn: (value: number, row: Routes) => this.originFormatter(row)
      },
      {
        label: `${this.$t('general.destination')}`,
        field: 'id',
        formatFn: (value: number, row: Routes) => this.destinationFormatter(row)
      },
      {
        label: `${this.$t('general.active')}`,
        field: 'active',
        class: 'col-2',
        formatFn: (row: boolean) => (row ? this.$t('general.yes') : this.$t('general.no'))
      },
      {
        label: `${this.$t('general.actions')}`,
        field: 'actions',
        class: 'col-1'
      }
    ];
  }

  //CICLO DE VIDA DEL COMPONENTE
  mounted() {
    this.findAll();
  }

  //FORMATEADORES DE COLUMNAS
  //Este método es llamado para dar formato a la columna "origen" en la tabla principal
  originFormatter(item: Routes) {
    return `${item.countryOriginName} - ${item.stateOriginName} - ${item.cityOriginName} `;
  }
  //Este método es llamado para dar formato a la columna "destino" en la tabla principal
  destinationFormatter(item: Routes) {
    return `${item.countryDestinationName} - ${item.stateDestinationName} - ${item.cityDestinationName} `;
  }

  //METODOS
  // Este método es llamado cuando el usuario escribe sobre el input de origen o destino para buscar territorios.
  findTerritoriesFactory(query: string, input: string, delayed = true): void {
    //Limpiamos cualquier función anterior en el timeout para evitar búsquedas repetitivas y solapadas.
    delayed ? clearTimeout(this.timeoutOrigin) : null;
    this.timeoutOrigin = setTimeout(
      () => {
        (async () => {
          if (query.length >= 3) {
            try {
              const res = await this.findTerritories(query);
              input == 'origin' ? (this.originList = res) : (this.destinationList = res);
            } catch (error) {
              this.isLoading = false;
              throw new Error(`${error}`);
            }
          }
          clearTimeout(this.timeoutOrigin);
        })();
      },
      delayed ? this.searchInterval : 0
    );
  }

  async findTerritories(query: string): Promise<Territories[]> {
    this.isLoading = true;
    const res = await this.searchTerritoriesByQueryParameter.execute(query.toUpperCase());
    this.isLoading = false;
    return res;
  }

  //Este metodo es llamado cuando se inicia el componente y cada vez que se requiera obtener todas las rutas.
  async findAll() {
    try {
      this.isLoading = true;
      const res = await this.routesFindAll.execute();
      this.routesList = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  }

  //Este metodo es llamado para reiniciar el formulario
  clearForm() {
    this.form = new Routes();
    this.formAdd = { origin: null, destination: null, active: true, id: 0 };
    this.buttonOptions = {
      variant: 'success',
      buttonTitle: 'general.add',
      icon: 'fa-plus',
      isEdit: false
    };
  }

  //Este metodo es llamado para cargar el item desde la tabla hacia el objeto del form
  loadRoute(item: Routes) {
    this.buttonOptions = {
      variant: 'secondary',
      buttonTitle: 'general.edit',
      icon: 'fa-edit',
      isEdit: true
    };
    this.routeFormatter(item);
  }

  //Este metodo es llamado al presionar el botón principal en el componente AddRoute
  factory() {
    !this.buttonOptions.isEdit ? this.save() : this.update();
  }

  //Este metodo es usado para formatear la ruta hacia los selects del front
  async routeFormatter(item: any) {
    try {
      const origins = await this.findTerritories(item.cityOriginName);
      const destinations = await this.findTerritories(item.cityDestinationName);
      this.formAdd.origin = origins.filter((element: any) => {
        return element.city == item.cityOrigin;
      })[0];
      this.formAdd.destination = destinations.filter((element: any) => {
        return element.city == item.cityDestination;
      })[0];
      this.formAdd.active = item.active;
      this.formAdd.id = item.id;
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  //Este metodo es usado para formatear el payload y que sea compatible con lo requerido en el Backend
  payloadFormatter() {
    const payloadRoute: any = {
      active: this.formAdd.active,
      id: this.formAdd.id,
      cityDestination: this.formAdd.destination.city,
      cityDestinationName: this.formAdd.destination.cityName,
      countryDestination: this.formAdd.destination.country,
      countryDestinationName: this.formAdd.destination.countryName,
      stateDestination: this.formAdd.destination.state,
      stateDestinationName: this.formAdd.destination.stateName,
      cityOrigin: this.formAdd.origin.city,
      cityOriginName: this.formAdd.origin.cityName,
      countryOrigin: this.formAdd.origin.country,
      countryOriginName: this.formAdd.origin.countryName,
      stateOrigin: this.formAdd.origin.state,
      stateOriginName: this.formAdd.origin.stateName
    };

    return payloadRoute;
  }

  //Este metodo se usa para guardar la ruta en la base de datos
  async save() {
    const payload = this.payloadFormatter();
    this.isLoading = true;
    await this.createRoutes.execute(payload);
    this.clearForm();
    this.findAll();
  }

  //Este metodo se usa para editar la ruta en la base de datos
  async update() {
    const payload = this.payloadFormatter();
    await this.routesUpdate.execute(payload);
    this.clearForm();
    this.findAll();
  }
}
