import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Query } from '@/core/cqrs/Query';
import { TYPES } from '@/core/config/Types';
import { ViewLocation } from '@/settings/domain/views/location/ViewLocation';
import { ViewLocationRepository } from '@/settings/domain/views/location/ViewLocationRepository';

@Injectable()
export class ViewLocationFindAllStoreAndCommodity extends Query<
  Promise<ViewLocation[]>,
  { storeCode: number; commodityId: number }
> {
  public constructor(
    @Inject(TYPES.VIEW_FIND_LOCATIONS_BY_STORE_AND_COMMODITY_REPOSITORY)
    private readonly repository: ViewLocationRepository
  ) {
    super();
  }

  async internalExecute(payload: { storeCode: number; commodityId: number }): Promise<ViewLocation[]> {
    try {
      return await this.repository.findByWarehouseAndCommodity(payload);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
