
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { FindByNameConsignee } from '@/settings/application/uses_cases/consignee/search/FindByNameConsignee';
import { Consignee } from '@/settings/domain/consignee/Consignee';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'ConsigneeSearch',
  components: {}
})
export default class ConsigneeSearch extends Vue {
  // TODO: Tipar bien esto
  //Timeout para evitar búsquedas repetitivas y solapadas.
  timeout: any | undefined = undefined;

  //Intervalo de tiempo para realizar la búsqueda
  searchInterval = 500;

  //Variable para almacenar la lista de territorios
  consigneeList: Consignee[] = [];

  //Variable para controlar el estado de la carga
  isLoading = false;

  @Inject(TYPES.CONSIGNEE_FIND_BY_NAME)
  readonly findConsigneeByName!: FindByNameConsignee;

  @Prop({ required: true }) value!: Consignee | null;
  @Prop({ required: false }) label!: string | null;
  @Prop({ required: false, default: '' }) containerClass!: string | null;
  @Prop({ required: false, default: '' }) validationRules!: string | null;
  @Prop({
    required: false,
    default: (): null => {
      return null;
    }
  })
  onInput!: (entity: Consignee) => Promise<void> | void;
  @Prop({ required: false, default: false }) readonly disabled!: boolean;
  get localValue(): Consignee | null {
    return this.value;
  }

  set localValue(value: Consignee | null) {
    this.$emit('update:value', value);
  }

  // Este método es llamado cuando el usuario escribe sobre el input para buscar territorios.
  findEntitiesFactory(query: string): void {
    //Limpiamos cualquier función anterior en el timeout para evitar búsquedas repetitivas y solapadas.
    clearTimeout(this.timeout);
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    this.timeout = setTimeout(async () => {
      if (query.length >= 3) {
        try {
          const res = await this.getEntities(query);
          this.consigneeList = res;
        } catch (error) {
          this.isLoading = false;
          this.consigneeList = [];
          throw new Error(`${error}`);
        }
      }
      clearTimeout(this.timeout);
    }, this.searchInterval);
  }

  //Funcion invocada para buscar los territorios
  async getEntities(query: string) {
    this.isLoading = true;
    const res = await this.findConsigneeByName.execute(query);
    this.isLoading = false;
    return res;
  }

  //Función invocada cuando se selecciona un territorio y se desea enviar al padre para ejecutar alguna acción.
  async dispatchInput(value: Consignee) {
    if (this.onInput) await this.onInput(value);
  }
}
