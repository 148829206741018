import { Injectable } from '@/core/di/Injectable';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { EntityRepository } from '@/settings/domain/entity/entity/EntityRepository';

@Injectable()
export class EntityFindFilter extends Query<Promise<Entity[]>, any> {
  public constructor(
    @Inject(TYPES.API_ENTITY_REPOSITORY)
    private readonly customerRepository: EntityRepository,

    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(params: any): Promise<Entity[]> {
    const dataCustomerEntities = await this.customerRepository.findByFullNameLike(params);
    this.stateManager.patch({ dataCustomerEntities });
    return this.stateManager.state.dataCustomerEntities;
  }
}
