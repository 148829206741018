import { ActivityTransactional } from '@/tracking/domain/activitiesTransactional/ActivityTransactional';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { ActivityTransactionalRepository } from '@/tracking/domain/activitiesTransactional/ActivityTransactionalRepository';

@Injectable()
export class ActivityTransactionalUpdateStatus extends Command<Promise<ActivityTransactional>, ActivityTransactional> {
  public constructor(
    @Inject(TYPES.ACTIVITIES_TRANSACTIONAL_RESPOSITORY)
    private readonly activityTransactionalRepository: ActivityTransactionalRepository
  ) {
    super();
  }

  async internalExecute(activity: ActivityTransactional): Promise<ActivityTransactional> {
    return await this.activityTransactionalRepository.updateStatus(activity);
  }
}
