import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { TopInformationCards } from '@/settings/domain/topInformationCards/TopInformationCards';
import { TopInformationCardsRepository } from '@/settings/domain/topInformationCards/TopInformationCardsRepository';

@Injectable()
export class FindAllTopInformationCards extends Query<Promise<TopInformationCards[]>> {
  public constructor(
    @Inject(TYPES.TOP_INFORMATION_CARD_REPOSITORY)
    private readonly topInformationCardsRepository: TopInformationCardsRepository
  ) {
    super();
  }

  async internalExecute(): Promise<TopInformationCards[]> {
    return await this.topInformationCardsRepository.findAll();
  }
}
