import { StandardPrice } from '@/wms/domain/catalogs/pricingEngine/SettingStandardPrice/StandardPrice';

export class SettingSupplierStandard {
  public suppliers: any;
  public standardPrice: StandardPrice;
  constructor() {
    this.suppliers = [];
    this.standardPrice = new StandardPrice();
  }
}
