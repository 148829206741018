import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { IncotermsRepository } from '@/settings/domain/ParmsGeneral/Incoterms/IncotermsRepository';
import { Incoterms } from '@/settings/domain/ParmsGeneral/Incoterms/Incoterms';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class IncotermsService implements IncotermsRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_INCOTERMS_FINDALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_INCOTERMS_FINDALL;
  private readonly API_INCOTERMS_SAVE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_INCOTERMS_SAVE;
  private readonly API_INCOTERMS_UPDATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_INCOTERMS_UPDATE;
  private readonly API_INCOTERMS_DELETE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_INCOTERMS_DELETE;

  async create(incoterms: Incoterms) {
    try {
      await http.post<Incoterms[]>(this.API_INCOTERMS_SAVE, JSON.stringify(incoterms));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(incoterms: Incoterms) {
    try {
      await http.put<Incoterms[]>(this.API_INCOTERMS_UPDATE, JSON.stringify(incoterms));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async delete(incoterms: Incoterms) {
    try {
      await http.singleDelete<Incoterms[]>(this.API_INCOTERMS_DELETE + `/${incoterms.id}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAll(): Promise<Incoterms[]> {
    try {
      return await http.get<Incoterms[]>(this.API_INCOTERMS_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
