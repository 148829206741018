import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { Consignee } from '@/settings/domain/consignee/Consignee';
import { ConsigneeRepository } from '@/settings/domain/consignee/ConsigneeRepository';

@Injectable()
export class ConsigneeService implements ConsigneeRepository {
  private readonly API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;

  private readonly API_CONSIGNEE_FIND_ALL = localStorageModule().transactions.routes.API_CONSIGNEE_FIND_ALL;
  private readonly API_CONSIGNEE_FIND_BY_NAME =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_CONSIGNEE_FIND_BY_NAME;
  private readonly API_CONSIGNEE_FIND_BY_OPERATION =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_CONSIGNEE_FIND_BY_OPERATION;

  async findAll(): Promise<Consignee[]> {
    try {
      return await http.get(`${this.API_CONSIGNEE_FIND_ALL}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findByName(name: string): Promise<Consignee[]> {
    try {
      return await http.get(`${this.API_CONSIGNEE_FIND_BY_NAME}?name=${name}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findConsigneeByOperation(payload: { type: string; number: number }): Promise<string[]> {
    try {
      return await http.get(`${this.API_CONSIGNEE_FIND_BY_OPERATION}type=${payload.type}&number=${payload.number}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
