import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { OperationRepository } from '@/tracking/domain/typeoperation/OperationRepository';
import { TypeOperation } from '@/settings/domain/typeOperation/TypeOperation';

@Injectable()
export class CreateOperation extends Command<Promise<any>, TypeOperation> {
  public constructor(
    @Inject(TYPES.TYPE_OPERATION_REPOSITORY)
    private readonly operationRepository: OperationRepository
  ) {
    super();
  }

  internalExecute(operation: TypeOperation): Promise<any> {
    return this.operationRepository.create(operation);
  }
}
