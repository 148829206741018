import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { httpFetchNoToken } from '@/core/http/HttpFetchWithoutToken';
import { Role } from '@/settings/domain/role/Role';
import { RoleRepository } from '@/settings/domain/role/RoleRepository';
import { http } from '@/core/http/HttpFetch';
import { RoleDTO } from '@/settings/domain/role/RoleDTO';

@Injectable()
export class RoleServices implements RoleRepository {
  private readonly API_BASE_URI: string = process.env.VUE_APP_URL_TOKEN ?? '';
  private readonly API_ROLE_SAVE: string = this.API_BASE_URI + localStorageModule().auth.routes.API_ROLE_SAVE;
  private readonly API_ROLE_DELETE: string = this.API_BASE_URI + localStorageModule().auth.routes.API_ROLE_DELETE;
  private readonly API_ROLE_UPDATE: string = this.API_BASE_URI + localStorageModule().auth.routes.API_ROLE_UPDATE;
  private readonly API_ROLE_FINDBYROLEID: string =
    this.API_BASE_URI + localStorageModule().auth.routes.API_ROLE_FINDBYROLEID;
  private readonly API_ROLE_FINDMENUPLANEBYROLEID: string =
    this.API_BASE_URI + localStorageModule().auth.routes.API_ROLE_FINDMENUPLANEBYROLEID;
  private readonly API_ROLE_FINDMENUPLANEBYROLENAME: string =
    this.API_BASE_URI + localStorageModule().auth.routes.API_ROLE_FINDMENUPLANEBYROLENAME;
  private readonly API_ROLE_FINDALL: string = this.API_BASE_URI + localStorageModule().auth.routes.API_ROLE_FINDALL;
  private readonly API_ROLE_FIND_BY_NAMESPACE: string =
    this.API_BASE_URI + localStorageModule().auth.routes.API_ROLE_FIND_BY_NAMESPACE;
  private readonly API_ROLE: string = this.API_BASE_URI + localStorageModule().auth.routes.API_ROLE;

  async create(role: Role) {
    try {
      return await httpFetchNoToken.post<Role[]>(this.API_ROLE_SAVE, JSON.stringify(role));
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async update(role: Role) {
    try {
      return await httpFetchNoToken.put<Role[]>(this.API_ROLE_UPDATE, JSON.stringify(role));
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findByRoleId(id: number): Promise<Role[]> {
    try {
      return await httpFetchNoToken.get<Role[]>(this.API_ROLE_FINDBYROLEID + id);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findMenuPlaneByRoleId(id: number): Promise<Role[]> {
    try {
      return await httpFetchNoToken.get<Role[]>(this.API_ROLE_FINDMENUPLANEBYROLEID + id);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findMenuPlaneByRoleName(name: string): Promise<Role[]> {
    try {
      return await httpFetchNoToken.get<Role[]>(this.API_ROLE_FINDMENUPLANEBYROLENAME + name);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findAll(): Promise<Role[]> {
    try {
      return await httpFetchNoToken.get<Role[]>(this.API_ROLE_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findRolesByCompanyId(companyId: string): Promise<Role[]> {
    try {
      return await http.get<Role[]>(this.API_ROLE_FIND_BY_NAMESPACE + 'companyId=' + companyId);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async delete(role: RoleDTO): Promise<void> {
    try {
      return await http.delete<void>(this.API_ROLE, JSON.stringify(role));
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async createRoleV2(role: RoleDTO): Promise<void> {
    try {
      await http.post<void[]>(this.API_ROLE, JSON.stringify(role));
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async updateRoleV2(role: RoleDTO): Promise<void> {
    try {
      await http.VOID_PUT<void[]>(this.API_ROLE, JSON.stringify(role));
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
