import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Rack } from '@/wms/domain/layout/rack/Rack';
import { RackRepository } from '@/wms/domain/layout/rack/RackRepository';

@Injectable()
export class UpdateRack extends Command<Promise<any>, Rack> {
  public constructor(
    @Inject(TYPES.RACKS_REPOSITORY)
    private readonly racksRepository: RackRepository
  ) {
    super();
  }

  internalExecute(rack: Rack): Promise<any> {
    return this.racksRepository.update(rack);
  }
}
