import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Injectable } from '@/core/di/Injectable';
import { StateManager } from '@/core/statemanager/StateManager';
import { OrderbyFilter } from '@/courier/domain/view/orderbyfilter/OrderbyFilter';
import { OrderbyFilterRepository } from '@/courier/domain/view/orderbyfilter/OrderbyFilterRepository';
import { Inject } from 'inversify-props';

@Injectable()
export class FindOrderByFilters extends Query<Promise<OrderbyFilter[]>, any> {
  public constructor(
    @Inject(TYPES.VIEW_ORDER_REPOSITORY)
    private readonly orderRepository: OrderbyFilterRepository,
    @Inject(TYPES.STATE_MANAGER) private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(payload: any): Promise<OrderbyFilter[]> {
    const dataViewOrder = await this.orderRepository.FindOrderByFilters(payload);
    this.stateManager.patch({ dataViewOrder });
    return this.stateManager.state.dataViewOrder;
  }
}
