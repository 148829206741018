
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { CreateEvents } from '@/tracking/application/uses_cases/events/create/CreateEvents';
import { UpdateEvents } from '@/tracking/application/uses_cases/events/update/UpdateEvents';
import { OperationFindByProcessType } from '@/tracking/application/uses_cases/typeoperation/search/OperationFindByProcessType';
import { MonetaryTransactionFindAll } from '@/tracking/application/uses_cases/monetarytransaction/search/findall/MonetaryTransactionFindAll';
import { EventsFindByProcessType } from '@/tracking/application/uses_cases/events/search/EventsFindByProcessType';
import Swal from 'sweetalert2';
import i18n from '@/core/plugins/i18n';
import { sortDescending } from '@/core/plugins/FunctionUtil';
import { SwitchFindAllByProcessType } from '@/tracking/application/uses_cases/switches/search/SwitchFindAllByProcessType';

@Component({
  name: 'ManageEventsWms'
})
export default class AppManageEventsWms extends Vue {
  @Inject(TYPES.FINDBYPROCESSTYPE_TYPE_OPERATION)
  readonly operationFindByProcessType!: OperationFindByProcessType;
  @Inject(TYPES.FINDEVENTSBYPROCESSTYPE_EVENTS)
  readonly eventsFindByProcessType!: EventsFindByProcessType;
  @Inject(TYPES.FINDBYPROCESSTYPE_SWITCH)
  readonly switchFindAllByProcessType!: SwitchFindAllByProcessType;
  @Inject(TYPES.CREATE_EVENTS)
  readonly createEvents!: CreateEvents;
  @Inject(TYPES.UPDATE_EVENTS)
  readonly updateEvents!: UpdateEvents;
  @Inject(TYPES.FINDALL_MONETARY_TRANSACTION)
  readonly monetaryTransactionFindAll!: MonetaryTransactionFindAll;
  //Data
  activityMonetaryList = [];
  activitySwitchList = [];
  typeOperationList = [];
  private eventsList: any = [
    {
      description: '',
      typeOperation: '',
      day: 0,
      handleSpreadAutomatically: false,
      active: true,
      activities: [
        {
          description: '',
          monetaryTransactionId: null,
          required: true,
          value: '',
          active: true,
          sw: [],
          processType: 2
        }
      ]
    }
  ];
  private formLineTimeWms: any = {
    eventSelected: [],
    eventsListFilter: [],
    visibleEvents: false,
    activeActivity: false,
    isTouched: false,
    dataTypeOperation: [],
    disabledEvents: false,
    edit: null
  };
  //Refs
  $refs!: {
    activityDescription: HTMLFormElement;
    dropdown: HTMLFormElement;
  };
  //Hook
  mounted() {
    this.$nextTick(() => this.typeOperation);
    this.$nextTick(() => this.switch);
    this.$nextTick(() => this.getFindAllEvents());
  }
  //Computed
  get typeOperation() {
    this.operationFindByProcessType.execute(2).then((response: any) => {
      this.typeOperationList = response;
    });
    return true;
  }
  get typeMonetary() {
    this.monetaryTransactionFindAll.execute().then((response: any) => {
      this.activityMonetaryList = response;
    });
    this.formLineTimeWms.activeActivity = true;
    return true;
  }
  get switch() {
    this.switchFindAllByProcessType.execute(2).then((response: any) => {
      this.activitySwitchList = response;
    });
    return true;
  }
  //method
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  public getFindAllEvents() {
    this.eventsFindByProcessType
      .execute(2)
      .then((response: any) => {
        const res = sortDescending(response);
        this.eventsList = res;
        this.formLineTimeWms.eventsListFilter = res;
        this.formLineTimeWms.disabledEvents = true;
        this.formLineTimeWms.visibleEvents = false;
      })
      .catch(error => {
        this.eventsList = [];
        throw new Error(error);
      });
  }
  public saveEventWms(index: number) {
    this.eventsList[index].activities.find((activity: any) => {
      if (activity.monetaryTransactionId) {
        activity.monetaryTransactionId = activity.monetaryTransactionId.id;
      }
      if (activity.sw) {
        activity.sw = activity.sw.sw;
      }
    });
    if (this.eventsList[index].id) {
      const payloadUpdate: any = {
        id: this.eventsList[index].id,
        description: this.eventsList[index].description,
        day: this.eventsList[index].day,
        handleSpreadAutomatically: false,
        typeOperation: this.formLineTimeWms.dataTypeOperation.code,
        active: this.eventsList[index].active,
        activities: this.eventsList[index].activities
      };
      this.updateEvents.execute(payloadUpdate);
      this.formLineTimeWms.edit = null;
    } else {
      const payloadSave: any = {
        description: this.eventsList[index].description,
        day: this.eventsList[index].day,
        handleSpreadAutomatically: false,
        typeOperation: this.formLineTimeWms.dataTypeOperation.code,
        active: true,
        activities: this.eventsList[index].activities
      };
      this.createEvents.execute(payloadSave);
    }
    this.getFindAllEvents();
    this.formLineTimeWms.disabledEvents = true;
    this.formLineTimeWms.activeActivity = true;
  }
  public editEvents(data: any) {
    this.formLineTimeWms.edit = this.formLineTimeWms.edit !== data.id ? data.id : null;
    this.formLineTimeWms.dataOperation = {
      code: data.typeOperation,
      description: data.typeOperationName
    };
    for (const activity of data.activities) {
      if (activity.monetaryTransactionId) {
        activity.monetaryTransactionId = {
          id: activity.monetaryTransactionId,
          name: activity.monetaryTransactionName
        };
      }
      if (activity.sw) {
        activity.sw = {
          sw: activity.sw,
          nature: activity.nature
        };
      }
    }
    this.typeMonetary;
    this.formLineTimeWms.disabledEvents = false;
  }
  public filterEvent() {
    this.getFindAllEvents();
    const result: any = this.eventsList.filter((data: any) => data.id === this.formLineTimeWms.eventSelected.id);
    this.eventsList = result;
    this.$refs.dropdown.hide(true);
    this.formLineTimeWms.visibleEvents = true;
    return this.eventsList;
  }
  public addEvent() {
    this.eventsList.unshift({
      description: '',
      day: 0,
      typeOperation: '',
      handleSpreadAutomatically: false,
      active: true,
      activities: [
        {
          description: '',
          monetaryTransactionId: null,
          required: true,
          value: '',
          active: true,
          sw: [],
          processType: 2
        }
      ]
    });
    this.formLineTimeWms.disabledEvents = false;
    this.formLineTimeWms.activeActivity = false;
  }
  public addActivity(activities: any) {
    activities.push({
      description: '',
      monetaryTransactionId: null,
      required: true,
      value: '',
      active: true,
      sw: [],
      processType: 2
    });
  }
  public addActivityNext() {
    this.typeMonetary;
    this.formLineTimeWms.activeActivity = true;
  }
  public deleteActivity(index: number, activity: any, activities: any) {
    const idActivity = activities.indexOf(activity);
    if (idActivity > -1) {
      activities.splice(idActivity, 1);
    }
  }
  public deleteTimelineWms(index: any) {
    if (this.eventsList[index].id) {
      Swal.fire({
        title:
          this.$i18n.t('general.areyousuretodeletethis') +
          ' ' +
          this.$i18n.t('general.event') +
          ' ' +
          this.$i18n.t('general.squestion'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `${i18n.t('general.delete')}`,
        cancelButtonText: `${i18n.t('general.cancel')}`,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then(result => {
        if (result.isConfirmed) {
          const payloadUpdate: any = {
            id: this.eventsList[index].id,
            description: this.eventsList[index].description,
            day: this.eventsList[index].day,
            handleSpreadAutomatically: false,
            typeOperation: this.formLineTimeWms.dataTypeOperation.code,
            active: false,
            activities: this.eventsList[index].activities
          };
          this.updateEvents.execute(payloadUpdate);
          this.getFindAllEvents();
        } else {
          return false;
        }
      });
    } else {
      this.eventsList.shift();
    }
  }
}
