import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Injectable } from '@/core/di/Injectable';
import { MenuRepository } from '@/settings/domain/menu/MenuRepository';
import { Menu } from '@/settings/domain/menu/menu';
import { Inject } from 'inversify-props';

@Injectable()
export class AssignMenuToRole extends Query<Promise<void>, { companyId: string; role: number; menuItems: Menu[] }> {
  public constructor(
    @Inject(TYPES.MENU_REPOSITORY)
    private readonly repository: MenuRepository
  ) {
    super();
  }

  async internalExecute(payload: { companyId: string; role: number; menuItems: Menu[] }): Promise<void> {
    await this.repository.assignToRole(payload);
  }
}
