import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { Branch } from '@/settings/domain/branch/Branch';
import { BranchRepository } from '@/settings/domain/branch/BranchRepository';
@Injectable()
export class FindBranchByCode extends Query<Promise<Branch | null>, string> {
  public constructor(@Inject(TYPES.BRANCH_REPOSITORY) private readonly branchRepository: BranchRepository) {
    super();
  }

  async internalExecute(code: string): Promise<Branch | null> {
    return await this.branchRepository.findByCode(code);
  }
}
