
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { TransportFindAll } from '@/tracking/application/uses_cases/typetransport/search/findall/TransportFindAll';
import { CreateTimeline } from '@/tracking/application/uses_cases/timeline/create/CreateTimeline';
import { EventsFindByProcessType } from '@/tracking/application/uses_cases/events/search/EventsFindByProcessType';
import { UpdateTimeline } from '@/tracking/application/uses_cases/timeline/update/UpdateTimeline';
import { OperationFindByProcessType } from '@/tracking/application/uses_cases/typeoperation/search/OperationFindByProcessType';

@Component({
  name: 'CreateTimelineWms'
})
export default class CreateTimelineWms extends Vue {
  @Inject(TYPES.FINDALL_TRANSPORT)
  readonly transportFindAll!: TransportFindAll;
  @Inject(TYPES.CREATE_TIMELINE)
  readonly createTimeline!: CreateTimeline;
  @Inject(TYPES.UPDATE_TIMELINE)
  readonly updateTimeline!: UpdateTimeline;
  @Inject(TYPES.FINDEVENTSBYPROCESSTYPE_EVENTS)
  readonly eventsFindByProcessType!: EventsFindByProcessType;
  @Inject(TYPES.FINDBYPROCESSTYPE_TYPE_OPERATION)
  readonly operationFindByProcessType!: OperationFindByProcessType;
  //Data
  typeTransportList = [];
  processTypeList = [];
  typeOperationList = [];
  processTypeTable = [];
  private formCreateTimeline: any = {
    timelinedDescription: '',
    isTouched: false,
    dataTypeTransport: [],
    dataProcessType: [],
    dataTypeOperation: [],
    edit: null
  };
  //Prop
  @Prop() readonly timelineList!: any;
  @Prop() readonly validationSave!: boolean;
  //Refs
  $refs!: {
    timelinedDescription: HTMLInputElement;
  };
  //Hook
  mounted() {
    this.$nextTick(() => this.getTimeline(this.timelineList));
    this.$nextTick(() => this.typeTransport);
    this.$nextTick(() => this.eventsProcessType);
    this.$nextTick(() => this.typeOperation);
    if (this.validationSave) {
      this.$nextTick(() => this.clearForm());
    }
  }
  //Computed
  get rows() {
    return this.processTypeTable.length;
  }
  public get eventsProcessType() {
    this.eventsFindByProcessType.execute(2).then((response: any) => {
      this.processTypeList = [];
      this.processTypeList = response;
    });
    return true;
  }
  public get typeTransport() {
    this.transportFindAll.execute().then((response: any) => {
      this.typeTransportList = response;
    });
    return true;
  }
  public get typeOperation() {
    this.operationFindByProcessType.execute(2).then((response: any) => {
      this.typeOperationList = response;
    });
    return true;
  }
  //method
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  public closeModal() {
    this.$emit('closeModal');
  }
  public getTimeline(data: any) {
    this.formCreateTimeline.timelinedDescription = data.description;
    this.formCreateTimeline.dataTypeTransport = {
      id: data.typeTransport,
      name: data.typeTransportName
    };
    this.formCreateTimeline.dataTypeOperation = {
      code: data.typeOperation,
      description: data.typeOperationName
    };
    this.processTypeTable = data.events;
  }
  public editEventTimeline(data: any) {
    this.formCreateTimeline.edit = this.formCreateTimeline.edit !== data.id ? data.id : null;
    if (this.formCreateTimeline.edit === null) {
      const payloadUpdate: any = {
        id: this.timelineList.id,
        description: this.formCreateTimeline.timelinedDescription.trim(),
        typeTransport: this.formCreateTimeline.dataTypeTransport.id,
        typeOperation: this.formCreateTimeline.dataTypeOperation.code,
        processType: 2,
        active: true,
        events: this.processTypeTable
      };
      this.updateTimeline.execute(payloadUpdate);
      this.getTimelineSkeleton();
    }
  }
  public saveTimeline() {
    for (const index in this.formCreateTimeline.dataProcessType) {
      this.formCreateTimeline.dataProcessType[index].sequence = parseInt(index) + 1;
    }
    const payloadSave: any = {
      id: this.timelineList.id,
      description: this.formCreateTimeline.timelinedDescription.trim(),
      typeTransport: this.formCreateTimeline.dataTypeTransport.id,
      typeOperation: this.formCreateTimeline.dataTypeOperation.code,
      processType: 2,
      active: true,
      events: this.formCreateTimeline.dataProcessType
    };
    if (this.processTypeTable.length > 0) {
      this.updateTimeline.execute(payloadSave);
    } else {
      this.createTimeline.execute(payloadSave);
    }
    this.getTimelineSkeleton();
  }
  public clearForm() {
    this.typeTransportList = [];
    this.processTypeList = [];
    this.processTypeTable = [];
    this.formCreateTimeline = {
      timelinedDescription: '',
      isTouched: false,
      dataTypeTransport: [],
      dataProcessType: [],
      edit: null
    };
    this.$nextTick(() => this.$refs.timelinedDescription.focus());
  }
  //Emit
  @Emit()
  public getTimelineSkeleton() {
    this.$emit('getTimelineSkeleton');
  }
}
