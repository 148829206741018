
import Vue from 'vue';
import i18n from '@/core/plugins/i18n';
import { ValidationObserver } from 'vee-validate/dist/types/components';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import Component from 'vue-class-component';
import LanguageSwitcher from '@/core/components/LanguageSwitcher.vue';
import { LoginFindToken } from '@/courier/application/uses_cases/login/search/findtoken/LoginFindToken';
import { showAlert } from '@/core/plugins/Notifications';
import CreateCompany from '@/settings/infrastructure/ui/components/company/CreateCompany.vue';

@Component({
  name: 'Login',
  components: {
    CreateCompany,
    LanguageSwitcher
  }
})
export default class Login extends Vue {
  @Inject(TYPES.LOGIN_USER)
  loginFindToken!: LoginFindToken;
  //Data
  widthResize = 0;
  inputType = 'password';
  latitude = 0;
  isLoading = false;
  fullPage = true;
  urlLogoCompany = '';
  form: any = {
    username: '',
    password: ''
  };
  //Refs
  $refs!: {
    username: HTMLInputElement;
    btnLogin: HTMLButtonElement;
    formLogin: InstanceType<typeof ValidationObserver>;
  };
  //Hook
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  }
  mounted() {
    this.$nextTick(() => this.clearForm());
  }
  //Properties Computed
  get marginLogin() {
    return this.widthResize < 450 ? 'container-logo--margin' : '';
  }
  //Methods
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  handleResize() {
    this.widthResize = window.innerWidth;
  }
  Login() {
    this.isLoading = true;
    const payload: any = {
      username: this.form.username.trim(),
      password: this.form.password.trim()
    };
    this.loginFindToken
      .execute(payload)
      .then((response: any) => {
        if ('error' in response || 'message' in response) {
          showAlert('warning', i18n.t('generalmessage.ERROR_MESSAGE_LOGIN'));
        } else {
          this.$toasted.show(i18n.t('general.welcome') + ` ${response.customerName} !`);
        }
        this.isLoading = false;
        return response;
      })
      .catch((error: any) => {
        this.isLoading = false;
        throw new Error(error);
      });
    this.$nextTick(() => (this.$refs.btnLogin.disabled = true));
  }
  clearForm() {
    this.form.username = '';
    this.form.password = '';
    this.$refs.formLogin.reset();
    this.$refs.username.focus();
  }
}
