import { Injectable } from '@/core/di/Injectable';
import { Query } from '@/core/cqrs/Query';
import { ContableConcepts } from '@/courier/domain/ContableConcepts/ContableConcepts';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { ContableConceptsRepository } from '@/courier/domain/ContableConcepts/ContableConceptsRepository';

@Injectable()
export class FindByStatusContableConcepts extends Query<Promise<ContableConcepts[]>, boolean> {
  constructor(
    @Inject(TYPES.CONTABLE_CONCEPTS_REPOSITORY)
    private readonly contableConceptRepository: ContableConceptsRepository
  ) {
    super();
  }

  public async internalExecute(status: boolean): Promise<ContableConcepts[]> {
    return await this.contableConceptRepository.findByStatus(status);
  }
}
