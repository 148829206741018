
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { Territories } from '@/settings/domain/views/territories/Territories';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { SearchTerritoryByQueryDescription } from '@/settings/application/uses_cases/views/territories/SearchTerritoryByQueryDescription';
import { Port } from '@/freight/domain/ports/Port';

@Component({
  name: 'AddPort'
})
export default class AddPort extends Vue {
  @Prop() readonly buttonOptions!: any;
  @Prop() readonly invalid!: any;
  @Prop() readonly factory!: any;
  @Prop() readonly clearForm!: any;
  @Prop() readonly form!: Port;
  @Prop() portLevelSelected!: any;

  @Inject(TYPES.STATE_MANAGER)
  readonly stateManager!: StateManager;
  territories: Territories[] = [];
  @Inject(TYPES.API_VIEW_FIND_TERRITORIES_BY_QUERY)
  readonly searchGeneralTerritories!: SearchTerritoryByQueryDescription;

  //VARIABLES PARA INTERVALO DE BUSQUEDA
  timeoutSearch: any;
  searchInterval = 600;

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  get portLevel() {
    return this.portLevelSelected;
  }

  set portLevel(newValue: any) {
    this.$emit('update:portLevelSelected', newValue);
  }

  get portLevelOptions() {
    return [
      { value: '1', description: 'Puerto local pequeño' },
      { value: '2', description: 'Puerto local grande' },
      { value: '3', description: 'Hub regional' },
      { value: '4', description: 'Hub global' }
    ];
  }

  findOrigin(query: string) {
    clearTimeout(this.timeoutSearch);
    this.timeoutSearch = setTimeout(() => {
      (async () => {
        if (query.length >= 3) {
          await this.searchGeneralTerritories
            .execute(query.toUpperCase())
            .then((response: any) => {
              this.territories = response;
            })
            .catch((error: any) => {
              throw new Error(`${error}`);
            });
        }
      })();
    }, this.searchInterval);
  }

  setPortLevel(data: any) {
    this.portLevel = data;
  }
}
