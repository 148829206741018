import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Bank } from '@/settings/domain/ParmsGeneral/bank/Bank';
import { BankRepository } from '@/settings/domain/ParmsGeneral/bank/BankRepository';

@Injectable()
export class BankFindAll extends Query<Promise<Bank[]>> {
  public constructor(
    @Inject(TYPES.BANK_REPOSITORY)
    private readonly repository: BankRepository,

    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(): Promise<Bank[]> {
    const dataBank = await this.repository.findAll();
    this.stateManager.patch({ dataBank });
    return this.stateManager.state.dataBank;
  }
}
