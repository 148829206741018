export class ContainerInspection {
  id: number;
  description: string;
  status: boolean;
  sequence: number;

  public constructor() {
    this.id = 0;
    this.description = '';
    this.status = false;
    this.sequence = 0;
  }
}
