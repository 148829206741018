import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { Injectable } from '@/core/di/Injectable';
import { Switches } from '@/tracking/domain/switches/Switches';
import { SwitchesRepository } from '@/tracking/domain/switches/SwitchesRepository';
import { Inject } from 'inversify-props';

@Injectable()
export class UpdateSwitchsStatus extends Command<Promise<Switches[]>, Switches[]> {
  public constructor(
    @Inject(TYPES.SWITCH_REPOSITORY)
    private readonly switchesRepository: SwitchesRepository
  ) {
    super();
  }
  async internalExecute(switches: Switches[]): Promise<Switches[]> {
    return await this.switchesRepository.updateStatus(switches);
  }
}
