
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { FindTransactionalActivityById } from '@/tracking/application/uses_cases/activitiesTransactional/search/FindTransactionalActivityById';
import { ActivityTransactional } from '@/tracking/domain/activitiesTransactional/ActivityTransactional';
import { ActivityTransactionalForTraceability } from '@/tracking/domain/activitiesTransactional/Projections/ActivityTransactionalForTraceability';
import { PreRegisterForTraceabilityPanel } from '@/wms/domain/preRegister/PreRegisterForTraceabilityPanel';
import TransactionalEventForTraceability from '@/wms/domain/preRegister/TransactionalEventForTraceability';
import { BvModalEvent } from 'bootstrap-vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import FooterModal from './FooterModals/FooterModal.vue';
import HeaderModal from './HeaderModals/HeaderModal.vue';

@Component({
  name: 'ModalC2',
  components: { HeaderModal, FooterModal }
})
export default class ModalC2 extends Vue {
  @Inject(TYPES.ACTIVITIES_TRANSACTIONAL_FIND_BY_ID)
  private readonly findActivityById!: FindTransactionalActivityById;

  @Prop() isLoading!: boolean;
  @Prop() switchId!: string;
  @Prop() sendNotification!: boolean;
  @Prop() idModal!: string;
  @Prop() activityData!: ActivityTransactionalForTraceability | null;
  @Prop() eventData!: TransactionalEventForTraceability;
  @Prop({ type: Function }) saveProcess!: (activity: ActivityTransactional) => void;
  @Prop() attachedFiles!: any[]; // #TODO: Toca cambiar el tipo de dato
  @Prop({ required: true }) master!: PreRegisterForTraceabilityPanel;
  @Prop({ type: Function }) searchActivities!: () => Promise<void>;

  modalActivityData: ActivityTransactional | null = null;

  @Watch('attachedFiles')
  onMyFilesChange() {
    // Debemos hacer que el padre actualice el valor de attachedFiles
    this.$emit('update:attachedFiles', this.attachedFiles);
  }

  get localIsLoading() {
    return this.isLoading;
  }

  set localIsLoading(value) {
    this.$emit('update:isLoading', value);
  }

  get eventExecuted() {
    return this.eventData.state === 'EXECUTED';
  }

  get modalTitle() {
    return `${this.eventData.description} - ${this.$t('switchs.' + this.activityData?.sw)} - ${this.idModal}`;
  }

  async findData() {
    try {
      if (!this.activityData) return;

      this.localIsLoading = true;

      this.modalActivityData = await this.findActivityById.execute({
        activityId: this.activityData?.activityId,
        eventId: this.eventData.eventId,
        preRegisterNumber: this.master.number,
        preRegisterType: this.master.type
      });
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.localIsLoading = false;
    }
  }

  save(event: BvModalEvent) {
    event.preventDefault();
    if (!this.modalActivityData) return;
    this.saveProcess(this.modalActivityData);
  }
}
