import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { LoginImage } from '@/settings/domain/loginImage/LoginImage';
import { LoginImageRepository } from '@/settings/domain/loginImage/LoginImageRepository';

@Injectable()
export class LoginImageFindAll extends Query<Promise<LoginImage[]>> {
  public constructor(
    @Inject(TYPES.LOGIN_IMAGE_REPOSITORY)
    private readonly repository: LoginImageRepository
  ) {
    super();
  }
  async internalExecute(): Promise<LoginImage[]> {
    return await this.repository.findAll();
  }
}
