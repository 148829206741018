import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Command } from '@/core/cqrs/Command';
import { Invoice } from '@/courier/domain/invoice/Invoice';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { InvoiceRepository } from '@/courier/domain/invoice/InvoiceRepository';

@Injectable()
export class CreateInvoiceFromOrder extends Command<Promise<any>, Invoice> {
  public constructor(
    @Inject(TYPES.INVOICE_REPOSITORY)
    private readonly invoiceListRepository: InvoiceRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  internalExecute(params: any): Promise<any> {
    return this.invoiceListRepository.createInvoiceFromOrder(params);
  }
}
