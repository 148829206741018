import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { EmailTypeRepository } from '@/settings/domain/emailType/EmailTypeRepository';
import { EmailType } from '@/settings/domain/emailType/EmailType';

@Injectable()
export class EmailTypeService implements EmailTypeRepository {
  private readonly API_BASE_URI: string = process.env.VUE_APP_URL_TOKEN ?? '';

  private readonly API_EMAIL_TYPES_FIND_ALL: string =
    this.API_BASE_URI + localStorageModule().auth.routes.API_EMAIL_TYPES_FIND_ALL;

  async findAll(): Promise<EmailType[]> {
    return await http.get<EmailType[]>(this.API_EMAIL_TYPES_FIND_ALL);
  }
}
