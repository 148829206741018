import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { GeneralPort } from '@/settings/domain/generalPort/GeneralPort';
import { GeneralPortRepository } from '@/settings/domain/generalPort/GeneralPortsRepository';
@Injectable()
export class SearchGeneralPortByQuery extends Query<Promise<GeneralPort[]>, string> {
  public constructor(
    @Inject(TYPES.GENERAL_PORTS_REPOSITORY)
    private readonly generalPortRepository: GeneralPortRepository
  ) {
    super();
  }
  async internalExecute(query: string): Promise<GeneralPort[]> {
    return await this.generalPortRepository.findByQuery(query);
  }
}
