// socketManager.js
import io, { Socket } from 'socket.io-client';

class SocketManager {
  private static instance: Socket | null = null;

  constructor() {
    SocketManager.instance = io(process.env.VUE_APP_SOCKET_URL ?? 'http://localhost:3000');
  }

  public static getInstance() {
    if (!SocketManager.instance) {
      SocketManager.instance = io(process.env.VUE_APP_SOCKET_URL ?? 'http://localhost:3000');
    }

    return SocketManager.instance;
  }

  connect() {
    SocketManager.instance?.connect();
  }

  disconnect() {
    SocketManager.instance?.disconnect();
  }

  joinRoom(room: string) {
    SocketManager.instance?.emit('join', room);
  }

  emitEvent(event: string, data: any) {
    SocketManager.instance?.emit(event, data);
  }
}

export default SocketManager;
