import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Packaging } from '@/wms/domain/catalogs/packaging/Packaging';
import { PackagingRepository } from '@/wms/domain/catalogs/packaging/PackagingRepository';

@Injectable()
export class FindPackagingByReferenceAndType extends Query<
  Promise<Packaging[]>,
  {
    reference: string;
    type: string;
  }
> {
  public constructor(
    @Inject(TYPES.PACKAGING_REFERENCE_REPOSITORY)
    private readonly packagingRepository: PackagingRepository
  ) {
    super();
  }

  async internalExecute(payload: { reference: string; type: string }): Promise<Packaging[]> {
    return await this.packagingRepository.findByReferenceAndType(payload);
  }
}
