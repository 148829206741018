import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { StateManager } from '@/core/statemanager/StateManager';
import { Containers } from '@/settings/domain/containers/Containers';
import { ContainersRepository } from '@/settings/domain/containers/CotainersRepository';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';

@Injectable()
export class FindContainerById extends Query<Promise<Containers>, string> {
  public constructor(
    @Inject(TYPES.CONTAINER_REPOSITORY)
    private readonly containerRepository: ContainersRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(containerId: string): Promise<Containers> {
    return await this.containerRepository.findContainerById(containerId);
  }
}
