import { Injectable } from '@/core/di/Injectable';
import { Query } from '@/core/cqrs/Query';
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { StateManager } from '@/core/statemanager/StateManager';
import { Rack } from '@/wms/domain/layout/rack/Rack';
import { RackRepository } from '@/wms/domain/layout/rack/RackRepository';

@Injectable()
export class FindAllByStatusRack extends Query<Promise<Rack[]>, boolean> {
  public constructor(
    @Inject(TYPES.RACKS_REPOSITORY)
    private readonly racksRepository: RackRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(status: boolean): Promise<Rack[]> {
    const dataLayoutRack = await this.racksRepository.findByStatus(status);
    this.stateManager.patch({ dataLayoutRack });
    return this.stateManager.state.dataLayoutRack;
  }
}
