import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';

export class Locations {
  rackId: number;
  level: number;
  locationId: number;
  position: string;
  description: string;
  maxCapacity: number;
  volume: number;
  active: boolean;
  createAt: string;
  updateAt: string;
  commodities: CommodityType[];
  //keys for config layout
  layoutRackId: number;
  layoutId: number;
  storeId: number;
  zoneId: string;

  public constructor(rackId: number, level: number) {
    this.rackId = rackId;
    this.level = level;
    this.locationId = 0;
    this.position = '';
    this.description = '';
    this.maxCapacity = 0;
    this.volume = 0;
    this.active = true;
    this.createAt = '';
    this.updateAt = '';
    this.commodities = [];
    this.layoutRackId = 0;
    this.layoutId = 0;
    this.storeId = 0;
    this.zoneId = '';
  }
}
