import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Switches } from '@/tracking/domain/switches/Switches';
import { SwitchesRepository } from '@/tracking/domain/switches/SwitchesRepository';

@Injectable()
export class SwitchesFindAll extends Query<Promise<Switches[]>> {
  public constructor(
    @Inject(TYPES.SWITCH_REPOSITORY)
    private readonly switchesRepository: SwitchesRepository,
    @Inject(TYPES.STATE_MANAGER) private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(): Promise<Switches[]> {
    const dataSwitches = await this.switchesRepository.findAll();
    this.stateManager.patch({ dataSwitches });
    return this.stateManager.state.dataSwitches;
  }
}
