import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { AlternativeReferencesRepository } from '@/wms/domain/catalogs/alternativeReferences/AlternativeReferencesRepository';
import { AlternativeReferences } from '@/wms/domain/catalogs/alternativeReferences/AlternativeReferences';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class AlternativeReferencesService implements AlternativeReferencesRepository {
  private readonly API_BASE_URI: string = localStorageModule().wms.host.API_BASE_URI;
  private readonly API_ALTERNATIVEREFERENCE_FINDALL: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_ALTERNATIVEREFERENCE_FINDALL;
  private readonly API_ALTERNATIVEREFERENCE_FINDBYREFERENCE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_ALTERNATIVEREFERENCE_FINDBYREFERENCE;
  private readonly API_ALTERNATIVEREFERENCE_SAVE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_ALTERNATIVEREFERENCE_SAVE;
  private readonly API_ALTERNATIVEREFERENCE_UPDATE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_ALTERNATIVEREFERENCE_UPDATE;
  private readonly API_ALTERNATIVEREFERENCE_DELETE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_ALTERNATIVEREFERENCE_DELETE;
  private readonly API_ALTERNATIVEREFERENCE_FINDOWNER: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_ALTERNATIVEREFERENCE_FINDOWNER;

  async create(alternativeReferences: AlternativeReferences) {
    try {
      await http.post<AlternativeReferences[]>(
        this.API_ALTERNATIVEREFERENCE_SAVE,
        JSON.stringify(alternativeReferences)
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(alternativeReferences: AlternativeReferences) {
    try {
      await http.put<AlternativeReferences[]>(
        this.API_ALTERNATIVEREFERENCE_UPDATE,
        JSON.stringify(alternativeReferences)
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async delete(alternativeReferences: AlternativeReferences) {
    try {
      await http.delete<AlternativeReferences[]>(
        this.API_ALTERNATIVEREFERENCE_DELETE,
        JSON.stringify(alternativeReferences)
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAll(): Promise<AlternativeReferences[]> {
    try {
      return await http.get<AlternativeReferences[]>(this.API_ALTERNATIVEREFERENCE_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findByReference(code: string): Promise<AlternativeReferences[]> {
    try {
      return await http.get<AlternativeReferences[]>(this.API_ALTERNATIVEREFERENCE_FINDBYREFERENCE + code);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async findOwnerByReferenceAndType(parms: any): Promise<Entity[]> {
    try {
      return await http.get<Entity[]>(
        this.API_ALTERNATIVEREFERENCE_FINDOWNER + `${parms.code}/${parms.text}/${parms.type}`
      );
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
