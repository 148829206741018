import { TYPES } from '@/core/config/Types';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { StateManager } from '@/core/statemanager/StateManager';
import { PriceList } from '@/settings/domain/priceList/PriceList';
import { PriceListRepositoryVolume } from '@/settings/domain/priceList/PriceListRepositoryVolume';
import { Query } from '@/core/cqrs/Query';
@Injectable()
export class PriceListFindAllVolume extends Query<Promise<PriceList[]>> {
  public constructor(
    @Inject(TYPES.PRICE_LIST_REPOSITORY_VOLUME)
    private readonly priceListRepository: PriceListRepositoryVolume,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(): Promise<PriceList[]> {
    const dataPriceList = await this.priceListRepository.findAll();
    this.stateManager.patch({ dataPriceList });
    return this.stateManager.state.dataPriceList;
  }
}
