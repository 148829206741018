import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Weight } from '@/settings/domain/weight/Weight';
import { WeightRepository } from '@/settings/domain/weight/WeightRepository';

@Injectable()
export class WeightFindAll extends Query<Promise<Weight[]>> {
  public constructor(
    @Inject(TYPES.WEIGHT_REPOSITORY)
    private readonly weightRepository: WeightRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(): Promise<Weight[]> {
    const dataWeight = await this.weightRepository.findAll();
    this.stateManager.patch({ dataWeight });
    return this.stateManager.state.dataWeight;
  }
}
