import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Command } from '@/core/cqrs/Command';
import { Activities } from '@/tracking/domain/activities/Activities';
import { ActivitiesRepository } from '@/tracking/domain/activities/ActivitiesRepository';

@Injectable()
export class CreateActivities extends Command<Promise<any>, Activities> {
  public constructor(
    @Inject(TYPES.STATE_MANAGER) private readonly stateManager: StateManager,
    @Inject(TYPES.ACTIVITIES_REPOSITORY)
    private readonly activitiesRepository: ActivitiesRepository
  ) {
    super();
  }

  internalExecute(activities: Activities): Promise<any> {
    return this.activitiesRepository.create(activities);
  }
}
