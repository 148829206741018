
import Vue from 'vue';
import Component from 'vue-class-component';
import CustomTable from '@/core/components/shared/CustomTable.vue';
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { SwitchesFindAll } from '@/tracking/application/uses_cases/switches/search/SwitchesFindAll';
import { Switches } from '@/tracking/domain/switches/Switches';
import { UpdateSwitchsStatus } from '@/tracking/application/uses_cases/switches/update/UpdateSwitchsStatus';
@Component({
  name: 'SwitchManagement',
  components: { CustomTable }
})
export default class SwitchManagement extends Vue {
  @Inject(TYPES.FINDALL_SWITCH)
  readonly findAllSwitches!: SwitchesFindAll;
  @Inject(TYPES.UPDATE_SWITCHES_STATUS)
  readonly updateSwitchsStatus!: UpdateSwitchsStatus;
  //LISTAS
  switchList: Switches[] = [];
  selectedSwitchs: Switches[] = [];
  //DATOS
  isLoading = false;

  mounted() {
    this.findall();
  }

  //METODOS
  //Este metodo es usado para obtener la lista de todos los switchs
  async findall() {
    this.isLoading = true;
    const res = await this.findAllSwitches.execute();
    this.switchList = res;
    this.isLoading = true;
  }

  //Este metodo es usado para activar todos los switchs seleccionados
  activate() {
    this.selectedSwitchs.map((item: Switches) => {
      item.active = true;
      return item;
    });
  }

  //Este metodo es usado para inactivar todos los switchs seleccionados
  inactivate() {
    this.selectedSwitchs.map((item: Switches) => {
      item.active = false;
      return item;
    });
  }
}
