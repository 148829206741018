import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { OrderbyFilterRepository } from '@/courier/domain/view/orderbyfilter/OrderbyFilterRepository';
import { OrderbyFilter } from '@/courier/domain/view/orderbyfilter/OrderbyFilter';

@Injectable()
export class OrderByFilterService implements OrderbyFilterRepository {
  private readonly API_BASE_URI: string = localStorageModule().courier.host.API_BASE_URI;
  private readonly API_VIEW_ORDER_FINDBYFILTERS: string =
    this.API_BASE_URI + localStorageModule().courier.routes.API_VIEW_ORDER_FINDBYFILTERS;

  async FindOrderByFilters(payload: any): Promise<OrderbyFilter[]> {
    let resturi = '';
    try {
      if (payload.FechIni != null && payload.FechFin != null) {
        resturi = `&FechIni=${payload.FechIni}&FechFin=${payload.FechFin}`;
      }
      return await http.get<OrderbyFilter[]>(
        this.API_VIEW_ORDER_FINDBYFILTERS +
          `?sw=${payload.sw}&idRoute=${payload.idRoute}&numberConsol=${payload.numberConsol}` +
          resturi
      );
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
