import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { ReferencesRepository } from '@/wms/domain/catalogs/references/ReferencesRepository';
import { NewReferenceObject } from '@/wms/domain/catalogs/references/NewReferenceObject';

@Injectable()
export class ReferenceFindBySku extends Query<Promise<NewReferenceObject>, string> {
  public constructor(
    @Inject(TYPES.REFERENCE_REPOSITORY)
    private readonly referencesRepository: ReferencesRepository
  ) {
    super();
  }
  async internalExecute(sku: string): Promise<NewReferenceObject> {
    return this.referencesRepository.findBySku(sku);
  }
}
