import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { EmailTypeConfig } from '@/settings/domain/emailTypeConfig/EmailTypeConfig';
import { EmailTypeConfigRepository } from '@/settings/domain/emailTypeConfig/EmailTypeConfigRepository';
import { Command } from '@/core/cqrs/Command';

@Injectable()
export class SaveEmailTypeConfig extends Command<Promise<EmailTypeConfig>, EmailTypeConfig> {
  public constructor(
    @Inject(TYPES.EMAIL_TYPE_CONFIG_REPOSITORY)
    private readonly repository: EmailTypeConfigRepository
  ) {
    super();
  }
  async internalExecute(config: EmailTypeConfig): Promise<EmailTypeConfig> {
    return await this.repository.saveConfig(config);
  }
}
