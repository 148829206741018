import Vue from 'vue';
import { Injectable } from '@/core/di/Injectable';
import { Observer } from '@/core/observer/Observer';
import { State } from '@/core/statemanager/State';
import { StateManager } from '@/core/statemanager/StateManager';

@Injectable()
export class TyStateManager implements StateManager {
  private _state: State = Vue.observable(new State());
  private readonly observers: Observer[] = [];

  get state(): State {
    return this._state;
  }
  set state(value: State) {
    this._state = value;
    this.notifyAll();
  }
  patch(state: State): void {
    type Keys = keyof State;
    Object.entries(state).forEach(([key, value]) => {
      const accessor = key as Keys;
      if (value !== undefined) {
        this.state[accessor] = value;
      }
    });
  }
  notifyAll() {
    this.observers.forEach(observer => observer.notify());
  }
  register(observer: Observer) {
    this.observers.push(observer);
  }
}
