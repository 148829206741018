import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { BillOfLandingRepository } from '@/wms/domain/billOfLanding/BillOfLandingRepository';

@Injectable()
export class FindMasterBlByHouses extends Command<Promise<string[]>, string[]> {
  public constructor(
    @Inject(TYPES.BILL_OF_LANDING_REPOSITORY)
    private readonly repository: BillOfLandingRepository
  ) {
    super();
  }

  public async internalExecute(houses: string[]): Promise<string[]> {
    return await this.repository.findByHouses(houses);
  }
}
