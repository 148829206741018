
import Component from 'vue-class-component';
import Vue from 'vue';
interface Attributes {
  key: number;
  customData: { title: string; class?: string; description?: string; dayReaming?: any };
  dates: Date;
  dot?: boolean;
  highlight?: any;
  popover?: any;
}

@Component({
  name: 'ScheduleManagement',
  components: { 'v-calendar': require('v-calendar').Calendar }
})
export default class ScheduleManagement extends Vue {
  items: any = [];
  selectedDate: any = null;
  eventText: string[] = ['Zarpe', 'Entrada a almacén', 'Salida de almacén', 'Arribo'];
  milisecondsPerDay = 24 * 60 * 60 * 1000;
  dateToday: Date = new Date();

  get attributes() {
    return this.items.map(
      (item: any, index: number): Attributes => ({
        key: index,
        customData: { title: item.title, description: item.description ?? '', dayReaming: item.dayReaming },
        dates: item.date,
        highlight: {
          color: item.dayReaming <= 0 ? 'red' : item.dayReaming < 8 ? 'green' : 'blue',
          fillMode: 'solid'
        },
        popover: {
          label: item.title
        }
      })
    );
  }

  mounted() {
    for (let index = 0; index < Math.floor(Math.random() * 200 + 30); index++) {
      for (let code = 0; code < Math.floor(Math.random() * 10 + 5); code++) {
        const generatedDay = new Date(2022, Math.floor(Math.random() * 11 + 10), Math.floor(Math.random() * 24));
        this.items.push({
          title: this.eventText[Math.floor(Math.random() * 3)],
          date: generatedDay,
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
          dayReaming: Math.round((generatedDay.getTime() - this.dateToday.getTime()) / this.milisecondsPerDay)
        });
      }
    }
  }

  manageClick(day: any) {
    this.selectedDate = day.attributes;
  }

  getBackground(daysReaming: number) {
    if (daysReaming === 0) {
      return 'text-danger';
    } else if (daysReaming < 7) {
      return 'text-info';
    }
  }
}
