import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { HouseBillOfLandingRepository } from '@/wms/domain/houseBillOfLading/HouseBillOfLadingRepository';
import { Query } from '@/core/cqrs/Query';
import { HouseBillOfLading } from '@/wms/domain/houseBillOfLading/HouseBillOfLading';

@Injectable()
export class SaveHouseBillOfLanding extends Query<Promise<HouseBillOfLading[]>, HouseBillOfLading[]> {
  public constructor(
    @Inject(TYPES.HOUSE_BILL_OF_LADING_REPOSITORY)
    private readonly repository: HouseBillOfLandingRepository
  ) {
    super();
  }

  public async internalExecute(houses: HouseBillOfLading[]): Promise<HouseBillOfLading[]> {
    return this.repository.saveHouseBL(houses);
  }
}
