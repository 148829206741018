import { TypeContact } from '../typeContact/TypeContact';

export class Contact {
  contactId: number;
  typeContact: TypeContact | null;
  phone: string;
  fax: string;
  email: string;
  address: string;
  name: string;
  mainContact: boolean;
  public constructor() {
    this.contactId = 0;
    this.typeContact = null;
    this.phone = '';
    this.fax = '';
    this.email = '';
    this.address = '';
    this.name = '';
    this.mainContact = false;
  }
}
