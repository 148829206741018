
import Component from 'vue-class-component';
import Vue from 'vue';
import { Port } from '@/freight/domain/ports/Port';
import AddPort from './AddPort.vue';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { SearchAllPorts } from '@/freight/application/port/search/SearchAllPorts';
import CustomTable from '@/core/components/shared/CustomTable.vue';
import { CreatePort } from '@/freight/application/port/create/CreatePort';
import { UpdatePort } from '@/freight/application/port/update/UpdatePort';
import { SearchTerritoriesByQueryParameter } from '@/settings/application/uses_cases/views/territories/SearchTerritoriesByQueryParameter';
import { StateManager } from '@/core/statemanager/StateManager';

@Component({
  name: 'PortsCenter',
  components: {
    AddPort,
    CustomTable
  }
})
export default class PortsCenter extends Vue {
  @Inject(TYPES.PORT_SEARCH_ALL)
  readonly searchPorts!: SearchAllPorts;
  @Inject(TYPES.PORT_CREATE)
  readonly createPort!: CreatePort;
  @Inject(TYPES.PORT_UPDATE)
  readonly updatePort!: UpdatePort;
  @Inject(TYPES.VIEW_FIND_TERRITORIES)
  readonly searchTerritoriesByQueryParameter!: SearchTerritoriesByQueryParameter;
  @Inject(TYPES.STATE_MANAGER)
  readonly stateManager!: StateManager;

  //Data
  isLoading = false;
  fullPage = true;
  //Selects

  portLevelSelected: { value: string; description: string } = { value: '', description: '' };
  //Opciones boton agregar
  buttonOptions = {
    variant: 'success',
    buttonTitle: 'general.add',
    icon: 'fa-plus',
    isEdit: false
  };

  form: Port = new Port();

  actions: any = {
    edit: this.editAction,
    delete: this.deleteAction,
    clean: this.cleanForm
  };

  factory = {
    actions: this.factoryMethod,
    find: this.findItemByCode,
    asyncFindOrigin: this.asyncFindOrigin
  };

  items: Port[] = [];
  //Getters
  get tableFields() {
    return [
      {
        key: 'portId',
        label: this.$t('general.id')
      },
      {
        key: 'portName',
        label: this.$t('general.name')
      },
      {
        key: 'portAddress',
        label: this.$t('general.address')
      },
      {
        key: 'levelDescription',
        label: this.$t('general.level')
      },
      {
        key: 'countryName',
        label: this.$t('general.country')
      },
      {
        key: 'cityName',
        label: this.$t('general.city')
      },
      {
        key: 'zipCode',
        label: this.$t('general.zipCode')
      },
      {
        key: 'active',
        label: this.$t('general.active')
      },
      {
        key: 'actions',
        label: this.$t('general.actions'),
        class: 'col-md-2 col-xs-4'
      }
    ];
  }
  //Created
  created() {
    this.getAllPorts();
  }

  //Methods
  factoryMethod() {
    this.isLoading = true;
    this.form.portLevel = this.portLevelSelected != null ? this.portLevelSelected?.value : '';
    this.form.levelDescription = this.portLevelSelected != null ? this.portLevelSelected?.description : '';
    if (!this.buttonOptions.isEdit) {
      this.create(this.parseLocation(this.form));
    } else {
      this.editPort(this.parseLocation(this.form));
    }
  }

  async create(port: Port) {
    await this.createPort
      .execute(port)
      .then(() => {
        this.isLoading = false;
        this.cleanForm();
        this.getAllPorts();
      })
      .catch(error => {
        this.isLoading = false;
        throw new Error(`${error}`);
      });
  }

  async editPort(port: Port) {
    await this.updatePort
      .execute(port)
      .then(() => {
        this.isLoading = false;
        this.cleanForm();
        this.getAllPorts();
      })
      .catch(error => {
        this.isLoading = false;
        throw new Error(`${error}`);
      });
  }

  deleteAction(port: Port) {
    port.active = false;
    this.updatePort
      .execute(port)
      .then(() => {
        this.isLoading = false;
        this.getAllPorts();
      })
      .catch(error => {
        this.isLoading = false;
        throw new Error(`${error}`);
      });
  }

  editAction(port: Port) {
    this.findItemByCode(port);
  }

  cleanForm() {
    this.form = new Port();
    this.buttonOptions = {
      isEdit: false,
      variant: 'success',
      buttonTitle: 'general.add',
      icon: 'fa-plus'
    };
  }

  async findItemByCode(portData?: any) {
    if ('target' in portData) portData = this.form;
    const res = this.items.filter((item: any) => {
      return item.portId === portData.portId;
    });

    if (res.length > 0) {
      this.form = res[0];
      await this.asyncFindOrigin(this.form.cityName);
      this.form.origin = this.stateManager.state.dataTerritories[0];
      this.buttonOptions = {
        isEdit: true,
        variant: 'secondary',
        buttonTitle: 'general.edit',
        icon: 'fa-save'
      };
    }
  }

  getAllPorts() {
    this.isLoading = true;
    this.searchPorts
      .execute()
      .then((response: any) => {
        this.isLoading = false;
        this.items = response;
      })
      .catch(error => {
        this.isLoading = false;
        throw new Error(`${error}`);
      });
  }

  parseLocation(port: Port) {
    //Parser
    if (port.origin != null) {
      port.country = port.origin.country ? port.origin.country : port.country;
      port.countryName = port.origin.countryName ? port.origin.countryName : port.countryName;
      port.state = port.origin.state ? port.origin.state : port.state;
      port.stateName = port.origin.stateName ? port.origin.stateName : port.stateName;
      port.city = port.origin.city ? port.origin.city : port.city;
      port.cityName = port.origin.cityName ? port.origin.cityName : port.cityName;
      port.zipCode = port.origin.zipCode ? port.origin.zipCode : port.zipCode;
    }
    return port;
  }

  async asyncFindOrigin(query: string) {
    if (query.length >= 3) {
      await this.searchTerritoriesByQueryParameter
        .execute(query.toUpperCase())
        .then((response: any) => {
          this.stateManager.state.dataTerritories = response;
        })
        .catch((error: any) => {
          throw new Error(`${error}`);
        });
    }
  }
}
