
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { WmsfOrderReferences } from '@/courier/domain/WmsfOrderReferences/WmsfOrderReferences';
import { WmsQuotationOrder } from '@/courier/domain/wmsQuotationOrder/WmsQuotationOrder';

@Component({
  name: 'ServicesList',
  components: {}
})
export default class ServicesList extends Vue {
  @Prop() items!: WmsfOrderReferences[];
  @Prop() actions!: any;
  @Prop() form!: WmsQuotationOrder;
  @Prop() forView!: boolean;
  @Prop() isQuotation!: boolean;

  //Funcion invocada para validar un input
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  get activeItems() {
    return this.items.filter(item => item.active);
  }
}
