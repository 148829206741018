
import { showAlert } from '@/core/plugins/Notifications';
import { ActivityTransactional } from '@/tracking/domain/activitiesTransactional/ActivityTransactional';
import { EventTransactional } from '@/tracking/domain/eventsTransactional/EventTransactional';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import EventModal from '../EventsModal/EventModal.vue';

@Component({
  name: 'Timeline',
  components: { EventModal }
})
export default class Timeline extends Vue {
  //Data
  activities: ActivityTransactional[] = [];
  eventData: EventTransactional | null = null;
  hover = false;
  eventCode = 0;
  eventName = '';

  //Prop
  @Prop({ type: Array, required: true }) readonly items!: EventTransactional[];
  @Prop({ required: true }) readonly master!: PreRegister;
  @Prop({ type: Number, required: true }) readonly masterNumber!: number;
  @Prop({ type: String, required: true }) readonly lineSerial!: string;
  @Prop({ type: Array, required: true }) readonly houses!: any[];
  @Prop({ type: String, required: false }) readonly typeOperation!: string;
  @Prop({ type: String, required: false }) readonly typeTransport!: string;
  @Prop({ type: String, required: false }) readonly preRegisterType!: string;

  get sortItems() {
    return this.items.sort((a, b) => a.sequence - b.sequence);
  }

  //Methods
  updateEventExec() {
    this.eventCode = 0;
  }

  //Copy
  processEvent(item: EventTransactional, indexItem: number) {
    try {
      if (indexItem !== 0) {
        if (this.sortItems[indexItem - 1].state !== 'EXECUTED') {
          showAlert(
            'warning',
            'Advertencia',
            `Se debe ejecutar el evento ${this.sortItems[indexItem - 1].description}`
          );
          return;
        }
      }

      this.eventData = item;
      this.activities = item.activities;
      this.eventCode = item.id;
      this.eventName = item.description;
      this.$bvModal.show(`modal-event-${item.id}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  stateClass(state: string) {
    let className = '';

    switch (state) {
      case 'PENDING':
        className = 'fa fa-close pending';
        break;

      case 'CURRENT':
        className = 'fa fa-refresh current';
        break;

      case 'EXECUTED':
        className = 'fa fa-check ready';
        break;

      default:
        className = 'fa fa-close pending';
        break;
    }

    return className;
  }
}
