import { Injectable } from '@/core/di/Injectable';
import { LoginImageConfigRepository } from '@/settings/domain/loginImageConfig/LoginImageConfigRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { LoginImageConfig } from '@/settings/domain/loginImageConfig/LoginImageConfig';
import { http } from '@/core/http/HttpFetch';

@Injectable()
export class LoginImageConfigService implements LoginImageConfigRepository {
  private readonly API_BASE_URI: string = process.env.VUE_APP_URL_TOKEN ?? '';

  private readonly API_LOGIN_IMAGE_SAVE_CONFIG: string =
    this.API_BASE_URI + localStorageModule().auth.routes.API_PERSONALIZATION_TYPES_CONFIG_SAVE;

  async saveConfig(config: LoginImageConfig): Promise<LoginImageConfig> {
    return await http.post<LoginImageConfig>(this.API_LOGIN_IMAGE_SAVE_CONFIG, JSON.stringify(config));
  }
}
