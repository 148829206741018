import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { CurrencyFactor } from '@/settings/domain/currencyfactor/CurrencyFactor';
import { CurrencyFactorRepository } from '@/settings/domain/currencyfactor/CurrencyFactorRepository';

@Injectable()
export class CurrencyFactorFindRateByDate extends Query<Promise<CurrencyFactor>, any> {
  public constructor(
    @Inject(TYPES.CURRENCYFACTOR_REPOSITORY)
    private readonly currencyFactorRepository: CurrencyFactorRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(payload: any): Promise<CurrencyFactor> {
    return await this.currencyFactorRepository.findCurrencyFactorRateByDate(payload);
  }
}
