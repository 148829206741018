
import { Notification } from '@/settings/domain/notification/Notification';
import Vue from 'vue';
import Component from 'vue-class-component';
import localStorageVariables from '../config/LocalStorageVariables';
import SocketManager from '../config/SocketManager';
import { ConsoleLogger } from '../consolelogger/ConsoleLogger';

@Component({
  name: 'app'
})
export default class App extends Vue {
  show = true;
  mounted() {
    this.$nextTick(() => this.timeOut);
    // this.notificationsOn();
  }
  get timeOut() {
    return setTimeout(() => {
      this.show = !this.show;
    }, 1000);
  }

  destroyed() {
    clearTimeout(this.timeOut);
  }

  notificationsOn() {
    const socket = SocketManager.getInstance();

    const companyId = localStorage.getItem(localStorageVariables.BUSINESS_ID);

    if (!companyId || companyId === '') {
      return;
    }

    new ConsoleLogger(window).log(['companyId', companyId].toString());

    socket.on('connect', () => {
      new ConsoleLogger(window).log('Socket connected');
      new ConsoleLogger(window).log('Joining to room');
      socket.emit('join', companyId);
    });

    socket.on('joined', () => {
      new ConsoleLogger(window).log('Joined to room');
    });

    socket.on('notification', (data: Notification) => {
      this.$toast(data.title);
    });
  }
}
