import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { PricingZoneRepository } from '@/settings/domain/PricingZone/PricingZoneRepository';
import { RequestPricelistPayload } from '@/core/helper/RequestPricelistPayload';
import { PricingZoneResponse } from '@/settings/domain/pricingZoneResponse/PricingZoneResponse';

@Injectable()
export class PricingZoneFindByOriginAndDestination extends Query<
  Promise<PricingZoneResponse>,
  RequestPricelistPayload
> {
  public constructor(
    @Inject(TYPES.PRICING_ZONE_REPOSITORY)
    private readonly pricingZoneRepository: PricingZoneRepository
  ) {
    super();
  }
  async internalExecute(payload: RequestPricelistPayload): Promise<PricingZoneResponse> {
    return await this.pricingZoneRepository.findByOriginAndDestination(payload);
  }
}
