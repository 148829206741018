
import ReferenceSearch from '@/core/components/shared/ReferenceSearch.vue';
import StoreSearch from '@/core/components/shared/StoreSearch.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { StockMovementForm } from './StockMovements.vue';

@Component({
  name: 'FilterForm',
  components: {
    ReferenceSearch,
    StoreSearch
  }
})
export default class FilterForm extends Vue {
  @Prop() form!: StockMovementForm;
  @Prop() search!: (form: StockMovementForm) => void;

  get isDisabled() {
    return !this.form.store;
  }

  get localYear(): number {
    return this.form.year;
  }
  set localYear(value: number) {
    this.$emit('update:form', { ...this.form, year: value });
  }
  get localIMonth(): number {
    return this.form.iMonth;
  }
  set localIMonth(value: number) {
    this.$emit('update:form', { ...this.form, iMonth: value });
  }
  get localFMonth(): number {
    return this.form.fMonth;
  }
  set localFMonth(value: number) {
    this.$emit('update:form', { ...this.form, fMonth: value });
  }

  submitForm = () => {
    try {
      this.search(this.form);
    } catch (err) {
      throw new Error(`error: ${err}`);
    }
  };
}
