import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Warehouse } from '@/settings/domain/warehouse/Warehouse';
import { WarehouseRepository } from '@/settings/domain/warehouse/WarehouseRepository';

@Injectable()
export class WarehouseFindAll extends Query<Promise<Warehouse[]>> {
  public constructor(
    @Inject(TYPES.WAREHOUSE_REPOSITORY)
    private readonly warehouseRepository: WarehouseRepository,

    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(): Promise<Warehouse[]> {
    const dataWarehouse = await this.warehouseRepository.findAll();
    this.stateManager.patch({ dataWarehouse });
    return this.stateManager.state.dataWarehouse;
  }
}
