const format = ({ value, currency }: { value: string; currency: string }) => {
  return Number(value).toLocaleString('ES-es', {
    style: 'currency',
    currency: currency
  });
};

export const currencyFormatter = {
  format
};
