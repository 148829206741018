
import Vue from 'vue';
import Component from 'vue-class-component';
import { getLocalstorageItem } from '@/core/config/LocalStorageVariables';
import { Prop } from 'vue-property-decorator';
import { RoleDTO } from '@/settings/domain/role/RoleDTO';
import VJstree from 'vue-jstree';
import { Menu } from '@/settings/domain/menu/menu';

@Component({
  name: 'CreateRole',
  components: { VJstree }
})
export default class CreateRole extends Vue {
  isLoading = false;
  fullPage = true;

  //#region Listas
  // menuArray: Menu[] = [];
  // roleMenuArray: Menu[] = [];

  @Prop() form!: RoleDTO;
  @Prop() save!: () => Promise<void>;
  @Prop() reset!: () => Promise<void>;
  @Prop() roleMenuArray!: Menu[];

  formInvite = {
    email: '',
    role: 0
  };

  get roleTypes() {
    return ['ADMIN', 'USER'];
  }

  get permissions() {
    return ['CREATE', 'READ', 'UPDATE', 'DELETE'];
  }

  get fields() {
    return [
      {
        field: 'id',
        label: 'ID'
      },
      {
        field: 'name',
        label: this.$t('general.role')
      },
      {
        field: 'type',
        label: this.$t('general.type')
      },
      {
        field: 'actions',
        label: this.$t('general.actions')
      }
    ];
  }

  get namespace() {
    return getLocalstorageItem('BUSINESS_ID');
  }
}
