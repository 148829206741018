import { TYPES } from '@/core/config/Types';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Query } from '@/core/cqrs/Query';
import { StateManager } from '@/core/statemanager/StateManager';
import { Process } from '@/tracking/domain/processtype/Process';
import { ProcessRepository } from '@/tracking/domain/processtype/ProcessRepository';

@Injectable()
export class ProcessFindById extends Query<Promise<Process[]>, string> {
  public constructor(
    @Inject(TYPES.PROCESS_TYPE_REPOSITORY)
    private readonly processRepository: ProcessRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(params: any): Promise<Process[]> {
    const dataProcess = await this.processRepository.findById(params);
    this.stateManager.patch({ dataProcess });
    return this.stateManager.state.dataProcess;
  }
}
