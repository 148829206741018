import { VueConstructor } from 'vue';
import { TYPES } from '@/core/config/Types';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import VueFormWizard from 'vue-form-wizard';
import router from '@/core/router';
import store from '@/core/store';
import i18n from '@/core/plugins/i18n';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import Multiselect from 'vue-multiselect';
import VueSweetalert2 from 'vue-sweetalert2';
import Breadcrumbs from '@/core/components/dashboard/BreadCrumbs.vue';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import Toasted from 'vue-toasted';
import VueFeather from 'vue-feather';
import ZoomOnHover from 'vue-zoom-on-hover';
import VueLoading from 'vue-loading-overlay';
import VueEasytable from 'vue-easytable';
import Toast from 'vue-toastification';
require('v-calendar');
require('vue-good-table').VueGoodTable;
@Injectable()
export class Application {
  constructor(@Inject(TYPES.VUE) private readonly vue: VueConstructor) {}
  create(app: VueConstructor) {
    this.vue.config.productionTip = false;
    this.vue.use(VueFormWizard);
    this.vue.use(BootstrapVue);
    this.vue.use(BootstrapVueIcons);
    this.vue.use(VueSweetalert2);
    this.vue.use(ZoomOnHover);
    this.vue.use(VueEasytable);
    this.vue.use(VueLoading, { color: '#4466f2', backgroundColor: '#313131' });
    this.vue.use(Toasted, {
      theme: 'bubble',
      duration: 1500,
      position: 'top-right'
    });
    this.vue.use(require('vue-chartist'));
    this.vue.use(Toast, {
      position: 'top-right',
      timeout: 2000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: 'button',
      icon: true,
      rtl: false,
      transition: 'Vue-Toastification__bounce',
      maxToasts: 10,
      newestOnTop: true
    });
    this.vue.component('Multiselect', Multiselect);
    this.vue.component('Breadcrumbs', Breadcrumbs);
    this.vue.component('CustomTableN', CustomTableN);
    this.vue.component(VueFeather.name, VueFeather);
    this.vue.component('loading', VueLoading);
    return new this.vue({
      router,
      store,
      i18n,
      render: create => create(app)
    }).$mount('#app');
  }
}
