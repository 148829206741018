
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import CreateTimelineFF from '@/tracking/infrastructure/ui/components/ff/timeline/CreateTimelineFF.vue';
import TimelineComponentFF from '@/tracking/infrastructure/ui/components/ff/timeline/TimelineComponentFF.vue';
import { TimelineFindByProcessType } from '@/tracking/application/uses_cases/timeline/search/findtimelinebyprocesstype/TimelineFindByProcessType';
import TableTimeline from '@/tracking/infrastructure/ui/components/shared/TableTimeline.vue';
import TableEvent from '@/tracking/infrastructure/ui/components/shared/TableEvent.vue';
import TableActivity from '@/tracking/infrastructure/ui/components/shared/TableActivity.vue';
import Swal from 'sweetalert2';
import i18n from '@/core/plugins/i18n';
import { UpdateTimeline } from '@/tracking/application/uses_cases/timeline/update/UpdateTimeline';
import { EventsFindByProcessType } from '@/tracking/application/uses_cases/events/search/EventsFindByProcessType';
import Draggable from 'vuedraggable';
import Linechart from '@/core/components/dashboard/chartjs/Linechart.vue';
import { FindChartValuesByTimeLine } from '@/tracking/application/uses_cases/timeline/search/findchartvaluesbytimeline/FindChartValuesByTimeLine';

@Component({
  name: 'ManageTimelineFF',
  components: {
    TableActivity,
    TableEvent,
    TableTimeline,
    TimelineComponentFF,
    CreateTimelineFF,
    Draggable,
    Linechart
  }
})
export default class AppManageTimelineFF extends Vue {
  @Inject(TYPES.FINDBYPROCESSTYPE_TIMELINE)
  readonly timelineFindByProcessType!: TimelineFindByProcessType;
  @Inject(TYPES.UPDATE_TIMELINE)
  readonly updateTimeline!: UpdateTimeline;
  @Inject(TYPES.FINDEVENTSBYPROCESSTYPE_EVENTS)
  readonly eventsFindByProcessType!: EventsFindByProcessType;
  @Inject(TYPES.FIND_CHARTVALUESTIMELINE)
  readonly findChartValuesByTimeLine!: FindChartValuesByTimeLine;

  //GRAPH
  target = i18n.t('general.timeline');
  isLoading = false;
  fullPage = true;
  dataDummy = {
    profit: [500, 1000, 2000, 1000, 0, 700, 100],
    income: [1000, 1000, 3000, 1500, 1000, 1500, 800],
    bill: [500, 2000, 1000, 500, 1000, 800, 700],
    days: [1, 3, 5, 7, 9, 13, 16]
  };
  //CSS EFECTS
  selectedEvent = 0;
  selectedTimeLine = 0;
  openEvents: any = [];
  openTimeLines: any = [];
  myArray: any = {
    newIndex: 0,
    oldIndex: 0
  };
  componentKey = 0;
  //DATA
  timelineList = [];
  processEventList = [];
  processEventListModal = [];
  private formLineTime: any = {
    isTouched: false,
    timelineSelected: [],
    timelineListFilter: [],
    dataProcessType: [],
    dataTimeline: [],
    validationButtonSave: false,
    timelineListByIndex: [],
    eventsListByIndex: [],
    eventsListLink: [],
    activitiesListLink: [],
    valueItem: '',
    edit: null,
    selectEvent: [],
    selectAll: false
  };
  //Refs
  $refs!: {
    mdCreateModal: HTMLFormElement;
    mdAddEvent: HTMLFormElement;
    dropdownTimeline: HTMLFormElement;
  };
  //Hook
  async mounted() {
    await this.getTimelineSkeleton();
    this.$nextTick(() => this.eventsProcessType);
  }
  //Computed
  get eventsProcessType() {
    this.eventsFindByProcessType.execute(3).then((response: any) => {
      this.processEventList = response;
    });
    return true;
  }
  //method
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  async getTimelineSkeleton() {
    this.isLoading = true;
    this.timelineList = [];
    await this.timelineFindByProcessType
      .execute(3)
      .then((response: any) => {
        this.isLoading = false;
        const res = response.reverse();
        this.timelineList = res;
        this.formLineTime.timelineListFilter = res;
        for (const index in res) {
          if (index === '0') {
            this.$set(res[0], 'visible', true);
            this.loadChartData(res[index].id);
            this.getTimelineDetail(res[0]);
          }
        }
        this.closeModal();
      })
      .catch(error => {
        this.isLoading = false;
        this.timelineList = [];
        throw new Error(error);
      });
    this.forceRerender();
  }
  async filterTimeline() {
    await this.getTimelineSkeleton();
    const result: any = this.timelineList.filter((data: any) => data.id === this.formLineTime.timelineSelected.id);
    this.timelineList = [];
    this.timelineList = result;
    this.$refs.dropdownTimeline.hide(true);
    let time: any;
    for (time of this.timelineList) {
      this.$set(time, 'visible', true);
      this.loadChartData(time.id);
      this.getTimelineDetail(time);
    }
  }
  closeModal() {
    this.$refs['mdCreateModal'].hide();
  }
  getTimelineDetail(data: any) {
    this.formLineTime.timelineListByIndex = data;
    this.formLineTime.eventsListByIndex = data.events;
    this.formLineTime.valueItem = 'timeline';
    this.formLineTime.eventsListByIndex.forEach((item: any, index: number) => {
      if (index === 0) {
        this.$set(item, 'hover', true);
      } else {
        this.$set(item, 'hover', false);
      }
    });
    this.formLineTime.eventsListByIndex.sort((a: any, b: any) => a.sequence - b.sequence);
    const arrayEvents: [] = [];
    for (let i = 0; i < this.processEventList.length; i++) {
      let igual = false;
      for (let j = 0; j < data.events.length && !igual; j++) {
        if (this.processEventList[i]['description'] === data.events[j]['description']) {
          igual = true;
        }
      }
      if (!igual) arrayEvents.push(this.processEventList[i]);
    }
    this.processEventListModal = arrayEvents;
  }
  deleteTimeline(dataTimeline: any) {
    Swal.fire({
      title:
        this.$i18n.t('general.areyousuretodeletethis') +
        ' ' +
        this.$i18n.t('general.timeline') +
        `(${dataTimeline.description})` +
        this.$i18n.t('general.squestion'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      reverseButtons: true,
      confirmButtonText: `${i18n.t('general.delete')}`,
      cancelButtonText: `${i18n.t('general.cancel')}`,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    }).then(result => {
      if (result.isConfirmed) {
        const payloadUpdate: any = {
          id: dataTimeline.id,
          description: dataTimeline.description,
          typeTransport: dataTimeline.typeTransport,
          typeOperation: dataTimeline.typeOperation,
          processType: 3,
          active: false,
          events: dataTimeline.events
        };
        this.isLoading = true;
        this.updateTimeline
          .execute(payloadUpdate)
          .then((response: any) => {
            this.isLoading = false;
            if (response.ok) {
              this.getTimelineSkeleton();
              this.formLineTime.valueItem = '';
            }
            return response;
          })
          .catch(error => {
            this.isLoading = false;
            throw new Error(error);
          });
      } else {
        return false;
      }
    });
  }
  addEvents(dataTimeline: any) {
    if (this.formLineTime.selectEvent.length) {
      let position = 1;
      const eventlist: any = [];
      this.formLineTime.selectEvent.forEach((event: any) => {
        event.eventReference = event.id;
        event.id = null;
        //SECUENCES OF EVENTS
        event.sequence = dataTimeline.events.length + position;
        position += 1;
        for (const item of event.activities) {
          item.activityId = null;
          item.eventReference = event.eventReference;
        }
        eventlist.push(event);
      });
      const payloadUpdate: any = {
        id: dataTimeline.id,
        description: dataTimeline.description,
        typeTransport: dataTimeline.typeTransport,
        typeOperation: dataTimeline.typeOperation,
        processType: 3,
        active: true,
        events: dataTimeline.events.concat(eventlist)
      };
      this.isLoading = true;
      this.updateTimeline
        .execute(payloadUpdate)
        .then((response: any) => {
          this.isLoading = false;
          if (response.ok) {
            this.formLineTime.eventsListByIndex = [];
            this.formLineTime.eventsListByIndex = payloadUpdate.events;
            this.getTimelineSkeleton();
            this.getTimelineDetail(payloadUpdate);
            this.loadChartData(dataTimeline.id);
            this.formLineTime.selectEvent = [];
            this.$refs['mdAddEvent'].hide();
          }
          return response;
        })
        .catch(error => {
          this.isLoading = false;
          throw new Error(error);
        });
    }
  }
  //CSS EFECTS LOGIC
  openCloseTree(event: any) {
    let opened: boolean;
    if (!this.openEvents.includes(event.id)) {
      this.openEvents = [];
      this.openEvents.push(event.id);
      opened = true;
    } else {
      this.openEvents.splice(this.openEvents.indexOf(event.id), 1);
      opened = false;
    }
    return opened;
  }
  //DRAGGABLE EFFECTS TO ORDER TREE EVENTS v2.0
  onEndDrag(events: any, dataTimeline: any) {
    events.forEach((element: any, index: number) => {
      element.sequence = index + 1;
    });
    dataTimeline.events = events;
    this.updateTimeline
      .execute(dataTimeline)
      .then((response: any) => {
        this.$nextTick(() => this.loadChartData(dataTimeline.id));
        this.getTimelineDetail(dataTimeline);
        return response;
      })
      .catch(error => {
        throw new Error(error);
      });
  }
  //END DRAGGABLE LOGIC
  sumArray(array: any) {
    let summation = 0;
    array.forEach((element: any) => {
      summation += element;
    });
    return summation;
  }
  forceRerender() {
    this.componentKey += 1;
  }
  loadChartData(timelineId: number) {
    const incomeArray: any = [];
    const billArray: any = [];
    const profitArray: any = [];
    const daysArray: any = [];
    this.isLoading = true;
    this.findChartValuesByTimeLine
      .execute(timelineId)
      .then((response: any) => {
        this.isLoading = false;
        response.forEach((element: any) => {
          incomeArray.push(element[2]);
          billArray.push(element[3]);
          profitArray.push(element[4]);
          daysArray.push(element[6]);
        });
        this.dataDummy = {
          profit: profitArray,
          income: incomeArray,
          bill: billArray,
          days: daysArray
        };
        this.componentKey += 3;
      })
      .catch(error => {
        this.isLoading = false;
        throw new Error(error);
      });
  }
}
