
import Vue from 'vue';
import Component from 'vue-class-component';
import LanguageSwitcher from '@/core/components/LanguageSwitcher.vue';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { ResetPassword } from '@/settings/application/uses_cases/user/update/ResetPassword';

@Component({
  name: 'ForgetPassword',
  components: {
    LanguageSwitcher
  }
})
export default class AppForgetPassword extends Vue {
  @Inject(TYPES.USER_RESET_PASSWORD)
  private readonly resetPassword!: ResetPassword;

  //Data
  email = '';
  isLoading = false;
  //Refs
  $refs!: {
    forgetEmail: HTMLInputElement;
  };
  //Hook
  created() {
    this.$nextTick(() => this.$refs.forgetEmail.focus());
  }
  //Methods
  public getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  public async sendEmail() {
    this.isLoading = true;
    try {
      await this.resetPassword.internalExecute(this.email);
      this.$swal({
        title: this.$t('general.success').toString(),
        text: this.$t('general.resetPasswordMessage').toString(),
        icon: 'success'
      });
      this.email = '';
      this.$router.push('/auth/login');
    } catch (error) {
      this.$swal({
        title: 'Ups!',
        text: this.$t('error.resetPasswordMessage').toString(),
        icon: 'error'
      });
    } finally {
      this.isLoading = false;
    }
  }
}
