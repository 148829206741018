
import Vue from 'vue';
import Component from 'vue-class-component';
import CreateOrderN from '@/courier/infrastructure/ui/components/order/CreateOrderN/CreateOrderN.vue';
@Component({
  name: 'CreateQuotationN',
  components: { CreateOrderN }
})
export default class CreateQuotationN extends Vue {
  orderType = 3;
}
