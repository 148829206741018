import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { Product } from '@/wms/domain/catalogs/product/Product';
import { ProductRepository } from '@/wms/domain/catalogs/product/ProductRepository';

@Injectable()
export class ProductServices implements ProductRepository {
  async create(product: Product): Promise<Product> {
    try {
      return await http._postERP<Product>(`${process.env.VUE_APP_ROUTE_API_ERP}/api/producto`, JSON.stringify(product));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async update(product: Product): Promise<Product> {
    try {
      return await http._putERP<Product>(`${process.env.VUE_APP_ROUTE_API_ERP}/api/producto`, JSON.stringify(product));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findBySKU(reference: string): Promise<Product[]> {
    try {
      return await http._getERP<Product[]>(`${process.env.VUE_APP_ROUTE_API_ERP}/api/producto/referencia/${reference}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
