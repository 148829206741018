
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ButtonOptions } from '@/settings/domain/buttonOptions/ButtonOptions';
import { CommodityClass } from '@/settings/domain/commodityClass/CommodityClass';
@Component({
  name: 'AddCommodityClassForm',
  components: {}
})
export default class AddCommodityClassForm extends Vue {
  //Data
  isLoading = false;

  //Declaracion de props
  @Prop() readonly buttonOptions!: ButtonOptions;
  @Prop() readonly form!: CommodityClass;
  @Prop() readonly actions!: any;

  //Funcion ejecutada para validar un input
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }): any {
    return dirty || validated ? valid : null;
  }
}
