import { SettingCustomerOffers } from '@/wms/domain/catalogs/pricingEngine/SettingOffers/SettingCustomerOffers';
import { SettingSupplierOffers } from '@/wms/domain/catalogs/pricingEngine/SettingOffers/SettingSupplierOffers';
import { SettingZoneOffers } from '@/wms/domain/catalogs/pricingEngine/SettingOffers/SettingZoneOffers';

export class SettingOffers {
  public settingCustomerOffersList: SettingCustomerOffers[];
  public settingSupplierOffersList: SettingSupplierOffers[];
  public settingZoneOffersList: SettingZoneOffers[];
  constructor() {
    this.settingCustomerOffersList = [];
    this.settingSupplierOffersList = [];
    this.settingZoneOffersList = [];
  }
}
