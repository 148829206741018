import { Port } from '../ports/Port';

export class CommodityType {
  id: number;
  code: string;
  description: string;
  active: boolean;
  createAt: string;
  updateAt: string;
  //keys for config layout
  layoutRackId: number;
  layoutId: number;
  storeId: number;
  zoneId: string;
  layoutCommodityId: number;
  rackId: number;
  level: number;
  locationId: number;

  tariffCode?: string;
  tariffItemPercentage?: number;
  port: Port | null;

  public constructor() {
    this.id = 0;
    this.code = '';
    this.description = '';
    this.active = true;
    this.createAt = '';
    this.updateAt = '';

    this.layoutRackId = 0;
    this.layoutId = 0;
    this.storeId = 0;
    this.zoneId = '';

    this.layoutCommodityId = 0;
    this.rackId = 0;
    this.level = 0;
    this.locationId = 0;

    this.tariffCode = '';
    this.tariffItemPercentage = 0;
    this.port = null;
  }
}
