
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { PreRegisterFindWithBulks } from '@/wms/application/preRegister/search/PreRegisterFindWithBulks';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import Timeline from '@/courier/infrastructure/ui/components/order/TimelineN/Timeline.vue';
import VueBarcode from 'vue-barcode';
import TimelineComponentFF from '@/tracking/infrastructure/ui/components/ff/timeline/TimelineComponentFF.vue';
import AppTimelineComponentFF from '@/tracking/infrastructure/ui/components/ff/timeline/TimelineComponentFF.vue';
import { PreRegisterFindByDateAndType } from '@/wms/application/preRegister/search/PreRegisterFindByDateAndType';
import { PreRegisterTypeEnum } from '@/wms/domain/preRegister/PreRegisterTypeEnum';
import { getFormattedDate } from '@/core/plugins/DateFormatter';
import { OrderFindByDateRange } from '@/courier/application/uses_cases/order/search/OrderFindByDateRange';
import { Order } from '@/courier/domain/order/Order';
import { FindChartValuesByTimeLine } from '@/tracking/application/uses_cases/timeline/search/findchartvaluesbytimeline/FindChartValuesByTimeLine';
import Linechart from '@/core/components/dashboard/chartjs/Linechart.vue';
import { Timeline as TypeTimeline } from '@/tracking/domain/timeline/Timeline';
import BillsAndProfit from '@/wms/infrastructure/ui/historyPanel/components/BillsAndProfit.vue';
import AssociatedDocumentsToMaster from '@/wms/infrastructure/ui/historyPanel/components/AssociatedDocumentsToMaster.vue';
import DocumentListModal from '@/wms/infrastructure/ui/historyPanel/components/DocumentListModal.vue';
import { DocumentHeader } from '@/courier/domain/DocumentsV2/Documents_V2';
import { TimelineTransactional } from '@/tracking/domain/timelineTransactional/TimelineTransactional';
import { PreRegisterFindByPk } from '@/wms/application/preRegister/search/PreRegisterFindByPk';
import { Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { ActivityTransactional } from '@/tracking/domain/activitiesTransactional/ActivityTransactional';
import CustomButton from '@/wms/infrastructure/ui/historyPanel/components/CustomButton.vue';
import TimelineHistoryPanel from '@/wms/infrastructure/ui/historyPanel/components/Timeline.vue';
import { Bulk } from '@/wms/domain/bulk/Bulk';
import { OrderLine } from '@/courier/domain/orderline/OrderLine';
import DeliveryGuide from './components/DeliveryGuide.vue';
import DeliveryCargoDetail from './components/DeliveryCargoDetail.vue';
import { PreRegisterFindForTraceability } from '@/wms/application/preRegister/search/PreRegisterFindForTraceabilityPanel';
import { PreRegisterForTraceabilityPanel } from '@/wms/domain/preRegister/PreRegisterForTraceabilityPanel';
import localStorageVariables from '@/core/config/LocalStorageVariables';

interface ModuleOptions {
  type: string;
  description: string;
  module: string;
}

interface PreRegisterInfo {
  id: string;
  name: string;
  masterNumber: string;
  shipmentNumber: number;
  operationType: string | undefined;
  transportType: string | undefined;
}

@Component({
  name: 'HistoryPanel',
  components: {
    Timeline,
    VueBarcode,
    TimelineComponentFF,
    AppTimelineComponentFF,
    Linechart,
    BillsAndProfit,
    AssociatedDocumentsToMaster,
    DocumentListModal,
    TimelineHistoryPanel,
    DeliveryGuide,
    DeliveryCargoDetail
  },
  filters: {
    toCurrency(value: number) {
      if (typeof value !== 'number') {
        return value;
      }
      const formatter = new Intl.NumberFormat(`USD`);
      return formatter.format(value);
    }
  }
})
export default class HistoryPanel extends Vue {
  @Inject(TYPES.PRE_REGISTER_FINDALL_WITH_BULKS)
  readonly findPreRegisters!: PreRegisterFindWithBulks;
  @Inject(TYPES.PRE_REGISTER_FINDALL_BY_DATE_AND_TYPE)
  readonly findDocumentsByDateAndType!: PreRegisterFindByDateAndType;
  @Inject(TYPES.FINDBYDATERANGE_ORDER)
  readonly orderFindByDateRange!: OrderFindByDateRange;
  @Inject(TYPES.FIND_CHARTVALUESTIMELINE)
  readonly findChartValuesByTimeLine!: FindChartValuesByTimeLine;
  @Inject(TYPES.PRE_REGISTER_FIND_BY_PK)
  readonly findByPk!: PreRegisterFindByPk;
  @Inject(TYPES.PRE_REGISTER_FIND_FOR_TRACEABILITY)
  readonly findForTraceability!: PreRegisterFindForTraceability;

  operationalProfilesStore: string[] = JSON.parse(
    localStorage.getItem(localStorageVariables.OPERATIONAL_PROFILES) as string
  );
  documentListSelected: DocumentHeader[] | null = null;
  filteredPreRegister = new PreRegister();
  //Listas
  preRegisterList: PreRegister[] = [];
  preRegisterListForTraceability: PreRegisterForTraceabilityPanel[] = [];
  moduleOptions: ModuleOptions[] = [
    {
      type: PreRegisterTypeEnum.SHIPMENT,
      description: `${this.$t('general.freight')}`,
      module: 'freight'
    },
    {
      type: 'WMS',
      description: `${this.$t('general.WMS')}`,
      module: 'wms'
    },
    {
      type: PreRegisterTypeEnum.COURIER_ORDER,
      description: `${this.$t('general.courier')}`,
      module: 'courier'
    }
  ].filter(item => {
    return this.operationalProfilesStore.includes(item.module);
  });
  filterOrderType: ModuleOptions[] = [
    {
      type: 'PR',
      description: `${`${this.$t('general.orders')}`}`,
      module: ''
    },
    {
      type: 'CT',
      description: `${`${this.$t('general.assistedOrder')}`}`,
      module: ''
    },
    {
      type: 'QR',
      description: `${`${this.$t('general.quotations')}`}`,
      module: ''
    }
  ];
  //Data
  isLoading = false;
  booleanOrder = false;
  searched = false;
  showSearche = true;
  renderComponent = true;
  chartData: {
    profit: number[];
    income: number[];
    bill: number[];
    days: number[];
    labelDescription: { description: string }[];
  } = {
    profit: [],
    income: [],
    bill: [],
    days: [],
    labelDescription: []
  };
  axisy2 = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  selectedEvent = 0;
  selectedTimelineConfig: any | null = null;
  selectedTimeLine: TypeTimeline | null = null;
  //Selects
  selectedfilterOrders: ModuleOptions | null = null;
  //Filters
  filter: {
    from: string;
    to: string;
    type: ModuleOptions | null;
  } = {
    from: getFormattedDate(new Date()),
    to: getFormattedDate(new Date()),
    type: null
  };

  /**
   * Propiedades para la tabla de preregistros
   */
  columnWidthResizeOption = {
    enable: true,
    minWidth: 10
  };
  cellStyleOption = {
    headerCellClass: ({ column }: any) => {
      if (column.key === 'origindata' || column.key === 'adresseedetails') {
        return 'table-header-cell-class';
      }
    }
  };
  sortOption = {
    multipleSort: true,
    sortChange: (params: PreRegister) => {
      this.sortChange(params);
    }
  };

  sortChange(params: PreRegister) {
    this.preRegisterList.sort((a: any, b: any): any => {
      if (params.number) {
        if (params.number === 'asc') {
          return a.number - b.number;
        } else if (params.number === 'desc') {
          return b.number - a.number;
        } else {
          return 0;
        }
      }
    });
  }

  showModal = false;
  showModalDetailGuide = false;
  serialList: Bulk[] | OrderLine[] = [];
  /**
   * Getter para obtener los parametros de la URL
   */
  get urlParams() {
    return {
      query: this.$route.query
    };
  }
  get columnsForTraceability() {
    const columns: any[] = [
      {
        field: '',
        key: 'typeTransport',
        title: this.$t('general.palletsFactorName'),
        width: 75,
        align: 'center',
        renderBodyCell: ({ row }: any, h: any) => {
          return h('img', {
            attrs: {
              src: require(`@/core/assets/iconsvg/${row?.typeTransport}.svg`),
              alt: 'logo-timeline',
              title: `${this.$t(`general.${row.typeTransport.toLowerCase() ?? 'air'}`)}`
            },
            style: {
              width: '20px'
            }
          });
        }
      },
      {
        field: 'events',
        key: 'events',
        title: this.$t('menu.Timeline'),
        width: 500,
        align: 'left',
        renderBodyCell: ({ row }: any, h: any) => {
          return h(TimelineHistoryPanel, {
            props: {
              master: row,
              masterNumber: row.number,
              houses: [],
              lineSerial: '',
              preregisterType: row.type,
              typeOperation: row.typeOperationData?.description,
              typeTransport: row.typeTransport?.name,
              items: row?.events ?? [],
              searchFunction: this.factoryFind
            }
          });
        }
      },
      {
        field: 'number',
        key: 'number',
        title: 'Number'
      },
      {
        field: 'estimatedDepartureDate',
        key: 'estimatedDepartureDate',
        title: 'Estimated Departure Date'
      },
      {
        field: 'estimatedArrivalDate',
        key: 'estimatedArrivalDate',
        title: 'Estimated Arrival Date'
      },
      {
        field: 'completedActivities',
        key: 'completedActivities',
        title: 'Completed Activities'
      },
      {
        field: 'totalActivities',
        key: 'totalActivities',
        title: 'Total Activities'
      },
      {
        field: 'generatedDocuments',
        key: 'generatedDocuments',
        title: 'Generated Documents'
      },
      {
        field: 'senderName',
        key: 'senderName',
        title: 'Sender Name'
      },
      {
        field: 'originCountry',
        key: 'originCountry',
        title: 'Origin Country'
      },
      {
        field: 'originState',
        key: 'originState',
        title: 'Origin State'
      },
      {
        field: 'originCity',
        key: 'originCity',
        title: 'Origin City'
      },
      {
        field: 'destinationCountry',
        key: 'destinationCountry',
        title: 'Destination Country'
      },
      {
        field: 'destinationState',
        key: 'destinationState',
        title: 'Destination State'
      },
      {
        field: 'destinationCity',
        key: 'destinationCity',
        title: 'Destination City'
      }
    ];
    return columns;
  }

  get columns() {
    let columns: any[] = [];
    columns = columns.concat([
      {
        field: '',
        key: 'typetransport',
        title: this.$t('general.palletsFactorName'),
        width: 75,
        align: 'center',
        renderBodyCell: ({ row }: any, h: any) => {
          return h('img', {
            attrs: {
              src: require(`@/core/assets/iconsvg/${row.timeline?.typeTransport?.name}.svg`),
              alt: 'logo-timeline',
              title: `${this.$t(`general.${row.typeTransport?.name.toLowerCase() ?? 'air'}`)}`
            },
            style: {
              width: '20px'
            }
          });
        }
      },
      {
        field: '',
        key: 'timeline',
        title: this.$t('menu.Timeline'),
        width: 500,
        align: 'left',
        renderBodyCell: ({ row }: any, h: any) => {
          return h(TimelineHistoryPanel, {
            props: {
              master: row,
              masterNumber: row.number,
              houses: row?.bulks,
              lineSerial: '',
              preregisterType: row.type,
              typeOperation: row.typeOperationData?.description,
              typeTransport: row.typeTransport?.name,
              items: row.timeline?.events ?? []
            }
          });
        }
      },
      {
        field: 'number',
        key: 'number',
        title: '# Master',
        width: 100,
        align: 'center',
        sortBy: ''
      },
      {
        field: 'estimatedDepartureDate',
        key: 'departure',
        title: this.$t('general.departure'),
        width: 100,
        align: 'center'
      },
      {
        field: 'estimatedArrivalDate',
        key: 'arrival',
        title: this.$t('general.arrival'),
        width: 100,
        align: 'center',
        renderBodyCell: ({ row }: any, h: any) => {
          return h('span', row.estimatedArrivalDate.substring(0, 10));
        }
      },
      {
        field: '',
        key: 'completedActivities',
        title: this.$t('general.completedActivities'),
        width: 100,
        align: 'center',
        renderBodyCell: ({ row }: any, h: any) => {
          return h('span', this.renderActivityCompleted(row.timeline));
        }
      },
      {
        field: '',
        key: 'generatedDocuments',
        title: this.$t('general.generatedDocuments'),
        width: 100,
        align: 'center',
        renderBodyCell: ({ row }: any, h: any) => {
          return h(AssociatedDocumentsToMaster, {
            props: {
              master: row,
              setDocumentListModal: this.setDocumentListModal
            }
          });
        }
      },
      {
        title: this.$t('general.origindata'),
        key: 'origindata',
        width: 300,
        children: [
          {
            field: '',
            key: 'fullName',
            title: this.$t('general.name'),
            width: 250,
            align: 'left',
            renderBodyCell: ({ row }: any, h: any) => {
              return h('span', row.sender?.fullName ?? '-');
            }
          },
          {
            field: '',
            key: 'country',
            title: this.$t('general.country'),
            width: 150,
            align: 'left',
            renderBodyCell: ({ row }: any, h: any) => {
              return h('span', `${row.address.territory?.countryName}`);
            }
          },
          {
            field: '',
            key: 'state',
            title: this.$t('general.state'),
            width: 150,
            align: 'left',
            renderBodyCell: ({ row }: any, h: any) => {
              return h('span', `${row.address.territory?.stateName}`);
            }
          },
          {
            field: '',
            key: 'city',
            title: this.$t('general.city'),
            width: 150,
            align: 'left',
            renderBodyCell: ({ row }: any, h: any) => {
              return h('span', `${row.address.territory?.cityName}`);
            }
          }
        ]
      },
      {
        title: this.$t('general.adresseedetails'),
        width: 300,
        key: 'adresseedetails',
        children: [
          {
            field: '',
            key: 'name',
            title: this.$t('general.name'),
            width: 250,
            align: 'left',
            renderBodyCell: ({ row }: any, h: any) => {
              return h('span', row?.consignee?.name ?? '-');
            }
          },
          {
            field: '',
            key: 'countryName',
            title: this.$t('general.country'),
            width: 150,
            align: 'left',
            renderBodyCell: ({ row }: any, h: any) => {
              return h('span', `${row.addressDestination.territory?.countryName}`);
            }
          },
          {
            field: '',
            key: 'stateName',
            title: this.$t('general.state'),
            width: 150,
            align: 'left',
            renderBodyCell: ({ row }: any, h: any) => {
              return h('span', `${row.addressDestination.territory?.stateName}`);
            }
          },
          {
            field: '',
            key: 'cityName',
            title: this.$t('general.city'),
            width: 150,
            align: 'left',
            renderBodyCell: ({ row }: any, h: any) => {
              return h('span', `${row.addressDestination.territory?.cityName}`);
            }
          }
        ]
      },
      {
        field: '',
        key: 'e',
        title: this.$t('general.actions'),
        center: 'center',
        width: 80,
        fixed: 'right',
        renderBodyCell: ({ row }: any, h: any) => {
          return h('span', { style: { display: 'flex', justifyContent: 'center', alignItems: 'center' } }, [
            h(CustomButton, {
              props: {
                actions: {
                  title: `${this.$t('general.see')} ${this.$t('general.analysis')}`,
                  icon: 'fa fa-line-chart',
                  action: () => this.showAnalysis(row),
                  variant: 'success mr-1'
                }
              }
            }),
            h(CustomButton, {
              props: {
                actions: {
                  title: `${this.$t('general.seeDetail')}`,
                  icon: 'fa fa-file-text-o',
                  action: () => this.showDetailGuide(row),
                  variant: 'info'
                }
              }
            })
          ]);
        }
      }
    ]);
    return columns;
  }

  get tableFieldsGuide() {
    return [
      {
        field: '',
        key: 'items',
        title: '#',
        width: 50,
        align: 'center',
        renderBodyCell: ({ rowIndex }: any, h: any) => {
          return h('span', rowIndex + 1);
        }
      },
      {
        field: '',
        key: 'serial',
        title: this.$t('general.barCode'),
        width: 500,
        align: 'center',
        renderBodyCell: ({ row }: any, h: any) => {
          return h(VueBarcode, {
            props: {
              value: row.serial,
              width: '2',
              height: '30',
              displayValue: 'true',
              fontSize: '11'
            }
          });
        }
      },
      {
        field: 'customer',
        key: 'customer',
        title: this.$t('general.customer'),
        width: 300,
        align: 'center',
        renderBodyCell: ({ row }: any, h: any) => {
          return h('span', row.customer?.fullName);
        }
      },
      {
        field: 'shipper',
        key: 'shipper',
        title: this.$t('general.shipper'),
        width: 300,
        align: 'center',
        renderBodyCell: ({ row }: any, h: any) => {
          return h('span', row.shipper?.fullName);
        }
      },
      {
        field: 'consignee',
        key: 'consignee',
        title: this.$t('general.consignee'),
        width: 300,
        align: 'center',
        renderBodyCell: ({ row }: any, h: any) => {
          return h('span', row.consignee?.fullName);
        }
      }
    ];
  }

  /**
   * Valido la ruta cuando voy a la opcion del menu y asi limpie todo
   */
  @Watch('$route', { immediate: true, deep: true })
  onUrlChange(newVal: Route, oldVal: Route) {
    if (newVal !== oldVal) {
      this.filter.type = null;
      this.showSearche = true;
    }
  }
  //Ciclo de vida del componente
  mounted() {
    'type' in this.urlParams.query && this.getPreRegisterByNumberAndType();
  }

  showAnalysis(row: PreRegister) {
    this.loadChartData(row.timeline as TimelineTransactional);
    this.savePreRegisterInfo(row);
    this.showModal = true;
  }

  showDetailGuide(row: PreRegister) {
    this.serialList = row.bulks;
    this.preRegisterInfo.masterNumber = row.number;
    this.preRegisterInfo.id = row.sender?.id === undefined ? '' : row.sender?.id;
    this.preRegisterInfo.name = row.sender?.fullName === undefined ? '' : row.sender?.fullName;
    this.showModalDetailGuide = true;
    this.filteredPreRegister = row;
  }

  showDetailGuideCourier(row: Order) {
    this.serialList = row.lines;
    this.preRegisterInfo.masterNumber = row.number.toString();
    this.preRegisterInfo.id = row.customerId === undefined ? '' : row.customerId;
    this.preRegisterInfo.name = row.customerName === undefined ? '' : row.customerName;
    this.showModalDetailGuide = true;
  }

  renderActivityCompleted(timeline: TimelineTransactional) {
    const activityList: ActivityTransactional[][] = [];
    timeline.events.forEach(event => {
      activityList.push(event.activities);
    });
    const totalActivities = activityList.flat();
    const totalActivitiesCompleted = totalActivities.filter(activity => activity.executed);
    return `${totalActivitiesCompleted.length}/${totalActivities.length}`;
  }

  //Funcion invocada para obtener un preregistro concreto
  async getPreRegisterByNumberAndType() {
    try {
      this.filter.type = { type: 'WMS', description: 'Almacén', module: 'wms' };
      this.isLoading = true;
      this.showSearche = false;
      const res = await this.findByPk.execute({
        type: this.urlParams.query.type,
        number: this.urlParams.query.number
      });
      this.preRegisterList = [res];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async findDocuments(module: string) {
    try {
      this.isLoading = true;
      const res = await this.findDocumentsByDateAndType.execute({ ...this.filter, type: module });
      this.preRegisterList = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  // async findOrders(module: string) {
  //   try {
  //     this.isLoading = true;
  //     const res = await this.orderFindByDateRange.execute({ ...this.filter, type: module });
  //     this.preRegisterList =
  //       res.length > 0 ? res.filter(item => this.selectedfilterOrders?.type == item.typeOrder) : [];
  //     this.isLoading = false;
  //   } catch (error) {
  //     this.isLoading = false;
  //     throw new Error(`${error}`);
  //   }
  // }

  //Funcion para obtener todos los pre registros con bultos
  // async getAllPreregister(): Promise<void> {
  //   try {
  //     this.isLoading = true;
  //     const res = await this.findPreRegisters.execute('FREIGHT_SP');
  //     this.preRegisterList = res.length > 0 ? res.reverse() : [];
  //     this.isLoading = false;
  //   } catch (error) {
  //     this.isLoading = false;
  //     throw new Error(`${error}`);
  //   }
  // }

  async factoryFind() {
    switch (this.filter.type?.type) {
      case 'WMS':
        await this.findForTraceabilityPanel();
        break;
      case PreRegisterTypeEnum.SHIPMENT:
        await this.findForTraceabilityPanel();
        break;
      case PreRegisterTypeEnum.COURIER_ORDER:
        await this.findDocuments(this.filter.type.type);
        break;
      default:
        break;
    }
    this.searched = true;
  }

  //Funcion para cargar las graficas
  async loadChartData(timeline: TimelineTransactional) {
    try {
      if (!timeline) return;
      const incomeArray: number[] = [];
      const billArray: number[] = [];
      const profitArray: number[] = [];
      const daysArray: number[] = [];
      const eventList: { description: string }[] = [];
      this.selectedEvent = 0;

      this.isLoading = true;
      const res = await this.findChartValuesByTimeLine.execute(timeline.timelineOrigin?.id as number);
      res.forEach((element: any) => {
        incomeArray.push(element[2]);
        billArray.push(element[3]);
        profitArray.push(element[4]);
        daysArray.push(element[6]);
        eventList.push({ description: element[1] });
      });
      this.selectedTimelineConfig = timeline;
      this.chartData = {
        profit: profitArray,
        income: incomeArray,
        bill: billArray,
        days: daysArray,
        labelDescription: eventList
      };
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  /**
   * Funcion que toma la info del chart y la replica en las tablass
   */
  getTableData(data: any) {
    try {
      const tableInfo: any = data.labelDescription.map((_: any, index: number) => ({
        event: data.labelDescription[index].description,
        planningBills: data.bill[index],
        planningIncome: data.income[index],
        real: data.profit[index],
        variationPricing: (data.bill[index] / data.income[index]) * 100,
        variationReal: (data.bill[index] * data.income[index]) / 100
      }));
      return tableInfo;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
  tableFields = [
    {
      key: 'event',
      label: `${this.$t('general.event')}`,
      sortable: true
    },
    {
      key: 'planningIncome',
      label: `${this.$t('general.incomes')}`,
      sortable: true
    },
    {
      key: 'planningBills',
      label: `${this.$t('general.bills')}`,
      sortable: true
    },
    {
      key: 'pricingIncome',
      label: `${this.$t('general.incomes')}`,
      sortable: true
    },
    {
      key: 'pricingBills',
      label: `${this.$t('general.bills')}`,
      sortable: true
    },
    {
      key: 'realIncome',
      label: `${this.$t('general.incomes')}`,
      sortable: true
    },
    {
      key: 'realBills',
      label: `${this.$t('general.bills')}`,
      sortable: true
    },
    {
      key: 'real',
      label: `${this.$t('general.real')}`,
      sortable: true
    },
    {
      key: 'variationPricing',
      label: `${this.$t('general.variationPricing')}`,
      sortable: true
    },
    {
      key: 'variationReal',
      label: `${this.$t('general.variationReal')}`,
      sortable: true
    }
  ];
  /**
   * Funcion que toma la info del PreRegister seleccionado y la graba en un objeto que despues se usa para renderizar
   * dicha info en el modal de las tablas
   */
  preRegisterInfo: PreRegisterInfo = {
    id: '',
    name: '',
    masterNumber: '',
    shipmentNumber: 0,
    operationType: '',
    transportType: ''
  };
  savePreRegisterInfo(preRegister: PreRegister) {
    this.preRegisterInfo.masterNumber = preRegister.number;
    this.preRegisterInfo.shipmentNumber = preRegister.bulks.length;
    this.preRegisterInfo.operationType = preRegister.typeOperationData?.description;
    this.preRegisterInfo.transportType = preRegister.typeTransport?.name;
  }

  /**
   * @name calculateFreigthDescription
   * @description Funcion para calcular la descripcion y cantidadde la carga
   */

  calculateFreigthDescription(shipment: PreRegister) {
    let description = '';

    description += shipment.bulksF.length
      ? description.concat(`${shipment.bulksF.length} ${this.$t('general.boxes')} `)
      : '';

    description += shipment.containers.length
      ? description.concat(`${shipment.containers.length} ${this.$t('general.containers')} `)
      : '';

    description += shipment.boxes.length
      ? description.concat(`${shipment.boxes.length} ${this.$t('general.pallets')}`)
      : '';

    return description;
  }

  setDocumentListModal(event: Event, documentList: DocumentHeader[]) {
    event.preventDefault();
    this.$bvModal.show('documentListModal');
    this.documentListSelected = documentList.sort((a, b) => {
      return a.number > b.number ? 1 : -1;
    });
  }
  openDeliveryModal() {
    this.$bvModal.show('deliveryGuide');
  }
  openDeliveryCargoDetailModal() {
    this.$bvModal.show('deliveryCargoDetails');
  }

  async findForTraceabilityPanel() {
    if (!this.filter.type) return;
    this.isLoading = true;
    const payload = {
      dateFrom: this.filter.from,
      dateTo: this.filter.to,
      type: this.filter.type?.type
    };
    try {
      const res = await this.findForTraceability.execute(payload);
      this.preRegisterListForTraceability = res;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }
}
