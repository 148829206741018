import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { FileRepository } from '@/settings/domain/file/FileRepository';

@Injectable()
export class CreateManifest {
  public constructor(
    @Inject(TYPES.FILE_REPOSITORY)
    private readonly fileRepository: FileRepository
  ) {}

  execute(file: File): Promise<File> {
    return this.fileRepository.saveManifest(file);
  }
}
