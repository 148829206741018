export class ButtonOptions {
  variant: string;
  buttonTitle: string;
  icon: string;
  isEdit: boolean;

  /**
   * constructor
   */
  public constructor(variant?: string, buttonTitle?: string, icon?: string, isEdit?: boolean) {
    this.variant = variant || 'success';
    this.buttonTitle = buttonTitle || 'general.add';
    this.icon = icon || 'fa-plus';
    this.isEdit = isEdit || false;
  }
}
