import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { CourierPriceList } from '@/settings/domain/courierPriceList/CourierPriceList';
import { CourierPriceListRepository } from '@/settings/domain/courierPriceList/CourierPriceListRepository';

@Injectable()
export class UpdateCourierPriceList extends Command<Promise<CourierPriceList>, CourierPriceList> {
  constructor(
    @Inject(TYPES.COURIER_PRICE_LIST_REPOSITORY)
    private readonly repository: CourierPriceListRepository
  ) {
    super();
  }

  async internalExecute(dto: CourierPriceList): Promise<CourierPriceList> {
    return await this.repository.update(dto);
  }
}
