import { Injectable } from '@/core/di/Injectable';
import router from '@/core/router';
import { LoginRepository } from '@/courier/domain/login/LoginRepository';
import { getRoutesApi } from '@/core/config/ConfigurationServer';
import LocalStoragerRemover from '@/core/helper/LocalStorageRemover';
import localStorageVariables from '@/core/config/LocalStorageVariables';
import { Workspace } from '@/courier/domain/workspace/Workspace';

@Injectable()
export class LoginService implements LoginRepository {
  private token!: string;
  private email!: string;
  private user: any = {};

  /**
   * @description Decode JWT
   * @param token
   * @returns {any} Retorna el usuario decodificado
   * @memberof LoginService
   */
  decodeJwt(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const data = JSON.parse(window.atob(base64));
    this.user = { data };
  }

  decodeJwtV2(token: string): void {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const data = JSON.parse(window.atob(base64));
    this.user = data;
  }

  async getToken(payload: { username: string; password: string }): Promise<string> {
    try {
      const response = await fetch(
        `${process.env.VUE_APP_URL_TOKEN}/user/login?email=${payload.username}&password=${payload.password}`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json'
          }
        }
      );

      // Obtenemos el token, dicho token lo asignamos como un token temporal Basico
      if (response.ok) {
        const token = await response.text();
        this.token = token;
        this.email = payload.username;
        router.replace({ name: 'WorkspaceSelection' });
        return this.token;
      }

      throw new Error('Error al obtener el token');
    } catch (error) {
      LocalStoragerRemover();
      throw new Error(`${error}`);
    }
  }

  async getWorkspaces(): Promise<Workspace[]> {
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          Authorization: 'Basic ' + this.token
        }
      };
      const response = await fetch(
        `${process.env.VUE_APP_URL_TOKEN}/user/namespaces?email=${this.email}`,
        requestOptions
      );
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async LoginWithWorkspace(workspace: Workspace): Promise<void> {
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          Authorization: 'Basic ' + this.token
        }
      };
      const response = await fetch(
        `${process.env.VUE_APP_URL_TOKEN}/user/login-into-namespace?email=${this.email}&namespace=${workspace.id}`,
        requestOptions
      );

      const data = await response.text();

      if (response.ok) {
        // Si se hizo login correctamente, decodificamos el token y lo guardamos en localstorage
        // TODO: Mejorar esto, poner el token en cookies
        this.decodeJwtV2(data);
        const urlApi = this.user.resources;
        const configProfile = this.user.configProfile;
        await getRoutesApi(urlApi, configProfile);
        this.setLocalStorageVariables(data);
        router.replace({ name: 'DashboardDefault' });
      }
    } catch (error) {
      LocalStoragerRemover();
      throw new Error(`${error}`);
    }
  }

  getTemporalToken(): string {
    return this.token;
  }

  setLocalStorageVariables(data: string) {
    const rolName = this.user.roleName;
    localStorage.setItem(localStorageVariables.ACCESS_TOKEN_COMPANY, data);
    localStorage.setItem(localStorageVariables.USER_ROL, rolName);
    localStorage.setItem(localStorageVariables.DATE_EXPIRATION_COMPANY, String(this.user.exp * 1000));
    localStorage.setItem(localStorageVariables.BUSINESS_ID, this.user.namespace);
    localStorage.setItem(localStorageVariables.OPERATIONAL_PROFILES, JSON.stringify(this.user.operationalProfiles));
    localStorage.setItem(localStorageVariables.USER_NAME, this.user.name);
    localStorage.setItem(localStorageVariables.USER_EMAIL, this.user.email);
  }
}
