
import Vue from 'vue';
import Component from 'vue-class-component';
import DocTransactional from '@/wms/infrastructure/ui/docs/DocTransactional.vue';
import { Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ListTransactional from '@/wms/infrastructure/ui/docs/ListTransactional.vue';
import ListDocumentComponent from './ListComponent/ListDocumentComponent.vue';
import { Order } from '@/courier/domain/order/Order';
import { TYPES } from '@/core/config/Types';
import { OrderFindByPk } from '@/courier/application/uses_cases/order/search/OrderFindByPk';
import { EntityFindFilter } from '@/settings/application/uses_cases/entity/search/EntityFindFilter';
import { AddressLine } from '@/settings/domain/address/AddressLine';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';
import { TermsPaymentFindAll } from '@/settings/application/uses_cases/parmsGeneral/termsPayment/search/TermsPaymentFindAll';
import { TermsPayment } from '@/settings/domain/ParmsGeneral/termsPayment/TermsPayment';
import { DocumentHeader } from '@/courier/domain/DocumentsV2/Documents_V2';
import { FindDocumentsBySwitchsV2 } from '@/courier/application/uses_cases/DocumentsV2/search/FindDocumentsBySwitchsV2';
import { Company } from '@/settings/domain/company/Company';
import { FindDocumentsByPkV2 } from '@/courier/application/uses_cases/DocumentsV2/search/FindDocumentsByPkV2';
import { FindAllTypeDocument } from '@/settings/application/uses_cases/TypeDocument/search/FindAllTypeDocument';
import { TypeDocument } from '@/settings/domain/options/TypeDocument';
@Component({
  name: 'ListDocTransactional',
  components: { DocTransactional, ListTransactional, ListDocumentComponent }
})
export default class ListDocTransactional extends Vue {
  @Inject(TYPES.FINDBYPK_ORDER) readonly findOrderByPk!: OrderFindByPk;
  @Inject(TYPES.DOCUMENTS_V2_FIND_BY_PK) readonly findDocumentByPk!: FindDocumentsByPkV2;
  @Inject(TYPES.ENTITY_FIND_BY_DESCRIPTION_LIKE) readonly customerFindFilter!: EntityFindFilter;
  @Inject(TYPES.FINDBYID_COMPANY) readonly companyFindById!: CompanyFindById;
  @Inject(TYPES.FINDALL_TERMS_PAYMENT)
  readonly findAllPaymentTerms!: TermsPaymentFindAll;
  @Inject(TYPES.DOCUMENTS_V2_FIND_BY_SWITCHS) readonly documentsV2FindBySwitchs!: FindDocumentsBySwitchsV2;
  @Inject(TYPES.FINDBYID_COMPANY)
  readonly findCompanyById!: CompanyFindById;
  @Inject(TYPES.TYPE_DOCUMENT_FIND_ALL)
  readonly findAllTypeDocument!: FindAllTypeDocument;

  //DATA
  formOrder: DocumentHeader = new DocumentHeader();
  isNewDocument = false;
  isEdit = false;
  forView = false;
  isLoading = false;
  orderList: any[] = [];

  @Prop() readonly items!: any[];
  @Prop() readonly onlyTable!: boolean;
  @Prop() readonly observer!: Function;
  @Prop() readonly fields!: any;

  switchEspecial = { sw: 12, label: `${this.$t('general.entries')}` };
  documentSwitch = this.switchEspecial.sw;
  isWarehouseMovement = ['11', '12', '13', '14', '16'].includes(this.documentSwitch.toString());
  availableDocuments: TypeDocument[] = [];

  mounted() {
    this.findAllDocumentsBySwitch();
  }
  //GETTERS

  get switchOptions() {
    return [
      { sw: 12, label: `${this.$t('general.entries')}` },
      { sw: 11, label: `${this.$t('general.exits')}` },
      { sw: 16, label: `${this.$t('general.transfers')}` },
      { sw: 14, label: `${this.$t('general.costAdjustments')} (${this.$t('general.increase')})` },
      { sw: 13, label: `${this.$t('general.costAdjustments')} (${this.$t('general.decrease')})` }
    ];
  }

  documentTypesBySwitch() {
    this.findAllTypeDocument.execute().then((res: TypeDocument[]) => {
      this.availableDocuments = res.filter(item => Number(item.sw) === this.switchEspecial.sw);
    });
  }

  get fieldsAction() {
    return [
      { field: 'number', label: `#` },
      {
        field: 'type',
        label: this.translateCol('type')
      },
      { field: 'concept', label: this.translateCol('concept') },
      {
        field: 'date',
        label: this.translateCol('date'),
        formatFn: (value: string) => {
          return value.split('T')[0];
        }
      },
      { field: 'customer.fullName', label: this.translateCol('entity') },
      { field: 'warehouse.description', label: this.translateCol('warehouse') },
      { field: 'total', label: this.translateCol('total') },
      { field: 'actions', label: this.translateCol('actions') }
    ];
  }

  $refs!: {
    bvModal: HTMLFormElement;
    listDocsModal: HTMLFormElement;
    tableComponent: ListDocumentComponent;
    documentList: HTMLFormElement;
  };

  /**
   * @description Busca los documentos por switch especial y se setean en la tabla principal
   * @returns {Promise<void>}
   */
  async findAllDocumentsBySwitch(): Promise<void> {
    this.isLoading = true;
    const res = await this.documentsV2FindBySwitchs.execute([this.switchEspecial.sw.toString()]);
    this.orderList = res.length > 0 ? res.reverse() : [];
    this.isLoading = false;
  }

  controllerModal(object: any) {
    if (object) this.observer(object);

    this.$bvModal.hide('DocTransactional-modal');
  }

  translateCol(colName: any) {
    return this.$i18n.t('general.' + colName);
  }

  actions = {
    customActions: [
      {
        title: `${this.$t('general.load')}`,
        icon: 'fa fa-file',
        variant: 'secondary',
        action: this.load
      }
    ]
  };

  async load(item: DocumentHeader) {
    this.forView = false;
    this.isLoading = true;
    await this.findDocument(item);
    this.$refs.documentList.hide();
    this.$refs.bvModal.show();
    this.isLoading = false;
  }

  async findEntity(item: DocumentHeader) {
    let res = null;
    const params: any = {
      text: item.customer?.fullName,
      type: ['SUPPLIER', 'CUSTOMER']
    };
    const entities = await this.customerFindFilter.execute(params);
    entities.forEach(entity => {
      if (entity.id == item.customer?.id) {
        res = entity;
      }
    });

    return res;
  }

  createBlankOrder() {
    this.isNewDocument = false;
    this.isEdit = false;
    this.forView = false;
    this.formOrder = new DocumentHeader();
  }

  async setMaxCapatity(res: any) {
    let sum = 0;
    await res.lines.forEach((element: any) => {
      sum += element.unitsQuantity;
    });

    return sum;
  }

  async editOnMainTable(item: Order) {
    this.isLoading = true;
    this.createBlankOrder();
    this.isNewDocument = false;
    this.isEdit = true;
    await this.findOrder(item);
    this.$refs.bvModal.show();
    this.isLoading = false;
  }

  async showDetails(item: DocumentHeader) {
    this.isLoading = true;
    try {
      this.forView = true;
      const res = await this.findDocument(item);
      this.formOrder = Object.assign(this.formOrder, res);
      this.$bvModal.show('DocTransactional-modal');
      this.isNewDocument = true;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async findOrder(item: Order) {
    const res: any = await this.findOrderByPk.execute({
      number: item.number,
      type: item.typeOrder
    });

    this.formOrder = {
      ...res,
      originAddress: new AddressLine(),
      maxCapacity: await this.setMaxCapatity(res),
      associatedDocumentNumber: res.number,
      typeDocAssociated: res.typeOrder,
      forBondedEntry: res.forBondedEntry,
      lines: res.lines.map((line: any) => ({
        ...line,
        _rowVariant: null
      }))
    };
  }

  async getCondition(orderTerm: string): Promise<TermsPayment> {
    try {
      this.isLoading = true;
      const res = await this.findAllPaymentTerms.execute();

      this.isLoading = false;
      return res.filter((term: TermsPayment) => term.description == orderTerm)[0];
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async findDocument(item: any) {
    const payload = {
      number: item.number,
      typeOrder: item.documentType.type
    };
    const res: DocumentHeader = await this.findDocumentByPk.internalExecute(payload);

    return res;
  }

  isOrder(): boolean {
    return !this.isWarehouseMovement;
  }

  /**
   * @description Genera un PDF del documento seleccionado
   * @param item El documento seleccionado
   * @returns {Promise<void>}
   */
  get image() {
    return localStorage.getItem('urlLogoCompany') as string;
  }

  generatePDF = async (row: DocumentHeader) => {
    const companyId = localStorage.getItem('businessId') as string;
    const companyObtained: Company = await this.findCompanyById.internalExecute(companyId);
    const res = await this.findDocument(row);
    const clientMainContact = res.customer?.contacts?.filter(contact => contact.mainContact)[0] ?? null;
    if (typeof res.date === 'string') res.date = new Date(res.date);
    if (typeof res.dueDate === 'string') res.dueDate = new Date(res.dueDate);

    const columns = [
      [
        'SKU',
        this.$t('general.description'),
        this.$t('general.units'),
        `${this.$t('general.unitprice')} ${res.currency?.currencyCode}`,
        `${this.$t('general.discount')}`,
        `${this.$t('general.taxes')}`,
        `${this.$t('general.total')} ${res.currency?.currencyCode}`
      ]
    ];

    const data: any[] = [];
    res.lines.forEach((item: any) => {
      const unitPriceOpr = Number(item.purchaseValue / item.unitsQuantity).toFixed(2);
      data.push([
        item.reference?.code,
        item.reference?.filterBrand,
        item.unitsQuantity,
        unitPriceOpr,
        `${item.discountRate}%`,
        `${item.totalTaxes}%`,
        Number(item.purchaseValue).toFixed(2)
      ]);
    });

    const doc = new jsPDF();

    const initialTextColor = doc.getTextColor();

    autoTable(doc, {
      head: columns,
      body: data,
      foot: [['', '', '', '', '', `Total ${res.currency?.currencyCode}: ${res.total.toFixed(2).toString()}`]],
      margin: { top: 95 },
      headStyles: { fillColor: '#ccd8db', textColor: initialTextColor, fontSize: 6.5, halign: 'right' },
      footStyles: { fillColor: '#ccd8db', textColor: initialTextColor, fontSize: 6.5, halign: 'right' },
      bodyStyles: { fontSize: 6.5, halign: 'right' }
    });
    doc.setTextColor('#000000');
    doc.text('_____________________________________________________________', 10, 285);

    for (let index = 1; index <= doc.getNumberOfPages(); index++) {
      doc.setPage(index);
      doc.addImage({ imageData: this.image, format: 'JPEG', x: 15, y: 15, height: 40, width: 40 });
      doc.setFontSize(6.5);
      doc.text(`${companyObtained?.name}`, 120, 23);
      doc.text(`${companyObtained?.address}, ${companyObtained?.zipCode}`, 120, 26);
      doc.text(`${companyObtained?.identification}`, 120, 29);
      doc.text(`${companyObtained?.email}`, 120, 32);
      doc.text(`${this.$t('general.billTo')}: `, 15, 67);
      doc.text(`${res.customer?.fullName} `, 15, 70);
      doc.text(`${res.customer?.address?.description} `, 15, 73);
      doc.text(
        `${res.customer?.address?.cityName}, ${res.customer?.address?.stateName}, ${res.customer?.address?.countryName} `,
        15,
        76
      );

      doc.text(`${clientMainContact?.email}`, 15, 79);
      doc.text(`${this.$t('general.phone')}: ${clientMainContact?.phone}`, 15, 82);

      doc.setDrawColor('#ccd8db');
      doc.setFillColor('#ccd8db');
      doc.roundedRect(120, 70, 75, 20, 1, 1, 'FD');
      doc.setTextColor(initialTextColor);
      doc.text(
        `${
          res.documentType?.sw === '1' ? this.$t('general.invoiceDate') : this.$t('general.date')
        }: ${res.date.toLocaleDateString()}`,
        125,
        75
      );
      doc.text(`${res.documentType?.description} # ${res.number}`, 125, 78);
      doc.text(`${this.$t('general.incoterms')}: ${res.customer?.incoterms?.name ?? ''}`, 125, 81);
      doc.text(`${this.$t('general.conditionpayment')}: ${res.condition?.description ?? '-'}`, 125, 84);
      doc.text(`${res.dueDate ? `${this.$t('general.dueDate')}: ${res.dueDate.toLocaleDateString()}` : '-'}`, 125, 87);
      doc.text(`${this.$t('general.paginationDOC', { index, length: doc.getNumberOfPages() })}`, 98, 290);
    }

    doc.save(`${res.documentType?.type}${res.number}-${res.date.toLocaleDateString()}`);
  };

  actionsMainTable = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: this.editOnMainTable,
        conditionalRender: () => {
          return !this.isOrder();
        }
      },
      {
        title: `${this.$t('general.details')}`,
        icon: 'fa fa-eye',
        variant: 'warning',
        action: this.showDetails
      },
      {
        title: `${this.$t('general.download')}`,
        icon: 'fa fa-download',
        variant: 'info',
        action: (row: DocumentHeader) => this.generatePDF(row)
      }
    ]
  };
}
