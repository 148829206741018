
import { TYPES } from '@/core/config/Types';
import { CreateBranch } from '@/settings/application/uses_cases/branch/create/CreateBranch';
import { DeleteBranch } from '@/settings/application/uses_cases/branch/delete/DeleteBranch';
import { FindAllBranch } from '@/settings/application/uses_cases/branch/search/FindAllBranch';
import { UpdateBranch } from '@/settings/application/uses_cases/branch/update/UpdateBranch';
import { Branch } from '@/settings/domain/branch/Branch';
import { Inject } from 'inversify-props';
import Vue from 'vue';
import Component from 'vue-class-component';
import AddBranch from './AddBranch.vue';

@Component({
  name: 'UnknownComponentCenter',
  components: {
    AddBranch
  }
})
export default class UnknownComponentCenter extends Vue {
  @Inject(TYPES.BRANCH_FIND_ALL)
  readonly findAllBranch!: FindAllBranch;
  @Inject(TYPES.BRANCH_SAVE)
  readonly saveBranch!: CreateBranch;
  @Inject(TYPES.BRANCH_DELETE)
  readonly deleteBranch!: DeleteBranch;
  @Inject(TYPES.BRANCH_UPDATE)
  readonly updateBranch!: UpdateBranch;

  //DATOS
  isLoading = false;
  fullPage = true;
  branchList: Branch[] = [];
  isEdit = false;
  form: Branch = new Branch();

  mounted() {
    this.findAll();
  }

  //Actions

  //Objetos de acciones
  tableActions = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: this.edit
      },
      {
        title: `${this.$t('general.delete')}`,
        icon: 'fa fa-close',
        variant: 'danger',
        action: this.delete
      }
    ]
  };

  //METODOS
  get fields() {
    return [
      {
        field: 'code',
        label: `${this.$t('general.code')}`
      },
      {
        field: 'name',
        label: `${this.$t('general.name')}`
      },
      {
        field: 'warehouse.description',
        label: `${this.$t('general.warehouse')}`
      },
      {
        field: 'store.storeName',
        label: `${this.$t('general.store')}`
      },
      {
        label: this.$t('general.status'),
        field: 'active',
        sortable: true,
        formatFn: (value: boolean) => (value ? this.$t('general.active') : this.$t('general.disabled'))
      },
      {
        field: 'actions',
        label: `${this.$t('general.actions')}`
      }
    ];
  }

  async findAll() {
    try {
      this.isLoading = true;
      this.branchList = await this.findAllBranch.internalExecute();
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
    this.isLoading = false;
  }

  async update(branch: Branch) {
    return await this.updateBranch.internalExecute(branch);
  }

  async save(branch: Branch) {
    return await this.saveBranch.internalExecute(branch);
  }

  async factory(branch: Branch) {
    this.isLoading = true;
    const res = this.isEdit ? await this.update(branch) : await this.save(branch);
    this.isLoading = false;

    if (res) {
      this.findAll();
      this.clearForm();
    }
  }

  async delete(branch: Branch) {
    this.isLoading = true;
    await this.deleteBranch.internalExecute(branch.code);
    this.isLoading = false;
  }

  edit(branch: Branch) {
    this.isEdit = true;
    this.form = Object.assign({}, branch);
  }

  clearForm() {
    this.isEdit = false;
    this.form = new Branch();
  }
}
