import { StandardPrice } from '@/wms/domain/catalogs/pricingEngine/SettingStandardPrice/StandardPrice';

export class SettingZoneStandard {
  public zones: any;
  public standardPrice: StandardPrice;
  constructor() {
    this.zones = [];
    this.standardPrice = new StandardPrice();
  }
}
