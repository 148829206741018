import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Document } from '@/courier/domain/documents/Document';
import { DocumentRepository } from '@/courier/domain/documents/DocumentRepository';

@Injectable()
export class DocumentFindBySwtichsAndFilter extends Query<Promise<Document[]>, any> {
  public constructor(
    @Inject(TYPES.DOCUMENT_TYPE_REPOSITORY)
    private readonly documentRepository: DocumentRepository
  ) {
    super();
  }
  async internalExecute(payload: any): Promise<Document[]> {
    const dataDocument = await this.documentRepository.findBySwitchsAndFilters(payload);
    return dataDocument;
  }
}
