
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { TransportFindAll } from '@/tracking/application/uses_cases/typetransport/search/findall/TransportFindAll';
import { CreateTimeline } from '@/tracking/application/uses_cases/timeline/create/CreateTimeline';
import { UpdateTimeline } from '@/tracking/application/uses_cases/timeline/update/UpdateTimeline';
import i18n from '@/core/plugins/i18n';
import { OperationFindAll } from '@/tracking/application/uses_cases/typeoperation/search/OperationFindAll';
import { EventsFindAllConfActive } from '@/tracking/application/uses_cases/events/search/EventsFindAllConfActive';

@Component({
  name: 'CreateTimelineCourier'
})
export default class CreateTimelineCourier extends Vue {
  @Inject(TYPES.FINDALL_TRANSPORT)
  readonly transportFindAll!: TransportFindAll;
  @Inject(TYPES.CREATE_TIMELINE)
  readonly createTimeline!: CreateTimeline;
  @Inject(TYPES.UPDATE_TIMELINE)
  readonly updateTimeline!: UpdateTimeline;
  @Inject(TYPES.FINDALL_CONF_ACTIVE_EVENTS)
  readonly eventsFindAllConfActive!: EventsFindAllConfActive;
  @Inject(TYPES.FINDALL_TYPE_OPERATION)
  readonly OperationFindAll!: OperationFindAll;
  //Data
  target = i18n.t('general.timeline');
  isLoading = false;
  fullPage = true;
  typeTransportList = [];
  processTypeList = [];
  typeOperationList = [];
  processTypeTable = [];
  private formCreateTimeline: any = {
    timelinedDescription: '',
    isTouched: false,
    dataTypeTransport: [],
    dataProcessType: [],
    dataTypeOperation: [],
    currentPage: 1,
    perPage: 3,
    sortBy: 'code',
    sortDesc: false,
    filter: null,
    totalRows: 1,
    isBusy: false,
    edit: null,
    search: 'close'
  };
  //Prop
  @Prop() readonly timelineList!: any;
  @Prop() readonly validationSave!: boolean;
  //Refs
  $refs!: {
    timelinedDescription: HTMLInputElement;
  };
  //Hook
  mounted() {
    this.$nextTick(() => this.getTimeline(this.timelineList));
    this.$nextTick(() => this.typeTransport);
    this.$nextTick(() => this.eventsProcessType);
    this.$nextTick(() => this.typeOperation);
    if (this.validationSave) {
      this.$nextTick(() => this.clearForm());
    }
  }
  //Computed
  get rows() {
    return this.processTypeTable.length;
  }
  public get eventsProcessType() {
    this.eventsFindAllConfActive.execute(true).then((response: any) => {
      this.processTypeList = [];
      this.processTypeList = response;
    });
    return true;
  }
  public get typeTransport() {
    this.transportFindAll.execute().then((response: any) => {
      this.typeTransportList = response;
    });
    return true;
  }
  public get typeOperation() {
    this.OperationFindAll.execute().then((response: any) => {
      this.typeOperationList = response;
    });
    return true;
  }
  //method
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  public onFiltered(filteredItems: any) {
    this.formCreateTimeline.totalRows = filteredItems.length;
    this.formCreateTimeline.currentPage = 1;
  }
  public closeModal() {
    this.$emit('closeModal');
  }
  public getTimeline(data: any) {
    this.formCreateTimeline.timelinedDescription = data.description;
    this.formCreateTimeline.dataTypeTransport = {
      id: data.typeTransport,
      name: data.typeTransportName
    };
    this.formCreateTimeline.dataTypeOperation = {
      code: data.typeOperation,
      description: data.typeOperationName
    };
    this.processTypeTable = data.events;
  }
  public getValueInput(value: string) {
    this.formCreateTimeline.filter = value;
  }
  public editEventTimeline(data: any) {
    this.formCreateTimeline.edit = this.formCreateTimeline.edit !== data.id ? data.id : null;
    if (this.formCreateTimeline.edit === null) {
      const payloadUpdate: any = {
        id: this.timelineList.id,
        description: this.formCreateTimeline.timelinedDescription.trim(),
        typeTransport: this.formCreateTimeline.dataTypeTransport.id,
        typeOperation: this.formCreateTimeline.dataTypeOperation.code,
        processType: 1,
        active: true,
        events: this.processTypeTable
      };
      this.isLoading = true;
      this.updateTimeline
        .execute(payloadUpdate)
        .then((response: any) => {
          this.isLoading = false;
          this.getTimelineSkeleton();
          return response;
        })
        .catch((error: any) => {
          this.isLoading = false;
          throw new Error(error);
        });
    }
  }
  public saveTimeline() {
    for (const index in this.formCreateTimeline.dataProcessType) {
      this.formCreateTimeline.dataProcessType[index].sequence = parseInt(index) + 1;
    }
    const payloadSave: any = {
      id: this.timelineList.id,
      description: this.formCreateTimeline.timelinedDescription.trim(),
      typeTransport: this.formCreateTimeline.dataTypeTransport.id,
      typeOperation: this.formCreateTimeline.dataTypeOperation.code,
      processType: 1,
      active: true,
      events: this.formCreateTimeline.dataProcessType
    };
    if (this.processTypeTable.length > 0) {
      this.isLoading = true;
      this.updateTimeline
        .execute(payloadSave)
        .then((response: any) => {
          this.isLoading = false;
          this.getTimelineSkeleton();
          return response;
        })
        .catch((error: any) => {
          this.isLoading = false;
          throw new Error(error);
        });
    } else {
      this.isLoading = true;
      this.createTimeline
        .execute(payloadSave)
        .then((response: any) => {
          this.isLoading = false;
          this.getTimelineSkeleton();
          return response;
        })
        .catch((error: any) => {
          this.isLoading = false;
          throw new Error(error);
        });
    }
  }
  public clearForm() {
    this.typeTransportList = [];
    this.processTypeList = [];
    this.processTypeTable = [];
    this.formCreateTimeline = {
      timelinedDescription: '',
      isTouched: false,
      dataTypeTransport: [],
      dataProcessType: [],
      currentPage: 1,
      perPage: 3,
      sortBy: 'code',
      sortDesc: false,
      filter: null,
      totalRows: 1,
      isBusy: false,
      edit: null,
      search: 'close'
    };
    this.$nextTick(() => this.$refs.timelinedDescription.focus());
  }
  //Emit
  @Emit()
  getTimelineSkeleton() {
    this.$emit('getTimelineSkeleton');
  }
}
