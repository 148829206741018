import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Invoice } from '@/courier/domain/invoice/Invoice';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { InvoiceRepository } from '@/courier/domain/invoice/InvoiceRepository';
import { Query } from '@/core/cqrs/Query';

@Injectable()
export class InvoiceFindAll extends Query<Promise<Invoice[]>, number> {
  public constructor(
    @Inject(TYPES.INVOICE_REPOSITORY)
    private readonly invoiceRepository: InvoiceRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(page: number): Promise<Invoice[]> {
    const dataInvoice = await this.invoiceRepository.findAll(page);
    this.stateManager.patch({ dataInvoice });
    return this.stateManager.state.dataInvoice;
  }
}
