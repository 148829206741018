import { Warehouse } from '../warehouse/Warehouse';

export class TypeDocument {
  sw: string;
  type: string;
  description: string;
  programmingText1: string;
  programmingText2: string;
  warehouse: Warehouse | null;
  reserved: boolean;

  public constructor() {
    this.sw = '';
    this.type = '';
    this.description = '';
    this.programmingText1 = '';
    this.programmingText2 = '';
    this.warehouse = null;
    this.reserved = false;
  }
}
