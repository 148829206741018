import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { OrderRepository } from '@/courier/domain/order/OrderRepository';
import { WmsQuotationOrder } from '@/courier/domain/wmsQuotationOrder/WmsQuotationOrder';

@Injectable()
export class UpdateQuotation extends Command<Promise<WmsQuotationOrder>, WmsQuotationOrder> {
  public constructor(
    @Inject(TYPES.ORDER_REPOSITORY)
    private readonly orderRepository: OrderRepository
  ) {
    super();
  }
  internalExecute(payload: WmsQuotationOrder): Promise<WmsQuotationOrder> {
    return this.orderRepository.updateQuotation(payload);
  }
}
