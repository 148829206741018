import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Country } from '@/settings/domain/country/Country';
import { CountryRepository } from '@/settings/domain/country/CountryRepository';

@Injectable()
export class CountryFindByStatus extends Query<Promise<Country[]>, boolean> {
  public constructor(
    @Inject(TYPES.TERRITORIES_REPOSITORY)
    private readonly countryRepository: CountryRepository,

    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(status: boolean): Promise<Country[]> {
    const dataCountries = await this.countryRepository.findCountryByStatus(status);
    this.stateManager.patch({ dataCountries });
    return this.stateManager.state.dataCountries;
  }
}
