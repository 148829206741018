import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { HouseBillOfLandingRepository } from '@/wms/domain/houseBillOfLading/HouseBillOfLadingRepository';

@Injectable()
export class FindConsigneeByHouses extends Command<Promise<string[]>, string[]> {
  public constructor(
    @Inject(TYPES.HOUSE_BILL_OF_LADING_REPOSITORY)
    private readonly repository: HouseBillOfLandingRepository
  ) {
    super();
  }

  public internalExecute(houses: string[]): Promise<string[]> {
    return this.repository.findConsigneesByHouses(houses);
  }
}
