import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { BulkRepository } from '@/wms/domain/bulk/BulkRepository';

@Injectable()
export class SplitBulk extends Command<
  Promise<void>,
  { number: number; type: string; sequence: number; quantity: number }
> {
  public constructor(
    @Inject(TYPES.BULK_REPOSITORY)
    private readonly repository: BulkRepository
  ) {
    super();
  }

  async internalExecute(payload: { number: number; type: string; sequence: number; quantity: number }): Promise<void> {
    try {
      return await this.repository.splitBulk(payload);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
