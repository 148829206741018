
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

// Import FilePond and styles
import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import { ActivityTransactional } from '@/tracking/domain/activitiesTransactional/ActivityTransactional';
import { Inject } from 'inversify-props';
import { FindFile } from '@/settings/application/uses_cases/file/search/FindFile';
import { TYPES } from '@/core/config/Types';

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

@Component({
  name: 'FooterModal',
  components: { FilePond }
})
export default class FooterModal extends Vue {
  @Inject(TYPES.FIND_FILE_BY_NAME)
  readonly findFileByName!: FindFile;

  @Prop() sendNotification!: boolean;
  @Prop() activityData!: ActivityTransactional;
  @Prop() attachedFiles!: any[];
  @Prop({ default: () => false }) isLoading!: boolean;
  @Prop({ default: () => false, required: true }) disabledInputs!: boolean;

  $refs!: {
    pondFileUpload: InstanceType<typeof FilePond>;
  };

  myFiles = [];

  get localAttachedFiles() {
    return this.attachedFiles;
  }

  set localAttachedFiles(value) {
    this.$emit('update:attachedFiles', value);
  }

  mounted() {
    this.onActivityDataChange();
  }

  async onActivityDataChange() {
    const promises: Promise<Blob>[] = [];
    if (this.activityData?.files?.length > 0) {
      this.activityData.files.forEach((file: string) => {
        promises.push(fetch(this.findFileByName.execute(file)).then(response => response.blob()));
      });

      // Tomamos las url de los archivos y los convertimos a blob para poder subirlos a filepond
      let files = await Promise.all(promises);

      files = files.map((file: Blob, index: number) => {
        return new File([file], this.activityData.files[index].split('.')[0], { type: file.type });
      });

      // Luego de tener los archivos en blob los agregamos a filepond
      this.$refs.pondFileUpload.addFiles(files);
    }
  }
}
