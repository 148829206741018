import { Injectable } from '@/core/di/Injectable';
import { Query } from '@/core/cqrs/Query';
import { TYPES } from '@/core/config/Types';
import { HouseRepository } from '@/courier/domain/House/HouseRepository';
import { Inject } from '@/core/di/Inject';

@Injectable()
export class FindHouseSerialsByDateRange extends Query<Promise<string[]>, { from: string; to: string }> {
  public constructor(
    @Inject(TYPES.HOUSE_REPOSITORY)
    private readonly repository: HouseRepository
  ) {
    super();
  }

  public async internalExecute(payload: { from: string; to: string }): Promise<string[]> {
    return await this.repository.findByDateRange(payload.from, payload.to);
  }
}
