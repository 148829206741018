import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import { TypeDocument } from '@/settings/domain/TypeDocument/TypeDocument';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { TypeDocumentRepository } from '@/settings/domain/TypeDocument/TypeDocumentRepository';

/**
 * @class TypeDocumentSave
 * @description Clase que contiene la lógica de negocio para actualizar un tipo de documento
 * @version 1.0.0
 * @Author San7iix
 */

@Injectable()
export class UpdateTypeDocument extends Command<Promise<TypeDocument>, TypeDocument> {
  public constructor(
    @Inject(TYPES.TYPE_DOCUMENT_REPOSITORY)
    private readonly repository: TypeDocumentRepository
  ) {
    super();
  }

  public async internalExecute(typeDocument: TypeDocument): Promise<TypeDocument> {
    return await this.repository.update(typeDocument);
  }
}
