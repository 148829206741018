
import { Stock } from '@/wms/domain/stock/Stock';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'FreightStockMovements',
  components: {}
})
export default class FreightStockMovements extends Vue {
  //Props
  @Prop({ required: true, default: [] }) bulks!: Stock[];

  //Table fields
  get fields() {
    return [
      {
        title: `Serial`,
        field: 'serial',
        key: 'serial',
        width: 200
      },
      {
        title: `${this.$t('general.reference')}`,
        field: 'reference',
        key: 'reference',
        renderBodyCell: ({ row }: any, h: any) => h('span', `${row.reference.code} - ${row.reference.filterBrand}`),
        width: 250
      },
      {
        title: `Commodity`,
        field: 'commodity',
        key: 'commodity',
        renderBodyCell: ({ row }: any, h: any) => h('span', `${row.commodity} - ${row.commodityDescription}`),
        width: 250
      },
      {
        title: `${this.$t('general.type')}`,
        field: 'type',
        key: 'type'
      },
      {
        title: `${this.$t('general.number')}`,
        field: 'number',
        key: 'number'
      },
      {
        title: `${this.$t('general.store')}`,
        field: 'storeId',
        key: 'storeId'
      },
      {
        title: `${this.$t('general.year')}`,
        field: 'year',
        key: 'year'
      },
      {
        title: `${this.$t('general.month')}`,
        field: 'month',
        key: 'month'
      },
      {
        title: `${this.$t('general.date')}`,
        field: 'dateDoc',
        key: 'dateDoc',
        width: 100
      },
      {
        title: `${this.$t('general.nature')}`,
        field: 'sw',
        key: 'sw'
      },
      {
        title: `${this.$t('general.in')}`,
        field: 'entry',
        key: 'entry'
      },
      {
        title: `${this.$t('general.out')}`,
        field: 'invoice',
        key: 'invoice'
      },
      {
        title: `${this.$t('general.currentStatus')}`,
        field: 'stockBalance',
        key: 'stockBalance',
        renderBodyCell: ({ row }: any, h: any) =>
          h('span', row.entry > row.invoice ? this.$t('general.on_warehouse') : this.$t('general.dispatched'))
      }
    ];
  }

  columnWidthResizeOption = {
    enable: true,
    minWidth: 100
  };

  cellStyleOption = {
    headerCellClass: () => {
      return 'table-header-cell-class';
    }
  };
}
