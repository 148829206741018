import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Stock } from '@/wms/domain/stock/Stock';
import { StockRepository } from '@/wms/domain/stock/StockRepository';

@Injectable()
export class StockFindAll extends Query<Promise<Stock[]>> {
  public constructor(
    @Inject(TYPES.STOCK_REPOSITORY)
    private readonly stockRepository: StockRepository
  ) {
    super();
  }
  async internalExecute(): Promise<Stock[]> {
    const res = await this.stockRepository.findAll();
    return res;
  }
}
