import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import { PreRegisterRepository } from '@/wms/domain/preRegister/PreRegisterRepository';
import { Command } from '@/core/cqrs/Command';

@Injectable()
export class PreRegisterSave extends Command<Promise<PreRegister>, PreRegister> {
  public constructor(
    @Inject(TYPES.PRE_REGISTER_REPOSITORY)
    private readonly preRegisterRepository: PreRegisterRepository
  ) {
    super();
  }
  async internalExecute(payload: PreRegister): Promise<PreRegister> {
    const res = await this.preRegisterRepository.save(payload);
    return res;
  }
}
