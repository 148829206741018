import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Zone } from '@/settings/domain/zone/Zone';
import { ZoneRepository } from '@/settings/domain/zone/ZoneRepository';

@Injectable()
export class ZoneFindAll extends Query<Promise<Zone[]>> {
  public constructor(
    @Inject(TYPES.ZONE_REPOSITORY)
    private readonly zoneRepository: ZoneRepository,

    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(): Promise<Zone[]> {
    const dataZones = await this.zoneRepository.findAll();
    this.stateManager.patch({ dataZones });
    return this.stateManager.state.dataZones;
  }
}
