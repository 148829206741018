import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { TypeContact } from '@/settings/domain/ParmsGeneral/typeContact/TypeContact';
import { TypeContactRepository } from '@/settings/domain/ParmsGeneral/typeContact/TypeContactRepository';

@Injectable()
export class TypeContactService implements TypeContactRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_TYPECONTACT_FINDALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_TYPECONTACT_FINDALL;
  private readonly API_TYPECONTACT_SAVE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_TYPECONTACT_SAVE;
  private readonly API_TYPECONTACT_UPDATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_TYPECONTACT_UPDATE;
  private readonly API_TYPECONTACT_DELETE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_TYPECONTACT_DELETE;

  async create(typeContact: TypeContact) {
    try {
      await http.post<TypeContact[]>(this.API_TYPECONTACT_SAVE, JSON.stringify(typeContact));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(typeContact: TypeContact) {
    try {
      await http.put<TypeContact[]>(this.API_TYPECONTACT_UPDATE, JSON.stringify(typeContact));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async delete(typeContact: TypeContact) {
    try {
      await http.singleDelete<TypeContact[]>(this.API_TYPECONTACT_DELETE + `/${typeContact.id}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAll(): Promise<TypeContact[]> {
    try {
      return await http.get<TypeContact[]>(this.API_TYPECONTACT_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
