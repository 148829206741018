
import Component from 'vue-class-component';
import Vue from 'vue';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import Timeline from '@/courier/infrastructure/ui/components/order/TimelineN/Timeline.vue';
import { OrderFindByDateRange } from '@/courier/application/uses_cases/order/search/OrderFindByDateRange';
import OrderDetail from '@/courier/infrastructure/ui/components/order/OrderDetail.vue';
import SwitchEvents from '@/courier/infrastructure/ui/components/order/switch/SwitchEvents.vue';
import UpdateOrder from '@/courier/infrastructure/ui/components/order/UpdateOrder.vue';
import { CreateEventsExec } from '@/tracking/application/uses_cases/events/create/CreateEventsExec';
import { showAlert } from '@/core/plugins/Notifications';
import i18n from '@/core/plugins/i18n';
import { OrderFindByDateRangeAndCustomerId } from '@/courier/application/uses_cases/order/search/OrderFindByDateRangeAndCustomerId';
import router from '@/core/router';
import CreateOrderN from './CreateOrderN/CreateOrderN.vue';
import { OrderFindAllByModule } from '@/courier/application/uses_cases/order/search/OrderFindAllByModule';
import { OrderType } from '@/courier/domain/CourierOrder/OrderTypeEnum';
import VueBarcode from 'vue-barcode';
import { PreRegisterFindWithBulks } from '@/wms/application/preRegister/search/PreRegisterFindWithBulks';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';

@Component({
  name: 'OrderHistory',
  components: { UpdateOrder, SwitchEvents, OrderDetail, Timeline, CreateOrderN, VueBarcode },
  filters: {
    toCurrency(value: number) {
      if (typeof value !== 'number') {
        return value;
      }
      const formatter = new Intl.NumberFormat(`USD`);
      return formatter.format(value);
    }
  }
})
export default class AppOrderHistory extends Vue {
  @Inject(TYPES.FINDBYDATERANGE_ORDER) readonly orderFindByDateRange!: OrderFindByDateRange;
  @Inject(TYPES.ORDER_FIND_ALL_BY_MODULE) readonly orderFindAllByModule!: OrderFindAllByModule;
  @Inject(TYPES.CREATE_EXEC_EVENTS) readonly createEventsExec!: CreateEventsExec;
  @Inject(TYPES.FINDBYDATERANGEANDCUSTOMERID_ORDER)
  readonly orderFindByDateRangeAndCustomerId!: OrderFindByDateRangeAndCustomerId;
  @Inject(TYPES.PRE_REGISTER_FINDALL_WITH_BULKS)
  readonly findPreRegisters!: PreRegisterFindWithBulks;

  //Data
  target = i18n.t('general.orderhistory');
  isLoading = false;
  fullPage = true;
  customerId = '';
  selected = [];
  filter = null;
  filterOn = [];
  shippingLines = [];
  valueFrom = '';
  valueTo = '';
  arrayDateRange: any[] = [];
  orderSelected = [];
  selectOrder = [] as any;
  booleanOrder = false;
  paramsExec = false;
  typeEventOrder = '';
  loadOrders = true;
  uriImageSvg = '@/core/assets/iconsvg/Ground.svg';

  //Listas
  preRegisterList: PreRegister[] = [];

  //Refs
  $refs!: {
    dropdownOrderHistory: HTMLFormElement;
  };
  //Hook
  mounted() {
    const date = new Date();
    const isoDateTime = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    this.valueFrom = new Date().getFullYear() + '-' + this.monthConcat + '-' + '01';
    this.valueTo = isoDateTime.substring(0, 10);
  }
  //Compute
  get rows() {
    return this.arrayDateRange.length;
  }
  get monthConcat() {
    return new Date().getMonth() + 1 > 9 ? new Date().getMonth() + 1 : '0' + (new Date().getMonth() + 1);
  }

  get uriImage() {
    return '@/core/assets/iconsvg/';
  }
  //Methods
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  translateCol(colName: any) {
    return this.$i18n.t('general.' + colName);
  }
  customerById(valueFrom: string, valueTo: string, id: string) {
    const payload: any = {
      from: valueFrom,
      to: valueTo,
      id: id
    };
    this.isLoading = true;
    this.orderFindByDateRangeAndCustomerId
      .execute(payload)
      .then((response: any) => {
        this.$refs.dropdownOrderHistory.hide(true);
        this.isLoading = false;
        if (response.length > 0) {
          this.arrayDateRange = [];
          this.arrayDateRange = response;
        } else {
          const searchData = document.querySelector('#searchData');
          if (searchData) {
            this.arrayDateRange = [];
            searchData.classList.remove('search-none');
            searchData.classList.add('search-failed');
          }
        }
        return response;
      })
      .catch((error: any) => {
        this.$refs.dropdownOrderHistory.hide(true);
        this.isLoading = false;
        throw new Error(error);
      });
  }
  filterOrderToConsolidate() {
    const arrayOrders = [];
    for (const orders of this.arrayDateRange) {
      for (const index in orders.eventLogs) {
        if (
          orders.eventLogs[index].exec !== 'S' &&
          orders.eventLogs[index].activities[0]['sw'] === '40' &&
          orders.eventLogs[parseInt(index) - 1].exec === 'S'
        ) {
          arrayOrders.push(orders);
        }
      }
    }
    if (arrayOrders.length > 0) {
      this.arrayDateRange = [];
      this.arrayDateRange = arrayOrders;
      this.booleanOrder = !this.booleanOrder;
    } else {
      showAlert('info', i18n.t('general.thereisnodatato') + ' ' + i18n.t('general.consolidate'));
      this.$nextTick(() => this.shippingByDateRange());
      this.booleanOrder = false;
    }
  }
  filterOrderToUnConsolidate() {
    const arrayUnConsolidate = [];
    for (const orders of this.arrayDateRange) {
      if (orders.consolidatedNumber !== null && orders.unconsolidatedNumber === null) {
        for (const index in orders.eventLogs) {
          if (
            orders.eventLogs[index].exec !== 'S' &&
            orders.eventLogs[index].activities[0]['sw'] === '41' &&
            orders.eventLogs[parseInt(index) - 1].exec === 'S'
          ) {
            arrayUnConsolidate.push(orders);
          }
        }
      }
    }
    if (arrayUnConsolidate.length > 0) {
      this.paramsExec = !this.paramsExec;
    } else {
      showAlert('info', i18n.t('general.thereisnodatato') + ' ' + i18n.t('general.unconsolidate'));
      this.paramsExec = false;
    }
  }
  shippingByCode(data: any, button: any, type: string) {
    const payload: any = {
      number: data.number,
      type: data.typeOrder,
      warehouse: '000',
      sw: 1
    };
    this.shippingLines = payload;
    this.typeEventOrder = type;
    this.$root.$emit('bv::show::modal', 'mdDetailOrUpdateOrder', button);
  }
  shippingByDateRange() {
    const payload: any = {
      from: this.valueFrom,
      to: this.valueTo
    };
    this.isLoading = true;
    this.orderFindByDateRange
      .execute(payload)
      .then((response: any) => {
        this.isLoading = false;
        this.$refs.dropdownOrderHistory.hide(true);
        this.arrayDateRange = response.filter((item: any) => {
          return item.typeOrder == this.loadOrders;
        });
        const searchData = document.querySelector('#searchData');
        if (searchData) {
          searchData.classList.remove('search-failed');
          searchData.classList.add('search-none');
        }
      })
      .catch((err: any) => {
        this.isLoading = false;
        throw new Error(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async findAll() {
    this.isLoading = true;
    const payload = {
      module: 'courier',
      orderType: [OrderType.purchaseRequest, OrderType.assistedOrder]
    };
    const res = await this.orderFindAllByModule.execute(payload);
    this.arrayDateRange = res;
    this.isLoading = false;
  }

  findAllQuotations() {
    this.arrayDateRange = [];
    throw new Error('Method not implemented');
  }

  findFactory() {
    this.shippingByDateRange();
  }

  removeTag() {
    this.selectOrder = [];
    this.$nextTick(() => this.shippingByDateRange());
    this.booleanOrder = !this.booleanOrder;
  }
  saveConsolidate() {
    let itemOrder: any;
    const consolidationList = [] as any;
    for (itemOrder of this.selectOrder) {
      for (const itemEventLog of itemOrder['eventLogs']) {
        if (itemEventLog['activities'][0]['sw'] === '40') {
          const consolidateList: any = {
            activityCode: itemEventLog['activities'][0]['activityId'],
            eventCode: itemEventLog['eventCode'],
            orderNumber: itemOrder['number'],
            orderType: itemOrder['typeOrder'],
            timelineCode: itemOrder['timeline']
          };
          consolidationList.push(consolidateList);
        }
      }
    }
    const payload: any = {
      swCode: '40',
      swTypeOperation: 1,
      consolidationList
    };
    this.isLoading = true;
    this.createEventsExec
      .execute(payload)
      .then(() => {
        this.isLoading = false;
        this.removeTag();
      })
      .catch((error: any) => {
        this.isLoading = false;
        throw new Error(error);
      });
  }
  redirectPage() {
    router.push({ path: 'operationbyswitch' });
  }

  //Funcion para obtener todos los pre registros con bultos
  async getAllPreregister() {
    try {
      this.isLoading = true;
      const res: any = await this.findPreRegisters.execute('WMSF');
      res.lines = res.bulks;
      this.preRegisterList = res.length > 0 ? res.reverse() : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
}
