export class PricingEngineParam {
  public code: number;
  public name: string;
  public typeParam: string;
  public codeParam: string;

  public constructor() {
    this.code = 0;
    this.name = '';
    this.typeParam = '';
    this.codeParam = '';
  }
}
