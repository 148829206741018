export class ZoneL {
  zoneId: string;
  zoneDescription: string;
  maxCapacity: number;
  unusedCapacity: number;
  active: boolean;
  racksLength: number;

  public constructor() {
    this.zoneId = '';
    this.zoneDescription = '';
    this.maxCapacity = 0;
    this.unusedCapacity = 0;
    this.active = true;
    this.racksLength = 0;
  }
}
