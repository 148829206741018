import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Command } from '@/core/cqrs/Command';
import { Customer } from '@/courier/domain/customer/Customer';
import { CustomerRepository } from '@/courier/domain/customer/CustomerRepository';

@Injectable()
export class CreateCustomer extends Command<Promise<any>, Customer> {
  public constructor(
    @Inject(TYPES.CUSTOMER_REPOSITORY)
    private readonly customerRepository: CustomerRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  internalExecute(user: Customer): Promise<any> {
    return this.customerRepository.create(user);
  }
}
