
import Component from 'vue-class-component';
import Vue from 'vue';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateFindByCountryCode } from '@/settings/application/uses_cases/state/search/StateFindByCountryCode';
import i18n from '@/core/plugins/i18n';
import { PalletsFactorFindAll } from '@/settings/application/uses_cases/palletsFactor/search/PalletsFactorFindAll';
import { CreateUnitOfMeasurement } from '@/settings/application/uses_cases/unitofmeasurement/create/CreateUnitOfMeasurement';
import { VolumeFindAll } from '@/settings/application/uses_cases/volume/search/VolumeFindAll';
import { WeightFindAll } from '@/settings/application/uses_cases/weight/search/WeightFindAll';

@Component({
  name: 'UnitOfMeasurement'
})
export default class AppUnitOfMeasurement extends Vue {
  @Inject(TYPES.FINDALL_PALLETSFACTOR)
  readonly PalletsFactorFindAll!: PalletsFactorFindAll;
  @Inject(TYPES.FINDALL_VOLUME) readonly volumeFindAll!: VolumeFindAll;
  @Inject(TYPES.FINDALL_WEIGHT) readonly weightFindAll!: WeightFindAll;
  @Inject(TYPES.CREATE_UNITOFMEASUREMENT) readonly createUnitOfMeasurement!: CreateUnitOfMeasurement;
  @Inject(TYPES.FINDBYCOUNTRYCODE_STATE) readonly stateFindByCountryCode!: StateFindByCountryCode;
  //DATA
  target = i18n.t('menu.Unit Of Measurement');
  isLoading = false;
  fullPage = true;
  typeTransportList = [];
  volumeList = [];
  weightList = [];
  reportedList = [];
  isTouched = false;
  form: any = {
    palletsFactor: 0,
    dataTypeTransport: [],
    modelVolume: [],
    modelWeight: [],
    modelReporteIn: []
  };
  //Refs
  $refs!: {
    validationMeasurement: HTMLFormElement;
    palletsFactor: HTMLInputElement;
    volumerefs: HTMLFormElement;
  };
  created() {
    this.$nextTick(() => this.$refs.volumerefs.focus);
    this.$nextTick(() => this.transportFindAll);
    this.$nextTick(() => this.volumeFindAllList);
    this.$nextTick(() => this.weightFindAllList);
  }
  //Computed
  get transportFindAll() {
    this.PalletsFactorFindAll.execute()
      .then((response: any) => {
        this.typeTransportList = response;
        this.$emit('response-transport', response);
      })
      .catch(error => {
        throw new Error(error);
      });
    return this.typeTransportList;
  }
  get volumeFindAllList() {
    this.volumeFindAll
      .execute()
      .then((response: any) => {
        this.volumeList = response;
      })
      .catch(error => {
        throw new Error(error);
      });
    return this.volumeList;
  }
  get weightFindAllList() {
    this.weightFindAll
      .execute()
      .then((response: any) => {
        this.weightList = response;
        this.reportedList = response;
      })
      .catch(error => {
        throw new Error(error);
      });
    return this.weightList;
  }
  //METHODS
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  submitUnitOfMeasurement() {
    const payload: any = {
      volumeUnit: this.form.modelVolume.code,
      weightUnit: this.form.modelWeight.code,
      reportedIn: this.form.modelReporteIn.code,
      factor: this.form.palletsFactor,
      palletFactorId: this.form.dataTypeTransport.code,
      palletFactorName: this.form.dataTypeTransport.description,
      active: true
    };
    this.isLoading = true;
    this.createUnitOfMeasurement
      .execute(payload)
      .then((response: any) => {
        this.isLoading = false;
        this.clearForm();
        this.$emit('submit-unit');
        return response;
      })
      .catch((error: any) => {
        this.isLoading = false;
        throw new Error(error);
      });
  }
  clearForm() {
    this.volumeList = [];
    this.weightList = [];
    this.reportedList = [];
    this.form = {
      palletsFactor: 0,
      dataTypeTransport: [],
      modelVolume: [],
      modelWeight: [],
      modelReporteIn: []
    };
    this.$refs.volumerefs.focus;
    this.$refs.validationMeasurement.reset();
  }
}
