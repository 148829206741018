/**
 * @class FindByStatusGroup
 * @description Clase que contiene la lógica de negocio para obtener un grupo por status
 * @version 1.0.0
 * @Author EnriquePallares
 */

import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { Group } from '@/settings/domain/group/Group';
import { GroupRepository } from '@/settings/domain/group/GroupRepository';

@Injectable()
export class FindByStatusGroup extends Query<Promise<Group[]>, boolean> {
  public constructor(
    @Inject(TYPES.GROUP_REPOSITORY)
    private readonly repository: GroupRepository
  ) {
    super();
  }

  async internalExecute(status: boolean): Promise<Group[]> {
    return await this.repository.findByStatus(status);
  }
}
