import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { BulkRepository } from '@/wms/domain/bulk/BulkRepository';
import { WarehouseBulk } from '@/wms/domain/bulk/WarehouseBulk';

@Injectable()
export class FindBulksForWarehouseReport extends Query<Promise<WarehouseBulk>, { numberDoc: number; typeDoc: string }> {
  public constructor(
    @Inject(TYPES.BULK_REPOSITORY)
    private readonly repository: BulkRepository
  ) {
    super();
  }

  async internalExecute({ numberDoc, typeDoc }: { numberDoc: number; typeDoc: string }): Promise<WarehouseBulk> {
    try {
      return await this.repository.findForWarehouseReceipt({ numberDoc, typeDoc });
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
