import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { PriceListRepositoryWeight } from '@/settings/domain/priceList/PriceListRepositoryWeight';

@Injectable()
export class PriceListImportWeight extends Command<Promise<boolean>, { formData: FormData }> {
  public constructor(
    @Inject(TYPES.PRICE_LIST_REPOSITORY_WEIGHT)
    private readonly priceListRepository: PriceListRepositoryWeight
  ) {
    super();
  }

  internalExecute({ formData }: { formData: FormData }): Promise<boolean> {
    return this.priceListRepository.importCSV(formData);
  }
}
