import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Command } from '@/core/cqrs/Command';
import { TimelineConfig } from '@/tracking/domain/timelineConfig/TimelineConfig';
import { TimelineConfigRepository } from '@/tracking/domain/timelineConfig/TimelineConfigRepository';

@Injectable()
export class CreateTimelineConfig extends Command<Promise<any>, TimelineConfig> {
  public constructor(
    @Inject(TYPES.STATE_MANAGER) private readonly stateManager: StateManager,
    @Inject(TYPES.TIMELINECONFIG_TYPE_REPOSITORY)
    private readonly timelineConfigRepository: TimelineConfigRepository
  ) {
    super();
  }
  internalExecute(timelineConfig: TimelineConfig): Promise<any> {
    return this.timelineConfigRepository.save(timelineConfig);
  }
}
