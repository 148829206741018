import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { GroupAgentService } from '@/settings/domain/ParmsGeneral/groupAgentService/GroupAgentService';
import { GroupAgentServiceRepository } from '@/settings/domain/ParmsGeneral/groupAgentService/GroupAgentServiceRepository';

@Injectable()
export class GroupAgentServiceFindAll extends Query<Promise<GroupAgentService[]>> {
  public constructor(
    @Inject(TYPES.GROUP_AGENT_SERVICE_REPOSITORY)
    private readonly repository: GroupAgentServiceRepository,

    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(): Promise<GroupAgentService[]> {
    const dataGroupAgentService = await this.repository.findAll();
    this.stateManager.patch({ dataGroupAgentService });
    return this.stateManager.state.dataGroupAgentService;
  }
}
