
import Vue from 'vue';
import Component from 'vue-class-component';
import { ValidationObserver } from 'vee-validate/dist/types/components';
import { OrderLine } from '@/courier/domain/orderline/OrderLine';
import { showAlert } from '@/core/plugins/Notifications';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { PurchaseScraping } from '@/courier/application/uses_cases/purchase/search/PurchaseScraping';
import i18n, { selectedLocale } from '@/core/plugins/i18n';
import { VolumeFindAll } from '@/settings/application/uses_cases/volume/search/VolumeFindAll';
import { WeightFindAll } from '@/settings/application/uses_cases/weight/search/WeightFindAll';
import { Prop } from 'vue-property-decorator';
import { RateEngine } from '@/utils/RateEngine';

@Component({
  name: 'CalculateService',

  filters: {
    toCurrency(value: any) {
      if (typeof value !== 'number') {
        return value;
      }
      const language = selectedLocale;
      const formatter = new Intl.NumberFormat(`${language}`);
      return formatter.format(value);
    }
  }
})
export default class CalculateService extends Vue {
  @Inject(TYPES.PURCHASE_SCRAPING) readonly purchaseScraping!: PurchaseScraping;
  @Inject(TYPES.FINDALL_VOLUME) readonly volumeFindAll!: VolumeFindAll;
  @Inject(TYPES.FINDALL_WEIGHT) readonly weightFindAll!: WeightFindAll;
  //DATA
  selected = '2';
  optionPickupAndPurchase = null;
  optionPurchaseOrderStore = false;
  isDisabledEcommerce = false;
  optionPurchaseOrder = false;

  //flag of external data
  externalData = false;
  isUpdate = false;
  //header form
  unitWeight: any = []; // as unitofmeasurementPackagesWeight
  unitDimension: any = []; // as unitofmeasurementPackagesDimension

  showSppinerSearch = false;
  quickViewProduct = [];
  urlImageAmazon = '';
  virtualShops: any = { name: 'AMAZON', code: '001' };
  //list multiselect // falta revisar el tema del refector para que esto se tome de los stados globales de la app
  weightList = [];
  volumeList = [];

  //form grids
  packageLines: OrderLine[] = []; // as invoiceProductsPackeges
  documentLines: OrderLine[] = []; // as invoiceProduct

  //response after calculate
  palletsFactor: any = [];

  //totales external data
  subTotal = 0;
  totalTax = 0;
  totalOrderLine = 0;

  //objectQuotation exteral
  objectQuotation: any = {};

  //ref
  $refs!: {
    modalPackageOption: HTMLFormElement;
    modalOrderQuotation: HTMLFormElement;
    twoStep: InstanceType<typeof ValidationObserver>;
  };

  @Prop({ type: Array, required: true }) readonly dataCompany!: any;
  @Prop({ type: Function, required: true }) factorSelected!: any;
  @Prop() readonly orderType!: number;

  created() {
    this.$nextTick(() => this.volumeFindAllList);
    this.$nextTick(() => this.weightFindAllList);
  }

  //computed
  get volumeFindAllList() {
    this.volumeFindAll
      .execute()
      .then((response: any) => {
        this.volumeList = response;
      })
      .catch(error => {
        throw new Error(error);
      });
    return this.volumeList;
  }
  get weightFindAllList() {
    this.weightFindAll
      .execute()
      .then((response: any) => {
        this.weightList = response;
      })
      .catch(error => {
        throw new Error(error);
      });
    return this.weightList;
  }
  // as calculateRatePackages
  get totalFormPackage() {
    let totalWeight = 0,
      totalVolume = 0,
      totalQuantity = 0,
      totalPackage = 0;
    this.packageLines.map((data: any) => {
      totalQuantity += Number(data.quantity);
      totalWeight += parseFloat(String(data.weight));
      totalVolume += Number(
        parseFloat(String(data.width)) * parseFloat(String(data.height)) * parseFloat(String(data.lengthy))
      );
      totalPackage++;
    });
    return {
      totalWeight: totalWeight.toFixed(2),
      totalVolume: totalVolume.toFixed(2),
      totalQuantity: totalQuantity,
      totalPackage: totalPackage
    };
  }
  //methods
  showModalOption() {
    if (this.dataCompany[0]['handlePurchase']) {
      this.optionPickupAndPurchase = null;
      this.$refs['modalPackageOption'].show();
      this.isDisabledEcommerce = false;
    }
  }
  validateStep() {
    return this.$refs.twoStep.validate();
  }
  addNewRowPackages(validate: any) {
    validate.then((res: any) => {
      if (!res) {
        return;
      }
      this.packageLines.push(new OrderLine());
    });
  }
  addNewRowDocument(validate: any) {
    validate.then((res: any) => {
      if (!res) {
        return;
      }
      this.documentLines.push(new OrderLine());
    });
  }
  deleteRowPackages(index: number, packageLine: any) {
    const idx = this.packageLines.indexOf(packageLine);
    if (idx > -1) {
      this.packageLines.splice(idx, 1);
    }
  }
  deleteRowDocument(index: number, packageLine: any) {
    const idx = this.documentLines.indexOf(packageLine);
    if (idx > -1) {
      this.documentLines.splice(idx, 1);
    }
  }
  //Amazon service
  getDataAmazonUrl(url: any, index: any) {
    if (this.packageLines[index].purchaseUrl) {
      this.showSppinerSearch = true;
      this.purchaseScraping
        .execute(url)
        .then((response: any) => {
          this.showSppinerSearch = false;
          const weightAmz = response['data']['weight'].split(' ');
          this.packageLines[index].weight = weightAmz[0];
          this.packageLines[index].height = response['data']['height'].replace(/^[a-zA-Z$]+/g, '').trim();
          this.packageLines[index].width = response['data']['width'].replace(/^[a-zA-Z$]+/g, '').trim();
          this.packageLines[index].lengthy = response['data']['length'].replace(/^[a-zA-Z$]+/g, '').trim();
          // this.packageLines[index].unitofmeasurement = weightAmz[1] === 'pounds' ? 'lb' : 'oz';
          this.packageLines[index].unitPrice = response['data']['price'].replace(/^[a-zA-Z$]+/g, '').trim();
          // this.calculateTQuantityAndWeight();
          this.quickViewProduct = response['data'];
          this.urlImageAmazon = response['data']['urlImage'].replace('\n', '');
          return response;
        })
        .catch((error: any) => {
          this.showSppinerSearch = false;
          this.packageLines[index].purchaseUrl = '';
          this.packageLines[index].unitPrice = 0;
          this.packageLines[index].height = 0;
          this.packageLines[index].width = 0;
          this.packageLines[index].lengthy = 0;
          // this.packageLines[index].unitofmeasurement = ''; verificar que es esta propiedad
          showAlert('warning', this.$i18n.t('general.messageamazon'));
          return error;
        });
    } else {
      return;
    }
  }
  chosenOptionCheck() {
    this.optionPurchaseOrder = !this.optionPurchaseOrder;
    this.optionPurchaseOrderStore = !this.optionPurchaseOrderStore;

    this.virtualShops = this.dataCompany[0]['amazonStore']
      ? { name: 'AMAZON', code: '001' }
      : { name: i18n.t('general.allstores'), code: '002' };
    this.clearFormPackages();
  }
  secondaryWindow(url: any) {
    const w = screen.width;
    const h = screen.height;
    const leftPosition = (screen.width - w - 12) / 2;
    const topPosition = (screen.height - h - 57) / 2;
    const params = `width=${w},height=${h},left=-${leftPosition}pt,top=${topPosition}pt,
      scrollbars=no,resizable=no`;
    const newWindow: any = window.open(url, 'Amazon virtual Store', params);
    // Puts focus on the newWindow
    if (window) newWindow.focus();
  }
  // valudacion de formularios
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  clearFormPackages() {
    this.packageLines = [new OrderLine()];
  }
  clearFormVirtualStore() {
    this.virtualShops.code === '002' ? (this.optionPurchaseOrder = false) : (this.optionPurchaseOrder = true);
    this.packageLines = [];
  }
  chosenOption(value: number) {
    this.optionPurchaseOrder = value === 4;
    this.optionPurchaseOrderStore = value === 5;
    this.virtualShops = this.dataCompany[0]['amazonStore']
      ? { name: 'AMAZON', code: '001' }
      : { name: i18n.t('general.allstores'), code: '002' };
    this.$refs['modalPackageOption'].hide();
  }
  //executeCalcPricing
  //implementar el calculo por documentos-------------------------------------------------
  async calculatePricing(dataRoute: any) {
    if (!(await this.$refs.twoStep.validate())) return;

    const rateEngine = new RateEngine();
    const payload: any = {
      ...dataRoute,
      volumeUnit: this.unitDimension.id,
      weightUnit: this.selected === '1' ? 0 : this.unitWeight.id
    };
    rateEngine
      .calc(payload, this.packageLines)
      .then((response: any) => {
        //falta filtrar por la listas de precio validas
        this.palletsFactor = response.data;
        this.$refs['modalOrderQuotation'].show();
      })
      .catch((err: any) => {
        return err;
      });
  }
  factorSelectedCall(factor: any) {
    let objFactor = {};
    if (factor) {
      objFactor = {
        ...factor,
        unitWeight: this.unitWeight,
        unitDimension: this.unitDimension,
        selected: this.selected,
        arrayDocument: this.packageLines,
        typeQuotation: this.optionPurchaseOrder ? 'PR' : 'CT',
        optionPurchaseOrder: this.optionPurchaseOrder,
        optionPurchaseOrderStore: this.optionPurchaseOrderStore
      };
      this.$refs['modalOrderQuotation'].hide();
      this.subTotal = factor.subTotal;
      this.totalTax =
        factor.taxes.length > 0 ? factor.taxes.reduce((tax1: any, tax2: any) => tax1.taxValue + tax2.taxValue) : 0;
      this.totalOrderLine = factor.rateValue;
    } else {
      objFactor = this.objectQuotation;
    }
    this.factorSelected(objFactor);
  }

  getTitleByFactor(factorId: number) {
    let title: any = '';
    switch (factorId) {
      case 1: {
        title = i18n.t('general.air');
        break;
      }
      case 2: {
        title = i18n.t('general.ground');
        break;
      }
      case 3: {
        title = i18n.t('general.ocean');
        break;
      }
    }
    return title;
  }
  startWithExternalData(objectQuotation: any) {
    if (objectQuotation) {
      this.objectQuotation = objectQuotation;
      this.packageLines = objectQuotation.arrayDocument;
      this.selected = objectQuotation.selected;
      if (typeof objectQuotation.unitWeight === 'object') {
        this.unitWeight = objectQuotation.unitWeight;
      } else {
        this.unitWeight = this.weightList.filter((object: any) => object.id == objectQuotation.unitWeight);
      }
      //this.unitDimension = objectQuotation.unitDimension;
      this.optionPurchaseOrder = objectQuotation.optionPurchaseOrder;
      this.optionPurchaseOrderStore = objectQuotation.optionPurchaseOrderStore;
      this.subTotal = objectQuotation.subTotal;
      this.totalTax =
        objectQuotation.taxes.length > 0
          ? objectQuotation.taxes.reduce((tax1: any, tax2: any) => tax1.taxValue + tax2.taxValue)
          : 0;
      this.totalOrderLine = objectQuotation.rateValue;
      //active falg
      this.externalData = true;

      if (objectQuotation.isUpdate) {
        this.externalData = false;
        this.isUpdate = true;
      }
    }
  }
}
