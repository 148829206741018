import 'reflect-metadata';
import { Application } from '@/core/config/Application';
import { TYPES } from '@/core/config/Types';
import { Container } from '@/core/config/Container';
import App from './core/ui/App.vue';
import './registerServiceWorker';
import '@/core/assets/scss/app.scss';
import '@/core/customrules/Validator.ts';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue-easytable/libs/theme-default/index.css';
import 'vue-toastification/dist/index.css';

import i18n, { setI18nLanguage } from '@/core/plugins/i18n';

async function initializeApp() {
  await setI18nLanguage(i18n.locale);

  Container.instance()
    .get<Application>(TYPES.APPLICATION)
    .create(App);
}

initializeApp();
