import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { Operation } from '@/tracking/domain/typeoperation/Operation';
import { OperationRepository } from '@/tracking/domain/typeoperation/OperationRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { TypeOperation } from '@/settings/domain/typeOperation/TypeOperation';

@Injectable()
export class OperationServices implements OperationRepository {
  private readonly API_BASE_URI: string = localStorageModule().tracking.host.API_BASE_URI;
  private readonly API_OPERATION_SAVE: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_OPERATION_SAVE;
  private readonly API_OPERATION_UPDATE: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_OPERATION_UPDATE;
  private readonly API_OPERATION_FINDBYPROCESSTYPE: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_OPERATION_FINDBYPROCESSTYPE;
  private readonly API_OPERATION_FINDALL: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_OPERATION_FINDALL;
  private readonly API_OPERATION_DELETE: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_OPERATION_DELETE;

  async create(operation: TypeOperation) {
    try {
      return await http.post<Operation[]>(this.API_OPERATION_SAVE, JSON.stringify(operation));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(operation: TypeOperation) {
    try {
      return await http.put<Operation[]>(this.API_OPERATION_UPDATE, JSON.stringify(operation));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findByProcessType(params: number): Promise<Operation[]> {
    try {
      return await http.get<Operation[]>(this.API_OPERATION_FINDBYPROCESSTYPE + params);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findAll(): Promise<TypeOperation[]> {
    try {
      return await http.get<TypeOperation[]>(this.API_OPERATION_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async delete(code: string): Promise<TypeOperation> {
    try {
      return await http.singleDelete<TypeOperation>(this.API_OPERATION_DELETE + `?code=${code}`);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
