
import Vue from 'vue';
import Component from 'vue-class-component';
import { CurrencyFindAll } from '@/settings/application/uses_cases/currency/search/CurrencyFindAll';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { CurrencyFactorFindAll } from '@/settings/application/uses_cases/currencyfactor/search/CurrencyFactorFindAll';
import { CreateCurrencyFactor } from '@/settings/application/uses_cases/currencyfactor/create/CreateCurrencyFactor';
import { CurrencyFactorUpdate } from '@/settings/application/uses_cases/currencyfactor/update/CurrencyFactorUpdate';
import { CreateCurrency } from '@/settings/application/uses_cases/currency/create/CreateCurrency';
import { CurrencyFindById } from '@/settings/application/uses_cases/currency/search/CurrencyFindById';
import { CurrencyFactorFindByCurrencyCode } from '@/settings/application/uses_cases/currencyfactor/search/CurrencyFactorFindByCurrencyCode';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import RegisterCurrency from './RegisterCurrency.vue';
import { Currency } from '@/settings/domain/currency/Currency';
import { ButtonOptions } from '@/settings/domain/buttonOptions/ButtonOptions';
import { CurrencyFindAllWithRate } from '@/settings/application/uses_cases/currency/search/CurrencyFindAllWithRate';
import { Country } from '@/settings/domain/country/Country';
import { CountryFindByDescriptionLikeInGeneralTerritories } from '@/settings/application/uses_cases/country/search/CountryFindByDescriptionLikeInGeneralTerritories';
import { CurrencyFactor } from '@/settings/domain/currencyfactor/CurrencyFactor';
import LinechartVue from '@/core/components/dashboard/chartjs/Linechart.vue';
import { DeleteCurrency } from '@/settings/application/uses_cases/currency/delete/DeleteCurrency';

class FormCurrency {
  currencyCode: string;
  description: string;
  date: string;
  country: Country | null;
  rate: number;

  constructor() {
    this.currencyCode = '';
    this.description = '';
    this.date = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10);
    this.country = null;
    this.rate = 0;
  }
}

class CustomButtonOptions extends ButtonOptions {
  currencyExists: boolean;

  constructor() {
    super();
    this.currencyExists = false;
  }
}

@Component({
  name: 'RegisterCurrencyCenter',
  components: { CustomTableN, RegisterCurrency, LinechartVue }
})
export default class RegisterCurrencyCenter extends Vue {
  @Inject(TYPES.FINDALL_CURRENCY)
  readonly findAllCurrencyFromDb!: CurrencyFindAll;
  @Inject(TYPES.CREATE_CURRENCY)
  readonly currencySave!: CreateCurrency;
  @Inject(TYPES.DELETE_CURRENCY)
  readonly deleteCurrency!: DeleteCurrency;
  @Inject(TYPES.FINDBYID_CURRENCY)
  readonly currencyFindById!: CurrencyFindById;
  @Inject(TYPES.FINDALL_CURRENCYFACTOR)
  readonly currencyFactorFindAllcall!: CurrencyFactorFindAll;
  @Inject(TYPES.CREATE_CURRENCYFACTOR)
  readonly currencyFactorSave!: CreateCurrencyFactor;
  @Inject(TYPES.UPDATE_CURRENCYFACTOR)
  readonly currencyFactorUpdate!: CurrencyFactorUpdate;
  @Inject(TYPES.FINDBYCURRENCYCODE_CURRENCYFACTOR)
  readonly currencyFactorFindByCurrencyCode!: CurrencyFactorFindByCurrencyCode;
  @Inject(TYPES.FINDALL_CURRENCY_WITH_RATE)
  readonly findAllWithRate!: CurrencyFindAllWithRate;
  @Inject(TYPES.COUNTRY_FIND_BY_DESCRIPTION_IN_GENERAL_TERRITORIES)
  readonly findCountryInGeneralTerritories!: CountryFindByDescriptionLikeInGeneralTerritories;

  //Listas
  currencyList: Currency[] = [];
  currencyFactorList: CurrencyFactor[] = [];

  //Formulario
  form: FormCurrency = new FormCurrency();

  //Helpers
  isLoading = false;

  //HOOKS
  mounted() {
    this.findAll();
  }

  //Objeto de acciones para componente hijo
  actions = {
    factory: this.factory,
    clear: this.clearForm
  };

  //Objeto de propiedades del botón save del componente hijo

  buttonOptions: CustomButtonOptions = Object.assign(new CustomButtonOptions(), {
    variant: 'success',
    buttonTitle: 'save',
    isEdit: false,
    icon: 'fa-save',
    currencyExists: false
  });
  buttonDelete: CustomButtonOptions = Object.assign(new CustomButtonOptions(), {
    variant: 'danger',
    buttonTitle: 'delete',
    isEdit: false,
    icon: 'fa-trash',
    currencyExists: false
  });

  chartData = {
    labels: ['January', 'February', 'March'],
    datasets: [{ data: [40, 20, 12] }]
  };

  //Objeto de acciones para la tabla
  tableActions = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: this.load
      },
      {
        title: `${this.$t('general.addRate')}`,
        icon: 'fa fa-plus',
        variant: 'success',
        action: this.loadWithoutEdit
      },
      {
        title: `${this.$t('general.rateHistory')}`,
        icon: 'fa fa-bar-chart',
        variant: 'warning',
        action: this.openModal
      },
      {
        title: `${this.$t('general.delete')}`,
        icon: 'fa fa-trash',
        variant: 'danger',
        action: this.removeCurrency
      }
    ]
  };

  //Campos para obtener la fecha actual
  get dateNow() {
    const dateNow = new Date();
    return new Date(dateNow.getTime() - dateNow.getTimezoneOffset() * 60000).toISOString().substring(0, 10);
  }

  //Campos para obtener cabeceras y data de la tabla
  get fields() {
    return [
      { field: 'currencyCode', label: this.$t('general.code'), sortable: true },
      { field: 'description', label: this.$t('general.description'), sortable: true },
      { field: 'countryName', label: this.$t('general.country'), sortable: true, sortDirection: 'desc' },
      { field: 'rate', label: this.$t('general.trm'), sortable: true },
      { field: 'date', label: this.$t('general.date'), sortable: true },
      { field: 'actions', label: this.$t('general.actions'), sortable: false }
    ];
  }

  //Campos para obtener cabeceras y data de la tabla de rates en el modal
  get rateFields() {
    return [
      { field: 'rate', label: 'TRM', sortable: true },
      { field: 'date', label: this.$t('general.date'), sortable: true }
    ];
  }

  //Funcion invocada para obtener las monedas con el último rate asociado
  async findAll() {
    try {
      this.isLoading = true;
      const res = await this.findAllWithRate.execute();
      this.currencyList = res ?? [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  // Funcion invocada para eliminar moneda
  async removeCurrency(item: Currency) {
    const payload = item.currencyCode;
    const res = await this.deleteCurrency.execute(payload);
    this.clearForm();
    this.findAll();
    return res;
  }

  //Funcion invocada para cargar una moneda en el formulario
  async load(item: any) {
    this.buttonOptions = Object.assign(new CustomButtonOptions(), {
      variant: 'secondary',
      buttonTitle: 'edit',
      icon: 'fa-edit',
      isEdit: true
    });
    this.buttonDelete = Object.assign(new CustomButtonOptions(), {
      ...this.buttonDelete,
      isEdit: true
    });
    this.form = Object.assign(new FormCurrency(), {
      currencyCode: item.currencyCode,
      description: item.description,
      rate: item.rate,
      date: item.date
    });

    await this.getCountry(item);
  }

  //Funcion invocada para cargar una moneda en el formulario
  async loadWithoutEdit(item: any) {
    this.buttonOptions = Object.assign(new CustomButtonOptions(), {
      variant: 'success',
      buttonTitle: 'add',
      icon: 'fa-plus',
      isEdit: false,
      currencyExists: true
    });
    this.form = Object.assign(new FormCurrency(), {
      currencyCode: item.currencyCode,
      description: item.description,
      rate: item.rate,
      date: item.date
    });

    await this.getCountry(item);
  }

  //Funcion invocada para limpiar el formulario y resetear el boton de agregar
  clearForm() {
    this.form = new FormCurrency();
    this.buttonOptions = Object.assign(new CustomButtonOptions(), {
      variant: 'success',
      buttonTitle: 'save',
      isEdit: false,
      icon: 'fa-save'
    });
  }

  //Funcion invocada para guardar una moneda y el rate de dicha moneda en el dia actual
  save() {
    try {
      this.isLoading = true;
      //Payload necesario para crear o actualizar un rate
      const payloadCurrencyFactor: any = {
        currency: this.form.currencyCode,
        date: this.form.date,
        rate: this.form.rate
      };

      //Payload necesario para crear una moneda
      const payloadCurrency: any = {
        currencyCode: this.form.currencyCode,
        description: this.form.description,
        countryCode: this.form.country?.code,
        countryName: this.form.country?.name
      };
      const promises = [
        !this.buttonOptions.currencyExists && this.currencySave.execute(payloadCurrency),
        this.currencyFactorSave.execute(payloadCurrencyFactor)
      ];
      Promise.all(promises)
        .then(() => {
          this.isLoading = false;
          this.clearForm();
          this.findAll();
        })
        .catch(error => {
          this.isLoading = false;
          throw new Error(`${error}`);
        });
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para actualizar un rate
  async updateRate() {
    try {
      this.isLoading = true;
      const payloadCurrencyFactor: any = {
        currency: this.form.currencyCode,
        date: this.form.date,
        rate: this.form.rate
      };
      await this.currencyFactorUpdate.execute(payloadCurrencyFactor);
      this.findAll();
      this.clearForm();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para crear un rate
  async createRate() {
    try {
      this.isLoading = true;
      const payloadCurrencyFactor: any = {
        currency: this.form.currencyCode,
        date: this.form.date,
        rate: this.form.rate
      };
      await this.currencyFactorSave.execute(payloadCurrencyFactor);
      this.findAll();
      this.clearForm();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para obtener paises desde la tabla general
  async getCountry(input: any) {
    try {
      this.isLoading = true;
      const res = await this.findCountryInGeneralTerritories.execute(input.countryName);
      this.form.country = res.filter((item: Country) => item.code == input.countryCode)[0];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para obtener todos los registros historicos de los rates de una moneda
  async getHistoricRates(currencyCode: string) {
    try {
      this.isLoading = true;
      const res = (await this.currencyFactorFindByCurrencyCode.execute(currencyCode)) ?? [];
      this.currencyFactorList = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para abrir el modal de estadisticas
  async openModal(currency: any) {
    await this.getHistoricRates(currency.currencyCode);
    this.$bvModal.show('history');
  }

  factoryUpdate() {
    this.form.date == this.dateNow ? this.updateRate() : ((this.form.date = this.dateNow), this.createRate());
  }

  factory() {
    !this.buttonOptions.isEdit ? this.save() : this.factoryUpdate();
  }
}
