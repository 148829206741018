import { Injectable } from '@/core/di/Injectable';
import { Query } from '@/core/cqrs/Query';
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { StateManager } from '@/core/statemanager/StateManager';
import { ZoneL } from '@/wms/domain/layout/zone/ZoneL';
import { ZoneLRepository } from '@/wms/domain/layout/zone/ZoneLRepository';

@Injectable()
export class FindAllZone extends Query<Promise<ZoneL[]>> {
  public constructor(
    @Inject(TYPES.LAYOUT_ZONE_REPOSITORY)
    private readonly zoneRepository: ZoneLRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(): Promise<ZoneL[]> {
    const dataLayoutZone = await this.zoneRepository.findAll();
    this.stateManager.patch({ dataLayoutZone });
    return this.stateManager.state.dataLayoutZone;
  }
}
