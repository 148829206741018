import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { CurrentMonth } from '@/settings/domain/currentMonth/CurrentMonth';
import { CurrentMonthRepository } from '@/settings/domain/currentMonth/CurrentMonthRepository';

@Injectable()
export class CurrentMonthService implements CurrentMonthRepository {
  /**
   * @description Ruta base del API
   * @type {string}
   */
  private readonly API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;

  /**
   * @description Ruta del API para crear documentos
   * @type {string}
   */
  private readonly API_CURRENT_MONTH: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_CURRENT_MONTH;

  async getCurrentMonth(): Promise<CurrentMonth> {
    return await http.getText(this.API_CURRENT_MONTH);
  }
  async updateCurrentMonth(): Promise<void> {
    return await http.VOID_PUT<CurrentMonth>(this.API_CURRENT_MONTH, null);
  }
}
