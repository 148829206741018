import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { Branch } from '@/settings/domain/branch/Branch';
import { BranchRepository } from '@/settings/domain/branch/BranchRepository';
@Injectable()
export class FindAllBranch extends Query<Promise<Branch[]>> {
  public constructor(@Inject(TYPES.BRANCH_REPOSITORY) private readonly branchRepository: BranchRepository) {
    super();
  }

  async internalExecute(): Promise<Branch[]> {
    try {
      const res = await this.branchRepository.findAll();
      if ('error' in res) {
        return [];
      }
      return res;
    } catch (e) {
      return [];
    }
  }
}
