
import Vue from 'vue';
import Component from 'vue-class-component';
import CreateOrderN from './CreateOrderN.vue';
@Component({
  name: 'CreateAsistedOrder',
  components: { CreateOrderN }
})
export default class CreateAsistedOrder extends Vue {
  orderType = 2;
}
