import { Injectable } from '@/core/di/Injectable';
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { LayoutConfigRepository } from '@/wms/domain/layout/configResizing/LayoutConfigRepository';
import { ResizingZone } from '@/wms/domain/layout/configResizing/ResizingZone';
import { Command } from '@/core/cqrs/Command';

@Injectable()
export class DeleteZoneLayoutConfig extends Command<Promise<any>, ResizingZone> {
  public constructor(
    @Inject(TYPES.LAYOUT_CONFIG_REPOSITORY)
    private readonly repository: LayoutConfigRepository
  ) {
    super();
  }

  async internalExecute(resizingZone: ResizingZone): Promise<ResizingZone> {
    return await this.repository.deleteZone(resizingZone);
  }
}
