import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { ShippingMethodRepository } from '@/settings/domain/shippingMethod/ShippingMethodRepository';
import { ShippingMethod } from '@/settings/domain/shippingMethod/ShippingMethod';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class ShippingMethodService implements ShippingMethodRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_SHIPPING_METHOD_SAVE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_SHIPPING_METHOD_SAVE;
  private readonly API_SHIPPING_METHOD_UPDATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_SHIPPING_METHOD_UPDATE;
  private readonly API_SHIPPING_METHOD_DELETE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_SHIPPING_METHOD_DELETE;
  private readonly API_SHIPPING_METHOD_FINDALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_SHIPPING_METHOD_FINDALL;
  private readonly API_SHIPPING_METHOD_FIND_BY_ID: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_SHIPPING_METHOD_FIND_BY_ID;
  private readonly API_SHIPPING_METHOD_FIND_BY_STATUS: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_SHIPPING_METHOD_FIND_BY_STATUS;

  async save(dto: ShippingMethod): Promise<ShippingMethod> {
    try {
      return await http.post<ShippingMethod>(this.API_SHIPPING_METHOD_SAVE, JSON.stringify(dto));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(dto: ShippingMethod): Promise<any> {
    try {
      return await http.put<ShippingMethod>(this.API_SHIPPING_METHOD_UPDATE, JSON.stringify(dto));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async delete(dto: ShippingMethod): Promise<ShippingMethod> {
    try {
      return await http.delete<ShippingMethod>(this.API_SHIPPING_METHOD_DELETE, JSON.stringify(dto));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findAll(): Promise<ShippingMethod[]> {
    try {
      return await http.get<ShippingMethod[]>(this.API_SHIPPING_METHOD_FINDALL);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findByStatus(status: boolean): Promise<ShippingMethod[]> {
    try {
      return await http.get<ShippingMethod[]>(`${this.API_SHIPPING_METHOD_FIND_BY_STATUS}${status}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findById(id: string): Promise<ShippingMethod> {
    try {
      return await http.get<ShippingMethod>(`${this.API_SHIPPING_METHOD_FIND_BY_ID}${id}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
