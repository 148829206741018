
import VueResizable from 'vue-resizable';
import Component from 'vue-class-component';
import Vue from 'vue';
import Draggable from 'vuedraggable';
import { ResizingZone } from '@/wms/domain/layout/configResizing/ResizingZone';
import LayoutZone from '@/wms/infrastructure/ui/Layout/zone/LayoutZone.vue';
import LayoutRack from '@/wms/infrastructure/ui/Layout/rack/LayoutRack.vue';
import AddStore from '@/wms/infrastructure/ui/Layout/store/AddStore.vue';
import { Prop } from 'vue-property-decorator';
import { Warehouse } from '@/settings/domain/warehouse/Warehouse';
import { Store } from '@/wms/domain/layout/store/Store';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { CreateStore } from '@/wms/application/layout/store/create/CreateStore';
import { FindAllStorebyWarehouse } from '@/wms/application/layout/store/search/FindAllStorebyWarehouse';
import { ZoneL } from '@/wms/domain/layout/zone/ZoneL';
import { FindAllZone } from '@/wms/application/layout/zone/search/FindAllZone';
import { FindCommodityTypeByStatus } from '@/freight/application/commodity_type/search/FindCommodityTypesByStatus';
import { CreateLayoutConfig } from '@/wms/application/layout/config/create/CreateLayoutConfig';
import { FindLayoutConfigByWarehouseAndStore } from '@/wms/application/layout/config/search/FindLayoutConfigByWarehouseAndStore';
import { ValidationObserver } from 'vee-validate';
import { DeleteZoneLayoutConfig } from '@/wms/application/layout/config/delete/DeleteZoneLayoutConfig';
import { DeleteRackLayoutConfig } from '@/wms/application/layout/rack/delete/DeleteRackLayoutConfig';
import Swal from 'sweetalert2';
import { FileFindAll } from '@/settings/application/uses_cases/file/search/FileFindAll';
import { DeleteZoneL } from '@/wms/application/layout/zone/delete/DeleteZone';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { EntityFindFilter } from '@/settings/application/uses_cases/entity/search/EntityFindFilter';
import { Rack } from '@/wms/domain/layout/rack/Rack';
import { FindAllByStatusRack } from '@/wms/application/layout/rack/search/FindAllByStatusRack';
import { CreateRack } from '@/wms/application/layout/rack/create/CreateRack';
import { UpdateRack } from '@/wms/application/layout/rack/update/UpdateRack';
import { FindFile } from '@/settings/application/uses_cases/file/search/FindFile';
import { CreateFileV2 } from '@/settings/application/uses_cases/file/create/CreateFileV2';
import { FindZoneNotConfigured } from '@/wms/application/layout/zone/search/FindAllZoneNotConfigured';
import { SetRacksToConfigZone } from '@/wms/application/layout/config/update/SetRacksToConfigZone';
import { GenerateTagPdf } from '@/wms/application/location/search/GenerateTagPdf';
import { Locations } from '@/wms/domain/layout/locations/Locations';
import { GenerateLocationTagsByZone } from '@/wms/application/location/search/GenerateLocationTagsByZone';

@Component({
  name: 'LayoutStore',
  components: { VueResizable, Draggable, LayoutZone, LayoutRack, AddStore },
  filters: {
    checkEmpty(value: any) {
      return typeof value !== 'number' ? 0 : value;
    }
  }
})
export default class LayoutStore extends Vue {
  @Inject(TYPES.CREATE_STORE)
  readonly createStore!: CreateStore;
  @Inject(TYPES.FIND_ALL_STORE_WAREHOUSE)
  readonly FindAllStorebyWarehouse!: FindAllStorebyWarehouse;
  @Inject(TYPES.FINDALL_LAYOUT_ZONE)
  readonly findAllZone!: FindAllZone;
  @Inject(TYPES.FIND_RACKS_BY_STATUS)
  readonly findAllRacks!: FindAllByStatusRack;
  @Inject(TYPES.COMMODITY_TYPE_FIND_BY_STATUS)
  readonly findCommodityByStatus!: FindCommodityTypeByStatus;
  @Inject(TYPES.LAYOUT_CONFIG_SAVE)
  readonly createLayoutConfig!: CreateLayoutConfig;
  @Inject(TYPES.LAYOUT_CONFIG_FIND)
  readonly FindLayoutConfig!: FindLayoutConfigByWarehouseAndStore;
  @Inject(TYPES.LAYOUT_CONFIG_DELETE_ZONE)
  readonly deleteZoneFromdb!: DeleteZoneLayoutConfig;
  @Inject(TYPES.LAYOUT_CONFIG_DELETE_RACK)
  readonly deleteRackFromdb!: DeleteRackLayoutConfig;
  @Inject(TYPES.FINDALL_FILE)
  readonly fileFindAll!: FileFindAll;
  @Inject(TYPES.DELETE_LAYOUT_ZONE)
  readonly deleteMainZone!: DeleteZoneL;
  @Inject(TYPES.ENTITY_FIND_BY_DESCRIPTION_LIKE)
  readonly customerFindFilter!: EntityFindFilter;
  @Inject(TYPES.CREATE_RACKS)
  readonly CreateRack!: CreateRack;
  @Inject(TYPES.UPDATE_RACKS)
  readonly UpdateRack!: UpdateRack;
  @Inject(TYPES.FIND_FILE_BY_NAME)
  readonly findFileByName!: FindFile;
  @Inject(TYPES.SAVE_FILE)
  readonly saveFile!: CreateFileV2;
  @Inject(TYPES.FINDALL_LAYOUT_ZONE_NOT_CONFIGURED)
  readonly findAllNotConfiguredZone!: FindZoneNotConfigured;
  @Inject(TYPES.SET_RACKS_CONFIG_TO_LAYOUT_ZONE)
  readonly setRacksToConfigZone!: SetRacksToConfigZone;
  @Inject(TYPES.LOCATIONS_GET_TAG_PDF)
  readonly getTagPdf!: GenerateTagPdf;
  @Inject(TYPES.LOCATIONS_GET_TAG_PDF_BY_ZONE)
  readonly getTagPdfByZone!: GenerateLocationTagsByZone;

  handlers = ['r', 'rb', 'b', 'lb', 'l', 'lt', 't', 'rt'];

  resizingZone: ResizingZone[] = [];
  event = '';
  store: Store | null = null;
  storeWarehouse: Store[] = [];
  storeLayout = '';
  confirmStep1 = false;
  zoneSelect: ResizingZone | null = null;
  zoneList: ZoneL[] = [];
  notConfiguredZoneList: ZoneL[] = [];
  myArrayRack: any[] = [];
  commodityList = [] as any;
  entities: Entity[] = [];
  isLoading = false;
  form: Rack = new Rack();
  isEdit = false;
  isEditRack = false;
  showZones = false;
  showNotConfiguredZones = false;

  $refs!: {
    LayoutRackMdl: HTMLFormElement;
    LayoutZoneMdl: HTMLFormElement;
    ConfigRacksMdl: HTMLFormElement;
    layoutStore: HTMLFormElement;
    rackForm: InstanceType<typeof ValidationObserver>;
  };

  @Prop({ type: Object, required: true }) readonly warehouse!: Warehouse;

  created() {
    this.findAllbyWarehouse(this.warehouse.code);
    this.findRacks();
    this.findZone();
    this.getAllCommodityActive();
  }

  get handlersComputed() {
    if (this.confirmStep1) return [];

    return this.handlers;
  }

  get filteredZoneList() {
    return this.zoneList.filter((item: any) => {
      return this.searchInZones(item.zoneId) ? false : true;
    });
  }

  set filteredZoneList(newValue: any) {
    this.zoneList = newValue;
  }

  get filteredRacks() {
    return this.myArrayRack.filter(item => {
      return this.searchInRacks(item.id) ? false : true;
    });
  }

  set filteredRacks(newValue: any) {
    this.filteredRacks = newValue;
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  customPlaceholderStore(store: Store) {
    return `${store.storeId} - ${store.storeName}`;
  }

  async deleteZone(index: any, element: any) {
    const confirm = await this.confirmAlert();
    if (!confirm.isConfirmed) return;
    this.isLoading = true;
    if ('id' in element && element.id != 0) {
      await this.deleteZoneFromdb.execute(element).then(() => {
        this.isLoading = false;
        this.findLayoutConfig(this.store);
      });
    }
    const idx = this.resizingZone.indexOf(element);
    if (idx > -1) {
      this.resizingZone.splice(idx, 1);
    }
    this.isLoading = false;
  }
  async deleteRack(index: any, element: any) {
    const confirm = await this.confirmAlert();
    if (!confirm.isConfirmed) return;
    this.isLoading = true;
    if (element.zoneId) {
      await this.deleteRackFromdb.execute(element).then(() => {
        this.isLoading = false;
        this.findLayoutConfig(this.store);
      });
    }
    const idx = this.zoneSelect?.rack.indexOf(element) ?? -1;
    if (idx > -1) {
      this.zoneSelect?.rack.splice(idx, 1);
    }
    this.isLoading = false;
  }

  //Verificar esta funcion para revisar que todos los datos en los racks se hayan completado
  checkRacks() {
    const lastRack = this.zoneSelect;
    let isOk = false;
    this.resizingZone.map(async item => {
      this.zoneSelect = item;
      isOk = await this.$refs.rackForm.validate();
    });
    this.zoneSelect = lastRack;
    return isOk;
  }

  openRacksModal(config: any) {
    if (this.confirmStep1) {
      this.zoneSelect = config;
      this.$refs.ConfigRacksMdl.show();
    }
  }

  async confirmLayout(step: number) {
    if (step === 1) {
      this.confirmStep1 = true;
    }

    if (this.zoneSelect && this.confirmStep1 && !(await this.$refs.rackForm.validate())) {
      this.$swal(`${this.$t('general.warning')}`, `${this.$t('general.fillAllFields')}`, 'warning');
      return;
    }

    // Buscamos la zona en el arreglo de zonas
    const findZone = this.resizingZone.find(zone => {
      return 'zoneId' in zone && zone?.zoneId === this.zoneSelect?.zoneId;
    });

    // Si la zona existe, se le asigna el rack (No se para qué se hace esto pero se deja por si acaso)
    if (findZone) {
      Object.assign(findZone, { rack: this.zoneSelect?.rack });
    }

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    // Modificamos el arreglo de zonas para que se guarde en la base de datos y enviar solo lo necesario y evitar payloads largos
    this.resizingZone = this.resizingZone.map(item => {
      return {
        ...item,
        rack: [],
        innerHeight: windowHeight,
        innerWidth: windowWidth
      };
    });

    // Guardamos la configuración de la zona
    this.isLoading = true;
    this.checkLayoutProperties();
    this.createLayoutConfig
      .execute(this.resizingZone)
      .then(async (response: any) => {
        this.$refs.ConfigRacksMdl.hide();
        await this.findLayoutConfig(this.store);
        this.findAllbyWarehouse(this.warehouse.code);
        return response;
      })
      .catch((err: any) => {
        throw new Error(`${err}`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
  cancelLayout(step: number) {
    if (step === 1) {
      this.confirmStep1 = false;
    }
  }
  setSelectedZone(config: any) {
    if (this.confirmStep1) {
      this.zoneSelect = config;
    }
  }
  async findLayoutConfig(optionSelected: Store | null) {
    if (!optionSelected) return;
    this.confirmStep1 = false;
    this.isLoading = true;

    // Buscamos la imagen en el servidor
    this.storeLayout = this.findFileByName.execute(optionSelected.imgStore);

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const pyload = {
      state: true,
      warehouseCode: this.warehouse.code,
      storeId: optionSelected.storeId,
      windowHeight: windowHeight,
      windowWidth: windowWidth
    };

    await this.FindLayoutConfig.execute(pyload)
      .then((response: any) => {
        this.isLoading = false;
        this.resizingZone = response;
        if (this.resizingZone.length > 0) {
          this.confirmStep1 = true;
        }
      })
      .catch((err: any) => {
        this.isLoading = false;
        throw new Error(`${err}`);
      });
  }

  findRacks() {
    this.isLoading = true;
    this.findAllRacks
      .execute(true)
      .then((resp: any) => {
        this.myArrayRack = resp;
        this.isLoading = false;
      })
      .catch((err: any) => {
        this.isLoading = false;
        throw new Error(`${err}`);
      });
  }
  controllerModal() {
    this.$refs['LayoutRackMdl'].hide();
    this.findRacks();
  }
  controllerModalZone() {
    this.$refs['LayoutZoneMdl'].hide();
    this.findZone();
  }
  controllerModalStore() {
    if (this.store) this.store = null;
    this.$refs.layoutStore.hide();
    this.findAllbyWarehouse(this.warehouse.code);
  }
  addTagStore(newTag: string) {
    if (newTag.length > 20) {
      this.$swal(
        `${this.$t('general.warning')}`,
        `${this.$t('general.charlenght')}`.replace('$', `${this.$t('general.store')}`.toLowerCase()).replace('%', '20'),
        'warning'
      );
      return;
    }
    const store: Store = new Store();
    store.storeId = 0;
    store.storeName = newTag;
    store.active = true;
    store.warehouse = this.warehouse;
    this.createStore.execute(store).then(() => {
      this.findAllbyWarehouse(this.warehouse.code);
    });
  }
  findAllbyWarehouse(code: any) {
    this.FindAllStorebyWarehouse.execute(code)
      .then((resp: any) => {
        this.storeWarehouse = Array.isArray(resp) ? resp : [];
      })
      .catch((err: any) => {
        throw new Error(`${err}`);
      });

    return null;
  }
  findZone() {
    this.isLoading = true;
    this.findAllNotConfiguredZone
      .execute()
      .then((resp: any) => {
        this.zoneList = resp || ([] as ZoneL[]);
        this.isLoading = false;
      })
      .catch((err: any) => {
        this.isLoading = false;
        throw new Error(`${err}`);
      });
  }
  getAllCommodityActive() {
    this.findCommodityByStatus
      .execute(true)
      .then((response: any) => {
        this.commodityList = response;
      })
      .catch(err => {
        this.commodityList = [];
        throw new Error(`${err}`);
      });
  }
  getEntities(query: string) {
    if (query.length >= 3) {
      const params: any = {
        text: query,
        type: ['SUPPLIER', 'CUSTOMER', 'SERVICE', 'OTHERS']
      };
      this.isLoading = true;
      this.customerFindFilter
        .execute(params)
        .then((response: any) => {
          this.entities = response;
          this.isLoading = false;
        })
        .catch((err: any) => {
          this.isLoading = false;
          throw new Error(`${err}`);
        });
    }
  }

  detectMove(evt: any) {
    //Accede al mapa de nodos del evento custom, en la propiedad de atributos, y dentro de este accede al nodo de de nombre "name"
    // independiente de su indice, en el cual obtiene el valor del id de la zona
    const item = evt.item.attributes.getNamedItem('name').value;

    if (item && this.resizingZone.length > 0) {
      const newArray = this.resizingZone.map((zone: any) => {
        if (zone.zoneId === item) {
          //object Config for initial ResizingZone
          const obj = new ResizingZone();
          // set key and name zone for config obj
          obj.zoneId = zone.zoneId;
          obj.zoneDescription = zone.zoneDescription;
          obj.storeId = this.store?.storeId || 0;
          obj.state = true;
          //return new object update include zone object
          return { zone, ...obj };
        }
        //return obj zone old
        return zone;
      });

      this.resizingZone = newArray.slice(); //copy aux array in config array
    }
  }
  eHandler(config: any, data: any) {
    config.width = data.width;
    config.height = data.height;
    config.left = data.left;
    config.top = data.top;
  }

  sortRackList() {
    if (this.zoneSelect?.rack)
      this.zoneSelect.rack =
        this.zoneSelect?.rack.sort((a: Rack, b: Rack) => {
          return Number(a.secuenceCode) - Number(b.secuenceCode);
        }) ?? [];
  }

  parseObj() {
    if (this.zoneSelect?.rack)
      this.zoneSelect.rack = [...this.zoneSelect.rack].sort((a: Rack, b: Rack) => {
        if (a.secuenceCode === null || a.secuenceCode === '') {
          return -1;
        }
        if (b.secuenceCode === null || b.secuenceCode === '') {
          return 1;
        }
        return a < b ? 1 : -1;
      });
  }

  get warehouseSelected() {
    return this.warehouse.code + ' - ' + this.warehouse.description;
  }

  searchInZones(element: string) {
    let encontrado = false;
    this.resizingZone.map(item => {
      if (item.zoneId.toString() == element) {
        encontrado = true;
        return;
      }
    });
    return encontrado;
  }

  searchInRacks(element: string) {
    if (this.zoneSelect == null) return;
    let encontrado = false;
    this.zoneSelect.rack.map((item: any) => {
      if (item.id == element) {
        encontrado = true;
        return;
      }
    });
    return encontrado;
  }

  checkLayoutProperties() {
    this.resizingZone = this.resizingZone.map(item => ({
      ...item,
      left: typeof item.left == 'string' ? '0' : item.left
    }));
  }

  async confirmAlert() {
    return await Swal.fire({
      title: `${this.$t('general.confirmAction')}`,
      icon: 'info',
      text: `${this.$t('general.areyousuretodeletethis')}`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `${this.$t('general.delete')}`,
      cancelButtonText: `${this.$t('general.cancel')}`
    });
  }

  async deleteZoneL(element: ZoneL) {
    const confirm = await this.confirmAlert();
    if (!confirm.isConfirmed) return;
    this.isLoading = true;
    const res = await this.deleteMainZone.execute(element);
    if ('id' in res) this.findZone();
    this.isLoading = false;
  }

  setRack(element: Rack) {
    this.isEditRack = true;
    this.form = Object.assign({}, element);
  }

  /**
   * @description Inactiva un rack
   */

  async inactivateRack(element: Rack) {
    try {
      const confirm = await this.confirmAlert();
      if (!confirm.isConfirmed) return;
      this.isLoading = true;
      element.active = false;
      await this.UpdateRack.execute(element);
      this.isLoading = false;
      this.findRacks();
      this.findZone();
    } catch (error) {
      this.isLoading = false;
    }
  }

  closeModalRack() {
    this.isEditRack = false;
    this.form = new Rack();
  }

  showAvailableZones() {
    this.showZones = !this.showZones;
  }

  async findConfiguredZones() {
    const res = await this.findAllZone.execute();
    this.zoneList = res;
  }

  showConfigured() {
    this.showNotConfiguredZones = !this.showNotConfiguredZones;
    if (this.showNotConfiguredZones) {
      this.findConfiguredZones();
    }
    if (!this.showNotConfiguredZones) {
      this.findZone();
    }
  }

  async saveZoneConfig() {
    // Validar que se haya seleccionado una zona
    if (!this.zoneSelect) {
      this.$swal(`${this.$t('general.warning')}`, `${this.$t('general.selectZone')}`, 'warning');
      return;
    }

    // Validar que se haya seleccionado una bodega
    if (!this.store) {
      this.$swal(`${this.$t('general.warning')}`, `${this.$t('general.selectStore')}`, 'warning');
      return;
    }

    // Tomamos los racks asociados a la zona
    const racks = this.zoneSelect.rack;

    // Validar que se haya seleccionado al menos un rack
    if (racks.length === 0) {
      this.$swal(`${this.$t('general.warning')}`, `${this.$t('general.addRack')}`, 'warning');
      return;
    }

    // Guardamos los racks en la zona con su respectiva información
    try {
      this.isLoading = true;
      await this.setRacksToConfigZone.internalExecute({
        zoneId: this.zoneSelect.zoneId,
        racks,
        storeId: this.store.storeId,
        layoutConfigId: this.zoneSelect?.id
      });

      this.$bvModal.hide('ConfigRacksMdl');
      this.zoneSelect = null;
    } catch (error) {
      this.$swal(`${this.$t('general.error')}`, `${this.$t('general.error')}`, 'error');
    } finally {
      this.isLoading = false;
      this.findLayoutConfig(this.store);
    }
  }

  async generateTagPdf(location: Locations) {
    try {
      this.isLoading = true;
      const URL = await this.getTagPdf.internalExecute(location);

      // Generamos un link para descargar el archivo
      const link = document.createElement('a');

      link.setAttribute('href', URL);
      link.setAttribute('download', 'tag.pdf');

      link.click();
    } catch (error) {
      this.$swal(`${this.$t('general.error')}`, `${this.$t('general.error')}`, 'error');
    } finally {
      this.isLoading = false;
    }
  }

  async generateTagPdfByZone() {
    try {
      if (!this.zoneSelect) {
        this.$swal(`${this.$t('general.warning')}`, `${this.$t('general.selectZone')}`, 'warning');
        return;
      }

      this.isLoading = true;
      const URL = await this.getTagPdfByZone.internalExecute(this.zoneSelect);

      // Generamos un link para descargar el archivo
      const link = document.createElement('a');

      link.setAttribute('href', URL);
      link.setAttribute('download', `${this.zoneSelect.zoneId}-zone-tags.pdf`);

      link.click();
    } catch (error) {
      this.$swal(`${this.$t('general.error')}`, `${this.$t('general.error')}`, 'error');
    } finally {
      this.isLoading = false;
    }
  }

  // updateWindowSize() {
  //   this.windowHeight = window.innerHeight;
  //   this.windowWidth = window.innerWidth;
  //   // eslint-disable-next-line
  //   console.log(this.windowWidth);
  // }
}
