import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Routes } from '@/settings/domain/routes/Routes';
import { RoutesRepository } from '@/settings/domain/routes/RoutesRepository';
import { RequestPricelistPayload } from '@/core/helper/RequestPricelistPayload';

@Injectable()
export class RoutesFindByOiriginAndDestination extends Query<Promise<Routes>, RequestPricelistPayload> {
  public constructor(
    @Inject(TYPES.ROUTES_REPOSITORY)
    private readonly routesRepository: RoutesRepository
  ) {
    super();
  }
  async internalExecute(payload: RequestPricelistPayload): Promise<Routes> {
    const res = await this.routesRepository.findByOriginAndDestination(payload);
    return res;
  }
}
