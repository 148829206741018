import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { StockRepository } from '@/wms/domain/stock/StockRepository';
import { StockDocumentsLin } from '@/wms/domain/stock/StockDocumentsLin';

@Injectable()
export class FindFreightMovements extends Query<
  Promise<StockDocumentsLin[]>,
  {
    fMonth: string;
    iMonth: string;
    serial: string;
    store: string;
    year: string;
    entity: string;
  }
> {
  public constructor(
    @Inject(TYPES.STOCK_REPOSITORY)
    private readonly stockRepository: StockRepository
  ) {
    super();
  }
  async internalExecute(payload: {
    fMonth: string;
    iMonth: string;
    serial: string;
    store: string;
    year: string;
    entity: string;
  }): Promise<StockDocumentsLin[]> {
    return await this.stockRepository.findFreightStocklMovements(payload);
  }
}
