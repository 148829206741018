import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { PackingUnits } from '@/settings/domain/packing_units/PackingUnits';
import { PackingUnitsRepository } from '@/settings/domain/packing_units/PackingUnitsRepository';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';

@Injectable()
export class DeletePackingUnits extends Command<Promise<PackingUnits>, PackingUnits> {
  public constructor(
    @Inject(TYPES.PACKING_UNITS_REPOSITORY)
    private readonly packingUnitsRepository: PackingUnitsRepository
  ) {
    super();
  }

  internalExecute(packingUnits: PackingUnits): Promise<PackingUnits> {
    return this.packingUnitsRepository.delete(packingUnits);
  }
}
