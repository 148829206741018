import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { BillOfLandingRepository } from '@/wms/domain/billOfLanding/BillOfLandingRepository';
import { BillOfLanding } from '@/wms/domain/billOfLanding/BillOfLanding';

@Injectable()
export class CreateBillOfLandingForPreRegister extends Command<
  Promise<BillOfLanding>,
  {
    billOfLanding: BillOfLanding;
    preRegisterType: string;
    preRegisterNumber: number;
  }
> {
  public constructor(
    @Inject(TYPES.BILL_OF_LANDING_REPOSITORY)
    private readonly repository: BillOfLandingRepository
  ) {
    super();
  }

  public internalExecute(payload: {
    billOfLanding: BillOfLanding;
    preRegisterType: string;
    preRegisterNumber: number;
  }): Promise<BillOfLanding> {
    return this.repository.saveForPreRegister(payload);
  }
}
