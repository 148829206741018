import { render, staticRenderFns } from "./DashboardDefault.vue?vue&type=template&id=dc3b2b98&scoped=true"
import script from "./DashboardDefault.vue?vue&type=script&lang=ts"
export * from "./DashboardDefault.vue?vue&type=script&lang=ts"
import style0 from "./DashboardDefault.vue?vue&type=style&index=0&id=dc3b2b98&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc3b2b98",
  null
  
)

export default component.exports