
import Vue from 'vue';
import Component from 'vue-class-component';
import ReportCenter from './ReportCenter.vue';

@Component({
  name: 'ReportForm',
  components: {
    ReportCenter
  }
})
export default class WMSReports extends Vue {

}
