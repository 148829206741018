
import Vue from 'vue';
import Component from 'vue-class-component';
import Header from '@/core/components/dashboard/Header.vue';
import Sidebar from '@/core/components/dashboard/Sidebar.vue';
import RightSidebar from '@/core/components/dashboard/RightSidebar.vue';
import LayoutStore from '@/core/store/modules/Layout';
import MenuStore from '@/core/store/modules/Menu';
import { Watch } from 'vue-property-decorator';
import Customizer from '@/core/components/dashboard/Customizer.vue';
import Login from '@/courier/infrastructure/ui/components/login/Login.vue';

@Component({
  name: 'Main',
  components: {
    Customizer,
    Header,
    Sidebar,
    RightSidebar,
    Login
  }
})
export default class Main extends Vue {
  rightsidebarToggleVar = false;
  mobileheaderToggleVar = false;
  sidebarToggleVarData = false;
  resized = false;
  width = 0;
  height = 0;

  //Hook
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.resized = this.sidebarToggleVarData;
    this.$store.dispatch('Layout/setAct');
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    if (this.menuItems && this.menuItems.length > 0) {
      this.menuItems.filter((items: any) => {
        if (items.path === this.$route.path) MenuStore.setActiveRouteAct(items);
        if (!items.children) return false;
        items.children.filter((subItems: any) => {
          if (subItems.path === this.$route.path) MenuStore.setActiveRouteAct(subItems);
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems: { path: string }) => {
            if (subSubItems.path === this.$route.path) MenuStore.setActiveRouteAct(subSubItems);
          });
        });
      });
    }
  }
  @Watch('')
  sidebarToggleVar() {
    this.resized = this.width <= 991 ? !this.sidebarToggleVarData : this.sidebarToggleVarData;
  }

  //Property Computed
  get layout() {
    return LayoutStore.layout;
  }
  get menuItems() {
    return MenuStore.data;
  }

  get verifyToken() {
    const dateExpiration: any = Number(localStorage.getItem('dateExpirationCompany'));
    const date: any = new Date().getTime();

    if (date > dateExpiration) {
      // destroyToken();
      return true;
    }
    return false;
  }

  //Methods
  sidebarToggle(value: boolean) {
    this.sidebarToggleVarData = !value;
  }
  rightsidebarToggle(value: boolean) {
    this.rightsidebarToggleVar = value;
  }
  mobiletoggleToggle(value: boolean) {
    this.mobileheaderToggleVar = value;
  }
  handleResize() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
  }
}
