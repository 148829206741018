
import LanguageSwitcher from '@/core/components/LanguageSwitcher.vue';
import Component from 'vue-class-component';
import Vue from 'vue';
import router from '@/core/router';
import { getToken, requestGetRol, requestPost } from '@/core/plugins/FunctionUtil';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { CompanyFindAll } from '@/settings/application/uses_cases/company/search/CompanyFindAll';

@Component({
  name: 'CreateCompany',
  components: {
    LanguageSwitcher
  }
})
export default class Login extends Vue {
  @Inject(TYPES.FINDALL_COMPANY)
  readonly companyFindAllcall!: CompanyFindAll;
  $refs!: {
    childComponent: HTMLFormElement;
    btnUnitofmeasurement: HTMLButtonElement;
  };

  //DATA
  widthResize = 0;
  isTouched = false;
  typeTransportList = [];
  modelTypetransport = [] as any;
  modelUnitofmeasurement = [] as any;
  form: any = {
    CountryCodeID: '',
    CountryName: '',
    StateCode: '',
    StateName: '',
    CurrencyName: '',
    CurrencyCode: '',
    CompanyID: '',
    CompanyName: '',
    CompanyPassword: '',
    LocalCurrency: '',
    CompanyUrlLogo: 'empty',
    CompanyEmail: '',
    CompanyPhone: '',
    palletsFactor: '',
    unitOfMeasurementCode: '',
    unitOfMeasurementDescription: '',
    booleanoNext: true,
    CompanyAddressLine: {
      Description: '',
      State: '',
      Country: '',
      CountryCurrency: ''
    }
  };
  //Hook
  mounted() {
    const displayNone = document.querySelector('.wizard-nav-pills');
    const displayNoneProgress = document.querySelector('.wizard-progress-with-circle');
    const displayNoneHeader = document.querySelector('.wizard-header');
    if (displayNone !== null) {
      displayNone.setAttribute('style', 'display:none !important');
    }
    if (displayNoneProgress !== null) {
      displayNoneProgress.setAttribute('style', 'display:none !important');
    }
    if (displayNoneHeader !== null) {
      displayNoneHeader.setAttribute('style', 'display:none !important');
    }
    this.$nextTick(() => this.typeTransport);
    this.$nextTick(() => this.validateNextStep);
  }
  //Computed
  get typeTransport() {
    getToken().then((res: any) => {
      const response = requestGetRol('pallets/factor/findAll', res);
      response
        .then((response: any) => response)
        .then((data: any) => {
          this.typeTransportList = data;
        })
        .catch((error: any) => {
          throw new Error(error);
        });
    });
    return this.typeTransportList;
  }
  get validateNextStep() {
    getToken().then((res: any) => {
      const promisesUnitOfMeasure = [
        requestGetRol('weight-unit/findAll', res),
        requestGetRol('volume-unit/findAll', res)
      ];
      const response = Promise.all(promisesUnitOfMeasure);
      response.then((response: any) => {
        if (response[0].length > 0 || response[1].length > 0) {
          this.form.booleanoNext = false;
        } else {
          this.form.booleanoNext = true;
        }
      });
    });
    return this.form.booleanoNext;
  }
  //Method
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  registerCountry() {
    const payload: any = {
      code: this.form.CountryCodeID,
      name: this.form.CountryName
    };
    getToken()
      .then((res: any) => {
        const response = requestPost('country/save', JSON.stringify(payload), res);
        response.then((response: any) => {
          if (response.ok) {
            this.$refs.childComponent.nextTab();
          } else {
            return false;
          }
        });
      })
      .catch((error: any) => {
        throw new Error(error);
      });
  }
  registerStates() {
    const payloadState: any = {
      code: this.form.StateCode,
      name: this.form.StateName,
      countryCode: this.form.CountryCodeID,
      countryName: this.form.CountryName
    };
    getToken()
      .then((res: any) => {
        const response = requestPost('states/save', JSON.stringify(payloadState), res);
        response.then((response: any) => {
          if (response.ok) {
            this.$refs.childComponent.nextTab();
          } else {
            return false;
          }
        });
      })
      .catch((error: any) => {
        throw new Error(error);
      });
  }
  registerCurrency() {
    const payload: any = {
      countryCode: this.form.CountryCodeID,
      countryName: this.form.CountryName,
      currencyCode: this.form.CurrencyCode,
      description: this.form.CurrencyName
    };
    getToken().then((res: any) => {
      const response = requestPost('currency/save', JSON.stringify(payload), res);
      response
        .then((response: any) => {
          if (response.ok) {
            this.$refs.childComponent.nextTab();
          } else {
            return false;
          }
        })
        .catch((error: any) => {
          throw new Error(error);
        });
    });
  }
  registerPalletsFactor() {
    const payload: any = {
      code: this.modelTypetransport.code,
      description: this.modelTypetransport.description,
      factor: this.form.palletsFactor
    };
    getToken().then((res: any) => {
      const response = requestPost('pallets/factor/update', JSON.stringify(payload), res);
      response
        .then((response: any) => {
          if (response.ok) {
            this.$refs.childComponent.nextTab();
          } else {
            return false;
          }
        })
        .catch((error: any) => {
          throw new Error(error);
        });
    });
  }
  registerUnitOfMeasurement() {
    const payload: any = {
      code: this.form.unitOfMeasurementCode,
      description: this.form.unitOfMeasurementDescription
    };
    getToken().then((res: any) => {
      let response: any;
      if (this.modelUnitofmeasurement.code === '001') {
        response = requestPost('weight-unit/save', JSON.stringify(payload), res);
      } else {
        response = requestPost('volume-unit/save', JSON.stringify(payload), res);
      }
      response
        .then(() => {
          this.form.booleanoNext = false;
          return false;
        })
        .catch((error: any) => {
          this.form.booleanoNext = true;
          throw new Error(error);
        });
    });
  }
  nextStep() {
    this.$refs.childComponent.nextTab();
    const tabCompany = document.querySelector('.market-tabcontent');
    if (tabCompany !== null) {
      tabCompany.setAttribute('style', 'height: 670px !important');
    }
  }
  registerCompany() {
    const payload: any = {
      addressLine: {
        country: this.form.CountryCodeID,
        description: this.form.CompanyAddressLine.Description,
        state: this.form.StateCode,
        countryCurrency: this.form.CurrencyCode
      },
      businessName: this.form.CompanyName,
      email: this.form.CompanyEmail,
      id: this.form.CompanyID,
      localCurrency: this.form.CurrencyCode,
      password: this.form.CompanyPassword,
      phoneNumber: this.form.CompanyPhone,
      urlLogo: this.form.CompanyUrlLogo,
      defaultCurrency: this.form.CurrencyCode
    };
    getToken()
      .then((res: any) => {
        const response = requestPost('setting/save', JSON.stringify(payload), res);
        response.then((response: any) => {
          if (response.ok) {
            const tabCompany = document.querySelector('.market-tabcontent');
            if (tabCompany !== null) {
              tabCompany.setAttribute('style', 'height: 480px !important');
            }
            router.replace({ name: 'Login' });
          } else {
            return false;
          }
        });
      })
      .catch((error: any) => {
        throw new Error(error);
      });
  }
}
