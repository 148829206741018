import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { Product } from '@/wms/domain/catalogs/product/Product';
import { ProductRepository } from '@/wms/domain/catalogs/product/ProductRepository';

@Injectable()
export class ProductFindBySKU extends Query<Promise<Product[]>, string> {
  public constructor(
    @Inject(TYPES.PRODUCT_REPOSITORY)
    private readonly productRepository: ProductRepository
  ) {
    super();
  }

  async internalExecute(reference: string): Promise<Product[]> {
    return await this.productRepository.findBySKU(reference);
  }
}
