import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { PortRepository } from '@/freight/domain/ports/PortRepository';
import { Port } from '@/freight/domain/ports/Port';

@Injectable()
export class SearchPortsByStatus extends Query<Promise<Port[]>, boolean> {
  public constructor(
    @Inject(TYPES.PORT_REPOSITORY)
    private readonly portRepository: PortRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(status: boolean): Promise<Port[]> {
    const dataPorts = await this.portRepository.findPortsByStatus(status);
    this.stateManager.patch({ dataPorts });
    return this.stateManager.state.dataPorts;
  }
}
