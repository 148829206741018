import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { TypeCustomer } from '@/settings/domain/entity/typeCustomer/TypeCustomer';
import { TypeCustomerRepository } from '@/settings/domain/entity/typeCustomer/TypeCustomerRepository';

@Injectable()
export class TypeCustomerFindAll extends Query<Promise<TypeCustomer[]>> {
  public constructor(
    @Inject(TYPES.TYPE_CUSTOMER_REPOSITORY)
    private readonly repository: TypeCustomerRepository,

    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(): Promise<TypeCustomer[]> {
    const dataTypeCustomer = await this.repository.findAll();
    this.stateManager.patch({ dataTypeCustomer });
    return this.stateManager.state.dataTypeCustomer;
  }
}
