import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Role } from '@/settings/domain/role/Role';
import { RoleRepository } from '@/settings/domain/role/RoleRepository';

@Injectable()
export class RoleFindByCompanyId extends Query<Promise<Role[]>, string> {
  public constructor(
    @Inject(TYPES.ROLE_REPOSITORY)
    private readonly roleRepository: RoleRepository,
    @Inject(TYPES.STATE_MANAGER) private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(companyId: string): Promise<Role[]> {
    const dataRole = await this.roleRepository.findRolesByCompanyId(companyId);
    this.stateManager.patch({ dataRole });
    return this.stateManager.state.dataRole;
  }
}
