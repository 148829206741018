import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { Group } from '@/settings/domain/group/Group';
import { GroupRepository } from '@/settings/domain/group/GroupRepository';

/**
 * @name GroupService
 * @description Genera la clase para el servicio de grupo que hace peticiones al API
 * @implements GroupRepository
 * @author EnriquePallares
 */
@Injectable()
export class GroupService implements GroupRepository {
  /**
   * @name API_BASE_URI
   * @description Dirección base del API
   * @type {string}
   */
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;

  /**
   * @name API_GROUP_SAVE
   * @description Dirección del API para guardar un grupo
   * @type {string}
   */
  private readonly API_GROUP_SAVE: string = this.API_BASE_URI + localStorageModule().config.routes.API_GROUP_SAVE;

  /**
   * @name API_GROUP_UPDATE
   * @description Dirección del API para actualizar un grupo
   * @type {string}
   */
  private readonly API_GROUP_UPDATE: string = this.API_BASE_URI + localStorageModule().config.routes.API_GROUP_UPDATE;

  /**
   * @name API_GROUP_DELETE
   * @description Dirección del API para eliminar un grupo
   * @type {string}
   */
  private readonly API_GROUP_DELETE: string = this.API_BASE_URI + localStorageModule().config.routes.API_GROUP_DELETE;

  /**
   * @name API_GROUP_FIND_BY_ID
   * @description Dirección del API para obtener un grupo por su id
   * @type {string}
   */
  private readonly API_GROUP_FIND_BY_ID: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_GROUP_FIND_BY_ID;

  /**
   * @name API_GROUP_FIND_BY_STATUS
   * @description Dirección del API para obtener un grupo por su status
   * @type {string}
   */
  private readonly API_GROUP_FIND_BY_STATUS: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_GROUP_FIND_BY_STATUS;

  /**
   * @name API_GROUP_FIND_ALL
   * @description Dirección del API para obtener todos los grupos
   * @type {string}
   */
  private readonly API_GROUP_FIND_ALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_GROUP_FIND_ALL;

  /**
   *
   * @param group
   * @returns El grupo guardado
   * @throws Error al guardar el grupo
   * @description Guarda el grupo
   * @Author EnriquePallares
   */
  async save(group: Group): Promise<Group> {
    try {
      return await http.post(this.API_GROUP_SAVE, group);
    } catch (error) {
      throw new Error('Error al guardar el grupo');
    }
  }

  /**
   *
   * @param group
   * @returns El grupo actualizada
   * @throws Error al actualizar el grupo
   * @description Actualiza el grupo
   * @Author EnriquePallares
   */
  async update(group: Group): Promise<Group> {
    try {
      return await http._put(this.API_GROUP_UPDATE, group);
    } catch (error) {
      throw new Error('Error al actualizar el grupo');
    }
  }

  /**
   *
   * @param group
   * @returns El grupo eliminado
   * @throws Error al eliminar el grupo
   * @description Elimina el grupo
   * @Author EnriquePallares
   */
  async delete(group: string): Promise<Group> {
    try {
      return await http.singleDelete(`${this.API_GROUP_DELETE}?group=${group}`);
    } catch (error) {
      throw new Error('Error al eliminar el grupo');
    }
  }

  /**
   *
   * @param id
   * @returns El grupo encontrado
   * @throws Error al buscar el grupo
   * @description Busca el grupo por su id
   * @Author EnriquePallares
   */
  async findById(id: string): Promise<Group> {
    try {
      return await http.get(`${this.API_GROUP_FIND_BY_ID}id=${id}`);
    } catch (error) {
      throw new Error('Error al buscar el grupo');
    }
  }

  /**
   *
   * @param status
   * @returns El grupo encontrado
   * @throws Error al buscar el grupo
   * @description Busca el grupo por su status
   * @Author EnriquePallares
   */
  async findByStatus(status: boolean): Promise<Group[]> {
    try {
      return await http.get(`${this.API_GROUP_FIND_BY_STATUS}status=${status}`);
    } catch (error) {
      throw new Error('Error al buscar el grupo');
    }
  }

  /**
   *
   * @returns Array de grupos
   * @throws Error al buscar los grupos
   * @description Busca todos los grupos
   * @Author EnriquePallares
   */
  async findAll(): Promise<Group[]> {
    try {
      return await http.get(this.API_GROUP_FIND_ALL);
    } catch (error) {
      throw new Error(`Error al buscar los grupos: ${error}`);
    }
  }
}
