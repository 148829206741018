import { TypeIdentification } from '../typeIdentification/TypeIdentification';

export class EntityTypeIdentification {
  id: number;
  identification: string;
  typeIdentification: TypeIdentification | null;
  active: boolean;

  public constructor() {
    this.id = 0;
    this.identification = '';
    this.typeIdentification = null;
    this.active = true;
  }
}
