import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import { ReferenceGroupLevel } from '@/wms/domain/referenceGroupLevel/ReferenceGroupLevel';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { ReferenceGroupLevelRepository } from '@/wms/domain/referenceGroupLevel/ReferenceGroupLevelRepository';

@Injectable()
export class GetTreeReferenceGroupLevel extends Command<Promise<Array<ReferenceGroupLevel>>> {
  public constructor(
    @Inject(TYPES.REFERENCE_GROUP_LEVEL_REPOSITORY)
    private readonly referenceGroupLevelRepository: ReferenceGroupLevelRepository
  ) {
    super();
  }

  public async internalExecute(): Promise<Array<ReferenceGroupLevel>> {
    return await this.referenceGroupLevelRepository.getTree();
  }
}
