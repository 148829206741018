
import Vue from 'vue';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';
import { TYPES } from '@/core/config/Types';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { BillOfLanding } from '@/wms/domain/billOfLanding/BillOfLanding';
import { FindDataForReportHouseBillOfLading } from '@/wms/application/houseBillOfLading/search/FindDataForReportHouseBL';
import TransactionalEventForTraceability from '@/wms/domain/preRegister/TransactionalEventForTraceability';
import { PreRegisterForTraceabilityPanel } from '@/wms/domain/preRegister/PreRegisterForTraceabilityPanel';

@Component({
  name: 'HouseWarehouseBillOfLading'
})
export default class HouseWarehouseBillOfLading extends Vue {
  @Inject(TYPES.FINDBYID_COMPANY) private readonly findByIdCompany!: CompanyFindById;
  @Inject(TYPES.FIND_DATA_FOR_REPORT_HOUSE_BILL_OF_LADING)
  private readonly findDataForReport!: FindDataForReportHouseBillOfLading;
  @Inject()
  @Prop({ type: Function, default: null })
  exportToPDF!: Function;
  @Prop({ default: null }) clientSelected!: any;
  @Prop() eventData!: TransactionalEventForTraceability | null;
  @Prop() master!: PreRegisterForTraceabilityPanel | null;
  @Prop() form!: BillOfLanding | null;

  logoImg = localStorage.getItem('urlLogoCompany') as string;
  isLoading = false;
  companyInfo = {
    companyName: '',
    companyAdress: '',
    companyPhoneNumber: '',
    companyEmail: '',
    companyZipCode: ''
  };
  masterBl = new BillOfLanding();
  cargoDescription = null;

  mounted() {
    this.getCompanyInfo();
    // this.findBillOfLandingOnActivities();
    this.billOfLadingDataForReport(this.houseBl?.serial.toString() ?? '');
  }

  async getCompanyInfo() {
    try {
      this.isLoading = true;
      const companyId = localStorage.getItem('businessId') as string;
      const res: any = await this.findByIdCompany.execute(companyId);
      this.isLoading = false;
      this.companyInfo.companyName = res.businessName;
      this.companyInfo.companyAdress = res.address;
      this.companyInfo.companyPhoneNumber = res.phoneNumber;
      this.companyInfo.companyEmail = res.email;
      this.companyInfo.companyZipCode = res.zipCode;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  // findBillOfLandingOnActivities() {
  //   const billOfLanding = this.searchBillOfLandingOnEvents();

  //   if (billOfLanding) {
  //     this.masterBl = billOfLanding;
  //   }
  // }

  // searchBillOfLandingOnEvents() {
  //   return this.master?.timeline?.events
  //     .map(event => {
  //       return event.activities;
  //     })
  //     .flat()
  //     .map(activity => {
  //       return activity?.billOfLanding;
  //     })
  //     .find(billOfLanding => {
  //       return billOfLanding;
  //     });
  // }

  async billOfLadingDataForReport(code: string) {
    try {
      const res = await this.findDataForReport.execute(code);
      this.cargoDescription = res;
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  get houseBl() {
    return this.form;
  }
}
