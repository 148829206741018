import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { PreEntityRepository } from '@/settings/domain/pre_entity/PreEntityRepository';
import { PreEntity } from '@/settings/domain/pre_entity/PreEntity';

@Injectable()
export class PreEntityService implements PreEntityRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_PRE_ENTITY_SAVE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PRE_ENTITY_SAVE;
  private readonly API_PRE_ENTITY_UPDATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PRE_ENTITY_UPDATE;
  private readonly API_PRE_ENTITY_FIND_BY_ID: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PRE_ENTITY_FIND_BY_ID;
  private readonly API_PRE_ENTITY_FIND_BY_DESCRIPTION_lIKE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PRE_ENTITY_FIND_BY_DESCRIPTION_lIKE;

  async create(payload: PreEntity): Promise<any> {
    return await http.post<PreEntity>(this.API_PRE_ENTITY_SAVE, JSON.stringify(payload));
  }

  async update(payload: PreEntity): Promise<any> {
    return await http.post<PreEntity>(this.API_PRE_ENTITY_UPDATE, JSON.stringify(payload));
  }

  async findById(id: string): Promise<PreEntity> {
    return await http.get<PreEntity>(this.API_PRE_ENTITY_FIND_BY_ID + id);
  }

  async findByFullNameLike(query: string): Promise<PreEntity[]> {
    return await http.get<PreEntity[]>(this.API_PRE_ENTITY_FIND_BY_DESCRIPTION_lIKE + query + '/CUSTOMER');
  }
}
