import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Injectable } from '@/core/di/Injectable';
import { ConceptsContainer } from '@/settings/domain/containers/conceptsContainer/ConceptsContainer';
import { ConceptsContainerRepository } from '@/settings/domain/containers/conceptsContainer/ConceptsContainerRepository';
import { Inject } from 'inversify-props';

@Injectable()
export class FindAllConceptsContainer extends Query<Promise<ConceptsContainer[]>> {
  public constructor(
    @Inject(TYPES.CONCEPTS_CONTAINER_REPOSITORY)
    private readonly conceptsContainerRepository: ConceptsContainerRepository
  ) {
    super();
  }

  async internalExecute(): Promise<ConceptsContainer[]> {
    return await this.conceptsContainerRepository.findAll();
  }
}
