
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { References } from '@/wms/domain/catalogs/references/References';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { FindAllSize } from '@/settings/application/uses_cases/gamma/size/search/SizeFindall';
import { Size } from '@/wms/domain/gamma/Size';
import { FindAllGamma } from '@/settings/application/uses_cases/gamma/gamma/search/GammaFindall';
import { Gamma } from '@/wms/domain/gamma/Gamma';
import { FindAllColor } from '@/settings/application/uses_cases/gamma/color/search/ColorFindall';
import { Color } from '@/wms/domain/gamma/Color';
import { CreateGamma } from '@/settings/application/uses_cases/gamma/gamma/create/CreateGamma';
import { UpdateGamma } from '@/settings/application/uses_cases/gamma/gamma/update/UpdateGamma';
import EditDynamicElement from '@/settings/infrastructure/ui/components/entity/editDynamicRecords/EditRecordModal.vue';
import { DeleteGamma } from '@/settings/application/uses_cases/gamma/gamma/delete/DeleteGamma';

@Component({
  name: 'GammaModal',
  components: { CustomTableN, EditDynamicElement }
})
export default class GammaModal extends Vue {
  @Inject(TYPES.SIZE_FINDALL)
  readonly findAllSizes!: FindAllSize;
  @Inject(TYPES.COLOR_FINDALL)
  readonly findAllColors!: FindAllColor;
  @Inject(TYPES.GAMMA_FINDALL)
  readonly findAllGammas!: FindAllGamma;
  @Inject(TYPES.GAMMA_SAVE)
  readonly createGamma!: CreateGamma;
  @Inject(TYPES.GAMMA_UPDATE)
  readonly updateGamma!: UpdateGamma;
  @Inject(TYPES.GAMMA_DELETE)
  readonly deleteGamma!: DeleteGamma;

  @Prop({ required: true }) reference!: References;
  @Prop({ required: true }) sku!: any;

  gamma: null | Gamma = null;

  setGammaPayload() {
    this.gamma = {
      gamma: '',
      description: '',
      sizes: []
    };
  }

  gammas: Gamma[] = [];
  originalColors: Color[] = [];
  processedColors: Color[] = [];
  originalSizes: Size[] = [];
  processedSizes: Size[] = [];
  isLoading = false;

  gammaSelected: null | Gamma = null;
  selectedSize: Size | null = null;
  sizesSelected = [];
  colorsSelected = new Object();

  mounted() {
    this.findAllData();
  }

  async findSizes() {
    try {
      this.isLoading = true;
      const res = await this.findAllSizes.internalExecute();
      this.originalSizes = res;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }
  async findColors() {
    try {
      this.isLoading = true;
      const res = await this.findAllColors.internalExecute();
      this.originalColors = res;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }
  async findGammas() {
    try {
      this.isLoading = true;
      const res = await this.findAllGammas.internalExecute();
      this.gammas = res;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async findAllData() {
    await this.findColors();
    await this.findSizes();
    await this.findGammas();
  }

  async addGamma(newGamma: string) {
    this.isLoading = true;
    const gamma = {
      gamma: newGamma,
      description: '',
      sizes: []
    };
    await this.createGamma.internalExecute(gamma);
    this.isLoading = false;
    this.findGammas();
  }

  handleClicked(e: Size) {
    const size = this.processedSizes.find(size => size.size === e.size);

    if (!size) {
      return;
    }

    if (this.selectedSize && this.selectedSize.size === size.size) {
      this.selectedSize = null;
      return;
    }

    this.selectedSize = size;

    // Mapeamos los colores seleccionados y si alguno de ellos existe en la talla seleccionada, lo cambiamos en el array
    this.processedColors = this.originalColors?.map(color => {
      const colorInSize = size?.colors.find(colorInSize => {
        return colorInSize.code === color.code;
      });

      if (colorInSize) {
        return colorInSize;
      }

      return color;
    });
  }

  async update() {
    if (!this.gammaSelected) {
      return;
    }

    this.isLoading = true;
    await this.updateGamma.internalExecute(this.gammaSelected);
    this.isLoading = false;
    this.findGammas();
  }

  handleGammaSelected(e: Gamma) {
    if (!e) {
      this.selectedSize = null;
      return;
    }

    this.selectedSize = null;

    this.processedSizes = this.originalSizes.map(size => {
      // Debemos buscar la talla en el gamma seleccionado
      const sizeInGamma = this.gammaSelected?.sizes.find(sizeInGamma => sizeInGamma.size === size.size);

      if (sizeInGamma) {
        return sizeInGamma;
      }

      return size;
    });
  }

  async updateGammas(data: Gamma) {
    return await this.updateGamma.execute(data);
  }

  async deleteGammas(data: Gamma) {
    return await this.deleteGamma.execute(data);
  }
}
