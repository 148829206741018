
import { showAlert } from '@/core/plugins/Notifications';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import EventModal from '@/courier/infrastructure/ui/components/order/EventsModal/EventModal.vue';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import TransactionalEventForTraceability from '@/wms/domain/preRegister/TransactionalEventForTraceability';
import { FindActivitiesForTraceabilityByEvent } from '@/tracking/application/uses_cases/activitiesTransactional/search/FindActivitiesForTraceabilityByEvent';
import { ActivityTransactionalForTraceability } from '@/tracking/domain/activitiesTransactional/Projections/ActivityTransactionalForTraceability';
import { PreRegisterForTraceabilityPanel } from '@/wms/domain/preRegister/PreRegisterForTraceabilityPanel';

@Component({
  name: 'Timeline',
  components: { EventModal }
})
export default class Timeline extends Vue {
  @Inject(TYPES.ACTIVITIES_TRANSACTIONAL_FIND_FOR_TRACEABILITY_BY_EVENT)
  private readonly findActivities!: FindActivitiesForTraceabilityByEvent;

  //Data
  activities: ActivityTransactionalForTraceability[] = [];
  eventData: TransactionalEventForTraceability | null = null;
  hover = false;
  eventCode = 0;
  eventName = '';
  isLoading = false;

  //Prop
  @Prop({ type: Array, required: true }) items!: TransactionalEventForTraceability[];
  @Prop({ required: true }) master!: PreRegisterForTraceabilityPanel;
  @Prop({ type: Number, required: true }) masterNumber!: number;
  @Prop({ type: String, required: true }) readonly lineSerial!: string;
  // @Prop({ type: Array, required: true }) houses!: any[];
  @Prop({ type: String, required: false }) typeOperation!: string;
  @Prop({ type: String, required: false }) typeTransport!: string;
  @Prop({ type: String, required: false }) preRegisterType!: string;
  @Prop({ type: String, required: false }) readonly customModalIdGenerator!: string;
  @Prop({ type: Function }) searchFunction!: () => Promise<void>;

  get sortItems() {
    return this.items.sort((a, b) => a.sequence - b.sequence);
  }

  //Methods
  updateEventExec() {
    this.eventCode = 0;
  }

  //Copy
  async processEvent(item: TransactionalEventForTraceability, indexItem: number) {
    try {
      if (indexItem !== 0) {
        if (this.sortItems[indexItem - 1].state !== 'EXECUTED') {
          showAlert(
            'warning',
            'Advertencia',
            `Se debe ejecutar el evento ${this.sortItems[indexItem - 1].description}`
          );
          return;
        }
      }

      await this.getActivities(item);
      this.$bvModal.show(this.customModalIdGenerator ?? `modal-event-${item.eventId}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  stateClass(state: string) {
    let className = '';

    switch (state) {
      case 'PENDING':
        className = 'fa fa-close pending';
        break;

      case 'CURRENT':
        className = 'fa fa-refresh current';
        break;

      case 'EXECUTED':
        className = 'fa fa-check ready';
        break;

      default:
        className = 'fa fa-close pending';
        break;
    }

    return className;
  }

  async getActivities(event: TransactionalEventForTraceability) {
    try {
      this.isLoading = true;

      this.activities = await this.findActivities.execute({
        eventId: event.eventId,
        preRegisterNumber: this.master.number,
        preRegisterType: this.master.type
      });
      this.eventCode = event.eventId;
      this.eventName = event.description;
      this.eventData = event;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }
}
