import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { BulkRepository } from '@/wms/domain/bulk/BulkRepository';
import { GroupedBulk } from '@/wms/domain/bulk/GroupedBulk';

@Injectable()
export class FindBulksForPickingByFilters extends Query<
  Promise<GroupedBulk[]>,
  {
    dateFrom: string;
    dateTo: string;
    commodity: CommodityType | null;
    customer: Entity | null;
    masterBl: string | null;
    houseBl: string[] | null;
  }
> {
  public constructor(
    @Inject(TYPES.BULK_REPOSITORY)
    private readonly repository: BulkRepository
  ) {
    super();
  }

  async internalExecute(filters: {
    dateFrom: string;
    dateTo: string;
    commodity: CommodityType | null;
    customer: Entity | null;
    masterBl: string | null;
    houseBl: string[] | null;
    consignee: string | null;
  }): Promise<GroupedBulk[]> {
    try {
      return await this.repository.findForPicking(filters);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
