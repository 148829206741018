
import Component from 'vue-class-component';
import Vue from 'vue';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { CreateWeight } from '@/settings/application/uses_cases/weight/create/CreateWeight';
import { CreateVolume } from '@/settings/application/uses_cases/volume/create/CreateVolume';
import { Prop } from 'vue-property-decorator';
import { UpdateVolume } from '@/settings/application/uses_cases/volume/update/UpdateVolume';
import { UpdateWeight } from '@/settings/application/uses_cases/weight/update/UpdateWeight';

@Component({
  name: 'VolumeAndWeight'
})
export default class AppVolumeAndWeight extends Vue {
  @Inject(TYPES.CREATE_WEIGHT)
  readonly createWeight!: CreateWeight;
  @Inject(TYPES.CREATE_VOLUME)
  readonly createVolume!: CreateVolume;
  @Inject(TYPES.UPDATE_VOLUME)
  readonly editVolume!: UpdateVolume;
  @Inject(TYPES.UPDATE_WEIGHT)
  readonly editWeight!: UpdateWeight;
  //DATA
  isLoading = false;
  fullPage = true;
  isTouched = false;

  //PROPS
  @Prop({ required: true }) readonly form!: any;
  @Prop({ required: true }) readonly unit!: any;
  @Prop({ required: true }) readonly clear!: Function;
  @Prop({ required: true }) readonly items!: any[];
  //Refs
  $refs!: {
    validationVolumeAndWeight: HTMLFormElement;
    unitOfMeasurementCode: HTMLInputElement;
    unitOfMeasurementDescription: HTMLInputElement;
  };
  created() {
    this.$nextTick(() => this.$refs.unitOfMeasurementCode.focus());
  }
  //GETTERS
  get buttonOptions() {
    return {
      icon: !this.form.isEdit ? 'fa fa-plus' : 'fa fa-save',
      variant: this.form.isEdit ? 'secondary' : 'success',
      title: this.form.isEdit ? this.$t('general.add') : this.$t('general.edit')
    };
  }
  //METHODS
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  submit() {
    this.isLoading = true;
    if (this.unit == 'weight') {
      this.createWeight
        .execute(this.form)
        .then((response: any) => {
          this.isLoading = false;
          this.$emit('refresh', this.unit);
          return response;
        })
        .catch((error: any) => {
          this.isLoading = false;
          throw new Error(error);
        });
      return;
    }
    this.createVolume
      .execute(this.form)
      .then((response: any) => {
        this.isLoading = false;
        this.$emit('refresh', this.unit);
        return response;
      })
      .catch((error: any) => {
        this.isLoading = false;
        throw new Error(error);
      });
  }

  update() {
    this.isLoading = true;
    if (this.unit == 'weight') {
      this.editWeight
        .execute(this.form)
        .then((response: any) => {
          this.isLoading = false;
          this.$emit('refresh', this.unit);
          return response;
        })
        .catch((error: any) => {
          this.isLoading = false;
          throw new Error(error);
        });
      return;
    }
    this.editVolume
      .execute(this.form)
      .then((response: any) => {
        this.isLoading = false;
        this.$emit('refresh', this.unit);
        return response;
      })
      .catch((error: any) => {
        this.isLoading = false;
        throw new Error(error);
      });
  }

  factory() {
    this.form.isEdit ? this.update() : this.submit();
  }

  findInItems() {
    const find = this.items.filter(item => item.code == this.form.code);
    if (find.length > 0) {
      this.$swal({
        title: `${this.$t('general.warning')}`,
        text: `${this.$t('general.unitAlreadyExist')}`,
        icon: 'warning'
      });
      this.clear(this.unit);
    }
  }
}
