
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { Prop } from 'vue-property-decorator';
import { TYPES } from '@/core/config/Types';
import { ValidationObserver } from 'vee-validate/dist/types/components';
import { selectedLocale } from '@/core/plugins/i18n';
import { CompanyFindAll } from '@/settings/application/uses_cases/company/search/CompanyFindAll';
import { StateFindByCountryCode } from '@/settings/application/uses_cases/state/search/StateFindByCountryCode';
import { ZoneFindByStatusAndCountryAndState } from '@/settings/application/uses_cases/zone/search/ZoneFindByStatusAndCountryAndState';
import { CountryFindAll } from '@/settings/application/uses_cases/country/search/CountryFindAll';
import { PriceListFindByOriginAndDestinationWeight } from '@/settings/application/uses_cases/priceList/search/weight/PriceListFindByOriginAndDestinationWeight';
import { PriceListFindByOriginAndDestination } from '@/settings/application/uses_cases/priceList/search/volume/PriceListFindByOriginAndDestination';
import { PurchaseScraping } from '@/courier/application/uses_cases/purchase/search/PurchaseScraping';
import { PalletsFactorFindAll } from '@/settings/application/uses_cases/palletsFactor/search/PalletsFactorFindAll';
import { CurrencyFindAll } from '@/settings/application/uses_cases/currency/search/CurrencyFindAll';
import { OrderFindByPk } from '@/courier/application/uses_cases/order/search/OrderFindByPk';
import { UpdateOrder } from '@/courier/application/uses_cases/order/update/UpdateOrder';
import CalculateService from '@/courier/infrastructure/ui/components/calculateService/CalculateService.vue';
import { RateEngine } from '@/utils/RateEngine';

@Component({
  name: 'UpdateOrder',
  components: { CalculateService },
  //Filters
  filters: {
    toCurrency(value: any) {
      if (typeof value !== 'number') {
        return value;
      }
      const language = selectedLocale;
      const formatter = new Intl.NumberFormat(`${language}`);
      return formatter.format(value);
    }
  }
})
export default class AppUpdateOrder extends Vue {
  @Inject(TYPES.UPDATE_ORDER) readonly updateOrders!: UpdateOrder;
  @Inject(TYPES.FINDALL_PALLETSFACTOR) readonly palletsFactorFindAll!: PalletsFactorFindAll;
  @Inject(TYPES.FINDALL_COMPANY) readonly companyFindAll!: CompanyFindAll;
  @Inject(TYPES.FINDALL_COUNTRY) readonly countryFindAll!: CountryFindAll;
  @Inject(TYPES.FINDBYCOUNTRYCODE_STATE) readonly stateFindByCountryCode!: StateFindByCountryCode;
  @Inject(TYPES.FINDSTATUSCOUNTRYSTATE_ZONE)
  readonly zoneFindByStatusAndCountryAndState!: ZoneFindByStatusAndCountryAndState;
  @Inject(TYPES.FINDBY_ORIGINANDDESTINATION_WEIGHT)
  readonly priceListFindByOriginAndDestinationWeight!: PriceListFindByOriginAndDestinationWeight;
  @Inject(TYPES.FINDBY_ORIGINANDDESTINATION_VOLUME)
  readonly priceListFindByOriginAndDestination!: PriceListFindByOriginAndDestination;
  @Inject(TYPES.PURCHASE_SCRAPING) readonly purchaseScraping!: PurchaseScraping;
  @Inject(TYPES.FINDALL_CURRENCY) readonly currencyFindAll!: CurrencyFindAll;
  @Inject(TYPES.FINDBYPK_ORDER) readonly orderFindByPk!: OrderFindByPk;

  //Data
  totalWeight = 0;
  totalQuantity = 0;
  tWeight = 0;
  tQuantity = 0;
  tVolumen = 0;
  packagesTotal = 0;
  selectedLocales = selectedLocale;
  selected = '1';
  selectedRate = 0;
  isTouched = false;
  disabledQuotation = true;
  disabledQuotationDocuments = false;
  disabledQuotationPackages = false;
  checkedAdressee = false;
  provinceFrom = [];
  provinceTo = [];
  zonesFrom = [] as any;
  zonesTo = [] as any;
  valueRate = 0;
  findVolumeAndWeight = true;
  palletsFactor = [];
  localStorageQuotation = null;
  optionPurchaseOrder = false;
  optionPurchaseOrderStore = true;
  optionPickupAndPurchase = null;
  isDisabledDocument = false;
  showSppinerSearch = false;
  isDisabledEcommerce = true;
  quickViewProduct = [];
  urlImageAmazon = '';
  show = true;
  fieldDisabledAddress = true;
  companyLocalCurrency = '';
  unitofmeasurementPackages = 'kg';
  unitofmeasurementDocument = 'kg';
  dataCompany = [];
  palletsFactors = [];
  countries = [];
  currencies = [];
  orderRouteId = 0;
  orderTimelineSkeletonId = 0;
  form: any = {
    orderNumber: null,
    orderType: '',
    orderCustomerName: '',
    orderCustomerPhone: '',
    orderCustomerAddress: '',
    orderConditionPayment: 'cash',
    orderCurrency: '',
    orderDate: '',
    orderCustomerId: '',
    orderDestinationId: '',
    orderDestinationName: '',
    orderDestinationPhone: '',
    orderDestinationAddress: '',
    orderDestinationCountry: [],
    orderDestinationState: [],
    orderDestinationZone: [],
    orderCustomerCountry: [],
    orderCustomerState: [],
    orderCustomerZone: [],
    orderDestinationEmail: '',
    orderSuggestedPickupdate: '',
    orderSuggesTedtime: '',
    orderSubTotalProduct: 0,
    orderTotalTaxesProduct: 0,
    orderTotalProduct: 0,
    orderSubTotalProductConverted: 0,
    orderTaxesProductConverted: 0,
    orderTotalProductConverted: 0,
    orderSubTotalProductPackageConverted: 0,
    orderTotalTaxesProductPackageConverted: 0,
    orderTotalProductPackageConverted: 0,
    orderSubTotalProductPackage: 0,
    orderTotalTaxesProductPackage: 0,
    orderTotalProductPackage: 0,
    orderAddressDisabled: true,
    orderNotes: '',
    orderTaxesRegistry: []
  };
  invoiceProductsPackeges: any = [
    {
      quantity: 1,
      weight: '',
      unitofmeasurement: 'kg',
      lengthy: '',
      width: '',
      height: '',
      amountShipped: 0,
      active: 'true',
      number: 1,
      scheduled: 0,
      sequence: 0,
      sw: '1',
      taxes: [],
      typeOrder: 'empty',
      unitPrice: '',
      units: 0,
      warehouse: '',
      purchaseUrl: '',
      description: ''
    }
  ];
  invoiceProducts: any = [
    {
      quantity: '',
      weight: '',
      unitofmeasurement: 'kg',
      lengthy: 0,
      width: 0,
      height: 0,
      amountShipped: 0,
      active: 'true',
      number: 1,
      scheduled: 0,
      sequence: 0,
      sw: '1',
      taxes: [],
      typeOrder: 'empty',
      unitPrice: '',
      units: 0,
      warehouse: ''
    }
  ];
  optionsTime: any = [
    { value: '8:00am a 12:00pm', text: '8:00am a 12:00pm' },
    { value: '13:00pm a 17:00pm', text: '13:00pm a 17:00pm' }
  ];
  virtualShops: any = { name: 'AMAZON', code: '001' };
  //Refs
  $refs!: {
    mdValidateTrmOrder: HTMLFormElement;
    formWizardOrder: HTMLFormElement;
    modalPackageOption: HTMLFormElement;
    orderCustomerCountry: HTMLFormElement;
    registerClient: HTMLFormElement;
    orderCustomerId: HTMLInputElement;
    orderDestinationAddress: HTMLInputElement;
    firstStep: InstanceType<typeof ValidationObserver>;
    twoStep: InstanceType<typeof ValidationObserver>;
    calculateSrv: HTMLFormElement;
  };
  //Props
  @Prop({ required: true, type: Object }) readonly dataOrder!: [];
  calculatedQuotation: any;
  dataRoute = {};
  excuteCalc = false;

  //Hook
  created() {
    this.$nextTick(() => this.getPalletsFactorsAll);
    this.$nextTick(() => this.getCurrencies);
    this.$nextTick(() => this.getItemsOrder(this.dataOrder));
    this.localStorageQuotation = null;
    const date = new Date();
    this.form.orderDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    this.form.orderSuggestedPickupdate =
      new Date().getFullYear() +
      '-' +
      this.defaultEndDateMonth(new Date().getMonth() + 1) +
      '-' +
      this.defaultEndDateDay(new Date().getDate());
  }
  async mounted() {
    await this.companyFindAll.execute().then((response: any) => {
      this.dataCompany = response;
    });
  }
  //Computed
  get fields() {
    return [
      { key: 'key', label: this.translateCol('description'), sortable: true },
      { key: 'value', label: this.translateCol('detail'), sortable: true }
    ];
  }
  get isDisabled() {
    return this.form.orderAddressDisabled;
  }
  get state() {
    return Boolean(this.selectedRate);
  }
  get getPalletsFactorsAll() {
    this.palletsFactorFindAll.execute().then((response: any) => {
      this.palletsFactors = response;
    });
    return true;
  }
  get getCurrencies() {
    this.currencyFindAll.execute().then((response: any) => {
      this.currencies = response;
    });
    return true;
  }
  //Methods
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  translateCol(colName: any) {
    return this.$i18n.t('general.' + colName);
  }
  defaultEndDateMonth(month: any) {
    if (Number(month) < 10) {
      return '0' + month;
    } else {
      return month;
    }
  }
  defaultEndDateDay(day: any) {
    if (Number(day) < 10) {
      return '0' + day;
    } else {
      return day;
    }
  }
  shippingUpdate(event: any) {
    event.preventDefault();
    const totalTax =
      this.calculatedQuotation.taxes.length > 0
        ? this.calculatedQuotation.taxes.reduce((tax1: any, tax2: any) => tax1.taxValue + tax2.taxValue)
        : 0;
    this.form.orderType = this.calculatedQuotation.optionPurchaseOrder ? 'PR' : 'CT';

    const payload: any = {
      customerId: this.form.orderCustomerId.trim(),
      customerName: this.form.orderCustomerName.trim(),
      typeOrder: this.form.orderType,
      condition: this.form.orderConditionPayment,
      currency: this.form.orderCurrency,
      addressOriginCountry: this.form.orderCustomerCountry.code,
      addressOriginState: this.form.orderCustomerState.code,
      addressOriginZipCode: this.form.orderCustomerZone.zoneZc,
      addressOriginLine: this.form.orderCustomerAddress.trim(),
      addressOriginId: this.form.addressOriginId,
      destination: {
        customerId: this.form.orderDestinationId.trim(),
        fullName: this.form.orderDestinationName.trim(),
        email: this.form.orderDestinationEmail.trim(),
        phone1: this.form.orderDestinationPhone.trim(),
        addressLine: {
          country: this.form.orderDestinationCountry.code,
          description: this.form.orderDestinationAddress.trim(),
          state: this.form.orderDestinationState.code,
          zipCode: this.form.orderDestinationZone.zoneZc,
          warehouse: '000'
        }
      },
      lines: this.calculatedQuotation.arrayDocument,
      number: this.form.orderNumber,
      deliveryDateTime: this.form.orderSuggestedPickupdate,
      dateTime: this.form.orderSuggesTedtime,
      weight: this.calculatedQuotation.unitWeight.id,
      width: 0,
      lengthy: 0,
      height: 0,
      approved: true,
      canceled: false,
      checked: false,
      date: this.form.orderDate,
      discount: '',
      document: '',
      notes: this.form.orderNotes,
      warehouse: '000',
      sw: 1,
      routeId: this.calculatedQuotation.routeId,
      timelineSkeletonId: this.calculatedQuotation.timeLineId,
      tax: totalTax,
      total: this.calculatedQuotation.rateValue,
      conversionRate: this.calculatedQuotation.rateValue
    };
    this.updateOrders.execute(payload);
    this.$refs.formWizardOrder.reset();
  }
  getItemsOrder(data: any) {
    this.orderFindByPk.execute(data).then((response: any) => {
      this.form.orderCustomerId = response.customerId;
      this.form.orderCustomerName = response.customerName;
      this.form.orderNumber = response.number;
      this.form.orderCustomerAddress = response.addressOriginLine;
      this.form.addressOriginId = response.addressOriginId;
      this.fieldDisabledAddress = false;
      this.form.orderDestinationId = response.destination.customerId;
      this.form.orderDestinationName = response.destination.fullName;
      this.form.orderDestinationAddress = response.destination.addressLine.description;
      this.form.orderDestinationPhone = response.destination.phone1;
      this.form.orderDestinationEmail = response.destination.email;
      this.form.orderCustomerCountry = { name: response.addressOriginCountryName, code: response.addressOriginCountry };
      this.form.orderDestinationCountry = {
        name: response.destination.addressLine.countryName,
        code: response.destination.addressLine.country
      };
      this.form.orderCustomerState = { name: response.addressOriginStateName, code: response.addressOriginState };
      this.form.orderCustomerZone = {
        description: response.addressOriginZipCodeDescription,
        zoneZc: response.addressOriginZipCode
      };
      this.form.orderDestinationState = {
        name: response.destination.addressLine.stateName,
        code: response.destination.addressLine.state
      };
      this.form.orderDestinationZone = {
        description: response.destination.addressLine.zipCodeDescription,
        zoneZc: response.destination.addressLine.zipCode
      };
      this.form.orderCurrency = response.currency;
      this.form.orderDeliveryDateTime = response.deliveryDateTime;
      this.form.orderNotes = response.notes;
      this.form.orderSuggesTedtime = response.dateTime;
      this.disabledQuotation = false;
      //object
      this.calculatedQuotation = {
        arrayDocument: response.lines,
        selected: response.lines[0]['width'] ? '2' : '1',
        unitWeight: response.weight,
        // unitDimension: response.volume
        optionPurchaseOrder: response.typeOperation == 'PR',
        optionPurchaseOrderStore: response.typeOperation == 'CT',
        subTotal: response.total,
        taxes: [],
        rateValue: response.total,
        isUpdate: true // flag para notificar al componente de calculo que es un update
      };
      this.$refs.calculateSrv.startWithExternalData(this.calculatedQuotation);
    });
  }
  getStateFindByCountryCode(country: any, type: string) {
    this.stateFindByCountryCode.execute(country.code).then((response: any) => {
      if (type === 'from') {
        this.form.orderCustomerState = [];
        this.form.orderCustomerZone = [];
        this.zonesFrom = [];
        this.provinceFrom = response;
      } else {
        this.form.orderDestinationState = [];
        this.form.orderDestinationZone = [];
        this.zonesTo = [];
        this.provinceTo = response;
      }
      return response;
    });
  }
  getZoneByCountryAndState(country: any, state: any, type: string) {
    const payload: any = {
      countryCode: country.code,
      stateCode: state.code
    };
    this.zoneFindByStatusAndCountryAndState
      .execute(payload)
      .then((response: any) => {
        if (type === 'from') {
          this.form.orderCustomerZone = [];
          this.zonesFrom = response;
        } else {
          this.form.orderDestinationZone = [];
          this.zonesTo = response;
        }
        //this.zonesFrom.unshift(' ');
        return response;
      })
      .catch(() => {
        this.zonesFrom = [];
        this.zonesTo = [];
      });
  }
  async validateStepFirst() {
    const rateEngine = new RateEngine();
    this.dataRoute = {
      countryOrigin: this.form.orderCustomerCountry.code,
      stateOrigin: this.form.orderCustomerState.code,
      zoneZcOrigin: this.form.orderCustomerZone.zoneZc,
      countryDestination: this.form.orderDestinationCountry.code,
      stateDestination: this.form.orderDestinationState.code,
      zoneZcDestination: this.form.orderDestinationZone.zoneZc
    };
    return (await this.$refs.firstStep.validate()) && (await rateEngine.validatePriceList(this.dataRoute));
  }
  //nuevos metodos
  executePricingCall() {
    //params
    if (!this.excuteCalc) this.$refs.calculateSrv.calculatePricing(this.dataRoute);

    return this.excuteCalc;
  }
  pricingFactorSelected(objFactor: any) {
    this.calculatedQuotation = objFactor;
    if (this.calculatedQuotation) {
      this.excuteCalc = true;
      this.$refs.formWizardOrder.nextTab();
    }
  }
}
