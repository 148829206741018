import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { Person } from '@/settings/domain/person/Person';
import { PersonRepository } from '@/settings/domain/person/PersonRepository';

@Injectable()
export class PersonFindByDocument extends Query<Promise<Person[]>, string> {
  public constructor(
    @Inject(TYPES.PERSON_REPOSITORY)
    private readonly personRepository: PersonRepository
  ) {
    super();
  }

  async internalExecute(document: string): Promise<Person[]> {
    return await this.personRepository.findByDocument(document);
  }
}
