import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { PackingUnitRepository } from '@/wms/domain/packingUnit/PackingUnitRepository';
import { PackingUnit } from '@/wms/domain/packingUnit/PackingUnit';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class PackingUnitService implements PackingUnitRepository {
  private readonly API_BASE_URI: string = localStorageModule().wms.host.API_BASE_URI;
  private readonly API_PACKINGUNIT_FINDALL: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_PACKINGUNIT_FINDALL;
  private readonly API_PACKINGUNIT_SAVE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_PACKINGUNIT_SAVE;
  private readonly API_PACKINGUNIT_UPDATE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_PACKINGUNIT_UPDATE;
  private readonly API_PACKAGING_FIND_BY_TYPE: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_PACKAGING_FIND_BY_TYPE;

  async create(packingUnit: PackingUnit) {
    try {
      await http.post<PackingUnit[]>(this.API_PACKINGUNIT_SAVE, JSON.stringify(packingUnit));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(packingUnit: PackingUnit) {
    try {
      await http.put<PackingUnit[]>(this.API_PACKINGUNIT_UPDATE, JSON.stringify(packingUnit));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAll(): Promise<PackingUnit[]> {
    try {
      return await http.get<PackingUnit[]>(this.API_PACKINGUNIT_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async findAllByType(type: string): Promise<PackingUnit[]> {
    try {
      return await http.get<PackingUnit[]>(this.API_PACKAGING_FIND_BY_TYPE + type);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
