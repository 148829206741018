
import { Store } from '@/wms/domain/layout/store/Store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'StoreManagement',
  components: {}
})
export default class StoreManagement extends Vue {
  @Prop({ default: () => [] }) allStoresList!: Store[];
  @Prop({ default: () => [] }) storesByUser!: Store[];
  @Prop({ default: false }) isNew!: boolean;
  @Prop({ required: true }) setStoreByUser!: () => Promise<void>;

  get fields() {
    return [
      { key: 'index', label: this.$t('general.warehouse'), sortable: true, sortDirection: 'asc' },
      { key: 'description', label: `${this.$t('general.warehouseName')}`, sortable: true },
      { key: 'stores', label: `${this.$t('general.store')}`, sortable: true }
    ];
  }

  get storesByUserLocal() {
    return this.storesByUser;
  }

  set storesByUserLocal(newValue: Store[]) {
    this.$emit('update:storesByUser', newValue);
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  selectAllStores() {
    this.storesByUserLocal = Object.assign([], this.allStoresList);
  }

  unselectAllStores() {
    this.storesByUserLocal = [];
  }
}
