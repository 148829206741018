
import Vue from 'vue';
import Component from 'vue-class-component';
@Component({
  name: 'ListTransactional',
  components: {}
})
export default class ListTransactional extends Vue {
  $refs!: {
    selectableTable: any;
  };
  items = [
    {
      consecutive: '1',
      supplier: 'Supplier 1',
      warehouse: 'Warehouse 1',
      storage: 'Storage 1',
      date: '2022-01-10'
    },
    {
      consecutive: '2',
      supplier: 'Supplier 2',
      warehouse: 'Warehouse 2',
      storage: 'Storage 2',
      date: '2022-01-10'
    },
    {
      consecutive: '3',
      supplier: 'Supplier 3',
      warehouse: 'Warehouse 3',
      storage: 'Storage 3',
      date: '2022-01-10'
    },
    {
      consecutive: '4',
      supplier: 'Supplier 4',
      warehouse: 'Warehouse 4',
      storage: 'Storage 4',
      date: '2022-01-10'
    }
  ];
  fields = [
    {
      key: 'consecutive',
      label: this.$i18n.t('general.consecutive'),
      sortable: true
    },
    {
      key: 'supplier',
      label: this.$i18n.t('general.supplier'),
      sortable: true
    },
    {
      key: 'warehouse',
      label: this.$i18n.t('general.warehouse'),
      sortable: true
    },
    {
      key: 'storage',
      label: this.$i18n.t('general.storage'),
      sortable: true
    },
    {
      key: 'date',
      label: this.$i18n.t('general.date'),
      sortable: true
    },
    {
      key: 'action',
      label: this.$i18n.t('general.action'),
      sortable: false
    }
  ];

  filters = {
    supplier: '',
    initialDate: '',
    finalDate: '',
    code: ''
  };

  selected = [];

  onRowSelected(items: any) {
    this.selected = items;
  }

  clearSelected() {
    if (this.$refs.selectableTable != undefined) {
      this.$refs.selectableTable.clearSelected();
    }
  }

  clearFilters() {
    this.filters = {
      supplier: '',
      initialDate: '',
      finalDate: '',
      code: ''
    };
  }

  get activeFilters() {
    const active = [0, 0, 0];
    if (this.filters.supplier != null && this.filters.supplier != '') {
      active[2] = 1;
    }
    if (
      this.filters.initialDate &&
      this.filters.finalDate &&
      this.filters.initialDate != '' &&
      this.filters.finalDate != ''
    ) {
      active[1] = 3;
    }

    if (this.filters.code && this.filters.code != '') {
      active[0] = 5;
    }

    return active;
  }

  filterOptions(item: any) {
    if (this.activeFilters[2]) {
      if (item.supplier.toLowerCase().indexOf(this.filters.supplier) < 0) {
        return false;
      }
    }
    if (this.activeFilters[0]) {
      if (item.consecutive.indexOf(this.filters.code) < 0) {
        return false;
      }
    }
    if (this.activeFilters[1]) {
      if (this.filters.initialDate !== null && this.filters.finalDate !== null) {
        if (item.date < this.filters.initialDate || item.date > this.filters.finalDate) {
          return false;
        }
      }
    }

    return true;
  }
}
