import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { FileRepository } from '@/settings/domain/file/FileRepository';

@Injectable()
export class CreateFileV2 extends Command<Promise<string | string[]>, { files: File[]; folder?: string }> {
  public constructor(
    @Inject(TYPES.FILE_REPOSITORY)
    private readonly fileRepository: FileRepository
  ) {
    super();
  }

  internalExecute({ files, folder }: { files: File[]; folder?: string }): Promise<string | string[]> {
    return this.fileRepository.createNewVersion({ files, folder });
  }
}
