
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { CreateBank } from '@/settings/application/uses_cases/parmsGeneral/bank/create/CreateBank';
import { UpdateBank } from '@/settings/application/uses_cases/parmsGeneral/bank/update/UpdateBank';
import { SearchTerritoryByQueryDescription } from '@/settings/application/uses_cases/views/territories/SearchTerritoryByQueryDescription';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { Bank } from '@/settings/domain/ParmsGeneral/bank/Bank';
import { Territories } from '@/settings/domain/views/territories/Territories';
import { Inject } from 'inversify-props';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'CreateBankC',
  components: {}
})
export default class CreateBankC extends Vue {
  @Inject(TYPES.API_VIEW_FIND_TERRITORIES_BY_QUERY)
  readonly searchTerritoriesByQueryParameter!: SearchTerritoryByQueryDescription;
  @Inject(TYPES.UPDATE_BANK)
  readonly updateBank!: UpdateBank;
  @Inject(TYPES.CREATE_BANK)
  readonly crateBank!: CreateBank;
  @Inject(TYPES.STATE_MANAGER)
  readonly stateManager!: StateManager;

  //properties aux
  territorieBk: Territories | null = null;
  //array properties
  territories: Territories[] = [];

  @Prop() readonly observer!: Function;
  @Prop() readonly isModal!: boolean;
  @Prop() readonly isEdit!: boolean;
  @Prop() formEntity!: Entity | any;

  get formObject() {
    return this.formEntity;
  }
  get from() {
    return `${this.formEntity.bank?.address.countryName} - ${this.formEntity.bank?.address.stateName} - ${this.formEntity.bank?.address.cityName}`;
  }

  asyncFindOrigin(query: string) {
    if (query.length >= 3) {
      this.searchTerritoriesByQueryParameter
        .execute(query)
        .then((response: any) => {
          this.territories = response;
        })
        .catch((err: any) => {
          throw new Error(`${err}`);
        });
    }
  }

  saveForm() {
    //prepare pyload
    if (this.formEntity?.bank) {
      this.formEntity.bank.address = {
        ...this.territorieBk,
        zipCode: this.formEntity.bank.address.zipCode,
        description: this.formEntity.bank.address.description,
        id: 0
      };
      if (this.formEntity.bank != null) {
        if (!this.isEdit) {
          this.crateBank.execute(this.formEntity.bank).then(() => {
            this.$emit('update-banks');
            this.observer(true);
            this.cancelForm();
          });
        } else {
          this.updateBank.execute(this.formEntity.bank).then(() => {
            this.$emit('update-banks');
            this.observer(true);
            this.cancelForm();
          });
        }
      }
    }
  }
  cancelForm() {
    this.$emit('clear-form');
  }

  updateEdit(data: boolean) {
    this.$emit('update-edit', data);
  }

  async created() {
    if (!this.formEntity.bank) {
      this.formEntity.bank = new Bank();
    }

    if (this.isEdit && this.formEntity.bank) {
      const response = await this.searchTerritoriesByQueryParameter.execute(this.formEntity.bank?.address.cityName);
      this.territories = response;
      this.territorieBk =
        this.territories.filter((item: Territories) => {
          return item.city === this.formEntity.bank?.address.city;
        })[0] || this.formEntity.bank.address;
    }
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
}
