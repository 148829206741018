
import ReferenceSearch from '@/core/components/shared/ReferenceSearch.vue';
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import { PreparationBulk } from '@/freight/domain/preparationBulk/domain/PreparationBulk';
import { Containers } from '@/settings/domain/containers/Containers';
import { Weight } from '@/settings/domain/weight/Weight';
import { FindByReferencePackaging } from '@/wms/application/catalogs/packaging/search/FindByReferencePackaging';
import { Packaging } from '@/wms/domain/catalogs/packaging/Packaging';
import { References } from '@/wms/domain/catalogs/references/References';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ShippingDetails from '../../quotation/form/shippingDetails/ShippingDetails.vue';
@Component({
  name: 'AddPreparationReference',
  components: {
    ShippingDetails,
    ReferenceSearch
  }
})
export default class AddPreparationReference extends Vue {
  @Inject(TYPES.FINDBYREFERENCE_PACKAGING_REFERENCE)
  readonly findByReferencePackaging!: FindByReferencePackaging;

  @Prop({ required: true }) lists!: {
    containerList: Containers[];
    references: References[];
    weightList: Weight[];
    commodityList?: CommodityType[];
    packagingList?: Packaging[];
  };
  @Prop({ default: false }) forView!: boolean;
  @Prop({ required: true }) addToBulks!: (bulk: PreparationBulk) => void;

  packingUnitList: Packaging[] = [];
  reference: PreparationBulk = new PreparationBulk();

  isLoading = false;

  get referencesFilteredByCargoType() {
    return this.lists.references;
  }

  get disabledInputs() {
    return false;
  }

  get allowBatch() {
    if (this.reference.reference) return this.reference.reference.allowBatch;

    return false;
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  handleInventory(handleInventory: boolean) {
    handleInventory ? (this.reference.handleInventory = 'S') : (this.reference.handleInventory = 'N');
  }

  onReferenceInput(value: References | null) {
    this.reference.packaging = null;
    if (!value) return;
    this.getPackingUnit(value);
    this.reference.description = value.filterBrand;
    this.setCargoDetailCommodityFromReference(value);
    this.resetBatch();
    this.handleInventory(value.noManageStock);
  }

  async getPackingUnit(reference: References) {
    try {
      if (!reference) return;
      this.isLoading = true;
      const res = await this.findByReferencePackaging.internalExecute(reference.code);
      this.packingUnitList = res ?? [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  setCargoDetailCommodityFromReference(reference: References) {
    this.reference.shippingDetails.commodity = reference?.commodity ?? null;
  }

  // Funcion invocada para setear los valores del embalaje en la linea seleccionada
  setPackingDimensions(packaing: Packaging, reference: PreparationBulk) {
    reference.length = packaing.large;
    reference.width = packaing.width;
    reference.height = packaing.height;
  }

  // Funcion invocada para setear reiniciar los valores del lote cuando una referencia es seleccionada
  resetBatch() {
    this.reference.batch = '';
    this.reference.batchExpiration = null;
  }

  addBulk() {
    this.reference.setBulkType('reference');
    this.addToBulks(this.reference);
    this.reset();
  }

  reset(bulk: PreparationBulk = new PreparationBulk()) {
    this.reference = bulk;
  }
}
