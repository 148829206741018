import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { Branch } from '@/settings/domain/branch/Branch';
import { BranchRepository } from '@/settings/domain/branch/BranchRepository';

@Injectable()
export class BranchService implements BranchRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;

  private readonly BRANCH_API: string = this.API_BASE_URI + localStorageModule().config.routes.BRANCH_API;
  private readonly BRANCH_FIND_BY_STATUS: string =
    this.API_BASE_URI + localStorageModule().config.routes.BRANCH_FIND_BY_STATUS;
  private readonly BRANCH_FIND_BY_CODE: string =
    this.API_BASE_URI + localStorageModule().config.routes.BRANCH_FIND_BY_CODE;

  async create(city: Branch): Promise<Branch> {
    try {
      return await http.post(this.BRANCH_API, JSON.stringify(city));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(city: Branch): Promise<Branch> {
    try {
      return await http._put(this.BRANCH_API, JSON.stringify(city));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async delete(code: string): Promise<Branch> {
    try {
      return await http.singleDelete(this.BRANCH_API + code);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findAll(): Promise<Branch[]> {
    try {
      return await http.get(this.BRANCH_API);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findByCode(code: string): Promise<Branch | null> {
    try {
      return await http.get(this.BRANCH_FIND_BY_CODE + code);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findByStatus(status: boolean): Promise<Branch[]> {
    try {
      return await http.get(this.BRANCH_FIND_BY_STATUS + status);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
