import { TYPES } from '@/core/config/Types';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Query } from '@/core/cqrs/Query';
import { StateManager } from '@/core/statemanager/StateManager';
import { Switches } from '@/tracking/domain/switches/Switches';
import { SwitchesRepository } from '@/tracking/domain/switches/SwitchesRepository';

@Injectable()
export class SwitchFindAllByProcessType extends Query<Promise<Switches[]>, number> {
  public constructor(
    @Inject(TYPES.SWITCH_REPOSITORY)
    private readonly switchesRepository: SwitchesRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(params: number): Promise<Switches[]> {
    const dataSwitches = await this.switchesRepository.findAllSwitchByProcessType(params);
    // Metodo para no mostrar ningun switch cuyo valor exceda 99
    const extractedResponse = dataSwitches
      .filter((item: Switches) => {
        return parseInt(item.sw) < 100;
      })
      .sort((a, b) => {
        return parseInt(a.sw) - parseInt(b.sw);
      });

    return extractedResponse;
  }
}
