import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { BulkRepository } from '@/wms/domain/bulk/BulkRepository';
import { GroupedBulk } from '@/wms/domain/bulk/GroupedBulk';

@Injectable()
export class FindBulksByPickingLists extends Query<Promise<GroupedBulk[]>, number[]> {
  public constructor(
    @Inject(TYPES.BULK_REPOSITORY)
    private readonly repository: BulkRepository
  ) {
    super();
  }

  async internalExecute(pickingList: number[]): Promise<GroupedBulk[]> {
    try {
      return await this.repository.findByPickingLists(pickingList);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
