
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import { UserForList } from '@/settings/domain/user/Projections/UserForList';
import { getLocalstorageItem } from '@/core/config/LocalStorageVariables';
import { RoleFindByCompanyId } from '@/settings/application/uses_cases/role/search/RoleFindByCompanyId';
import CreateRole from './components/CreateRole.vue';
import { RoleDTO } from '@/settings/domain/role/RoleDTO';
import { CreateRoleV2 } from '@/settings/application/uses_cases/role/create/CreateRoleV2';
import { UpdateRoleV2 } from '@/settings/application/uses_cases/role/update/UpdateRoleV2';
import { RoleDelete } from '@/settings/application/uses_cases/role/delete/RoleDelete';
import { FindMenuByRole } from '@/settings/application/uses_cases/menu/search/FindMenuByRole';
import { Menu } from '@/settings/domain/menu/menu';
import { FindAllMenu } from '@/settings/application/uses_cases/menu/search/FindAllMenu';

@Component({
  name: 'RoleManagement',
  components: {
    CustomTableN,
    CreateRole
  }
})
export default class RoleManagement extends Vue {
  @Inject(TYPES.FIND_ROLE_BY_COMPANY_ID)
  private readonly findRoles!: RoleFindByCompanyId;
  @Inject(TYPES.CREATE_ROLE_V2)
  private readonly createRole!: CreateRoleV2;
  @Inject(TYPES.UPDATE_ROLE_V2)
  private readonly updateRole!: UpdateRoleV2;
  @Inject(TYPES.DELETE_ROLE)
  private readonly deleteRole!: RoleDelete;
  @Inject(TYPES.MENU_FIND_BY_ROLE)
  private readonly findMenusByRole!: FindMenuByRole;
  @Inject(TYPES.MENU_FIND_ALL)
  private readonly findMenus!: FindAllMenu;

  isLoading = false;
  fullPage = true;
  isEdit = false;

  selectedUser: string | null = null;
  selectedRole: any | null = null;
  users: UserForList[] = [];

  roles: any[] = [];
  formRole: RoleDTO = new RoleDTO();
  roleMenuArray: Menu[] = [];

  formInvite = {
    email: '',
    role: 0
  };

  $refs!: {
    createRoleComponent: CreateRole;
  };

  tableActions = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: this.setEditData
      },
      {
        title: `${this.$t('general.delete')}`,
        icon: 'fa fa-close',
        variant: 'danger',
        action: this.delete
      }
    ]
  };

  get fields() {
    return [
      {
        field: 'id',
        label: 'ID'
      },
      {
        field: 'name',
        label: this.$t('general.role')
      },
      {
        field: 'type',
        label: this.$t('general.type')
      },
      {
        field: 'actions',
        label: this.$t('general.actions')
      }
    ];
  }

  get namespace() {
    return getLocalstorageItem('BUSINESS_ID');
  }

  mounted() {
    this.getRoles();
  }

  async getRoles() {
    try {
      if (!this.namespace) return;
      this.isLoading = true;
      this.roles = await this.findRoles.internalExecute(this.namespace);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async setEditData(role: RoleDTO) {
    this.formRole = Object.assign({}, role);
    this.isEdit = true;
    await this.getRoleMenu(role);
    this.openModal();
  }

  edit() {
    try {
      throw new Error('Method not implemented.');
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async openModal() {
    if (!this.isEdit) await this.getMenus();
    this.$bvModal.show('createRoleModal');
  }

  async save() {
    try {
      if (!this.formRole || !this.namespace) return;
      this.isLoading = true;

      const payload = Object.assign({}, this.formRole);
      payload.namespace = this.namespace;
      payload.menu = this.getPlainMenu(this.roleMenuArray);

      if (this.isEdit) {
        await this.updateRole.internalExecute(payload);
      } else {
        await this.createRole.internalExecute(payload);
      }
      this.reset();
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
      this.getRoles();
      this.$bvModal.hide('createRoleModal');
    }
  }

  async delete(role: RoleDTO) {
    try {
      if (!role || !this.namespace) return;
      this.isLoading = true;
      role.namespace = this.namespace;
      await this.deleteRole.internalExecute(role);
      this.reset();
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
      this.getRoles();
      this.$bvModal.hide('createRoleModal');
    }
  }

  reset() {
    this.formRole = new RoleDTO();
    this.isEdit = false;
  }

  async getRoleMenu(role: RoleDTO) {
    try {
      if (!role.id || !this.namespace) return;
      this.isLoading = true;
      this.roleMenuArray = await this.findMenusByRole.internalExecute({
        companyId: this.namespace,
        roleId: role.id,
        type: 'plain'
      });
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  getPlainMenu(menuList: Menu[]) {
    // Debemos obtener recursivamente los menus y mapearlos a un array plano, incluyendo los hijos
    // Para ello, podemos usar una función recursiva que recorra los hijos de cada menu y los añada al array los seleccionados

    const plainMenu: number[] = [];

    menuList.forEach(menu => {
      if (menu.selected) {
        plainMenu.push(Number(menu.id));
      }
      this.getChildren(menu, plainMenu);
    });

    return plainMenu;
  }

  getChildren = (menu: Menu, plainMenu: number[]) => {
    if (menu.children && menu.children.length > 0) {
      menu.children.forEach(child => {
        if (child.selected) {
          plainMenu.push(child.id);
        }
        this.getChildren(child, plainMenu);
      });
    }
  };

  async getMenus() {
    try {
      this.isLoading = true;
      this.roleMenuArray = await this.findMenus.internalExecute();
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }
}
