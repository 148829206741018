
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { UserFindAllForList } from '@/settings/application/uses_cases/user/search/UserFindAllForList';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import { UserForList } from '@/settings/domain/user/Projections/UserForList';
import { getLocalstorageItem } from '@/core/config/LocalStorageVariables';
import { RoleFindByCompanyId } from '@/settings/application/uses_cases/role/search/RoleFindByCompanyId';
import { UserAssignRole } from '@/settings/application/uses_cases/user/update/UserAssignRole';
import RoleManagement from './RoleManagement.vue';
import { InviteUserToNamespace } from '@/settings/application/uses_cases/user/create/InviteUserToNamespace';
import { RoleDTO } from '@/settings/domain/role/RoleDTO';
import { RemoveUserFromNamespace } from '@/settings/application/uses_cases/user/delete/RemoveUserFromNamespace';
import { AssignStoreToUser } from '@/settings/application/uses_cases/user/update/AssignStoreToUser';
import StoreManagement from './StoreManagement.vue';
import { FindAllStore } from '@/wms/application/layout/store/search/FindAllStore';
import { Store } from '@/wms/domain/layout/store/Store';
import { FindAllStoreByUser } from '@/wms/application/layout/store/search/FindAllStoreByUser';

@Component({
  name: 'UserManagement',
  components: {
    CustomTableN,
    RoleManagement,
    StoreManagement
  }
})
export default class UserManagement extends Vue {
  @Inject(TYPES.USER_FIND_ALL_FOR_LIST)
  private readonly findUsers!: UserFindAllForList;
  @Inject(TYPES.FIND_ROLE_BY_COMPANY_ID)
  private readonly findRoles!: RoleFindByCompanyId;
  @Inject(TYPES.USER_ASSIGN_ROLE)
  private readonly assignRole!: UserAssignRole;
  @Inject(TYPES.USER_INVITE_TO_NAMESPACE)
  private readonly inviteUser!: InviteUserToNamespace;
  @Inject(TYPES.REMOVE_FROM_NAMESPACE)
  private readonly removeFromNamespace!: RemoveUserFromNamespace;
  @Inject(TYPES.ASSIGN_STORES_TO_USER)
  private readonly assignStores!: AssignStoreToUser;
  @Inject(TYPES.FIND_ALL_STORE)
  private readonly findAllStores!: FindAllStore;
  @Inject(TYPES.STORES_GET_BY_USER)
  private readonly getStoresByUser!: FindAllStoreByUser;

  isLoading = false;
  fullPage = true;

  selectedUser: string | null = null;
  selectedRole: any | null = null;
  users: UserForList[] = [];
  stores: Store[] = [];
  userStores: Store[] = [];

  roles: any[] = [];

  formInvite: { email: string; role: RoleDTO | null } = {
    email: '',
    role: null
  };

  tableActions = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: this.setEditData
      },
      {
        title: `${this.$t('general.delete')}`,
        icon: 'fa fa-close',
        variant: 'danger',
        action: this.delete
      },
      {
        title: `${this.$t('general.assign store')}`,
        icon: 'fa fa-archive',
        variant: 'primary',
        action: this.setAssignStore
      }
    ]
  };

  get fields() {
    return [
      {
        field: 'name',
        label: this.$t('general.name')
      },
      {
        field: 'email',
        label: this.$t('general.email')
      },
      {
        field: 'roleName',
        label: this.$t('general.role')
      },
      {
        field: 'state',
        label: this.$t('general.status')
      },
      {
        field: 'actions',
        label: this.$t('general.actions')
      }
    ];
  }

  get namespace() {
    return getLocalstorageItem('BUSINESS_ID');
  }

  mounted() {
    this.getUsers();
    this.getRoles();
    this.getStores();
  }

  async getUsers() {
    try {
      if (!this.namespace) return;
      this.isLoading = true;
      this.users = await this.findUsers.internalExecute(this.namespace);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async getRoles() {
    try {
      if (!this.namespace) return;
      this.isLoading = true;
      this.roles = await this.findRoles.internalExecute(this.namespace);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async invite() {
    try {
      if (!this.namespace || !this.formInvite.role) return;
      this.isLoading = true;

      await this.inviteUser.internalExecute({
        email: this.formInvite.email,
        namespace: this.namespace,
        role: this.formInvite.role?.id
      });

      this.$bvModal.hide('invite');
      this.getUsers();
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  setEditData(user: UserForList) {
    try {
      this.selectedUser = user.email;
      this.selectedRole = this.roles.find(role => role.id === user.roleId);
      this.$bvModal.show('edit');
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async edit() {
    try {
      if (!this.selectedUser || !this.namespace || !this.selectedRole) return;
      this.isLoading = true;

      await this.assignRole.internalExecute({
        email: this.selectedUser,
        namespace: this.namespace,
        role: this.selectedRole.id
      });
      this.$bvModal.hide('edit');
      this.getUsers();
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async delete(user: UserForList) {
    try {
      if (!user || !this.namespace) return;
      this.isLoading = true;
      await this.removeFromNamespace.internalExecute({
        email: user.email,
        namespace: this.namespace
      });
      this.getRoles();
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async assignStore() {
    try {
      if (!this.selectedUser) return;
      this.isLoading = true;
      await this.assignStores.internalExecute({
        store: this.userStores,
        user: this.selectedUser
      });
      this.$bvModal.hide('assignStore');
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async setAssignStore(user: UserForList) {
    try {
      this.selectedUser = user.email;
      await this.getUsersStores(user);
      this.$bvModal.show('assignStore');
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async getStores() {
    try {
      this.isLoading = true;
      this.stores = await this.findAllStores.internalExecute();
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async getUsersStores(user: UserForList) {
    try {
      this.isLoading = true;
      this.userStores = await this.getStoresByUser.internalExecute(user.email);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  clearForm() {
    this.formInvite = {
      email: '',
      role: null
    };
  }
}
