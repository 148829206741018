import { getLocalstorageItem } from '../config/LocalStorageVariables';
import { http } from '../http/HttpFetch';

export async function getMenu(rol: number) {
  try {
    // await requestGet('role/findMenuByRoleName/' + rol)
    //   .then((response: any) => response.json())
    //   .then((data: any) => {
    //     localStorage.setItem('menuUser', JSON.stringify(data['menu']));
    //   });
    const company = getLocalstorageItem('BUSINESS_ID');
    const response = await http.get(
      process.env.VUE_APP_URL_TOKEN + '/menu/find-by-role?roleId=' + rol + '&companyId=' + company
    );
    localStorage.setItem('menuUser', JSON.stringify(response));
  } catch (err) {
    throw new Error(`${err}`);
  }
}
