import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { CloudConfig } from '@/settings/domain/cloudConfig/CloudConfig';
import { CloudConfigRepository } from '@/settings/domain/cloudConfig/CloudConfigRepository';

@Injectable()
export class CloudConfigService implements CloudConfigRepository {
  private readonly API_BASE_URI: string = localStorageModule().auth.routes.API_BASE_URI;
  private readonly API_CLOUD_CONFIG: string = this.API_BASE_URI + localStorageModule().auth.routes.API_CLOUD_CONFIG;
  private readonly API_CLOUD_CONFIG_FIND_BY_COMPANY: string =
    this.API_BASE_URI + localStorageModule().auth.routes.API_CLOUD_CONFIG_FIND_BY_COMPANY;

  async findByCompanyId(id: number): Promise<CloudConfig[]> {
    return await http.get<CloudConfig[]>(this.API_CLOUD_CONFIG_FIND_BY_COMPANY + 'company=' + id);
  }

  async save(dto: CloudConfig): Promise<CloudConfig> {
    return await http.post<CloudConfig>(this.API_CLOUD_CONFIG, JSON.stringify(dto));
  }
}
