import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { Injectable } from '@/core/di/Injectable';
import { ReferencesRepository } from '@/wms/domain/catalogs/references/ReferencesRepository';
import Serial from '@/wms/domain/catalogs/references/Serial';
import { Inject } from 'inversify-props';
/**
 * @class DeleteSerials
 * @description Clase que permite eliminar referencias(productos y servicios)
 * @version 1.0.0
 * @Author Mauricio Cantillo
 */
@Injectable()
export class DeleteSerials extends Command<Promise<Serial[]>, { code: string; serials: string[] }> {
  public constructor(
    @Inject(TYPES.REFERENCE_REPOSITORY)
    readonly repository: ReferencesRepository
  ) {
    super();
  }

  public async internalExecute(params: { code: string; serials: string[] }): Promise<Serial[]> {
    const { code, serials } = params;
    return await this.repository.deleteAssociatedSerial(code, serials);
  }
}
