
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import i18n from '@/core/plugins/i18n';
import Swal from 'sweetalert2';
import CreateTimelineCourier from '@/tracking/infrastructure/ui/components/courier/timeline/CreateTimelineCourier.vue';
import TimelineComponent from '@/tracking/infrastructure/ui/components/courier/timeline/TimelineComponent.vue';
import TableTimeline from '@/tracking/infrastructure/ui/components/shared/TableTimeline.vue';
import TableEvent from '@/tracking/infrastructure/ui/components/shared/TableEvent.vue';
import { TimelineFindByProcessType } from '@/tracking/application/uses_cases/timeline/search/findtimelinebyprocesstype/TimelineFindByProcessType';
import { EventsFindByProcessType } from '@/tracking/application/uses_cases/events/search/EventsFindByProcessType';
import { UpdateTimeline } from '@/tracking/application/uses_cases/timeline/update/UpdateTimeline';
import Draggable from 'vuedraggable';

@Component({
  name: 'ManageTimelineCourier',
  components: {
    TableEvent,
    TableTimeline,
    TimelineComponent,
    CreateTimelineCourier,
    Draggable
  }
})
export default class AppManageTimeline extends Vue {
  @Inject(TYPES.FINDBYPROCESSTYPE_TIMELINE)
  readonly timelineFindByProcessType!: TimelineFindByProcessType;
  @Inject(TYPES.UPDATE_TIMELINE)
  readonly updateTimeline!: UpdateTimeline;
  @Inject(TYPES.FINDEVENTSBYPROCESSTYPE_EVENTS)
  readonly eventsFindByProcessType!: EventsFindByProcessType;

  //Data
  target = i18n.t('general.timeline');
  isLoading = false;
  fullPage = true;
  timelineList = [];
  processEventList = [];
  processEventListModal = [];
  selectedEvent = 0;
  componentKey = 0;
  private formLineTime: any = {
    timelineSelected: [],
    timelineListFilter: [],
    dataTimeline: [],
    validationButtonSave: false,
    timelineListByIndex: [],
    eventsListByIndex: [],
    eventsListLink: [],
    valueItem: '',
    selectEvent: []
  };
  //Refs
  $refs!: {
    mdCreateModal: HTMLFormElement;
    mdAddEvent: HTMLFormElement;
    dropdownTimeline: HTMLFormElement;
  };
  //Hook
  created() {
    this.getTimelineSkeleton();
    this.eventsProcessType();
  }
  //Computed
  eventsProcessType() {
    this.eventsFindByProcessType.execute(1).then((response: any) => {
      this.processEventList = response;
    });
  }
  //method
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  async getTimelineSkeleton() {
    this.isLoading = true;
    await this.timelineFindByProcessType
      .execute(1)
      .then((response: any) => {
        this.isLoading = false;
        const res = response.reverse();
        this.timelineList = res;
        this.formLineTime.timelineListFilter = res;
        for (const index in res) {
          if (index === '0') {
            this.$set(res[0], 'visible', true);
            this.getTimelineDetail(res[0]);
          }
        }
        this.componentKey += 1;
        this.closeModal();
      })
      .catch(error => {
        this.isLoading = false;
        this.timelineList = [];
        throw new Error(error);
      });
  }
  async filterTimeline() {
    try {
      await this.getTimelineSkeleton();
      const result: any = this.timelineList.filter((data: any) => data.id === this.formLineTime.timelineSelected.id);
      this.timelineList = [];
      this.timelineList = result;
      for (const time of this.timelineList) {
        this.$set(time, 'visible', true);
        this.getTimelineDetail(time);
      }
      this.$refs.dropdownTimeline.hide(true);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  closeModal() {
    this.$refs['mdCreateModal'].hide();
  }
  getTimelineDetail(data: any) {
    this.formLineTime.timelineListByIndex = data;
    this.formLineTime.eventsListByIndex = data.events;
    this.formLineTime.valueItem = 'timeline';
    this.formLineTime.eventsListByIndex.forEach((item: any) => {
      this.$set(item, 'hover', false);
    });
    this.formLineTime.eventsListByIndex.sort((a: any, b: any) => a.sequence - b.sequence);
    const arrayEvents: [] = [];
    for (let i = 0; i < this.processEventList.length; i++) {
      let igual = false;
      for (let j = 0; j < data.events.length && !igual; j++) {
        if (this.processEventList[i]['description'] === data.events[j]['description']) {
          igual = true;
        }
      }
      if (!igual) arrayEvents.push(this.processEventList[i]);
    }
    this.processEventListModal = arrayEvents;
  }
  deleteTimeline(dataTimeline: any) {
    Swal.fire({
      title:
        this.$i18n.t('general.areyousuretodeletethis') +
        ' ' +
        this.$i18n.t('general.timeline') +
        ' ' +
        this.$i18n.t('general.squestion'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `${i18n.t('general.delete')}`,
      cancelButtonText: `${i18n.t('general.cancel')}`,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    }).then(result => {
      if (result.isConfirmed) {
        const payloadUpdate: any = {
          id: dataTimeline.id,
          description: dataTimeline.description,
          typeTransport: dataTimeline.typeTransport,
          typeOperation: dataTimeline.typeOperation,
          processType: 1,
          active: false,
          events: dataTimeline.events
        };
        this.isLoading = true;
        this.updateTimeline
          .execute(payloadUpdate)
          .then((response: any) => {
            this.isLoading = false;
            this.getTimelineSkeleton();
            this.formLineTime.valueItem = '';
            return response;
          })
          .catch((error: any) => {
            this.isLoading = false;
            throw new Error(error);
          });
      } else {
        return false;
      }
    });
  }
  addEvents(dataTimeline: any) {
    let position = 1;
    const eventList: any = [];
    this.formLineTime.selectEvent.forEach((event: any) => {
      event.id = null;
      event.sequence = dataTimeline.events.length + position;
      position += 1;
      eventList.push(event);
    });
    const payloadUpdate: any = {
      id: dataTimeline.id,
      description: dataTimeline.description,
      typeTransport: dataTimeline.typeTransport,
      typeOperation: dataTimeline.typeOperation,
      processType: 1,
      active: true,
      events: dataTimeline.events.concat(eventList)
    };
    this.isLoading = true;
    this.updateTimeline
      .execute(payloadUpdate)
      .then((response: any) => {
        this.isLoading = false;
        this.formLineTime.eventsListByIndex = [];
        this.formLineTime.eventsListByIndex = payloadUpdate.events;
        this.getTimelineSkeleton();
        this.getTimelineDetail(payloadUpdate);
        this.formLineTime.selectEvent = [];
        this.$refs['mdAddEvent'].hide();
        return response;
      })
      .catch((error: any) => {
        this.isLoading = false;
        throw new Error(error);
      });
  }
  //DRAGGABLE EFFECTS TO ORDER TREE EVENTS v2.0
  onEndDrag(events: any, dataTimeline: any) {
    events.forEach((element: any, index: number) => {
      element.sequence = index + 1;
    });
    dataTimeline.events = events;
    this.updateTimeline
      .execute(dataTimeline)
      .then((response: any) => {
        this.getTimelineDetail(dataTimeline);
        return response;
      })
      .catch(error => {
        throw new Error(error);
      });
  }
  //END DRAGGABLE LOGIC
}
