import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { TypeIdentification } from '@/settings/domain/entity/typeIdentification/TypeIdentification';
import { TypeIdentificationRepository } from '@/settings/domain/entity/typeIdentification/TypeIdentificationRepository';

@Injectable()
export class TypeIdentificationFindAll extends Query<Promise<TypeIdentification[]>> {
  public constructor(
    @Inject(TYPES.TYPE_IDENTIFICATION_REPOSITORY)
    private readonly repository: TypeIdentificationRepository,

    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(): Promise<TypeIdentification[]> {
    const dataTypeIdentification = await this.repository.findAll();
    this.stateManager.patch({ dataTypeIdentification });
    return this.stateManager.state.dataTypeIdentification;
  }
}
