import { localStorageModule } from '@/core/config/ConfigurationServer';
import { http } from '@/core/http/HttpFetch';
import { RecentShipments } from '@/settings/domain/recentShipments/RecentShipments';
import { RecentShipmentsRepository } from '@/settings/domain/recentShipments/RecentShipmentsRepository';
import { Injectable } from '@/core/di/Injectable';

@Injectable()
export class RecentShipmentsService implements RecentShipmentsRepository {
  API_BASE_URI: string = localStorageModule().wms.host.API_BASE_URI;
  API_FIND_ALL_RECENT_SHIPMENTS: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_PRE_REGISTER_FIND_LAST_SHIPMENTS;

  async findAll(): Promise<RecentShipments[]> {
    try {
      return await http.get<RecentShipments[]>(this.API_FIND_ALL_RECENT_SHIPMENTS);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
