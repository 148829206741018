import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Command } from '@/core/cqrs/Command';
import { TYPES } from '@/core/config/Types';
import { Store } from '@/wms/domain/layout/store/Store';
import { StoreRepository } from '@/wms/domain/layout/store/StoreRepository';

@Injectable()
export class AssignStoreToUser extends Command<Promise<Store[]>, { user: string; store: Store[] }> {
  public constructor(
    @Inject(TYPES.STORE_REPOSITORY)
    private readonly userRepository: StoreRepository
  ) {
    super();
  }

  internalExecute(payload: { user: string; store: Store[] }): Promise<Store[]> {
    return this.userRepository.assignStore(payload);
  }
}
