import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { CompanyRepository } from '@/settings/domain/company/CompanyRepository';
import { MailServerConfig } from '@/settings/domain/mailServerConfig/MailServerConfig';

@Injectable()
export class CreateEmailConfig extends Command<Promise<any>, MailServerConfig> {
  public constructor(
    @Inject(TYPES.COMPANY_REPOSITORY)
    private readonly companyRepository: CompanyRepository
  ) {
    super();
  }

  internalExecute(config: MailServerConfig): Promise<MailServerConfig> {
    return this.companyRepository.createEmailConfig(config);
  }
}
