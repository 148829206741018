
import Vue from 'vue';
import Component from 'vue-class-component';
import BillOfLandingAddForm from './BillOfLandingAddForm.vue';
import { BillOfLanding } from '@/wms/domain/billOfLanding/BillOfLanding';
@Component({
  name: 'BillOfLandingCenter',
  components: {
    BillOfLandingAddForm
  }
})
export default class BillOfLandingCenter extends Vue {
  //Data
  isLoading = false;

  //Form
  form: BillOfLanding = new BillOfLanding();

  //Objeto de acciones del form
  actions = {
    save: this.save,
    clear: this.clear
  };

  //
  clear() {
    this.form = new BillOfLanding();
  }

  save() {
    // eslint-disable-next-line no-console
    console.log(this.form);
  }
}
