import { DocsLin, StockCalculatedValues } from '@/courier/domain/DocsLin/DocsLin';

/**
 *
 * @param item
 * @returns El 'precio unitario' calculado con descuento (en el caso que tenga descuento)
 */
const calculateUnitValue = (item: DocsLin): number => Number(item.unitValue) * (1 - Number(item.discountRate) / 100);

/**
 *
 * @param num
 * @returns Valor numérico formateado a porcentaje
 */
const formatAsPercent = (num: number): string =>
  new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(num / 100);

/**
 *
 * @param item
 * @param newItem
 * @returns El porcentaje de utilidad calculado teniendo en cuenta el switch del documento
 */
const calculateProfit = (item: DocsLin, newItem: StockCalculatedValues): string | number => {
  const profit =
    ((calculateUnitValue(item) - newItem.totalCost / Number(item.quantity)) / calculateUnitValue(item)) * 100;

  const profitAbs =
    item.sw !== '13' && item.sw !== '14' ? (Math.sign(Number(profit.toFixed(2))) === 0 ? Math.abs(profit) : profit) : 0;

  return formatAsPercent(profitAbs);
};

/**
 *
 * @param docsLin
 * @param stock
 * @returns El arreglo de la consulta de movimientos con los valores calculados
 * a partir de la vista de documents_lin
 */
export const stockInquiryWithCalculatedValues = (docsLin: DocsLin[], stock: number): StockCalculatedValues[] => {
  let objectTemp: StockCalculatedValues | null = null;

  if (docsLin.length > 0) {
    return docsLin.map((item: DocsLin) => {
      const newItem: StockCalculatedValues = {
        ...item,
        stockBalance: 0,
        stockValue: 0,
        unitCostSetted: 0,
        unitValueSetted: 0,
        totalCost: 0,
        averageCost: 0,
        totalPrice: 0,
        profit: 0
      };

      /**
       * Estables los cálculos progresivos de los valores 'Saldo stock' y 'Saldo valor'
       * según el switch del documento
       */
      if (objectTemp === null) {
        switch (item.sw) {
          case '2':
          case '3':
          case '12':
          case '16':
            newItem.stockBalance = Number(stock) + Number(item.entry);
            newItem.stockValue = Number(item.unitCost) * Number(item.entry);
            break;
          case '1':
          case '4':
          case '11':
            newItem.stockBalance = Number(stock) - Number(item.invoice);
            newItem.stockValue = Number(item.unitCost) * Number(item.invoice);
            break;
          case '13':
          case '14':
            newItem.stockBalance = Number(stock);
            newItem.stockValue = calculateUnitValue(item);
            break;
        }
      } else {
        newItem.stockBalance = Number(objectTemp.stockBalance) + Number(item.entry) - Number(item.invoice);

        switch (item.sw) {
          case '2':
          case '3':
          case '12':
          case '16':
            newItem.stockValue = Number(objectTemp.stockValue) + Number(item.unitCost) * Number(item.entry);
            break;
          case '1':
          case '4':
          case '11':
            newItem.stockValue = Number(objectTemp.stockValue) - Number(item.unitCost) * Number(item.invoice);
            break;
          case '13':
            newItem.stockValue = Number(objectTemp.stockValue) - calculateUnitValue(item) * newItem.stockBalance;
            break;
          case '14':
            newItem.stockValue = Number(objectTemp.stockValue) + calculateUnitValue(item) * newItem.stockBalance;
            break;
        }
      }
      objectTemp = newItem;

      /**
       * Establece valor de 'Costo unitario' según el switch del documento
       */
      newItem.unitCostSetted =
        item.sw !== '13' && item.sw !== '14' ? (item.unitCost !== null ? item.unitCost.toFixed(2) : 0) : '-';

      /**
       * Establece valor de 'Precio unitario' con descuento
       */
      newItem.unitValueSetted = calculateUnitValue(item);

      /**
       * Establece valor de 'Costo total' según el swtich del documento
       */
      switch (item.sw) {
        case '2':
        case '3':
        case '12':
        case '16':
          newItem.totalCost = Number(item.unitCost) * Number(item.entry);
          break;
        case '1':
        case '4':
        case '11':
          newItem.totalCost = Number(item.unitCost) * Number(item.invoice);
          break;
        case '13':
          newItem.totalCost = Number(item.unitCost) * Number(newItem.stockBalance) - calculateUnitValue(item);
          break;
        case '14':
          newItem.totalCost = Number(item.unitCost) * Number(newItem.stockBalance) + calculateUnitValue(item);
          break;
        default:
          newItem.totalCost = item.unitCost;
          break;
      }

      /**
       * Establece valor de 'Costo promedio'
       */
      newItem.averageCost = !isNaN(Number(newItem.stockValue) / Number(newItem.stockBalance))
        ? Number(newItem.stockValue) / Number(newItem.stockBalance)
        : 0;

      /**
       * Establece valor de 'Precio total'
       */
      newItem.totalPrice = calculateUnitValue(item) * Number(item.quantity);

      /**
       * Establece valor del porcentaje de 'Utilidad'
       */
      newItem.profit = item.sw === '1' || item.sw === '2' ? calculateProfit(item, newItem) : '';

      return newItem;
    });
  }

  return [];
};
