import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Size } from '@/wms/domain/gamma/Size';
import { SizeRepository } from '@/wms/domain/gamma/SizeRepository';

@Injectable()
export default class SizeServices implements SizeRepository {
  private readonly API_BASE_URI: string = localStorageModule().wms.host.API_BASE_URI;
  private readonly API_SIZE_SAVE: string = this.API_BASE_URI + localStorageModule().wms.routes.API_SIZE;
  private readonly API_SIZE_DELETE: string = this.API_BASE_URI + localStorageModule().wms.routes.API_SIZE;
  private readonly API_SIZE_FINDALL: string = this.API_BASE_URI + localStorageModule().wms.routes.API_SIZE;

  async saveSize(dto: Size): Promise<Size> {
    try {
      return await http.post(this.API_SIZE_SAVE, JSON.stringify(dto));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAllSize(): Promise<Size[]> {
    try {
      return await http.get(this.API_SIZE_FINDALL);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async deleteSize(dto: Size): Promise<Size> {
    try {
      return await http.delete(this.API_SIZE_DELETE, JSON.stringify(dto));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
