import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { Size } from '@/wms/domain/gamma/Size';
import { SizeRepository } from '@/wms/domain/gamma/SizeRepository';

@Injectable()
export class CreateSize extends Command<Promise<Size>, Size> {
  public constructor(
    @Inject(TYPES.SIZE_REPOSITORY)
    private readonly sizeRepository: SizeRepository
  ) {
    super();
  }

  async internalExecute(dto: Size): Promise<Size> {
    return await this.sizeRepository.saveSize(dto);
  }
}
