import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Purchase } from '@/courier/domain/purchase/Purchase';
import { PurchaseRepository } from '@/courier/domain/purchase/PurchaseRepository';

@Injectable()
export class PurchaseServices implements PurchaseRepository {
  private readonly API_BASE_URI: string = localStorageModule().courier.host.API_BASE_URI;
  private readonly API_PURCHASE_GETDATAFROMAMAZONURL: string = process.env.VUE_APP_SCRAPPER_URL ?? '';

  async getDataFromAmazonUrl(url: string): Promise<Purchase[]> {
    try {
      return await http.get<Purchase[]>(this.API_PURCHASE_GETDATAFROMAMAZONURL + url);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
