
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { CreateEvents } from '@/tracking/application/uses_cases/events/create/CreateEvents';
import { UpdateEvents } from '@/tracking/application/uses_cases/events/update/UpdateEvents';
import { OperationFindByProcessType } from '@/tracking/application/uses_cases/typeoperation/search/OperationFindByProcessType';
import { MonetaryTransactionFindAll } from '@/tracking/application/uses_cases/monetarytransaction/search/findall/MonetaryTransactionFindAll';
import { EventsFindByProcessType } from '@/tracking/application/uses_cases/events/search/EventsFindByProcessType';
import Swal from 'sweetalert2';
import i18n from '@/core/plugins/i18n';
import { sortDescending } from '@/core/plugins/FunctionUtil';
import { SwitchFindAllByProcessType } from '@/tracking/application/uses_cases/switches/search/SwitchFindAllByProcessType';
import { TimelineFindByProcessType } from '@/tracking/application/uses_cases/timeline/search/findtimelinebyprocesstype/TimelineFindByProcessType';
import Draggable from 'vuedraggable';
import { DeleteActivity } from '@/tracking/application/uses_cases/activities/delete/DeleteActivity';
import { Switches } from '@/tracking/domain/switches/Switches';

@Component({
  name: 'ManageEventsFF',
  components: { Draggable }
})
export default class AppManageEventsFF extends Vue {
  //EVENT INJECTIONS
  @Inject(TYPES.FINDBYPROCESSTYPE_TYPE_OPERATION)
  readonly operationFindByProcessType!: OperationFindByProcessType;
  @Inject(TYPES.FINDEVENTSBYPROCESSTYPE_EVENTS)
  readonly eventsFindByProcessType!: EventsFindByProcessType;
  @Inject(TYPES.FINDBYPROCESSTYPE_SWITCH)
  readonly switchFindAllByProcessType!: SwitchFindAllByProcessType;
  @Inject(TYPES.CREATE_EVENTS)
  readonly createEvents!: CreateEvents;
  @Inject(TYPES.UPDATE_EVENTS)
  readonly updateEvents!: UpdateEvents;
  @Inject(TYPES.FINDALL_MONETARY_TRANSACTION)
  readonly monetaryTransactionFindAll!: MonetaryTransactionFindAll;
  @Inject(TYPES.DELETE_ACTIVITY)
  readonly deleteAtivity!: DeleteActivity;

  //TREE INJECTIONS
  @Inject(TYPES.FINDBYPROCESSTYPE_TIMELINE)
  readonly timelineFindByProcessType!: TimelineFindByProcessType;

  //01 - TREE VARIABLES
  timelineList = [];
  isLoading = false;
  fullPage = true;
  target = i18n.t('general.event');
  //01 - 01 - TREE CSS EFFECTS
  selectedEvent = 0;
  selectedTimeLine = 0;
  openEvents: any = [];
  openTimeLines: any = [];

  //02 - EVENTS DATA
  courierEventSwitch = null;
  activityMonetaryList = [];
  activitySwitchList: Switches[] = [];
  typeOperationList = [];
  private eventsList: any = [
    {
      description: '',
      typeOperation: '',
      day: 0,
      handleSpreadAutomatically: false,
      active: true,
      visible: false,
      forCourier: false,
      sendNotification: false,
      activities: [
        {
          description: '',
          monetaryTransactionId: null,
          required: true,
          value: '',
          active: true,
          spreadAutomatically: true,
          sw: [],
          processType: 3
        }
      ]
    }
  ];
  private formLineTimeWms: any = {
    eventSelected: [],
    eventsListFilter: [],
    activeActivity: false,
    isTouched: false,
    dataTypeOperation: [],
    disabledEvents: false,
    edit: null
  };
  //Refs
  $refs!: {
    activityDescription: HTMLFormElement;
    dropdown: HTMLFormElement;
  };

  //Hook
  async mounted() {
    await this.typeOperation();
    await this.switch();
    await this.getFindAllEvents();
  }

  //Computed
  async typeOperation() {
    this.isLoading = true;
    await this.operationFindByProcessType.execute(3).then((response: any) => {
      this.typeOperationList = response;
      this.isLoading = false;
    });
  }

  get typeMonetary() {
    this.monetaryTransactionFindAll.execute().then((response: any) => {
      this.activityMonetaryList = response;
    });
    this.formLineTimeWms.activeActivity = true;
    return true;
  }

  customLabelSwitch(switchList: any) {
    return `${switchList.sw} - ${this.$t(`switchs.${switchList.sw}`)}`;
  }

  async switch() {
    try {
      const response: Switches[] = await this.switchFindAllByProcessType.execute(3);

      this.activitySwitchList = response;

      this.activitySwitchList.map((item: Switches) => {
        const translatedResult = this.$t(`switchs.${item.sw}`);

        if (typeof translatedResult === 'string') {
          item.nature = translatedResult;
        } else {
          item.nature = 'Translation not available';
        }
      });
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  //method
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  async getFindAllEvents() {
    this.isLoading = true;
    await this.eventsFindByProcessType
      .execute(3)
      .then((response: any) => {
        response.map((evento: any) => {
          if (evento.forCourier) {
            evento.courierEventSwitch = this.activitySwitchList.filter(
              (item: any) => evento.activities[0].sw === item.sw
            );
          }
        });
        this.isLoading = false;
        const res = sortDescending(response);
        this.eventsList = res;
        this.formLineTimeWms.eventsListFilter = res;
        this.formLineTimeWms.disabledEvents = true;
        for (const ev in response) {
          if (ev === '0') {
            this.eventsList[ev].visible = true;
          }
        }
      })
      .catch(error => {
        this.isLoading = false;
        this.eventsList = [];
        throw new Error(error);
      });
  }

  saveEventWms(index: number) {
    this.eventsList[index].activities.find((activity: any) => {
      if (activity.monetaryTransactionId) {
        activity.monetaryTransactionId = activity.monetaryTransactionId.id;
      }
      if (activity.sw) {
        activity.sw = activity.sw.sw;
      }
    });

    this.setActivitiesSecuence(this.eventsList[index].activities);

    //const validate = this.eventsList[index].activities.some((data: any) => data.description || data.sw);
    this.isLoading = true;
    if (this.eventsList[index].id) {
      if (this.eventsList[index].forCourier === true) {
        this.eventsList[index].activities[0].sw = this.eventsList[index].courierEventSwitch.sw;
      }
      const payloadUpdate: any = {
        id: this.eventsList[index].id,
        description: this.eventsList[index].description,
        day: this.eventsList[index].day,
        handleSpreadAutomatically: false,
        typeOperation: this.formLineTimeWms.dataTypeOperation.code,
        active: this.eventsList[index].active,
        activities: this.eventsList[index].activities,
        forCourier: this.eventsList[index].forCourier,
        sendNotification: this.eventsList[index].sendNotification
      };
      this.updateEvents
        .execute(payloadUpdate)
        .then((response: any) => {
          this.isLoading = false;
          this.getFindAllEvents();
          this.formLineTimeWms.edit = null;
          this.formLineTimeWms.disabledEvents = true;
          this.formLineTimeWms.activeActivity = true;
          return response;
        })
        .catch(error => {
          this.isLoading = false;
          throw new Error(error);
        });
    } else {
      this.eventsList[index].activities.processType = 3;
      const payloadSave: any = {
        description: this.eventsList[index].description,
        day: this.eventsList[index].day,
        handleSpreadAutomatically: false,
        typeOperation: this.formLineTimeWms.dataTypeOperation.code,
        active: true,
        activities:
          this.eventsList[index].forCourier === true
            ? [
                {
                  description: this.eventsList[index].description,
                  monetaryTransactionId: null,
                  required: true,
                  value: '',
                  active: true,
                  sw: this.eventsList[index].courierEventSwitch.sw,
                  processType: 3,
                  spreadAutomatically: true
                }
              ]
            : this.eventsList[index].activities,
        forCourier: this.eventsList[index].forCourier,
        sendNotification: this.eventsList[index].sendNotification
      };
      this.createEvents
        .execute(payloadSave)
        .then((response: any) => {
          this.isLoading = false;
          this.getFindAllEvents();
          this.formLineTimeWms.disabledEvents = true;
          this.formLineTimeWms.activeActivity = true;
          return response;
        })
        .catch(error => {
          this.isLoading = false;
          throw new Error(error);
        });
    }
  }

  editEvents(data: any) {
    this.formLineTimeWms.edit = this.formLineTimeWms.edit !== data.id ? data.id : null;
    this.formLineTimeWms.dataOperation = {
      code: data.typeOperation,
      description: data.typeOperationName
    };
    for (const activity of data.activities) {
      activity.eventReference = data.id;
      if (activity.monetaryTransactionId) {
        activity.monetaryTransactionId = {
          id: activity.monetaryTransactionId,
          name: activity.monetaryTransactionName
        };
      }
      if (activity.sw) {
        activity.sw = {
          sw: activity.sw,
          nature: activity.nature
        };
      }
    }
    this.typeMonetary;
    this.formLineTimeWms.disabledEvents = false;
    data.visible = this.formLineTimeWms.edit != null ? true : false;
  }

  async filterEvent() {
    await this.getFindAllEvents();
    const result: any = this.eventsList.filter((data: any) => data.id === this.formLineTimeWms.eventSelected.id);
    this.eventsList = [];
    this.eventsList = result;
    this.$refs.dropdown.hide(true);
    this.eventsList[0].visible = true;
    return this.eventsList;
  }

  addEvent() {
    this.eventsList.unshift({
      description: '',
      day: 0,
      typeOperation: '',
      handleSpreadAutomatically: false,
      active: true,
      visible: true,
      activities: [
        {
          description: '',
          monetaryTransactionId: null,
          required: true,
          value: '',
          active: true,
          sw: [],
          processType: 3,
          spreadAutomatically: true
        }
      ]
    });
    for (const ev in this.eventsList) {
      if (ev === '0') {
        this.eventsList[ev].visible = true;
      } else {
        this.eventsList[ev].visible = false;
      }
    }
    this.formLineTimeWms.disabledEvents = false;
    this.formLineTimeWms.activeActivity = false;
  }

  addActivity(activities: any) {
    activities.push({
      description: '',
      monetaryTransactionId: null,
      required: true,
      value: '',
      active: true,
      sw: [],
      processType: 3,
      spreadAutomatically: true,
      sequence: 0
    });
  }

  addActivityNext() {
    this.typeMonetary;
    this.formLineTimeWms.activeActivity = true;
  }

  deleteActivityLocal(activity: any, event: any) {
    const idActivity = event.activities.indexOf(activity);
    if (idActivity > -1) {
      event.activities.splice(idActivity, 1);
    }
  }

  async factoryDeleteActivities(activity: any, event: any) {
    const res = await Swal.fire({
      title: this.$i18n.t('general.areyousuretodeletethis'),
      text: `${this.$i18n.t('general.activity')}: ${activity.description}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      reverseButtons: true,
      confirmButtonText: `${i18n.t('general.delete')}`,
      cancelButtonText: `${i18n.t('general.cancel')}`,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    });

    if (!res.isConfirmed) return;

    !('activityId' in activity) ? this.deleteActivityLocal(activity, event) : this.deleteActivityOnDb(activity, event);
  }

  async deleteActivityOnDb(activity: any, event: any) {
    this.isLoading = true;
    try {
      await this.deleteAtivity.execute({ actividyId: activity.activityId, idEvent: event.id });
      this.formLineTimeWms.edit = null;
      this.getFindAllEvents();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  deleteTimelineWms(index: any) {
    if (this.eventsList[index].id) {
      Swal.fire({
        title: this.$i18n.t('general.areyousuretodeletethis'),
        text: `${this.$t('general.event')}: ${this.eventsList[index].description}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        reverseButtons: true,
        confirmButtonText: `${i18n.t('general.delete')}`,
        cancelButtonText: `${i18n.t('general.cancel')}`,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then(result => {
        if (result.isConfirmed) {
          const payloadUpdate: any = {
            id: this.eventsList[index].id,
            description: this.eventsList[index].description,
            day: this.eventsList[index].day,
            handleSpreadAutomatically: false,
            typeOperation: this.formLineTimeWms.dataTypeOperation.code,
            active: false,
            activities: this.eventsList[index].activities
          };
          this.isLoading = true;
          this.updateEvents
            .execute(payloadUpdate)
            .then((response: any) => {
              this.isLoading = false;
              if (response.ok) {
                this.getFindAllEvents();
                return;
              }
              this.$swal(`Error`, `${this.$t('generalmessage.ERROR_MESSAGE_DELETE_FAILED')}`, 'error');
            })
            .catch((error: any) => {
              this.isLoading = false;
              throw new Error(error);
            });
        } else {
          return false;
        }
      });
    } else {
      this.eventsList.shift();
    }
  }

  //TREE COMPUTED && METHODS
  //----- CSS EFECTS LOGIC
  openCloseTree(event: any) {
    let opened: boolean;
    if (!this.openEvents.includes(event.id)) {
      this.openEvents = [];
      this.openEvents.push(event.id);
      opened = true;
    } else {
      this.openEvents.splice(this.openEvents.indexOf(event.id), 1);
      opened = false;
    }
    return opened;
  }

  getTimelineSkeleton() {
    this.isLoading = true;
    this.timelineList = [];
    this.timelineFindByProcessType
      .execute(3)
      .then((response: any) => {
        this.isLoading = false;
        const res = response.reverse();
        this.timelineList = res;
      })
      .catch(error => {
        this.isLoading = false;
        this.timelineList = [];
        throw new Error(error);
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setActivitiesSecuence(activityList: any[]): void {
    activityList = activityList.map((element: any, index: number) => (element.sequence = index + 1));
  }
}
