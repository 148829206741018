
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'WarehouseAdministrationContent'
})
export default class WarehouseAdministrationContent extends Vue {
  selected = [];
}
