
import StoreSearch from '@/core/components/shared/StoreSearch.vue';
import WarehouseSearch from '@/core/components/shared/WarehouseSearch.vue';
import { TYPES } from '@/core/config/Types';
import { WarehouseFindAll } from '@/settings/application/uses_cases/warehouse/search/WarehouseFindAll';
import { Branch } from '@/settings/domain/branch/Branch';
import { Warehouse } from '@/settings/domain/warehouse/Warehouse';
import { ReferenceFindAll } from '@/wms/application/catalogs/references/search/ReferenceFindAll';
import { FindAllStorebyWarehouse } from '@/wms/application/layout/store/search/FindAllStorebyWarehouse';
import { References } from '@/wms/domain/catalogs/references/References';
import { Store } from '@/wms/domain/layout/store/Store';
import { Inject } from 'inversify-props';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import BranchComponentCenter from './BranchComponentCenter.vue';

@Component({
  name: 'AddBranch',
  components: { WarehouseSearch, StoreSearch }
})
export default class AddBranch extends Vue {
  @Inject(TYPES.FINDALL_WAREHOUSE)
  readonly findAllWarehouse!: WarehouseFindAll;
  @Inject(TYPES.FIND_ALL_STORE_WAREHOUSE)
  readonly findStoreByWarehouse!: FindAllStorebyWarehouse;
  @Inject(TYPES.FINDALL_REFERENCE)
  readonly findReference!: ReferenceFindAll;

  @Prop({ required: true, default: () => new Branch() }) form!: Branch;
  @Prop({ required: true }) isEdit!: boolean;

  $parent!: BranchComponentCenter;

  //DATOS
  isLoading = false;
  fullPage = true;
  warehouseList: Warehouse[] = [];
  storeList: Store[] = [];
  referencelist: References[] = [];

  save() {
    this.$parent?.factory(this.form);
  }

  clear() {
    this.$parent?.clearForm();
  }

  mounted() {
    this.findWarehouses();
    this.findReferences();
  }

  //Funcion ejecutada para validar un input
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }): any {
    return dirty || validated ? valid : null;
  }

  //METODOS
  async findWarehouses() {
    this.isLoading = true;
    try {
      const response = await this.findAllWarehouse.execute();
      this.warehouseList = response;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async findStores(warehouse: Warehouse | null) {
    if (!warehouse) {
      this.form.store = null;
      this.storeList = [];
      return;
    }
    this.isLoading = true;
    try {
      const response = await this.findStoreByWarehouse.execute(warehouse.code);
      this.storeList = response;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async findReferences() {
    this.isLoading = true;
    try {
      const response = await this.findReference.execute();
      this.referencelist = response.filter(x => x.noManageStock);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }
}
