import { Color } from './Color';

export class Size {
  size: string;
  colors: Color[];

  public constructor(size?: string, colors?: Color[]) {
    this.size = size || '';
    this.colors = colors || [];
  }
}
