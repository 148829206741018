import { TaxesLine } from '@/courier/domain/taxesline/TaxesLine';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import { Timeline } from '@/tracking/domain/timeline/Timeline';
import { Packaging } from '@/wms/domain/catalogs/packaging/Packaging';
import { References } from '@/wms/domain/catalogs/references/References';
import Serial from '@/wms/domain/catalogs/references/Serial';
import { Store } from '@/wms/domain/layout/store/Store';

export class OrderLine {
  active: boolean;
  amountShipped: number;
  comment: string;
  commodity: CommodityType | null;
  consolidatedNumber: number;
  consolidatedType: string;
  createAt: string;
  date: string;
  description: string;
  discountRate: number;
  enabled: boolean;
  eventLogs: any[];
  fobValue: number;
  freight: number;
  guideNumber: string;
  handleInventory: string;
  height: number;
  lengthy: number;
  number: number;
  originalPrice: number;
  packaging: Packaging | null;
  packagingNumberUnits: number;
  packingNumber: number;
  packingType: string;
  partialPrice: number;
  purchaseUrl: string;
  purchaseValue: number;
  quantity: number;
  reference: References | null;
  scheduled: number;
  sequence: number;
  serial: string;
  servicePrice: number;
  shippedQuantity: number;
  store: Store | null;
  subTotal: number;
  sw: string;
  taxes: TaxesLine[];
  timeline: number;
  timelineName: string;
  timelineSkeletonId: number;
  timelineSkeletonName: string;
  timelineTypeOfTransportId: number;
  timelineTypeOfTransportName: string;
  tm: Timeline | null;
  totalPrice: number;
  totalTaxes: number;
  type: string;
  unconsolidatedNumber: number;
  unconsolidatedType: string;
  unitCost: number;
  unitPrice: number;
  unitValue: number;
  units: string;
  unitsQuantity: number;
  updateAt: string;
  warehouse: string;
  weight: number;
  width: number;
  isPromotional: boolean;
  serials: Serial[] | null;
  batch: string;
  batchExpiration: string;

  public constructor() {
    this.active = true;
    this.amountShipped = 0;
    this.comment = '';
    this.commodity = null;
    this.consolidatedNumber = 0;
    this.consolidatedType = '';
    this.createAt = '';
    this.date = '';
    this.description = '';
    this.discountRate = 0;
    this.enabled = false;
    this.eventLogs = [];
    this.fobValue = 0;
    this.freight = 0;
    this.guideNumber = '';
    this.handleInventory = '';
    this.height = 0;
    this.lengthy = 0;
    this.number = 0;
    this.originalPrice = 0;
    this.packaging = null;
    this.packagingNumberUnits = 0;
    this.packingNumber = 0;
    this.packingType = '';
    this.partialPrice = 0;
    this.purchaseUrl = '';
    this.purchaseValue = 0;
    this.quantity = 1;
    this.reference = null;
    this.scheduled = 0;
    this.sequence = 0;
    this.serial = '';
    this.servicePrice = 0;
    this.shippedQuantity = 0;
    this.store = null;
    this.subTotal = 0;
    this.sw = '';
    this.taxes = [];
    this.timeline = 0;
    this.timelineName = '';
    this.timelineSkeletonId = 0;
    this.timelineSkeletonName = '';
    this.timelineTypeOfTransportId = 0;
    this.timelineTypeOfTransportName = '';
    this.tm = null;
    this.totalPrice = 0;
    this.totalTaxes = 0;
    this.type = '';
    this.unconsolidatedNumber = 0;
    this.unconsolidatedType = '';
    this.unitCost = 0;
    this.unitPrice = 0;
    this.unitValue = 0;
    this.units = '';
    this.unitsQuantity = 0;
    this.updateAt = '';
    this.warehouse = '';
    this.weight = 0;
    this.width = 0;
    this.isPromotional = false;
    this.serials = [];
    this.originalPrice = 0;
    this.batch = '';
    this.batchExpiration = '';
  }
}
