import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { VarietyRepository } from '@/wms/domain/catalogs/xgeneral/variety/VarietyRepository';
import { Variety } from '@/wms/domain/catalogs/xgeneral/variety/Variety';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class VarietyService implements VarietyRepository {
  private readonly API_BASE_URI: string = localStorageModule().wms.host.API_BASE_URI;
  private readonly API_VARIETY_FINDALL: string =
    this.API_BASE_URI + localStorageModule().wms.routes.API_VARIETY_FINDALL;
  private readonly API_VARIETY_SAVE: string = this.API_BASE_URI + localStorageModule().wms.routes.API_VARIETY_SAVE;
  private readonly API_VARIETY_UPDATE: string = this.API_BASE_URI + localStorageModule().wms.routes.API_VARIETY_UPDATE;

  async create(variety: Variety) {
    try {
      await http.post<Variety[]>(this.API_VARIETY_SAVE, JSON.stringify(variety));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(variety: Variety) {
    try {
      await http.put<Variety[]>(this.API_VARIETY_UPDATE, JSON.stringify(variety));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAll(): Promise<Variety[]> {
    try {
      return await http.get<Variety[]>(this.API_VARIETY_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
