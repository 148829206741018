import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Timeline } from '@/tracking/domain/timeline/Timeline';
import { TimelineRepository } from '@/tracking/domain/timeline/TimelineRepository';

@Injectable()
export class TimelineFindAll extends Query<Promise<Timeline[]>> {
  public constructor(
    @Inject(TYPES.TIMELINE_REPOSITORY)
    private readonly timelineRepository: TimelineRepository,
    @Inject(TYPES.STATE_MANAGER) private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(): Promise<Timeline[]> {
    const dataTimeline = await this.timelineRepository.findAll();
    this.stateManager.patch({ dataTimeline });
    return this.stateManager.state.dataTimeline;
  }
}
