
import Vue from 'vue';
import Component from 'vue-class-component';
import i18n, { selectedLocale } from '@/core/plugins/i18n';
import Swal from 'sweetalert2';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { CreateOrder } from '@/courier/application/uses_cases/order/create/CreateOrder';
import { CompanyFindAll } from '@/settings/application/uses_cases/company/search/CompanyFindAll';
import { CustomerFindById } from '@/courier/application/uses_cases/customer/search/CustomerFindById';
import { CurrencyFactorFindRateByDate } from '@/settings/application/uses_cases/currencyfactor/search/CurrencyFactorFindRateByDate';
import { ValidationObserver } from 'vee-validate/dist/types/components';
import { StateFindByCountryCode } from '@/settings/application/uses_cases/state/search/StateFindByCountryCode';
import { ZoneFindByStatusAndCountryAndState } from '@/settings/application/uses_cases/zone/search/ZoneFindByStatusAndCountryAndState';
import { CountryFindAll } from '@/settings/application/uses_cases/country/search/CountryFindAll';
import { PriceListFindByOriginAndDestinationWeight } from '@/settings/application/uses_cases/priceList/search/weight/PriceListFindByOriginAndDestinationWeight';
import { PriceListFindByOriginAndDestination } from '@/settings/application/uses_cases/priceList/search/volume/PriceListFindByOriginAndDestination';
import { PurchaseScraping } from '@/courier/application/uses_cases/purchase/search/PurchaseScraping';
import { PalletsFactorFindAll } from '@/settings/application/uses_cases/palletsFactor/search/PalletsFactorFindAll';
import { CurrencyFindAll } from '@/settings/application/uses_cases/currency/search/CurrencyFindAll';
import { RouteFindById } from '@/settings/application/uses_cases/routes/search/RouteFindById';
import CalculateService from '@/courier/infrastructure/ui/components/calculateService/CalculateService.vue';
import AppCreateCustomer from '@/courier/infrastructure/ui/components/customer/CreateCustomer.vue';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';
import { Company } from '@/settings/domain/company/Company';
import { Territories } from '@/settings/domain/views/territories/Territories';
import { Currency } from '@/settings/domain/currency/Currency';
import { SearchTerritoriesByQueryParameter } from '@/settings/application/uses_cases/views/territories/SearchTerritoriesByQueryParameter';
import { PreEntity } from '@/settings/domain/pre_entity/PreEntity';
import { SearchByCountryStateAndCity } from '@/settings/application/uses_cases/zipCod/search/SearchCitiesByCountryAndStateActive';
import { EntityFindFilter } from '@/settings/application/uses_cases/entity/search/EntityFindFilter';
import { RateEngine } from '@/utils/RateEngine';

@Component({
  name: 'CreateOrder',
  components: { AppCreateCustomer, CalculateService },
  //Filters
  filters: {
    toCurrency(value: any) {
      if (typeof value !== 'number') {
        return value;
      }
      const language = selectedLocale;
      const formatter = new Intl.NumberFormat(`${language}`);
      return formatter.format(value);
    }
  }
})
export default class AppCreateOrder extends Vue {
  @Inject(TYPES.CREATE_ORDER)
  readonly createOrder!: CreateOrder;
  @Inject(TYPES.FINDBYID_CUSTOMER)
  readonly customerFindById!: CustomerFindById;
  @Inject(TYPES.FINDALL_PALLETSFACTOR)
  readonly palletsFactorFindAll!: PalletsFactorFindAll;
  @Inject(TYPES.FINDALL_COMPANY)
  readonly companyFindAll!: CompanyFindAll;
  @Inject(TYPES.FINDBYID_COMPANY)
  readonly companyFindById!: CompanyFindById;
  @Inject(TYPES.FINDRATEBYDATE_CURRENCY_FACTOR)
  readonly currencyFactorFindRateByDate!: CurrencyFactorFindRateByDate;
  @Inject(TYPES.FINDALL_COUNTRY)
  readonly countryFindAll!: CountryFindAll;
  @Inject(TYPES.FINDBYCOUNTRYCODE_STATE)
  readonly stateFindByCountryCode!: StateFindByCountryCode;
  @Inject(TYPES.FINDSTATUSCOUNTRYSTATE_ZONE)
  readonly zoneFindByStatusAndCountryAndState!: ZoneFindByStatusAndCountryAndState;
  @Inject(TYPES.FINDBY_ORIGINANDDESTINATION_WEIGHT)
  readonly priceListFindByOriginAndDestinationWeight!: PriceListFindByOriginAndDestinationWeight;
  @Inject(TYPES.FINDBY_ORIGINANDDESTINATION_VOLUME)
  readonly priceListFindByOriginAndDestination!: PriceListFindByOriginAndDestination;
  @Inject(TYPES.PURCHASE_SCRAPING)
  readonly purchaseScraping!: PurchaseScraping;
  @Inject(TYPES.FINDALL_CURRENCY)
  readonly currencyFindAll!: CurrencyFindAll;
  @Inject(TYPES.FINDBYID_ROUTES)
  readonly routeFindById!: RouteFindById;
  @Inject(TYPES.VIEW_FIND_TERRITORIES)
  readonly searchTerritoriesByQueryParameter!: SearchTerritoriesByQueryParameter;
  @Inject(TYPES.FINDALL_CURRENCY)
  readonly findAllCurrency!: CurrencyFindAll;
  @Inject(TYPES.ENTITY_FIND_BY_DESCRIPTION_LIKE)
  readonly searchCustomerByDescriptionLike!: EntityFindFilter;
  @Inject(TYPES.ZIPCODE_SEARCH_BY_COUNTRY_STATE_CITY)
  readonly searchByCountryStateAndCity!: SearchByCountryStateAndCity;
  //Data
  isLoading = false;
  fullPage = true;
  disableCurrency = true;
  totalWeight = 0;
  totalQuantity = 0;
  tWeight = 0;
  tQuantity = 0;
  tVolumen = 0;
  packagesTotal = 0;
  selectedLocales = selectedLocale;
  selected = '1';
  selectedRate = 0;
  isTouched = false;
  disabledQuotation = false;
  disabledQuotationDocuments = false;
  disabledQuotationPackages = false;
  checkedAdressee = false;
  provinceFrom = [];
  provinceTo = [];
  zonesFrom = [] as any;
  zonesTo = [] as any;
  valueRate = 0;
  palletsFactor = [];
  calculatedQuotation = null as any;
  isCalculate = false;
  optionPurchaseOrder = false;
  optionPurchaseOrderStore = true;
  quickViewProduct = [];
  urlImageAmazon = '';
  show = true;
  fieldDisabledAddress = true;
  companyLocalCurrency = '';
  unitofmeasurementPackages = 'lb';
  dataCompany: Company[] = [];
  palletsFactors = [];
  countries = [];
  currencies = [];
  orderRouteId = 0;
  form: any = {
    orderType: '',
    orderCustomerName: '',
    orderCustomerPhone: '',
    orderCustomerAddress: '',
    orderConditionPayment: 'cash',
    orderCurrency: '',
    orderDate: '',
    orderCustomerId: '',
    orderDestinationId: '',
    orderDestinationName: '',
    orderDestinationPhone: '',
    orderDestinationAddress: '',
    orderDestinationCountry: [],
    orderDestinationState: [],
    orderDestinationZone: [],
    orderCustomerCountry: [],
    orderCustomerState: [],
    orderCustomerZone: [],
    orderDestinationEmail: '',
    orderSuggestedPickupdate: '',
    orderSuggesTedtime: '',
    orderSubTotalProduct: 0,
    orderTotalTaxesProduct: 0,
    orderTotalProduct: 0,
    orderSubTotalProductConverted: 0,
    orderTaxesProductConverted: 0,
    orderTotalProductConverted: 0,
    orderSubTotalProductPackageConverted: 0,
    orderTotalTaxesProductPackageConverted: 0,
    orderTotalProductPackageConverted: 0,
    orderSubTotalProductPackage: 0,
    orderTotalTaxesProductPackage: 0,
    orderTotalProductPackage: 0,
    orderAddressDisabled: true,
    orderNotes: '',
    orderTaxesRegistry: [],
    origin: null,
    originZipCode: null,
    destination: null,
    destinationZipCode: null
  };
  dataRoute: any = {};
  optionsTime: any = [
    { value: '8:00am a 12:00pm', text: '8:00am a 12:00pm' },
    { value: '13:00pm a 17:00pm', text: '13:00pm a 17:00pm' }
  ];
  virtualShops: any = { name: 'AMAZON', code: '001' };
  origin: Territories[] = [];
  destination: Territories[] = [];
  currency: Currency[] = [];
  preEntities: PreEntity[] = [];
  ZipCodes: any[] = [];
  ZipCodesDestination: any[] = [];
  //Refs
  $refs!: {
    mdValidateTrmOrder: HTMLFormElement;
    formWizardOrder: HTMLFormElement;
    modalPackageOption: HTMLFormElement;
    orderCustomerCountry: HTMLFormElement;
    registerClient: HTMLFormElement;
    orderCustomerId: HTMLInputElement;
    orderDestinationAddress: HTMLInputElement;
    firstStep: InstanceType<typeof ValidationObserver>;
    calculateSrv: HTMLFormElement;
  };
  //Hook
  created() {
    this.$nextTick(this.getDataQuotation);
    this.$nextTick(() => this.getPalletsFactorsAll);
    this.$nextTick(() => this.getCountryAll);
    this.$nextTick(() => this.getCurrencies);
    this.$nextTick(this.searchAllCurrency);
    // this.findByIdClient(localStorage.getItem('customerId') as string);
  }
  async mounted() {
    //TODO: Colocar validacion de la moneda al momento de seleccionar una moneda diferente en la transaccion
    const companyId = localStorage.getItem('businessId') as string;
    await this.companyFindById.execute(companyId).then((response: any) => {
      this.dataCompany.push(response);
      this.companyLocalCurrency = response['localCurrency'];
      this.form.currency = {
        currencyCode: this.companyLocalCurrency
      };
      if (response['multiCurrency']) {
        this.disableCurrency = false;
        this.$nextTick(() => this.getCurrencyFactorDate());
      }
    });
  }
  //Computed
  get fields() {
    return [
      { key: 'key', label: this.translateCol('description'), sortable: true },
      { key: 'value', label: this.translateCol('detail'), sortable: true }
    ];
  }
  get isDisabled() {
    return this.form.orderAddressDisabled;
  }
  get state() {
    return Boolean(this.selectedRate);
  }
  get getPalletsFactorsAll() {
    this.palletsFactorFindAll.execute().then((response: any) => {
      this.palletsFactors = response;
    });
    return true;
  }
  get getCountryAll() {
    this.countryFindAll.execute().then((response: any) => {
      this.countries = response;
    });
    return true;
  }
  get getCurrencies() {
    this.currencyFindAll.execute().then((response: any) => {
      this.currencies = response;
    });
    return true;
  }
  //Methods
  searchAllCurrency() {
    this.findAllCurrency
      .execute()
      .then(response => {
        this.currency = response;
      })
      .catch(error => {
        throw new Error(`${error}`);
      });
  }

  findCustomerByDescriptioinLike(query: string) {
    if (query.length >= 3) {
      this.isLoading = true;
      this.searchCustomerByDescriptionLike
        .execute({
          text: query.toUpperCase(),
          type: ['CUSTOMER']
        })
        .then((response: any) => {
          this.isLoading = false;
          this.preEntities = response;
        })
        .catch(error => {
          this.isLoading = false;
          this.preEntities = [];
          throw new Error(`${error}`);
        });
    }
  }

  findTerritriesFromViewDest(query: string) {
    if (query.length >= 3) {
      this.isLoading = true;
      this.searchTerritoriesByQueryParameter
        .execute(query.toUpperCase())
        .then((response: any) => {
          this.isLoading = false;
          this.destination = response;
        })
        .catch(error => {
          this.isLoading = false;
          this.destination = [];
          throw new Error(`${error}`);
        });
    }
  }

  findTerritriesFromView(query: string) {
    if (query.length >= 3) {
      this.isLoading = true;
      this.searchTerritoriesByQueryParameter
        .execute(query.toUpperCase())
        .then((response: any) => {
          this.isLoading = false;
          this.origin = response;
        })
        .catch(error => {
          this.isLoading = false;
          this.origin = [];
          throw new Error(`${error}`);
        });
    }
  }
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  translateCol(colName: any) {
    return this.$i18n.t('general.' + colName);
  }

  defaultEndDateMonth(month: any) {
    if (Number(month) < 10) {
      return '0' + month;
    } else {
      return month;
    }
  }
  defaultEndDateDay(day: any) {
    if (Number(day) < 10) {
      return '0' + day;
    } else {
      return day;
    }
  }
  //evento de guardado de la orden
  shippingRegister() {
    //recuperar objetoData del componente de calculo,
    // se envia el parm en null para que este retorne el object que recibio al inicializar el componente.
    if (this.disabledQuotation) {
      this.$refs.calculateSrv.factorSelectedCall(null);
    }

    const totalTax =
      this.calculatedQuotation.taxes.length > 0
        ? this.calculatedQuotation.taxes.reduce((tax1: any, tax2: any) => tax1.taxValue + tax2.taxValue)
        : 0;
    this.form.orderType = this.calculatedQuotation.optionPurchaseOrder ? 'PR' : 'CT';
    const arrayAux: any = [];
    const timeline = this.calculatedQuotation.timeLineId;
    this.calculatedQuotation.arrayDocument.map((item: any) => {
      arrayAux.push({
        ...item,
        timelineSkeletonId: timeline
      });
    });
    const payload: any = {
      customerId: this.form.orderCustomer.id.trim(),
      customerName: this.form.orderCustomerName.trim(),
      typeOrder: this.form.orderType,
      condition: this.form.orderConditionPayment,
      currency: this.form.orderCurrency,
      addressOriginCountry: this.form.origin.country,
      addressOriginState: this.form.origin.state,
      addressOriginCity: this.form.origin.city,
      addressOriginZipCode: this.form.originZipCode.zoneZc,
      addressOriginLine: this.form.orderCustomerAddress.trim(),
      destination: {
        customerId: this.form.orderDestinationId.trim(),
        fullName: this.form.orderDestinationName.trim(),
        email: this.form.orderDestinationEmail.trim(),
        phone1: this.form.orderDestinationPhone.trim(),
        addressLine: {
          country: this.form.destination.country,
          description: this.form.orderDestinationAddress.trim(),
          state: this.form.destination.state,
          city: this.form.destination.city,
          zipCode: this.form.destinationZipCode.zoneZc,
          warehouse: '000'
        }
      },
      lines: arrayAux,
      number: 0,
      deliveryDateTime: this.form.orderSuggestedPickupdate,
      dateTime: this.form.orderSuggesTedtime,
      weight: this.calculatedQuotation.unitWeight.id,
      width: 0,
      lengthy: 0,
      height: 0,
      approved: true,
      canceled: false,
      checked: false,
      date: this.form.orderDate,
      discount: '',
      document: '',
      notes: this.form.orderNotes,
      warehouse: '000',
      sw: 1,
      routeId: this.calculatedQuotation.routeId,
      tax: totalTax,
      total: this.calculatedQuotation.rateValue,
      conversionRate: this.calculatedQuotation.rateValue,
      id: 0,
      module: 'courier'
    };
    this.createOrder.execute(payload);
    this.clearForm();
  }
  getCurrencyFactorDate() {
    if (this.companyLocalCurrency === this.form.orderCurrency) {
      this.valueRate = 1;
    } else {
      const date = new Date();
      const isoDateTime = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
      const payload: any = {
        currency: this.companyLocalCurrency,
        date: isoDateTime.substring(0, 10)
      };
      this.currencyFactorFindRateByDate
        .execute(payload)
        .then((response: any) => {
          this.valueRate = response['rate'];
        })
        .catch((err: any) => {
          return err;
        })
        .finally(() => {
          if (this.valueRate > 0) {
            this.$nextTick(() => this.$refs['mdValidateTrmOrder'].hide());
          } else {
            this.$nextTick(() => this.$refs['mdValidateTrmOrder'].show());
          }
        });
    }
  }

  selectedCustomer(customer: PreEntity) {
    this.form.orderCustomerAddress = customer.address != null ? customer.address.description : '';
    this.form.orderDestinationAddress = this.form.orderCustomerAddress;
    this.form.orderCustomerPhone = customer.phone1;
    this.fieldDisabledAddress = false;
  }

  findByIdClient(id: string) {
    this.customerFindById
      .execute(id)
      .then((response: any) => {
        if (response.code >= 400 && response.code <= 499) {
          Swal.fire({
            title: this.$i18n.t('general.theclientisnotregistered'),
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `${i18n.t('general.register')}`,
            cancelButtonText: `${i18n.t('general.cancel')}`,
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
          }).then(result => {
            if (result.isConfirmed) {
              this.$refs['registerClient'].show();
            } else {
              this.form.orderCustomer = null;
              return false;
            }
          });
        } else {
          this.form.orderCustomerId = response['id'];
          this.form.orderCustomerName = response['fullName'];
          this.form.orderCustomerPhone = response['phone1'];
          this.form.orderCustomerAddress = response['addressLine']['description'];
          this.form.orderCurrency = response['addressLine']['countryCurrency'];
          this.fieldDisabledAddress = false;
          if (this.calculatedQuotation === null) {
            this.disabledQuotation = false;
            this.$nextTick(() => this.$refs.orderCustomerCountry.$el.focus());
          } else {
            this.$nextTick(() => this.$refs.orderDestinationAddress.focus());
          }
        }
        return response;
      })
      .catch(() => {
        this.form.orderCustomerId = '';
        return;
      });
  }

  async validateStepFirst() {
    const rateEngine = new RateEngine();
    this.dataRoute = {
      countryOrigin: this.form.origin.country,
      stateOrigin: this.form.origin.state,
      originCity: this.form.origin.city,
      zoneZcOrigin: this.form.originZipCode.zoneZc,
      countryDestination: this.form.destination.country,
      stateDestination: this.form.destination.state,
      destCity: this.form.destination.city,
      zoneZcDestination: this.form.destinationZipCode.zoneZc
    };
    return (await this.$refs.firstStep.validate()) && (await rateEngine.validatePriceList(this.dataRoute));
  }
  clearForm() {
    const date = new Date();
    const isoDateTime = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    this.form.orderTotalDiscount = '';
    this.form.orderDate = isoDateTime;
    this.form.orderDestinationId = '';
    this.form.orderDestinationName = '';
    this.form.orderDestinationPhone = '';
    this.form.orderDestinationAddress = '';
    this.form.orderDestinationCountry = [];
    this.form.orderDestinationState = [];
    this.form.orderDestinationZone = [];
    this.form.orderCustomerCountry = [];
    this.form.orderCustomerState = [];
    this.form.orderCustomerZone = [];
    this.form.orderCustomerId = '';
    this.form.orderCustomerName = '';
    this.form.orderCustomerAddress = '';
    this.form.orderCustomerPhone = '';
    this.palletsFactor = [];
    this.form.orderDestinationEmail = '';
    this.disabledQuotation = false;
    this.disabledQuotationDocuments = false;
    this.disabledQuotationPackages = false;
    this.calculatedQuotation = null as any;
    this.selectedRate = 0;
    this.form.orderSuggestedPickupdate =
      new Date().getFullYear() +
      '-' +
      this.defaultEndDateMonth(new Date().getMonth() + 1) +
      '-' +
      this.defaultEndDateDay(new Date().getDate());
    this.form.orderSuggesTedtime = '';
    this.form.orderNotes = '';
    this.form.orderAddressDisabled = true;
    this.selected = '1';
    this.$nextTick(() => this.$refs.firstStep.reset());
    this.$refs.formWizardOrder.reset();
    localStorage.removeItem('cotizacion');
  }

  //metodos nuevos
  routeFindByIdCall(routeId: any) {
    this.routeFindById
      .execute(routeId)
      .then((response: any) => {
        this.setRouteForm(response);
      })
      .catch((err: any) => {
        return err;
      });
  }
  setRouteForm(route: any) {
    this.form.orderCustomerCountry = {
      name: route.countryOriginName,
      code: route.countryOrigin
    };
    this.form.orderDestinationCountry = {
      name: route.countryDestinationName,
      code: route.countryDestination
    };
    this.form.orderCustomerState = {
      name: route.stateOriginName,
      code: route.stateOrigin
    };
    this.form.orderDestinationState = {
      name: route.stateDestinationName,
      code: route.stateDestination
    };

    this.form.orderCustomerZone = {
      description: route.zoneZcOriginDescription,
      zoneZc: route.zoneZcOrigin
    };

    this.form.orderDestinationZone = {
      description: route.zoneZcDestinationDescription,
      zoneZc: route.zoneZcDestination
    };
  }
  //INICIO DEL REFACTOR PRICING
  executePricingCall() {
    //params
    if (!this.calculatedQuotation) {
      this.$refs.calculateSrv.calculatePricing(this.dataRoute);
    }
    return this.calculatedQuotation !== null;
  }
  getDataQuotation() {
    this.calculatedQuotation = JSON.parse(localStorage.getItem('cotizacion') as any);
    this.$refs.calculateSrv.startWithExternalData(this.calculatedQuotation);

    if (this.calculatedQuotation) {
      this.routeFindByIdCall(this.calculatedQuotation.routeId);
      this.disabledQuotation = true;
      this.$nextTick(() => this.$refs.orderCustomerId.focus());
    }

    const date = new Date();
    this.form.orderDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    this.form.orderSuggestedPickupdate =
      new Date().getFullYear() +
      '-' +
      this.defaultEndDateMonth(new Date().getMonth() + 1) +
      '-' +
      this.defaultEndDateDay(new Date().getDate());
  }
  pricingFactorSelected(objFactor: any) {
    this.calculatedQuotation = objFactor;
    if (this.calculatedQuotation) {
      this.$refs.formWizardOrder.nextTab();
    }
  }
  findZipCodeOrigin(territory: any) {
    const payload = {
      country: territory.country,
      state: territory.state,
      city: territory.city
    };
    this.searchByCountryStateAndCity
      .execute(payload)
      .then((response: any) => {
        this.ZipCodes = response;
      })
      .catch((error: any) => {
        throw new Error(`${error}`);
      });
  }
  findZipCodeDestination(territory: any) {
    const payload = {
      country: territory.country,
      state: territory.state,
      city: territory.city
    };
    this.searchByCountryStateAndCity
      .execute(payload)
      .then((response: any) => {
        this.ZipCodesDestination = response;
      })
      .catch((error: any) => {
        throw new Error(`${error}`);
      });
  }
}
