import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Packaging } from '@/wms/domain/catalogs/packaging/Packaging';
import { PackagingRepository } from '@/wms/domain/catalogs/packaging/PackagingRepository';

@Injectable()
export class DeletePackaging extends Command<Promise<any>, Packaging> {
  public constructor(
    @Inject(TYPES.PACKAGING_REFERENCE_REPOSITORY)
    private readonly packagingRepository: PackagingRepository
  ) {
    super();
  }

  internalExecute(packaging: Packaging): Promise<any> {
    return this.packagingRepository.delete(packaging);
  }
}
