import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Injectable } from '@/core/di/Injectable';
import { MenuRepository } from '@/settings/domain/menu/MenuRepository';
import { Menu } from '@/settings/domain/menu/menu';
import { Inject } from 'inversify-props';

@Injectable()
export class FindMenuByRole extends Query<Promise<Menu[]>, { companyId: string; roleId: number; type: string }> {
  public constructor(
    @Inject(TYPES.MENU_REPOSITORY)
    private readonly repository: MenuRepository
  ) {
    super();
  }

  internalExecute(payload: { companyId: string; roleId: number; type: string }): Promise<Menu[]> {
    return this.repository.findByRole(payload);
  }
}
