import { Injectable } from '@/core/di/Injectable';
import { Query } from '@/core/cqrs/Query';
import Airport from '@/settings/domain/airport/Airport';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { AirportRepository } from '@/settings/domain/airport/AirportRepository';

@Injectable()
export class FindByCodeAirport extends Query<Promise<Airport>, string> {
  public constructor(
    @Inject(TYPES.AIRPORT_REPOSITORY)
    private readonly airportRepository: AirportRepository
  ) {
    super();
  }

  async internalExecute(code: string): Promise<Airport> {
    return await this.airportRepository.findByCode(code);
  }
}
