
import Vue from 'vue';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import draggable from 'vuedraggable';
import { ContainerInspection } from '@/wms/domain/containerInspection/ContainerInspection';
import { ProcessFindAll } from '@/tracking/application/uses_cases/processtype/search/ProcessFindAll';
import { Process } from '@/tracking/domain/processtype/Process';
@Component({
  name: 'Characteristics',
  components: { draggable }
})
export default class Characteristics extends Vue {
  @Inject(TYPES.FINDALL_PROCESS)
  readonly findProcess!: ProcessFindAll;

  //Props
  @Prop() readonly reference!: any;

  //Data
  newCheckContainerInspection: ContainerInspection = new ContainerInspection();
  isLoading = false;

  //Listas
  processList: Process[] = [];
  cargoTypeList = ['container', 'bulk', 'pallet'];

  //Getter para obtener la lista de opciones de transporte
  get transportMethods() {
    return ['ocean', 'ground', 'air'];
  }

  //Funcion invocada cuando el componente se monta al DOM
  mounted() {
    this.getAllProcess();
  }

  //Funcion invocada para agregar items a la lista de inspeccion de container
  addCheckToContainerInspectionList() {
    !this.reference.containerInspectionList ? (this.reference.containerInspectionList = []) : null;
    this.reference.containerInspectionList.push(this.newCheckContainerInspection);
    this.clearContainerInspectionForm();
  }

  //Funcion invocada para remover items de la lista de inspeccion de container
  removeCheckToContainerInspectionList(element: any, index: number) {
    this.reference.containerInspectionList.splice(index, 1);
  }

  //Funcion invocada para limpiar el formulario de container inspection
  clearContainerInspectionForm() {
    this.newCheckContainerInspection = new ContainerInspection();
  }

  //Funcion invocada para obtener todos los tipos de procesos IMPORT, EXPORT, LOCAL...
  async getAllProcess() {
    try {
      this.isLoading = true;
      const res = await this.findProcess.execute();
      this.processList = res.length > 0 ? res.reverse() : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  handdleChange(e: boolean, input: string) {
    switch (input) {
      case 'additional':
        this.reference.isMainService = false;
        this.reference.forCategories = false;
        break;
      case 'main':
        this.reference.showOnQuotation = false;
        this.reference.forCategories = false;
        break;

      default:
        this.reference.isMainService = false;
        this.reference.showOnQuotation = false;
        break;
    }
  }
}
