
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Prop } from 'vue-property-decorator';
import { ButtonOptions } from '@/settings/domain/buttonOptions/ButtonOptions';
import { CountryFindByDescriptionLikeInGeneralTerritories } from '@/settings/application/uses_cases/country/search/CountryFindByDescriptionLikeInGeneralTerritories';
import { Country } from '@/settings/domain/country/Country';

interface FormCurrency {
  currencyCode: string;
  description: string;
  date: number;
  country: Country | null;
  rate: number;
}

@Component({
  name: 'RegisterCurrency'
})
export default class RegisterCurrency extends Vue {
  @Inject(TYPES.COUNTRY_FIND_BY_DESCRIPTION_IN_GENERAL_TERRITORIES)
  readonly findCountryInGeneralTerritories!: CountryFindByDescriptionLikeInGeneralTerritories;

  @Prop({ required: true }) form!: FormCurrency;
  @Prop({ required: true }) buttonOptions!: ButtonOptions;
  @Prop({ required: true }) actions!: undefined;

  //DATA
  isLoading = false;
  fullPage = true;
  isSave = true;
  dateNow: any = new Date();

  //Listas
  countryList: Country[] = [];

  timeoutFunction!: any;

  get isDisable() {
    return this.buttonOptions.isEdit;
  }

  async getCountry(input: string) {
    try {
      this.isLoading = true;
      const res = await this.findCountryInGeneralTerritories.execute(input);
      this.countryList = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  timeoutExecutionOfGetCountry(input: string) {
    clearTimeout(this.timeoutFunction);
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    this.timeoutFunction = setTimeout(async () => {
      input.length > 2 && (await this.getCountry(input));
      clearTimeout(this.timeoutFunction);
    }, 600);
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
}
