import { Entity } from '../entity/entity/Entity';

export class Warehouse {
  public address: string;
  public code: string;
  public codeAux: string;
  public createAt: string;
  public description: string;
  public status: boolean;
  public updateAt: string;
  public virtualLocker: boolean;
  public ftz: string;
  public bonded: string;
  public prefix: string;
  public consecutive: string;
  public entity: Entity | null;

  public constructor() {
    this.address = '';
    this.code = '';
    this.codeAux = '';
    this.createAt = '';
    this.description = '';
    this.status = true;
    this.updateAt = '';
    this.virtualLocker = false;
    this.ftz = '';
    this.bonded = '';
    this.prefix = '';
    this.consecutive = '';
    this.entity = null;
  }
}
