import { Containers } from '../Containers';
import { PackingUnits } from '../../packing_units/PackingUnits';

export class ConceptsContainer {
  conceptCode: string;
  conceptType: string;
  conceptDescription: string;
  inspectionDescription: string;
  evaluationMethod: string;
  listValues: string[];
  listConceptual: string[];
  evaluation: string | null;
  sequence: number;
  isRequired: boolean;
  container: Containers | null;
  valuation: string | null;
  valuationNotes: string;
  packingUnits: PackingUnits | null;
  isFreight: boolean;
  photos: string[];

  public constructor() {
    this.conceptCode = '';
    this.conceptType = '';
    this.conceptDescription = '';
    this.inspectionDescription = '';
    this.evaluationMethod = '';
    this.listValues = [];
    this.listConceptual = [];
    this.evaluation = null;
    this.sequence = 0;
    this.isRequired = false;
    this.container = null;
    this.valuation = null;
    this.valuationNotes = '';
    this.packingUnits = null;
    this.isFreight = false;
    this.photos = [];
  }
}
