/* eslint-disable @typescript-eslint/camelcase */
export class OrderbyFilter {
  serial: string;
  type: string;
  number: string;
  dateOrder: string;
  idRoute: number;
  countryOrigin: string;
  countryOrigin_name: string;
  stateOrigin: string;
  stateOrigin_name: string;
  cityOrigin: string;
  cityOrigin_name: string;
  zipCodeOrigin: string;
  zipCodeOriginName: string;
  countryDestination: string;
  countryDestination_name: string;
  stateDestination: string;
  stateDestination_name: string;
  cityDestination: string;
  cityDestination_name: string;
  zipCodeDestination: string;
  zipCodeDestinationName: string;
  C: string;
  sw: string;
  event: number;
  timeline: number;
  unconsolidatedType: string;
  unconsolidatedNumber: string;
  consolidatedNumber: string;
  consolidatedType: string;
  activityId: number;
  public constructor() {
    this.serial = '';
    this.type = '';
    this.number = '';
    this.dateOrder = '';
    this.idRoute = 0;
    this.countryOrigin = '';
    this.countryOrigin_name = '';
    this.stateOrigin = '';
    this.stateOrigin_name = '';
    this.cityOrigin = '';
    this.cityOrigin_name = '';
    this.zipCodeOrigin = '';
    this.zipCodeOriginName = '';
    this.countryDestination = '';
    this.countryDestination_name = '';
    this.stateDestination = '';
    this.stateDestination_name = '';
    this.cityDestination = '';
    this.cityDestination_name = '';
    this.zipCodeDestination = '';
    this.zipCodeDestinationName = '';
    this.C = '';
    this.sw = '';
    this.unconsolidatedType = '';
    this.unconsolidatedNumber = '';
    this.consolidatedNumber = '';
    this.consolidatedType = '';
    this.event = 0;
    this.timeline = 0;
    this.activityId = 0;
  }
}
