import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { AlternativeReferences } from '@/wms/domain/catalogs/alternativeReferences/AlternativeReferences';
import { AlternativeReferencesRepository } from '@/wms/domain/catalogs/alternativeReferences/AlternativeReferencesRepository';

@Injectable()
export class FindByReferenceAlternativeReferences extends Query<Promise<AlternativeReferences[]>, string> {
  public constructor(
    @Inject(TYPES.ALTERNATIVE_REFERENCE_REPOSITORY)
    private readonly alternativeReferencesRepository: AlternativeReferencesRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(code: string): Promise<AlternativeReferences[]> {
    const dataAlternativeReferences = await this.alternativeReferencesRepository.findByReference(code);
    this.stateManager.patch({ dataAlternativeReferences });
    return this.stateManager.state.dataAlternativeReferences;
  }
}
