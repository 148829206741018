import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { GroupReferences } from '@/wms/domain/category/groupReferences/GroupReferences';
import { GroupReferencesRepository } from '@/wms/domain/category/groupReferences/GroupReferencesRepository';

@Injectable()
export class GroupReferenceFindAllTree extends Query<Promise<GroupReferences[]>> {
  public constructor(
    @Inject(TYPES.GROUP_REFERENCE_REPOSITORY)
    private readonly groupReferencesRepository: GroupReferencesRepository,

    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(): Promise<GroupReferences[]> {
    const dataGroupReferences = await this.groupReferencesRepository.findAllTree();
    this.stateManager.patch({ dataGroupReferences });
    return this.stateManager.state.dataGroupReferences;
  }
}
