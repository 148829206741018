
import Component from 'vue-class-component';
import Vue from 'vue';
import { selectedLocale } from '@/core/plugins/i18n';
import { ValidationObserver } from 'vee-validate';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { SearchTerritoriesByQueryParameter } from '@/settings/application/uses_cases/views/territories/SearchTerritoriesByQueryParameter';
import { Territories } from '@/settings/domain/views/territories/Territories';
import { SearchByCountryStateAndCity } from '@/settings/application/uses_cases/zipCod/search/SearchCitiesByCountryAndStateActive';
import { CurrencyFindAll } from '@/settings/application/uses_cases/currency/search/CurrencyFindAll';
import { Currency } from '@/settings/domain/currency/Currency';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';
import { Company } from '@/settings/domain/company/Company';
import CalculateServiceN from '@/courier/infrastructure/ui/components/calculateService/CalculateServiceN.vue';
import { CurrencyFactorFindRateByDate } from '@/settings/application/uses_cases/currencyfactor/search/CurrencyFactorFindRateByDate';
import { Prop } from 'vue-property-decorator';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import { FindCommodityTypeByStatus } from '@/freight/application/commodity_type/search/FindCommodityTypesByStatus';
import { StateManager } from '@/core/statemanager/StateManager';
import { PriceList } from '@/settings/domain/priceList/PriceList';
import { VolumeFindAll } from '@/settings/application/uses_cases/volume/search/VolumeFindAll';
import { WeightFindAll } from '@/settings/application/uses_cases/weight/search/WeightFindAll';
import PricelistOrder from '../pricelistOrder/PricelistOrder.vue';
import { EntityFindFilter } from '@/settings/application/uses_cases/entity/search/EntityFindFilter';
import { ZipCode } from '@/settings/domain/ZipCode/ZipCode';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import SearchTerritories from '@/core/components/shared/SearchTerritories.vue';
import { SearchTerritoryByQueryDescription } from '@/settings/application/uses_cases/views/territories/SearchTerritoryByQueryDescription';
import { PricingZoneFindByOriginAndDestination } from '@/settings/application/uses_cases/PricingZone/search/PricingZoneFindByOriginAndDestination';
import { RequestPricelistPayload } from '@/core/helper/RequestPricelistPayload';
import { PreRegisterSave } from '@/wms/application/preRegister/create/PreRegisterSave';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import GeneralInformationTab from './components/GeneralInformationTab.vue';
import { PreRegisterTypeEnum } from '@/wms/domain/preRegister/PreRegisterTypeEnum';
import { Bulk } from '@/wms/domain/bulk/Bulk';
import { DateISOStringGenerator } from '@/core/helper/DateISOStringGenerator';
import { PreRegisterStatusEnum } from '@/wms/domain/preRegister/PreRegisterStatusEnum';
import { References } from '@/wms/domain/catalogs/references/References';
@Component({
  name: 'CreateOrderN',
  components: { CalculateServiceN, PricelistOrder, SearchTerritories, GeneralInformationTab }
})
export default class CreateOrderN extends Vue {
  //TYPES
  @Inject(TYPES.ENTITY_FIND_BY_DESCRIPTION_LIKE)
  readonly searchCustomerByDescriptionLike!: EntityFindFilter;
  @Inject(TYPES.VIEW_FIND_TERRITORIES)
  readonly searchTerritoriesByQueryParameter!: SearchTerritoriesByQueryParameter;
  @Inject(TYPES.API_VIEW_FIND_TERRITORIES_BY_QUERY)
  readonly searchInAllTerritories!: SearchTerritoryByQueryDescription;
  @Inject(TYPES.ZIPCODE_SEARCH_BY_COUNTRY_STATE_CITY)
  readonly searchByCountryStateAndCity!: SearchByCountryStateAndCity;
  @Inject(TYPES.FINDALL_CURRENCY)
  readonly findAllCurrency!: CurrencyFindAll;
  @Inject(TYPES.FINDBYID_COMPANY)
  readonly companyFindById!: CompanyFindById;
  @Inject(TYPES.FINDRATEBYDATE_CURRENCY_FACTOR)
  readonly currencyFactorFindRateByDate!: CurrencyFactorFindRateByDate;
  @Inject(TYPES.PRE_REGISTER_SAVE)
  readonly createOrder!: PreRegisterSave;
  @Inject(TYPES.COMMODITY_TYPE_FIND_BY_STATUS)
  readonly findActiveCommodity!: FindCommodityTypeByStatus;
  @Inject(TYPES.STATE_MANAGER)
  readonly stateManager!: StateManager;
  @Inject(TYPES.FINDALL_VOLUME)
  readonly findAllVolumeUnits!: VolumeFindAll;
  @Inject(TYPES.FINDALL_WEIGHT)
  readonly findAllWeightUnits!: WeightFindAll;
  @Inject(TYPES.PRICING_ZONE_FIND_BY_ORIGIN_AND_DESTINATION)
  readonly findPricelist!: PricingZoneFindByOriginAndDestination;
  @Inject(TYPES.PRE_REGISTER_SAVE)
  readonly savePreregister!: PreRegisterSave;

  //FORM
  form: PreRegister = new PreRegister(PreRegisterTypeEnum.COURIER_ORDER);
  selectedTimelineAndPricelist: any = null; //Esto hay que tiparlo
  //SELECTS
  selectedOrigin: Territories | null = null;
  selectedOriginZipCode: ZipCode | null = null;
  selectedCustomer: Entity | null = new Entity();
  selectedCurrency: Currency | null = null;
  selectedDestination: Territories | null = null;
  //LISTS
  origin: Territories[] = [];
  preEntities = [] as any;
  destination: Territories[] = [];
  zipCodesOrigin = [] as any;
  zipCodesDestination = [] as any;
  currencies: Currency[] = [];
  dataCompany: Company[] = [];
  calculated = [] as any;
  //OTHER DATA
  companyLocalCurrency = '';
  calculatedQuotation = null as any;
  dataRoute: any;
  selectedLocales = selectedLocale;
  pricingLevel = '';
  optionsTime: { value: string; text: string }[] = [
    { value: '8:00am a 12:00pm', text: '8:00am a 12:00pm' },
    { value: '13:00pm a 17:00pm', text: '13:00pm a 17:00pm' }
  ];
  //PROPS
  @Prop()
  readonly orderType!: number;
  //BOOLEAN DATA
  checkedAdressee = false;
  disableCurrency = false;
  isLoading = false;
  //REFERENCIAS
  $refs!: {
    mdValidateTrmOrder: HTMLFormElement;
    formWizardOrder: HTMLFormElement;
    modalPackageOption: HTMLFormElement;
    orderCustomerCountry: HTMLFormElement;
    registerClient: HTMLFormElement;
    orderCustomerId: HTMLInputElement;
    orderDestinationAddress: HTMLInputElement;
    firstStep: InstanceType<typeof ValidationObserver>;
    calculateSrv: CalculateServiceN;
    pricelistOrder: PricelistOrder;
  };

  //INICIO
  async mounted() {
    await this.fetchRequiredData();
    this.clearForm();
  }

  //SEARCH INTERVAL VARIABLES
  timeout: any;
  searchInterval = 600;
  //METHODS

  updateData(newData: any) {
    this.calculated = newData;
  }

  async fetchRequiredData() {
    await this.searchAllCurrency();
    this.getCurrencyData();
    this.weightFindAllList();
    this.volumeFindAllList();
  }

  async validateStepOne() {
    try {
      const payload: RequestPricelistPayload = {
        origin: this.form.address.territory,
        destination: this.form.addressDestination.territory,
        level: this.pricingLevel
      };

      const validate = await this.$refs.firstStep.validate();
      if (!validate) {
        this.$swal({ title: `${this.$t('general.fillAllFields')}`, icon: 'warning' });
        return false;
      }

      const res = await this.findPricelist.execute(payload);
      const invalidRes = res.volume.length < 1 && res.weight.length < 1;
      if (invalidRes && this.orderType != 3) {
        this.$swal(`${this.$t('general.noPricelist')}`, ``, 'warning');
        return false;
      }

      this.stateManager.state.dataPriceList = [];
      this.stateManager.state.dataPriceList.push(res.volume, res.weight);
      this.stateManager.state.dataPriceList = this.stateManager.state.dataPriceList.reduce(
        (acc, item) => acc.concat(item),
        []
      );

      await this.getCommodityActive();

      if (this.orderType != 3) {
        const volumeListCheck = this.stateManager.state.dataPriceList.map(item => item.volumeUnit);
        const weightListCheck = this.stateManager.state.dataPriceList.map(item => item.weightUnit);
        const commodityListCheck = this.stateManager.state.dataPriceList.map(item => item.commodity?.id);

        this.stateManager.state.dataVolume = this.stateManager.state.dataVolume.filter((item: any) =>
          volumeListCheck.includes(item.id)
        );
        this.stateManager.state.dataWeight = this.stateManager.state.dataWeight.filter((item: any) =>
          weightListCheck.includes(item.id)
        );
        this.stateManager.state.dataCommodityTypes = this.stateManager.state.dataCommodityTypes.filter(item =>
          commodityListCheck.includes(item.id)
        );
      }
      this.form.routeId =
        this.stateManager.state.dataPriceList.length > 0 ? this.stateManager.state.dataPriceList[0].routeId : 0;

      this.$toasted.show(`${this.$t('general.check_success')}`);
      return true;
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async executePricingCall() {
    try {
      if (this.form.bulks.length < 1) {
        this.$swal(`${this.$t('general.warning')}`, `${this.$t('general.errorPackagesNumber')}`, 'warning');
        return false;
      }

      this.calculated = [];
      this.selectedTimelineAndPricelist = null;

      return await this.$refs.calculateSrv.calculatePricing({
        levelOfPricing: this.pricingLevel
      });
    } catch (error) {
      return false;
      // throw new Error(`${error}`);
    }
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  translateCol(colName: any) {
    return this.$i18n.t('general.' + colName);
  }

  async searchAllCurrency() {
    await this.findAllCurrency
      .execute()
      .then(response => {
        this.currencies = response;
      })
      .catch(error => {
        throw new Error(`${error}`);
      });
  }

  async getCurrencyData() {
    const companyId = localStorage.getItem('businessId') as string;
    const res: any = await this.companyFindById.execute(companyId);
    this.dataCompany.push(res);
    this.companyLocalCurrency = res.localCurrency;
    this.form.currency = this.currencies.filter((item: Currency) => {
      return this.companyLocalCurrency == item.currencyCode;
    })[0];
    this.disableCurrency = !res.multiCurrency;
    this.pricingLevel = res.zonePricingLevel;
    if (res.multiCurrency) {
      this.$nextTick(() => this.getCurrencyFactorDate());
    }
  }

  pricingFactorSelected(objFactor: any) {
    this.calculatedQuotation = objFactor;
    if (this.calculatedQuotation) {
      this.$refs.formWizardOrder.nextTab();
    }
  }

  getCurrencyFactorDate() {
    if (this.selectedCurrency?.currencyCode !== this.companyLocalCurrency) {
      const date = new Date();
      const isoDateTime = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
      const payload: any = {
        currency: this.companyLocalCurrency,
        date: isoDateTime.substring(0, 10)
      };
      this.currencyFactorFindRateByDate
        .execute(payload)
        .then((response: any) => {
          this.form.rateValue = response['rate'];
        })
        .catch((err: any) => {
          return err;
        });
      // .finally(() => {
      //   if (this.form.rateValue > 0) {
      //     this.$nextTick(() => this.$refs['mdValidateTrmOrder'].hide());
      //   } else {
      //     this.$nextTick(() => this.$refs['mdValidateTrmOrder'].show());
      //   }
      // });
    }
  }

  //formatea fecha a doble digito

  formatToTwoDigits(num: Date | number) {
    return num.toString().padStart(2, '0');
  }
  formatDate(year: Date | number, month: Date | number, day: Date | number) {
    return `${year}-${this.formatToTwoDigits(month)}-${this.formatToTwoDigits(day)}`;
  }

  //evento de guardado de la orden
  async shippingRegister() {
    if (this.form.bulks.length < 1) {
      this.$swal(`${this.$t('general.warning')}`, `${this.$t('general.errorPackagesNumber')}`, 'warning');
      return false;
    }

    const payload = Object.assign({}, this.form);
    payload.subTotal = this.selectedTimelineAndPricelist?.response.subTotal ?? 0;
    payload.taxesValue = this.selectedTimelineAndPricelist?.response.rateValue ?? 0;
    payload.timeline = this.selectedTimelineAndPricelist?.timeline;
    if (typeof this.form.condition !== 'string') payload.condition = this.form.condition?.name;
    if (payload.timeline) {
      if (payload.timeline.typeTransport) {
        payload.timeline.typeTransport = {
          id: Number(payload.timeline.typeTransport),
          active: true,
          name: ''
        };
      }

      if (payload.timeline.typeOperation) {
        payload.timeline.typeOperation = {
          code: String(payload.timeline.typeOperation),
          nature: '',
          status: true,
          sw: ''
        };
      }

      if (payload.timeline.processType) {
        payload.timeline.processType = {
          id: Number(payload.timeline.processType),
          name: '',
          nameEs: '',
          active: true
        };
      }
    }
    //Seteamos el cliente consignatario de la carga
    payload.bulks = this.consigneeToBulks({ bulks: payload.bulks, consignee: this.form.consignee });
    payload.bulks = this.ownerToBulks({ bulks: this.form.bulks, owner: this.form.sender })
      .map(bulk => {
        return bulk.setBulkType('box'); //Seteamos el tipo de bulto a caja para poder cargarlo en la cubicación
      })
      .map((bulk, indexBulk) => {
        bulk.group = indexBulk; //Seteamos el grupo de bultos para poder desagrupar en la cubicación
        return bulk;
      })
      .map(bulk => {
        bulk.cargoTo = this.form.sender; //Seteamos el cliente dueño de la carga
        return bulk;
      })
      .map((bulk, indexBulk) => {
        bulk.quantity = 1; //Seteamos la cantidad de bultos a 1
        bulk.group = indexBulk; //Seteamos el grupo de bultos para poder desagrupar en la cubicación
        return bulk;
      });
    if (this.form.branch?.reference)
      payload.bulks = this.addReference({ bulks: payload.bulks, reference: this.form.branch?.reference });
    payload.date = DateISOStringGenerator();
    payload.notes = `${this.form.notes} ${this.form.dateTime}`;
    await this.save(payload);
  }

  async save(payload: PreRegister) {
    try {
      this.isLoading = true;
      const res = await this.savePreregister.execute(payload);
      if (!('error' in res)) {
        this.clearForm();
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  addReference({ bulks, reference }: { bulks: Bulk[]; reference: References | null }) {
    try {
      return bulks.map(bulk => {
        bulk.reference = reference;
        bulk.quantity = 1;

        return bulk;
      });
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  ownerToBulks({ bulks, owner }: { bulks: Bulk[]; owner: Entity | null }): Bulk[] {
    try {
      return bulks.map(bulk => {
        bulk.owner = owner;
        bulk.customer = owner;
        bulk.shipper = owner;
        return bulk;
      });
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  consigneeToBulks({ bulks, consignee }: { bulks: Bulk[]; consignee: Entity | null }): Bulk[] {
    try {
      return bulks.map(bulk => {
        bulk.consignee = consignee;
        bulk.customer = consignee;
        return bulk;
      });
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async getCommodityActive() {
    await this.findActiveCommodity
      .execute(true)
      .then((response: CommodityType[]) => {
        this.stateManager.state.dataCommodityTypes = response;
      })
      .catch(error => {
        this.stateManager.state.dataCommodityTypes = [];
        throw new Error(error);
      });
  }

  searchUnitInPricelist(unit: string) {
    const isValid = false;
    this.stateManager.state.dataPriceList.map((item: PriceList) => {
      return item.volumeUnitCode == unit;
    });
    return isValid;
  }

  async volumeFindAllList() {
    await this.findAllVolumeUnits
      .execute()
      .then((response: any) => {
        this.stateManager.state.dataVolume = response;
      })
      .catch(error => {
        throw new Error(error);
      });
  }

  async weightFindAllList() {
    await this.findAllWeightUnits
      .execute()
      .then((response: any) => {
        this.stateManager.state.dataWeight = response;
      })
      .catch(error => {
        throw new Error(error);
      });
  }

  handdleOriginChange(input: any) {
    this.form.address = {
      ...this.form.address,
      ...input
    };
  }

  handdleOriginZipCode(input: ZipCode) {
    this.form.address.zipCode = input.description;
  }

  handdleChangeCustomerData(input: Entity) {
    if (input) {
      this.form.address.description = input.address?.description ?? '';
    }
    if (this.form.customer) {
      this.form.customer.code = input.code;
      this.form.customer.id = input.id;
    }
  }

  handdleChangeCurrency(input: Currency) {
    if (this.form.currency !== null) {
      this.form.currency.currencyCode = input.currencyCode;
    }
  }

  async checkSelectedProvider() {
    return await this.$refs.pricelistOrder.verificar();
  }

  clearForm() {
    this.$refs.formWizardOrder.reset();
    this.form = new PreRegister(PreRegisterTypeEnum.COURIER_ORDER);
    this.form.state = PreRegisterStatusEnum.COURIER_PRE_REGISTER;
  }

  createQuotationRequest(form: any) {
    // eslint-disable-next-line no-console
    console.log(form);
    throw new Error('Not implemented method');
  }
}
