
import Component from 'vue-class-component';
import EntitySearch from '@/core/components/shared/EntitySearch.vue';
import TerritorySearch from '@/core/components/shared/TerritorySearch.vue';
import { Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import { Currency } from '@/settings/domain/currency/Currency';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import BranchSearch from '@/core/components/shared/BranchSearch.vue';
import ConsigneeSearch from '@/core/components/shared/ConsigneeSearch.vue';
import { Branch } from '@/settings/domain/branch/Branch';

@Component({
  name: 'GeneralInformationTab',
  components: { EntitySearch, TerritorySearch, BranchSearch, ConsigneeSearch }
})
export default class GeneralInformationTab extends Vue {
  @Prop({ required: true }) form!: PreRegister;
  @Prop({ required: true, default: () => [] }) currencies!: Currency[];
  @Prop({ required: false }) isLoading!: boolean;
  @Prop({ required: false }) dataCompany!: any;
  @Prop({ required: false }) disableCurrency!: boolean;

  selectedConsignee: Entity | null = null;

  //data
  // currencies: Currency[] = []

  //methods
  setFormAddress(input: Entity) {
    if (input) {
      this.form.address.description = input.address?.description ?? '';
      this.form.address.zipCode = input.address?.zipCode ?? '';
      this.form.address.zipCodeDescription = input.address?.zipCodeDescription ?? '';
      this.form.address.territory = input.address?.selectTerritorie || null;
      this.form.condition = input.termsPayment ?? 'credit';
    }
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  setConsigneeInfo(consignee: Entity | null) {
    if (!consignee) return;
    this.form.consignee = Object.assign({}, consignee);
  }

  setAttributesFromBranch(branch: Branch | null) {
    if (!branch) return;
    this.form.store = Object.assign({}, branch.store);
    this.form.warehouse = Object.assign({}, branch.warehouse);
  }
}
