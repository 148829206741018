
import Vue from 'vue';
import Component from 'vue-class-component';
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { ButtonOptions } from '@/settings/domain/buttonOptions/ButtonOptions';
import AddCommodityClassForm from './AddCommodityClassForm.vue';
import { CommodityClass } from '@/settings/domain/commodityClass/CommodityClass';
import { FindAllCommodityClass } from '@/settings/application/uses_cases/commodityClass/search/FindAllCommodityClass';
import { CreateCommodityClass } from '@/settings/application/uses_cases/commodityClass/create/CreateCommodityClass';
import { UpdateCommodityClass } from '@/settings/application/uses_cases/commodityClass/update/UpdateCommodityClass';
import { DeleteCommodityClass } from '@/settings/application/uses_cases/commodityClass/delete/DeleteCommodityClass';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
@Component({
  name: 'CommodityClassCenter',
  components: { CustomTableN, AddCommodityClassForm }
})
export default class CommodityClassCenter extends Vue {
  @Inject(TYPES.COMMODITY_CLASS_FIND_ALL)
  readonly findAllCommodityClass!: FindAllCommodityClass;
  @Inject(TYPES.COMMODITY_CLASS_SAVE)
  readonly saveCommodityClass!: CreateCommodityClass;
  @Inject(TYPES.COMMODITY_CLASS_UPDATE)
  readonly updateCommodityClass!: UpdateCommodityClass;
  @Inject(TYPES.COMMODITY_CLASS_DELETE)
  readonly deleteCommodityClass!: DeleteCommodityClass;

  //Data
  isLoading = false;

  //Listas
  commodityClassList: CommodityClass[] = [];

  //Formulario
  form: CommodityClass = new CommodityClass();

  //Objeto para acciones y opciones del boton de agregar
  buttonOptions: ButtonOptions = new ButtonOptions();

  //Objeto de acciones para el componente hijo
  actions = {
    save: this.factory,
    clear: this.clear
  };

  //Objeto de acciones para la tabla
  actionsTable = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: this.load
      },
      {
        title: `${this.$t('general.delete')}`,
        icon: 'fa fa-trash',
        variant: 'danger',
        action: this.delete
      }
    ]
  };

  //Getter para obtener los campos renderizados en la tabla
  get fields() {
    return [
      {
        field: 'code',
        label: `${this.$t('general.code')}`
      },
      {
        field: 'description',
        label: `${this.$t('general.description')}`
      },
      {
        label: this.$t('general.status'),
        field: 'active',
        sortable: true,
        formatFn: (value: boolean) => (value ? this.$t('general.active') : this.$t('general.disabled'))
      },
      {
        field: 'actions',
        label: `${this.$t('general.actions')}`
      }
    ];
  }

  //Ciclo de vida del componente, se usa para que las consultas se ejecuten cuando el componente sea montado al DOM
  async mounted() {
    await this.findAll();
  }

  //Funcion invocada para desatar las funciones de guardado o actualizacion dependiendo del valor de la propiedad "isEdit" de las opciones del boton
  factory() {
    !this.buttonOptions.isEdit ? this.save() : this.update();
  }

  //Funcion invocada para limpiar el formulario
  clear() {
    this.form = new CommodityClass();
    this.buttonOptions = new ButtonOptions();
  }

  //Funcion invocada para obtener todas las clases de commodity
  async findAll() {
    try {
      this.isLoading = true;
      const res = await this.findAllCommodityClass.execute();
      this.commodityClassList = res.length > 0 ? res.reverse() : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para guardar el registro en la base de datos
  async save() {
    try {
      this.isLoading = true;
      await this.saveCommodityClass.execute(this.form);
      this.clear();
      this.findAll();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
  //Funcion invocada para actualizar el registro en la base de datos
  async update() {
    try {
      this.isLoading = true;
      await this.updateCommodityClass.execute(this.form);
      this.clear();
      this.findAll();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
  //Funcion invocada para guardar el registro en la base de datos
  async delete(item: CommodityClass) {
    try {
      this.isLoading = true;
      await this.deleteCommodityClass.execute(item);
      this.clear();
      this.findAll();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para cargar el objeto seleccionado al formulario
  load(item: CommodityClass) {
    this.form = Object.assign(new CommodityClass(), item);
    this.buttonOptions = new ButtonOptions('secondary', `general.edit`, 'fa-save', true);
  }
}
