
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import AddVolumeAndWeight from '@/settings/infrastructure/ui/components/volumeandweight/AddVolumeAndWeight.vue';
import { WeightFindAll } from '@/settings/application/uses_cases/weight/search/WeightFindAll';
import { VolumeFindAll } from '@/settings/application/uses_cases/volume/search/VolumeFindAll';
import { UpdateWeight } from '@/settings/application/uses_cases/weight/update/UpdateWeight';
import { UpdateVolume } from '@/settings/application/uses_cases/volume/update/UpdateVolume';
import CustomTable from '@/core/components/shared/CustomTable.vue';
import { Volume } from '@/settings/domain/volume/Volume';
import { Weight } from '@/settings/domain/weight/Weight';
import { DeleteVolume } from '@/settings/application/uses_cases/volume/delete/DeleteVolume';
import { DeleteWeight } from '@/settings/application/uses_cases/weight/delete/DeleteWeight';

@Component({
  name: 'CreateVolumeAndWeight',
  components: { AddVolumeAndWeight, CustomTable }
})
export default class AppCreateVolumeAndWeight extends Vue {
  @Inject(TYPES.FINDALL_VOLUME)
  readonly volumeFindAll!: VolumeFindAll;
  @Inject(TYPES.FINDALL_WEIGHT)
  readonly weightFindAll!: WeightFindAll;
  @Inject(TYPES.UPDATE_VOLUME)
  readonly updateVolume!: UpdateVolume;
  @Inject(TYPES.UPDATE_WEIGHT)
  readonly updateWeight!: UpdateWeight;
  @Inject(TYPES.DELETE_VOLUME)
  readonly deleteVolumeFromDb!: DeleteVolume;
  @Inject(TYPES.DELETE_WEIGHT)
  readonly deleteWeightFromDb!: DeleteWeight;

  formWeigth = {
    code: '',
    description: '',
    isEdit: false,
    active: true
  };

  formVolume = {
    code: '',
    description: '',
    isEdit: false,
    active: true
  };

  //Actions
  actionsVolume = {
    edit: this.loadDataFromObjectVolume,
    delete: this.deleteVolume
  };
  actionsWeight = {
    edit: this.loadDataFromObjectWeigth,
    delete: this.deleteWeight
  };
  //DATA
  lastDataWeigth: any = null;
  lastDataVolume: any = null;
  isLoading = false;
  fullPage = true;
  editVolume = null;
  editWeight = null;
  weightList = [];
  volumeList = [];
  totalRowsVolume = 1;
  currentPageVolume = 1;
  perPageVolume = 5;
  filterVolume = null;
  filterOnVolume = [];
  sortByVolume = 'description';
  sortDescVolume = true;
  totalRowsWeight = 1;
  currentPageWeight = 1;
  perPageWeight = 5;
  filterWeight = null;
  filterOnWeight = [];
  sortByWeight = 'description';
  sortDescWeight = true;
  //REFS
  $refs!: {
    currencyDescription: HTMLInputElement;
  };
  //HOOKS
  created() {
    this.$nextTick(() => this.volumeFindAllList());
    this.$nextTick(() => this.weightFindAllList());
  }
  //COMPUTED
  get rowsVolume() {
    return this.volumeList.length;
  }
  get rowsWeight() {
    return this.weightList.length;
  }
  get fields() {
    return [
      { key: 'code', label: this.translateCol('code'), sortable: true },
      { key: 'description', label: this.translateCol('description'), sortable: true },
      { key: 'active', label: this.translateCol('active'), sortable: false },
      { key: 'actions', label: this.translateCol('actions'), sortable: false }
    ];
  }

  //Methods
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  translateCol(colName: string) {
    return this.$i18n.t('general.' + colName);
  }
  onFilteredVolume(filteredItems: any) {
    this.totalRowsVolume = filteredItems.length;
    this.currentPageVolume = 1;
  }
  onFilteredWeight(filteredItems: any) {
    this.totalRowsWeight = filteredItems.length;
    this.currentPageWeight = 1;
  }
  volumeFindAllList() {
    this.isLoading = true;
    this.volumeFindAll
      .execute()
      .then((response: any) => {
        this.isLoading = false;
        this.volumeList = response.reverse();
      })
      .catch(error => {
        this.isLoading = false;
        throw new Error(error);
      });
  }
  weightFindAllList() {
    this.isLoading = true;
    this.weightFindAll
      .execute()
      .then((response: any) => {
        this.isLoading = false;
        this.weightList = response.reverse();
      })
      .catch(error => {
        this.isLoading = false;
        throw new Error(error);
      });
  }

  refresh(component: string) {
    component == 'volume' ? this.volumeFindAllList() : this.weightFindAllList();
  }

  loadDataFromObjectVolume(input: any) {
    this.formVolume = Object.assign(
      {},
      {
        ...input,
        isEdit: true
      }
    );
  }

  loadDataFromObjectWeigth(input: any) {
    this.formWeigth = Object.assign(
      {},
      {
        ...input,
        isEdit: true
      }
    );
  }

  clearFunction(component: string) {
    if (component == 'volume') {
      this.formVolume = {
        code: '',
        description: '',
        isEdit: false,
        active: true
      };
      return;
    }
    this.formWeigth = {
      code: '',
      description: '',
      isEdit: false,
      active: true
    };
  }

  async deleteVolume(unit: Volume) {
    try {
      const res = await this.deleteVolumeFromDb.execute(unit);
      if (res) this.volumeFindAllList();
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async deleteWeight(unit: Weight) {
    try {
      const res = await this.deleteWeightFromDb.execute(unit);
      if (res) this.weightFindAllList();
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
