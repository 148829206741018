
import { TYPES } from '@/core/config/Types';
import { EntityFindAll } from '@/settings/application/uses_cases/entity/search/EntityFindAll';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { Prop } from 'vue-property-decorator';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
@Component({
  name: 'EntityList',
  components: { CustomTableN }
})
export default class EntityList extends Vue {
  @Inject(TYPES.API_ENTITY_FIND_ALL_PAGE)
  readonly findAllEntity!: EntityFindAll;
  //DATA
  isLoading = false;
  items: Entity[] = [];
  //PROPS
  @Prop()
  formUpdate!: Function;
  //GETTERS
  get fields() {
    return [
      {
        field: 'code',
        label: this.$t('general.code'),
        sortable: true
      },
      {
        field: 'id',
        label: this.$t('general.id'),
        sortable: true
      },
      {
        field: 'fullName',
        label: this.$t('general.name'),
        sortable: true
      },
      {
        field: 'typeCustomer.name',
        label: this.$t('general.type'),
        sortable: true
      },
      {
        field: 'discountPercentage',
        label: this.$t('general.discount'),
        sortable: true
      },
      {
        field: 'actions',
        label: this.$t('general.actions')
      }
    ];
  }
  //MOUNT FUNCTIONS
  mounted() {
    this.getAllEntity();
  }
  //ACTIONS FOR TABLE
  /*  actions = {
    edit: this.selectEntity
  }; */
  actions = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: this.selectEntity
      }
    ]
  };
  //FUNCTIONS
  getAllEntity() {
    this.isLoading = true;
    this.findAllEntity
      .execute()
      .then((response: any) => {
        if ('message' in response) {
          this.$swal(response.message, '', 'warning');
          this.items = [];
          this.isLoading = false;
          return;
        } else {
          this.items = response.reverse();
          this.isLoading = false;
        }
      })
      .catch(error => {
        this.isLoading = false;
        throw new Error(`${error}`);
      });
  }

  selectEntity(data: any) {
    this.formUpdate(data);
  }
}
