import { Injectable } from '@/core/di/Injectable';
import { Query } from '@/core/cqrs/Query';
import { ContableConcepts } from '@/courier/domain/ContableConcepts/ContableConcepts';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { ContableConceptsRepository } from '@/courier/domain/ContableConcepts/ContableConceptsRepository';

@Injectable()
export class FindByIdContableConcepts extends Query<Promise<ContableConcepts>, string> {
  constructor(
    @Inject(TYPES.CONTABLE_CONCEPTS_REPOSITORY)
    private readonly contableConceptRepository: ContableConceptsRepository
  ) {
    super();
  }

  public async internalExecute(id: string): Promise<ContableConcepts> {
    return await this.contableConceptRepository.findById(id);
  }
}
