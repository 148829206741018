import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Command } from '@/core/cqrs/Command';
import { TermsPayment } from '@/settings/domain/ParmsGeneral/termsPayment/TermsPayment';
import { TermsPaymentRepository } from '@/settings/domain/ParmsGeneral/termsPayment/TermsPaymentRepository';

@Injectable()
export class CreateTermsPayment extends Command<Promise<any>, TermsPayment> {
  public constructor(
    @Inject(TYPES.TERMS_PAYMENT_REPOSITORY)
    private readonly repository: TermsPaymentRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  internalExecute(termsPayment: TermsPayment): Promise<any> {
    return this.repository.create(termsPayment);
  }
}
