import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { StateManager } from '@/core/statemanager/StateManager';
import { Containers } from '@/settings/domain/containers/Containers';
import { ContainersRepository } from '@/settings/domain/containers/CotainersRepository';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';

@Injectable()
export class FindAllContainers extends Query<Promise<Containers[]>> {
  public constructor(
    @Inject(TYPES.CONTAINER_REPOSITORY)
    private readonly containerRepository: ContainersRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(): Promise<Containers[]> {
    const dataContainer = await this.containerRepository.findAll();
    this.stateManager.patch({ dataContainer });
    return this.stateManager.state.dataContainer;
  }
}
