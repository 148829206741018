import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { ResizingZone } from '@/wms/domain/layout/configResizing/ResizingZone';
import { LocationsRepository } from '@/wms/domain/layout/locations/LocationsRepository';

@Injectable()
export class GenerateLocationTagsByZone extends Query<Promise<string>, ResizingZone> {
  constructor(@Inject(TYPES.LOCATIONS_REPOSITORY) private locationsRepository: LocationsRepository) {
    super();
  }

  async internalExecute(param: ResizingZone): Promise<string> {
    return await this.locationsRepository.printTagsByZone(param);
  }
}
