
import Vue from 'vue';

export default Vue.extend({
  name: 'AirWayBill',
  data() {
    return {
      message: 'Hola Mundo'
    };
  },
  methods: {}
});
