import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Inject } from '@/core/di/Inject';
import { Injectable } from '@/core/di/Injectable';
import { UserERP } from '@/settings/domain/userERP/UserERP';
import { UserErpRepository } from '@/settings/domain/userERP/UserErpRepository';

@Injectable()
export class FindUserErpByEmail extends Query<Promise<UserERP>, string> {
  public constructor(
    @Inject(TYPES.USER_ERP_REPOSITORY)
    private readonly userErpRepository: UserErpRepository
  ) {
    super();
  }

  async internalExecute(email: string): Promise<UserERP> {
    return await this.userErpRepository.findByEmail(email);
  }
}
