import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { EntityRepository } from '@/settings/domain/entity/entity/EntityRepository';

@Injectable()
export class EntityDeleteAddress extends Query<Promise<any>, any> {
  public constructor(
    @Inject(TYPES.API_ENTITY_REPOSITORY)
    readonly entityRep: EntityRepository
  ) {
    super();
  }

  async internalExecute(objectIds: any): Promise<any> {
    return await this.entityRep.deleteAddress(objectIds);
  }
}
