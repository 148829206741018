export class TypeAddress {
  id: number;
  name: string;
  description: string;

  public constructor() {
    this.id = 0;
    this.name = '';
    this.description = '';
  }
}
