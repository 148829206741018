import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { PreRegisterRepository } from '@/wms/domain/preRegister/PreRegisterRepository';
import { PreRegisterForDashboard } from '@/wms/domain/preRegister/PreRegisterForDashboard';
import { DashboardAdministrationFilters } from '@/filters/domain/Filters';

@Injectable()
export class PreRegisterFindForDashboard extends Query<
  Promise<PreRegisterForDashboard[]>,
  DashboardAdministrationFilters
> {
  public constructor(
    @Inject(TYPES.PRE_REGISTER_REPOSITORY)
    private readonly preRegisterRepository: PreRegisterRepository
  ) {
    super();
  }
  async internalExecute(payload: DashboardAdministrationFilters): Promise<PreRegisterForDashboard[]> {
    return await this.preRegisterRepository.findForDashboard(payload);
  }
}
