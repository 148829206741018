
import { TYPES } from '@/core/config/Types';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { SearchTerritoryByQueryDescription } from '@/settings/application/uses_cases/views/territories/SearchTerritoryByQueryDescription';
import { Prop } from 'vue-property-decorator';
import { Territories } from '@/settings/domain/views/territories/Territories';
import { SearchTerritoriesByQueryParameter } from '@/settings/application/uses_cases/views/territories/SearchTerritoriesByQueryParameter';
@Component({
  name: 'SearchTerritories'
})
export default class SearchTerritories extends Vue {
  @Inject(TYPES.API_VIEW_FIND_TERRITORIES_BY_QUERY)
  readonly searchTerritoriesByQueryDescription!: SearchTerritoryByQueryDescription;
  @Inject(TYPES.VIEW_FIND_TERRITORIES)
  readonly searchTerritoriesByQueryParameter!: SearchTerritoriesByQueryParameter;
  //SEARCH INTERVAL VARIABLES
  timeout: any;
  searchInterval = 600;

  //DATA
  territories: Territories[] = [];
  isLoading = false;
  //PROPS
  @Prop({ required: true }) model: any;
  @Prop({ required: true }) searchType!: string;
  @Prop({ required: false }) validate!: any;

  get selected() {
    return this.model;
  }

  set selected(newValue: any) {
    this.$emit('update:model', newValue);
  }

  //FUNCTIONS
  searchByDescription(query: string) {
    clearTimeout(this.timeout);
    if (query.length < 1) return;
    this.timeout = setTimeout(() => {
      this.isLoading = true;
      this.searchTerritoriesByQueryDescription
        .execute(query)
        .then((response: any) => {
          this.territories = response;
        })
        .catch((err: any) => {
          throw new Error(`${err}`);
        })
        .finally(() => {
          this.isLoading = false;
        });
      clearTimeout(this.timeout);
    }, this.searchInterval);
  }

  searchByParameter(query: string) {
    clearTimeout(this.timeout);
    if (query.length < 1) return;
    this.timeout = setTimeout(() => {
      this.isLoading = true;
      this.searchTerritoriesByQueryParameter
        .execute(query)
        .then((response: any) => {
          this.territories = response;
        })
        .catch((err: any) => {
          throw new Error(`${err}`);
        })
        .finally(() => {
          this.isLoading = false;
        });
      clearTimeout(this.timeout);
    }, this.searchInterval);
  }

  factory(query: string) {
    query = query.toUpperCase();
    switch (this.searchType) {
      case 'parameter':
        this.searchByParameter(query);
        break;
      case 'description':
        this.searchByDescription(query);
        break;
    }
  }
}
