import { Injectable } from '@/core/di/Injectable';
import { PreRegisterEventLogDetailRepository } from '@/wms/domain/preRegisterEventLogDetail/PreRegisterEventLogDetailRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { PreRegisterEventLogDetail } from '@/wms/domain/preRegisterEventLogDetail/PreRegisterEventLogDetail';
import { http } from '@/core/http/HttpFetch';

@Injectable()
export class PreRegisterEventLogDetailService implements PreRegisterEventLogDetailRepository {
  private readonly API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;
  private readonly API_PRE_REGISTER_DETAIL_SAVE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_EVENTS_LOG_DETAIL_SAVE;
  private readonly API_PRE_REGISTER_DETAIL_GET_DETAIL: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_EVENTS_LOG_DETAIL_GET_DETAIL;

  async save(payload: {
    activityId: number;
    detail: PreRegisterEventLogDetail;
    eventId: number;
    eventLogId: number;
    preRegisterNumber: number;
    preRegisterType: string;
  }): Promise<PreRegisterEventLogDetail> {
    try {
      return await http.post<PreRegisterEventLogDetail>(
        `${this.API_PRE_REGISTER_DETAIL_SAVE}?activityId=${payload.activityId}&eventId=${payload.eventId}&eventLogId=${payload.eventLogId}&preRegisterNumber=${payload.preRegisterNumber}&preRegisterType=${payload.preRegisterType}`,
        JSON.stringify(payload.detail)
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async getDetail(payload: {
    activityId: number;
    eventId: number;
    eventLogId: number;
    preRegisterNumber: number;
    preRegisterType: string;
  }): Promise<PreRegisterEventLogDetail> {
    try {
      return await http.get<PreRegisterEventLogDetail>(
        `${this.API_PRE_REGISTER_DETAIL_GET_DETAIL}activityId=${payload.activityId}&eventId=${payload.eventId}&eventLogId=${payload.eventLogId}&preRegisterNumber=${payload.preRegisterNumber}&preRegisterType=${payload.preRegisterType}`
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
