import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Company } from '@/settings/domain/company/Company';
import { CompanyRepository } from '@/settings/domain/company/CompanyRepository';

@Injectable()
export class CompanyFindById extends Query<Promise<Company>, string> {
  public constructor(
    @Inject(TYPES.COMPANY_REPOSITORY)
    private readonly companyRepository: CompanyRepository
  ) {
    super();
  }
  async internalExecute(companyId: string): Promise<Company> {
    return await this.companyRepository.findCompanyById(companyId);
  }
}
