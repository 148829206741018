
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { TimelineFindByProcessType } from '@/tracking/application/uses_cases/timeline/search/findtimelinebyprocesstype/TimelineFindByProcessType';
import { UpdateTimeline } from '@/tracking/application/uses_cases/timeline/update/UpdateTimeline';
import { EventsFindByProcessType } from '@/tracking/application/uses_cases/events/search/EventsFindByProcessType';
import { FindChartValuesByTimeLine } from '@/tracking/application/uses_cases/timeline/search/findchartvaluesbytimeline/FindChartValuesByTimeLine';
import { RoutesFindByStatus } from '@/settings/application/uses_cases/routes/search/RoutesFindByStatus';
import { CreateTimelineConfig } from '@/tracking/application/uses_cases/timelineConfig/create/CreateTimelineConfig';
import { FindAllTimelineConfig } from '@/tracking/application/uses_cases/timelineConfig/search/findAll/FindAllTimelineConfig';
import { DeleteEventOnGeneralTimeline } from '@/tracking/application/uses_cases/events/delete/DeleteEventOnGeneralTimeline';
import { DeleteEventOnRouteTimeline } from '@/tracking/application/uses_cases/events/delete/deleteEventOnRouteTimeline';
import { CreateTimeline } from '@/tracking/application/uses_cases/timeline/create/CreateTimeline';
import { Routes } from '@/settings/domain/routes/Routes';
import { Timeline } from '@/tracking/domain/timeline/Timeline';
import { Events } from '@/tracking/domain/events/Events';
import { TimelineConfig } from '@/tracking/domain/timelineConfig/TimelineConfig';
import AsociateEvents from '../AsociateEvents.vue';
import draggable from 'vuedraggable';
import AddTimeline from './AddTimeline.vue';
import AsociateEventsN from '../asociateEvents/AsociateEventsN.vue';
import Linechart from '@/core/components/dashboard/chartjs/Linechart.vue';
import TimelineComponentFF from '@/tracking/infrastructure/ui/components/ff/timeline/TimelineComponentFF.vue';
import AsociateReferences from './AsociateReferences.vue';

@Component({
  name: 'ManageTimelineFFN',
  components: {
    AsociateEvents,
    draggable,
    AddTimeline,
    AsociateEventsN,
    Linechart,
    TimelineComponentFF,
    AsociateReferences
  }
})
export default class ManageTimelineFFN extends Vue {
  @Inject(TYPES.FINDBYPROCESSTYPE_TIMELINE)
  readonly findTimelinesByProcess!: TimelineFindByProcessType;
  @Inject(TYPES.UPDATE_TIMELINE)
  readonly updateTimeline!: UpdateTimeline;
  @Inject(TYPES.FINDEVENTSBYPROCESSTYPE_EVENTS)
  readonly eventsFindByProcessType!: EventsFindByProcessType;
  @Inject(TYPES.FIND_CHARTVALUESTIMELINE)
  readonly findChartValuesByTimeLine!: FindChartValuesByTimeLine;
  @Inject(TYPES.FINDBYSTATUS_ROUTES)
  readonly findRoutesByStatus!: RoutesFindByStatus;
  @Inject(TYPES.TIMELINECONFIG_TYPE_SAVE)
  readonly saveTimelineConfig!: CreateTimelineConfig;
  @Inject(TYPES.TIMELINECONFIG_TYPE_FINDALL)
  readonly findAllTimelineConfig!: FindAllTimelineConfig;
  @Inject(TYPES.DELETE_EVENT_ON_GENERAL_TIMELINE)
  readonly deleteEventOnGeneralTimeline!: DeleteEventOnGeneralTimeline;
  @Inject(TYPES.DELETE_EVENT_ON_ROUTE_TIMELINE)
  readonly deleteEventOnRouteTimeline!: DeleteEventOnRouteTimeline;
  @Inject(TYPES.CREATE_TIMELINE)
  readonly createTimeline!: CreateTimeline;

  //Data
  isLoading = false;
  timelineIsEdit = false;
  typeComponent = '';
  chartData: { profit: number[]; income: number[]; bill: number[]; days: number[] } = {
    profit: [],
    income: [],
    bill: [],
    days: []
  };
  selectedEvent = 0;

  //Objetos de formulario
  newTimeline: Timeline = new Timeline();

  //Objeto de acciones para modales o componentes hijos
  timelineModalActions = {
    factory: null,
    save: this.factoryTimelineSkeleton,
    clear: this.clearTimelineForm
  };

  //Selects
  operationalProfilesSelected = 'a';
  selectedTimelineConfig: any | null = null;
  selectedTimeLine: Timeline | null = null;

  auxiliarTimeline: Timeline | TimelineConfig | null | any = null;

  //Listas
  routesList: Routes[] = [];
  timelinesList: Timeline[] = [];
  timelineConfigList: TimelineConfig[] = [];

  //Array para obtener los eventos abiertos en la previsualizacion
  openEvents: Events[] = [];

  //Objeto de referencias
  $refs!: {
    mdCreateModal: HTMLFormElement;
  };

  //Funcion invocada cuando el componente es montado al DOM
  mounted() {
    this.getTimelines();
  }

  //Getter para mostra/ocultar componentes a partir de la seleccion de timelines
  get showComponents() {
    return this.selectedTimeLine;
  }

  //Getter para obtener los perfiles de la empresa
  get operationalProfiles() {
    const profiles = JSON.parse(localStorage.getItem('operationalProfiles') as any);
    const arrayProfile = [
      {
        text: 'wms',
        value: 'w',
        notEnabled: false
      },
      {
        text: 'courier',
        value: 'u',
        notEnabled: false
      },
      {
        text: 'freight',
        value: 'c',
        notEnabled: false
      }
    ] as any;

    arrayProfile.map((item: any) => {
      if (!profiles.find((element: any) => element == item.text)) {
        item.notEnabled = true;
      }
      item.text =
        item.text == 'wms'
          ? this.$t('general.WMS')
          : item.text == 'courier'
          ? this.$t('menu.Courier')
          : item.text == 'freight'
          ? this.$t('menu.Freight')
          : '';
    });
    arrayProfile.push({ text: this.$t('general.all'), value: 'a' });
    return arrayProfile;
  }

  //Getter para obtener la lista de eventos
  get eventList() {
    return this.selectedTimeLine && !this.selectedTimelineConfig
      ? this.selectedTimeLine.events
      : this.selectedTimelineConfig.events;
  }

  set eventList(newValue: any[]) {
    this.eventList = newValue;
  }

  //Getter para saber si está seleccionada un timeline de configuración
  get configIsSelected() {
    return this.selectedTimelineConfig != null;
  }

  //Funcion invocada para obtener todos los timelines
  async getTimelines() {
    try {
      this.isLoading = true;
      let res = await this.findTimelinesByProcess.execute(3);

      //Verificamos si hay un filtro de timelines activo
      if (this.operationalProfilesSelected !== 'a' && res.length > 0) {
        res = res.filter((item: Timeline) => item.configFor === this.operationalProfilesSelected);
      }

      this.timelinesList = res.length > 0 ? res.reverse() : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada cuando se ejecuta el evento @change del filtro de timelines
  filterHanddleChange() {
    this.timelinesList = [];
    this.selectedTimeLine = null;
    this.selectedTimelineConfig = null;
    this.getTimelines();
  }

  //Funcion invocada para obtener todas las rutas activas
  async getRoutes() {
    try {
      this.isLoading = true;
      const res = await this.findRoutesByStatus.execute(true);
      this.routesList = res.length > 0 ? res.reverse() : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion para decidir si se hace un update o un save de un TimelineSkeleton
  factoryTimelineSkeleton() {
    !this.timelineIsEdit ? this.saveSkeletonTimeline() : this.updateSkeletonTimeline(this.newTimeline);
  }

  //Funcion invocada para crear un timeline
  async saveSkeletonTimeline() {
    try {
      this.isLoading = true;
      const res = await this.createTimeline.execute(this.newTimeline);
      if (!('error' in res)) {
        await this.getTimelines();
        this.selectedTimeLine = this.searchTimelineById(res.id)[0];
        this.selectedTimelineConfig = null;
        this.$refs.mdCreateModal.hide();
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para actualizar un timeline
  async updateSkeletonTimeline(timeline: Timeline, fromModal?: boolean) {
    try {
      this.isLoading = true;
      timeline.events.sort((a: any, b: any) => a.sequence - b.sequence) as any;
      const res = await this.updateTimeline.execute(timeline);
      if (!('error' in res)) {
        await this.getTimelines();
        this.selectedTimeLine = this.searchTimelineById(timeline.id)[0];
        this.selectedTimelineConfig = null;
        this.$refs.mdCreateModal.hide();
        fromModal && this.$bvModal.hide('mdAsociateEvents');
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para actualizar un timeline
  async createConfigTimeline(timeline: TimelineConfig, fromModal?: boolean) {
    try {
      this.isLoading = true;
      timeline.events.sort((a: any, b: any) => a.sequence - b.sequence) as any;
      const res = await this.saveTimelineConfig.execute(timeline);
      if (!('error' in res)) {
        await this.getTimelines();
        await this.getTimelineConfig();
        this.selectedTimeLine = this.searchTimelineById(timeline.id)[0];
        this.selectedTimelineConfig = null;
        fromModal && this.$bvModal.hide('mdAsociateEvents');
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para deshabilitar un timeline
  async deleteTimeline() {
    try {
      if (!this.selectedTimeLine) return;

      //Se muestra un mensaje de confirmacion
      const message = await this.$swal.fire({
        showCancelButton: true,
        showConfirmButton: true,
        reverseButtons: true,
        title: this.$t('general.info'),
        text: `${this.$t('general.areyousuretodeletethis')} ${this.$t('general.timeline')} (${
          this.selectedTimeLine.description
        })?`,
        confirmButtonText: `${this.$t('general.delete')}`,
        cancelButtonText: `${this.$t('general.cancel')}`,
        icon: 'warning'
      });

      if (!message.isConfirmed) return;
      //Si se confirma se ejecuta la actualización del estado del timeline

      this.isLoading = true;
      this.selectedTimeLine.active = false;
      const res = await this.updateTimeline.execute(this.selectedTimeLine);
      if (!('error' in res)) {
        this.selectedTimeLine = null;
        this.selectedTimelineConfig = null;
        await this.getTimelines();
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para cargar el timeline seleccionado al form de edicion
  loadTimelineSkeletonToLoad() {
    this.newTimeline.id = this.selectedTimeLine?.id as any;
    this.newTimeline.typeOperation = this.selectedTimeLine?.typeOperation as any;
    this.newTimeline.typeTransport = this.selectedTimeLine?.typeTransport as any;
    this.newTimeline.description = this.selectedTimeLine?.description as any;
    this.newTimeline.configFor = this.selectedTimeLine?.configFor as any;
    this.newTimeline.events = this.selectedTimeLine?.events as any;

    this.timelineIsEdit = true;
    this.$bvModal.show('mdCreateModal');
  }

  //Funcion invocada para buscar timelines a partir de un ID
  searchTimelineById(id: number): Timeline[] {
    return this.timelinesList.filter(timeline => timeline.id === id);
  }

  //Funcion invocada para buscar timelines de configuracion a partir de un ID
  searchTimelineConfigById(id: number): TimelineConfig[] {
    return this.timelineConfigList.filter(timeline => timeline.id === id);
  }

  //Funcion invocada para reiniciar el form de creacion/edicion de timeline
  clearTimelineForm() {
    this.newTimeline = new Timeline();
  }

  //Funcion para determinar que tipo de modal se abre para asociar eventos
  openAsociateEvents(type: string) {
    this.typeComponent = type;
    this.setTimeline();
    this.$bvModal.show('mdAsociateEvents');
  }

  //Funcion para eliminar eventos desde el timeline skeleton
  async deleteEventGeneralTimeline(eventId: number) {
    try {
      this.isLoading = true;
      const idTimeline = this.selectedTimeLine?.id;
      const res = await this.deleteEventOnGeneralTimeline.execute({
        timeline: idTimeline,
        event: eventId
      });

      if ('id' in res) {
        this.$swal(`${this.$t('general.messageDelete')}`, '', 'success');
        await this.getTimelines();
        this.selectedTimeLine = null;
        this.selectedTimeLine = this.searchTimelineById(idTimeline as number)[0];
      }

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para obtener los timelines de configuracion de la linea de tiempo esqueleto seleccionada
  async getTimelineConfig() {
    try {
      if (!this.selectedTimeLine) return;
      this.isLoading = true;
      const res = await this.findAllTimelineConfig.execute(this.selectedTimeLine.id);
      this.timelineConfigList = res.length > 0 ? res.reverse() : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion para ordernar los eventos al terminar un drag
  endDrag() {
    this.selectedTimeLine?.events.map((evento: any, index: number) => (evento.sequence = index + 1));
    this.updateSkeletonTimeline(this.selectedTimeLine as Timeline);
    this.$toasted.show(`${this.$t('general.messageupdate')}`);
  }

  //Funcion para cargar las graficas
  async loadChartData(timeline: TimelineConfig) {
    try {
      if (!timeline) return;

      const incomeArray: number[] = [];
      const billArray: number[] = [];
      const profitArray: number[] = [];
      const daysArray: number[] = [];
      this.selectedEvent = 0;

      this.isLoading = true;
      const res = await this.findChartValuesByTimeLine.execute(timeline.id);
      res.forEach((element: any) => {
        incomeArray.push(element[2]);
        billArray.push(element[3]);
        profitArray.push(element[4]);
        daysArray.push(element[6]);
      });
      this.chartData = {
        profit: profitArray,
        income: incomeArray,
        bill: billArray,
        days: daysArray
      };
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para eliminar un evento de un timeline de configuracion
  async deleteEventRouteTimeline(eventId: number, timeline: TimelineConfig) {
    try {
      this.isLoading = true;
      const res = await this.deleteEventOnRouteTimeline.execute({ timeline: timeline.id, event: eventId });
      if ('id' in res) {
        await this.getTimelineConfig();
        this.selectedTimelineConfig = this.searchTimelineConfigById(timeline.id)[0];
        this.loadChartData(this.selectedTimelineConfig);
        this.$swal(`${this.$t('general.messageDelete')}`, '', 'success');
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para decidir que tipo de funcion de eliminar eventos debe ejecutarse
  factoryDelete(eventId: number) {
    this.selectedTimelineConfig
      ? this.deleteEventRouteTimeline(eventId, this.selectedTimelineConfig)
      : this.deleteEventGeneralTimeline(eventId);
  }

  //Funcion para evitar que se modifique el orden de los eventos en un timeline de configuracion, esto solo se hace para
  // un timeline esqueleto.
  verifyDrag() {
    if (this.selectedTimelineConfig) return false;
    return true;
  }

  //Funcion para hacer un set del timeline necesario a partir de si esta o no escogido un timeline de configuracion
  setTimeline() {
    this.auxiliarTimeline = Object.assign(
      {},
      {
        ...this.selectedTimeLine,
        events: this.selectedTimelineConfig ? this.selectedTimelineConfig.events : this.selectedTimeLine?.events
      }
    );
  }
}
