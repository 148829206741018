import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Query } from '@/core/cqrs/Query';
import { TYPES } from '@/core/config/Types';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { EntityRepository } from '@/settings/domain/entity/entity/EntityRepository';

@Injectable()
export class EntityFindById extends Query<Promise<Entity[]>, number> {
  public constructor(
    @Inject(TYPES.API_ENTITY_REPOSITORY)
    private readonly customerRepository: EntityRepository
  ) {
    super();
  }

  async internalExecute(code: number): Promise<Entity[]> {
    try {
      return await this.customerRepository.findById(code);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
