import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { ConsolidatedDataForReport } from '@/wms/domain/consolidated/ConsolidatedDataForReport';
import { ConsolidatedRepository } from '@/wms/domain/consolidated/ConsolidatedRepository';
@Injectable()
export class ConsolidatedService implements ConsolidatedRepository {
  private readonly API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;
  private readonly API_CONSOLIDATED_FIND_ID_BY_DATE_RANGE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_CONSOLIDATED_FIND_ID_BY_DATE_RANGE;
  private readonly API_CONSOLIDATED_FIND_DATA_FOR_REPORT: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_CONSOLIDATED_FIND_DATA_FOR_REPORT;
  findByDateRange({
    dateFrom,
    dateTo,
    carrier,
    consignee
  }: {
    dateFrom: string;
    dateTo: string;
    carrier: number | undefined;
    consignee: string | null;
  }): Promise<number[]> {
    try {
      let QUERY = `${this.API_CONSOLIDATED_FIND_ID_BY_DATE_RANGE}dateFrom=${dateFrom}&dateTo=${dateTo}`;

      if (carrier) {
        QUERY += `&carrier=${carrier}`;
      }

      if (consignee) {
        QUERY += `&consignee=${consignee}`;
      }

      return http.get<number[]>(QUERY);
    } catch (error) {
      throw new Error('Method not implemented.');
    }
  }
  findDataReportById(id: number): Promise<ConsolidatedDataForReport> {
    try {
      return http.get<ConsolidatedDataForReport>(`${this.API_CONSOLIDATED_FIND_DATA_FOR_REPORT}number=${id}`);
    } catch (error) {
      throw new Error('Method not implemented.');
    }
  }
}
