import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { ColorRepository } from '@/wms/domain/gamma/ColorRepository';
import { Color } from '@/wms/domain/gamma/Color';

@Injectable()
export default class ColorServices implements ColorRepository {
  private readonly API_BASE_URI: string = localStorageModule().wms.host.API_BASE_URI;
  private readonly API_COLOR_SAVE: string = this.API_BASE_URI + localStorageModule().wms.routes.API_COLOR;
  private readonly API_COLOR_DELETE: string = this.API_BASE_URI + localStorageModule().wms.routes.API_COLOR;
  private readonly API_COLOR_FINDALL: string = this.API_BASE_URI + localStorageModule().wms.routes.API_COLOR;

  async saveColor(dto: Color): Promise<Color> {
    try {
      return await http.post(this.API_COLOR_SAVE, JSON.stringify(dto));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAllColor(): Promise<Color[]> {
    try {
      return await http.get(this.API_COLOR_FINDALL);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async deleteColor(dto: Color): Promise<Color> {
    try {
      return await http.delete(this.API_COLOR_DELETE, JSON.stringify(dto));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async updateColor(dto: Color): Promise<Color> {
    try {
      return await http._put(this.API_COLOR_SAVE, JSON.stringify(dto));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
