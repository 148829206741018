
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';
import html2pdf from 'html2pdf.js';

@Component({
  name: 'DeliveryGuide'
})
export default class DeliveryGuide extends Vue {
  @Inject(TYPES.FINDBYID_COMPANY)
  readonly findByIdCompany!: CompanyFindById;

  @Prop({ required: true }) readonly formData!: PreRegister;

  volume = 0;
  totalWeight = 0;
  totalDeclaredValue = 0;
  downloadablePDFName = `${this.$t('general.deliveryGuide')}-number-${this.formData?.number}`;
  logoImg = localStorage.getItem('urlLogoCompany') as string;
  companyName = '';
  companyAddress = '';
  companyPhoneNumber = '';
  companyEmail = '';
  companyZipCode = '';
  isLoading = false;
  companyUserName = '';
  text = `${this.$t('general.signature')}:\n${this.$t('general.date')}:\n${this.$t('general.id')}:\n${this.$t(
    'general.phone'
  )}:`;

  mounted() {
    this.setPackageValues();
  }

  created(): void {
    this.getCompanyInfo();
  }

  setPackageValues() {
    this.formData?.bulks.forEach(bulk => {
      this.volume += (bulk.length * bulk.height * bulk.width) / 100;
    });
    this.formData?.bulks.forEach(bulk => {
      this.totalWeight += bulk.weight;
    });
    this.formData?.bulks.forEach(bulk => {
      this.totalDeclaredValue += bulk.declaredValue;
    });
  }

  async getCompanyInfo() {
    try {
      this.isLoading = true;
      const companyId = localStorage.getItem('businessId') as string;
      const userNameId = localStorage.getItem('userNameCompany') as string;
      const res: any = await this.findByIdCompany.execute(companyId);
      this.isLoading = false;
      this.companyName = res.businessName;
      this.companyAddress = res.address;
      this.companyPhoneNumber = res.phoneNumber;
      this.companyEmail = res.email;
      this.companyZipCode = res.zipCode;
      this.companyUserName = userNameId;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async printFunction() {
    this.isLoading = true;
    const element = document.getElementById('deliveryGuidePrintTag');
    const opt = {
      margin: 0.5,
      filename: this.downloadablePDFName,
      image: { type: 'jpeg', quality: 0.2 },
      html2canvas: { scale: 2, letterRendering: true, useCORS: true, logging: true },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'p' }
    };
    await html2pdf()
      .set(opt)
      .from(element)
      .toPdf()
      .save();
    this.isLoading = false;
  }
}
