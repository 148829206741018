
import Vue from 'vue';
import Component from 'vue-class-component';
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import AddShippingMethod from './AddShippingMethod.vue';
import { ShippingMethod } from '@/settings/domain/shippingMethod/ShippingMethod';
import { ButtonOptions } from '@/settings/domain/buttonOptions/ButtonOptions';
import CustomTable from '@/core/components/shared/CustomTable.vue';
import { FindAllShippingMethod } from '@/settings/application/uses_cases/shippingMethod/search/FindAllShippingMethod';
import { SaveShippingMethod } from '@/settings/application/uses_cases/shippingMethod/create/SaveShippingMethod';
import { UpdateShippingMethod } from '@/settings/application/uses_cases/shippingMethod/update/UpdateShippingMethod';
import { DeleteShippingMethod } from '@/settings/application/uses_cases/shippingMethod/delete/DeleteShippingMethod';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
@Component({
  name: 'ShippingMethodCenter',
  components: { AddShippingMethod, CustomTable, CustomTableN }
})
export default class ShippingMethodCenter extends Vue {
  @Inject(TYPES.SHIPPING_METHOD_FIND_ALL)
  readonly findAllShippingMethods!: FindAllShippingMethod;
  @Inject(TYPES.SHIPPING_METHOD_SAVE)
  readonly saveShippingMethod!: SaveShippingMethod;
  @Inject(TYPES.SHIPPING_METHOD_UPDATE)
  readonly updateShippingMethod!: UpdateShippingMethod;
  @Inject(TYPES.SHIPPING_METHOD_DELETE)
  readonly deleteShippingMethod!: DeleteShippingMethod;

  //Data
  isLoading = false;

  //Listas
  shippingMethodList: ShippingMethod[] = [];

  //Form
  form: ShippingMethod = new ShippingMethod();

  //Objeto para acciones y opciones del boton de agregar
  buttonOptions: ButtonOptions = new ButtonOptions();

  //Objeto de acciones para el componente hijo
  actions = {
    save: this.factory,
    clear: this.clear
  };

  //Objeto de acciones para la tabla
  actionsTable = {
    delete: this.delete,
    edit: this.load,
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: this.load
      },
      {
        title: `${this.$t('general.delete')}`,
        icon: 'fa fa-trash',
        variant: 'danger',
        action: this.delete
      }
    ]
  };

  //Getter para obtener los campos renderizados en la tabla
  get fields() {
    return [
      {
        field: 'id',
        label: `ID`,
        filterOptions: {
          enabled: true
        }
      },
      {
        field: 'description',
        label: `${this.$t('general.description')}`,
        filterOptions: { enabled: true, filterDropdownItems: [''] }
      },
      {
        field: (row: ShippingMethod) => this.$t(`transports.${row.typeTransport?.name}`),
        label: `${this.$t('general.typetransport')}`
      },
      {
        field: 'multiple',
        label: `${this.$t('general.consolidatedOperation')}`,
        formatFn: (row: boolean) => (row ? this.$t('general.yes') : this.$t('general.no'))
      },
      {
        field: 'isTriangulated',
        label: `${this.$t('general.isTriangulated')}`,
        formatFn: (row: boolean) => (row ? this.$t('general.yes') : this.$t('general.no'))
      },
      {
        field: 'active',
        label: `${this.$t('general.status')}`,
        formatFn: (row: boolean) => (row ? this.$t('general.active') : this.$t('general.disabled'))
      },
      {
        field: 'actions',
        label: `${this.$t('general.actions')}`,
        class: 'col-2'
      }
    ];
  }

  //Ciclo de vida del componente, se usa para que las consultas se ejecuten cuando el componente sea montado al DOM
  async mounted() {
    await this.findAll();
  }

  //Funcion invocada para guardar el registro en la base de datos
  async save() {
    try {
      this.isLoading = true;
      const res = await this.saveShippingMethod.execute(this.form);
      this.isLoading = false;
      if ('id' in res) {
        this.clear();
      }
      this.findAll();
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para actualizar el registro en la base de datos
  async update() {
    try {
      this.isLoading = true;
      await this.updateShippingMethod.execute(this.form);
      this.isLoading = false;
      this.clear();
      this.findAll();
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para desatar las funciones de guardado o actualizacion dependiendo del valor de la propiedad "isEdit" de las opciones del boton
  factory() {
    !this.buttonOptions.isEdit ? this.save() : this.update();
  }

  //Funcion invocada para limpiar el formulario
  clear() {
    this.form = new ShippingMethod();
    this.buttonOptions = new ButtonOptions();
  }

  //Funcion invocada para obtener todas las modalidades de envio
  async findAll() {
    try {
      this.isLoading = true;
      const res = await this.findAllShippingMethods.execute();
      this.shippingMethodList = res.length > 0 ? res.reverse() : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para cargar el objeto seleccionado al formulario
  load(item: ShippingMethod) {
    this.form = Object.assign(new ShippingMethod(), item);
    this.buttonOptions = new ButtonOptions('secondary', `general.edit`, 'fa-save', true);
  }

  //Funcion invocada para eliminar el objeto seleccionado al formulario
  async delete(item: ShippingMethod) {
    try {
      this.isLoading = true;
      await this.deleteShippingMethod.execute(item);
      this.findAll();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
}
