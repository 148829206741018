
import { TYPES } from '@/core/config/Types';
import { CreateMenu } from '@/settings/application/uses_cases/menu/create/CreateMenu';
import { DeleteMenu } from '@/settings/application/uses_cases/menu/delete/DeleteMenu';
import { UpdateMenu } from '@/settings/application/uses_cases/menu/update/UpdateMenu';
import { Menu } from '@/settings/domain/menu/menu';
import { Inject } from 'inversify-props';
import Vue from 'vue';
import Component from 'vue-class-component';
import DraggableList from './components/DraggableList.vue';
import { FindAllMenuForSuperUser } from '@/settings/application/uses_cases/menu/search/FindAllMenuForSuperUser';
@Component({
  name: 'MenuCenter',
  components: {
    DraggableList
  }
})
export default class MenuCenter extends Vue {
  @Inject(TYPES.FIND_ALL_MENU_FOR_SUPER_USER)
  readonly findAllMenu!: FindAllMenuForSuperUser;
  @Inject(TYPES.MENU_UPDATE)
  readonly updateMenu!: UpdateMenu;
  @Inject(TYPES.MENU_SAVE)
  readonly saveMenu!: CreateMenu;
  @Inject(TYPES.MENU_DELETE)
  readonly deleteMenu!: DeleteMenu;

  /**
   * Variables de ayuda
   */
  isLoading = false;
  isEdit = false;

  /**
   * Variables de datos
   */
  menuArray: Menu[] = [];

  /**
   * Variables de formulario
   */

  form = new Menu();

  /**
   * @name mounted
   * @description Hook de vue que se ejecuta cuando se carga el componente
   * @returns void
   * @author San7iix
   */
  mounted() {
    this.isLoading = true;
    Promise.all(this.getAllNecesaryData())
      .then(() => {
        this.isLoading = false;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  /**
   * @name getAllNecesaryData
   * @description Obtiene todos los datos necesarios para el componente
   * @returns Array de promesas
   * @author San7iix
   */
  getAllNecesaryData(): Promise<void>[] {
    return [this.getMenuData()];
  }

  /**
   * @name updateItem
   * @description Actualiza el item en la base de datos
   * @param menuItem Item a actualizar
   * @param parent Padre del item
   * @returns void
   * @author San7iix
   */
  async updateItem({ menuItem, parent }: { menuItem: Menu; parent: string }): Promise<void> {
    try {
      this.isLoading = true;
      menuItem.parent = parent ?? '';
      await this.updateMenu.execute(menuItem);
      this.$bvToast.show(`${this.$t('general.Updated')}`);
      await Promise.all(this.getAllNecesaryData());
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * @name getMenuData
   * @description Obtiene los datos de los menus
   * @returns void
   * @throws Error
   * @author San7iix
   */
  async getMenuData(): Promise<void> {
    try {
      const res = (await this.findAllMenu.execute()) ?? [];

      this.menuArray = res;
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  /**
   * @name resetForm
   * @description Resetea el formulario
   */
  resetForm(): void {
    this.form = new Menu();
    this.isEdit = false;
  }

  /**
   * @name factory
   * @description Crea o actualiza un menu
   * @returns void
   */
  async factory(): Promise<void> {
    try {
      this.isLoading = true;
      if (this.isEdit) {
        await this.updateMenu.execute(this.form);
      } else {
        await this.createMenu(this.form);
      }
      await Promise.all(this.getAllNecesaryData());
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * @name createMenu
   * @description Crea un menu
   * @param menu Menu a crear
   * @returns void
   */

  async createMenu(menu: Menu): Promise<void> {
    try {
      await this.saveMenu.execute(menu);
      this.$bvToast.show(`${this.$t('general.Created')}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  /**
   * @description Obtiene el estado de validación
   * @param dirty
   * @param validated
   * @param valid
   *
   */
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }): any {
    return dirty || validated ? valid : null;
  }

  /**
   * @name LoadToForm
   * @description Funcion que emite el evento loadToForm:update para cargar el formulario con los datos del elemento seleccionado
   */
  loadToFormUpdate(event: PointerEvent, menu: Menu): void {
    event.preventDefault();
    this.loadToForm(menu);
  }

  /**
   * @name loadToForm
   * @description Carga los datos del elemento seleccionado al formulario
   * @param referenceGroupLevel
   */
  loadToForm(menu: Menu): void {
    this.isEdit = true;
    this.form = Object.assign({}, menu);
  }

  /**
   * @name deleteMenu
   * @description Elimina un menu de la base de datos
   * @param menu Menu a eliminar
   * @returns void
   */

  async deleteItem(menu: Menu): Promise<void> {
    try {
      this.isLoading = true;
      await this.deleteMenu.internalExecute(Number(menu.id));
      await Promise.all(this.getAllNecesaryData()).then(() => {
        this.resetForm();
        this.isLoading = false;
      });
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
