import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { OrderNoveltyRepository } from '@/tracking/domain/orderNovelty/OrderNoveltyRepository';
import { OrderNovelty } from '@/tracking/domain/orderNovelty/OrderNovelty';

@Injectable()
export class OrderNoveltyServices implements OrderNoveltyRepository {
  private readonly API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;
  private readonly API_ORDER_NOVELTY: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_ORDER_NOVELTY;
  private readonly API_ORDER_NOVELTY_FIND_BY_ORDER: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_ORDER_NOVELTY_FIND_BY_ORDER;

  async create(novelty: OrderNovelty): Promise<void> {
    try {
      return await http.VOID_POST(this.API_ORDER_NOVELTY, JSON.stringify(novelty));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async update(novelty: OrderNovelty): Promise<void> {
    try {
      return await http.VOID_PUT(this.API_ORDER_NOVELTY, novelty);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findByOrder(payload: { orderType: string; orderNumber: number }): Promise<OrderNovelty[]> {
    const { orderType, orderNumber } = payload;
    try {
      return await http.get<OrderNovelty[]>(
        `${this.API_ORDER_NOVELTY_FIND_BY_ORDER}?orderType=${orderType}&orderNumber=${orderNumber}`
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async delete(noveltyId: string): Promise<void> {
    try {
      return await http.VOID_DELETE(`${this.API_ORDER_NOVELTY}/${noveltyId}`, null);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
