import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Command } from '@/core/cqrs/Command';
import { Zone } from '@/settings/domain/zone/Zone';
import { ZoneRepository } from '@/settings/domain/zone/ZoneRepository';
@Injectable()
export class CreateZone extends Command<Promise<any>, Zone> {
  public constructor(
    @Inject(TYPES.ZONE_REPOSITORY)
    private readonly zoneRepository: ZoneRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  internalExecute(zone: Zone): Promise<any> {
    return this.zoneRepository.create(zone);
  }
}
