import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { CourierOrderRepository } from '@/courier/domain/CourierOrder/CourierOrderRepository';
import { Query } from '@/core/cqrs/Query';

@Injectable()
export class CalculatePricesCourierOrder extends Query<Promise<any>, any> {
  public constructor(
    @Inject(TYPES.COURIER_ORDER_REPOSITORY)
    private readonly courierOrderRepository: CourierOrderRepository
  ) {
    super();
  }
  async internalExecute(payload: any): Promise<any> {
    return await this.courierOrderRepository.calculatePricing(payload);
  }
}
