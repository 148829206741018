import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Currency } from '@/settings/domain/currency/Currency';
import { CurrencyRepository } from '@/settings/domain/currency/CurrencyRepository';

@Injectable()
export class CurrencyFindAll extends Query<Promise<Currency[]>> {
  public constructor(
    @Inject(TYPES.CURRENCY_REPOSITORY)
    private readonly currencyRepository: CurrencyRepository,

    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(): Promise<Currency[]> {
    const dataCurrencies = await this.currencyRepository.findAll();
    this.stateManager.patch({ dataCurrencies });
    return this.stateManager.state.dataCurrencies;
  }
}
