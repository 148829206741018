
import i18n from '@/core/plugins/i18n';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import AddUnitOfMeasurement from '@/settings/infrastructure/ui/components/unitofmeasurement/AddUnitOfMeasurement.vue';
import { UnitOfMeasurementFindAll } from '@/settings/application/uses_cases/unitofmeasurement/search/UnitOfMeasurementFindAll';
import { UpdateUnitOfMeasurement } from '@/settings/application/uses_cases/unitofmeasurement/update/UpdateUnitOfMeasurement';

@Component({
  name: 'RegisterCurrency',
  components: { AddUnitOfMeasurement }
})
export default class RegisterCurrency extends Vue {
  @Inject(TYPES.FINDALL_UNITOFMEASUREMENT)
  readonly unitOfMeasurementFindAll!: UnitOfMeasurementFindAll;
  @Inject(TYPES.UPDATE_UNITOFMEASUREMENT)
  readonly updateUnitOfMeasurement!: UpdateUnitOfMeasurement;

  //DATA
  target = i18n.t('menu.Unit Of Measurement');
  isLoading = false;
  fullPage = true;
  edit = null;
  unitOfMeasurementList = [];
  modelTypetransport = [] as any;
  typeTransportList = [];
  isTouched = false;
  totalRows = 1;
  currentPage = 1;
  perPage = 5;
  filter = null;
  filterOn = [];
  sortBy = 'description';
  sortDesc = true;
  //REFS
  $refs!: {
    currencyDescription: HTMLInputElement;
  };
  //HOOKS
  created() {
    this.$nextTick(() => this.unitOfMeasurement());
  }
  //COMPUTED
  get rows() {
    return this.unitOfMeasurementList.length;
  }
  get fields() {
    return [
      { key: 'id', label: this.translateCol('id'), sortable: true },
      { key: 'code', label: this.translateCol('code'), sortable: false },
      { key: 'description', label: this.translateCol('description'), sortable: true },
      { key: 'palletsFactor', label: this.translateCol('palletsFactor'), sortable: true },
      { key: 'typetransport', label: this.translateCol('typetransport'), sortable: true },
      { key: 'action', label: this.translateCol('action'), sortable: false }
    ];
  }
  //Methods
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  translateCol(colName: string) {
    return this.$i18n.t('general.' + colName);
  }
  onFiltered(filteredItems: any) {
    // Trigger pagination to update the number of buttons/pages due to filtering
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  }
  findAllUnitOfMeasurement(newValue: any) {
    this.typeTransportList = newValue;
  }
  unitOfMeasurement() {
    this.isLoading = true;
    this.unitOfMeasurementFindAll
      .execute()
      .then((response: any) => {
        this.isLoading = false;
        this.unitOfMeasurementList = response.reverse();
      })
      .catch(error => {
        this.isLoading = false;
        throw new Error(error);
      });
  }
  editData(data: any) {
    this.edit = this.edit !== data.id ? data.id : null;
    if (this.edit === null) {
      const payload: any = {
        id: data.id,
        code: data.code,
        description: data.description,
        factor: data.factor,
        palletFactorId: this.modelTypetransport.code,
        palletFactorName: this.modelTypetransport.description,
        active: true
      };
      this.isLoading = true;
      this.updateUnitOfMeasurement
        .execute(payload)
        .then((response: any) => {
          this.isLoading = false;
          this.unitOfMeasurement();
          return response;
        })
        .catch((error: any) => {
          this.isLoading = false;
          throw new Error(error);
        });
    }
  }
  clearForm() {
    this.unitOfMeasurementList = [];
    this.isTouched = false;
    this.totalRows = 1;
    this.currentPage = 1;
    this.perPage = 5;
    this.filter = null;
    this.filterOn = [];
    this.sortBy = 'description';
    this.sortDesc = true;
  }
}
