
import Vue from 'vue';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';
import { TYPES } from '@/core/config/Types';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { FindDataForReportBillOfLading } from '@/wms/application/billOfLanding/search/FindDataForReportBillfOfLading';
import { BillOfLanding } from '@/wms/domain/billOfLanding/BillOfLanding';
import TransactionalEventForTraceability from '@/wms/domain/preRegister/TransactionalEventForTraceability';
import { PreRegisterForTraceabilityPanel } from '@/wms/domain/preRegister/PreRegisterForTraceabilityPanel';

@Component({
  name: 'WarehouseBillOfLading'
})
export default class WarehouseBillOfLading extends Vue {
  @Inject(TYPES.FINDBYID_COMPANY) private readonly findByIdCompany!: CompanyFindById;
  @Inject(TYPES.BILL_OF_LANDING_FIND_DATA_FOR_REPORT)
  private readonly findDataForReport!: FindDataForReportBillOfLading;
  @Inject()
  @Prop()
  eventData!: TransactionalEventForTraceability | null;
  @Prop() master!: PreRegisterForTraceabilityPanel | null;
  @Prop() form!: BillOfLanding | null;

  logoImg = localStorage.getItem('urlLogoCompany') as string;
  isLoading = false;
  companyInfo = {
    companyName: '',
    companyAdress: '',
    companyPhoneNumber: '',
    companyEmail: '',
    companyZipCode: ''
  };
  cargoDescription = [];

  mounted() {
    // eslint-disable-next-line no-console
    console.log(this.form);
    this.getCompanyInfo();
    this.billOfLadingDataForReport(this.form?.serial);
  }

  async getCompanyInfo() {
    try {
      this.isLoading = true;
      const companyId = localStorage.getItem('businessId') as string;
      const res: any = await this.findByIdCompany.execute(companyId);
      this.isLoading = false;
      this.companyInfo.companyName = res.businessName;
      this.companyInfo.companyAdress = res.address;
      this.companyInfo.companyPhoneNumber = res.phoneNumber;
      this.companyInfo.companyEmail = res.email;
      this.companyInfo.companyZipCode = res.zipCode;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  findBillOfLandingOnActivities() {
    // const billOfLanding = this.searchBillOfLandingOnEvents();
    // if (billOfLanding) {
    //   this.form = billOfLanding;
    // }
  }

  searchBillOfLandingOnEvents() {
    // return this.master?.timeline?.events
    //   .map(event => {
    //     return event.activities;
    //   })
    //   .flat()
    //   .map(activity => {
    //     return activity?.billOfLanding;
    //   })
    //   .find(billOfLanding => {
    //     return billOfLanding;
    //   });
  }

  async billOfLadingDataForReport(code: string | undefined) {
    try {
      if (!code) return;
      const res = await this.findDataForReport.internalExecute(code);
      this.cargoDescription = res;
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
