import { httpFetchNoToken } from '@/core/http/HttpFetchWithoutToken';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { CompanyRepository } from '@/settings/domain/company/CompanyRepository';
import { Company } from '@/settings/domain/company/Company';
import { MailServerConfig } from '@/settings/domain/mailServerConfig/MailServerConfig';
import { http } from '@/core/http/HttpFetch';

@Injectable()
export class CompanyServices implements CompanyRepository {
  private readonly API_BASE_URI: string = process.env.VUE_APP_URL_TOKEN ?? '';
  private readonly API_COMPANY_FINDALL: string =
    this.API_BASE_URI + localStorageModule().auth.routes.API_COMPANY_FINDALL;
  private readonly API_COMPANY_SAVE: string = this.API_BASE_URI + localStorageModule().auth.routes.API_COMPANY_SAVE;
  private readonly API_COMPANY_UPDATE: string = this.API_BASE_URI + localStorageModule().auth.routes.API_COMPANY_UPDATE;
  private readonly API_EMAIL_CONFIG_SAVE: string =
    this.API_BASE_URI + localStorageModule().auth.routes.API_EMAIL_CONFIG_SAVE;
  private readonly API_COMPANY_FIND_BY_ID: string =
    this.API_BASE_URI + localStorageModule().auth.routes.API_COMPANY_FIND_BY_ID_V2;
  private readonly API_EMAIL_CONFIG_FIND_BY_COMPANY: string =
    this.API_BASE_URI + localStorageModule().auth.routes.API_EMAIL_CONFIG_FIND_BY_COMPANY;
  private readonly API_COMPANY: string = this.API_BASE_URI + localStorageModule().auth.routes.API_COMPANY;

  async create(company: Company) {
    try {
      return await httpFetchNoToken.post<Company[]>(this.API_COMPANY, JSON.stringify(company));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(company: Company) {
    try {
      return await http.put<Company[]>(this.API_COMPANY, JSON.stringify(company));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findAll(): Promise<Company[]> {
    try {
      return await httpFetchNoToken.get<Company[]>(this.API_COMPANY_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findCompanyById(companyId: string): Promise<Company> {
    try {
      return await http.get<Company>(this.API_COMPANY_FIND_BY_ID + 'id=' + companyId);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async createEmailConfig(config: MailServerConfig): Promise<any> {
    try {
      return await http.post<MailServerConfig>(this.API_EMAIL_CONFIG_SAVE, JSON.stringify(config));
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async getEmailConfig(company: string): Promise<MailServerConfig> {
    try {
      return await httpFetchNoToken.get<MailServerConfig>(`${this.API_EMAIL_CONFIG_FIND_BY_COMPANY}company=${company}`);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
