import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';

export class References {
  code: string;
  createAt: string;
  description: string;
  filterAge: number;
  filterAlcoholGrade: number;
  filterBrand: string;
  filterCategory: string;
  filterClass: string;
  filterSize: number;
  standardCost: number;
  referenceStatus: string;
  groups: any = [];
  alternativeReferences: any = [];
  barCode: string;
  image: string;
  variety: any;
  consAutoReference: boolean;
  saleTax: number;
  purchaseTax: number;
  noManageStock: boolean;
  isPromotional: boolean;
  msrp: number;
  applyDiscount: boolean;
  showOnQuotation: boolean;
  requestInfo: boolean;
  requestValue: boolean;
  unitCost: number;
  containerInspectionList: any[];
  commodity: CommodityType | null;
  cargoType: string | null;
  mainReferenceSku = '';
  hasSerials: boolean;
  usePriceList: boolean;
  allowBatch: boolean;

  public constructor() {
    this.code = '';
    this.createAt = '';
    this.description = '';
    this.filterAge = 0;
    this.filterAlcoholGrade = 0;
    this.filterBrand = '';
    this.filterCategory = '';
    this.filterClass = '';
    this.filterSize = 0;
    this.standardCost = 0;
    this.referenceStatus = '';
    this.groups = [];
    this.alternativeReferences = [];
    this.barCode = '';
    this.image = '';
    this.variety = {};
    this.consAutoReference = false;
    this.saleTax = 0;
    this.purchaseTax = 0;
    this.noManageStock = false;
    this.msrp = 0;
    this.applyDiscount = false;
    this.showOnQuotation = false;
    this.requestInfo = false;
    this.requestValue = false;
    this.unitCost = 0;
    this.containerInspectionList = [];
    this.isPromotional = false;
    this.commodity = null;
    this.cargoType = null;
    this.hasSerials = false;
    this.usePriceList = false;
    this.allowBatch = false;
  }
}
