import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { OperationRepository } from '@/tracking/domain/typeoperation/OperationRepository';
import { TypeOperation } from '@/settings/domain/typeOperation/TypeOperation';

@Injectable()
export class DeleteOperation extends Query<Promise<TypeOperation>, string> {
  public constructor(
    @Inject(TYPES.TYPE_OPERATION_REPOSITORY)
    private readonly operationRepository: OperationRepository,
    @Inject(TYPES.STATE_MANAGER) private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(code: string): Promise<TypeOperation> {
    return this.operationRepository.delete(code);
  }
}
