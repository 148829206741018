import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { DocumentApplied } from '@/courier/domain/documents/documentApplied/DocumentApplied';
import { DocumentAppliedRepository } from '@/courier/domain/documents/documentApplied/DocumentAppliedRepository';

@Injectable()
export class DocumentAppliedServices implements DocumentAppliedRepository {
  private readonly API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;
  private readonly API_DOCUMENTAPPLIED_SAVE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_DOCUMENTAPPLIED_SAVE;

  async saveDocumentApplied(payload: DocumentApplied): Promise<DocumentApplied[]> {
    try {
      return await http.post<DocumentApplied[]>(this.API_DOCUMENTAPPLIED_SAVE, JSON.stringify(payload));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
