import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import { ContableConcepts } from '@/courier/domain/ContableConcepts/ContableConcepts';
import { Inject } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { ContableConceptsRepository } from '@/courier/domain/ContableConcepts/ContableConceptsRepository';

@Injectable()
export class DeleteContableConcepts extends Command<Promise<ContableConcepts>, number> {
  constructor(
    @Inject(TYPES.CONTABLE_CONCEPTS_REPOSITORY)
    private readonly contableConceptRepository: ContableConceptsRepository
  ) {
    super();
  }

  public async internalExecute(id: number): Promise<ContableConcepts> {
    return await this.contableConceptRepository.delete(id);
  }
}
