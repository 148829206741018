import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Command } from '@/core/cqrs/Command';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Volume } from '@/settings/domain/volume/Volume';
import { VolumeRepository } from '@/settings/domain/volume/VolumeRepository';

@Injectable()
export class UpdateVolume extends Command<Promise<any>, Volume> {
  public constructor(
    @Inject(TYPES.VOLUME_REPOSITORY)
    private readonly volumeRepository: VolumeRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  internalExecute(volume: Volume): Promise<any> {
    return this.volumeRepository.update(volume);
  }
}
