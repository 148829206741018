
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { CreateEventsExec } from '@/tracking/application/uses_cases/events/create/CreateEventsExec';
import i18n from '@/core/plugins/i18n';
import { CreateInvoiceFromOrder } from '@/courier/application/uses_cases/invoice/create/CreateInvoiceFromOrder';
import { OrderFindByDateRange } from '@/courier/application/uses_cases/order/search/OrderFindByDateRange';

@Component({
  name: 'SwitchEvents'
})
export default class AppSwitchEvents extends Vue {
  @Inject(TYPES.CREATE_EXEC_EVENTS) readonly createEventsExec!: CreateEventsExec;
  @Inject(TYPES.CREATE_INVOICE_ORDER) readonly createInvoiceFromOrder!: CreateInvoiceFromOrder;
  @Inject(TYPES.FINDBYDATERANGE_ORDER) readonly orderFindByDateRange!: OrderFindByDateRange;
  //Props
  @Prop() activityCode!: number;
  @Prop() eventCode!: number;
  @Prop() orderNumber!: number;
  @Prop() orderType!: string;
  @Prop() swCode!: string;
  @Prop() swNature!: string;
  @Prop() swTypeOperation!: number;
  @Prop() timelineCode!: number;
  @Prop() eventExec!: boolean;
  @Prop({ type: Function }) funcEventExec!: any;
  //Data
  target = i18n.t('general.sw');
  isLoading = false;
  fullPage = true;
  isExec = false;
  private form: any = {
    consolidationNo: '',
    warehouseCode: '',
    PaymentInvoice: []
  };
  //Methods
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
  saveProcess() {
    let payload: any;
    if (this.swCode === '41') {
      payload = {
        swCode: '41',
        swTypeOperation: 1,
        params: {
          consolidatedType: 'CNS',
          consolidatedNumber: this.form.consolidationNo
        }
      };
    } else if (this.swCode === '11' || this.swCode === '12' || this.swCode === '16') {
      payload = {
        swCode: this.swCode,
        swTypeOperation: 1,
        eventCode: this.eventCode,
        activityCode: this.activityCode,
        orderType: this.orderType,
        orderNumber: this.orderNumber,
        timelineCode: this.timelineCode,
        params: {
          warehouseTarget: this.form.warehouseCode
        }
      };
    } else if (
      this.swCode === '0' ||
      this.swCode === '1' ||
      this.swCode === '42' ||
      this.swCode === '44' ||
      this.swCode === '45'
    ) {
      payload = {
        swCode: this.swCode,
        swTypeOperation: 1,
        eventCode: this.eventCode,
        activityCode: this.activityCode,
        orderType: this.orderType,
        orderNumber: this.orderNumber,
        timelineCode: this.timelineCode
      };
    }
    if (payload) {
      this.isLoading = true;
      this.createEventsExec
        .execute(payload)
        .then((response: any) => {
          this.isLoading = false;
          this.switchShippingByDateRange();
          this.funcEventExec();
          return response;
        })
        .catch((error: any) => {
          this.isLoading = false;
          throw new Error(error);
        });
    }
  }
  switchShippingByDateRange() {
    this.$emit('shippingByDateRangeSwitch');
  }
  close() {
    this.funcEventExec();
    this.clearForm();
  }
  clearForm() {
    this.form.consolidationNo = '';
    this.form.warehouseCode = '';
    this.form.PaymentInvoice = [];
  }
}
