import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { ZipCodeRepository } from '@/settings/domain/ZipCode/ZipCodeRepository';
import { ZipCode } from '@/settings/domain/ZipCode/ZipCode';

@Injectable()
export class SearchByCountryStateAndCityActive extends Query<Promise<ZipCode[]>, any> {
  public constructor(
    @Inject(TYPES.ZIPCODE_REPOSITORY)
    private readonly zipCodeRepository: ZipCodeRepository
  ) {
    super();
  }
  async internalExecute(query: string): Promise<ZipCode[]> {
    return await this.zipCodeRepository.findByCountryStateAndCityActive(query);
  }
}
