import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { CustomerRepository } from '@/courier/domain/customer/CustomerRepository';
import { Customer } from '@/courier/domain/customer/Customer';

@Injectable()
export class CustomerServices implements CustomerRepository {
  private readonly API_BASE_URI: string = localStorageModule().courier.host.API_BASE_URI;
  private readonly API_USER_SAVE: string = this.API_BASE_URI + localStorageModule().courier.routes.API_USER_SAVE;
  private readonly API_USER_UPDATE: string = this.API_BASE_URI + localStorageModule().courier.routes.API_USER_UPDATE;
  private readonly API_CLIENT_UPDATEPASSWORD: string =
    this.API_BASE_URI + localStorageModule().courier.routes.API_CLIENT_UPDATEPASSWORD;
  private readonly API_USER_FINDBYEMAIL: string =
    this.API_BASE_URI + localStorageModule().courier.routes.API_USER_FINDBYEMAIL;
  private readonly API_USER_FINDBYID: string =
    this.API_BASE_URI + localStorageModule().courier.routes.API_USER_FINDBYID;
  private readonly API_CLIENT_GETSECURITYCODE: string =
    this.API_BASE_URI + localStorageModule().courier.routes.API_CLIENT_GETSECURITYCODE;

  async create(user: Customer) {
    try {
      return await http.post<Customer[]>(this.API_USER_SAVE, JSON.stringify(user));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(user: Customer) {
    try {
      return await http.put<Customer[]>(this.API_USER_UPDATE, JSON.stringify(user));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async updatePassword(payload: any) {
    try {
      return await http.put<Customer[]>(
        this.API_CLIENT_UPDATEPASSWORD,
        `password=${payload.password}&securityCode=${payload.securityCode}`
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findById(id: string): Promise<Customer[]> {
    try {
      return await http.get<Customer[]>(this.API_USER_FINDBYID + id);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findByEmail(email: string): Promise<Customer[]> {
    try {
      return await http.get<Customer[]>(this.API_USER_FINDBYEMAIL + email);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async getSecurityCode(email: string): Promise<Customer[]> {
    try {
      return await http.get<Customer[]>(this.API_CLIENT_GETSECURITYCODE + email);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
