import { TYPES } from '@/core/config/Types';
import { Command } from '@/core/cqrs/Command';
import { Injectable } from '@/core/di/Injectable';
import { GroupReferencesRepository } from '@/wms/domain/category/groupReferences/GroupReferencesRepository';
import { Inject } from 'inversify-props';

@Injectable()
export class GroupReferenceValidateCode extends Command<Promise<boolean>, string> {
  public constructor(
    @Inject(TYPES.GROUP_REFERENCE_REPOSITORY)
    private readonly groupReferencesRepository: GroupReferencesRepository
  ) {
    super();
  }

  internalExecute(id: string): Promise<boolean> {
    return this.groupReferencesRepository.validateGroupReferencesCode(id);
  }
}
