import { localStorageModule } from '@/core/config/ConfigurationServer';
import { http } from '@/core/http/HttpFetch';
import { PickingList } from '@/freight/domain/pickingList/PickingList';
import { PickingListForReport } from '@/freight/domain/pickingList/PickingListForReport';
import { PickingListRepository } from '@/freight/domain/pickingList/PickingListRepository';
import { injectable } from 'inversify-props';

@injectable()
export class PickingListService implements PickingListRepository {
  private readonly API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;

  private readonly API_PICKING_LIST_FIND_BY_DATE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_PICKING_LIST_FIND_BY_DATE;
  private readonly API_NO_SHIPPED_PICKING_LIST_NUMBER: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_NO_SHIPPED_PICKING_LIST_NUMBER;
  private readonly API_PICKING_LIST_FOR_REPORT: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_PICKING_LIST_FOR_REPORT;
  private readonly API_PICKING_LIST_FIND_SHIPPED_BY_DATE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_PICKING_LIST_FIND_SHIPPED_BY_DATE;

  async findByDate({
    dateFrom,
    dateTo,
    carrier,
    consignee
  }: {
    dateFrom: string;
    dateTo: string;
    carrier: number | undefined;
    consignee: string | null;
  }): Promise<PickingList[]> {
    try {
      let QUERY = `${this.API_PICKING_LIST_FIND_BY_DATE}?dateFrom=${dateFrom}&dateTo=${dateTo}`;

      if (carrier) {
        QUERY += `&carrier=${carrier}`;
      }

      if (consignee) {
        QUERY += `&consignee=${consignee}`;
      }

      return await http.get<PickingList[]>(QUERY);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async getDataForReport(pickingListId: number): Promise<PickingListForReport> {
    try {
      return await http.get<PickingListForReport>(`${this.API_PICKING_LIST_FOR_REPORT}?number=${pickingListId}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async getNoShippedLists(): Promise<number[]> {
    try {
      return await http.get<number[]>(this.API_NO_SHIPPED_PICKING_LIST_NUMBER);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async getShippedLists({
    dateFrom,
    dateTo,
    carrier,
    consignee
  }: {
    dateFrom: string;
    dateTo: string;
    carrier: number | undefined;
    consignee: string | null;
  }): Promise<PickingList[]> {
    try {
      let QUERY = `${this.API_PICKING_LIST_FIND_SHIPPED_BY_DATE}dateFrom=${dateFrom}&dateTo=${dateTo}`;

      if (carrier) {
        QUERY += `&carrier=${carrier}`;
      }

      if (consignee) {
        QUERY += `&consignee=${consignee}`;
      }

      return await http.get<PickingList[]>(QUERY);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
