import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { Routes } from '@/settings/domain/routes/Routes';
import { RoutesRepository } from '@/settings/domain/routes/RoutesRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { RequestPricelistPayload } from '@/core/helper/RequestPricelistPayload';

@Injectable()
export class RoutesServices implements RoutesRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_ROUTES_FINDALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ROUTES_FINDALL;
  private readonly API_ROUTES_SAVE: string = this.API_BASE_URI + localStorageModule().config.routes.API_ROUTES_SAVE;
  private readonly API_ROUTES_UPDATE: string = this.API_BASE_URI + localStorageModule().config.routes.API_ROUTES_UPDATE;
  private readonly API_ROUTES_FIND_BY_ID: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ROUTES_FIND_BY_ID;
  private readonly API_ROUTES_FIND_BY_STATUS: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ROUTES_FIND_BY_STATUS;
  private readonly API_ROUTES_FIND_BY_ORIGIN_AND_DESTINATION: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ROUTES_FIND_BY_ORIGIN_AND_DESTINATION;

  async create(routes: Routes) {
    return await http.post<Routes[]>(this.API_ROUTES_SAVE, JSON.stringify(routes));
  }
  async update(routes: Routes) {
    return await http.put<Routes[]>(this.API_ROUTES_UPDATE, JSON.stringify(routes));
  }
  async findAll(): Promise<Routes[]> {
    return await http.get<Routes[]>(this.API_ROUTES_FINDALL);
  }
  async findRouteById(routeId: number): Promise<Routes[]> {
    return await http.get<Routes[]>(this.API_ROUTES_FIND_BY_ID + routeId);
  }
  async findRouteByStatus(status: boolean): Promise<Routes[]> {
    return await http.get<Routes[]>(this.API_ROUTES_FIND_BY_STATUS + status);
  }
  async findByOriginAndDestination(payload: RequestPricelistPayload): Promise<Routes> {
    return await http.post<Routes>(this.API_ROUTES_FIND_BY_ORIGIN_AND_DESTINATION, JSON.stringify(payload));
  }
}
