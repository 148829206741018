import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { ZoneL } from '@/wms/domain/layout/zone/ZoneL';
import { ZoneLRepository } from '@/wms/domain/layout/zone/ZoneLRepository';

@Injectable()
export class CreateZoneL extends Command<Promise<any>, ZoneL> {
  public constructor(
    @Inject(TYPES.LAYOUT_ZONE_REPOSITORY)
    private readonly zoneRepository: ZoneLRepository
  ) {
    super();
  }

  internalExecute(zone: ZoneL): Promise<any> {
    return this.zoneRepository.create(zone);
  }
}
