
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapActions, mapGetters } from 'vuex';
import LanguageSwitcher from '@/core/components/LanguageSwitcher.vue';
import MenuStore from '@/core/store/modules/Menu';
import { Watch } from 'vue-property-decorator';
import { destroyToken } from '@/core/plugins/FunctionUtil';
import { toogleFullScreen } from '@/core/plugins/FullScreen';
import Customizer from '@/core/components/dashboard/Customizer.vue';
import { FindFile } from '@/settings/application/uses_cases/file/search/FindFile';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { UserFindByEmail } from '@/settings/application/uses_cases/user/search/UserFindByEmail';
import { User } from '@/settings/domain/user/User';

const body = document.getElementsByTagName('body')[0];
@Component({
  name: 'Header',
  components: {
    Customizer,
    LanguageSwitcher
  },
  computed: {
    ...mapGetters('Login', ['loggedIn'])
  },
  methods: {
    ...mapActions('Login', ['destroyToken'])
  }
})
export default class Header extends Vue {
  @Inject(TYPES.FIND_FILE_BY_NAME)
  readonly findFileByName!: FindFile;
  @Inject(TYPES.USER_FIND_BY_EMAIL)
  private readonly findUserInfo!: UserFindByEmail;
  //Data
  username = localStorage.getItem('userNameCompany') || '';
  userEmail = localStorage.getItem('userEmail') || '';
  user: User | null = null;
  fileURL = '';
  terms = '';
  searchResult = false;
  searchResultEmpty = false;
  toggleSidebarVar = false;
  clicked = false;
  mobile_toggle = false;
  mobile_search = false;
  @Watch('menuItems')
  menuItemsWatch() {
    this.terms ? this.addFix() : this.removeFix();
    if (!this.menuItems.length) {
      this.searchResultEmpty = true;
    } else {
      this.searchResultEmpty = false;
    }
  }

  mounted() {
    this.findInfo();
  }
  //Property Computed
  get menuItems() {
    return MenuStore.searchData;
  }
  get logo() {
    return process.env.VUE_APP_URL_TOKEN + '/' + localStorage.getItem('logoUser');
  }
  get logoCompany() {
    return localStorage.getItem('urlLogoCompany');
  }

  get contableLink() {
    return process.env.VUE_APP_URI_CONATBLE;
  }

  //Methods
  searchTerm(): void {
    this.$store.dispatch('Menu/searchTermAct', this.terms);
  }
  async logout() {
    this.$store.dispatch('Menu/removeMenu');
    await MenuStore.getMenu();
    destroyToken();
  }
  addFix(): void {
    body.classList.add('offcanvas');
    this.searchResult = true;
  }
  removeFix(): void {
    body.classList.remove('offcanvas');
    this.searchResult = false;
    this.terms = '';
  }
  toggleSidebar(): void {
    this.toggleSidebarVar = !this.toggleSidebarVar;
    this.clicked = !this.toggleSidebarVar;
    this.$emit('clicked', this.clicked);
  }
  toggleFullscreen(): void {
    toogleFullScreen();
  }

  goToContable() {
    this.$router.push({ path: '/contable' });
  }

  goToProfile() {
    this.$router.push({ path: '/settings/profile' });
  }
  async findInfo() {
    try {
      if (!this.userEmail) {
        return;
      }
      this.user = await this.findUserInfo.internalExecute(this.userEmail);
      this.fileURL = this.findFileByName.internalExecute(this.user?.image);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }
}
