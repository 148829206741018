import { References } from '@/wms/domain/catalogs/references/References';
import { GroupReferences } from '@/wms/domain/category/groupReferences/GroupReferences';

export class WmsfOrderReferences {
  number: number;
  typeOrder: string;
  category: GroupReferences | null;
  reference: References | null;
  minimum: number;
  rate: number;
  chargingBasis: string;
  price: number;
  type: string;
  additionalInformation: string;
  active: boolean;

  public constructor() {
    this.number = 0;
    this.typeOrder = '';
    this.type = '';
    this.category = null;
    this.reference = null;
    this.minimum = 0;
    this.rate = 0;
    this.chargingBasis = '';
    this.price = 0;
    this.additionalInformation = '';
    this.active = true;
  }
}
