import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Command } from '@/core/cqrs/Command';
import { ZoneLevel } from '@/settings/domain/ZoneLevel/ZoneLevel';
import { ZoneLevelRepository } from '@/settings/domain/ZoneLevel/ZoneLevelRepository';

@Injectable()
export class DeleteZoneLevel extends Command<Promise<any>, ZoneLevel> {
  public constructor(
    @Inject(TYPES.PRICING_ZONE_LEVEL_REPOSITORY)
    private readonly zoneLevelRepository: ZoneLevelRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  internalExecute(zoneLevel: ZoneLevel): Promise<ZoneLevel> {
    return this.zoneLevelRepository.delete(zoneLevel);
  }
}
