
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { WmsQuotationOrder } from '@/courier/domain/wmsQuotationOrder/WmsQuotationOrder';
import { CreateQuotation } from '@/courier/application/uses_cases/order/create/CreateQuotation';
import FreightQuotationForm from './form/FreightQuotationForm.vue';
import { OrderType } from '@/courier/domain/CourierOrder/OrderTypeEnum';
import { WmsOrderFindByPk } from '@/courier/application/uses_cases/order/search/WmsOrderFindByPk';
import { UpdateQuotation } from '@/courier/application/uses_cases/order/update/UpdateQuotation';
@Component({
  name: 'FreightRegisterCenter',
  components: { FreightQuotationForm }
})
export default class FreightRegisterCenter extends Vue {
  @Inject(TYPES.QUOTATIONS_SAVE)
  readonly saveQuotation!: CreateQuotation;
  @Inject(TYPES.WMS_ORDER_FIND_BY_PK)
  readonly findWmsOrderByPk!: WmsOrderFindByPk;
  @Inject(TYPES.QUOTATIONS_UPDATE)
  readonly updateQuotation!: UpdateQuotation;
  //DATOS
  isLoading = false;
  //FORMULARIO
  form: WmsQuotationOrder = new WmsQuotationOrder();

  //OBJETO DE ACCIONES
  actions = {
    save: this.factory,
    clear: this.clearForm
  };

  mounted() {
    'type' in this.urlParams.query ? this.loadRequest() : this.clearForm();
  }

  //Getter para obtener los parametros de la URL
  get urlParams() {
    return {
      query: this.$route.query
    };
  }

  //Funcion invocada para guardar la solicitud
  async save() {
    try {
      this.isLoading = true;
      await this.saveQuotation.execute(this.form);
      if (!('error' in this.form)) {
        this.clearForm();
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion para limpiar el formulario
  clearForm() {
    this.form = new WmsQuotationOrder();
    this.form.module = 'carga_f';
    this.form.typeOrder = OrderType.freightRequest;
  }

  //Metodo usado para obtener los detalles de cada documento
  async loadRequest() {
    try {
      this.isLoading = true;
      const res = await this.findWmsOrderByPk.execute({
        number: this.urlParams.query.number,
        type: this.urlParams.query.type
      });
      this.form = Object.assign(new WmsQuotationOrder(), res);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async update() {
    try {
      this.isLoading = true;
      const res = await this.updateQuotation.execute(this.form);
      if ('number' in res) {
        this.clearForm();
        this.$router.push({ name: 'Requests' });
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  factory() {
    this.form.number != 0 ? this.update() : this.save();
  }
}
