import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { States } from '@/settings/domain/state/States';
import { StateRepository } from '@/settings/domain/state/StateRepository';

@Injectable()
export class StateFindByCountryCode extends Query<Promise<States[]>, string> {
  public constructor(
    @Inject(TYPES.STATE_REPOSITORY)
    private readonly stateRepository: StateRepository,

    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(countryCode: string): Promise<States[]> {
    const dataStates = await this.stateRepository.findStateByCountryCode(countryCode);
    this.stateManager.patch({ dataStates });
    return this.stateManager.state.dataStates;
  }
}
