import { OrderLine } from '@/courier/domain/orderline/OrderLine';
import { PriceList } from '@/settings/domain/priceList/PriceList';
import { CalcResponse } from './CalcResponse';

export class PriceWeightCalculate {
  calculate(priceList: PriceList, packages: Array<OrderLine>): CalcResponse {
    let totalWeight = 0.0;
    let rate = 0.0;
    let unitValue = 0.0;
    //flags
    const isPriceListByWeight = true;

    //Calculate total packages
    packages.forEach(element => {
      totalWeight += Number(element['weight']);
    });

    //Calc. rate
    unitValue = priceList['unitValue'];
    rate = totalWeight * unitValue;

    //Validate min rate.
    if (rate < priceList['minRate']) {
      rate = priceList['minRate'];
    }

    const calcResponse = new CalcResponse();
    //Taxes calc.
    const base = rate;
    priceList['taxes'].forEach(element => {
      const percentaje = element['taxPercentage'] / 100;
      calcResponse.taxes.push({
        taxName: element['taxName'],
        taxValue: (base * percentaje).toFixed(2),
        taxPercentage: element['taxPercentage']
      });
      rate = rate * (1 + percentaje);
    });
    //Response
    calcResponse.transportName = priceList['transportName'];
    calcResponse.transportId = priceList['transportId'];
    calcResponse.routeId = priceList['routeId'];
    calcResponse.timeLineId = priceList['timeline'];
    calcResponse.rateValue = Number(rate.toFixed(2));
    calcResponse.isValidPriceList = true;
    calcResponse.isVolumePriceList = !isPriceListByWeight;
    calcResponse.totalWeight = totalWeight;
    calcResponse.subTotal = base;
    return calcResponse;
  }
}
