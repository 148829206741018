import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { City } from '@/settings/domain/city/City';
import { CityRepository } from '@/settings/domain/city/CityRepository';

@Injectable()
export class CityService implements CityRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_TERRITORIES_BASE: string = process.env.VUE_APP_API_TERRITORIES ?? '';
  private readonly API_CITY_CREATE: string = this.API_BASE_URI + localStorageModule().config.routes.API_CITY_CREATE;
  private readonly API_CITY_UPDATE: string = this.API_BASE_URI + localStorageModule().config.routes.API_CITY_UPDATE;
  private readonly API_CITY_DELETE: string = this.API_BASE_URI + localStorageModule().config.routes.API_CITY_DELETE;
  private readonly API_CITY_SEARCH_BY_COUNTRY_AND_STATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_CITY_SEARCH_BY_COUNTRY_AND_STATE;
  private readonly API_CITY_SEARCH_BY_DESCRIPTION_LIKE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_CITY_SEARCH_BY_DESCRIPTION_LIKE;
  private readonly API_FIND_CITIES_BY_STATE_AND_QUERY: string =
    this.API_TERRITORIES_BASE + localStorageModule().config.routes.API_FIND_CITIES_BY_STATE_AND_QUERY;

  async create(city: City): Promise<any> {
    return await http.post<City>(this.API_CITY_CREATE, city);
  }

  async update(city: City): Promise<any> {
    return await http.put<City>(this.API_CITY_UPDATE, city);
  }

  async delete(payload: any): Promise<any> {
    return await http.singleDelete<City>(
      `${this.API_CITY_DELETE} + ${payload.country} + '/' + ${payload.state} + '/' + ${payload.city}`
    );
  }

  async searchCityByDescriptionLike(query: string): Promise<City[]> {
    return await http.get<City[]>(this.API_CITY_SEARCH_BY_DESCRIPTION_LIKE + query);
  }

  async searchCityByCountryAndState(payload: any): Promise<City[]> {
    return await http.get<City[]>(
      `${this.API_CITY_SEARCH_BY_COUNTRY_AND_STATE}country=${payload.country}&state=${payload.state}&active=${payload.active}`
    );
  }

  async searchCityByCountryStateAndDescription(payload: any): Promise<City[]> {
    try {
      return await http.get<City[]>(
        `${this.API_FIND_CITIES_BY_STATE_AND_QUERY}countryCode=${payload.country}&stateCode=${payload.state}&query=${payload.query}`
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
