import { Injectable } from '@/core/di/Injectable';
import { PriceList } from '@/settings/domain/priceList/PriceList';
import { http } from '@/core/http/HttpFetch';
import { PriceListRepositoryWeight } from '@/settings/domain/priceList/PriceListRepositoryWeight';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class PriceListWeightServices implements PriceListRepositoryWeight {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_PRICE_LIST_WEIGHT_FINDALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PRICE_LIST_WEIGHT_FINDALL;
  private readonly API_PRICE_LIST_WEIGHT_FINDALL_BY_STATUS: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PRICE_LIST_WEIGHT_FINDALL_BY_STATUS;
  private readonly API_PRICE_LIST_WEIGHT_SAVE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PRICE_LIST_WEIGHT_SAVE;
  private readonly API_PRICE_LIST_WEIGHT_UPDATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PRICE_LIST_WEIGHT_UPDATE;
  private readonly API_PRICE_LIST_WEIGHT_FINDBYORIGINANDDESTINATION: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PRICE_LIST_WEIGHT_FINDBYORIGINANDDESTINATION;
  private readonly API_PRICE_LIST_WEIGHT_IMPORT: string = this.API_BASE_URI + 'EN CONSTRUCCION';

  async create(priceList: PriceList) {
    try {
      return await http.post<PriceList[]>(this.API_PRICE_LIST_WEIGHT_SAVE, JSON.stringify(priceList));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(priceList: PriceList) {
    try {
      return await http.put<PriceList[]>(this.API_PRICE_LIST_WEIGHT_UPDATE, JSON.stringify(priceList));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findAlByStatus(status: boolean): Promise<PriceList[]> {
    try {
      return await http.get<PriceList[]>(this.API_PRICE_LIST_WEIGHT_FINDALL_BY_STATUS + status);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findByOriginAndDestination(payload: any): Promise<PriceList[]> {
    try {
      let zoneDest = '';
      let zoneOrig = '';
      let volumeUnit = '';
      let weightUnit = '';
      let transport = '';
      if (payload.zoneZcDestination) {
        zoneDest = `&destZoneZc=${payload.zoneZcDestination}`;
      }
      if (payload.zoneZcOrigin) {
        zoneOrig = `&originZoneZc=${payload.zoneZcOrigin}`;
      }
      if (payload.volumeUnit) {
        volumeUnit = `&volumeUnit=${payload.volumeUnit}`;
      }
      if (payload.weightUnit) {
        weightUnit = `&weightUnit=${payload.weightUnit}`;
      }
      if (payload.transport) {
        transport = `&transport=${payload.transport}`;
      }
      return await http.get<PriceList[]>(
        this.API_PRICE_LIST_WEIGHT_FINDBYORIGINANDDESTINATION +
          `destCountry=${payload.countryDestination}&destState=${payload.stateDestination}&destCity=${payload.destCity}${zoneDest}&originCountry=${payload.countryOrigin}&originState=${payload.stateOrigin}&originCity=${payload.originCity}${zoneOrig}${volumeUnit}${weightUnit}${transport}`
      );
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findAll(): Promise<PriceList[]> {
    try {
      return await http.get<PriceList[]>(this.API_PRICE_LIST_WEIGHT_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async importCSV(formData: FormData): Promise<boolean> {
    try {
      return await http.postFormData<boolean>(this.API_PRICE_LIST_WEIGHT_IMPORT, formData);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
