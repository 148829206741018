
import Vue from 'vue';
import Component from 'vue-class-component';
import LayoutStore from '@/core/store/modules/Layout';

@Component({
  name: 'Customizer'
})
export default class Customizer extends Vue {
  customizer = '';
  sidebarSetting = 'color';
  layoutType = 'ltr';
  sidebarType = 'default';
  mixLayout = 'default';
  styleObject = {
    position: 'fixed',
    left: '0',
    top: '0',
    opacity: '0'
  };
  lightColor = '';

  //Refs
  $refs!: {
    layout: HTMLFormElement;
  };
  //Hook
  mounted() {
    this.openCustomizerSetting('settings');
    this.layout.color.color = localStorage.getItem('colorCompany') || '';
    this.layout.color.primaryColor = localStorage.getItem('primaryColorCompany') || '';
    this.layout.color.secondaryColor = localStorage.getItem('secondaryColorCompany') || '';
  }
  //Property Computed
  get layout() {
    return LayoutStore.layout;
  }
  get dataLayout() {
    return JSON.stringify(LayoutStore.layout);
  }
  //method
  openCustomizerSetting(val: string) {
    this.customizer = val;
  }
  customizerSetting(val: string) {
    this.sidebarSetting = val;
  }
  customizeLayoutType(val: string) {
    this.$store.dispatch('Layout/setLayoutTypeAct', val);
    this.layoutType = val;
  }
  customizeSidebarType(val: string) {
    this.$store.dispatch('Layout/setCustomizeSidebarTypeAct', val);
  }
  customizeSidebarSetting(val: string) {
    this.layout.settings.sidebarSetting = val;
  }
  customizeSidebarBackround(val: string) {
    this.layout.settings.sidebarBackround = val;
  }
  customizeMixLayout(val: string) {
    this.mixLayout = val;
    this.$store.dispatch('Layout/setLayoutAct', val);
  }
  customizeLightColorScheme(val: string) {
    this.lightColor = val;
    this.$store.dispatch('Layout/setColorLightSchemeAct', val);
  }
  customizeDarkColorScheme(val: string) {
    this.$store.dispatch('Layout/setColorDarkSchemeAct', val);
  }
  copyText() {
    this.$refs.layout.select();
    document.execCommand('copy');
    this.$toasted.show('Code Copied to clipboard', {
      theme: 'outline',
      position: 'top-right',
      type: 'default',
      duration: 2000
    });
  }
}
