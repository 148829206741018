import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Stock } from '@/wms/domain/stock/Stock';
import { StockRepository } from '@/wms/domain/stock/StockRepository';

@Injectable()
export class FindFreightStockByDateRangeAndStore extends Query<
  Promise<Stock[]>,
  {
    fMonth: string;
    iMonth: string;
    serial: string;
    store: string;
    year: string;
    entity: string;
  }
> {
  public constructor(
    @Inject(TYPES.STOCK_REPOSITORY)
    private readonly stockRepository: StockRepository
  ) {
    super();
  }
  async internalExecute(payload: {
    fMonth: string;
    iMonth: string;
    serial: string;
    store: string;
    year: string;
    entity: string;
  }): Promise<Stock[]> {
    return await this.stockRepository.findFreigthByDateRangeAndStore(payload);
  }
}
