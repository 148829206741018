
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Territories } from '@/settings/domain/views/territories/Territories';
@Component({
  name: 'AddRoute'
})
export default class AddRoute extends Vue {
  //PROPS
  @Prop() form!: any;
  @Prop() readonly formFunctions!: any;
  @Prop() readonly buttonOptions!: any;
  @Prop() readonly originList!: Territories[];
  @Prop() readonly destinationList!: Territories[];
  @Prop() readonly findTerritriesFromView!: Function;
}
