<script>
import { Line } from 'vue-chartjs';

const primary = '#4466f2';
const secondary = '#72f244';
const third = '#e30e0e';
const colors = [primary, secondary, third];
export default {
  extends: Line,
  props: {
    axisy: {
      type: Array,
      default: () => [-500, -2000, -1000, -500, -1000, -800, -700]
    },
    axisy2: {
      type: Array,
      default: () => [0, 0, 0, 0, 0, 0, 0]
    },
    axish: {
      type: Array,
      default: () => [1, 3, 6, 9, 12, 15, 17]
    },
    color: {
      type: Number,
      default: () => [1]
    },
    labelDescription: {
      type: Array,
      default: () => ['No Description Found']
    },
    minrango: {
      type: Number,
      default: () => 0
    },
    maxrango: {
      type: Number,
      default: () => 1000
    }
  },
  data() {
    return {
      linechart: {
        labels: this.axish,
        datasets: [
          {
            fill: true,
            backgroundColor: colors[this.color] + '10',
            borderColor: colors[this.color],
            borderWidth: 2.5,
            pointBackgroundColor: colors[this.color],
            pointBorderColor: colors[this.color],
            data: this.axisy
          },
          {
            fill: true,
            backgroundColor: colors[this.color] + '40',
            borderColor: colors[this.color] + '40',
            borderWidth: 2.5,
            pointBackgroundColor: colors[this.color] + '40',
            pointBorderColor: colors[this.color] + '40',
            data: this.axisy2
          }
        ]
      },
      options: {
        legend: {
          display: false
        },
        height: 10,
        responsive: true,
        animation: false,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                color: 'transparent',
                drawTicks: true
              }
            }
          ],
          yAxes: [
            {
              display: true,
              ticks: {
                min: this.minRango,
                max: this.maxRango,
                stepSize: 100,
                reverse: false,
                beginAtZero: true
              }
            }
          ]
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: tooltipItems => {
              return '$' + tooltipItems.yLabel + ' | ' + this.labelDescription[tooltipItems.index].description;
            }
          },
          bodyFontSize: 10,
          bodySpacing: 0
        }
      },
      legend: false
    };
  },
  mounted() {
    this.renderChart(this.linechart, this.options);
  }
};
</script>

<style></style>
