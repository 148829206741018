import { Injectable } from '@/core/di/Injectable';
import { TYPES } from '@/core/config/Types';
import { Inject } from '@/core/di/Inject';
import { Command } from '@/core/cqrs/Command';
import { Rack } from '@/wms/domain/layout/rack/Rack';
import { RackRepository } from '@/wms/domain/layout/rack/RackRepository';

@Injectable()
export class DeleteRackLayoutConfig extends Command<Promise<any>, Rack> {
  public constructor(
    @Inject(TYPES.RACKS_REPOSITORY)
    private readonly repository: RackRepository
  ) {
    super();
  }

  async internalExecute(rack: Rack): Promise<Rack> {
    return await this.repository.deleteRack(rack);
  }
}
