import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { UnitOfMeasurement } from '@/settings/domain/unitofmeasurement/UnitOfMeasurement';
import { UnitOfMeasurementRepository } from '@/settings/domain/unitofmeasurement/UnitOfMeasurementRepository';

@Injectable()
export class UnitOfMeasurementFindAll extends Query<Promise<UnitOfMeasurement[]>> {
  public constructor(
    @Inject(TYPES.UNITOFMEASUREMENT_REPOSITORY)
    private readonly unitOfMeasurementRepository: UnitOfMeasurementRepository,
    @Inject(TYPES.STATE_MANAGER) private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(): Promise<UnitOfMeasurement[]> {
    const dataUnitOfMeasurement = await this.unitOfMeasurementRepository.findAll();
    this.stateManager.patch({ dataUnitOfMeasurement });
    return this.stateManager.state.dataUnitOfMeasurement;
  }
}
