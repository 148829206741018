
import { Company } from '@/settings/domain/company/Company';
import { ValidationObserver } from 'vee-validate';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'CreateCompany',
  components: {}
})
export default class CreateCompany extends Vue {
  @Prop({ required: true }) form!: Company;
  @Prop({ required: true }) save!: () => void;

  $refs!: {
    companyForm: InstanceType<typeof ValidationObserver>;
  };

  async saveCompany(e: Event) {
    e.preventDefault();
    if (!(await this.$refs.companyForm.validate())) return;

    this?.save();
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
}
