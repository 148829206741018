
import { TYPES } from '@/core/config/Types';
import { OrderType } from '@/courier/domain/CourierOrder/OrderTypeEnum';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { WmsOrderFindAllByModule } from '@/courier/application/uses_cases/order/search/WmsOrderFindAllByModule';
import FreightQuotationForm from '../form/FreightQuotationForm.vue';
import { WmsQuotationOrder } from '@/courier/domain/wmsQuotationOrder/WmsQuotationOrder';
import { WmsOrderFindByPk } from '@/courier/application/uses_cases/order/search/WmsOrderFindByPk';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { UpdateQuotation } from '@/courier/application/uses_cases/order/update/UpdateQuotation';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import { getFormattedDate } from '@/core/plugins/DateFormatter';
import QuotationTemplate from '../form/addCargoDetails/document/QuotationTemplate.vue';
import NoveltyList from '@/wms/infrastructure/ui/warehousePreRegister/components/NoveltyList.vue';
import { FindWmsQuotationsByDateRangeAndType } from '@/courier/application/uses_cases/wmsQuotationOrder/search/FindWmsQuotationsByDateRangeAndType';
import { WmsQuotationOrderForTable } from '@/courier/domain/wmsQuotationOrder/WmsQuotationOrderForTable';
import * as moment from 'moment';
import { FindByTypeAndNumberOrderNovelty } from '@/tracking/application/uses_cases/orderNovelty/search/FindByOrderNovelty';
import { OrderNovelty } from '@/tracking/domain/orderNovelty/OrderNovelty';

@Component({
  name: 'FreightList',
  components: { CustomTableN, FreightQuotationForm, QuotationTemplate, NoveltyList }
})
export default class FreightList extends Vue {
  @Inject(TYPES.QUOTATIONS_FIND_ALL_BY_TYPE_AND_MODULE)
  readonly findOrdersByModule!: WmsOrderFindAllByModule;
  @Inject(TYPES.WMS_ORDER_FIND_BY_PK)
  readonly findWmsOrderByPk!: WmsOrderFindByPk;
  @Inject(TYPES.QUOTATIONS_UPDATE)
  readonly updateQuotation!: UpdateQuotation;
  @Inject(TYPES.WMS_ORDER_FIND_BY_DATE_RANGE_AND_TYPE)
  readonly findOrdersByDateRangeAndType!: FindWmsQuotationsByDateRangeAndType;
  @Inject(TYPES.ORDER_NOVELTY_FIND_BY_ORDER)
  readonly findNoveltyByOrder!: FindByTypeAndNumberOrderNovelty;

  //Datos
  isLoading = false;
  forView = false;
  componentType = `quotation`;
  //Form
  form: WmsQuotationOrder = new WmsQuotationOrder();
  //Listas
  operationList: any[] = [];
  //Referencias
  //Refs
  $refs!: {
    quotationModal: HTMLFormElement;
  };

  novelties: OrderNovelty[] = [];
  selectedQuotation: WmsQuotationOrderForTable | null = null;

  quotationsTypes = [
    { text: this.$t('general.requests'), value: OrderType.freightRequest },
    { text: this.$t('general.quotations'), value: OrderType.freightquotation }
  ];

  //Selects
  filter: {
    type: OrderType | null;
    query: string;
    from: string;
    to: string;
  } = {
    type: null,
    from: new Date().toISOString().split('T')[0],
    to: new Date().toISOString().split('T')[0],
    query: ''
  };
  //Objeto de acciones
  actions = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: this.edit,
        disabled: (row: WmsQuotationOrderForTable) => row.state == 'disabled'
      },
      {
        title: `${this.$t('general.details')}`,
        icon: 'fa fa-eye',
        variant: 'warning',
        action: this.showDetails
      },
      {
        title: `${this.$t('general.addQuotation')}`,
        icon: 'fa fa-plus-circle',
        variant: 'success',
        action: this.addQuotation,
        conditionalRender: this.disableForQuotations,
        disabled: (row: WmsQuotationOrderForTable) => row.state == 'disabled'
      },
      {
        title: `${this.$t('general.seeDocument')}`,
        icon: 'fa fa-file',
        action: this.seeDocument,
        variant: 'primary',
        disabled: (row: WmsQuotationOrderForTable) => row.state == 'disabled'
      },
      {
        title: `${this.$t('general.approveQuotation')}`,
        icon: 'fa fa-check',
        action: this.approve,
        variant: 'info',
        conditionalRender: this.disableForRequest
      },
      {
        title: `${this.$t('general.add novelty')}`,
        icon: 'fa fa-list',
        action: this.addNovelty,
        variant: 'info'
      },
      {
        title: `${this.$t('general.disable')}`,
        icon: 'fa fa-close',
        action: this.delete,
        variant: 'danger',
        disabled: (row: WmsQuotationOrderForTable) => row.state == 'disabled'
      }
    ]
  };
  mainActions = {
    clear: this.clearForm,
    save: this.saveQuotation
  };

  //Getter para obtener los campos de la tabla
  get fields() {
    return [
      {
        field: 'number',
        label: 'ID',
        sortable: true
      },
      {
        field: 'customer',
        label: `${this.$t('general.name')}`,
        sortable: true,
        formatFn: (item: Entity) => `${item?.fullName}`
      },
      {
        field: 'requestDate',
        label: `${this.$t('general.requestDate')}`,
        sortable: true,
        formatFn: (date: string) => (date != null ? getFormattedDate(new Date(date)) : '-')
      },
      {
        field: 'quotationDate',
        label: `${this.$t('general.quotationDate')}`,
        sortable: true,
        formatFn: (date: string) => (date != null ? getFormattedDate(new Date(date)) : '-')
      },
      {
        field: 'approvalDate',
        label: `${this.$t('general.approvalDate')}`,
        sortable: true,
        formatFn: (date: string) => (date != null ? getFormattedDate(new Date(date)) : '-')
      },
      {
        field: (row: WmsQuotationOrder) =>
          typeof row.approvalDate == 'string' ? this.$t('general.yes') : this.$t('general.no'),
        label: `${this.$t('general.approved')}`,
        sortable: true
      },
      {
        field: 'state',
        label: `${this.$t('general.status')}`,
        sortable: true,
        formatFn: (value: boolean) => this.$t(`general.${value}`)
      },
      {
        field: 'noveltyDescription',
        label: `${this.$t('general.novelty')}`,
        sortable: false,
        formatFn: (value: string | null) => value ?? '-',
        tdClass: (row: WmsQuotationOrderForTable) => (row.noveltyDueDate ? 'text-danger font-weight-bold' : '')
      },
      {
        field: 'noveltyDueDate',
        label: `${this.$t('general.noveltyDueDate')}`,
        sortable: false,
        formatFn: (value: string | null) => (value ? moment.utc(value).format('LLL') : '-'),
        tdClass: (row: WmsQuotationOrderForTable) => (row.noveltyDueDate ? 'text-danger font-weight-bold' : '')
      },
      {
        field: 'actions',
        label: `${this.$t('general.actions')}`
      }
    ];
  }

  //Formateadores

  //Getter oara obtener las opciones de filtrado
  get filterOptions() {
    return [
      {
        description: this.$t('general.entity'),
        code: 'entity'
      },
      {
        description: this.$t('general.date'),
        code: 'date'
      }
    ];
  }

  get moment() {
    return moment;
  }

  //Metodo usado para obtener todas las órdenes por módulo y tipo
  async findAll() {
    try {
      this.isLoading = true;
      const res = await this.findOrdersByModule.execute({ orderType: OrderType.freightRequest, module: 'carga_f' });
      this.operationList = res.length > 0 ? res.reverse() : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Metodo usado para obtener todas las cotizaciones por módulo y tipo
  async findAllQuotations() {
    try {
      this.isLoading = true;
      const res = await this.findOrdersByModule.execute({ orderType: OrderType.freightquotation, module: 'carga_f' });
      this.operationList = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Metodo usado para obtener los detalles de cada documento
  async showDetails(item: WmsQuotationOrderForTable) {
    try {
      this.isLoading = true;
      this.componentType = item.type.includes(OrderType.freightquotation) ? `quotation` : `request`;
      const res = await this.findWmsOrderByPk.execute({
        number: item.number,
        type: item.type
      });
      this.form = res;
      this.form.requestDate = this.form.requestDate ? this.form.requestDate.split('T')[0] : '';
      this.form.quotationDate = this.form.quotationDate ? this.form.quotationDate.split('T')[0] : '';
      this.isLoading = false;
      this.$refs.quotationModal.show();
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
    this.forView = true;
  }

  async loadQuotation(item: WmsQuotationOrderForTable) {
    if (item.type.includes(OrderType.freightquotation)) {
      this.$swal({
        title: `${this.$t('general.info')}`,
        text: `${this.$t('general.cantCreatedQuotation')}`,
        icon: 'info'
      });
      return;
    }
    try {
      this.isLoading = true;
      this.componentType = item.type.includes(OrderType.freightquotation) ? `quotation` : `request`;
      const res = await this.findWmsOrderByPk.execute({
        number: item.number,
        type: item.type
      });
      this.form = res;
      this.form.requestDate = this.form.requestDate ? this.form.requestDate.split('T')[0] : '';
      this.form.quotationDate = this.form.quotationDate ? this.form.quotationDate.split('T')[0] : '';
      this.isLoading = false;
      this.$refs.quotationModal.show();
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  addQuotation(input?: WmsQuotationOrderForTable) {
    this.$router.push({
      name: 'Quotation',
      query: input && { type: input.type, number: input.number.toString() }
    });
  }

  saveQuotation() {
    throw new Error('Aun no esta implementado');
  }

  //Metodo usado para obtener los detalles de cada documento
  async showDetailsQuotation(item: any) {
    try {
      this.isLoading = true;
      this.componentType = item.typeOrder.includes(OrderType.freightquotation) ? `quotation` : `request`;
      const res = await this.findWmsOrderByPk.internalExecute({
        number: item.number,
        type: item.typeOrder
      });
      this.form = res;
      this.form.requestDate = this.form.requestDate ? this.form.requestDate.split('T')[0] : '';
      this.form.quotationDate = this.form.quotationDate ? this.form.quotationDate.split('T')[0] : '';
      this.isLoading = false;
      this.$refs.quotationModal.show();
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  //Funcion para limpiar el formulario
  clearForm() {
    this.form = new WmsQuotationOrder();
  }

  //Funcion invocada para enviar a pantalla de embarque
  toShipment(input: WmsQuotationOrderForTable) {
    this.$router.push({ name: 'Shipment', query: { type: input.type, number: input.number.toString() } });
  }

  //Funcion invocada para deshabilitar o habilitar un botón
  disableForQuotations(item: WmsQuotationOrderForTable) {
    return item.type != OrderType.freightRequest;
  }

  //Funcion invocada para deshabilitar o habilitar un botón
  disableForRequest(item: WmsQuotationOrderForTable) {
    return item.type != OrderType.freightquotation;
  }

  //Funcion invocada para inactivar una cotizacion
  async delete(item: WmsQuotationOrderForTable) {
    try {
      this.isLoading = true;
      const res = await this.findWmsOrderByPk.execute({
        number: item.number,
        type: item.type
      });
      this.form = res;
      this.form.customer = Object.assign(new Entity(), res.customer);
      this.form.state = 'disabled';
      this.form.module = 'carga_f';
      this.isLoading = false;
      await this.updateQuotation.execute(this.form);
      this.findData();
      this.clearForm();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para aprobar una cotizacion
  async approve(item: WmsQuotationOrderForTable) {
    try {
      if (item.state == 'disabled') return;
      this.isLoading = true;
      const res = await this.findWmsOrderByPk.execute({
        number: item.number,
        type: item.type
      });
      this.form = res;
      this.form.customer = Object.assign(new Entity(), res.customer);
      this.form.approvalDate = new Date().toISOString().slice(0, 19);
      this.form.state = 'approved';
      this.form.module = 'carga_f';
      this.isLoading = false;
      await this.updateQuotation.execute(this.form);
      this.findData();
      this.clearForm();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  edit(item: WmsQuotationOrderForTable) {
    this.$router.push({
      name: item.type == OrderType.freightquotation ? 'Quotation' : 'QuotationRequest',
      query: { type: item.type, number: item.number.toString() }
    });
  }

  async seeDocument(item: WmsQuotationOrderForTable) {
    try {
      this.isLoading = true;
      const res = await this.findWmsOrderByPk.execute({
        number: item.number,
        type: item.type
      });
      this.form = res;
      this.isLoading = false;
      this.$bvModal.show('quotationDocModal');
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async addNovelty(item: WmsQuotationOrderForTable) {
    try {
      this.selectedQuotation = Object.assign({}, item);
      await this.findNoveltyData();
      this.$bvModal.show('noveltyList');
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async findData() {
    try {
      if (!this.filter.type || !this.filter.from || !this.filter.to) {
        this.$swal({
          title: `${this.$t('general.info')}`,
          text: `${this.$t('general.selectType')}`,
          icon: 'info'
        });
        return;
      }

      this.isLoading = true;
      const res = await this.findOrdersByDateRangeAndType.execute({
        dateFrom: this.filter.from,
        dateTo: this.filter.to,
        type: this.filter.type
      });
      this.operationList = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async findNoveltyData() {
    try {
      if (!this.selectedQuotation) return;
      this.isLoading = true;
      this.novelties = await this.findNoveltyByOrder.internalExecute({
        orderNumber: this.selectedQuotation.number,
        orderType: this.selectedQuotation.type
      });
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }
}
