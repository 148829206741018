import { Events } from '@/tracking/domain/events/Events';
import { Activities } from '@/tracking/domain/activities/Activities';
import { Novelty } from '@/tracking/domain/novelty/Novelty';
import { Operation } from '@/tracking/domain/typeoperation/Operation';
import { Switches } from '@/tracking/domain/switches/Switches';
import { Timeline } from '@/tracking/domain/timeline/Timeline';
import { Login } from '@/courier/domain/login/Login';
import { Process } from '@/tracking/domain/processtype/Process';
import { Transport } from '@/tracking/domain/typetransport/Transport';
import { MonetaryTransaction } from '@/tracking/domain/monetarytransaction/MonetaryTransaction';
import { Country } from '@/settings/domain/country/Country';
import { States } from '@/settings/domain/state/States';
import { Currency } from '@/settings/domain/currency/Currency';
import { CurrencyFactor } from '@/settings/domain/currencyfactor/CurrencyFactor';
import { Routes } from '@/settings/domain/routes/Routes';
import { Warehouse } from '@/settings/domain/warehouse/Warehouse';
import { Company } from '@/settings/domain/company/Company';
import { File } from '@/settings/domain/file/File';
import { Taxe } from '@/settings/domain/taxe/Taxe';
import { PalletsFactor } from '@/settings/domain/palletsFactor/PalletsFactor';
import { Invoice } from '@/courier/domain/invoice/Invoice';
import { Customer } from '@/courier/domain/customer/Customer';
import { Purchase } from '@/courier/domain/purchase/Purchase';
import { Order } from '@/courier/domain/order/Order';
import { Consecutive } from '@/courier/domain/consecutive/Consecutive';
import { Role } from '@/settings/domain/role/Role';
import { User } from '@/settings/domain/user/User';
import { ChartValues } from '@/tracking/domain/timeline/ChartValues';
import { UnitOfMeasurement } from '@/settings/domain/unitofmeasurement/UnitOfMeasurement';
import { Weight } from '@/settings/domain/weight/Weight';
import { Volume } from '@/settings/domain/volume/Volume';
import { AlternativeReferences } from '@/wms/domain/catalogs/alternativeReferences/AlternativeReferences';
import { CategoryReferences } from '@/wms/domain/catalogs/categoryReferences/CategoryReferences';
import { ClassReferences } from '@/wms/domain/catalogs/classReferences/ClassReferences';
import { Packaging } from '@/wms/domain/catalogs/packaging/Packaging';
import { PricingEngine } from '@/wms/domain/catalogs/pricingEngine/PricingEngine';
import { PricingEngineParamList } from '@/wms/domain/catalogs/pricingEngineParam/PricingEngineParamList';
import { References } from '@/wms/domain/catalogs/references/References';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { Address } from '@/settings/domain/ParmsGeneral/address/Address';
import { Bank } from '@/settings/domain/ParmsGeneral/bank/Bank';
import { Contact } from '@/settings/domain/ParmsGeneral/contact/Contact';
import { GroupAgentService } from '@/settings/domain/ParmsGeneral/groupAgentService/GroupAgentService';
import { Incoterms } from '@/settings/domain/ParmsGeneral/Incoterms/Incoterms';
import { PaymentMethods } from '@/settings/domain/ParmsGeneral/paymentMethods/PaymentMethods';
import { TermsPayment } from '@/settings/domain/ParmsGeneral/termsPayment/TermsPayment';
import { TypeCustomer } from '@/settings/domain/entity/typeCustomer/TypeCustomer';
import { TypeIdentification } from '@/settings/domain/entity/typeIdentification/TypeIdentification';
import { GroupReferences } from '@/wms/domain/category/groupReferences/GroupReferences';
import { Variety } from '@/wms/domain/catalogs/xgeneral/variety/Variety';
import { Segmentation } from '@/wms/domain/catalogs/segmentation/Segmentation';
import { PackingUnit } from '@/wms/domain/packingUnit/PackingUnit';
import { Containers } from '@/settings/domain/containers/Containers';
import { PackingUnits } from '@/settings/domain/packing_units/PackingUnits';
import { Zone } from '@/settings/domain/zone/Zone';
import { ZoneL } from '@/wms/domain/layout/zone/ZoneL';
import { Rack } from '@/wms/domain/layout/rack/Rack';
import { Port } from '@/freight/domain/ports/Port';
import { Store } from '@/wms/domain/layout/store/Store';
import { Territories } from '@/settings/domain/views/territories/Territories';
import { City } from '@/settings/domain/city/City';
import { PreEntity } from '@/settings/domain/pre_entity/PreEntity';
import { OrderbyFilter } from '@/courier/domain/view/orderbyfilter/OrderbyFilter';
import { ZipCode } from '@/settings/domain/ZipCode/ZipCode';
import { CommodityType } from '@/freight/domain/commodity_type/CommodityType';
import { TimelineConfig } from '@/tracking/domain/timelineConfig/TimelineConfig';
import { TypeContact } from '@/settings/domain/ParmsGeneral/typeContact/TypeContact';
import { ResizingZone } from '@/wms/domain/layout/configResizing/ResizingZone';
import { TypeAddress } from '@/settings/domain/ParmsGeneral/typeAddress/TypeAddress';
import { Stock } from '@/wms/domain/stock/Stock';

export class State {
  dataOrder: Order[] = [];
  dataEvents: Events[] = [];
  dataActivities: Activities[] = [];
  dataNovelty: Novelty[] = [];
  dataOperation: Operation[] = [];
  dataSwitches: Switches[] = [];
  dataTimeline: Timeline[] = [];
  dataTimelineConfig: TimelineConfig[] = [];
  dataProcess: Process[] = [];
  dataTransport: Transport[] = [];
  dataMonetary: MonetaryTransaction[] = [];
  dataUser: User[] = [];
  dataCustomer: Customer[] = [];
  dataConsecutive: Consecutive[] = [];
  token: Login = [] as any;
  //Andres Escorcia
  dataFile: File[] = [];
  dataCompany: Company[] = [];
  dataWarehouse: Warehouse[] = [];
  dataRoutes: Routes[] = [];
  dataCurrenciesFactor: CurrencyFactor[] = [];
  dataCurrencies: Currency[] = [];
  dataZones: Zone[] = [];
  dataStates: States[] = [];
  dataCountries: Country[] = [];
  dataTaxes: Taxe[] = [];
  dataPriceList: any[] = [];
  dataPalletsFactor: PalletsFactor[] = [];
  dataInvoice: Invoice[] = [];
  dataPurchase: Purchase[] = [];
  dataRole: Role[] = [];
  dataChartValues: ChartValues[] = [];
  dataUnitOfMeasurement: UnitOfMeasurement[] = [];
  dataWeight: Weight[] = [];
  dataVolume: Volume[] = [];
  dataAlternativeReferences: AlternativeReferences[] = [];
  dataCategoryReferences: CategoryReferences[] = [];
  dataClassReferences: ClassReferences[] = [];
  dataPackaging: Packaging[] = [];
  dataPricingEngine: PricingEngine[] = [];
  dataPricingEngineParam: PricingEngineParamList[] = [];
  dataReferences: References[] = [];
  dataCustomerEntities: Entity[] = [];
  dataAddress: Address[] = [];
  dataBank: Bank[] = [];
  dataContact: Contact[] = [];
  dataGroupAgentService: GroupAgentService[] = [];
  dataIncoterms: Incoterms[] = [];
  dataPaymentMethods: PaymentMethods[] = [];
  dataTermsPayment: TermsPayment[] = [];
  dataTypeCustomer: TypeCustomer[] = [];
  dataTypeIdentification: TypeIdentification[] = [];
  dataGroupReferences: GroupReferences[] = [];
  dataVariety: Variety[] = [];
  dataSegmentation: Segmentation[] = [];
  dataPackingUnit: PackingUnit[] = [];
  dataContainer: Containers[] = [];
  dataPackingUnits: PackingUnits[] = [];
  dataLayoutZone: ZoneL[] = [];
  dataLayoutRack: Rack[] = [];
  dataPorts: Port[] = [];
  dataStore: Store[] = [];
  dataTerritories: Territories[] = [];
  dataCity: City[] = [];
  dataZipCode: ZipCode[] = [];
  dataPreEntity: PreEntity[] = [];
  dataViewOrder: OrderbyFilter[] = [];
  dataMigration: any[] = [];
  dataCommodityTypes: CommodityType[] = [];
  dataTypeContact: TypeContact[] = [];
  dataLayoutConfig: ResizingZone[] = [];
  dataTypeAddress: TypeAddress[] = [];
  dataCalculatePrices: any[] = [];
  dataDocumentsOrders: any[] = [];
  dataStock: Stock[] = [];
}
