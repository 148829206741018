
import Component from 'vue-class-component';
import Vue from 'vue';
import VueBarcode from 'vue-barcode';
import autoTable from 'jspdf-autotable';
import exceljs from 'exceljs';
import { Emit, Prop } from 'vue-property-decorator';
import { jsPDF } from 'jspdf';

@Component({
  name: 'CustomTableN',
  components: { VueBarcode, 'vue-good-table': require('vue-good-table').VueGoodTable }
})
export default class CustomTableN extends Vue {
  //DATA
  @Prop() readonly fields!: any;
  @Prop() readonly items!: any[];
  @Prop() readonly actions!: undefined;
  @Prop() readonly inputForm!: undefined;
  @Prop() readonly perPage!: number;
  @Prop() readonly rowStyleClass!: string;
  @Prop({ default: false, required: false }) readonly activeExports!: boolean;
  @Prop({ default: false, required: false }) readonly lineNumbers!: boolean;
  @Prop({ default: 'myTable' }) readonly refTable!: string;
  @Prop({ default: false, required: false }) showCheckbox!: boolean;

  isLoading = false;

  mounted() {
    this.$nextTick(() => this.generateDropDowns());
  }

  updated() {
    this.$nextTick(() => this.generateDropDowns());
  }

  @Emit('select-table-checkbox')
  selectionChanged(newValue: any) {
    return newValue;
  }

  generateDropDowns() {
    const table = this.$refs[this.refTable] as any; /*Perdoname Dios por este any */
    table.typedColumns.map((item: any) => {
      if (
        item.filterOptions &&
        'filterDropdownItems' in item.filterOptions &&
        !('generateFilterDropdown' in item.filterOptions)
      ) {
        item.filterOptions.placeholder = this.$t('general.select');
        item.filterOptions.filterDropdownItems = Array.from(
          new Set(this.items.map((row: any) => row[item.field])).values()
        ).sort();
      }
    });
  }

  paginationOptions = {
    enabled: true,
    'first-label': `${this.$t('general.firstPage')}`,
    'last-label': `${this.$t('general.lastPage')}`,
    nextLabel: `${this.$t('general.next')}`,
    prevLabel: `${this.$t('general.prev')}`,
    rowsPerPageLabel: `${this.$t('general.rowsPerPage')}`,
    ofLabel: `${this.$t('general.of')}`,
    perPageDropdown: [5, 10, 15, 20, 50, 75, 100],
    perPage: this.perPage ?? 15
  };

  headForPdf() {
    return this.fields
      .map((item: any) => ({ header: item.label, dataKey: item.field, key: item.field }))
      .filter((item: { header: string; dataKey: string }) => {
        return item.dataKey != 'actions';
      });
  }

  async generateXLS() {
    const doc = new exceljs.Workbook();
    const worksheet = doc.addWorksheet('report');
    worksheet.columns = this.headForPdf();
    worksheet.addRows(this.items);
    const res = await doc.xlsx.writeBuffer();
    const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'download.xlsx';
    anchor.click();
    window.URL.revokeObjectURL(url);
  }

  generatePDF() {
    this.isLoading = true;
    const doc = new jsPDF();
    autoTable(doc, {
      columns: this.headForPdf(),
      body: this.items,
      bodyStyles: { fontSize: 6.5, halign: 'right' },
      headStyles: { fontSize: 6.5, halign: 'right' },
      footStyles: { fillColor: '#ccd8db', fontSize: 6.5, halign: 'right' }
    });
    doc.save('report.pdf');
    this.isLoading = false;
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }
}
