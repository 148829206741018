
import { TYPES } from '@/core/config/Types';
import { FindDocumentsByPkV2 } from '@/courier/application/uses_cases/DocumentsV2/search/FindDocumentsByPkV2';
import { DocumentHeader } from '@/courier/domain/DocumentsV2/Documents_V2';
import { Inject } from 'inversify-props';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'DetailDocument'
})
export default class DetailDocument extends Vue {
  documentHeader: DocumentHeader = new DocumentHeader();
  isLoading = false;

  @Inject(TYPES.DOCUMENTS_V2_FIND_BY_PK)
  readonly findDocument!: FindDocumentsByPkV2;

  mounted() {
    const { number, type } = this.$route.query;

    if (!number || !type) {
      this.$router.push({ name: 'Dashboard' });
      return;
    }

    this.find(Number(number), String(type));
  }

  async find(number: number, type: string) {
    try {
      this.isLoading = true;

      const res = await this.findDocument.execute({
        number: Number(number),
        typeOrder: type
      });
      this.documentHeader = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }
}
