import { Injectable } from '@/core/di/Injectable';
import { Command } from '@/core/cqrs/Command';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { Store } from '@/wms/domain/layout/store/Store';
import { StoreRepository } from '@/wms/domain/layout/store/StoreRepository';

@Injectable()
export class CreateStore extends Command<Promise<any>, Store> {
  public constructor(
    @Inject(TYPES.STORE_REPOSITORY)
    private readonly storeRepository: StoreRepository
  ) {
    super();
  }

  internalExecute(store: Store): Promise<any> {
    return this.storeRepository.create(store);
  }
}
