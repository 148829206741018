import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { CategoryReferences } from '@/wms/domain/catalogs/categoryReferences/CategoryReferences';
import { CategoryReferencesRepository } from '@/wms/domain/catalogs/categoryReferences/CategoryReferencesRepository';

@Injectable()
export class FindAllCategoryReferences extends Query<Promise<CategoryReferences[]>> {
  public constructor(
    @Inject(TYPES.CATEGORY_REFERENCE_REPOSITORY)
    private readonly categoryReferencesRepository: CategoryReferencesRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(): Promise<CategoryReferences[]> {
    const dataCategoryReferences = await this.categoryReferencesRepository.findAll();
    this.stateManager.patch({ dataCategoryReferences });
    return this.stateManager.state.dataCategoryReferences;
  }
}
