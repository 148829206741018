import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Variety } from '@/wms/domain/catalogs/xgeneral/variety/Variety';
import { VarietyRepository } from '@/wms/domain/catalogs/xgeneral/variety/VarietyRepository';

@Injectable()
export class FindAllVariety extends Query<Promise<Variety[]>> {
  public constructor(
    @Inject(TYPES.VARIETY_REFERENCE_REPOSITORY)
    private readonly varietyRepository: VarietyRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(): Promise<Variety[]> {
    const dataVariety = await this.varietyRepository.findAll();
    this.stateManager.patch({ dataVariety });
    return this.stateManager.state.dataVariety;
  }
}
