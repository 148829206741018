import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { TypeCustomerRepository } from '@/settings/domain/entity/typeCustomer/TypeCustomerRepository';
import { TypeCustomer } from '@/settings/domain/entity/typeCustomer/TypeCustomer';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class TypeCustomerService implements TypeCustomerRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_TYPECUSTOMER_SAVE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_TYPECUSTOMER_SAVE;

  private readonly API_TYPECUSTOMER_FINDALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_TYPECUSTOMER_FINDALL;

  async create(typeCustomer: TypeCustomer) {
    try {
      await http.post<TypeCustomer[]>(this.API_TYPECUSTOMER_SAVE, JSON.stringify(typeCustomer));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAll(): Promise<TypeCustomer[]> {
    try {
      return await http.get<TypeCustomer[]>(this.API_TYPECUSTOMER_FINDALL);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
